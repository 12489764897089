import React from "react";

import { NonCommissioningEventGradeTable } from "@components/common/events/NonCommissioningEventGradeTable";

import {
  EventDetails,
  EventEmployeeDetails,
  NonCommissioningEventGrade
} from "@models/event";

import { getEmployeesByType } from "@utils/eventAttendanceHelper";

import { NonCommissioningEventGradeForm } from "./NonCommissioningEventGradeForm";

interface Props {
  editable?: boolean;
  editablePracticalGrade?: boolean;

  errors?: { [key: string]: string };
  isLoading?: boolean;
  eventData?: EventDetails;
  eventEmployees?: EventEmployeeDetails[];
  onSubmit?: (values: NonCommissioningEventGrade[]) => void;
}

export const NonCommissioningEventGrades = (props: Props) => {
  const { availableEmployees: ungradedEmployees } = getEmployeesByType(
    props.eventEmployees || []
  );

  return props.editable || props.editablePracticalGrade ? (
    <NonCommissioningEventGradeForm
      isLoading={props.isLoading}
      errors={props.errors}
      onSubmit={props.onSubmit}
      eventData={props.eventData}
      editablePracticalGrade={props.editablePracticalGrade}
      eventEmployees={
        props.editablePracticalGrade ? props.eventEmployees : ungradedEmployees
      }
    />
  ) : (
    <NonCommissioningEventGradeTable eventEmployees={props.eventEmployees} />
  );
};
