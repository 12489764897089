import picture30 from "@assets/images/user-guide/2.30.png";
import picture31 from "@assets/images/user-guide/2.31.png";

export const certificateSearch = [
  {
    title: "Check certificate",
    html: `<ol>
    <li>
        <p>Once you press Certificates search tab on Panel menu you will be redirected to Check certificate page with the following information:</p>
        <p>Picture 2.30</p>
        <p><img src=${picture30} width="624" height="217"></p>
        <ol>
            <li>
                <p>Search by IIN, enter 12 digits</p>
            </li>
            <li>
                <p>Search by Certificate number, enter 12 digits</p>
            </li>
            <li>
                <p>Search button</p>
            </li><br>
        </ol>
        <p></p>
    </li>
</ol>`
  },
  {
    title: "How to Check certificate",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Certificate search tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Input IIN or Certificate number
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Search button or Enter on your keyboard
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 2.31
</p>
<p dir="ltr">
    <img
        src=${picture31}
        width="615"
        height="332"
    />
</p>
`
  }
];
