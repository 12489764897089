import picture14 from "@assets/images/user-guide/1.14.png";

export const members = [
  {
    title: "What’s on Members page",
    html: `<ol>
    <li>
        <p>Once you press Members tab on Panel menu you will be redirected to Members page with the following information:</p>
        <p>Picture 1.14</p>
        <p><img src="https://lh3.googleusercontent.com/4DpDfm9h1_4RnAtfy_dpdCTtJ8imt9vzpAHoFVdo0E69wAfMbv8cebX_SzYtbd4a5s5dME1R1GAI26hxHlpuePht4tWTOw6dWZY6GnUsvIuqeYzuPCVtF59_ff-LPYoKWy3Ld-FK75xV7NypzqRwRFYOsJbI_ssO0WmUouHxqC8l-fJKbzWgT_CtUeATWsq_JN_Qtg" width="624" height="348"></p>
        <ol>
            <li>
                <p>Invite member button</p>
            </li>
            <li>
                <p>Active members block with total number of active members</p>
            </li>
            <li>
                <p>Edit and Delete button</p>
            </li>
            <li>
                <p>Invited members block with total number of invited members</p>
            </li>
            <li>
                <p>Delete button</p>
            </li>Note: System allows to edit only your colleagues information, in case if you have appropriate permission.
        </ol>
        <p></p>
    </li>
</ol>`
  },

  {
    title: "How to export user- members from the portal?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Members tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Export users button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System will download an excel file with all active and inactive
            members specified on the page. This functionality is only available
            for TCO users
        </p>
    </li>
</ol>`
  },
  {
    title: "How to invite my colleague to the portal?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Members tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Invite member button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select role for your colleague, KC or CT team, fill out all fields
            and press on Invite button
        </p>
    </li>
</ol>
<p>
    Note: Invited member will be shown in Invited members block, until he/she
    did not finish the registration process.
</p>`
  },
  {
    title: "How to edit my colleague information?",
    html: `<p dir="ltr">
    If you need to change role, type of permissions or notification settings of
    your colleague follow the steps below:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            On members page press on Edit button for required colleague
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Make the necessary changes and press on Save button
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Note: Once you save changes your colleague will receive email notification.
</p>
<div>
    <br/>
</div>`
  },
  {
    title: "Why can I delete invited members?",
    html: `<p dir="ltr">
    Once you invite your colleague, the system sends an email notification to
    finish the registration process. Invitation link in email expires in 24
    hours, so in case your colleague missed the expiration date you can delete
    him/her and invite again.
</p>
<div>
    <br/>
</div>`
  }
];
