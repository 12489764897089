import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Form, Checkbox, Row, Col, Space, Button } from "antd";

import { Subcontractor } from "@models/subcontractor";

interface Props {
  selectedSubcontractors: number[];
  subcontractors?: Subcontractor[];
  isLoading?: boolean;
  errors?: { [key: string]: string };
  onSubmit?: (subcontractors: number[]) => void;
  onCancel?: () => void;
}

export const SetSubcontractorToEventForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<{
    selectedSubcontractors: number[];
  }>({
    initialValues: {
      selectedSubcontractors: props.selectedSubcontractors
    },
    onSubmit: (values) => {
      if (props.onSubmit) {
        props.onSubmit(values.selectedSubcontractors);
      }
    }
  });

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  const subcontractorsOptions =
    props.subcontractors?.map(({ id, name }) => ({
      value: id,
      label: name
    })) || [];

  const toggleSelectAllSubcontractors = (select: boolean) => {
    const values = select ? subcontractorsOptions.map((s) => s.value) : [];
    formik.setFieldValue("selectedSubcontractors", values);
  };

  return (
    <Form onSubmitCapture={() => formik.submitForm()}>
      <Checkbox
        checked={
          props.subcontractors?.length ===
          formik.values.selectedSubcontractors.length
        }
        onChange={(e) => toggleSelectAllSubcontractors(e.target.checked)}
      >
        {t("all")}
      </Checkbox>
      <Checkbox.Group
        options={subcontractorsOptions}
        value={formik.values.selectedSubcontractors}
        onChange={(values) => {
          formik.setFieldValue("selectedSubcontractors", values);
        }}
      />

      <Form.Item noStyle>
        <Row justify="end">
          <Col>
            <Space align="end">
              <Button htmlType="button" onClick={props.onCancel}>
                {t("cancel")}
              </Button>

              <Button
                htmlType="submit"
                type="primary"
                loading={props.isLoading}
                disabled={formik.values.selectedSubcontractors === []}
              >
                {t("save")}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};
