import picture32 from "@assets/images/user-guide/2.32.png";
import picture33 from "@assets/images/user-guide/2.33.png";

export const requests = [
  {
    title: "Your requests from Contractors:",
    html: `<p dir="ltr">
    In order to see your requests from Contractors press on Requests tab on
    Panel menu you will be redirected to Your requests from Contractors page
    with the following information:
</p>
<p dir="ltr">
    Picture 2.32
</p>
<p dir="ltr">
    <img
        src=${picture32}
        width="624"
        height="283"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Date from
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Date to
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Contractors
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Assignee
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Request ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of messages in one request
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to reply to Request?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            Press on Requests tab on the panel menu
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Request
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Leave your message on Leave a reply field and press Submit button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: Once you reply to the Contractor’s request which hasn’t any reply
    from the Training center member yet you’ll become a Request Assignee. Later
    only Request Assignee can reply to the Request.
</p>
<p dir="ltr">
    Picture 2.33
</p>
<p dir="ltr">
    <img
        src=${picture33}
        width="624"
        height="369"
    />
</p>
`
  }
];
