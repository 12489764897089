import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import moment from "moment";

import { Button, Col, DatePicker, Form, Input, Row, Space } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { EditEmployeePayload, EmployeeDetails } from "@models/employee";
import { Country } from "@models/countryCity";
import { Contract } from "@models/contract";

import { MOU_CATEGORY_OPTIONS } from "@constants/mouCategoryOptions";

interface FormValues {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  firstNameCyrillic: string;
  lastNameCyrillic: string;
  middleNameCyrillic: string;
  position: string;
  mouCategory: number;
  phoneNumber: string;
  birthDate?: string;
  citizenshipId: number;
  residenceCountryId: number;
  residenceRegion: string;
  contractorId: number;
  contractId: number;
  lineSupervisorBadgeNumber?: number | "";
  workLocationArea: string;
  facilityAccessStandard: string;
  facilityAccessAdditional: string;
  specialAccessJustification: string;
  waterTreatmentPlant: string;
  residentialAreas: string;
  firstNameCompanyRep: string;
  lastNameCompanyRep: string;
  phoneNumberCompanyRep: string;
}

interface Props {
  employee: EmployeeDetails | null;
  isLoading?: boolean;
  errors?: { [key: string]: string };
  selectableContractors?: { id: number; name: string }[];
  contracts?: Contract[];
  countries?: Country[];
  onSubmit?: (employee: EditEmployeePayload) => void;
  onCancel?: () => void;
}

export const EditEmployeeForm: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      id: 0,
      firstName: "",
      lastName: "",
      middleName: "",
      firstNameCyrillic: "",
      lastNameCyrillic: "",
      middleNameCyrillic: "",
      position: "",
      mouCategory: 0,
      phoneNumber: "",
      birthDate: "",
      citizenshipId: 0,
      residenceCountryId: 0,
      residenceRegion: "",
      contractorId: 0,
      contractId: 0,
      lineSupervisorBadgeNumber: 0,
      workLocationArea: "",
      facilityAccessStandard: "",
      facilityAccessAdditional: "",
      specialAccessJustification: "",
      waterTreatmentPlant: "",
      residentialAreas: "",
      firstNameCompanyRep: "",
      lastNameCompanyRep: "",
      phoneNumberCompanyRep: ""
    },
    onSubmit: (values) => {
      if (props.onSubmit) {
        props.onSubmit({
          ...values,
          lineSupervisorBadgeNumber:
            values.lineSupervisorBadgeNumber === ""
              ? undefined
              : values.lineSupervisorBadgeNumber,
          birthDate: values.birthDate === "" ? undefined : values.birthDate
        });
      }
    }
  });

  useEffect(() => {
    if (props.employee) {
      formik.setValues({ ...props.employee });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employee]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <>
      <Form onSubmitCapture={() => formik.submitForm()}>
        <Row wrap gutter={4}>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.firstName")}
              validateStatus={formik.errors.firstName && "error"}
              help={formik.errors.firstName}
            >
              <Input
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.lastName")}
              validateStatus={formik.errors.lastName && "error"}
              help={formik.errors.lastName}
            >
              <Input
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.middleName")}
              validateStatus={formik.errors.middleName && "error"}
              help={formik.errors.middleName}
            >
              <Input
                name="middleName"
                value={formik.values.middleName}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.firstNameCyrillic")}
              validateStatus={formik.errors.firstNameCyrillic && "error"}
              help={formik.errors.firstNameCyrillic}
            >
              <Input
                name="firstNameCyrillic"
                value={formik.values.firstNameCyrillic}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.lastNameCyrillic")}
              validateStatus={formik.errors.lastNameCyrillic && "error"}
              help={formik.errors.lastNameCyrillic}
            >
              <Input
                name="lastNameCyrillic"
                value={formik.values.lastNameCyrillic}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.middleNameCyrillic")}
              validateStatus={formik.errors.middleNameCyrillic && "error"}
              help={formik.errors.middleNameCyrillic}
            >
              <Input
                name="middleNameCyrillic"
                value={formik.values.middleNameCyrillic}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{ span: 24 }} label={t("employees.iin")}>
              <Input disabled value={props.employee?.iin || ""} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{ span: 24 }} label={t("employees.birthDate")}>
              <DatePicker
                name="birthDate"
                style={{ width: "100%" }}
                value={
                  formik.values.birthDate
                    ? moment(formik.values.birthDate, "YYYY-MM-DD")
                    : undefined
                }
                onChange={(_, value) =>
                  formik.setFieldValue("birthDate", value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              style={{ fontSize: "12px" }}
              label={t("employees.phoneNumber")}
              validateStatus={formik.errors.phoneNumber && "error"}
              help={formik.errors.phoneNumber}
            >
              <Input
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.contractor")}
              validateStatus={formik.errors.contractorId && "error"}
              help={formik.errors.contractorId}
            >
              <SearchSelect
                value={formik.values.contractorId}
                options={props.selectableContractors?.map((s) => ({
                  value: s.id,
                  label: s.name
                }))}
                onChange={(v: number) =>
                  formik.setFieldValue("contractorId", v)
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.contract")}
              validateStatus={formik.errors.contractId && "error"}
              help={formik.errors.contractId}
            >
              <SearchSelect
                value={formik.values.contractId}
                options={props.contracts?.map((c) => ({
                  value: c.id,
                  label: c.contractNumber
                }))}
                onChange={(v: number) => formik.setFieldValue("contractId", v)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.mouCategory")}
              validateStatus={formik.errors.mouCategory && "error"}
              help={formik.errors.mouCategory}
            >
              <SearchSelect
                value={formik.values.mouCategory}
                options={MOU_CATEGORY_OPTIONS.map((c) => ({
                  value: c.value,
                  label: t(c.labelKey)
                }))}
                onChange={(v: number) => formik.setFieldValue("mouCategory", v)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.jobTitle")}
              validateStatus={formik.errors.position && "error"}
              help={formik.errors.position}
            >
              <Input
                name="position"
                value={formik.values.position}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.citizenship")}
              validateStatus={formik.errors.citizenshipId && "error"}
              help={formik.errors.citizenshipId}
            >
              <SearchSelect
                options={props.countries?.map((c) => ({
                  value: c.id,
                  label: i18n.language === "en" ? c.nameEn : c.nameRu
                }))}
                value={formik.values.citizenshipId}
                onChange={(v: number) =>
                  formik.setFieldValue("citizenshipId", v)
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.residenceCountry")}
              validateStatus={formik.errors.residenceCountryId && "error"}
              help={formik.errors.residenceCountryId}
            >
              <SearchSelect
                options={props.countries?.map((c) => ({
                  value: c.id,
                  label: i18n.language === "en" ? c.nameEn : c.nameRu
                }))}
                value={formik.values.residenceCountryId}
                onChange={(v: number) =>
                  formik.setFieldValue("residenceCountryId", v)
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.residenceRegion")}
              validateStatus={formik.errors.residenceRegion && "error"}
              help={formik.errors.residenceRegion}
            >
              <Input
                name="residenceRegion"
                value={formik.values.residenceRegion}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.workLocationArea")}
              validateStatus={formik.errors.workLocationArea && "error"}
              help={formik.errors.workLocationArea}
            >
              <Input
                name="workLocationArea"
                value={formik.values.workLocationArea}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.firstNameCompanyRep")}
              validateStatus={formik.errors.firstNameCompanyRep && "error"}
              help={formik.errors.firstNameCompanyRep}
            >
              <Input
                name="firstNameCompanyRep"
                value={formik.values.firstNameCompanyRep}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.lastNameCompanyRep")}
              validateStatus={formik.errors.lastNameCompanyRep && "error"}
              help={formik.errors.lastNameCompanyRep}
            >
              <Input
                name="lastNameCompanyRep"
                value={formik.values.lastNameCompanyRep}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.phoneNumberCompanyRep")}
              validateStatus={formik.errors.phoneNumberCompanyRep && "error"}
              help={formik.errors.phoneNumberCompanyRep}
            >
              <Input
                name="phoneNumberCompanyRep"
                value={formik.values.phoneNumberCompanyRep}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.lineSupervisorBadgeNumber")}
              validateStatus={
                formik.errors.lineSupervisorBadgeNumber && "error"
              }
              help={formik.errors.lineSupervisorBadgeNumber}
            >
              <Input
                name="lineSupervisorBadgeNumber"
                value={formik.values.lineSupervisorBadgeNumber}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.facilityAccessStandard")}
              validateStatus={formik.errors.facilityAccessStandard && "error"}
              help={formik.errors.facilityAccessStandard}
            >
              <Input
                name="facilityAccessStandard"
                value={formik.values.facilityAccessStandard}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.facilityAccessAdditional")}
              validateStatus={formik.errors.facilityAccessAdditional && "error"}
              help={formik.errors.facilityAccessAdditional}
            >
              <Input
                name="facilityAccessAdditional"
                value={formik.values.facilityAccessAdditional}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.specialAccessJustification")}
              validateStatus={
                formik.errors.specialAccessJustification && "error"
              }
              help={formik.errors.specialAccessJustification}
            >
              <Input
                name="specialAccessJustification"
                value={formik.values.specialAccessJustification}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.waterTreatmentPlant")}
              validateStatus={formik.errors.waterTreatmentPlant && "error"}
              help={formik.errors.waterTreatmentPlant}
            >
              <Input
                name="waterTreatmentPlant"
                value={formik.values.waterTreatmentPlant}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("employees.residentialAreas")}
              validateStatus={formik.errors.residentialAreas && "error"}
              help={formik.errors.residentialAreas}
            >
              <Input
                name="residentialAreas"
                value={formik.values.residentialAreas}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Space align="end">
              <Button htmlType="button" onClick={props.onCancel}>
                {t("cancel")}
              </Button>

              <Button
                htmlType="submit"
                type="primary"
                loading={props.isLoading}
              >
                {t("save")}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};
