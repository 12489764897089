import React, { createContext, useReducer } from "react";

import { notification } from "antd";

import { AppUser } from "@models/appUser";
import { Contractor } from "@models/contractor";
import { USER_ROLES } from "@models/roles";

import userApi from "@api/userApi";
import { contractorsApi } from "@api/contractorsApi";

type Actions =
  | { type: "SET_USER"; user: AppUser | null }
  | { type: "SET_USER_CONTRACTOR"; contractor: Contractor | null }
  | { type: "SET_USER_LOADING"; userLoading: boolean }
  | { type: "LOGOUT" };

export type UserRole =
  | "trainingcenter"
  | "subcontractor"
  | "contractor"
  | "admin"
  | "guest";

interface State {
  user: AppUser | null;
  userRole: UserRole;
  userEntityId: number;
  userContractor: Contractor | null;
  isUserLoggedIn: boolean;
  userLoading: boolean;
}

function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case "LOGOUT":
      localStorage.removeItem("CT_PORTAL_USER_TOKEN");
      return {
        isUserLoggedIn: false,
        user: null,
        userContractor: null,
        userLoading: false,
        userRole: "guest",
        userEntityId: 0
      };

    case "SET_USER":
      return { ...state, user: action.user };

    case "SET_USER_LOADING":
      return { ...state, userLoading: action.userLoading };

    case "SET_USER_CONTRACTOR":
      return { ...state, userContractor: action.contractor };
    default:
      return state;
  }
}

async function loadUser(dispatch: React.Dispatch<Actions>) {
  dispatch({ type: "SET_USER_LOADING", userLoading: true });
  try {
    const response = await userApi.fetchUserData();
    const user = response.data;

    const role = user.userRoles[0].role || 0;
    const entityId = user.userRoles[0].entityId || 0;

    switch (role) {
      case USER_ROLES.CONTRACTOR_OWNER:
      case USER_ROLES.CONTRACTOR_MANAGER:
      case USER_ROLES.SUBCONTRACTOR_MANAGER:
      case USER_ROLES.SUBCONTRACTOR_OWNER:
        loadUserContractor(entityId, dispatch);

        break;

      default:
        break;
    }

    dispatch({ type: "SET_USER", user });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    notification.warn({
      message: error.response.data.message,
      key: "loginExpired",
      duration: 0
    });
    dispatch({ type: "SET_USER", user: null });
  } finally {
    dispatch({ type: "SET_USER_LOADING", userLoading: false });
  }
}

async function loadUserContractor(
  contractorId: number,
  dispatch: React.Dispatch<Actions>
) {
  try {
    const response = await contractorsApi.getContractor(contractorId);
    const contractor = response.data;

    dispatch({ type: "SET_USER_CONTRACTOR", contractor });
  } catch (error) {
    dispatch({ type: "SET_USER_CONTRACTOR", contractor: null });
  }
}

async function logoutUser(dispatch: React.Dispatch<Actions>) {
  dispatch({ type: "SET_USER_LOADING", userLoading: true });
  try {
    await userApi.logout();
    dispatch({ type: "LOGOUT" });
  } finally {
    dispatch({ type: "SET_USER_LOADING", userLoading: false });
  }
}

function defineUserRole(role: number | undefined): UserRole {
  switch (role) {
    case USER_ROLES.TRAINING_CENTER_MANAGER:
    case USER_ROLES.TRAINING_CENTER_OWNER:
    case USER_ROLES.TRAINING_CENTER_TEACHER:
      return "trainingcenter";
    case USER_ROLES.SUBCONTRACTOR_MANAGER:
    case USER_ROLES.SUBCONTRACTOR_OWNER:
      return "subcontractor";
    case USER_ROLES.CONTRACTOR_MANAGER:
    case USER_ROLES.CONTRACTOR_OWNER:
      return "contractor";
    case USER_ROLES.ADMINISTRATOR:
      return "admin";
    default:
      return "guest";
  }
}

interface UserContextProps extends State {
  loadUser: Function;
  logoutUser: Function;
  checkPermission: (permissionCode: number) => boolean;
}

export const UserContext = createContext<UserContextProps>({
  user: null,
  userContractor: null,
  userRole: "guest",
  userEntityId: 0,
  isUserLoggedIn: false,
  userLoading: false,
  loadUser: () => {},
  logoutUser: () => {},
  checkPermission: () => true
});

export const UserContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    isUserLoggedIn: false,
    user: null,
    userContractor: null,
    userRole: "guest",
    userEntityId: 0,
    userLoading: false
  });

  const providerValue: UserContextProps = {
    user: state.user,
    userRole: defineUserRole(state?.user?.userRoles?.[0].role),
    userEntityId: state?.user?.userRoles?.[0].entityId || 0,
    userContractor: state.userContractor,
    isUserLoggedIn: !!state.user,
    userLoading: state.userLoading,
    loadUser: () => loadUser(dispatch),
    logoutUser: () => logoutUser(dispatch),
    checkPermission: (p) =>
      state.user?.permissions.some(
        (_userPermission) => _userPermission.permission === p
      ) || false
  };

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};
