export const enCompetencyLevels = {
  null: "-",

  0: "No competency level",
  2: "Basic",
  3: "Intermediate",
  4: "Advanced",
  5: "Competent",
  6: "Proficient",
  7: "Helper",
  20: "Trainee technician",
  22: "Junior technician",
  24: "Technician",
  26: "Senior technician"
};
