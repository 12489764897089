import picture25 from "@assets/images/user-guide/1.25.png";

export const members = [
  {
    title: "What’s on Members page:",
    html: `<p dir="ltr">
    Once you press Members tab on Panel menu you will be redirected to Members
    page with the following information:
</p>
<p dir="ltr">
    Picture 4.25
</p>
<p dir="ltr">
    <img
        sc=${picture25}
        width="624"
        height="316"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Invite member button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Active members block with total number of active members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Edit and Delete button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Invited members block with total number of invited members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Delete button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: System allows to edit only your colleagues information, in case if
    you have appropriate permission.
</p>
`
  },
  {
    title: "How to invite my colleague to the portal?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Members tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Invite member button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select role for your colleague, KC or CT team, fill out all the
            fields and press Invite button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: Invited member will be shown in the Invited members block, until
    he/she does not finish the registration process.
</p>
`
  },
  {
    title: "How to edit my colleague's information?",
    html: `<p dir="ltr">
    If you need to change role, type of permissions or notification settings of
    your colleague follow the steps below:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            On members page press on Edit button for required colleague
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Make the necessary changes and press Save button
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Note: Once you save changes your colleague will receive email notification.
</p>
`
  },
  {
    title: "Why can I delete an invited member?",
    html: `<p dir="ltr">
    Once you invite your colleague, the system sends an email notification to
    finish the registration process. Invitation link in email expires in 24
    hours, so in case your colleague missed the expiration date you can delete
    him/her and invite again.
</p>
`
  }
];
