import React from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Typography } from "antd";

import { EventCertificate } from "@models/certificate";

import { CertificateDetailsCard } from "./CertificateDetailsCard";

interface FormValues {
  iin?: string;
  certificateId?: string;
}

interface Props {
  errors?: { [key: string]: string };
  loading?: boolean;
  downloading?: boolean;
  results?: EventCertificate[];

  onCertificateDownload?: (certId: string) => void;
  onSubmit?: (v: FormValues) => void;
}

export const CertificateSearchContainer = (props: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      iin: undefined,
      certificateId: undefined
    },
    onSubmit(v) {
      if (props.onSubmit) {
        props.onSubmit(v);
      }
    }
  });

  return (
    <>
      <Form
        onSubmitCapture={() => {
          formik.submitForm();
        }}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col md={6}>
            <Form.Item
              label={t("certificates.iin")}
              help={props.errors?.iin}
              validateStatus={props.errors?.iin && "error"}
            >
              <Input
                name="iin"
                onChange={formik.handleChange}
                value={formik.values.iin}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col md={6}>
            <Form.Item
              label={t("certificates.certNumber")}
              help={props.errors?.certificateId}
              validateStatus={props.errors?.certificateId && "error"}
            >
              <Input
                name="certificateId"
                onChange={formik.handleChange}
                value={formik.values.certificateId}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label=" ">
              <Button type="primary" htmlType="submit" loading={props.loading}>
                {t("search")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <br />

      <Row>
        {props.results && props.results.length
          ? props.results.map((cert) => (
              <Col xs={13} key={cert.certificateId}>
                <CertificateDetailsCard
                  loading={props.downloading}
                  certificate={cert}
                  onDownload={props.onCertificateDownload}
                />
              </Col>
            ))
          : ""}

        {props.results?.length === 0 && (
          <div>
            <Typography.Title level={3}>
              {t("certificates.certNotFound")}
            </Typography.Title>

            <Typography.Text>
              {t("certificates.checkDataOrEnterNew")}
            </Typography.Text>
          </div>
        )}
      </Row>
    </>
  );
};
