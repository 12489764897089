import axios from "axios";

import { CompetencyLevel } from "@models/competencyLevel";
import { Contractor } from "@models/contractor";
import { EmployeeDetails } from "@models/employee";
import {
  EventEmployeeDetails,
  EventEmployeeGradeType,
  FailReasonType,
  EditEventPayload,
  PreMatureEvaluationPayload,
  EventFilter
} from "@models/event";
import {
  CreateEventPayload,
  EventAttendance,
  EventData,
  EventDetails,
  EventShortDetails,
  EventStatus,
  EventType,
  CommissioningEventRecommendation
} from "@models/event";
import { PaginationItem } from "@models/paginationItem";
import { ExcludeEmployeePayload } from "@models/event";

import { EVENT_TYPES } from "@constants/eventTypes";

const getTcEventPage = ({
  trainingCenterId,
  ...filters
}: {
  trainingCenterId: number;
  status?: EventStatus;
  page?: number;
}) =>
  axios.get<PaginationItem<EventShortDetails>>(
    `/api/training-center/${trainingCenterId}/event`,
    {
      params: { ...filters }
    }
  );

const getContractorEventPage = ({
  contractorId,
  ...filters
}: {
  contractorId: number;
  status?: EventStatus;
  page?: number;
}) =>
  axios.get<PaginationItem<EventShortDetails>>(
    `/api/contractor/${contractorId}/event`,
    {
      params: { ...filters }
    }
  );

const getEventPage = (filters: EventFilter & { page?: number }) => {
  if (filters.type && filters.type === EVENT_TYPES.COMMISSIONING_ASSESSMENT) {
    filters.type = EVENT_TYPES.ASSESSMENT;
  }
  if (filters.type && filters.type === EVENT_TYPES.COMMISSIONING_TRAINING) {
    filters.type = EVENT_TYPES.TRAINING;
  }
  return axios.get("/api/event", {
    params: filters
  });
};

const getTrainingCenterEventDetails = ({
  trainingCenterId,
  eventId
}: {
  trainingCenterId: number;
  eventId: number;
}) =>
  axios.get<EventDetails>(
    `/api/training-center/${trainingCenterId}/event/${eventId}`
  );

const getContractorEventDetails = ({
  contractorId,
  eventId
}: {
  contractorId: number;
  eventId: number;
}) =>
  axios.get<EventDetails>(`/api/contractor/${contractorId}/event/${eventId}`);

const getEventData = (eventId: number) =>
  axios.get<EventData>(`/api/event/${eventId}`);

const getEventAttendance = (eventId: number) =>
  axios.get<EventAttendance[]>(`/api/event/${eventId}/employee`);

const getTrainingCenterEventAttendance = ({
  trainingCenterId,
  eventId
}: {
  trainingCenterId: number;
  eventId: number;
}) =>
  axios.get<EventAttendance[]>(
    `/api/training-center/${trainingCenterId}/event/${eventId}/attendance`
  );

const setTrainingCenterEventAttendance = ({
  trainingCenterId,
  eventId,
  daysOff,
  attendances
}: {
  trainingCenterId: number;
  eventId: number;
  daysOff: Date[];
  attendances: EventAttendance[];
}) =>
  axios.post(
    `/api/training-center/${trainingCenterId}/event/${eventId}/attendance`,
    {
      daysOff,
      attendances
    }
  );
export const getApiSuffixByEventType = (eventType: EventType) => {
  switch (eventType) {
    case EventType.ASSESSMENT:
      return "assessment";
    case EventType.TRAINING:
      return "training";
    case EventType.OJT_TRAINING:
      return "ojt-training";
    case EventType.PRESCREENING:
      return "pre-screening";
  }
};

const createEvent = ({
  trainingCenterId,
  event: {
    applicableContractors,
    capacityMinNotificationEntities,
    daysOff,
    files,
    ...event
  }
}: {
  trainingCenterId: number;
  event: CreateEventPayload;
}) => {
  const apiUrlSuffix = getApiSuffixByEventType(
    event.type || EventType.OJT_TRAINING
  );

  const formData = new FormData();

  for (const k in event) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val: any = event[k];

    if (val !== undefined) {
      formData.append(k, val);
    }
  }

  if (applicableContractors && applicableContractors.length) {
    applicableContractors.forEach((v, i) => {
      formData.append(`applicableContractors[${i}]`, v.toString());
    });
  }

  if (
    capacityMinNotificationEntities &&
    capacityMinNotificationEntities.length
  ) {
    capacityMinNotificationEntities.forEach((v, i) => {
      formData.append(`capacityMinNotificationEntities[${i}]`, v.toString());
    });
  }

  if (daysOff && daysOff.length) {
    daysOff.forEach((v, i) => {
      formData.append(`daysOff[${i}]`, v.toString());
    });
  }

  if (files && files.length) {
    files.forEach((v, i) => {
      formData.append(`files`, v);
    });
  }

  return axios.post(
    `/api/training-center/${trainingCenterId}/${apiUrlSuffix}`,
    formData
  );
};

const editEvent = ({
  trainingCenterId,
  event: { id, ...event },
  eventType
}: {
  trainingCenterId: number;
  event: EditEventPayload;
  eventType: number;
}) => {
  const apiUrlSuffix = getApiSuffixByEventType(eventType);

  return axios.put(
    `/api/training-center/${trainingCenterId}/${apiUrlSuffix}/${id}`,
    event
  );
};

const getTcEventEmployees = ({
  trainingCenterId,
  eventId,
  filters = {}
}: {
  trainingCenterId: number;
  eventId: number;
  filters?: {
    isPassed?: number;
    contractorIds?: number[];
    status?: number;
  };
}) =>
  axios.get<EventEmployeeDetails[]>(
    `/api/training-center/${trainingCenterId}/event/${eventId}/employee`,
    {
      params: { ...filters }
    }
  );

const getContractorEventEmployees = ({
  contractorId,
  eventId,
  filters = {}
}: {
  contractorId: number;
  eventId: number;
  filters?: {
    isPassed?: number;
    contractorIds?: number[];
    status?: number;
  };
}) =>
  axios.get<EventEmployeeDetails[]>(
    `/api/contractor/${contractorId}/event/${eventId}/employee`,
    {
      params: { ...filters }
    }
  );

const getTcEventGradeEmployees = ({
  trainingCenterId,
  eventId
}: {
  trainingCenterId: number;
  eventId: number;
}) =>
  axios.get<EventEmployeeDetails[]>(
    `/api/training-center/${trainingCenterId}/event/${eventId}/grade/employees`
  );

const getTcEventContractors = ({
  trainingCenterId,
  eventId
}: {
  trainingCenterId: number;
  eventId: number;
}) =>
  axios.get<Contractor[]>(
    `/api/training-center/${trainingCenterId}/event/${eventId}/contractor`
  );

const getContractorEventContractors = ({
  contractorId,
  eventId
}: {
  contractorId: number;
  eventId: number;
}) =>
  axios.get<Contractor[]>(
    `/api/contractor/${contractorId}/event/${eventId}/contractor`
  );

const setSubcontractor = ({
  contractorId,
  eventId,
  subcontractorIds
}: {
  contractorId: number;
  eventId: number;
  subcontractorIds: number[];
}) =>
  axios.put(`/api/contractor/${contractorId}/event/${eventId}/subcontractor`, {
    subcontractorIds
  });

const contractorApplyEvent = (
  contractorId: number,
  eventId: number,
  employees: EmployeeDetails[]
) =>
  axios.post(`/api/contractor/${contractorId}/event/${eventId}/request`, {
    employees
  });

const adminApplyEvent = (eventId: number, employees: EmployeeDetails[]) =>
  axios.post(`/api/contractor/event/${eventId}/requestForAdmin`, {
    employees
  });

const tcCancelEvent = ({
  trainingCenterId,
  eventId,
  cancellationReason
}: {
  trainingCenterId: number;
  eventId: number;
  cancellationReason: string;
}) =>
  axios.put(`api/training-center/${trainingCenterId}/event/${eventId}/cancel`, {
    cancellationReason
  });

const preMatureEvaluate = ({
  isEventOJT,
  employeeId,
  eventId,
  trainingCenterId,
  ...payload
}: PreMatureEvaluationPayload) =>
  isEventOJT
    ? axios.post(
        `/api/training-center/${trainingCenterId}/event/${eventId}/employee/${employeeId}/grade/ojt-training`,
        {
          evaluatedDate: payload.evaluatedDate,
          instructorId: payload.instructorId,
          isPassed: payload.isPassed
        }
      )
    : axios.post(
        `/api/training-center/${trainingCenterId}/event/${eventId}/employee/${employeeId}/grade/training`,
        {
          evaluatedDate: payload.evaluatedDate,
          instructorId: payload.instructorId,
          isPassed: payload.isPassed,
          theoreticalGrade: payload.theoreticalGrade,
          practicalGrade: payload.practicalGrade,
          failReasonType: payload.failReasonType,
          failReason: payload.failReason,
          attendance: payload.attendance
        }
      );

const excludeEmployee = ({
  employeeId,
  eventId,
  trainingCenterId,
  ...payload
}: ExcludeEmployeePayload) =>
  axios.put(
    `/api/training-center/${trainingCenterId}/event/${eventId}/employee/${employeeId}/exclude`,
    payload
  );

const setEventGrades = ({
  eventType,
  isCommissioning = false,
  trainingCenterId,
  eventId,
  grades
}: {
  eventType: EventType;
  isCommissioning?: boolean;
  trainingCenterId: number;
  eventId: number;
  grades: {
    employeeId: number;
    isPassed?: EventEmployeeGradeType;
    competencyLevel?: CompetencyLevel;
    theoreticalStatus?: 1 | 0;
    practicalStatus?: 1 | 0;
    theoreticalGrade?: number;
    practicalGrade?: number;
    failReasonType?: FailReasonType;
    failReason?: string;
    recommendation: CommissioningEventRecommendation;
  }[];
}) => {
  const resourceUrlPrefix = `/api/training-center/${trainingCenterId}/event/${eventId}/grades`;

  if (isCommissioning) {
    const payload = grades.map((g) => ({
      employeeId: g.employeeId,
      theoreticalGrade: g.theoreticalGrade,
      theoreticalStatus: g.theoreticalStatus,
      practicalStatus: g.practicalStatus,
      practicalGrade: g.practicalGrade,
      isPassed: g.isPassed,
      competencyLevel: g.competencyLevel,
      failReasonType: g.failReasonType,
      failReason: g.failReason,
      recommendation: g.recommendation
    }));

    switch (eventType) {
      case EventType.ASSESSMENT:
        return axios.post(
          resourceUrlPrefix + "/commissioning/assessment",
          payload
        );

      case EventType.TRAINING:
        return axios.post(
          resourceUrlPrefix + "/commissioning/training",
          payload
        );

      default:
        return Promise.reject("Bad event parameters");
    }
  } else {
    let payload = [];

    switch (eventType) {
      case EventType.ASSESSMENT:
        payload = grades.map((g) => ({
          employeeId: g.employeeId,
          theoreticalGrade: g.theoreticalGrade,
          practicalGrade: g.practicalGrade,
          isPassed: g.isPassed,
          failReasonType: g.failReasonType,
          failReason: g.failReason
        }));

        return axios.post(resourceUrlPrefix + "/assessment", payload);

      case EventType.TRAINING:
        payload = grades.map((g) => ({
          employeeId: g.employeeId,
          theoreticalGrade: g.theoreticalGrade,
          practicalGrade: g.practicalGrade,
          isPassed: g.isPassed,
          failReasonType: g.failReasonType,
          failReason: g.failReason
        }));

        return axios.post(resourceUrlPrefix + "/training", payload);

      case EventType.OJT_TRAINING:
        payload = grades.map((g) => ({
          employeeId: g.employeeId,
          isPassed: g.isPassed
        }));

        return axios.post(resourceUrlPrefix + "/ojt-training", payload);

      case EventType.PRESCREENING:
        payload = grades.map((g) => ({
          employeeId: g.employeeId,
          isPassed: g.isPassed,
          theoreticalGrade: g.theoreticalGrade,
          practicalGrade: g.practicalGrade,
          failReasonType: g.failReasonType,
          failReason: g.failReason
        }));

        return axios.post(resourceUrlPrefix + "/pre-screening", payload);

      default:
        return Promise.reject("Bad event parameters");
    }
  }
};

const setEventInstructor = ({
  trainingCenterId,
  eventId,
  instructorId
}: {
  trainingCenterId: number;
  eventId: number;
  instructorId: number;
}) =>
  axios.put(
    `/api/training-center/${trainingCenterId}/event/${eventId}/instructor/${instructorId}`
  );

const getContractorEventAttendance = ({
  contractorId,
  eventId
}: {
  contractorId: number;
  eventId: number;
}) => axios.get(`/api/contractor/${contractorId}/event/${eventId}/attendance`);

const addTCFeedback = ({
  trainingCenterId,
  eventId,
  feedback
}: {
  trainingCenterId: number;
  eventId: number;
  feedback: string;
}) =>
  axios.post(
    `/api/training-center/${trainingCenterId}/event/${eventId}/feedback`,
    {
      feedback
    }
  );

const addContractorFeedback = ({
  contractorId,
  eventId,
  feedback
}: {
  contractorId: number;
  eventId: number;
  feedback: string;
}) =>
  axios.post(`/api/contractor/${contractorId}/event/${eventId}/feedback`, {
    feedback
  });

const updateEventGrades = ({
  grades,
  trainingCenterId,
  eventId
}: {
  grades: {
    employeeGradeId: number;
    practicalGrade: number;
    practicalStatus: number;
  }[];
  trainingCenterId: number;
  eventId: number;
}) =>
  axios.put(
    `/api/training-center/${trainingCenterId}/event/${eventId}/grades`,
    grades
  );

export const eventApi = {
  getTcEventPage,
  getContractorEventPage,
  createEvent,
  editEvent,
  getEventData,
  getEventAttendance,
  setSubcontractor,
  contractorApplyEvent,
  adminApplyEvent,
  getTcEventEmployees,
  getTcEventContractors,
  tcCancelEvent,
  getTcEventGradeEmployees,
  getContractorEventEmployees,
  setEventInstructor,
  getTrainingCenterEventDetails,
  getContractorEventDetails,
  getTrainingCenterEventAttendance,
  setTrainingCenterEventAttendance,
  preMatureEvaluate,
  excludeEmployee,
  setEventGrades,
  updateEventGrades,
  getContractorEventContractors,
  getEventPage,
  getContractorEventAttendance,
  addTCFeedback,
  addContractorFeedback
};
