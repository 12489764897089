import { useTranslation } from "react-i18next";

import format from "date-fns/format";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";

type Formats =
  | "full-date"
  | "short-date"
  | "full-datetime"
  | "short-datetime"
  | "time";
const formatMap = {
  "full-date": "d MMMM yyyy",
  "short-date": "d MMM yyyy",
  "full-datetime": "d MMMM yyyy, HH:mm",
  "short-datetime": "d MMM yyyy, HH:mm",
  time: "HH:mm"
};

const useDateFormatter = () => {
  const { i18n } = useTranslation();

  const dateFormatConfig = {
    locale: i18n.language === "ru" ? ruLocale : enLocale
  };
  const formatter = (date: string, dateFormat: Formats = "short-datetime") =>
    format(
      date && date.length ? new Date(date) : new Date(),
      formatMap[dateFormat],
      dateFormatConfig
    );

  return {
    formatter
  };
};

export default useDateFormatter;
