export const ruSubcontractorWhatNew = [
  {
    title:
      "Новая функция: добавлено поле «локация» для всех типов событий: в отчетах, в фильтрах отчетов.",
    html: `<p><span style="font-weight: 400;">Теперь пользователи могут указать место проведения мероприятия, в том числе &ldquo;Онлайн&rdquo; и &ldquo;Тенгиз&rdquo;. Не путайте с городом учебного центра.</span></p>`
  },
  {
    title:
      "Новая функция: Профили кандидатов. \n" +
      "\n" +
      "Доступно для ТШО, подрядчиков, субподрядчиков и пользователей учебного центра.\n",
    html: `<p><span style="font-weight: 400;">Теперь есть возможность просматривать, &laquo;нанимать&raquo;, &laquo;увольнять&raquo;, скачивать профили местных безработных, выпускников событий Учебных центров в рамках программы КСО ТШО.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">На вкладке &laquo;Профиль&raquo; отображаются профили людей, ищущих работу, которых пользователи подрядчиков/ субподрядчиков могут просмотреть и нанять на работу. </span></p>`
  },
  {
    title: "Изменения в отчетности, в рамках нового функционала.",
    html: `<p><span style="font-weight: 400;">В отчеты добавлены новые функции и другие небольшие изменения.</span></p>`
  },
  {
    title:
      "Изменения в квалификационных требованиях: отменено правило 1- месяца для работников для повторного участия в мероприятии. Теперь работники могут ",
    html: `<p><span style="font-weight: 400;">С этим изменением работникам не нужно ждать истечения одного месяца, чтобы повторно подать заявку на участие в том же типе и дисциплине мероприятия.</span></p>`
  }
];
