export const ruCraftProfiles = {
  filters: {
    iin: "ИИН",
    disciplines: "Дисциплины",
    selectDisciplines: "Выберите дисциплины",
    trainingCenters: "Тренинг Центры",
    selectTrainingCenters: "Выберите тренинг центры",
    contractors: "Подрядчики",
    clearFilters: "Очистить фильтры",
    csrContractors: "Подрядчики КСО",
    csrPhase: "Фаза КСО",
    csrPhases: "Фазы КСО",
    sortBy: "Сортировка",
    phase: "Фаза {{no}}",
    employed: "Трудоустроен(-а)",
    unemployed: "В поиске работы",
    status: "Статус сотрудника"
  },

  downloadProfilesData: "Скачать отчет по профилям",

  profile: "Профиль",
  profiles: "Профили",
  actions: "Действия",
  hire: "Наём",
  hireAction: "Нанять",
  hireConfirmation: "Вы действительно хотите нанять сотрудника {{name}}?",
  hireSuccess: "{{name}} успешно нанят",

  fire: "Увольнение",
  fireAction: "Уволить",
  fireConfirmation: "Вы действительно хотите уволить сотрудника {{name}}",
  fireSuccess: "{{name}} успешно уволен",

  name: "Имя (ФИО)",
  fullName: "Полное имя",
  csr: "Подрядчик КСО",
  discipline: "Дисциплина",
  address: "Адрес",
  email: "Электронная почта",
  number: "Номер телефона",
  dateOfBirth: "Дата рождения",
  martialStatus: "Семейное положение",
  education: "Образование",
  additionalEducation: "Дополнительные курсы",
  workExperience: "Рабочий стаж",
  language: "Знание языков",
  languages: "Знание языков",
  personalQualities: "Личные качества",
  additionalInformation: "Дополнительные сведения",
  status: "Статус",
  unEmployed: "Не трудоустроен",
  cancelHire: "Уволить",
  cancelHireText:
    "Вы уверены, что хотите изменить статус профиля на 'не трудоустроен'?",
  employed: "Трудоустроен(-а)",
  unemployed: "В поиске работы",
  notIndicated: "не указана",

  csrContractor: "Подрядчик КСО",
  csrPhase: "Фаза КСО",
  sortBy: "Сортировка",
  phase: "Фаза {{no}}",
  otherContractors: "Другие",

  marital: {
    0: "-",
    1: "Замужем/Женат",
    2: "Не женат/Не замужем",
    3: "Вдовец/Вдова",
    4: "Разведен/Разведена"
  },

  professionNA: "Профессия не указана",
  employerNA: "Работодатель не указан",

  createProfile: "Создать Профиль",
  createProfileSuccess: "Профиль успешно создан",

  editProfile: "Редактировать Профиль",
  editCraftProfileSuccess: "Профиль успешно отредактирован",

  deleteProfile: "Удалить Профиль",
  deleteProfileConfirm: "Вы уверены, что хотите удалить профиль {{name}}?",

  findEmployee: "Найти сотрудника",
  fillProfileInfo: "Заполнить информацию",

  uploadImage: "Фото пользователя",
  clickToUpload: "Загрузить",

  maritalStatusOptions: {
    1: "Женат/Замужем",
    2: "Не женат/Не замужем",
    3: "Вдовец/Вдова",
    4: "Разведен(-а)"
  },

  csrContractorOptions: {
    1: "КСО Атырау",
    2: "КСО Актау",
    3: "Demob"
  },

  employmentStatusOptions: {
    1: "Трудоустроен(-а)",
    2: "В поиске работы"
  },

  searchByIinSuccess: "Сотрудник найден по указанному ИИН-у",

  knownLanguagesOptions: {
    1: "Русский",
    2: "Английский",
    3: "Казахский",
    4: "Китайский",
    5: "Французский",
    6: "Немецкий",
    7: "Итальянский",
    8: "Турекций"
  },

  allProfiles: "Все профили",
  hiredProfiles: "Профили трудоустроенных"
};
