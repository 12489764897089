import { PERMISSIONS } from "@models/permissions";
import { PermissionGroup } from "@models/permissionsGroup";

export const TRAINING_CENTER_PERMISSION_OPTION_LIST: PermissionGroup[] = [
  {
    values: [PERMISSIONS.REPORT_VIEW],
    label: "reportView",
    dependentPermissions: [
      {
        values: [PERMISSIONS.REPORT_DOWNLOAD, PERMISSIONS.REPORT_DOWNLOAD_DUMP],
        label: "reportDownload"
      }
    ]
  },
  {
    values: [PERMISSIONS.MEMBER_VIEW],
    label: "memberView",
    dependentPermissions: [
      {
        values: [
          PERMISSIONS.MEMBER_INVITE,
          PERMISSIONS.MEMBER_UPDATE,
          PERMISSIONS.MEMBER_DELETE
        ],
        label: "memberEdit"
      }
    ]
  },
  {
    values: [PERMISSIONS.EVENT_VIEW],
    label: "eventView",
    dependentPermissions: [
      {
        values: [
          PERMISSIONS.EVENT_CREATE,
          PERMISSIONS.EVENT_UPDATE,
          PERMISSIONS.EVENT_CANCEL
        ],
        label: "eventEdit"
      }
    ]
  },
  {
    values: [PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_VIEW],
    label: "trainingCenterInstructorView",
    dependentPermissions: [
      {
        values: [
          PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_CREATE,
          PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_UPDATE,
          PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_DELETE
        ],
        label: "trainingCenterInstructorEdit"
      }
    ]
  },
  {
    values: [PERMISSIONS.TICKET_VIEW],
    label: "ticketView",
    dependentPermissions: [
      {
        values: [PERMISSIONS.TICKET_REPLY],
        label: "ticketReply"
      }
    ]
  },
  {
    values: [PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW],
    label: "craftEmployeeView"
  },
  {
    values: [PERMISSIONS.TRAINING_CENTER_REPORT_VIEW],
    label: "tcReportView"
  },
  {
    values: [PERMISSIONS.TRAINING_CENTER_REPORT_CREATE],
    label: "tcReportCreate"
  }
];
