import { useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { Typography } from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { EventPaginator } from "@components/common/events/EventPaginator";
import { EventFilterForm } from "@components/common/events/EventFilterForm";

import { EventFilter, EventStatus } from "@models/event";

import { eventApi } from "@api/eventApi";
import { disciplineApi } from "@api/disciplineApi";
import { countryCityApi } from "@api/countryCityApi";
import { contractorsApi } from "@api/contractorsApi";
import { trainingCenterApi } from "@api/trainingCenterApi";

import { useQueryParams } from "@hooks/useQueryParams";
import { usePagination } from "@hooks/usePagination";

interface Props {
  eventStatus?: EventStatus;
}

export const AdminEventPagination = (props: Props) => {
  const [t] = useTranslation();
  const qp = useQueryParams();

  const { page, setPage, setPagination } = usePagination();
  const [filters, setFilters] = useState<EventFilter>({
    cityIds: qp.cityIds,
    competencyLevel: qp.competencyLevel,
    csrPhases: qp.csrPhases,
    sortBy: qp.sortBy,
    contractorIds: qp.contractorIds,
    languageCode: qp.languageCode,
    dateEnd: qp.dateEnd,
    dateStart: qp.dateStart,
    eventIds: qp.eventIds,
    specialityIds: qp.specialityIds,
    type: qp.type,
    trainingCenterIds: qp.trainingCenterIds
  });

  const specialityList = useQuery("getTcSpecialityList", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const contractorList = useQuery("getContractors", () =>
    contractorsApi.getContractors().then((res) => res.data)
  );

  const tcList = useQuery("getTcs", () =>
    trainingCenterApi.getTcDictionary().then((res) => res.data)
  );

  const cityList = useQuery("getCities", () =>
    countryCityApi.getAllCities().then((res) => res.data)
  );

  const eventPage = useQuery(
    ["events", page, filters],
    () =>
      eventApi
        .getEventPage({
          status: props.eventStatus,
          page,
          ...filters
        })
        .then((res) => res.data),
    {
      onSuccess(res) {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterParams: filters
        };
        setPagination(pagination);
      }
    }
  );

  const pageTitle = props.eventStatus
    ? t("events.events") + " - " + t(`events.statuses.${props.eventStatus}`)
    : t("events.allEvents");

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={pageTitle}
        subTitle={
          <Typography.Text type="secondary">
            {t("total")}: {eventPage.data?.totalItems}
          </Typography.Text>
        }
        breadcrumbs={[
          {
            breadcrumbName: "CT",
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: ""
          }
        ]}
      />

      <EventFilterForm
        initialValues={filters}
        contractors={contractorList.data}
        disciplines={specialityList.data}
        trainingCenters={tcList.data}
        cities={cityList.data}
        isLoading={eventPage.isLoading}
        onSubmit={(v) => {
          setFilters(v);
          setPage(1);
        }}
      />

      <EventPaginator
        pageData={eventPage.data}
        disabled={eventPage.isLoading}
        linkTo={(ev) => `/admin/events/${ev.id}`}
        onChange={(nextPage) => {
          setPage(nextPage);
        }}
      />
    </>
  );
};
