import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Button, Card, Col, Row, Space, Tag, Tooltip, Typography } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { EventTypeTag } from "@components/common/events/EventTypeTag";

import { EventShortDetails, EventStatus, EventType } from "@models/event";

import useDateFormatter from "@hooks/useDateFormat";

const ButtonBase = styled(Button)`
  padding: 0;
  font-weight: bold;
  font-size: 20px;
`;

const EventDataColumn: React.FC<{
  label: string;
  data: string | string[];
  span?: number;
}> = ({ label, data, span = 4 }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <Col span={span}>
      <Row align="middle">
        <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
          {label}
        </Typography.Text>
      </Row>

      <>
        <Typography.Text strong className="break-word">
          {Array.isArray(data)
            ? data.slice(0, isExpanded ? data.length : 1).join(", ")
            : data}
        </Typography.Text>
        {Array.isArray(data) && data.length > 1 && (
          <Tooltip
            title={isExpanded ? null : t("expand")}
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}
          >
            <Button
              size="small"
              icon={isExpanded ? <MinusOutlined /> : <PlusOutlined />}
              onClick={() => setIsExpanded(!isExpanded)}
              style={{ marginLeft: "10px", lineHeight: "1px", padding: 0 }}
            ></Button>
          </Tooltip>
        )}
      </>
    </Col>
  );
};

interface Props {
  event: EventShortDetails;
  linkTo?: (ev: EventShortDetails) => string;
  actionTitle?: string;
}

const EVENT_STATUS_COLOR_MAP = {
  [EventStatus.PENDING]: "#049aff",
  [EventStatus.INPROGRESS]: "#EBC341",
  [EventStatus.EVALUATED]: "#78909C",
  [EventStatus.COMPLETED]: "#3DC674",
  [EventStatus.CANCELLED]: "#ff4d4f",
  [EventStatus.ACTIVATED]: "#3DC674"
};

const EventCard: React.FC<Props> = ({
  event: eventData,
  actionTitle,
  linkTo
}) => {
  const { t, i18n } = useTranslation();
  const { formatter } = useDateFormatter();
  const eventMainData = [
    {
      label: t("events.discipline"),
      data: eventData.speciality
        ? i18n.language === "en"
          ? eventData.speciality.nameEn
          : eventData.speciality.nameRu
        : ""
    },
    {
      label: t("events.competencyLevel"),
      data:
        eventData.competencyLevel && !Number.isNaN(eventData.competencyLevel)
          ? t(`competencyLevels.${eventData.competencyLevel}`)
          : ""
    },
    {
      label: t("events.language"),
      data: !Number.isNaN(eventData.languageCode)
        ? t(`languages.${eventData.languageCode}`)
        : "—"
    },
    {
      label: t("events.location"),
      data: eventData.location
        ? i18n.language === "en"
          ? eventData.location.nameEn
          : eventData.location.nameRu
        : ""
    },
    {
      label: t("events.trainingCenter"),
      data: eventData.trainingCenter ? eventData.trainingCenter.name : ""
    },
    {
      label: t("contractors.contractors"),
      data: eventData.contractors
        ? eventData.contractors.map((c) => c.contractor.name)
        : "",
      span: 6
    }
  ];

  return (
    <Card
      style={{ width: "100%", marginBottom: "16px" }}
      bodyStyle={{ padding: 0 }}
    >
      <Row>
        <Col span={20}>
          <Row style={{ padding: "0 0 16px 24px" }}>
            <Col span={24}>
              <div style={{ textAlign: "center" }}>
                <Tag
                  color={EVENT_STATUS_COLOR_MAP[eventData.status]}
                  style={{ borderRadius: 0 }}
                >
                  {t(`events.statuses.${eventData.status}`)}
                </Tag>
              </div>
            </Col>

            <Col span={18}>
              <Typography.Title level={4}>
                <Space align="start" size="middle">
                  <EventTypeTag
                    eventType={eventData.type}
                    isCommissioning={eventData.isCommissioning}
                  />
                  {linkTo ? (
                    <Link to={linkTo(eventData)}>
                      <ButtonBase type="link">{eventData.name}</ButtonBase>
                    </Link>
                  ) : (
                    eventData.name
                  )}

                  <Typography.Text
                    style={{ fontSize: "12px", color: "#fb8c00" }}
                  >
                    ID: {eventData.id}
                  </Typography.Text>
                </Space>
              </Typography.Title>

              <Row wrap>
                {eventMainData
                  .filter((e) => !!e.data)
                  .map((e) => (
                    <EventDataColumn
                      key={e.label}
                      label={e.label}
                      data={e.data}
                      span={e.span}
                    />
                  ))}
              </Row>
            </Col>

            <Col span={6}>
              <Space
                direction="vertical"
                align="center"
                style={{ width: "100%", textAlign: "center" }}
              >
                <span>
                  {t(`fromToRange`, {
                    from: formatter(eventData.dateTimeStart, "short-date"),
                    to: formatter(eventData.dateTimeEnd, "short-date")
                  })}
                </span>

                {Boolean(eventData.capacityMax) && (
                  <span>
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "12px", marginRight: "5px" }}
                    >
                      {t("availableSeats")}
                    </Typography.Text>

                    <Typography.Text
                      style={{ fontSize: "14px", color: "#049aff" }}
                    >
                      {t("outOfCapacity", {
                        quantity:
                          eventData.capacityMax - eventData.capacityBusy,
                        capacity: eventData.capacityMax
                      })}
                    </Typography.Text>
                  </span>
                )}

                {linkTo ? (
                  <Link to={linkTo(eventData)}>
                    <Button type="primary">
                      {actionTitle ? actionTitle : t("events.participantList")}
                    </Button>
                  </Link>
                ) : (
                  <Button type="primary">
                    {actionTitle ? actionTitle : t("events.participantList")}
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Col>

        {eventData.type !== EventType.PRESCREENING && (
          <Col
            span={3}
            offset={1}
            style={{
              backgroundColor: "#e3f2fd",
              paddingRight: 8,
              paddingLeft: 8
            }}
          >
            <Row align="middle" justify="center" style={{ height: "100%" }}>
              <Col>
                <Space
                  direction="vertical"
                  align="center"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <Typography.Text strong>
                    {eventData.academicHours}
                  </Typography.Text>

                  <Typography.Text>{t("events.academicHours")}</Typography.Text>
                </Space>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default EventCard;
