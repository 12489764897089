export const ruContractors = {
  contractors: "Подрядчики",
  contractor: "Подрядчик",
  contractorName: "Название подрядчика",
  registerContractor: "Пригласить подрядчика",
  editContractor: "Редактировать подрядчика",
  companyInfo: "Данные о компании",
  supervisorEmail: "Email линейного менеджера",
  contractorDeleted: "Подрядчик удален",
  saveSuccess: "Подрядчик успешно сохранен",
  confirmDelete: "Вы уверены, что хотите удалить подрядчика: {{name}}?",

  subcontractors: "Субподрядчики",
  subcontractor: "Субподрядчик",
  registrationDate: "Дата регистрации",
  bin: "БИН",
  workPhoneNumber: "Рабочий номер телефона",
  country: "Страна",
  city: "Город",
  address: "Адрес",
  nonIINExport: "Скачать лог работников без ИИН"
};
