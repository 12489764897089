export const ruPermissions = {
  permissions: "Доступы",

  1: "Report view",
  2: "Report download",
  3: "Report download dump",

  4: "Member view",
  5: "Member invite",
  6: "Member update",
  7: "Member delete",
  8: "Member permission",
  9: "Member notification",

  10: "Contractor view",
  11: "Contractor create",
  12: "Contractor update",
  13: "Contractor delete",

  14: "Contract create",
  15: "Contract view",
  16: "Contract update",
  17: "Contract delete",

  18: "Speciality create",
  19: "Speciality view",
  20: "Speciality update",
  21: "Speciality delete",

  22: "Training center view",
  23: "Training center create",
  24: "Training center update",
  25: "Training center delete",

  26: "Event view",
  27: "Event update",
  28: "Event cancel",
  29: "Event apply",
  30: "Event create",

  31: "Event details download",

  32: "Contractor employee reject",
  33: "Contractor employee view",
  34: "Contractor employee update",
  35: "Contractor employee create",
  36: "Contractor employee delete",

  37: "Certificate create",
  38: "Certificate update",
  39: "Certificate delete",
  40: "Certificate view",
  41: "Certificate download",

  42: "Permission view",
  43: "Notificate view",

  44: "Non IIN craft employee update",

  45: "Training center report view",

  46: "Training center instructor view",
  47: "Training center instructor create",
  48: "Training center instructor update",
  49: "Training center instructor delete",

  50: "Ticket create",
  51: "Ticket view",
  52: "Ticket reply",

  53: "City create",
  54: "Country create",

  55: "Currency edit",

  64: "EventDataUploadView",
  65: "EventDataUploadEdit",

  66: "DisciplineView",
  67: "DisciplineEdit",
  61: "Event delete",

  // displayed permissions

  reportView: "Отчеты - просмотр",
  reportDownload: "Отчеты - скачивание исходных данных",
  reportDownloadDump: "Отчеты - скачивание исходных данных",
  memberView: "Сотрудники - просмотр",
  memberEdit: "Сотрудники - редактирование",
  eventView: "События - просмотр",
  eventApply: "События - запись",
  eventDownload: "События - скачивание",
  eventEdit: "События - редактирование",
  eventDataUploadView: "Загрузка данных о событии - просмотр",
  eventDataUploadEdit: "Загрузка данных о событии - редактирование",
  trainingCenterView: "Тренинг-центры - просмотр",
  trainingCenterEdit: "Тренинг-центры - редактирование",
  contractorView: "Подрядчики - просмотр",
  contractorEdit: "Подрядчики - редактирование",
  certificateView: "Сертификаты - просмотр",
  certificateDownload: "Сертификаты - скачивание",
  craftEmployeeView: "Крафт работники - просмотр",
  craftEmployeeEdit: "Крафт работники - редактирование",
  tcReportView: "Отчеты ТЦ - просмотр",
  tcReportCreate: "Отчеты ТЦ - создание",
  trainingCenterInstructorView: "Инструкторы - просмотр",
  trainingCenterInstructorEdit: "Инструкторы - редактирование",
  specialityView: "Дисциплины - просмотр",
  specialityEdit: "Дисциплины - редактирование",
  ticketView: "Запросы - просмотр",
  ticketReply: "Запросы - отклик",
  ticketCreate: "Запросы - создание",
  subcontractorMemberView: "Субподрядчики - просмотр сотрудников",
  subcontractorMemberEdit: "Субподрядчики - редактирование сотрудников",
  disciplineView: "Новая дисциплина - просмотр",
  disciplineEdit: "Новая дисциплина - редактирование",
  eventDelete: "Удаление события - редактирование",
  eventActivate: "Активировать событие"
};
