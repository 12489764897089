export const enSubcontractors = {
  subcontractor: "Subcontractor",
  subcontractors: "Subcontractors",

  registerSubcontractor: "Register subcontractor",

  name: "Name",
  city: "City",
  address: "Address",
  workPhoneNumber: "Work phone number",
  contract: "Contract",
  contracts: "Contracts",
  email: "Email",

  firstName: "First name",
  lastName: "Last name",
  jobTitle: "Job title",

  register: "Register",

  registrationSuccess: "Subcontractor successfully registered",

  accessLevel: "Access level",

  confirmDelete: "Are you sure you want to delete subcontractor: {{name}}?",
  subcontractorDeleted: "Subcontractor deleted",
  members: "Members",

  editSuccess: "Subcontractor successfully saved",
  editSubcontractor: "Edit subcontractor"
};
