import moment, { Moment } from "moment";

import {
  EventAttendance,
  EventDetails,
  EventEmployeeDetails,
  FormattedEventAttendance
} from "@models/event";

const API_SHORT_DATE_FORMAT = "DD-MM-YYYY";

/**
 * Generates an array of given length
 *
 * @param length
 * @param fillValue
 * @returns
 */
export const getEmptyArray = (length: number, fillValue: any = null) =>
  length > 0 ? new Array(length).fill(fillValue) : [];

/**
 * Sorts event employees with evaluated and excluded employees at the bottom
 *
 * @param employees EventEmployeeDetails[]
 * @returns sorted list of employees
 */
export const sortEventEmployees = (employees: EventEmployeeDetails[]) =>
  employees.sort((eA, eB) => {
    const eAExcludedOrEvaluated = !!eA.excludedAt || !!eA.evaluatedAt;
    const eBExcludedOrEvaluated = !!eB.excludedAt || !!eB.evaluatedAt;

    if (eAExcludedOrEvaluated && !eBExcludedOrEvaluated) {
      return 1;
    }
    if (!eAExcludedOrEvaluated && eBExcludedOrEvaluated) {
      return -1;
    }

    return 0;
  });

/**
 * Splits employees object into different categories:
 * - `evaluatedEmployees` – employees who have been prematurely evaluated
 * - `excludedEmployees`  – employees who have been prematurely excluded
 * - `availableEmployees` – employees whose attendance needs to be saved
 * - `sortedEmployees`       – employees sorted with `sortEventEmployees()` method
 *
 * @see sortEventEmployees()
 * @param employees
 * @returns
 */
export const getEmployeesByType = (employees: EventEmployeeDetails[]) => {
  return {
    evaluatedEmployees: employees.filter((e) => !!e.evaluatedAt),
    excludedEmployees: employees.filter((e) => !!e.excludedAt),
    availableEmployees: employees.filter(
      (e) => !e.excludedAt && !e.evaluatedAt
    ),
    sortedEmployees: sortEventEmployees(employees)
  };
};

/**
 * Formats dates in attendance array to Moment date object for further manipulations
 *
 * @param attendances
 * @returns
 */
export const formatAttendance = (
  attendances: EventAttendance[]
): FormattedEventAttendance[] => {
  const formattedAttendances = attendances.map(({ employeeId, days }) => ({
    employeeId,
    days:
      days?.map(({ hours, date }) => ({
        hours,
        date: moment(date)
      })) || []
  }));

  return formattedAttendances;
};

/**
 * Counts number of unsaved days and returns an array filled up with dates
 * that needs to be filled in with attendance
 *
 * @param eventDetails              EventDetails object
 * @param unsavedEmployeeAttendance Saved attendance with Moment dates
 * @returns
 */
export const getUnsavedDays = (
  eventDetails: EventDetails,
  unsavedEmployeeAttendance: FormattedEventAttendance | undefined
) => {
  const dateStart = eventDetails.dateTimeStart.split("T")[0];
  const dateEnd = eventDetails.dateTimeEnd.split("T")[0];
  const dateTimeStart = moment(dateStart).startOf("day");
  const dateTimeEnd = moment(dateEnd).endOf("day");

  const today = moment().startOf("day");

  const lastSavedDay = unsavedEmployeeAttendance
    ? unsavedEmployeeAttendance.days
        .map(({ date }) => date)
        .reduce((prevDate: Moment, curDate) => {
          if (prevDate) {
            return prevDate.isBefore(curDate) ? curDate : prevDate;
          }
          return curDate;
        }, moment(dateTimeStart).subtract(1, "day"))
    : moment(dateTimeStart).subtract(1, "day");

  const firstUnsavedDay = moment(lastSavedDay).add(1, "day");

  // Allowing users to save attendance until today or last day of event
  const lastUnsavedDay = dateTimeEnd.isAfter(today) ? today : dateTimeEnd;

  // Number of days that needs to be saved
  const unsavedDaysCount = Math.round(
    lastUnsavedDay
      .endOf("day")
      .diff(firstUnsavedDay.startOf("day").startOf("day"), "days", true)
  );

  const unsavedDays = getEmptyArray(unsavedDaysCount).map((_, i) =>
    moment(firstUnsavedDay).add(i, "day").format(API_SHORT_DATE_FORMAT)
  );

  return unsavedDays;
};

interface AttendancePayload {
  daysOff: string[];
  attendances: EventAttendance[];
}

/**
 * Removes dates from employees' attendances that are listed in `daysOff` array
 * and formats dates for API
 *
 * @param formValues AttendancePayload
 * @returns
 */
export const prepareAttendancePayload = (
  formValues: AttendancePayload
): AttendancePayload => {
  const formatDate = (date: string) =>
    moment(date, "DD-MM-YYYY").endOf("day").subtract(11, "hour").format();

  return {
    daysOff: formValues.daysOff.map((day) => formatDate(day)),
    attendances: formValues.attendances.map(({ employeeId, days }) => ({
      employeeId,
      days: days
        .filter(({ date }) => !formValues.daysOff.includes(date))
        .map(({ hours, date }) => ({
          hours,
          date: formatDate(date)
        }))
    }))
  };
};

/**
 * Formats date to event timezone and subtracts 11 hours down to middle of the day to prevent timezone issues between Atyrau and Almaty
 *
 * @param date
 * @param eventDate
 * @returns
 */
export const convertDateToEventTimezone = (date: string, eventDate: string) => {
  const SHORT_DATE_FORMAT = "DD-MM-YYYY";
  const eventTimezone = eventDate.substr(eventDate.length - 6);

  const parsedDate = moment(date, SHORT_DATE_FORMAT)
    .endOf("day")
    .subtract(11, "hour")
    .format();
  return parsedDate.substr(0, parsedDate.length - 6) + eventTimezone;
};
