import { useContext, useEffect } from "react";
import { useHistory } from "react-router";

import { Content } from "antd/lib/layout/layout";

import { Spin } from "antd";

import { ssoLogin } from "@api/userApi";

import { useQueryParams } from "@hooks/useQueryParams";
import { UserContext } from "@contexts/UserContext";

const SSOAuthPage = () => {
  const queryParams = useQueryParams();
  const redirectTo = queryParams?.redirectTo || "/profile";
  const history = useHistory();
  const user = useContext(UserContext);

  useEffect(() => {
    if (queryParams.code !== null && typeof queryParams.code === "string") {
      ssoLogin(queryParams.code);
      user.loadUser().then(() => {
        history.replace(redirectTo);
      });
    } else if (!queryParams.code) {
      history.replace("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.code]);

  return (
    <Content
      style={{
        padding: 24,
        margin: 0,
        minHeight: 280,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch"
      }}
    >
      <Spin />
    </Content>
  );
};

export default SSOAuthPage;
