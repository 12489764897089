import { initReactI18next } from "react-i18next";

import i18n from "i18next";

import { enTranslation } from "./en/translation";
import { ruTranslation } from "./ru/translation";

const resources = {
  en: enTranslation,
  ru: ruTranslation
};

const persistedLng = localStorage.getItem("CT_PORTAL_LNG");

i18n.use(initReactI18next).init({
  resources,
  lng: persistedLng || "ru",
  fallbackLng: "ru"
});

i18n.on("languageChanged", (lng) => {
  localStorage.setItem("CT_PORTAL_LNG", lng);
});

export default i18n;
