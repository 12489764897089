import React from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import styled from "styled-components";

import { Button, Col, Form, Input, Row, Select, Typography } from "antd";

import { CompetencyLevel } from "@models/competencyLevel";
import { Discipline } from "@models/discipline";

const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;

interface FormValues {
  iin?: string;
  firstName?: string;
  lastName?: string;
  specialityIds?: number[];
  competencyLevel?: number;
}

interface Props {
  initialValues?: FormValues;
  disciplines?: Discipline[];
  onSubmit?: (values: FormValues) => void;
}

const COMPETENCY_LEVEL_OPTIONS: { label: string; value: number }[] = [
  {
    label: `competencyLevels.${CompetencyLevel.BASIC}`,
    value: CompetencyLevel.BASIC
  },
  {
    label: `competencyLevels.${CompetencyLevel.COMPETENT}`,
    value: CompetencyLevel.COMPETENT
  },
  {
    label: `competencyLevels.${CompetencyLevel.ADVANCED}`,
    value: CompetencyLevel.ADVANCED
  },
  {
    label: `competencyLevels.${CompetencyLevel.INTERMEDIATE}`,
    value: CompetencyLevel.INTERMEDIATE
  },
  {
    label: `competencyLevels.${CompetencyLevel.PROFICIENT}`,
    value: CompetencyLevel.PROFICIENT
  },
  {
    label: `competencyLevels.${CompetencyLevel.HELPER}`,
    value: CompetencyLevel.HELPER
  },
  {
    label: `competencyLevels.${CompetencyLevel.TECHNICIAN}`,
    value: CompetencyLevel.TECHNICIAN
  },
  {
    label: `competencyLevels.${CompetencyLevel.JUNIOR_TECHNICIAN}`,
    value: CompetencyLevel.JUNIOR_TECHNICIAN
  },
  {
    label: `competencyLevels.${CompetencyLevel.TRAINEE_TECHNICIAN}`,
    value: CompetencyLevel.TRAINEE_TECHNICIAN
  },
  {
    label: `competencyLevels.${CompetencyLevel.SENIOR_TECHNICIAN}`,
    value: CompetencyLevel.SENIOR_TECHNICIAN
  }
];

export const EmployeeFilterForm = (props: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      iin: props.initialValues?.iin,
      firstName: props.initialValues?.firstName,
      lastName: props.initialValues?.lastName,
      competencyLevel: props.initialValues?.competencyLevel,
      specialityIds: props.initialValues?.specialityIds
    },
    onSubmit(values) {
      props.onSubmit && props.onSubmit(values);
    }
  });

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8} align="middle" style={{ marginBottom: 16 }}>
        <Col xs={24}>
          <Typography.Title level={5}>{t("employees.search")}</Typography.Title>
        </Col>

        <Col md={5}>
          <Form.Item
            label={t("employees.iin")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.iin && "error"}
            help={formik.errors.iin}
          >
            <Input
              name="iin"
              value={formik.values.iin}
              onChange={formik.handleChange}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.firstName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.firstName && "error"}
            help={formik.errors.firstName}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.lastName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.lastName && "error"}
            help={formik.errors.lastName}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col md={3}>
          <ButtonStyled type="primary" block htmlType="submit">
            {t("search")}
          </ButtonStyled>
        </Col>

        <Col xs={24}>
          <Typography.Title level={5}>
            {t("employees.filtering")}
          </Typography.Title>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("disciplines.discipline")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.specialityIds && "error"}
            help={formik.errors.specialityIds}
          >
            <Select
              mode="multiple"
              showArrow
              showSearch
              allowClear
              optionFilterProp="nameRu"
              filterOption={(inp, opt) => {
                if (opt && opt.children) {
                  return (
                    opt.children
                      .toString()
                      .toLowerCase()
                      .indexOf(inp.toLowerCase()) >= 0
                  );
                }

                return false;
              }}
              value={formik.values.specialityIds || undefined}
              onChange={(v) => {
                formik.setFieldValue("specialityIds", v);
                formik.submitForm();
              }}
            >
              {props.disciplines?.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.nameEn}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("competencyLevels.competencyLevel")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.competencyLevel && "error"}
            help={formik.errors.competencyLevel}
          >
            <Select
              allowClear
              value={formik.values.competencyLevel || undefined}
              onChange={(v) => {
                formik.setFieldValue("competencyLevel", v);
                formik.submitForm();
              }}
            >
              {COMPETENCY_LEVEL_OPTIONS.map((co) => (
                <Select.Option key={co.value} value={co.value}>
                  {t(co.label)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
