import { useCallback, useRef } from "react";

interface IdleCheckerProps {
  idleTimeOutSeconds: number;
  onTimeOut: Function;
}

function setIdleCheck({ idleTimeOutSeconds, onTimeOut }: IdleCheckerProps) {
  return () => {
    const idleStartTime = localStorage.getItem("CT_PORTAL_IDLE_START_TIME");
    const elapsedIdleTimeSeconds = Math.round(
      (Date.now() - Number(idleStartTime)) / 1000
    );

    if (elapsedIdleTimeSeconds > idleTimeOutSeconds) {
      onTimeOut();
      localStorage.removeItem("CT_PORTAL_IDLE_START_TIME");
    }
  };
}

function idleReset() {
  localStorage.setItem("CT_PORTAL_IDLE_START_TIME", Date.now().toString());
}

interface UseIdleCheckerProps extends IdleCheckerProps {
  checkEvery: number;
}
export function useIdleChecker({
  checkEvery,
  ...idleCheckerProps
}: UseIdleCheckerProps) {
  const isIdleReseting = useRef<boolean>(false);
  const timeout = useRef<number | undefined>();

  const tryIdleReset = useCallback(() => {
    if (!isIdleReseting.current) {
      isIdleReseting.current = true;

      timeout.current = window.setTimeout(() => {
        idleReset();
        isIdleReseting.current = false;
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdleReseting.current]);

  return {
    subscribeToIdleChecker: () => {
      idleReset();

      const idleCheckerId = window.setInterval(
        setIdleCheck(idleCheckerProps),
        checkEvery
      );

      window.addEventListener("mousemove", tryIdleReset);
      window.addEventListener("mousedown", tryIdleReset);
      window.addEventListener("keypress", tryIdleReset);
      window.addEventListener("scroll", tryIdleReset);

      return () => {
        window.clearInterval(idleCheckerId);
        window.clearTimeout(timeout.current);

        window.removeEventListener("mousemove", tryIdleReset);
        window.removeEventListener("mousedown", tryIdleReset);
        window.removeEventListener("keypress", tryIdleReset);
        window.removeEventListener("scroll", tryIdleReset);
      };
    }
  };
}
