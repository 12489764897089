import axios, { AxiosResponse } from "axios";

const updateContractorMember = ({
  contractorId,
  memberId,
  payload
}: {
  contractorId: number;
  memberId: number;
  payload: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    position: string;
    permissions: number[];
  };
}) =>
  axios.post(
    `/api/admin/contractor/${contractorId}/member/${memberId}`,
    payload
  );

interface ActivateContractorMemberParams {
  contractorId: number;
  memberId: number;
}
const activateContractorMember: (
  params: ActivateContractorMemberParams
) => Promise<AxiosResponse> = ({ contractorId, memberId }) =>
  axios.get(
    `/api/admin/member/${memberId}/contractor/${contractorId}/activate`
  );

const resendContractorMemberInvite = (contractorId: number, email: string) =>
  axios.get(
    `/api/admin/contractor/${contractorId}/contractor/invite/?email=${email}`
  );

export const adminContractorApi = {
  updateContractorMember,
  activateContractorMember,
  resendContractorMemberInvite
};
