export const ruProfile = {
  profile: "Учетная запись",

  changePassword: "Сменить пароль",

  currentPassword: "Текущий пароль",
  newPassword: "Новый пароль",
  confirmNewPassword: "Подтверждение нового пароля",

  editProfile: "Редактировать профиль",
  firstName: "Имя",
  lastName: "Фамилия",

  change: "Поменять",
  cancel: "Отменить",

  passwordChangeSuccess: "Пароль успешно изменен",
  profileEditSuccess: "Профиль успешно обновлён"
};
