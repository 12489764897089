import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { EventDeleteForm } from "@components/common/events/forms/EventDeleteForm";

import { EventDetails } from "@models/event";

import { eventsApi } from "@api/eventsApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

interface Props {
  event: EventDetails;
  onEdit?: () => void;
}

const EventDeleteModal: React.FC<Props> = ({ event, onEdit }) => {
  const [t] = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteEventMutation = useValidatedMutation({
    mutationFunction: () =>
      eventsApi.adminDeleteEvent({
        eventId: event.id
      }),
    onSuccess() {
      notification.success({
        message: t("events.deleteComplete", {
          name: event.name,
          date: event.dateTimeStart
        })
      });

      setIsDeleting(false);

      onEdit && onEdit();
    }
  });
  const validationErrors = useMemo(
    () => transformValidationError(deleteEventMutation.error),
    [deleteEventMutation.error]
  );

  return (
    <>
      <Modal
        visible={isDeleting}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("events.deleteEvent")}
        footer={null}
        width={400}
        onCancel={() => {
          setIsDeleting(false);
        }}
      >
        <EventDeleteForm
          event={event}
          isLoading={deleteEventMutation.isLoading}
          errors={validationErrors}
          onCancel={() => setIsDeleting(false)}
          onSubmit={(values) => {
            deleteEventMutation.mutate(values);
          }}
        />
      </Modal>

      <Button
        danger
        type="primary"
        icon={<DeleteOutlined />}
        onClick={() => setIsDeleting(true)}
      >
        {t("events.deleteEvent")}
      </Button>
    </>
  );
};

export default EventDeleteModal;
