import axios from "axios";

import {
  Discipline,
  CreateDisciplinePayload,
  EditDisciplinePayload
} from "@models/discipline";

const sortDisciplines = (disciplines: Discipline[]) => {
  const lang = localStorage.getItem("CT_PORTAL_LNG");

  return disciplines.sort((a, b) => {
    const nameA = (lang === "en" ? a.nameEn : a.nameRu).trim();
    const nameB = (lang === "en" ? b.nameEn : b.nameRu).trim();

    if (nameA > nameB) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }

    return 0;
  });
};

const getAllDisciplines = () =>
  axios.get<Discipline[]>("/api/speciality").then((response) => ({
    ...response,
    data: sortDisciplines(response.data)
  }));

const createDiscipline = (payload: CreateDisciplinePayload) =>
  axios.post<CreateDisciplinePayload>("api/speciality", payload);

const editDiscipline = (payload: EditDisciplinePayload, id: number) =>
  axios.post<EditDisciplinePayload>(`api/speciality/${id}`, payload);

const getTcSpecialities = (trainingCenterId: number) =>
  axios
    .get<Discipline[]>(`/api/training-center/${trainingCenterId}/specialities`)
    .then((response) => ({
      ...response,
      data: sortDisciplines(response.data)
    }));

export const disciplineApi = {
  getAllDisciplines,
  createDiscipline,
  editDiscipline,
  getTcSpecialities
};
