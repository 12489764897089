import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, InputNumber, Row, Space } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { Contract } from "@models/contract";

interface FormValues {
  contractorId?: number;
  contractId?: number;
  contractNumber: string;
  capacity?: number;
  projectId?: number;
  kcCategory?: number;
}

interface Props {
  contractorId?: number;
  contract?: Contract | null;
  projects: { id: number; name: string }[];
  kcCategories: { id: number; name: string }[];
  isLoading: boolean;
  errors: { [key: string]: string };
  onSubmit?: (values: FormValues) => void;
  onCancel: () => void;
}

const ContractForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const formik = useFormik<FormValues>({
    initialValues: {
      contractorId: props.contractorId,
      contractNumber: ""
    },
    onSubmit: (values) => {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  const projectOptions = props.projects.map((p) => ({
    value: p.id,
    label: p.name
  }));

  const kcCategoryOptions = props.kcCategories.map((c) => ({
    value: c.id,
    label: c.name
  }));

  useEffect(() => {
    if (props.contract) {
      formik.setValues({
        contractId: props.contract.id,
        contractNumber: props.contract.contractNumber,
        capacity: props.contract.capacity,
        kcCategory: props.contract.kcCategory,
        projectId: props.contract.projectId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contract]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label={t("contract.contractNumber")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.contractNumber && "error"}
            help={formik.errors.contractNumber}
          >
            <Input
              name="contractNumber"
              value={formik.values.contractNumber}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("contract.capacity")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.capacity && "error"}
            help={formik.errors.capacity}
          >
            <InputNumber
              style={{ width: "100%" }}
              name="capacity"
              value={formik.values.capacity}
              onChange={(values) => {
                const v = Number(values);
                if (!Number.isNaN(v)) {
                  formik.setFieldValue("capacity", v);
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("contract.project")}
            validateStatus={formik.errors.projectId && "error"}
            help={formik.errors.projectId}
          >
            <SearchSelect
              value={formik.values.projectId}
              options={projectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("projectId", value)
              }
              onClear={() => formik.setFieldValue("projectId", null)}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("contract.kcCatAndArea")}
            validateStatus={formik.errors.kcCategory && "error"}
            help={formik.errors.kcCategory}
          >
            <SearchSelect
              value={formik.values.kcCategory}
              options={kcCategoryOptions}
              onChange={(value: number) =>
                formik.setFieldValue("kcCategory", value)
              }
              onClear={() => formik.setFieldValue("kcCategory", null)}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("save")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ContractForm;
