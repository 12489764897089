import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";

import { AxiosError } from "axios";

import {
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  HomeOutlined,
  PhoneOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  List,
  Modal,
  notification,
  Popconfirm,
  Space,
  Tag
} from "antd";
import { MemberTable } from "@components/common/MemberTable";
import { EditSubcontractorMemberForm } from "@components/contractors/subcontractors/EditSubcontractorMemberForm";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";

import { Member, MemberStatus } from "@models/member";
import { PERMISSIONS } from "@models/permissions";
import { UpdateSubcontractorMemberPayload } from "@models/subcontractorMember";

import { subcontractorApi } from "@api/subcontractorApi";
import { subcontractorMemberApi } from "@api/subcontractorMemberApi";

import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

const gridStyle = {
  width: "50%"
};

export const SubcontractorDetailsPage = () => {
  const [t, i18n] = useTranslation();

  const { user, checkPermission } = useContext(UserContext);
  const contractorId = user?.userRoles[0].entityId || 0;

  const params = useParams<{ id: string }>();
  const subcontractorId = Number(params.id) || 0;

  const canUpdate = checkPermission(PERMISSIONS.SUBCONTRACTOR_MEMBER_UPDATE);
  const canDelete = checkPermission(PERMISSIONS.SUBCONTRACTOR_MEMBER_DELETE);

  const [editedMember, setEditedMember] = useState<Member | null>(null);

  const { data } = useQuery("getSubcontractor", () =>
    subcontractorApi
      .getSubcontractor({ contractorId, subcontractorId })
      .then((res) => res.data)
  );

  const memberList = useQuery("subcontractorMembers", () =>
    subcontractorMemberApi
      .getSubcontractorMembers({
        contractorId,
        subcontractorId
      })
      .then((res) => res.data)
  );

  const editSubconractorMemberMutation = useValidatedMutation({
    mutationFunction: ({
      memberId,
      member
    }: {
      memberId: number;
      member: UpdateSubcontractorMemberPayload;
    }) =>
      subcontractorMemberApi.updateSubcontractorMember({
        contractorId,
        subcontractorId,
        memberId,
        member
      }),
    onSuccess() {
      notification.success({
        message: t("members.editSuccess")
      });
      memberList.refetch();
      setEditedMember(null);
    }
  });
  const editErrors = useMemo(
    () => transformValidationError(editSubconractorMemberMutation.error),
    [editSubconractorMemberMutation.error]
  );

  const deleteSubcontractorMemberMutation = useMutation(
    (memberId: number) =>
      subcontractorMemberApi.deleteSubcontractorMember({
        contractorId,
        subcontractorId,
        memberId
      }),
    {
      onSuccess() {
        notification.success({ message: t("members.memberDeleted") });

        memberList.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const deleteSubcontractorMemberInviteMutation = useMutation(
    (email: string) =>
      subcontractorMemberApi.deleteSubcontractorMemberInvite({
        contractorId,
        subcontractorId,
        email
      }),
    {
      onSuccess() {
        notification.success({ message: t("members.inviteDeleted") });

        memberList.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const activeMembers = memberList.data?.filter(
    (m) => m.status === MemberStatus.ACTIVE
  );
  const invitedMembers = memberList.data?.filter(
    (m) => m.status === MemberStatus.INVITED
  );

  const pageTitle =
    data?.name || t("subcontractors.subcontractor") + " #" + subcontractorId;

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={data?.name}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "contractor" },
          {
            breadcrumbName: t("subcontractors.subcontractors"),
            path: "subcontractors"
          },
          {
            breadcrumbName: pageTitle,
            path: subcontractorId.toString()
          }
        ]}
      />

      <Modal
        visible={!!editedMember}
        destroyOnClose
        maskClosable={false}
        closable={false}
        footer={null}
        width={800}
        onCancel={() => setEditedMember(null)}
      >
        <EditSubcontractorMemberForm
          errors={editErrors}
          isLoading={editSubconractorMemberMutation.isLoading}
          subcontractorMember={editedMember || undefined}
          onCancel={() => {
            setEditedMember(null);
          }}
          onSubmit={(values) => {
            editSubconractorMemberMutation.mutate({
              memberId: editedMember?.userId,
              member: values
            });
          }}
        />
      </Modal>

      <Card style={{ maxWidth: 800 }}>
        <Card.Grid style={gridStyle}>
          <List.Item>
            <List.Item.Meta
              avatar={<HomeOutlined />}
              title={data?.address}
              description={t("subcontractors.address")}
            />
          </List.Item>
        </Card.Grid>

        <Card.Grid style={gridStyle}>
          <List.Item>
            <List.Item.Meta
              avatar={<PhoneOutlined />}
              title={data?.phone || "-"}
              description={t("subcontractors.workPhoneNumber")}
            />
          </List.Item>
        </Card.Grid>

        <Card.Grid style={gridStyle}>
          <List.Item>
            <List.Item.Meta
              avatar={<EnvironmentOutlined />}
              title={
                i18n.language === "en" ? data?.city.nameEn : data?.city.nameRu
              }
              description={t("subcontractors.city")}
            />
          </List.Item>
        </Card.Grid>

        <Card.Grid style={gridStyle}>
          <List.Item>
            <List.Item.Meta
              avatar={<FileTextOutlined />}
              title={
                data?.contracts.length
                  ? data?.contracts?.map((c) => (
                      <Tag key={c.id}>{c.contractNumber}</Tag>
                    ))
                  : "-"
              }
              description={t("subcontractors.contracts")}
            />
          </List.Item>
        </Card.Grid>
      </Card>

      <br />

      <MemberTable
        title={t("members.activeMembers")}
        isLoading={memberList.isLoading}
        members={activeMembers || []}
        renderActions={(member: Member) => (
          <Space size="middle">
            <Button
              title={t("edit")}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              disabled={!canUpdate}
              onClick={() => {
                setEditedMember(member);
              }}
            />

            <Popconfirm
              title={t("members.confirmDelete", {
                name: member.firstName + " " + member.lastName
              })}
              cancelText={t("cancel")}
              okText={t("yes")}
              disabled={deleteSubcontractorMemberMutation.isLoading}
              onConfirm={() =>
                deleteSubcontractorMemberMutation.mutate(member.userId)
              }
            >
              <Button
                title={t("delete")}
                danger
                shape="circle"
                disabled={deleteSubcontractorMemberMutation.isLoading}
                loading={
                  deleteSubcontractorMemberMutation.isLoading &&
                  deleteSubcontractorMemberMutation.variables === member.userId
                }
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )}
      />

      <br />

      <MemberTable
        title={t("members.invitedMembers")}
        isLoading={memberList.isLoading}
        members={invitedMembers}
        renderActions={(member) => (
          <Space size="middle">
            <Popconfirm
              title={t("members.confirmDelete", {
                name: member.firstName + " " + member.lastName
              })}
              cancelText={t("cancel")}
              okText={t("yes")}
              disabled={
                !canDelete || deleteSubcontractorMemberInviteMutation.isLoading
              }
              onConfirm={() =>
                deleteSubcontractorMemberInviteMutation.mutate(member.email)
              }
            >
              <Button
                title={t("delete")}
                danger
                shape="circle"
                icon={<DeleteOutlined />}
                disabled={
                  !canDelete ||
                  deleteSubcontractorMemberInviteMutation.isLoading
                }
                loading={
                  deleteSubcontractorMemberInviteMutation.isLoading &&
                  deleteSubcontractorMemberInviteMutation.variables ===
                    member.email
                }
              />
            </Popconfirm>
          </Space>
        )}
      />
    </>
  );
};
