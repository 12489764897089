import picture15 from "@assets/images/user-guide/1.15.png";
import picture16 from "@assets/images/user-guide/1.16.png";
import picture17 from "@assets/images/user-guide/1.17.png";
import picture18 from "@assets/images/user-guide/1.18.png";
import picture19 from "@assets/images/user-guide/1.19.png";
import picture20 from "@assets/images/user-guide/1.20.png";
import picture21 from "@assets/images/user-guide/1.21.png";
import picture22 from "@assets/images/user-guide/1.22.png";
import picture23 from "@assets/images/user-guide/1.23.png";
import picture24 from "@assets/images/user-guide/1.24.png";

export const employees = [
  {
    title: "List of Craft-employees:",
    html: `<p dir="ltr">
    In order to see the list of Craft employees press on the Employees tab on
    Panel menu and select Craft.
</p>
<p dir="ltr">
    Picture 4.15
</p>
<p dir="ltr">
    <img
        src=${picture15}
        width="624"
        height="559"
    />
</p>
<br/>
<p dir="ltr">
    List of Craft-employees contains the following information:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Search by IIN
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by First Name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Last Name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Add Employee button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Upload Employees button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
</ol>
<p dir="ltr">
    Use filters to search Craft-worker and press on First Name Last Name to see
    Details.
</p>
`
  },
  {
    title: "Employee passport:",
    html: `<p dir="ltr">
    Employee Passport page contains two main blocks:
</p>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Craft-workers information block
        </p>
    </li>
</ul>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Personal information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Working information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Facility accesses information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Back to the list button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 4.16
</p>
<p dir="ltr">
    <img
        src=${picture16}
        width="624"
        height="393"
    />
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Training History block
        </p>
    </li>
</ul>
<p dir="ltr">
    Check training history of craft-workers.
</p>
<p dir="ltr">
    Picture 4.17
</p>
<p dir="ltr">
    <img
        src=${picture17}
        width="606"
        height="414"
    />
</p>
`
  },
  {
    title: "How to edit employee’s information?",
    html: `<p dir="ltr">
    Press on Pencil icon to edit
</p>
<p dir="ltr">
    Picture 4.18
</p>
<p dir="ltr">
    <img
        src=${picture18}
        width="624"
        height="191"
    />
</p>
<p dir="ltr">
    Picture 4.19
</p>
<p dir="ltr">
    <img
        src=${picture19}
        width="624"
        height="160"
    />
</p>
<p dir="ltr">
    Picture 4.20
</p>
<p dir="ltr">
    <img
        src=${picture20}
        width="624"
        height="129"
    />
</p>
`
  },
  {
    title: "How to add an employee?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Employees → Craft
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Add Employee button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out fields and press Add button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: Follow the steps and press Add button. Added employee will be
    displayed on the Employee list.
</p>
<p dir="ltr">
    Picture 4.21
</p>
<p dir="ltr">
    <img
        src=${picture21}
        width="624"
        height="389"
    />
</p>
<p dir="ltr">
    Picture 4.22
</p>
<p dir="ltr">
    <img
        src=${picture22}
        width="624"
        height="389"
    />
</p>
<p dir="ltr">
    Picture 4.23
</p>
<p dir="ltr">
    <img
        src=${picture23}
        width="624"
        height="391"
    />
</p>
`
  },
  {
    title: "How to add multiple employees?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Employees → Craft
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Upload Employees button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Upload file button and select file
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Check out uploaded employees data and press Submit button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: Uploaded file needs to be similar to template. You can download
    template file by pressing the Download template button on the Upload
    employees window.
</p>
<p dir="ltr">
    Picture 4.24
</p>
<p dir="ltr">
    <img
        src=${picture24}
        width="624"
        height="144"
    />
</p>
`
  }
];
