import { useContext, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import {
  Col,
  Row,
  Card,
  Descriptions,
  Typography,
  Space,
  notification,
  Empty,
  Table
} from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { OnlineTrainingReportDetailsTable } from "@components/common/reports/OnlineTrainingReportDetailsTable";
import EditOnlineTrainingReportModal from "@components/common/reports/modals/EditOnlineTrainingReportModal";
import DeleteOnlineTrainingReportModal from "@components/common/reports/modals/DeleteOnlineTrainingReportModal";
import AddEmployeeOnlineTrainingReportModal from "@components/common/reports/modals/AddEmplyeeOnlineTrainingReportModal";
import DeleteButton from "@components/common/reports/DeleteButton";

import {
  OnlineTrainingReport,
  OnlineTrainingReportUser
} from "@models/onlineTrainingReports";

import { onlineTrainingReportsApi } from "@api/onlineTrainingReportsApi";

import useDateFormatter from "@hooks/useDateFormat";
import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";
import { usePagination } from "@hooks/usePagination";

const OnlineTrainingReportDetailsPage: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const { formatter } = useDateFormatter();
  const { id }: { id: string } = useParams();
  const { userEntityId: contractorId } = useContext(UserContext);

  const { page, setPage, setPagination } = usePagination();

  const [isAddEmployeeModalOpen, setAddEmployeeModalOpen] =
    useState<boolean>(false);

  const [isEditReportModalOpen, setEditReportModalOpen] =
    useState<boolean>(false);

  const [isDeleteReportModalOpen, setDeleteReportModalOpen] =
    useState<boolean>(false);

  const currentReportData = useQuery(
    "current-online-training-report",
    () =>
      onlineTrainingReportsApi
        .getOnlineTrainingReport(id, contractorId)
        .then((res) => res.data),
    {
      onError: () =>
        notification.error({
          message: t("error.errorOccured")
        })
    }
  );

  const currentReportUsers = useQuery(
    ["current-online-training-report-users", page],
    () =>
      onlineTrainingReportsApi
        .getOnlineTrainingReportUsers({
          reportId: id,
          contractorId,
          page
        })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage
        };
        setPagination(pagination);
      },
      onError: () =>
        notification.error({
          message: t("error.errorOccured")
        })
    }
  );

  const deleteReport = useValidatedMutation({
    mutationFunction: (reportId: number) =>
      onlineTrainingReportsApi.deleteOnlineTrainingReport(
        contractorId,
        reportId
      ),
    onSuccess: () => {
      notification.success({
        message: t("reports.reportDeleted")
      });
      history.push("/admin/reports/online-training-reports");
    },
    onError: () =>
      notification.error({
        message: t("error.errorOccured")
      })
  });

  const addEmployeeToReport = useValidatedMutation({
    mutationFunction: (values: OnlineTrainingReportUser) =>
      onlineTrainingReportsApi
        .addOnlineTrainingReportEmployee(contractorId, values)
        .then((res) => res.data),
    onSuccess: () => {
      currentReportUsers.refetch();
      notification.success({
        message: t("reports.userAddSuccess")
      });
      setAddEmployeeModalOpen(false);
    },
    onError: () =>
      notification.error({
        message: t("error.errorOccured")
      })
  });

  const addEmployeeToReportErrors = useMemo(
    () => transformValidationError(addEmployeeToReport.error),
    [addEmployeeToReport.error]
  );

  const editOnlineTrainingReport = useValidatedMutation({
    mutationFunction: (values: OnlineTrainingReport) =>
      onlineTrainingReportsApi.editOnlineTrainingReport(contractorId, values),
    onSuccess: () => {
      notification.success({
        message: t("reports.saveSuccess")
      });
      currentReportData.refetch();
      setEditReportModalOpen(false);
    },
    onError: () =>
      notification.error({
        message: t("error.errorOccured")
      })
  });

  const editOnlineTrainingReportErrors = useMemo(
    () => transformValidationError(editOnlineTrainingReport.error),
    [editOnlineTrainingReport.error]
  );

  const deleteEmployeeFromReport = useValidatedMutation({
    mutationFunction: (userId: number) =>
      onlineTrainingReportsApi.deleteOnlineTrainingReportEmployee(
        contractorId,
        userId
      ),
    onSuccess: () => {
      currentReportUsers.refetch();
      notification.success({
        message: t("reports.userDeleteSuccess")
      });
    },
    onError: () =>
      notification.error({
        message: t("error.errorOccured")
      })
  });

  const pageTitle = currentReportData.data
    ? currentReportData?.data.name
    : t("reports.onlineTrainingReport");

  const paginationSettings = {
    hideOnSinglePage: true,
    total: currentReportUsers.data?.totalItems,
    current: currentReportUsers.data?.currentPage,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: t("reports.onlineTrainingReport_other"),
            path: "/admin/reports/online-training-reports"
          },
          {
            breadcrumbName: pageTitle,
            path: currentReportData.data
              ? `/admin/reports/online-training-report/${currentReportData.data?.id}`
              : ""
          }
        ]}
        extra={
          currentReportData.data && (
            <Space>
              <EditOnlineTrainingReportModal
                isModalOpen={isEditReportModalOpen}
                handleOpenModal={() => setEditReportModalOpen(true)}
                handleCloseModal={() => setEditReportModalOpen(false)}
                currentReport={currentReportData.data}
                editReport={editOnlineTrainingReport.mutate}
                errors={editOnlineTrainingReportErrors}
                isLoading={editOnlineTrainingReport.isLoading}
              />
              <AddEmployeeOnlineTrainingReportModal
                isModalOpen={isAddEmployeeModalOpen}
                handleOpenModal={() => setAddEmployeeModalOpen(true)}
                handleCloseModal={() => setAddEmployeeModalOpen(false)}
                currentReport={currentReportData.data}
                addEmployeeToReport={addEmployeeToReport.mutate}
                errors={addEmployeeToReportErrors}
                isLoading={addEmployeeToReport.isLoading}
              />
              <DeleteOnlineTrainingReportModal
                isModalOpen={isDeleteReportModalOpen}
                handleOpenModal={() => setDeleteReportModalOpen(true)}
                handleCloseModal={() => setDeleteReportModalOpen(false)}
                currentReport={currentReportData.data}
                handleDelete={deleteReport.mutate}
                isLoading={deleteReport.isLoading}
              />
            </Space>
          )
        }
      />
      <Card
        loading={currentReportData.isLoading}
        style={{ marginBottom: "24px" }}
      >
        {!currentReportData.data ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("error.errorOccured")}
          />
        ) : (
          <>
            <Row gutter={[16, 16]} justify="space-between">
              <Col>
                <Descriptions size="small">
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("name")}
                      </Typography.Text>
                    }
                  >
                    {currentReportData.data?.name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("contractors.contractor")}
                      </Typography.Text>
                    }
                  >
                    {currentReportData.data?.contractorDto.name}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <Descriptions size="small">
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("status")}
                      </Typography.Text>
                    }
                  >
                    {currentReportData.data?.completedAt
                      ? `${t("reports.completed")} ${formatter(
                          currentReportData.data?.completedAt,
                          "short-date"
                        )}`
                      : t("incompleted")}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <Descriptions size="small">
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("createdAt")}
                      </Typography.Text>
                    }
                  >
                    {currentReportData.data &&
                      formatter(
                        currentReportData.data?.createdAt,
                        "short-date"
                      )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </>
        )}
      </Card>
      {currentReportUsers.data ? (
        <OnlineTrainingReportDetailsTable
          currentReportUsers={currentReportUsers.data}
          isLoading={
            deleteEmployeeFromReport.isLoading || currentReportUsers.isLoading
          }
          pagination={paginationSettings}
          renderDeleteButton={(props: OnlineTrainingReportUser) => {
            return (
              <DeleteButton
                name={t("reports.userDeleteConfirmation", {
                  name: `${props.firstName} ${props.lastName}`
                })}
                id={props.id}
                onConfirm={deleteEmployeeFromReport.mutate}
              />
            );
          }}
        />
      ) : (
        <Table
          dataSource={[]}
          loading={currentReportUsers.isLoading}
          locale={{
            emptyText: currentReportUsers.isLoading
              ? ""
              : t("error.errorOccured")
          }}
        />
      )}
    </>
  );
};

export default OnlineTrainingReportDetailsPage;
