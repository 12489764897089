import React from "react";
import { useTranslation } from "react-i18next/";

import { Input, Button, Form, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { Contractor } from "@models/contractor";

interface Props {
  title?: React.ReactNode;
  isLoading?: boolean;
  data?: Contractor[];
  extraColumns?: {
    title?: string;
    dataIndex?: string;
    render?: (value: any, record: unknown, index: number) => React.ReactNode;
  }[];
  onDelete?: () => void;
  onEdit?: () => void;
  renderActions?: (member: any) => React.ReactNode;
  handleSearchQuery: (searchParams: { bin: string; name: string }) => void;
}

const SearchSection = (props: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (vals: { bin: string; name: string }) => {
    props.handleSearchQuery({ bin: vals.bin, name: vals.name });
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item name="bin" label={t("bin")}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label={t("contractors.contractorName")}>
            <Input />
          </Form.Item>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <Button type="primary" htmlType="submit">
            <SearchOutlined />
            {"SEARCH"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchSection;
