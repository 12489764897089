import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PaginationProps } from "antd/lib/pagination";
import { ColumnType } from "antd/lib/table";

import { Table } from "antd";

import { OnlineTrainingReportUser } from "@models/onlineTrainingReports";

import { PaginatedResponse } from "@api";

interface Props {
  currentReportUsers: PaginatedResponse<OnlineTrainingReportUser>;
  isLoading: boolean;
  pagination: PaginationProps;
  renderDeleteButton?: (props: OnlineTrainingReportUser) => ReactNode;
}

export const OnlineTrainingReportDetailsTable: React.FC<Props> = ({
  currentReportUsers,
  pagination,
  isLoading,
  renderDeleteButton
}) => {
  const [t] = useTranslation();
  const { results } = currentReportUsers;

  const columns = useMemo(
    () => {
      const e: (ColumnType<OnlineTrainingReportUser> & {
        hidden?: boolean;
      })[] = [
        {
          title: "#",
          dataIndex: "number",
          key: "id",
          render: (_, __, index: number) => <>{index + 1}</>
        },
        {
          title: t("lastName"),
          dataIndex: "lastName",
          key: "lastName"
        },
        {
          title: t("firstName"),
          dataIndex: "firstName",
          key: "firstName"
        },
        {
          title: t("iin"),
          dataIndex: "iin",
          key: "iin"
        },
        {
          title: t("employeeNumber"),
          dataIndex: "employeeNumber",
          key: "employeeNumber"
        },
        {
          title: t("department"),
          dataIndex: "department",
          key: "department"
        },
        {
          title: t("title"),
          dataIndex: "title",
          key: "title"
        },
        {
          title: t("supervisor"),
          dataIndex: "supervisor",
          key: "supervisor"
        },
        {
          title: "Rev",
          dataIndex: "rev",
          key: "rev",
          render: (rev) => <>{rev === 1 ? t("local") : t("expat")}</>
        },
        {
          title: t("actions"),
          dataIndex: "actions",
          key: "actions",
          render: (_, userData) =>
            renderDeleteButton && renderDeleteButton(userData),
          align: "center",
          hidden: !renderDeleteButton
        }
      ];

      return e.filter((item) => !item.hidden);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={results || []}
      pagination={pagination}
      rowKey="id"
    />
  );
};
