import { useCallback, useEffect, useMemo, useState } from "react";
import { UseMutateFunction } from "react-query";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { useFormik } from "formik";

import {
  CheckOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusOutlined,
  PlusOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography
} from "antd";
import FormItemGenerator from "@components/admin/migrations/renderers/FormItemGenerator";
import {
  FAIL_REASON_OPTIONS,
  RECOMMENDATION_OPTIONS
} from "@components/common/reports/CommissioningReportFilterForm";
import TableFormItemGenerator from "@components/admin/migrations/renderers/TableFormItemGenerator";
import NotRegisteredUsersTable from "@components/admin/migrations/tables/NotRegisteredUsersTable";
import {
  COMPETENCY_LEVEL_OPTIONS_MAP,
  DEFAULT_COMPETENCY_LEVEL_OPTIONS
} from "@components/training-center/events/NewEventForm";

import {
  OverallGrades,
  FormSettings,
  MigrationResponse,
  MigrationFieldProps,
  MigrationInitialFormData,
  MigrationTypes,
  StaticFieldProps,
  ParticipantRequest
} from "@models/migrations";
import { FailReasonType } from "@models/event";

import { cityApi } from "@api/cityApi";
import { disciplineApi } from "@api/disciplineApi";
import { contractorsApi } from "@api/contractorsApi";
import { instructorApi } from "@api/instructorApi";

interface Props {
  selectedMigration: number;
  isLoding?: boolean;
  formSettings: FormSettings;
  postEditedMigration: UseMutateFunction<
    unknown,
    unknown,
    MigrationInitialFormData
  >;
  handleCloseModal: () => void;
  parsedData: Partial<MigrationResponse> & {
    participants: ParticipantRequest[];
  };
  loading?: boolean;
  errors: { [key: string]: string };
}

const { Column } = Table;
const { Text } = Typography;

const MigrationFormComposer: React.FC<Props> = ({
  selectedMigration,
  formSettings,
  parsedData,
  handleCloseModal,
  postEditedMigration,
  loading,
  errors
}) => {
  const [t] = useTranslation();
  const [lineEdited, setLineEdited] = useState<number | null>(null);
  const [isTableHidden, setTableHidden] = useState<boolean>(false);
  const [isCommissioning] = useState<boolean>(
    selectedMigration === MigrationTypes.COMMISSIONING_ASSESSMENT ||
      selectedMigration === MigrationTypes.COMMISSIONING_TRAINING
  );

  const notRegisteredUsers = useMemo(
    () =>
      parsedData.participants.filter(
        (p) => p.employeeContractorRelations?.length === 0
      ),
    [parsedData.participants]
  );

  const registeredUsers = useMemo(
    () =>
      parsedData.participants.filter(
        (p) => p.employeeContractorRelations?.length !== 0
      ),
    [parsedData.participants]
  );

  const initialData = useMemo(
    () => ({
      participants: registeredUsers.map((item) => {
        return formSettings.participants.reduce((acc, val) => {
          if (val.name === "isPassed" && item["overallGrade"]) {
            return {
              ...acc,
              [val.name]: item["overallGrade"]
            };
          }

          if (
            val.name === "employeeId" &&
            item["employeeContractorRelations"]?.length
          ) {
            return {
              ...acc,
              // Frontend needs to select [0] item from "employeeContractorRelations" by default and backend needs both fields to be passed
              employeeId: item["employeeContractorRelations"][0]?.employeeId,
              contractorId: item["employeeContractorRelations"][0]?.contractorId
            };
          }

          return {
            ...acc,
            [val.name]: item[val.name]
          };
        }, {});
      }),
      event: formSettings.event.reduce((acc, val) => {
        return {
          ...acc,
          [val.name]: parsedData[val.name]
        };
      }, {})
    }),
    [formSettings.event, formSettings.participants, parsedData, registeredUsers]
  );

  const instructorsQuery = useQuery(
    "instructors-list",
    () => instructorApi.getAllInstructors().then((res) => res.data),
    {
      enabled: false
    }
  );

  const disciplinesQuery = useQuery(
    "disciplines-list",
    () => disciplineApi.getAllDisciplines().then((res) => res.data),
    {
      enabled: false
    }
  );

  const cityListQuery = useQuery(
    "city-list",
    () => cityApi.getCities().then((res) => res.data),
    { enabled: false }
  );

  const trainingCenterQuery = useQuery(
    "training-center-list",
    () => contractorsApi.getAllTrainingCenters().then((res) => res.data),
    {
      enabled: false
    }
  );

  const formik = useFormik<MigrationInitialFormData>({
    initialValues: initialData,
    onSubmit: (values) => {
      postEditedMigration(values);
    }
  });

  useEffect(() => {
    formik.setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const { event } = formSettings;
    const findStr = (str: string) => event.find((item) => item.name === str);

    if (findStr("instructorId")) {
      instructorsQuery.refetch();
    }
    if (findStr("trainingCenterId")) {
      trainingCenterQuery.refetch();
    }
    if (findStr("specialityId")) {
      disciplinesQuery.refetch();
    }
    if (findStr("cityId")) {
      cityListQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const employeeContractorRelations = useCallback(
    (index: number) => registeredUsers[index].employeeContractorRelations,
    [registeredUsers]
  );

  const renderEventFormColumns = useMemo(
    () =>
      formSettings.event.map((item: MigrationFieldProps) => (
        <Col span={4} key={item.name}>
          <Form.Item
            label={t(item.label)}
            validateStatus={
              formik.errors[
                `event.${
                  item.error ? item.error : item.name
                }` as keyof MigrationInitialFormData
              ] && "error"
            }
            help={
              formik.errors[
                `event.${
                  item.error ? item.error : item.name
                }` as keyof MigrationInitialFormData
              ]
            }
          >
            <FormItemGenerator
              field={item}
              formik={formik}
              instructorsQuery={instructorsQuery}
              isCommissioning={isCommissioning}
              trainingCenterQuery={trainingCenterQuery}
              disciplinesQuery={disciplinesQuery}
              cityListQuery={cityListQuery}
              onBlur={(name, value: string | null) =>
                formik.setFieldValue(`event.${name}`, value)
              }
            />
          </Form.Item>
        </Col>
      )),
    [
      cityListQuery,
      disciplinesQuery,
      formSettings.event,
      formik,
      instructorsQuery,
      isCommissioning,
      t,
      trainingCenterQuery
    ]
  );

  const renderStaticField = ({
    name,
    index,
    notRegistered,
    staticValue
  }: StaticFieldProps) => {
    const incomingValue = !notRegistered
      ? formik.values.participants[index]?.[name]
      : staticValue;

    if (name === "recommendation") {
      const selectedRecommendation = RECOMMENDATION_OPTIONS.find(
        (item) => item.value === Number(incomingValue)
      );
      return incomingValue && selectedRecommendation
        ? t(selectedRecommendation.label)
        : "-";
    }
    if (name === "employeeId") {
      const contractorName = registeredUsers[
        index
      ]?.employeeContractorRelations?.find(
        (item) => item.employeeId === incomingValue
      );

      return incomingValue && contractorName
        ? `${contractorName.contractorName} - ${t("employees.contract")}: ${
            contractorName.contractName
          }`
        : "-";
    }
    if (name === "isPassed") {
      return incomingValue
        ? t(`migrations.form.competencyLevels.${incomingValue}`)
        : "-";
    }
    if (name === "failReasonType") {
      const selectedFailType = FAIL_REASON_OPTIONS.find(
        (item) => item.value === Number(incomingValue)
      );

      return incomingValue && selectedFailType
        ? t(selectedFailType.label)
        : "-";
    }
    if (name === "practicalStatus" || name === "theoreticalStatus") {
      switch (incomingValue) {
        case OverallGrades.FAILED:
          return t("migrations.form.gradeFailed");
        case OverallGrades.PASSED:
          return t("migrations.form.gradePassed");
        default:
          return "-";
      }
    }
    return incomingValue ? incomingValue?.toString() : "-";
  };

  const selectedSpecialityCompetencyType = useMemo(() => {
    return disciplinesQuery.data?.find(
      (s) => s.id === Number(formik.values.event.specialityId)
    )?.competencyType;
  }, [formik.values.event.specialityId, disciplinesQuery]);

  const showCompetencyLevelField = useMemo(() => {
    if (!formik.values.event.specialityId) {
      return false;
    }

    if (
      selectedMigration === MigrationTypes.PRESCREENING ||
      selectedMigration === MigrationTypes.ASSESSMENT ||
      selectedMigration === MigrationTypes.COMMISSIONING_ASSESSMENT
    ) {
      return false;
    }

    return true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.event.specialityId, selectedSpecialityCompetencyType]);

  const competencyLevelOptions = useMemo(() => {
    return (
      COMPETENCY_LEVEL_OPTIONS_MAP[selectedSpecialityCompetencyType || 0] ||
      DEFAULT_COMPETENCY_LEVEL_OPTIONS
    );
  }, [selectedSpecialityCompetencyType]);

  return (
    <Spin spinning={loading}>
      <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
        <Row gutter={[16, 16]}>
          {renderEventFormColumns}
          {showCompetencyLevelField && (
            <Col span={4}>
              <Form.Item
                label={t("migrations.form.competencyLevel")}
                validateStatus={
                  formik.errors[
                    "event.CompetencyLevel" as keyof MigrationInitialFormData
                  ] && "error"
                }
                help={
                  formik.errors[
                    "event.CompetencyLevel" as keyof MigrationInitialFormData
                  ]
                }
              >
                <Select
                  value={formik.values.event.competencyLevel}
                  onChange={(v) =>
                    formik.setFieldValue("event.competencyLevel", v)
                  }
                >
                  {competencyLevelOptions?.map((cl) => (
                    <Select.Option value={cl.value || 0} key={cl.value || 0}>
                      {t(`competencyLevels.${cl.value}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        {notRegisteredUsers.length > 0 && (
          <>
            <Space style={{ marginBottom: "10px" }}>
              <Tooltip
                title={t("migrations.form.iinTooltip")}
                placement="right"
              >
                <Space>
                  <Text strong type="danger">
                    {t("migrations.notRegisteredUsers")}
                  </Text>
                  <ExclamationCircleOutlined
                    style={{ color: "#ff4d4f", fontSize: "20px" }}
                  />
                </Space>
              </Tooltip>
              <Button
                icon={isTableHidden ? <PlusOutlined /> : <MinusOutlined />}
                size="small"
                onClick={() => setTableHidden(!isTableHidden)}
              />
            </Space>
            {!isTableHidden && (
              <NotRegisteredUsersTable
                notRegisteredUsers={notRegisteredUsers}
                participants={formSettings.participants}
                renderStaticField={renderStaticField}
              />
            )}
            <Divider style={{ marginTop: "10px" }} />
          </>
        )}
        <Space style={{ marginBottom: "10px" }}>
          <Text strong type="success">
            {t("migrations.registeredUsers")}
          </Text>
        </Space>
        <Table
          dataSource={registeredUsers}
          scroll={{ x: 2600, y: 300 }}
          pagination={false}
          rowKey="index"
        >
          <Column
            title="#"
            dataIndex="number"
            key="number"
            width="2%"
            render={(_, __, index) => <>{index + 1}</>}
          />

          {formSettings.participants.map(
            (p) =>
              !p.hidden && (
                <Column
                  title={t(`migrations.form.${p.name}`)}
                  dataIndex={p.name}
                  key={p.name}
                  render={(_, obj: ParticipantRequest, index) =>
                    obj.isRegistered && (
                      <Form.Item
                        style={{ marginTop: "24px" }}
                        validateStatus={
                          formik.errors[
                            `participants[${index}].${
                              p.error ? p.error : p.name
                            }` as keyof MigrationInitialFormData
                          ] && "error"
                        }
                        help={
                          formik.errors[
                            `participants[${index}].${
                              p.error ? p.error : p.name
                            }` as keyof MigrationInitialFormData
                          ]
                        }
                        key={p.name}
                      >
                        {index === lineEdited ? (
                          <TableFormItemGenerator
                            field={p}
                            onBlur={(name, value) =>
                              formik.setFieldValue(
                                `participants[${index}].${name}`,
                                value
                              )
                            }
                            formik={formik}
                            index={index}
                            isCommissioning={isCommissioning}
                            employeeContractorRelations={employeeContractorRelations(
                              index
                            )}
                            cityListQuery={cityListQuery}
                            instructorsQuery={instructorsQuery}
                            disciplinesQuery={disciplinesQuery}
                            trainingCenterQuery={trainingCenterQuery}
                            disabled={
                              !!p.disabled ||
                              (p.name === "failReason" &&
                                Number(
                                  formik.values.participants[index]
                                    .failReasonType
                                ) !== FailReasonType.OTHER) ||
                              false
                            }
                            selectedMigration={selectedMigration}
                          />
                        ) : (
                          renderStaticField({ name: p.name, index })
                        )}
                      </Form.Item>
                    )
                  }
                />
              )
          )}

          {registeredUsers.length > 0 && (
            <Column
              title={t("actions")}
              align="center"
              fixed="right"
              width="4%"
              key="actions"
              render={(_, __, index) => (
                <>
                  {index === lineEdited ? (
                    <Button
                      shape="circle"
                      icon={<CheckOutlined />}
                      onClick={() => setLineEdited(null)}
                    />
                  ) : (
                    <Button
                      shape="circle"
                      icon={<EditOutlined />}
                      type="primary"
                      onClick={() => setLineEdited(index)}
                    />
                  )}
                </>
              )}
            />
          )}
        </Table>

        <Row justify="space-between" style={{ marginTop: "24px" }}>
          <Col>
            <Button onClick={handleCloseModal}>{t("cancel")}</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {t("create")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default MigrationFormComposer;
