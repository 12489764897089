import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { Button, Modal, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import RequestsTable from "@components/common/requests/RequestsTable";
import RequestsFilter from "@components/common/requests/RequestsFilter";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { NewRequestForm } from "@pages/contractor/requests/components/NewRequestForm";

import { CreateRequestData, FilterRequestsData } from "@models/requests";

import requestsApi from "@api/requestsApi";
import { contractorsApi } from "@api/contractorsApi";

import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { useQueryParams } from "@hooks/useQueryParams";
import { usePagination } from "@hooks/usePagination";

export const RequestsListPage: React.FC = () => {
  const [t] = useTranslation();
  const queryParams = useQueryParams();
  const { page, setPage, setPagination } = usePagination();
  const { ...initFilterData } = queryParams;
  const [filterData, setFilterData] =
    useState<FilterRequestsData>(initFilterData);

  const { user } = useContext(UserContext);
  const contractorId = user?.userRoles[0].entityId || 0;

  const [isModalShown, setIsModalShown] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ["getContracotrTickets", page, filterData],
    () =>
      requestsApi
        .getContractorRequests(contractorId, page, filterData)
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterParams: filterData
        };
        setPagination(pagination);
      }
    }
  );

  const { data: trainingCenters } = useQuery("getTrainingCentersMap", () =>
    contractorsApi.getTrainingCentersMap(contractorId).then((res) => res.data)
  );

  const [createErrors, setCreateErrors] = useState({});
  const createRequestMutation = useValidatedMutation({
    mutationFunction: (values: CreateRequestData) => {
      return requestsApi.createRequest(contractorId, values);
    },
    onSuccess() {
      notification.success({
        message: t("requests.createSuccess")
      });
      refetch();
      setCreateErrors({});
      setIsModalShown(false);
    },
    onError(errors) {
      setCreateErrors(errors);
    }
  });

  const paginationSettings = {
    total: data?.totalItems,
    current: data?.currentPage,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={t("requests.list")} />

      <AppPageHeader
        title={t("requests.list")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "contractor" },
          { breadcrumbName: t("requests.requests"), path: "requests" }
        ]}
        extra={[
          <Button
            key="2"
            type="primary"
            icon={<PlusOutlined />}
            disabled={false}
            onClick={() => setIsModalShown(true)}
          >
            {t("requests.create")}
          </Button>
        ]}
      />

      <Modal
        visible={isModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("requests.create")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsModalShown(false);
          createRequestMutation.reset();
        }}
      >
        <NewRequestForm
          onCancel={() => {
            setIsModalShown(false);
            createRequestMutation.reset();
          }}
          onSubmit={(values) => {
            createRequestMutation.mutate(values);
          }}
          trainingCenters={trainingCenters}
          errors={createErrors}
          isLoading={createRequestMutation.isLoading}
        />
      </Modal>

      <RequestsFilter
        onFilter={setFilterData}
        initialValues={initFilterData}
        trainingCenters={trainingCenters || {}}
      />

      <RequestsTable
        data={data}
        isLoading={isLoading}
        pagination={paginationSettings}
        generateUrl={(requestId) => `/contractor/requests/${requestId}`}
        showTrainingCenter
      />
    </>
  );
};
