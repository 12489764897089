import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  AuditOutlined,
  BarChartOutlined,
  SearchOutlined,
  TeamOutlined,
  CalendarOutlined,
  SolutionOutlined
} from "@ant-design/icons";
import ApplicationLayout from "@layouts/ApplicationLayout";
import MembersPage from "@pages/subcontractor/members/MembersPage";
import { ProfilePage } from "@pages/ProfilePage";
import { EmployeesPage } from "@pages/subcontractor/employees/EmployeesPage";
import { EmployeeDetailsPage } from "@pages/subcontractor/employees/EmployeeDetailsPage";
import { EventSearchPage } from "@pages/subcontractor/events/EventSearchPage";
import { AllEventsPage } from "@pages/subcontractor/events/AllEventsPage";
import { RegisterEmployeesPage } from "@pages/subcontractor/events/RegisterEmployeesPage";
import ErrorPage from "@pages/ErrorPage";
import CertificateVerifyPage from "@pages/subcontractor/CertificateVerifyPage";
import { PerformanceReportPage } from "@pages/subcontractor/reports/PerformanceReportPage";
import { UpcomingEventsPage } from "@pages/subcontractor/events/UpcomingEventsPage";
import { InprogressEventsPage } from "@pages/subcontractor/events/InprogressEventsPage";
import { CompletedEventsPage } from "@pages/subcontractor/events/CompletedEventsPage";
import { EvaluatedEventsPage } from "@pages/subcontractor/events/EvaluatedEventsPage";
import { CancelledEventsPage } from "@pages/subcontractor/events/CancelledEventsPage";
import { EventDetailsPage } from "@pages/subcontractor/events/EventDetailsPage";
import { CommissioningReportPage } from "@pages/subcontractor/reports/CommissioningReportPage";
import { CraftProfilesListPage } from "@pages/subcontractor/craftProfiles/CraftProfilesListPage";
import CraftProfilesCardPage from "@pages/subcontractor/craftProfiles/CraftProfilesCardPage";
import { SubcontractorUserGuide } from "@pages/subcontractor/UserGuide";
import { ContactUs } from "@pages/ContactUs";
import { AboutUs } from "@pages/AboutUs";
import { SubcaontractorWhatNew } from "@pages/subcontractor/whatNew";

import { PERMISSIONS } from "@models/permissions";
import { RouteItem } from "@models/routeItem";

import { UserContext } from "@contexts/UserContext";

const subcontractorRoutes: RouteItem[] = [
  {
    id: "profile",
    path: "/profile",
    component: ProfilePage
  },
  {
    id: "eventSearch",
    text: "search",
    icon: <SearchOutlined />,
    path: "/contractor/events/search",
    isNavItem: true,
    component: EventSearchPage,
    permissions: [PERMISSIONS.EVENT_VIEW]
  },
  {
    id: "events",
    text: "events.events",
    icon: <CalendarOutlined />,
    isNavItem: true,
    items: [
      {
        id: "allEvents",
        text: "events.all",
        path: "/subcontractor/events/all",
        isNavItem: true,
        component: AllEventsPage
      },
      {
        id: "upcoming-events",
        text: "events.upcoming",
        path: "/subcontractor/events/upcoming",
        isNavItem: true,
        component: UpcomingEventsPage
      },
      {
        id: "inprogress-events",
        text: "events.inprogress",
        path: "/subcontractor/events/inprogress",
        isNavItem: true,
        component: InprogressEventsPage
      },
      {
        id: "completed-events",
        text: "events.completed",
        path: "/subcontractor/events/completed",
        isNavItem: true,
        component: CompletedEventsPage
      },
      {
        id: "evaluated-events",
        text: "events.evaluated",
        path: "/subcontractor/events/evaluated",
        isNavItem: true,
        component: EvaluatedEventsPage
      },
      {
        id: "cancelled-events",
        text: "events.cancelled",
        path: "/subcontractor/events/cancelled",
        isNavItem: true,
        component: CancelledEventsPage
      }
    ],
    permissions: [PERMISSIONS.EVENT_VIEW]
  },
  {
    id: "event-details",
    path: "/subcontractor/events/:id",
    component: EventDetailsPage
  },
  {
    id: "registerEmployees",
    path: "/subcontractor/events/:id/registeremployees",
    component: RegisterEmployeesPage,
    permissions: [PERMISSIONS.EVENT_APPLY]
  },
  {
    id: "reports",
    text: "reports.reports",
    icon: <BarChartOutlined />,
    isNavItem: true,
    items: [
      {
        id: "reportsPerformanceReport",
        text: "reports.performanceReports",
        path: "/subcontractor/reports/performance",
        isNavItem: true,
        component: PerformanceReportPage
      },
      {
        id: "commissioningReport",
        text: "reports.commissioningPerformance",
        path: "/subcontractor/reports/commissioning",
        isNavItem: true,
        component: CommissioningReportPage
      }
    ],
    permissions: [PERMISSIONS.REPORT_VIEW]
  },
  {
    id: "userGuide",
    path: "/subcontractor/user-guide",
    component: SubcontractorUserGuide
  },
  {
    id: "aboutUs",
    path: "/subcontractor/about-us",
    component: AboutUs
  },
  {
    id: "whatNew",
    path: "/subcontractor/what-new",
    component: SubcaontractorWhatNew
  },
  {
    id: "contactUs",
    path: "/admin/contact-us",
    component: ContactUs
  },
  {
    id: "employees",
    text: "employees.employees",
    icon: <AuditOutlined />,
    path: "/subcontractor/employees",
    isNavItem: true,
    component: EmployeesPage,
    permissions: [PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW]
  },
  {
    id: "members",
    text: "members.members",
    icon: <TeamOutlined />,
    path: "/subcontractor/members",
    isNavItem: true,
    component: MembersPage
  },
  {
    id: "employeePassport",
    path: "/subcontractor/employees/:id",
    component: EmployeeDetailsPage
  },
  {
    id: "certificates-verify",
    text: "certificates.verification",
    icon: <SolutionOutlined />,
    path: "/contractor/certificates",
    isNavItem: true,
    component: CertificateVerifyPage,
    permissions: [PERMISSIONS.CERTIFICATE_VIEW]
  },
  {
    id: "craftProfiles",
    text: "craftProfiles.profiles",
    icon: <TeamOutlined />,
    path: "/subcontractor/craft-profiles",
    component: CraftProfilesListPage,
    isNavItem: true
  },
  {
    id: "craftProfileCard",
    text: "craftProfiles.profile",
    path: "/subcontractor/craft-profile/:id",
    component: CraftProfilesCardPage,
    isNavItem: false
  }
];

const SubcontractorRoutes: React.FC = () => {
  const { user } = useContext(UserContext);
  const userPermissions = user?.permissions.map((p) => p.permission);
  const filteredSubcontractorsRoutes = subcontractorRoutes.filter((route) =>
    route.permissions
      ? route.permissions.every((p) => userPermissions?.includes(p))
      : true
  );
  return (
    <ApplicationLayout navItems={filteredSubcontractorsRoutes}>
      <Switch>
        {filteredSubcontractorsRoutes.map((route, i) =>
          route.items ? (
            route.items.map((item, i) => (
              <Route
                exact
                path={item.path}
                component={item.component}
                key={i}
              />
            ))
          ) : (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={i}
            />
          )
        )}
        <Route exact path="/" render={() => <Redirect to="/profile" />} />
        <Route component={ErrorPage} />
      </Switch>
    </ApplicationLayout>
  );
};

export default SubcontractorRoutes;
