import axios from "axios";
import saveAs from "file-saver";

import {
  OnlineTrainingReport,
  OnlineTrainingReportResult,
  OnlineTrainingReportUser
} from "@models/onlineTrainingReports";

import { PaginatedResponse } from "@api";

interface GetOnlineTrainingReportUsersProps {
  reportId: string;
  contractorId?: number;
  page?: number;
  pageSize?: number;
}

const getAllContractorOnlineTrainingReports = (
  contractorId: number,
  page?: number
) =>
  axios.get<PaginatedResponse<OnlineTrainingReportResult>>(
    `/api/online-training-reports/${contractorId}`,
    {
      params: { page }
    }
  );

const getAllOnlineTrainingReports = (page?: number) =>
  axios.get<PaginatedResponse<OnlineTrainingReportResult>>(
    `/api/online-training-reports/all`,
    {
      params: { page }
    }
  );

const getOnlineTrainingReport = (reportId: string, contractorId?: number) =>
  axios.get<OnlineTrainingReportResult>(
    `/api/online-training-reports/reports/${reportId}`,
    { params: { contractorId } }
  );

const getOnlineTrainingReportUsers = ({
  reportId,
  contractorId,
  page,
  pageSize = 10
}: GetOnlineTrainingReportUsersProps) =>
  axios.get<PaginatedResponse<OnlineTrainingReportUser>>(
    `/api/online-training-reports/users/report/${reportId}`,
    { params: { page, pageSize, contractorId } }
  );

const createOnlineTrainingReport = (
  contractorId: number,
  values: OnlineTrainingReport
) =>
  axios.post<OnlineTrainingReport>(
    `/api/online-training-reports/${contractorId}`,
    values
  );

const getOnlineTrainingTemplate = () =>
  axios
    .get<Blob>(
      `/api/contractor/data-migration/online-training-reports/template`,
      {
        responseType: "blob"
      }
    )
    .then((response) => {
      return saveAs(new Blob([response.data]), "migration_template.xlsx");
    });

const uploadOnlineTrainingTable = (file: Blob, contractorId: number) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(
    `/api/contractor/data-migration/online-training-reports/parsing`,
    formData,
    {
      params: {
        contractorId
      }
    }
  );
};

const editOnlineTrainingReport = (
  contractorId: number,
  values: OnlineTrainingReport
) =>
  axios.put<OnlineTrainingReport>(
    `/api/online-training-reports/${contractorId}`,
    values
  );

const addOnlineTrainingReportEmployee = (
  contractorId: number,
  values: OnlineTrainingReportUser
) =>
  axios.post<OnlineTrainingReportUser>(
    `/api/online-training-reports/${contractorId}/users`,
    values
  );

const deleteOnlineTrainingReport = (contractorId: number, reportId: number) =>
  axios.delete<OnlineTrainingReport>(
    `/api/online-training-reports/${contractorId}/${reportId}`
  );

const deleteOnlineTrainingReportEmployee = (
  contractorId: number,
  userId: number
) =>
  axios.delete<OnlineTrainingReportUser>(
    `/api/online-training-reports/${contractorId}/users/${userId}`
  );

export const onlineTrainingReportsApi = {
  getAllOnlineTrainingReports,
  getAllContractorOnlineTrainingReports,
  getOnlineTrainingReport,
  getOnlineTrainingReportUsers,
  getOnlineTrainingTemplate,
  uploadOnlineTrainingTable,
  createOnlineTrainingReport,
  editOnlineTrainingReport,
  addOnlineTrainingReportEmployee,
  deleteOnlineTrainingReport,
  deleteOnlineTrainingReportEmployee
};
