import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import eachDayOfInterval from "date-fns/eachDayOfInterval";
import { useFormik } from "formik";

import { Alert, Button, Col, Form, Input, Row, Select, Space } from "antd";
import EventDateTimeRangePicker from "@components/common/events/forms/EventDateTimeRangePicker";
import MultiDatePicker from "@components/common/MultiDatePicker";

import { AdminEditEventPayload, EventDetails, EventType } from "@models/event";
import { Location } from "@models/location";
import { LanguageCode } from "@models/languageCode";

import useDateFormatter from "@hooks/useDateFormat";
import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

interface Props {
  event: EventDetails;
  isLoading?: boolean;
  errors?: { [key: string]: string };
  locations?: Location[];

  onCancel?: () => void;
  onSubmit?: (values: AdminEditEventPayload) => void;
}

const HOURS_PER_DAY_VARS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const EditEventForm = (props: Props) => {
  const dateFormatter = useDateFormatter();
  const [t, i18n] = useTranslation();
  const { event } = props;

  const formik = useFormik<AdminEditEventPayload>({
    initialValues: {
      name: event.name,
      capacityMax: event.capacityMax,
      academicHours: event.academicHours,
      languageCode: event.languageCode,
      standard: event.standard,
      csrPhase: event.csrPhase,
      description: event.description,

      dateTimeEnd: event.dateTimeEnd,
      dateTimeStart: event.dateTimeStart,
      hoursPerDay: event.hoursPerDay,
      daysOff: event.daysOff
        ? event.daysOff.map((d) => new Date(d).toISOString())
        : undefined,
      cityId: event.location?.id
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit({
          ...values,
          academicHours:
            values.academicHours === "" ? undefined : values.academicHours,
          capacityMax:
            values.capacityMax === "" ? undefined : values.capacityMax
        });
      }
    }
  });

  const isTraining = event.type === EventType.TRAINING;
  const isAssessment = event.type === EventType.ASSESSMENT;
  const isPrescreening = event.type === EventType.PRESCREENING;
  const isOjtTraining = event.type === EventType.OJT_TRAINING;

  const showDaysOff = isTraining || isOjtTraining;
  const showCsrPhase =
    !event.isCommissioning && (isTraining || isAssessment || isPrescreening);

  const showAcademicHours = !isPrescreening;
  const showStandard = !isPrescreening;
  const showDescription = !isPrescreening;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const eventDates = useMemo(() => {
    if (formik.values.dateTimeStart && formik.values.dateTimeEnd) {
      try {
        const val = eachDayOfInterval({
          start: new Date(formik.values.dateTimeStart),
          end: new Date(formik.values.dateTimeEnd)
        }).map((v) => ({
          value: v.toISOString(),
          label: dateFormatter.formatter(v.toJSON(), "short-date")
        }));

        return val;
      } catch (err) {
        return [];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.dateTimeStart, formik.values.dateTimeEnd]);

  useEffect(() => {
    if (!showDaysOff) {
      formik.setFieldValue("daysOff", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDaysOff]);

  useEffect(() => {
    if (!showCsrPhase) {
      formik.setFieldValue("csrPhase", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCsrPhase]);

  useEffect(() => {
    if (!showStandard) {
      formik.setFieldValue("standard", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStandard]);

  useEffect(() => {
    if (!showDescription) {
      formik.setFieldValue("description", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDescription]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors(props.errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Space direction="vertical">
        <Alert message={t("events.editAdminAlert")} type="warning" showIcon />

        {Boolean(event.type) && (
          <Row gutter={8}>
            <Col md={12}>
              <Form.Item
                label={t("events.name")}
                validateStatus={formik.errors.name && "error"}
                help={formik.errors.name}
              >
                <Input
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>

            <Col md={6}>
              <Form.Item
                label={t("events.numberOfSeats")}
                validateStatus={formik.errors.capacityMax && "error"}
                help={formik.errors.capacityMax}
              >
                <Input
                  name="capacityMax"
                  value={formik.values.capacityMax || undefined}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>

            {showAcademicHours && (
              <Col md={6}>
                <Form.Item
                  label={t("events.academicHours")}
                  validateStatus={formik.errors.academicHours && "error"}
                  help={formik.errors.academicHours}
                >
                  <Input
                    name="academicHours"
                    value={formik.values.academicHours || undefined}
                    onChange={formik.handleChange}
                  />
                </Form.Item>
              </Col>
            )}

            <Col md={12}>
              <Form.Item
                label={t("events.language")}
                validateStatus={formik.errors.languageCode && "error"}
                help={formik.errors.languageCode}
              >
                <Select
                  value={formik.values.languageCode}
                  onChange={(v) => {
                    formik.setFieldValue("languageCode", v);
                  }}
                >
                  <Select.Option value={LanguageCode.RU}>RU</Select.Option>
                  <Select.Option value={LanguageCode.KK}>KK</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={12}>
              <Form.Item
                label={t("events.location")}
                validateStatus={formik.errors.cityId && "error"}
                help={formik.errors.cityId}
              >
                <Select
                  value={formik.values.cityId}
                  onChange={(v) => {
                    formik.setFieldValue("cityId", v);
                  }}
                >
                  {props.locations?.map((location, index) => (
                    <Select.Option
                      key={`location-${index}`}
                      value={location.id}
                    >
                      {i18n.language === "en"
                        ? location.nameEn
                        : location.nameRu}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {showStandard && (
              <Col md={24}>
                <Form.Item
                  label={t("events.standard")}
                  validateStatus={formik.errors.standard && "error"}
                  help={formik.errors.standard}
                >
                  <Input
                    name="standard"
                    value={formik.values.standard}
                    onChange={formik.handleChange}
                  />
                </Form.Item>
              </Col>
            )}

            {showCsrPhase && (
              <Col md={24}>
                <Form.Item
                  label={t("events.csrPhase")}
                  validateStatus={formik.errors.csrPhase && "error"}
                  help={formik.errors.csrPhase}
                >
                  <Select
                    value={formik.values.csrPhase}
                    onChange={(v) => formik.setFieldValue("csrPhase", v)}
                  >
                    {CSR_PHASE_OPTIONS.map((cp) => (
                      <Select.Option value={cp.value} key={cp.value}>
                        {t(cp.label, { no: cp.value })}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {showDescription && (
              <Col md={24}>
                <Form.Item
                  label={t("events.description")}
                  validateStatus={formik.errors.description && "error"}
                  help={formik.errors.description}
                >
                  <Input.TextArea
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </Form.Item>
              </Col>
            )}

            <EventDateTimeRangePicker
              onStartChange={(v) =>
                formik.setFieldValue("dateTimeStart", v?.format())
              }
              onEndChange={(v) =>
                formik.setFieldValue("dateTimeEnd", v?.format())
              }
              dateTimeStart={formik.values.dateTimeStart}
              dateTimeEnd={formik.values.dateTimeEnd}
              dateTimeStartError={formik.errors.dateTimeStart}
              dateTimeEndError={formik.errors.dateTimeEnd}
              isAdmin
            />

            {showDaysOff && (
              <>
                <Col md={12}>
                  <Form.Item
                    label={t("events.setDaysOff")}
                    validateStatus={formik.errors.daysOff && "error"}
                    help={formik.errors.daysOff}
                  >
                    <MultiDatePicker
                      value={formik.values.daysOff || []}
                      onChange={(v) => formik.setFieldValue("daysOff", v)}
                      disabledDate={(date) =>
                        date.isAfter(formik.values.dateTimeEnd) ||
                        date.isBefore(formik.values.dateTimeStart)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col md={12}>
                  <Form.Item
                    label={t("events.hoursPerDay")}
                    validateStatus={formik.errors.hoursPerDay && "error"}
                    help={formik.errors.hoursPerDay}
                  >
                    <Select
                      value={formik.values.hoursPerDay}
                      onChange={(v) => formik.setFieldValue("hoursPerDay", v)}
                    >
                      {HOURS_PER_DAY_VARS.map((v) => (
                        <Select.Option value={v} key={v}>
                          {v}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        )}

        <Row justify="space-between">
          <Col>
            <Button onClick={() => props.onCancel && props.onCancel()}>
              {t("cancel")}
            </Button>
          </Col>

          <Col>
            <Button type="primary" htmlType="submit" loading={props.isLoading}>
              {t("edit")}
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};
