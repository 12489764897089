import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import { Button } from "antd";

const StyledButton = styled(Button)`
  color: rgba(234, 105, 73, 0.8) !important;
  font-weight: bold;
  font-size: 20px;
`;

const Logo: React.FC = () => {
  return (
    <Link to="/">
      <StyledButton type="link" size="large">
        CT Portal
      </StyledButton>
    </Link>
  );
};

export default Logo;
