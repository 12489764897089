import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Layout, Button, Space } from "antd";
import LanguageSelector from "@components/ui/LanguageSelector";
import LoginButton from "@components/ui/LoginButton";
import PageMeta from "@components/PageMeta";

import enImage from "@assets/images/us.png";
import ruImage from "@assets/images/ru.png";

const DepressedButton = styled(Button)`
  color: white;
  box-shadow: none;
`;

const CTPortalButton = styled(DepressedButton)`
  font-weight: bold;
`;

const Header = styled(Layout.Header)`
  background-color: #049aff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LANGS = [
  {
    title: "ENGLISH",
    shortTitle: "EN",
    iconsrc: enImage,
    code: "en"
  },
  {
    title: "РУССКИЙ",
    shortTitle: "RU",
    iconsrc: ruImage,
    code: "ru"
  }
];

const { Content } = Layout;

const DefaultLayout: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <PageMeta title={t("meta.main")} />
      <Layout>
        <Header>
          <Link to="/">
            <CTPortalButton size="large" type="primary">
              CT PORTAL
            </CTPortalButton>
          </Link>
          <Space>
            <LanguageSelector
              currentLangCode={i18n.language}
              onLangSwitch={(l: string) => i18n.changeLanguage(l)}
              langs={LANGS}
              whiteText
              withoutFlags
            />
            <DepressedButton type="primary">{t("contactUs")}</DepressedButton>
            <Link to="/login">
              <LoginButton />
            </Link>
          </Space>
        </Header>
        <Content> {children} </Content>
      </Layout>
    </>
  );
};

export default DefaultLayout;
