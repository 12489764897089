import { ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ColumnType } from "antd/lib/table";
import { PaginationProps } from "antd/lib/pagination";

import { Button, Table } from "antd";

import { OnlineTrainingReportResult } from "@models/onlineTrainingReports";

import { PaginatedResponse } from "@api";

import useDateFormatter from "@hooks/useDateFormat";

interface Props {
  reportsData?: PaginatedResponse<OnlineTrainingReportResult>;
  isLoading?: boolean;
  pagination: PaginationProps;
  renderDeleteButton?: (props: OnlineTrainingReportResult) => ReactNode;
}

export const OnlineTrainingReportTable: React.FC<Props> = ({
  reportsData,
  isLoading,
  pagination,
  renderDeleteButton
}) => {
  const [t] = useTranslation();
  const { formatter } = useDateFormatter();

  const columns = useMemo(
    () => {
      const e: (ColumnType<OnlineTrainingReportResult> & {
        hidden?: boolean;
      })[] = [
        {
          title: "#",
          dataIndex: "number",
          key: "id",
          render: (_, __, index: number) => <>{index + 1}</>
        },
        {
          title: t("title"),
          dataIndex: "name",
          key: "name",
          render: (_, reportData) => (
            <Link to={`/admin/reports/online-training-report/${reportData.id}`}>
              <Button type="link">{reportData.name}</Button>
            </Link>
          )
        },
        {
          title: t("contractors.contractor"),
          dataIndex: "contractorDto",
          key: "contractorDto",
          render: (contractor, _) => <>{contractor.name}</>
        },
        {
          title: t("createdAt"),
          dataIndex: "createdAt",
          key: "createdAt",
          render: (date) => <>{formatter(date, "short-date")}</>
        },
        {
          title: t("actions"),
          dataIndex: "actions",
          key: "actions",
          render: (_, reportData) =>
            renderDeleteButton && renderDeleteButton(reportData),
          align: "center",
          hidden: !renderDeleteButton
        }
      ];

      return e.filter((item) => !item.hidden);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  return (
    <Table
      columns={columns}
      dataSource={reportsData?.results || []}
      pagination={pagination}
      loading={isLoading}
      rowKey="id"
    />
  );
};
