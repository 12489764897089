import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { useFormik } from "formik";

import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from "antd";
import { PermissionCheckboxGroup } from "@components/common/PermissionCheckboxGroup";
import CountryCitySelectCascader from "@components/ui/CountryCitySelectCascader";

import { Country } from "@models/countryCity";
import { Discipline } from "@models/discipline";
import { PERMISSIONS } from "@models/permissions";

import { EVENT_TYPES } from "@constants/eventTypes";
import { TRAINING_CENTER_PERMISSION_OPTION_LIST } from "@constants/trainingCenterPermissions";

interface FormValues {
  name: string;
  cityId?: number;
  address: string;
  email: string;
  firstName: string;
  lastName: string;
  position: string;
  specialities?: number[];
  eventTypes?: number[];
  permissions?: number[];
  csrEnabled: boolean;
  commissioningEnabled: boolean;
}

const initialValues: FormValues = {
  name: "",
  cityId: undefined,
  address: "",
  email: "",
  firstName: "",
  lastName: "",
  position: "",
  specialities: [],
  eventTypes: [],
  csrEnabled: false,
  commissioningEnabled: false,
  permissions: [
    PERMISSIONS.REPORT_VIEW,
    PERMISSIONS.REPORT_DOWNLOAD,
    PERMISSIONS.REPORT_DOWNLOAD_DUMP,
    PERMISSIONS.MEMBER_VIEW,
    PERMISSIONS.MEMBER_INVITE,
    PERMISSIONS.MEMBER_UPDATE,
    PERMISSIONS.MEMBER_DELETE,
    PERMISSIONS.EVENT_VIEW,
    PERMISSIONS.EVENT_CREATE,
    PERMISSIONS.EVENT_UPDATE,
    PERMISSIONS.EVENT_CANCEL,
    PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_VIEW,
    PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_CREATE,
    PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_UPDATE,
    PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_DELETE,
    PERMISSIONS.TICKET_VIEW,
    PERMISSIONS.TICKET_REPLY,
    PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW,
    PERMISSIONS.TRAINING_CENTER_REPORT_VIEW
  ]
};

const { Option } = Select;

interface FormProps {
  countries: Country[];
  disciplines: Discipline[] | undefined;
  errors?: { [key: string]: string };
  isLoading?: boolean;
  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

const RegisterTrainingCenterForm: React.FC<FormProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedCountryCity, setSelectedCountryCity] = useState<number[]>();

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: (values) => {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  const eventTypeOptions = Object.values(EVENT_TYPES)
    .filter(
      (e) =>
        e !== EVENT_TYPES.COMMISSIONING_ASSESSMENT &&
        e !== EVENT_TYPES.COMMISSIONING_TRAINING
    )
    .map((e) => ({
      label: t(`eventTypes.${e}`),
      value: e
    }));

  return (
    <Form
      layout="vertical"
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={4}>
        <Col md={24}>
          <Form.Item
            label={t("trainingCenters.name")}
            validateStatus={formik.errors.name && "error"}
            help={formik.errors.name}
          >
            <Input
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={14}>
          <Form.Item
            label={t("city")}
            validateStatus={formik.errors.cityId && "error"}
            help={formik.errors.cityId}
          >
            <CountryCitySelectCascader
              value={selectedCountryCity}
              countries={props.countries}
              onChange={(values: number[]) => {
                setSelectedCountryCity(values);
                formik.setFieldValue("cityId", values[1]);
              }}
            />
          </Form.Item>
        </Col>

        <Col md={10}>
          <Form.Item
            label={t("address")}
            validateStatus={formik.errors.address && "error"}
            help={formik.errors.address}
          >
            <Input
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("email")}
            validateStatus={formik.errors.email && "error"}
            help={formik.errors.email}
          >
            <Input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("firstName")}
            validateStatus={formik.errors.firstName && "error"}
            help={formik.errors.firstName}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("lastName")}
            validateStatus={formik.errors.lastName && "error"}
            help={formik.errors.lastName}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("jobTitle")}
            validateStatus={formik.errors.position && "error"}
            help={formik.errors.position}
          >
            <Input
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("disciplines.disciplines")}
            validateStatus={formik.errors.specialities && "error"}
            help={formik.errors.specialities}
          >
            <Select
              allowClear
              maxTagCount={3}
              mode="multiple"
              onChange={(v) => formik.setFieldValue("specialities", v)}
            >
              {props.disciplines?.map((discipline) => (
                <Option key={discipline.id} value={discipline.id}>
                  {i18n.language === "en"
                    ? discipline.nameEn
                    : discipline.nameRu}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("eventTypes.eventTypes")}
            validateStatus={formik.errors.eventTypes && "error"}
            help={formik.errors.eventTypes}
          >
            <Row>
              <Col span={24}>
                <Checkbox.Group
                  style={{ width: "100%" }}
                  value={formik.values.eventTypes}
                  onChange={(v) => formik.setFieldValue("eventTypes", v)}
                >
                  <Row>
                    {eventTypeOptions.map((option) => (
                      <Col key={option.value} span={6}>
                        <Checkbox value={option.value}>{option.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item>
            <Row>
              <Col span={6}>
                <Checkbox
                  name="csrEnabled"
                  checked={formik.values.csrEnabled}
                  onChange={formik.handleChange}
                >
                  {t("csrPhase")}
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox
                  name="commissioningEnabled"
                  checked={formik.values.commissioningEnabled}
                  onChange={formik.handleChange}
                >
                  {t("commissioning")}
                </Checkbox>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item
            label={t("permissions.permissions")}
            validateStatus={formik.errors.permissions && "error"}
            help={formik.errors.permissions}
          >
            <PermissionCheckboxGroup
              options={TRAINING_CENTER_PERMISSION_OPTION_LIST}
              value={formik.values.permissions}
              onChange={(v) => formik.setFieldValue("permissions", v)}
            />
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("register")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default RegisterTrainingCenterForm;
