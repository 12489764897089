import { ruCommon } from "./common";
import { ruLogin } from "./login";
import { ruMembers } from "./members";
import { ruMeta } from "./meta";
import { ruPermissions } from "./permissions";
import { ruProfile } from "./profile";
import { ruReports } from "./reports";
import { ruResetPassword } from "./resetPassword";
import { ruSubcontractors } from "./subcontractors";
import { ruTrainingCenters } from "./trainingCenters";
import { ruContractors } from "./contractors";
import { ruEmployees } from "./employees";
import { ruValidation } from "./validation";
import { ruMouCategory } from "./mouCategory";
import { ruRequests } from "./requests";
import { ruContract } from "./contract";
import { ruEvents } from "./events";
import { ruCertificates } from "./certificates";
import { ruAcceptInvite } from "./acceptInvite";
import { ruDisciplines } from "./disciplines";
import { ruMigrations } from "./migrations";
import { ruCraftProfiles } from "./craftProfiles";
import { ruCompetencyLevels } from "./competencyLevels";
import { ruUserGuide } from "./userGuide";
import { ruContacts } from "./contacts";

export const ruTranslation = {
  translation: {
    ...ruCommon,

    meta: ruMeta,
    reports: ruReports,
    members: ruMembers,
    trainingCenters: ruTrainingCenters,
    profile: ruProfile,
    login: ruLogin,
    resetPassword: ruResetPassword,
    acceptInvite: ruAcceptInvite,
    permissions: ruPermissions,
    subcontractors: ruSubcontractors,
    contractors: ruContractors,
    contract: ruContract,
    employees: ruEmployees,
    validation: ruValidation,
    mouCategory: ruMouCategory,
    requests: ruRequests,
    events: ruEvents,
    certificates: ruCertificates,
    disciplines: ruDisciplines,
    migrations: ruMigrations,
    craftProfiles: ruCraftProfiles,
    competencyLevels: ruCompetencyLevels,
    userGuide: ruUserGuide,
    contacts: ruContacts
  }
};
