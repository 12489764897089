import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { Avatar, Card, Col, Divider, Row, Space, Spin, Typography } from "antd";
import EmploymentStatusTag from "@components/common/craftProfiles/renderers/EmploymentStatusTag";
import CraftProfileUpperRow from "@components/common/craftProfiles/renderers/CraftProfileUpperRow";
import CraftProfileLowerRow from "@components/common/craftProfiles/renderers/CraftProfileLowerRow";
import {
  FlagOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  ScheduleOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined
} from "@ant-design/icons";

import { CraftProfileCard, MaritalStatusOptions } from "@models/craftProfiles";

import { craftProfilesApi } from "@api/craftProfilesApi";

import useDateFormatter from "@hooks/useDateFormat";
import { CraftProfileCardLowerRows } from "@constants/craftProfiles";

interface showFieldProps {
  iin: boolean;
}

interface Props {
  profileData: CraftProfileCard;
  isProfileDataLoading: boolean;
  id: string;
  showField?: showFieldProps;
}

const CraftProfileCardTemplate: React.FC<Props> = ({
  profileData,
  isProfileDataLoading,
  id,
  showField = { iin: false }
}) => {
  const [t, i18n] = useTranslation();
  const { formatter } = useDateFormatter();
  const [img, setImg] = useState<string | ArrayBuffer | null>();

  const profilePhotoQuery = useQuery(
    ["profilePhoto"],
    () => craftProfilesApi.getProfilePhotoById(id).then((res) => res.data),
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (profileData.profilePhoto) {
      profilePhotoQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData.profilePhoto]);

  useEffect(() => {
    if (profilePhotoQuery.data) {
      const response = profilePhotoQuery.data;
      const reader = new FileReader();
      reader.readAsDataURL(response);
      reader.onloadend = () => {
        const base64data = reader.result;
        setImg(base64data);
      };
    }
  }, [profilePhotoQuery.data]);

  return (
    <div className="profile-card">
      <Card style={{ marginBottom: 32, paddingTop: 0 }}>
        <Row style={{ marginBottom: "24px" }} gutter={50}>
          <Col md={4} offset={16}>
            <EmploymentStatusTag
              isEmployed={
                profileData?.isEmployed ? profileData.isEmployed : false
              }
            />
          </Col>
        </Row>
        <Row gutter={50}>
          <Col
            span={8}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <Spin
              spinning={profilePhotoQuery.isFetching || isProfileDataLoading}
            >
              {profileData.profilePhoto && img ? (
                <Row justify="center">
                  <Avatar
                    src={img}
                    alt="avatar"
                    shape="square"
                    style={{
                      width: "80%",
                      height: "auto",
                      maxHeight: "250px"
                    }}
                  />
                </Row>
              ) : (
                <UserOutlined
                  style={{
                    fontSize: "250px",
                    marginBottom: "30px",
                    width: "100%",
                    height: "auto"
                  }}
                />
              )}
            </Spin>
            <div>
              <CraftProfileUpperRow
                styledIcon={(style) => <ToolOutlined style={style} />}
                primaryText={
                  i18n.language === "ru"
                    ? profileData.discipline.nameRu
                    : profileData.discipline.nameEn
                }
                secondaryText={"discipline"}
                isFirstItem
              />
              <CraftProfileUpperRow
                styledIcon={(style) => <FlagOutlined style={style} />}
                primaryText={profileData.csrContractor}
                secondaryText={"csr"}
              />
            </div>
          </Col>

          <Col span={8}>
            <CraftProfileUpperRow
              styledIcon={(style) => <UserOutlined style={style} />}
              primaryText={profileData.fullName}
              secondaryText={"fullName"}
            />
            {showField.iin && (
              <CraftProfileUpperRow
                styledIcon={(style) => <IdcardOutlined style={style} />}
                primaryText={profileData.iin}
                secondaryText={"filters.iin"}
              />
            )}
            <CraftProfileUpperRow
              styledIcon={(style) => <MailOutlined style={style} />}
              primaryText={profileData.emailAddress}
              secondaryText={"email"}
            />
            <CraftProfileUpperRow
              styledIcon={(style) => <PhoneOutlined style={style} />}
              primaryText={profileData.phoneNumber}
              secondaryText={"number"}
            />
            <CraftProfileUpperRow
              styledIcon={(style) => <HomeOutlined style={style} />}
              primaryText={profileData.address}
              secondaryText={"address"}
            />
            <CraftProfileUpperRow
              styledIcon={(style) => <ScheduleOutlined style={style} />}
              primaryText={
                profileData.birthDate &&
                formatter(profileData.birthDate, "short-date")
              }
              secondaryText={"dateOfBirth"}
            />
            <CraftProfileUpperRow
              styledIcon={(style) =>
                profileData.maritalStatus === MaritalStatusOptions.MARRIED ? (
                  <TeamOutlined style={style} />
                ) : (
                  <UserOutlined style={style} />
                )
              }
              primaryText={`${t(
                `craftProfiles.maritalStatusOptions.${profileData.maritalStatus}`
              )}`}
              secondaryText={"martialStatus"}
            />
          </Col>

          <Col span={8}>
            <Space direction="vertical">
              <Typography.Text>
                {i18n.language === "ru"
                  ? profileData.discipline.nameRu
                  : profileData.discipline.nameEn ||
                    t("craftProfiles.professionNA")}
              </Typography.Text>
              <Typography.Text>
                {profileData.hiredByName && profileData.isEmployed
                  ? profileData.hiredByName
                  : t("craftProfiles.employerNA")}
              </Typography.Text>
            </Space>
          </Col>
        </Row>

        <Divider />

        {CraftProfileCardLowerRows.map((rowName) => (
          <CraftProfileLowerRow
            key={rowName}
            title={rowName}
            profileDetails={profileData[rowName]}
          />
        ))}
      </Card>
    </div>
  );
};

export default CraftProfileCardTemplate;
