import axios from "axios";

import { DuplicatedHeadcount, EventEmployeeGradeType } from "@models/event";
import {
  MouCategoryReportTableData,
  MouCategoryReportType,
  CraftProfilesReportData
} from "@models/report";
import {
  CommissioningReportData,
  MouCategoryReportChartData,
  PerformanceReportData
} from "@models/report";

import { PaginatedResponse } from "@api";

const getMouCategoryChartsData = (reportType: MouCategoryReportType) =>
  reportType === MouCategoryReportType.DISCIPLINE
    ? axios.get<MouCategoryReportChartData[]>(
        `/api/dashboard/mou-chart/by-disciplines`
      )
    : axios.get<MouCategoryReportChartData[]>(
        `/api/dashboard/mou-chart/by-positions`
      );

const getMouCategoryTableData = (
  reportType: MouCategoryReportType,
  dateRange: {
    dateStart?: string;
    dateEnd?: string;
  }
) =>
  reportType === MouCategoryReportType.DISCIPLINE
    ? axios.get<MouCategoryReportTableData[]>(
        `/api/dashboard/mou-statistic/by-disciplines`,
        {
          params: { ...dateRange }
        }
      )
    : axios.get<MouCategoryReportTableData[]>(
        `/api/dashboard/mou-statistic/by-positions`,
        {
          params: { ...dateRange }
        }
      );

const getMouCategoryTableDataPTD = (reportType: MouCategoryReportType) =>
  reportType === MouCategoryReportType.DISCIPLINE
    ? axios.get<MouCategoryReportTableData[]>(
        `/api/dashboard/mou-statistic/by-disciplines`,
        {
          params: { isPtd: true }
        }
      )
    : axios.get<MouCategoryReportTableData[]>(
        `/api/dashboard/mou-statistic/by-positions`,
        {
          params: { isPtd: true }
        }
      );

const getAdminPerformanceReport = (eventType: number, filterData: {}) =>
  axios.get<PerformanceReportData>(`/api/dashboard/main`, {
    params: { ...filterData, type: eventType }
  });

const getContractorPerformanceReport = (
  contractorId: number,
  eventType: number,
  filterData: {}
) =>
  axios.get<PerformanceReportData>(
    `/api/contractor/${contractorId}/dashboard/main`,
    {
      params: { ...filterData, type: eventType }
    }
  );

const getTcPerformanceReport = (
  trainingCenterId: number,
  eventType: number,
  filterData: {}
) =>
  axios.get<PerformanceReportData>(
    `/api/training-center/${trainingCenterId}/dashboard/main`,
    {
      params: { ...filterData, type: eventType }
    }
  );

const getAdminCommissioningReport = (eventType: number, filterData: {}) =>
  axios.get<CommissioningReportData>(`/api/dashboard/commissioning`, {
    params: { ...filterData, type: eventType }
  });

const getTcCommissioningReport = (
  trainingCenterId: number,
  eventType: number,
  filterData: {}
) =>
  axios.get<CommissioningReportData>(
    `/api/training-center/${trainingCenterId}/dashboard/commissioning`,
    {
      params: { ...filterData, type: eventType }
    }
  );

const getContractorCommissioningReport = (
  contractorId: number,
  eventType: number,
  filterData: {}
) =>
  axios.get<CommissioningReportData>(
    `/api/contractor/${contractorId}/dashboard/commissioning`,
    {
      params: { ...filterData, type: eventType }
    }
  );

const getCraftAdminProfilesReport = (filterData: {}) =>
  axios.get<CraftProfilesReportData>(`/api/dashboard/hr-profiles`, {
    params: { ...filterData }
  });

const getDuplicatedHeadcounts = (reportType: number, filterData: {}) =>
  axios.get<DuplicatedHeadcount>(`/api/dashboard/duplicated-headcount`, {
    params: { ...filterData, type: reportType }
  });

const getDuplicatedHeadcountsDetails = (
  reportType: number,
  page: number,
  filterData: {}
) =>
  axios.get<
    PaginatedResponse<{
      count: number;
      rows: {
        iin: string;
        contractorId: number;
        trainingCenterId: number;
        citizenship: string;
        contractor: string;
        trainingCenter: string;
        discipline: string;
        eventType: string;
        overallGrade: EventEmployeeGradeType;
        eventDate: string;
      }[];
    }>
  >(`/api/dashboard/duplicated-headcount-details`, {
    params: { ...filterData, type: reportType, page }
  });
export const reportsApi = {
  getMouCategoryChartsData,
  getMouCategoryTableData,
  getMouCategoryTableDataPTD,
  getAdminPerformanceReport,
  getTcPerformanceReport,
  getContractorPerformanceReport,
  getAdminCommissioningReport,
  getTcCommissioningReport,
  getContractorCommissioningReport,
  getCraftAdminProfilesReport,
  getDuplicatedHeadcounts,
  getDuplicatedHeadcountsDetails
};
