import React, { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { Button, notification, Modal } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import PageMeta from "@components/PageMeta";
import ContractorsTable from "@components/contractors/ContractorsTable";
import SearchSection from "@components/contractors/SeacrhSection";
import ContractorsForm from "@components/admin/contractors/ContractorsForm";
import EditContractorForm from "@components/admin/contractors/EditContractorForm";
import ExportMembersButton from "@components/common/ExportMembersButton";

import { Contractor, ContractorEdit } from "@models/contractor";

import { contractorsApi } from "@api/contractorsApi";
import { countryCityApi } from "@api/countryCityApi";
import { trainingCenterApi } from "@api/trainingCenterApi";

import { transformValidationError } from "@utils/errorHelper";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { usePagination } from "@hooks/usePagination";

export const ContractorsPage: React.FC = () => {
  const [t] = useTranslation();
  const { page, setPage, setPagination } = usePagination();
  const [searchQuery, setSearchQuery] = useState<
    { bin: string; name: string } | undefined
  >();
  const [isModalShown, setIsModalShown] = useState(false);
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [editableContractor, setEditableContractor] = useState<Contractor>();

  const fetchContractors = async (
    page: number,
    bin?: number,
    name?: string
  ) => {
    return contractorsApi
      .getContractorsPaginated(page, bin, name)
      .then((res) => res.data);
  };

  const { data, refetch, isLoading } = useQuery(
    ["getAllContractors", { searchQuery, page }],
    // TODO: add types
    (searchQuery: any) =>
      searchQuery.queryKey[1].searchQuery
        ? fetchContractors(
            page,
            searchQuery.queryKey[1].searchQuery.bin,
            searchQuery.queryKey[1].searchQuery.name
          )
        : fetchContractors(page),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage
        };
        setPagination(pagination);
      },
      refetchOnMount: false
    }
  );

  const countries = useQuery("getAllCountries", () =>
    countryCityApi.getAllCountries().then((res) => res.data)
  );

  const trainingCenters = useQuery("getAllTrainingCenters", () =>
    trainingCenterApi.getAllTrainingCenters().then((res) => res.data)
  );

  const inviteContractorMutation = useValidatedMutation({
    mutationFunction: (values: any) =>
      contractorsApi.createContractor({ payload: values }),
    onSuccess(_, values) {
      notification.success({
        message: t("members.inviteSuccess", { email: values.ownerEmail })
      });
      refetch();
      setIsModalShown(false);
    }
  });

  const editContractorMutation = useValidatedMutation({
    mutationFunction: ({
      id,
      payload
    }: {
      id: number;
      payload: ContractorEdit;
    }) => contractorsApi.editContractor({ id, payload }),
    onSuccess() {
      notification.success({
        message: t("contractors.saveSuccess")
      });
      refetch();
      handleEditModal();
    }
  });

  const deleteContractorMutation = useValidatedMutation({
    mutationFunction: (id: number) => contractorsApi.deleteContractor(id),
    onSuccess() {
      notification.success({
        message: t("contractors.contractorDeleted")
      });
      refetch();
      setIsModalShown(false);
    }
  });

  const editErrors = useMemo(
    () => transformValidationError(editContractorMutation.error),
    [editContractorMutation.error]
  );

  const inviteErrors = useMemo(
    () => transformValidationError(inviteContractorMutation.error),
    [inviteContractorMutation.error]
  );

  const handleSearchQuery = (searchParams: { bin: string; name: string }) => {
    setSearchQuery(searchParams);
  };

  const handleModal = (item?: Contractor) => {
    setEditableContractor(item ? item : undefined);
    setIsModalShown(!isModalShown);
  };

  const handleEditModal = (item?: Contractor) => {
    setEditableContractor(item ? item : undefined);
    setIsEditModalShown(!isEditModalShown);
  };

  const paginationSettings = {
    hideOnSinglePage: true,
    total: data?.totalItems,
    current: data?.currentPage,
    pageSize: data?.pageSize,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={t("contractors.contractors")} />

      <AppPageHeader
        title={t("contractors.contractors")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "admin" },
          { breadcrumbName: t("contractors.contractors"), path: "contractors" }
        ]}
        extra={[
          <ExportMembersButton
            url="/api/admin/export/users/contractors"
            fileName={`export_contractor_users.xlsx`}
          />,
          <Button
            key="2"
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => {
              handleModal();
            }}
          >
            {t("contractors.registerContractor")}
          </Button>
        ]}
      />
      <SearchSection handleSearchQuery={handleSearchQuery} />
      <ContractorsTable
        data={data?.results}
        setEditedContractor={setEditableContractor}
        handleModal={handleEditModal}
        handleDelete={deleteContractorMutation.mutate}
        isLoading={isLoading}
        pagination={paginationSettings}
      />
      <Modal
        visible={isModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("contractors.registerContractor")}
        footer={null}
        width={800}
        onCancel={() => {
          handleModal();
          inviteContractorMutation.reset();
        }}
      >
        <ContractorsForm
          isLoading={inviteContractorMutation.isLoading}
          errors={inviteErrors}
          contractor={editableContractor}
          countries={countries.data || []}
          onCancel={() => {
            handleModal();
            inviteContractorMutation.reset();
          }}
          onSubmit={(values) => {
            inviteContractorMutation.mutate(values);
          }}
        />
      </Modal>
      <Modal
        visible={isEditModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("contractors.editContractor")}
        footer={null}
        width={800}
        onCancel={() => {
          handleEditModal();
          editContractorMutation.reset();
        }}
      >
        {editableContractor && (
          <EditContractorForm
            isLoading={editContractorMutation.isLoading}
            errors={editErrors}
            contractor={editableContractor}
            countries={countries.data || []}
            trainingCenters={trainingCenters.data || []}
            onCancel={() => {
              handleEditModal();
              editContractorMutation.reset();
            }}
            onSubmit={(values) => {
              editContractorMutation.mutate({
                id: editableContractor?.id,
                payload: values
              });
            }}
          />
        )}
      </Modal>
    </>
  );
};
