import { PERMISSIONS } from "@models/permissions";
import { PermissionGroup } from "@models/permissionsGroup";

export const SUBCONTRACTOR_PERMISSION_OPTION_LIST: PermissionGroup[] = [
  {
    values: [PERMISSIONS.EVENT_VIEW, PERMISSIONS.TRAINING_CENTER_VIEW],
    label: "eventView",
    dependentPermissions: [
      { values: [PERMISSIONS.EVENT_APPLY], label: "eventApply" },
      { values: [PERMISSIONS.EVENT_DETAILS_DOWNLOAD], label: "eventDownload" }
    ]
  },
  {
    values: [PERMISSIONS.REPORT_VIEW],
    label: "reportView",
    dependentPermissions: [
      {
        values: [PERMISSIONS.REPORT_DOWNLOAD_DUMP],
        label: "reportDownloadDump"
      }
    ]
  },

  {
    values: [PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW, PERMISSIONS.CONTRACT_VIEW],
    label: "craftEmployeeView",
    dependentPermissions: [
      {
        values: [
          PERMISSIONS.CONTRACTOR_EMPLOYEE_CREATE,
          PERMISSIONS.CONTRACTOR_EMPLOYEE_UPDATE,
          PERMISSIONS.CONTRACTOR_EMPLOYEE_DELETE,
          PERMISSIONS.CONTRACTOR_EMPLOYEE_REJECT
        ],
        label: "craftEmployeeEdit"
      }
    ]
  },

  {
    values: [PERMISSIONS.CERTIFICATE_VIEW],
    label: "certificateView",
    dependentPermissions: [
      {
        values: [PERMISSIONS.CERTIFICATE_DOWNLOAD],
        label: "certificateDownload"
      }
    ]
  }
];
