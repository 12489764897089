import { useContext, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { IdcardOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  notification,
  Result,
  Row,
  Skeleton,
  Typography
} from "antd";
import { EmployeeFacilityAccessCard } from "@components/common/employees/EmployeeFacilityAccessCard";
import { EmployeePersonalInfoCard } from "@components/common/employees/EmployeePersonalInfoCard";
import { EmployeeWorkingInfoCard } from "@components/common/employees/EmployeeWorkingInfoCard";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { EmployeeEventHistoryItemCard } from "@components/common/employees/EmployeeEventHistoryItemCard";
import { EmployeeFacilityAccessForm } from "@components/common/employees/EmployeeFacilityAccessForm";
import { EmployeePersonalInfoForm } from "@components/common/employees/EmployeePersonalInfoForm";
import { EmployeeWorkingInfoForm } from "@components/common/employees/EmployeeWorkingInfoForm";

import { EmployeeDetails } from "@models/employee";

import { employeeApi } from "@api/employeeApi";
import { contractApi } from "@api/contractApi";
import { countryCityApi } from "@api/countryCityApi";
import { subcontractorApi } from "@api/subcontractorApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { UserContext } from "@contexts/UserContext";

export const EmployeeDetailsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const { user, userContractor } = useContext(UserContext);
  const contractorId = user?.userRoles[0].entityId || 0;

  const [isPersonalInfoEdited, setIsPersonalInfoEdited] = useState(false);
  const [isWorkingInfoEdited, setIsWorkingInfoEdited] = useState(false);
  const [isFacilityAccessEdited, setIsFacilityAccessEdited] = useState(false);

  const params = useParams<{ id: string }>();
  const employeeId = Number(params.id);

  const employeeDetails = useQuery("getEmployee", () =>
    employeeApi
      .getEmployee({ contractorId, employeeId })
      .then((res) => res.data)
  );

  const eventHistory = useQuery(
    "getContractorEmployeeEventHistory",
    () =>
      employeeApi
        .getContractorEmployeeEventHistory({ employeeId, contractorId })
        .then((res) => res.data),
    {
      refetchOnReconnect: false
    }
  );

  const contractList = useQuery(["contractorContracts", contractorId], () =>
    contractApi.getContractorContracts(contractorId).then((res) => res.data)
  );

  const subcontractorList = useQuery(
    ["contractorSubcontractors", contractorId],
    () =>
      subcontractorApi
        .getSubcontractorList(contractorId)
        .then((res) => res.data)
  );
  const allContractorList = useMemo(() => {
    return userContractor
      ? [userContractor, ...(subcontractorList.data || [])]
      : [];
  }, [userContractor, subcontractorList.data]);

  const countryList = useQuery("allCountries", () =>
    countryCityApi.getAllCountries().then((res) => res.data)
  );

  const updateEmployeeMutation = useValidatedMutation({
    mutationFunction: (values: Partial<EmployeeDetails>) =>
      employeeApi.updateEmployee({
        contractorId,
        employeeId,
        employeeData: { ...ed, ...values }
      }),
    onSuccess() {
      employeeDetails.refetch();
    }
  });

  const ed = employeeDetails.data;

  const pageTitle = ed
    ? `${ed.firstName} ${ed.lastName}`
    : t("employees.employee") + " #" + employeeId;

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={
          <>
            {t("employees.employeePassport")} <IdcardOutlined />
          </>
        }
        breadcrumbs={[
          { breadcrumbName: "CT", path: "subcontractor" },
          {
            breadcrumbName: t("employees.employeesPassport"),
            path: "employees"
          },
          {
            breadcrumbName: pageTitle,
            path: employeeId.toString()
          }
        ]}
        extra={[
          <Button
            type="primary"
            onClick={() => history.push("/subcontractor/employees")}
          >
            {t("employees.backToList")}
          </Button>
        ]}
      />

      <Typography.Title level={5}>
        {pageTitle}

        <Typography.Text
          type="secondary"
          style={{ marginLeft: 8, fontWeight: "normal" }}
        >
          {ed ? `- ${ed.workLocationArea}, ${ed.position}` : ""}
        </Typography.Text>
      </Typography.Title>

      <Modal
        visible={isPersonalInfoEdited}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("employees.updatePersonalInfo")}
        footer={null}
        width={900}
      >
        <EmployeePersonalInfoForm
          omitIin
          isLoading={updateEmployeeMutation.isLoading}
          employee={ed}
          countries={countryList.data}
          submitText={t("save")}
          onCancel={() => {
            setIsPersonalInfoEdited(false);
          }}
          onSubmit={(values) => {
            updateEmployeeMutation.mutateAsync(values).then(() => {
              notification.success({
                message: t("employees.personalInfoUpdated")
              });

              setIsPersonalInfoEdited(false);
            });
          }}
        />
      </Modal>

      <Modal
        visible={isWorkingInfoEdited}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("employees.updateWorkingInfo")}
        footer={null}
        width={900}
      >
        <EmployeeWorkingInfoForm
          isLoading={updateEmployeeMutation.isLoading}
          employee={ed}
          contracts={contractList.data}
          contractors={allContractorList}
          submitText={t("save")}
          onCancel={() => setIsWorkingInfoEdited(false)}
          onSubmit={(values) => {
            updateEmployeeMutation.mutateAsync(values).then(() => {
              notification.success({
                message: t("employees.workingInfoUpdated")
              });

              setIsWorkingInfoEdited(false);
            });
          }}
        />
      </Modal>

      <Modal
        visible={isFacilityAccessEdited}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("employees.updateFacilityAccess")}
        footer={null}
        width={900}
      >
        <EmployeeFacilityAccessForm
          isLoading={updateEmployeeMutation.isLoading}
          employee={ed}
          submitText={t("save")}
          onCancel={() => setIsFacilityAccessEdited(false)}
          onSubmit={(values) => {
            updateEmployeeMutation.mutateAsync(values).then(() => {
              notification.success({
                message: t("employees.facilityAccessUpdated")
              });

              setIsFacilityAccessEdited(false);
            });
          }}
        />
      </Modal>

      {employeeDetails.data && (
        <Row gutter={8}>
          <Col md={12} lg={8}>
            <EmployeePersonalInfoCard
              personalInfo={ed}
              editable
              onEdit={() => {
                setIsPersonalInfoEdited(true);
              }}
            />
          </Col>

          <Col md={12} lg={8}>
            <EmployeeWorkingInfoCard
              personalInfo={ed}
              editable
              onEdit={() => setIsWorkingInfoEdited(true)}
            />
          </Col>

          <Col md={12} lg={8}>
            <EmployeeFacilityAccessCard
              personalInfo={ed}
              editable
              onEdit={() => setIsFacilityAccessEdited(true)}
            />
          </Col>
        </Row>
      )}

      {employeeDetails.error && (
        <Result
          status="404"
          title={t("error.errorOccured")}
          subTitle={t("employees.notFound", { id: employeeId })}
        />
      )}

      {employeeDetails.isLoading && (
        <Row gutter={[8, 16]}>
          <Col md={12} lg={8}>
            <Skeleton active />
          </Col>

          <Col md={12} lg={8}>
            <Skeleton active />
          </Col>

          <Col md={12} lg={8}>
            <Skeleton active />
          </Col>

          <Col md={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}

      {eventHistory.data && (
        <>
          <br />

          <Typography.Title level={3}>
            {t("events.trainingHistory")}
          </Typography.Title>

          <Row gutter={8}>
            {eventHistory.data.results
              .filter((eh) => !!eh.evaluatedAt || !!eh.excludedAt)
              .map((eh) => (
                <Col md={12} lg={8} key={eh.id}>
                  <EmployeeEventHistoryItemCard
                    eventHistoryItem={eh}
                    getCertificateLink={(eh) =>
                      `/api/contractor/${contractorId}/certificate/${eh.grade?.certificateId}/pdf`
                    }
                  />
                </Col>
              ))}
          </Row>
        </>
      )}
    </>
  );
};
