import { useTranslation } from "react-i18next";

import { Checkbox, Col, Row } from "antd";

import { PermissionGroup } from "@models/permissionsGroup";

interface Props {
  options: PermissionGroup[];

  value?: number[];
  onChange: (nextValue: number[]) => void;
}

export const PermissionCheckboxGroup = (props: Props) => {
  const [t] = useTranslation();

  const currentValue = props.value || [];

  const isPermissionChecked = (p: PermissionGroup) =>
    p.values.every((v) => currentValue.includes(v));

  return (
    <Row>
      {props.options.map((group) => {
        const isMainPermissionChecked = isPermissionChecked(group);
        const dependentPermissionsValues = group.dependentPermissions?.reduce(
          (acc: number[], cur) => {
            acc.push(...cur.values);
            return acc;
          },
          []
        );

        return (
          <Col xs={24} key={group.label} style={{ marginBottom: 4 }}>
            <Row>
              <Col span={12}>
                <Checkbox
                  checked={isMainPermissionChecked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.onChange([...currentValue, ...group.values]);
                    } else {
                      props.onChange([
                        ...currentValue.filter(
                          (v) =>
                            !group.values.includes(v) &&
                            !dependentPermissionsValues?.includes(v)
                        )
                      ]);
                    }
                  }}
                >
                  {t(`permissions.${group.label}`)}
                </Checkbox>
              </Col>
              <Col span={12}>
                {group.dependentPermissions?.map((p, index) => (
                  <Checkbox
                    disabled={!isMainPermissionChecked}
                    checked={isPermissionChecked(p)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        props.onChange([...currentValue, ...p.values]);
                      } else {
                        props.onChange([
                          ...currentValue.filter((v) => !p.values.includes(v))
                        ]);
                      }
                    }}
                    key={index}
                  >
                    {t(`permissions.${p.label}`)}
                  </Checkbox>
                ))}
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};
