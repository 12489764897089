import { useTranslation } from "react-i18next";
import { useState } from "react";

import { useFormik } from "formik";
import moment from "moment";

import { Button, Row, Space, Table, Typography } from "antd";

import {
  EventAttendance,
  EventAttendancePayload,
  EventDetails,
  EventEmployeeDetails,
  EventEmployeeStatus,
  EventStatus,
  EventType
} from "@models/event";
import { Instructor } from "@models/instructor";

import {
  prepareAttendancePayload,
  formatAttendance,
  getEmployeesByType,
  getUnsavedDays
} from "@utils/eventAttendanceHelper";

import DateColumnHeader from "./attendance/DateColumnHeader";
import DateColumnBody from "./attendance/DateColumnBody";
import SavedDateColumnBody from "./attendance/SavedDateColumnBody";
import SavedDateColumnHeader from "./attendance/SavedDateColumnHeader";
import EvaluateOrExcludeModal from "./attendance/EvaluateOrExcludeModal";

const { Column } = Table;

const API_SHORT_DATE_FORMAT = "DD-MM-YYYY";

const ZERO_ATTENDANCE_HOURS = 0;

interface FormValues {
  daysOff: string[];
  attendances: EventAttendance[];
}

interface Props {
  eventDetails: EventDetails;
  employees: EventEmployeeDetails[];
  attendances: EventAttendance[];
  isLoading: boolean;
  isAttendanceSaving: boolean;
  instructors: Instructor[];
  onAttendanceSave: (payload: {
    daysOff: string[];
    attendances: EventAttendancePayload[];
  }) => void;
  onExcludeOrEvaluate: () => void;
}

export const EventAttendanceTable: React.FC<Props> = ({
  eventDetails,
  attendances,
  ...props
}) => {
  const { t } = useTranslation();

  const isTrainingOrOJT =
    eventDetails.type === EventType.OJT_TRAINING ||
    (!eventDetails.isCommissioning && eventDetails.type === EventType.TRAINING);

  const canPreMature = [EventStatus.COMPLETED, EventStatus.INPROGRESS].includes(
    eventDetails.status
  );

  const initialDaysOff =
    eventDetails.daysOff?.map((day) =>
      moment(day).format(API_SHORT_DATE_FORMAT)
    ) || [];

  const formattedAttendances = formatAttendance(attendances);
  const {
    evaluatedEmployees,
    excludedEmployees,
    availableEmployees,
    sortedEmployees
  } = getEmployeesByType(
    props.employees.filter(
      (e) =>
        e.status === EventEmployeeStatus.REGISTERED ||
        e.status === EventEmployeeStatus.EXCLUDED
    )
  );

  const someEmployeeAttendance = formattedAttendances.find((a) =>
    availableEmployees.some((e) => e.employeeId === a.employeeId)
  );

  const unsavedDays = getUnsavedDays(eventDetails, someEmployeeAttendance);

  const formik = useFormik<FormValues>({
    initialValues: {
      daysOff: initialDaysOff,
      attendances: availableEmployees.map((e) => ({
        employeeId: e.employeeId,
        days: unsavedDays.map((date) => ({
          hours: isTrainingOrOJT ? eventDetails.hoursPerDay : 0,
          date
        }))
      }))
    },
    onSubmit: (values) => {
      props.onAttendanceSave(prepareAttendancePayload(values));
    }
  });

  const handleDayOffToggle = (date: string, value: boolean) => {
    if (value) {
      formik.setFieldValue("daysOff", [...formik.values.daysOff, date]);
    } else {
      const filteredDates = formik.values.daysOff?.filter(
        (day) => day !== date
      );

      formik.setFieldValue("daysOff", filteredDates);
    }
  };

  const findEmployeeAttendance = (employeeId: number) =>
    attendances.find((a) => a.employeeId === employeeId);

  const initialModalState = {
    attendance: undefined,
    date: undefined,
    savedAttendanceTotal: undefined
  };
  const [evaluateOrExcludeData, setEvaluateOrExcludeData] = useState<{
    attendance?: EventAttendance;
    date?: string;
    savedAttendanceTotal?: number;
  }>(initialModalState);
  const handleEvaluateOrExclude = (
    attendance: EventAttendance,
    date: string
  ) => {
    const employeeId = attendance?.employeeId;
    const savedAttendanceTotal =
      findEmployeeAttendance(employeeId)?.days?.reduce(
        (total, a) => total + a.hours,
        0
      ) || 0;
    setEvaluateOrExcludeData({
      attendance,
      date,
      savedAttendanceTotal
    });
  };

  const handleCancelEvaluateOrExclude = () => {
    setEvaluateOrExcludeData(initialModalState);
  };
  const handleSuccessEvaluateOrExclude = () => {
    setEvaluateOrExcludeData(initialModalState);
    props.onExcludeOrEvaluate();
  };

  return (
    <>
      <Table
        bordered
        locale={{ emptyText: t("noData") }}
        rowKey="employeeId"
        loading={props.isLoading}
        pagination={false}
        scroll={{ x: "max-content" }}
        dataSource={sortedEmployees}
      >
        <Column
          title={t("fullName")}
          fixed="left"
          width={300}
          render={(employee: EventEmployeeDetails) => (
            <Space direction="vertical" size="small">
              <Typography.Text>
                {employee.firstName} {employee.lastName}
              </Typography.Text>
              <Typography.Text type="secondary">{employee.iin}</Typography.Text>
            </Space>
          )}
        />
        {someEmployeeAttendance?.days.map(({ date }, colIndex) => {
          const formattedDate = date.format(API_SHORT_DATE_FORMAT);
          const isDayOff = eventDetails.daysOff?.includes(formattedDate);
          return (
            <Column
              width={100}
              align="center"
              key={colIndex}
              title={
                <SavedDateColumnHeader
                  date={formattedDate}
                  isDayOff={isDayOff}
                />
              }
              render={(employee: EventEmployeeDetails, _, rowIndex) => {
                const isEvaluated = evaluatedEmployees.includes(employee);
                const isExcluded = excludedEmployees.includes(employee);
                const employeeAttendance = formattedAttendances.find(
                  ({ employeeId }) => {
                    return employee.employeeId === employeeId;
                  }
                );
                const employeeDateAttendance = employeeAttendance?.days.find(
                  ({ date: employeeDate }) => {
                    return (
                      formattedDate ===
                      employeeDate.format(API_SHORT_DATE_FORMAT)
                    );
                  }
                );
                const isDateExcluded = moment(
                  moment(date, "DD-MM-YYYY").toDate()
                ).isSameOrAfter(
                  moment(employee.excludedAt).format("YYYY-MM-DD")
                );

                return (
                  <SavedDateColumnBody
                    employee={employee}
                    isExcluded={isExcluded}
                    isEvaluated={isEvaluated}
                    value={employeeDateAttendance?.hours}
                    isDayOff={isDayOff}
                    isDateExcluded={isDateExcluded}
                  />
                );
              }}
            />
          );
        })}

        {unsavedDays.map((date, colIndex) => (
          <Column
            width={100}
            align="center"
            title={
              <DateColumnHeader
                date={date}
                isDayOff={formik.values.daysOff.includes(date)}
                onDayOffToggle={handleDayOffToggle}
              />
            }
            render={(employee: EventEmployeeDetails, _, rowIndex) => {
              const isEvaluated = evaluatedEmployees.includes(employee);
              const isExcluded = excludedEmployees.includes(employee);
              const formatedDate = date.split("-").reverse().join("-");
              //console.log("date", formatedDate);
              //console.log("excludedAt", employee.excludedAt);
              const attendance =
                isEvaluated || isExcluded
                  ? findEmployeeAttendance(employee.employeeId)
                  : undefined;
              const formAttendanceIdx = formik.values.attendances.findIndex(
                (a) => a.employeeId === employee.employeeId
              );
              const attendanceValue = attendance
                ? attendance.days.find(
                    ({ date: attendanceDate }) =>
                      moment(attendanceDate).format(API_SHORT_DATE_FORMAT) ===
                      date
                  )?.hours
                : undefined;

              const isDateExcluded = moment(
                moment(date, "DD-MM-YYYY").toDate()
              ).isSame(moment(employee.excludedAt).format("YYYY-MM-DD"));

              return (
                <DateColumnBody
                  isDateExcluded={isDateExcluded}
                  employee={employee}
                  value={
                    isEvaluated || isExcluded
                      ? attendanceValue
                      : formik.values.attendances[formAttendanceIdx]?.days[
                          colIndex
                        ]?.hours
                  }
                  canEvaluateOrExclude={isTrainingOrOJT && canPreMature}
                  isEvaluated={isEvaluated}
                  isExcluded={isExcluded}
                  onChange={(value) => {
                    formik.setFieldValue(
                      `attendances.${formAttendanceIdx}.days.${colIndex}.hours`,
                      Number(value)
                    );
                  }}
                  onEvaluateOrExclude={() =>
                    handleEvaluateOrExclude(
                      formik.values.attendances[formAttendanceIdx],
                      date
                    )
                  }
                  isDayOff={formik.values.daysOff.includes(date)}
                />
              );
            }}
            key={colIndex}
          />
        ))}
        <Column
          width={100}
          align={"center"}
          title={t("events.totalHours")}
          render={(employee: EventEmployeeDetails) => {
            const savedAttendanceTotal =
              findEmployeeAttendance(employee.employeeId)
                ?.days?.filter(({ date }) => {
                  const formattedDate = moment(date).format("YYYY-MM-DD");
                  const isDayOff = eventDetails.daysOff?.some((day) =>
                    day.includes(formattedDate)
                  );

                  return !isDayOff;
                })
                .reduce((total, a) => total + a.hours, ZERO_ATTENDANCE_HOURS) ||
              ZERO_ATTENDANCE_HOURS;

            return (
              <Typography.Text strong>{savedAttendanceTotal}</Typography.Text>
            );
          }}
        />
      </Table>
      <Row justify="end" style={{ marginTop: "12px" }}>
        <Button
          type="primary"
          onClick={() => formik.submitForm()}
          loading={props.isAttendanceSaving}
          disabled={unsavedDays.length === 0 || !someEmployeeAttendance}
        >
          {t("save")}
        </Button>
      </Row>

      {evaluateOrExcludeData.date && evaluateOrExcludeData.attendance && (
        <EvaluateOrExcludeModal
          attendance={evaluateOrExcludeData.attendance}
          savedAttendanceTotal={evaluateOrExcludeData.savedAttendanceTotal || 0}
          eventDetails={eventDetails}
          date={evaluateOrExcludeData.date}
          instructors={props.instructors}
          onCancel={handleCancelEvaluateOrExclude}
          onSuccess={handleSuccessEvaluateOrExclude}
        />
      )}
    </>
  );
};
