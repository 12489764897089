import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { useFormik } from "formik";

import { FileAddOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { Container } from "@layouts/ApplicationLayout";

import { RequestReplyData } from "@models/requests";

const FormContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;

  width: 100%;
  padding: 16px 0;
`;

interface RequestReplyFormProps {
  onSubmit: (values: RequestReplyData) => void;
  isLoading?: boolean;
}

const RequestReplyForm: React.FC<RequestReplyFormProps> = (props) => {
  const [t] = useTranslation();
  const fileRef = useRef<HTMLInputElement>(null);

  const formik = useFormik<RequestReplyData>({
    initialValues: {
      text: "",
      attachmentFile: ""
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  return (
    <FormContainer>
      <Container>
        <Row gutter={8}>
          <Col span={19}>
            <Input
              name="text"
              placeholder={t("requests.replyPlaceholder")}
              onChange={formik.handleChange}
              value={formik.values.text}
            />
          </Col>
          <Col span={1}>
            <input
              name="attachmentFile"
              ref={fileRef}
              type="file"
              onChange={(event) => {
                if (event.currentTarget.files?.length) {
                  formik.setFieldValue(
                    "attachmentFile",
                    event.currentTarget.files[0]
                  );
                } else {
                  formik.setFieldValue("attachmentFile", undefined);
                }
              }}
              hidden
            />
            <Tooltip
              title={
                typeof formik.values.attachmentFile === "object"
                  ? formik.values.attachmentFile.name
                  : t("requests.replyAttachment")
              }
              placement="left"
            >
              <Button
                icon={<FileAddOutlined />}
                type={formik.values.attachmentFile ? "primary" : "default"}
                onClick={() => {
                  if (fileRef && fileRef.current) {
                    fileRef.current.click();
                  }
                }}
              />
            </Tooltip>
          </Col>
          <Col span={4}>
            <Button
              disabled={
                formik.values.text.length === 0 &&
                formik.values.attachmentFile === ""
              }
              loading={props.isLoading || false}
              icon={<SendOutlined />}
              type="primary"
              onClick={() => {
                formik.submitForm().then(() => {
                  formik.resetForm();
                });
              }}
            >
              {t("requests.reply")}
            </Button>
          </Col>
        </Row>
      </Container>
    </FormContainer>
  );
};

export default RequestReplyForm;
