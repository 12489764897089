import { EVENT_TYPES } from "@constants/eventTypes";

export const EVENT_TYPES_SELECT_OPTIONS: { label: string; value: number }[] = [
  {
    label: `eventTypes.${EVENT_TYPES.ASSESSMENT}`,
    value: EVENT_TYPES.ASSESSMENT
  },
  {
    label: `eventTypes.${EVENT_TYPES.TRAINING}`,
    value: EVENT_TYPES.TRAINING
  },
  {
    label: `eventTypes.${EVENT_TYPES.OJT_TRAINING}`,
    value: EVENT_TYPES.OJT_TRAINING
  },
  {
    label: `eventTypes.${EVENT_TYPES.PRESCREENING}`,
    value: EVENT_TYPES.PRESCREENING
  },
  {
    label: `eventTypes.${EVENT_TYPES.COMMISSIONING_ASSESSMENT}`,
    value: EVENT_TYPES.COMMISSIONING_ASSESSMENT
  },
  {
    label: `eventTypes.${EVENT_TYPES.COMMISSIONING_TRAINING}`,
    value: EVENT_TYPES.COMMISSIONING_TRAINING
  }
];
