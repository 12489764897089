import React from "react";
import { useTranslation } from "react-i18next";

import { UserOutlined } from "@ant-design/icons";

import { EmployeeDetails } from "@models/employee";

import useDateFormatter from "@hooks/useDateFormat";

import { EmployeeInfoCard } from "./EmployeeInfoCard";

type PersonalInfo = Partial<EmployeeDetails>;

interface Props {
  editable?: boolean;
  editDisabled?: boolean;

  onEdit?: () => void;

  personalInfo?: PersonalInfo;
}

export const EmployeePersonalInfoCard: React.FC<Props> = (props) => {
  const [t, i18n] = useTranslation();
  const { formatter } = useDateFormatter();

  const lang = i18n.language;

  const pi = props.personalInfo;

  return (
    <EmployeeInfoCard
      title={
        <>
          <UserOutlined /> {t("employees.personalInfo")}
        </>
      }
      fields={[
        { label: t("employees.lastName"), value: pi?.lastName },
        { label: t("employees.firstName"), value: pi?.firstName },
        { label: t("employees.middleName"), value: pi?.middleName },
        {
          label: t("employees.birthDate"),
          value: pi?.birthDate ? formatter(pi?.birthDate, "short-date") : "-",
          span: 12
        },
        { label: t("employees.iin"), value: pi?.iin, span: 12 },
        {
          label: t("employees.citizenship"),
          value:
            lang === "en" ? pi?.citizenShip?.nameEn : pi?.citizenShip?.nameRu,
          span: 12
        },
        {
          label: t("employees.residenceCountry"),
          value:
            lang === "en"
              ? pi?.residenceCountry?.nameEn
              : pi?.residenceCountry?.nameRu,
          span: 12
        },
        {
          label: t("employees.residenceRegion"),
          value: pi?.residenceRegion,
          span: 12
        },
        {
          label: t("employees.phoneNumber"),
          value: pi?.phoneNumber,
          span: 12
        }
      ]}
      editable={props.editable}
      editDisabled={props.editDisabled}
      onEdit={props.onEdit}
    />
  );
};
