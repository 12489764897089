import {
  CapacityMinNotificationEntitiesOptions,
  CreateEventPayload,
  EditEventPayload,
  EventDetails
} from "@models/event";
import { USER_ROLES } from "@models/roles";

const TrainingCenterRoles = [
  USER_ROLES.TRAINING_CENTER_MANAGER,
  USER_ROLES.TRAINING_CENTER_OWNER,
  USER_ROLES.TRAINING_CENTER_TEACHER
];

export const convertNotificationEntities = (
  eventData: EditEventPayload | CreateEventPayload
) => {
  if (eventData.capacityMinNotificationDate) {
    const capacityMinNotificationEntities: number[] = [];

    if (
      eventData.capacityMinNotificationEntities?.includes(
        CapacityMinNotificationEntitiesOptions.TCO
      )
    ) {
      capacityMinNotificationEntities.push(USER_ROLES.ADMINISTRATOR);
    }
    if (
      eventData.capacityMinNotificationEntities?.includes(
        CapacityMinNotificationEntitiesOptions.TRAINING_CENTER
      )
    ) {
      capacityMinNotificationEntities.push(...TrainingCenterRoles);
    }

    return {
      ...eventData,
      capacityMinNotificationEntities
    };
  }

  return eventData;
};

export const getNotificationEntitiesValues = (eventDetails: EventDetails) => {
  if (eventDetails.capacityMinNotifications.length > 0) {
    const roles = eventDetails.capacityMinNotifications.map(({ role }) => role);

    const values = [];
    if (roles.includes(USER_ROLES.ADMINISTRATOR)) {
      values.push(CapacityMinNotificationEntitiesOptions.TCO);
    }
    if (TrainingCenterRoles.every((role) => roles.includes(role))) {
      values.push(CapacityMinNotificationEntitiesOptions.TRAINING_CENTER);
    }

    return values;
  }

  return undefined;
};
export const getNotificationDate = (eventDetails: EventDetails) => {
  if (eventDetails.capacityMinNotifications.length > 0) {
    return eventDetails.capacityMinNotifications[0].dateNotify;
  }

  return undefined;
};

/**
 * Returns an array of numbers from `start` to `end`.
 * > Used to disable date pickers
 *
 * @param start number from
 * @param end   number to
 * @returns
 */
export const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
