import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Typography } from "antd";
import { UserguideTemplate } from "@components/common/userguides/UserguideTemplate";

import { ctUserguides } from "@utils/userguides/ct";

export const CtUserGuide: FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <Space direction="vertical" style={{ width: "100%", marginTop: "50px" }}>
      <Title>{t("login.userGuide")}</Title>
      <UserguideTemplate guides={ctUserguides} />
    </Space>
  );
};
