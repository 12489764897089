import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Typography } from "antd";
import { WhatNewTemplate } from "@components/common/WhatNew";

import { enTcoWhatNew } from "@utils/whatNew/enTco";
import { ruTcoWhatNew } from "@utils/whatNew/ruTco";

export const AdminWhatNew: FC = () => {
  const { t, i18n } = useTranslation();
  const { Title } = Typography;

  return (
    <Space direction="vertical" style={{ width: "100%", marginTop: "50px" }}>
      <Title>{t("whatsNew")}</Title>
      <WhatNewTemplate
        whatNews={i18n.language === "en" ? enTcoWhatNew : ruTcoWhatNew}
      />
    </Space>
  );
};
