import picture14 from "@assets/images/user-guide/3.14.png";
import picture15 from "@assets/images/user-guide/3.15.png";
import picture16 from "@assets/images/user-guide/3.16.png";
import picture17 from "@assets/images/user-guide/3.17.png";
import picture18 from "@assets/images/user-guide/3.18.png";
import picture19 from "@assets/images/user-guide/3.19.png";

export const reports = [
  {
    title: "Download data dump",
    html: `<ol>
    <li>
        <p>Data dump is a large file (.csv format) containing all the information about events, craft workers and their grades available in the system.&nbsp;</p><br>
        <p>In order to download data dump file follow the steps:</p>
        <ol>
            <li>
                <p>Press on Reports tab on panel menu</p>
            </li>
            <li>
                <p>Select Download data dump</p>
            </li>
            <li>
                <p>Press&nbsp;Generate data dump file&nbsp;or&nbsp;Generate commissioning data dump file&nbsp;button</p>
            </li>
            <li>
                <p>File will be shown in the Data dump files block</p>
            </li>
            <li>
                <p>Press&nbsp;Download&nbsp;button</p>
            </li>
        </ol><br>
        <p>Note:&nbsp;File generation will take up to 1 hour.&nbsp;</p><br>
        <p>Data Dump page details:</p>
        <ol>
            <li>
                <p>Report generation status: Completed, In progress, Failed.</p>
            </li>
            <li>
                <p>Generate data dump file button (for regular, non- commissioning events)</p>
            </li>
            <li>
                <p>Generate commissioning data dump file button (for commissioning events)</p>
            </li>
            <li>
                <p>Download button</p>
            </li>
            <li>
                <p>List of all previously generated files</p>
            </li>Picture 3.14
        </ol>
        <p></p>
        <p><img src=${picture14} width="624" height="227"></p>
    </li>
</ol>`
  },
  {
    title: "Performance Report",
    html: `<p>The report shows data of regular, non-commissioning event types: Assessment, Training, Pre- screening and OJT Training.</p>
<p>Details of the report are described below.</p>
<p><br></p>
<p>Report shows the following charts and buttons:</p>
<ol>
    <li>
        <p>Event type tab;</p>
    </li>
    <li>
        <p>Summary Statistic Cards;</p>
    </li>
    <li>
        <p>Trained employees number per year;</p>
    </li>
    <li>
        <p>Events by Training providers;</p>
    </li>
    <li>
        <p>Events by Contractors;</p>
    </li>
    <li>
        <p>Events by Disciplines;</p>
    </li>
    <li>
        <p>Event by Location;</p>
    </li>
    <li>
        <p>Citizenship of craft employees;</p>
    </li>
    <li>
        <p>Overall grade;</p>
    </li>
    <li>
        <p>Fail reasons;</p>
    </li>
    <li>
        <p>Download Performance Report button;</p>
    </li>
    <li>
        <p>Download Academic Hours Report button;</p>
    </li>
    <li>
        <p>Filtering;</p>
    </li>
    <li>
        <p>Switch Academic hours button.</p>
    </li>
</ol>
<p><br></p>
<p>Detailed information per each item is below.</p>
<p>&nbsp;</p>
<p>Page example:</p>
<p>Picture 3.15</p>
<p><img src=${picture15} width="624" height="448"></p>
<p>Picture 3.16</p>
<p><img src=${picture16} width="311" height="674"></p>
<p>Event type tab</p>
<p>TCO user can switch tabs to corresponding event types, so that the system will show the report on this specific type of event either Assessment, Training, Pre- screening or OJT.</p>
<p>Summary Statistic Cards:</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>Card label</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>In progress</p>
                </td>
                <td>
                    <p>The number of craft employees registered to In progress events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Completed</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Completed events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Evaluated</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Evaluated events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Excluded</p>
                </td>
                <td>
                    <p>The number of craft employees excluded from the events. Shown if any craft employee has been excluded, otherwise hidden.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>The number of all registered craft employees to all types of events</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p>Trained employees number per year&nbsp;line graph demonstrates fluctuation over the years.</p>
<p><br></p>
<p>Events by Training providers:</p>
<p>System shows the number of events run by each Training Center. &nbsp;</p>
<p><br></p>
<p>Events by Disciplines:</p>
<p>System shows the number of all craft employees taught per each discipline.&nbsp;</p>
<p>Also, discipline can be expanded if there&apos;s a plus sign (+) next to the discipline name.<br>In this case it shows all related disciplines and the number of employees taught per each discipline.</p>
<p><br></p>
<p>Event by Location:</p>
<p>Chart depicts a total number of all participants in a particular city as per &nbsp;event location, specified during the event creation stage.</p>
<p><br></p>
<p>Citizenship of craft employees:</p>
<p>Pie chart demonstrates employee citizenship countries. It shows the number and percentage.</p>
<p><br></p>
<p>Overall grade</p>
<p>Pie chart demonstrates employee overall grades. It shows the number and percentage.</p>
<p><br></p>
<p>Fail reasons</p>
<p>Pie chart demonstrates employee fail reasons. It shows the number and percentage.</p>
<p><br></p>
<p><br></p>
<p><b>Download Performance Report</b></p>
<p><br></p>
<p>In order to download Performance Report follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Download&nbsp;button</p>
    </li>
</ol>
<p><br></p>
<p><br></p>
<p><br></p>
<p><b>Download Academic Hours Report</b></p>
<p><br></p>
<p>In order to download Academic Hours Report follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Download Academic Hours Report&nbsp;button</p>
    </li>
</ol>
<p><br></p>
<p><b>Filtering</b></p>
<p>In order to use filters follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Filter&nbsp;icon</p>
    </li>
    <li>
        <p>Set up filter(s)</p>
    </li>
    <li>
        <p>Press&nbsp;Filter</p>
    </li>
</ol>
<p><br></p>
<p>To clear filters press&nbsp;<b>Clear filters button</b></p>
<p><br></p>
<p><b>Switch Academic hours button</b></p>
<p>Contractor users can view detailed information on the number of academic hours of the corresponding type of event (currently active tab) and it's total number in the following charts: Events by Training providers, Events by Contractors (shows my company), Events by Disciplines.</p>`
  },
  {
    title: "Commissioning Performance Report",
    html: `<p>The page shows data of commissioning event types: Assessment, Training.</p>
<p>Details of the report are described below.</p>
<p>TCO users view all data of all Training Centers, Contractors, Subcontractors and craft workers registered in the system.&nbsp;</p>
<p><br></p>
<p>Report shows the following charts and buttons:</p>
<ol>
    <li>
        <p>Event type tab;</p>
    </li>
    <li>
        <p>Summary Statistic Cards;</p>
    </li>
    <li>
        <p>by Contractors;</p>
    </li>
    <li>
        <p>by Disciplines;</p>
    </li>
    <li>
        <p>by Fail reasons;</p>
    </li>
    <li>
        <p>by Competency level;</p>
    </li>
    <li>
        <p>Download Report button;</p>
    </li>
    <li>
        <p>Filtering.</p>
    </li>
</ol>
<p><br></p>
<p>Picture 3.17</p>
<p><img src=${picture17} width="576" height="588"></p>
<p><b>Event type tab</b></p>
<p>TCO user can switch tabs to corresponding event types, so that the system will show the report on this specific type of event either Assessment or Training.</p>
<p><b>Summary Statistic Cards</b></p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p><b>Card label&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</b></p>
                </td>
                <td>
                    <p><b>Description</b></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>In progress</p>
                <td>
                    <p>The number of craft employees registered to In progress events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Completed</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Completed events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Evaluated</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Evaluated events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>The number of all registered craft employees to all types of events</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p><br></p>
<p><b>Events by Competency levels:</b></p>
<p>System shows the number of employees of each competency level: No competency, Trainee Technician, Junior Technician, Technician, Senior Technician.&nbsp;</p>
<p><br></p>
<p>The page shows data, described below, of craft employees&apos; event performance per each commissioning event type: Assessment, Training.</p>
<p><br></p>
<p><br></p>
<p>Report shows the following bar and pie charts:</p>
<p><b>Summary Statistic Cards</b></p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p><b>Label </b></p>
                </td>
                <td>
                    <p><b>Description</b></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>In progress&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</p>
                </td>
                <td>
                    <p>The number of employees registered to In progress events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Completed</p>
                </td>
                <td>
                    <p>The number of employees registered to Completed events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Evaluated</p>
                </td>
                <td>
                    <p>The number of employees registered to Evaluated events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>The number of all registered employees to all types of events</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p><b>Events by Competency levels:</b></p>
<p>System shows the number of events run by each Training Center. &nbsp;</p>
<p><br></p>
<p><b>Fail reasons</b></p>
<p>Pie chart demonstrates employee fail reasons. It shows the number and percentage.</p>
<p><br></p>
<p><br></p>
<p><b>Download Performance Report</b></p>
<p><br></p>
<p>In order to download Performance Report follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Commissioning Performance Report</p>
    </li>
    <li>
        <p>Press <b>Download</b> button</p>
    </li>
</ol>
<p><br></p>
<p><br></p>
<p><b>Filtering</b></p>
<p>In order to use filters follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Commissioning Performance Report</p>
    </li>
    <li>
        <p>Press <b>Filter</b> icon</p>
    </li>
    <li>
        <p>Set up filter(s)</p>
    </li>
    <li>
        <p>Press <b>Filter</b></p>
    </li>
</ol>
<p><br></p>
<p>To clear filters press <b>Clear filters button</b></p>`
  },
  {
    title: "Online Training Report",
    html: `<p dir="ltr">
    KPJV Online Training Report contains detailed information about events with
    non- craft employees saved in the system.
</p>
<p><br/><p>
<p>How to create an Online Training Report? </p>
<p dir="ltr">
    KPJV Online Training Reports are created by Contractor users by navigating
    to the Online Training Reports page and pressing the Create report button.
</p>
<br/>
<p dir="ltr">
    The following information is entered to create a Report:
</p>
<div dir="ltr" align="left">
    <table>
        <colgroup>
            <col width="98"/>
            <col width="100"/>
            <col width="260"/>
            <col width="146"/>
        </colgroup>
        <tbody>
            <tr>
                <td>
                    <p dir="ltr">
                    <b>
                        Field name
                    </b>
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                    <b>
                        Field type
                    </b>
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                    <b>
                        Value example
                    </b>
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                    <b>
                        Description
                    </b>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Last name
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Gilyazova
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employee\`s Last name
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        First name
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Aigerim
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employee\`s First name
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        IIN
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        IIN format
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        910130399211
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employee\`s IIN
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Employee number
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        KZ 3335
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employee number
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Department
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Engineering / Instrumentation / Main works
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employee\`s Department
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Title
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Instrument Engineer
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employee\`s Job Title
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Supervisor
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Robinson, Thomas
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Supervisor\`s name
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Training
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Development Technical: “As-built induction” &amp;
                        “Engineering Masters EM/VM induction” (2hr)
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Online Training\`s name
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Complete
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        date picker
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        15-Oct-21
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Date when online training ended/ completed
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Rev
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Dropdown menu.
                    </p>
                    <p dir="ltr">
                        Values:
                    </p>
                    <p dir="ltr">
                        Local, Expat
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employee\`s Rev: either Local or Expat
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br/></p>
<p>How to upload and create a KPJV Online Report?</p>
  <p dir="ltr">
    The KPJV Online Report can also be created via excel file upload:
    Contractor user fills out the excel and uploads in the modal window as
    shown on the example.
</p>
<p dir="ltr">
    Excel file template could be downloaded here too. In this case, the
    Contractor user will need to fill out the template and upload it in the
    system.
</p>
<br/>
<p dir="ltr">
    Create Report page example:
</p>
<p dir="ltr">
    Picture 3.18
</p>
<p dir="ltr">
    <img
        src=${picture18}
        width="624"
        height="332"
    />
</p>
<br/>
<br/>
<br/>
<p dir="ltr">
    Online Training Report details:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Name of the online training report;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Online training information;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Online training participants\` information.
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Picture 3.19
</p>
<p dir="ltr">
    <img
        src=${picture19}
        width="624"
        height="261"
    />
</p>
<div>
    <br/>
</div>
`
  }
];
