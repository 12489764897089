export const enCertificates = {
  search: "Certificates search",

  verification: "Certificate verification",
  certNumber: "Certificate number",
  iin: "IIN",
  ctpNumber: "CTP #",
  discipline: "Discipline",
  issuingBody: "Issuing body",
  level: "Level",
  academicHours: "Academic hours",
  completionDate: "Completion date",
  company: "Company/Client",
  projectName: "Project Name/Area",

  certNotFound: "Certificate not found",
  checkDataOrEnterNew:
    "Check that the entered data is correct or enter new ones",

  downloadCert: "Download certificate"
};
