import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography
} from "antd";

import {
  EventDetails,
  EventEmployeeDetails,
  EventEmployeeGradeType,
  FailReasonType
} from "@models/event";

interface GradeData {
  employeeId: number;
  isPassed?: number;
  theoreticalGrade?: number;
  practicalGrade?: number;
  failReasonType?: number;
  failReason?: string;
}

interface FormValues {
  grades: GradeData[];
}

interface Props {
  errors?: { [key: string]: string };
  isLoading?: boolean;
  eventData?: EventDetails;
  eventEmployees?: EventEmployeeDetails[];
  onSubmit?: (values: GradeData[]) => void;
}

const Column = Table.Column;

const FAIL_REASON_OPTIONS = [
  {
    value: FailReasonType.NO_SHOW,
    label: "events.failReason.1"
  },
  {
    value: FailReasonType.ATTENDANCE,
    label: "events.failReason.2"
  },
  {
    value: FailReasonType.UNDER_THE_INFLUENCE,
    label: "events.failReason.3"
  },
  {
    value: FailReasonType.PERFORMANCE,
    label: "events.failReason.4"
  },
  {
    value: FailReasonType.OTHER,
    label: "events.failReason.5"
  }
];

const GRADE_OPTIONS = [
  {
    label: `events.grade.completed`,
    value: EventEmployeeGradeType.PASSED
  },
  {
    label: `events.grade.incompleted`,
    value: EventEmployeeGradeType.FAILED
  }
];

export const PrescreeningEventGradeForm = (props: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      grades: []
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values.grades);
      }
    }
  });

  useEffect(() => {
    const initVal =
      props.eventEmployees?.map((ee) => ({ employeeId: ee.employeeId })) || [];
    formik.setFieldValue("grades", initVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eventEmployees]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Table
        dataSource={props.eventEmployees}
        rowKey="employeeId"
        pagination={false}
      >
        <Column
          title={t("events.participant")}
          render={(e: EventEmployeeDetails) => (
            <>
              {e.firstName}
              <br />
              {e.lastName}
              <br />
              <Typography.Text type="secondary">{e.iin}</Typography.Text>
            </>
          )}
        />

        <Column
          title={t("events.practicalGrade")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={
                props.errors?.[`[${i}].PracticalGrade`] && "error"
              }
              help={props.errors?.[`[${i}].PracticalGrade`]}
            >
              <InputNumber
                max={100}
                min={0}
                value={formik.values.grades?.[i]?.practicalGrade}
                onChange={(v) => {
                  formik.setFieldValue(`grades[${i}].practicalGrade`, v);
                }}
              />
            </Form.Item>
          )}
        />

        <Column
          title={t("events.theoreticalGrade")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={
                props.errors?.[`[${i}].TheoreticalGrade`] && "error"
              }
              help={props.errors?.[`[${i}].TheoreticalGrade`]}
            >
              <InputNumber
                max={100}
                min={0}
                value={formik.values.grades?.[i]?.theoreticalGrade}
                onChange={(v) =>
                  formik.setFieldValue(`grades[${i}].theoreticalGrade`, v)
                }
              />
            </Form.Item>
          )}
        />

        <Column
          title={t("events.overallGrade")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={props.errors?.[`[${i}].IsPassed`] && "error"}
              help={props.errors?.[`[${i}].IsPassed`]}
            >
              <Select
                value={formik.values.grades?.[i]?.isPassed}
                onChange={(v) => {
                  formik.setFieldValue(`grades[${i}].isPassed`, v);
                }}
              >
                {GRADE_OPTIONS.map((go) => (
                  <Select.Option value={go.value} key={go.value}>
                    {t(go.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />

        <Column
          title={t("events.failReason.failReason")}
          render={(_, __, i) =>
            formik.values.grades?.[i]?.isPassed ===
              EventEmployeeGradeType.FAILED && (
              <>
                <Form.Item
                  validateStatus={
                    props.errors?.[`[${i}].FailReasonType`] && "error"
                  }
                  help={props.errors?.[`[${i}].FailReasonType`]}
                >
                  <Select
                    value={formik.values.grades?.[i]?.failReasonType}
                    onChange={(v) => {
                      if (v !== FailReasonType.OTHER) {
                        formik.setFieldValue(
                          `grades[${i}].failReason`,
                          undefined
                        );
                      }

                      formik.setFieldValue(`grades[${i}].failReasonType`, v);
                    }}
                  >
                    {FAIL_REASON_OPTIONS.map((fr) => (
                      <Select.Option value={fr.value} key={fr.value}>
                        {t(fr.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {formik.values.grades?.[i]?.failReasonType ===
                  FailReasonType.OTHER && (
                  <Form.Item
                    validateStatus={
                      props.errors?.[`[${i}].FailReason`] && "error"
                    }
                    help={props.errors?.[`[${i}].FailReason`]}
                  >
                    <Input
                      name={`grades[${i}].failReason`}
                      value={formik.values.grades?.[i]?.failReason}
                      onChange={formik.handleChange}
                    />
                  </Form.Item>
                )}
              </>
            )
          }
        />
      </Table>

      <br />

      <Row justify="end">
        <Button type="primary" htmlType="submit" loading={props.isLoading}>
          {t("save")}
        </Button>
      </Row>
    </Form>
  );
};
