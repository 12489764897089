import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Button as _Button } from "antd";

const Button = styled(_Button)`
  border-color: rgba(234, 105, 73, 0.8) !important;
  color: #363636 !important;
  text-transform: uppercase;
`;
interface LogoutButtonProps {
  onClick: Function;
}
const LogoutButton: React.FC<LogoutButtonProps> = ({ onClick }) => {
  const [t] = useTranslation();
  return <Button onClick={() => onClick()}>{t("logoutBtn")}</Button>;
};

export default LogoutButton;
