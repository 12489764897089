import { SpecialityCategory } from "@models/discipline";

export const ruDisciplines = {
  [SpecialityCategory.Undefined]: "Без Категории",
  [SpecialityCategory.BoltFlangeJointAssembly]:
    "Сборка фланцевых болтовых соединений",
  [SpecialityCategory.GeneralCivilWorks]: "Общестроительные работы",
  [SpecialityCategory.CraneOperator]: "Оператор крана",
  [SpecialityCategory.DriverHeavyDutyVehicle]: "Водитель: грузовые автомобили",
  [SpecialityCategory.EquipmentOperatorLightEquipment]:
    "Оператор: оборудование малой мощности",
  [SpecialityCategory.EquipmentOperatorMediumEquipment]:
    "Оператор: оборудование средней мощности",
  [SpecialityCategory.EquipmentOperatorHeavyEquipment]:
    "Оператор: оборудование большой мощности",
  [SpecialityCategory.EXAwarenessAndCableGlanding]:
    "EX - Осведомленность и Уплотнения кабельных вводов",
  [SpecialityCategory.FieldSupervisor]: "Супервайзер на площадке",
  [SpecialityCategory.PainterIndustrialCoatingsInstaller]:
    "Специалист по нанесению промышленных покрытий",
  [SpecialityCategory.Rigger]: "Стропальщик",

  discipline: "Дисциплина",
  disciplines: "Дисциплины",
  createDiscipline: "Добавить Дисциплину",
  disciplineNameEn: "Название Дисциплины (англ.)",
  disciplineNameRu: "Название Дисциплины (рус.)",

  category: "Категория",
  eventType: "Тип События",
  competencyLevel: "Уровень Компетенций",

  competency: {
    standard: "Стандартная",
    proficient: "Незачет, Профессиональный",
    competent: "Незачет, Компетентный",
    technicians: "Младший техник, Обучаемый техник, Техник, Старший Техник"
  },

  speciality: {
    noCompetencyLevel: "Без Категории",
    boltFlange: "Сборка фланцевых болтовых соединений",
    generalCivilWorks: "Общестроительные работы",
    craneOperator: "Оператор крана",
    heavyDutyVehicle: "Водитель: грузовые автомобили",
    lightEquipment: "Оператор: оборудование малой мощности",
    mediumEquipment: "Оператор: оборудование средней мощности",
    heavyEquipment: "Оператор: оборудование большой мощности",
    cableGlanding: "EX - Осведомленность и Уплотнения кабельных вводов",
    fieldSupervisor: "Супервайзер на площадке",
    painter: "Специалист по нанесению промышленных покрытий",
    rigger: "Стропальщик"
  },

  disciplineAddedSuccess: "Дисциплина успешно добавлена",

  commissioning: "Введена в эксплуатацию",
  nonComissioning: "Не введена в эксплуатацию"
};
