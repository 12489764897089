import React from "react";
import { useTranslation } from "react-i18next";

import { Table, Typography } from "antd";

import { EventEmployeeDetails } from "@models/event";

const Column = Table.Column;

interface Props {
  eventEmployees?: EventEmployeeDetails[];
}

export const OjtEventGradeTable = (props: Props) => {
  const [t] = useTranslation();

  return (
    <Table dataSource={props.eventEmployees} pagination={false}>
      <Column
        title={t("events.participant")}
        render={(e: EventEmployeeDetails) => (
          <>
            {e.firstName}
            <br />
            {e.lastName}
            <br />
            <Typography.Text type="secondary">{e.iin}</Typography.Text>
          </>
        )}
      />

      <Column
        title={t("events.academicHours")}
        render={(e) => e.attendanceTotal}
      />

      <Column
        title={t("events.completionStatus")}
        render={(ee: EventEmployeeDetails) =>
          t(`events.grade.ojt.${ee.isPassed}`)
        }
      />
    </Table>
  );
};
