import React from "react";
import { useTranslation } from "react-i18next";

import { Table, Typography } from "antd";

import {
  EventEmployeeDetails,
  EventEmployeeGradeType,
  FailReasonType
} from "@models/event";

const Column = Table.Column;

interface Props {
  eventEmployees?: EventEmployeeDetails[];
}

export const NonCommissioningEventGradeTable = (props: Props) => {
  const [t] = useTranslation();

  return (
    <Table dataSource={props.eventEmployees} pagination={false}>
      <Column
        title={t("events.participant")}
        render={(e: EventEmployeeDetails) => (
          <>
            {e.firstName}
            <br />
            {e.lastName}
            <br />
            <Typography.Text type="secondary">{e.iin}</Typography.Text>
          </>
        )}
      />

      <Column
        title={t("events.academicHours")}
        render={(e) => e.attendanceTotal}
      />

      <Column
        title={t("events.practicalGrade")}
        render={(ee: EventEmployeeDetails) => ee.practicalGrade}
      />

      <Column
        title={t("events.theoreticalGrade")}
        render={(ee: EventEmployeeDetails) => ee.theoreticalGrade}
      />

      <Column
        title={t("events.overallGrade")}
        render={(ee: EventEmployeeDetails) => t(`events.grade.${ee.isPassed}`)}
      />

      <Column
        title={t("events.failReason.failReason")}
        render={(ee: EventEmployeeDetails) => {
          if (ee.isPassed !== EventEmployeeGradeType.FAILED) {
            return "-";
          }

          return ee.failReasonType === FailReasonType.OTHER
            ? ee.failReason
            : t(`events.failReason.${ee.failReasonType}`);
        }}
      />
    </Table>
  );
};
