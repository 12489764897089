import { PERMISSIONS } from "@models/permissions";

export const ADMIN_PERMISSION_OPTION_LIST: {
  label?: string;
  values: number[];
  dependsOn?: number;
}[] = [
  { values: [PERMISSIONS.REPORT_VIEW], label: "reportView" },
  {
    values: [PERMISSIONS.REPORT_DOWNLOAD_DUMP, PERMISSIONS.REPORT_DOWNLOAD],
    dependsOn: PERMISSIONS.REPORT_VIEW,
    label: "reportDownload"
  },
  { values: [PERMISSIONS.MEMBER_VIEW], label: "memberView" },
  {
    values: [
      PERMISSIONS.MEMBER_UPDATE,
      PERMISSIONS.MEMBER_DELETE,
      PERMISSIONS.MEMBER_INVITE
    ],
    dependsOn: PERMISSIONS.MEMBER_VIEW,
    label: "memberEdit"
  },
  {
    values: [PERMISSIONS.EVENT_DATA_UPLOAD_VIEW],
    label: "eventDataUploadView"
  },
  {
    values: [PERMISSIONS.EVENT_DATA_UPLOAD_EDIT],
    dependsOn: PERMISSIONS.EVENT_DATA_UPLOAD_VIEW,
    label: "eventDataUploadEdit"
  },
  {
    values: [PERMISSIONS.SPECIALITY_VIEW],
    label: "disciplineView"
  },
  {
    values: [PERMISSIONS.SPECIALITY_UPDATE],
    dependsOn: PERMISSIONS.SPECIALITY_VIEW,
    label: "disciplineEdit"
  },
  {
    values: [
      PERMISSIONS.TRAINING_CENTER_VIEW,
      PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_VIEW
    ],
    label: "trainingCenterView"
  },
  {
    values: [
      PERMISSIONS.TRAINING_CENTER_CREATE,
      PERMISSIONS.TRAINING_CENTER_DELETE,
      PERMISSIONS.TRAINING_CENTER_UPDATE
    ],
    dependsOn: PERMISSIONS.TRAINING_CENTER_VIEW,
    label: "trainingCenterEdit"
  },
  {
    values: [PERMISSIONS.CONTRACTOR_VIEW, PERMISSIONS.CONTRACT_VIEW],
    label: "contractorView"
  },
  {
    values: [
      PERMISSIONS.CONTRACTOR_CREATE,
      PERMISSIONS.CONTRACTOR_UPDATE,
      PERMISSIONS.CONTRACTOR_DELETE,
      PERMISSIONS.CONTRACT_CREATE,
      PERMISSIONS.CONTRACT_DELETE,
      PERMISSIONS.CONTRACT_UPDATE
    ],
    dependsOn: PERMISSIONS.CONTRACTOR_VIEW,
    label: "contractorEdit"
  },
  {
    values: [PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW],
    label: "craftEmployeeView"
  },
  {
    values: [
      PERMISSIONS.CONTRACTOR_EMPLOYEE_CREATE,
      PERMISSIONS.CONTRACTOR_EMPLOYEE_DELETE,
      PERMISSIONS.CONTRACTOR_EMPLOYEE_UPDATE,
      PERMISSIONS.CONTRACTOR_EMPLOYEE_REJECT,
      PERMISSIONS.NON_IIN_CRAFT_EMPLOYEE_UPDATE
    ],
    label: "craftEmployeeEdit"
  },
  {
    values: [PERMISSIONS.TRAINING_CENTER_REPORT_VIEW],
    label: "tcReportView"
  },
  {
    values: [PERMISSIONS.CERTIFICATE_VIEW, PERMISSIONS.CERTIFICATE_DOWNLOAD],
    label: "certificateView"
  },
  {
    values: [PERMISSIONS.EVENT_VIEW, PERMISSIONS.EVENT_DETAILS_DOWNLOAD],
    label: "eventView"
  },
  {
    values: [
      PERMISSIONS.EVENT_UPDATE,
      PERMISSIONS.EVENT_CANCEL,
      PERMISSIONS.CONTRACTOR_EMPLOYEE_REJECT
    ],
    dependsOn: PERMISSIONS.EVENT_VIEW,
    label: "eventEdit"
  },
  {
    values: [PERMISSIONS.EVENT_DELETE],
    dependsOn: PERMISSIONS.EVENT_VIEW,
    label: "eventDelete"
  },
  {
    values: [PERMISSIONS.EVENT_ACTIVATE],
    dependsOn: PERMISSIONS.EVENT_VIEW,
    label: "eventActivate"
  },
  {
    values: [PERMISSIONS.EVENT_APPLY],
    dependsOn: PERMISSIONS.EVENT_VIEW,
    label: "eventApply"
  }
];
