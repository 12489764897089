import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

import qs from "qs";
import Modal from "antd/lib/modal/Modal";

import { CraftProfileReport } from "@components/admin/reports/CraftProfileReport";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { CraftProfileReportFilterForm } from "@components/admin/reports/CraftProfileReportFilterForm";

import { disciplineApi } from "@api/disciplineApi";
import { trainingCenterApi } from "@api/trainingCenterApi";
import { contractorsApi } from "@api/contractorsApi";
import { reportsApi } from "@api/reportsApi";

import { useQueryParams } from "@hooks/useQueryParams";
import { useDefaultQuery } from "@hooks/useDefaultQuery";

export const CraftProfileReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const history = useHistory();

  const { ...initialFilters } = queryParams;

  const [filters, setFilters] = useState<{}>(initialFilters);

  const [isFilterModalShown, setIsFilterModalShown] = useState(false);

  const { data, isLoading } = useQuery(
    ["getAdminProfilesReport", filters],
    () =>
      reportsApi.getCraftAdminProfilesReport(filters).then((res) => res.data),
    {
      onSuccess() {
        history.push({
          search: qs.stringify({ ...filters }, { arrayFormat: "indices" })
        });
        setIsFilterModalShown(false);
      }
    }
  );

  const disciplines = useDefaultQuery("getAllDisciplines", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const trainingCenters = useDefaultQuery("getTcs", () =>
    trainingCenterApi.getAllTrainingCenters().then((res) => res.data)
  );

  const contractors = useDefaultQuery("getContractors", () =>
    contractorsApi.getContractors().then((res) => res.data)
  );

  const pageTitle = t("reports.profiles.profileReport");

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: "/admin/reports/performance"
          }
        ]}
      />
      <Modal
        visible={isFilterModalShown}
        destroyOnClose
        title={t("reports.filter")}
        maskClosable={false}
        width={600}
        footer={null}
        onCancel={() => {
          setIsFilterModalShown(false);
        }}
      >
        <CraftProfileReportFilterForm
          initialFilter={initialFilters}
          disciplines={disciplines.data || []}
          trainingCenters={trainingCenters.data || []}
          contractors={contractors.data || []}
          isLoading={isLoading}
          onSubmit={(filters) => setFilters(filters)}
        />
      </Modal>

      <CraftProfileReport
        report={data}
        isLoading={isLoading}
        onFilterClick={() => setIsFilterModalShown(true)}
      />
    </>
  );
};
