export const enSubcontractorWhatNew = [
  {
    title:
      "New feature: Addition of the 'location' field for all Event types: in Performance reports, Report filters. ",
    html: `<p><span style="font-weight: 400;">Users can view the location of the event including online and Tengiz. Do not mix up with the Training Center\`s city. </span></p>`
  },
  {
    title:
      "New feature: Candidate profiles: view, ‘hire’ profiles of local unemployed Training Centers` event participants as part of TCO CSR program. Available for TCO, Contractors, Subcontractors and Training Center Users.",
    html: `<p><span style="font-weight: 400;">Profile tab shows job- seeking craft worker profiles, which were created in the system so that Contractor/ Subcontractor users could view and hire profiles. This module also stores all the information in the system. </span></p>`
  },
  {
    title: "Changes in Reports",
    html: `<p><span style="font-weight: 400;">New functionality and some minor changes have been added to Reports.</span></p>`
  },
  {
    title:
      "Changes to eligibility requirements: the 1-month rule has been removed  for craft employees to reseat the event",
    html: `<p><span style="font-weight: 400;">With this change craft employees are not required to wait for one month to re-apply to the same type&amp; discipline of the event.</span></p>`
  }
];
