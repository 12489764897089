import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { Button, Skeleton, Space } from "antd";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import CraftProfileCardTemplate from "@components/common/craftProfiles/CraftProfileCardTemplate";
import EditCraftProfileModal from "@components/common/craftProfiles/modals/EditCraftProfileModal";
import DeleteCraftProfileModal from "@components/common/craftProfiles/modals/DeleteCraftProfileModal";

import { craftProfilesApi } from "@api/craftProfilesApi";

import { usePrintRef } from "@hooks/usePrintRef";

const CraftProfilesCardPage: React.FC = () => {
  const [t] = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [isEditProfileModalVisible, setEditProfileModalVisible] =
    useState<boolean>(false);

  const [isDeleteProfileModalVisible, setDeleteProfileModalVisible] =
    useState<boolean>(false);

  const {
    data: profileData,
    isFetching,
    refetch: refetchProfile
  } = useQuery(["craftProfile"], () =>
    craftProfilesApi.getCraftProfileById(id).then((res) => res.data)
  );

  const isEmployed = useMemo(
    () => profileData?.isEmployed,
    [profileData?.isEmployed]
  );

  const pageTitle = useMemo(() => {
    return `${t("craftProfiles.profile")}${
      profileData?.fullName ? ` - ${profileData.fullName}` : ""
    }`;
  }, [profileData?.fullName, t]);

  const { pageRef, handleDownloadReport } = usePrintRef(
    "pageRef",
    profileData?.fullName ? profileData.fullName : "profile"
  );

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: t("craftProfiles.profiles"),
            path: "/admin/craft-profiles"
          },
          {
            breadcrumbName: pageTitle,
            path: `/admin/craft-profile/${id}`
          }
        ]}
        extra={
          profileData ? (
            <Space>
              <EditCraftProfileModal
                refetchProfile={refetchProfile}
                isModalOpen={isEditProfileModalVisible}
                handleCloseModal={() => setEditProfileModalVisible(false)}
                handleOpenModal={() => setEditProfileModalVisible(true)}
                editedProfile={{ ...profileData, id }}
              />
              <Button
                onClick={handleDownloadReport}
                type="primary"
                icon={<DownloadOutlined />}
              >
                {t("download")}
              </Button>
              {!isEmployed && (
                <DeleteCraftProfileModal
                  profileId={Number(id)}
                  profileName={profileData.fullName}
                  isModalOpen={isDeleteProfileModalVisible}
                  handleOpenModal={() => setDeleteProfileModalVisible(true)}
                  handleCloseModal={() => setDeleteProfileModalVisible(false)}
                />
              )}
            </Space>
          ) : (
            <Space>
              <Button icon={<EditOutlined />} disabled>
                {t("edit")}
              </Button>
              <Button type="primary" icon={<DownloadOutlined />} disabled>
                {t("download")}
              </Button>
            </Space>
          )
        }
      />
      {profileData ? (
        <div ref={pageRef} id="pageRef" className="profile-card">
          <CraftProfileCardTemplate
            profileData={profileData}
            isProfileDataLoading={isFetching}
            showField={{ iin: true }}
            id={id}
          />
        </div>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};
export default CraftProfilesCardPage;
