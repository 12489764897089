import { FC } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { Space, Tooltip, Switch } from "antd";

interface Props {
  date: string;
  isDayOff: boolean;
  onDayOffToggle: (date: string, value: boolean) => void;
}

const DateColumnHeader: FC<Props> = (props) => {
  const [t] = useTranslation();
  const formattedDate = moment(props.date, "DD-MM-YYYY").format("DD.MM.YYYY");
  const isDateDayOff = true;

  return (
    <Space direction="vertical">
      <span>{formattedDate}</span>
      {isDateDayOff ? (
        <Tooltip title={t("events.setAsDayOff")}>
          <Switch
            size="small"
            checked={props.isDayOff}
            onChange={(checked) => {
              props.onDayOffToggle(props.date, checked);
            }}
          />
        </Tooltip>
      ) : null}
    </Space>
  );
};

export default DateColumnHeader;
