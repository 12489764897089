import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styled from "styled-components";

import { Menu, notification, Space } from "antd";
import { Layout as _Layout } from "antd";
import Logo from "@components/ui/Logo";
import ProfileButton from "@components/ui/ProfileButton";
import LogoutButton from "@components/ui/LogoutButton";
import LanguageSelector from "@components/ui/LanguageSelector";
import Navigation, { NavigationProps } from "@components/ui/Navigation";
import UserAgreementModal from "@components/common/profile/UserAgreementModal";

import enImage from "@assets/images/us.png";
import ruImage from "@assets/images/ru.png";
import { UserContext } from "@contexts/UserContext";
import { useIdleChecker } from "@hooks/useIdleChecker";
import { useMsal } from "@azure/msal-react";

const Layout = styled(_Layout)`
  min-height: 100vh;
`;

const Header = styled(_Layout.Header)`
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: auto;
`;

const Footer = styled(_Layout.Footer)`
  position: relative;
  margin-top: 32px;
  bottom: 0;
  background-color: #049aff !important;
`;

const FooterInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const { Content } = _Layout;

const LANGS = [
  { title: "ENGLISH", shortTitle: "EN", iconsrc: enImage, code: "en" },
  { title: "РУССКИЙ", shortTitle: "RU", iconsrc: ruImage, code: "ru" }
];

type ApplicationLayoutProps = NavigationProps;

const ApplicationLayout: React.FC<ApplicationLayoutProps> = ({
  children,
  navItems
}) => {
  const { t, i18n } = useTranslation();
  const { user, userRole } = useContext(UserContext);
  const { instance } = useMsal();
  const logoutUser = () => {
    instance.logoutRedirect();
  }
  
  const handleLogoutClick = async () => {
    await logoutUser();
  };

  const { subscribeToIdleChecker } = useIdleChecker({
    idleTimeOutSeconds: 600,
    onTimeOut: async () => {
      await logoutUser();

      notification.warn({
        message: t("idleLogout"),
        // `key` property doesn't let UI spawn more than one notification and allows the dismissing of the notification once logged in
        key: "idleLogout",
        duration: 0
      });
    },
    checkEvery: 1000
  });

  const guideLineLink = useMemo(
    () =>
      userRole === "trainingcenter"
        ? "/tc/user-guide"
        : `/${userRole}/user-guide`,
    [userRole]
  );

  const contactLineLink = useMemo(
    () =>
      userRole === "trainingcenter"
        ? "/tc/contact-us"
        : `/${userRole}/contact-us`,
    [userRole]
  );

  const whatsNewLineLink = useMemo(
    () =>
      userRole === "trainingcenter" ? "/tc/what-new" : `/${userRole}/what-new`,
    [userRole]
  );

  const aboutUsLineLink = useMemo(
    () =>
      userRole === "trainingcenter" ? "/tc/about-us" : `/${userRole}/about-us`,
    [userRole]
  );
  console.log(aboutUsLineLink);

  useEffect((): any => {
    const unsubscribe = subscribeToIdleChecker();

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <UserAgreementModal />
      <Header>
        <HeaderInner>
          <Space>
            <Logo />
            <Menu theme="light" mode="horizontal">
              <Menu.Item key="1">
                <Link to={aboutUsLineLink}>{t("aboutUs")}</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={guideLineLink}>{t("login.userGuide")}</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={whatsNewLineLink}>{t("whatsNew")}</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={contactLineLink}>{t("contacts.title")}</Link>
              </Menu.Item>
            </Menu>
          </Space>
          <Space>
            <LanguageSelector
              currentLangCode={i18n.language}
              onLangSwitch={(l: string) => i18n.changeLanguage(l)}
              langs={LANGS}
            />
            <Link to="/profile">
              <ProfileButton
                username={user ? user.firstName + " " + user.lastName : ""}
              />
            </Link>
            <LogoutButton onClick={handleLogoutClick} />
          </Space>
        </HeaderInner>
      </Header>
      <Content>
        <Navigation navItems={navItems} />
        <Container>{children}</Container>
      </Content>
      <Footer>
        <FooterInner></FooterInner>
      </Footer>
    </Layout>
  );
};

export default ApplicationLayout;
