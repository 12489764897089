import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Column from "antd/lib/table/Column";
import { useFormik } from "formik";

import { WarningOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Space,
  Typography,
  Form,
  Table,
  Checkbox,
  Tooltip,
  notification
} from "antd";

import { EmployeeDetails, EmployeeSearchResult } from "@models/employee";
import { EmployeeSearchStatus } from "@models/employee";

interface Props {
  seatsAvailable: number;
  employeeSearchResult?: EmployeeSearchResult[];
  employeeSearchLoading?: boolean;
  employeeSearchErrors?: { [key: string]: string };
  onEmployeeSearch?: (employeesIINs: string[]) => void;
  onClearSelectedEmployees?: () => void;
  onApplyEventStepChange?: (selectedEmployeesIINs: EmployeeDetails[]) => void;
}

export const ApplyEmployeeToEventSection: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeDetails[]>(
    []
  );
  const [notFoundEmployeesCount, setNotFoundEmployeesCount] =
    useState<number>(0);
  const [notEligbileEmployeesCount, setNotEligibleEmployeesCount] =
    useState<number>(0);
  const [duplicatedIINsCount, setDuplicatedIINsCount] = useState<number>(0);

  const searchEmployeesFormik = useFormik<{ employeesIINs: string[] }>({
    initialValues: {
      employeesIINs: []
    },
    onSubmit: (values) => {
      if (props.onEmployeeSearch) {
        props.onEmployeeSearch(values.employeesIINs);
      }
    }
  });

  useEffect(() => {
    const employees = props.employeeSearchResult || [];

    const eligibleEmployees = employees.filter(
      (e) => e.searchStatus === EmployeeSearchStatus.FOUND
    );

    if (eligibleEmployees.length <= props.seatsAvailable) {
      setSelectedEmployees(eligibleEmployees);
    } else {
      notification.warning({
        message: t("events.employeesAmountDoNotFitEvent")
      });
      setSelectedEmployees(eligibleEmployees.slice(0, props.seatsAvailable));
    }
    setNotFoundEmployeesCount(
      employees.filter((e) => e.searchStatus === EmployeeSearchStatus.NOT_FOUND)
        .length
    );
    setNotEligibleEmployeesCount(
      employees.filter(
        (e) => e.searchStatus === EmployeeSearchStatus.NOT_ELIGIBLE
      ).length
    );
    setDuplicatedIINsCount(
      employees.filter((e) => e.searchStatus === EmployeeSearchStatus.DUPLICATE)
        .length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employeeSearchResult]);

  useEffect(() => {
    if (props.employeeSearchErrors) {
      searchEmployeesFormik.setFieldError(
        "employeesIINs",
        props.employeeSearchErrors.iins
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employeeSearchErrors]);

  const isEn = i18n.language === "en";

  return (
    <Card
      title={
        <Typography.Title level={5}>
          {t("events.applyToTheEvent")}
        </Typography.Title>
      }
    >
      <Row justify="space-between">
        <Col span={10}>
          <Row gutter={20}>
            <Col span={12}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text type="secondary">
                  {t("events.searchByIIN")}:
                </Typography.Text>
                <Form.Item
                  validateStatus={
                    searchEmployeesFormik.errors.employeesIINs && "error"
                  }
                  help={searchEmployeesFormik.errors.employeesIINs}
                >
                  <Input.TextArea
                    onBlur={(e) => {
                      const { value } = e.target;
                      if (value.length) {
                        const employeesIINs = value.split("\n");
                        searchEmployeesFormik.setFieldValue(
                          "employeesIINs",
                          employeesIINs
                        );
                      } else {
                        searchEmployeesFormik.setFieldValue(
                          "employeesIINs",
                          []
                        );
                      }
                    }}
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col>
              <Space>
                <Typography.Text type="secondary">
                  {t("events.totalIINsEntered")}:
                </Typography.Text>
                <Space>
                  <Typography.Text strong>
                    {searchEmployeesFormik.values.employeesIINs.length}
                  </Typography.Text>
                </Space>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row wrap gutter={20}>
            <Col>
              <Space direction="vertical">
                <Typography.Text type="secondary">
                  {t("events.selected")}:
                </Typography.Text>
                <Space>
                  <Typography.Text strong>
                    {selectedEmployees.length}
                  </Typography.Text>
                  <span>{t("events.employees")}</span>
                </Space>
                <span style={{ color: "#2196f3" }}>
                  {t("events.seatsLeft")}{" "}
                  {props.seatsAvailable >= selectedEmployees.length
                    ? props.seatsAvailable - selectedEmployees.length
                    : 0}
                </span>
              </Space>
            </Col>
            <Col>
              <Space direction="vertical">
                <Typography.Text type="secondary">
                  {t("events.notFound")}:
                </Typography.Text>
                <Space>
                  <Typography.Text strong>
                    {notFoundEmployeesCount}
                  </Typography.Text>
                  {t("events.employees")}
                </Space>
              </Space>
            </Col>
            <Col>
              <Space direction="vertical">
                <Typography.Text type="secondary">
                  {t("events.duplicates")}:
                </Typography.Text>
                <Typography.Text strong>{duplicatedIINsCount}</Typography.Text>
              </Space>
            </Col>
            <Col>
              <Space direction="vertical">
                <Typography.Text type="secondary">
                  {t("events.notEligible")}:
                </Typography.Text>
                <Space>
                  <Typography.Text strong>
                    {notEligbileEmployeesCount}
                  </Typography.Text>
                  {t("events.employees")}
                </Space>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between">
        <Button
          type="primary"
          loading={props.employeeSearchLoading}
          onClick={() => searchEmployeesFormik.submitForm()}
        >
          {t("search")}
        </Button>
        <Space size="large">
          <Button
            danger
            ghost
            disabled={!selectedEmployees.length}
            onClick={() => setSelectedEmployees([])}
          >
            {t("events.clearSelected")}
          </Button>
          <Button
            type="primary"
            onClick={() =>
              props.onApplyEventStepChange &&
              props.onApplyEventStepChange(selectedEmployees)
            }
            disabled={!selectedEmployees.length}
          >
            {t("events.goToStepN", { n: 2 })}
          </Button>
        </Space>
      </Row>
      <Table
        bordered
        locale={{ emptyText: t("noData") }}
        rowKey="id"
        loading={false}
        pagination={false}
        dataSource={props.employeeSearchResult}
        style={{ marginTop: "32px" }}
      >
        <Column
          align="center"
          width="40px"
          render={(employee: EmployeeSearchResult) =>
            employee.eligible ? (
              <Checkbox
                disabled={selectedEmployees.length >= props.seatsAvailable}
                checked={
                  !!selectedEmployees.find(({ id }) => id === employee.id)
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedEmployees([...selectedEmployees, employee]);
                  } else {
                    setSelectedEmployees([
                      ...selectedEmployees.filter(
                        ({ id }) => id !== employee.id
                      )
                    ]);
                  }
                }}
              />
            ) : (
              <Tooltip
                title={
                  isEn
                    ? employee.notEligibleReasonEn
                    : employee.notEligibleReasonRu
                }
              >
                <WarningOutlined
                  style={{ fontSize: "20px", color: "orange" }}
                />
              </Tooltip>
            )
          }
        />
        <Column title="IIN" dataIndex="iin" />
        <Column
          title={t("firstName")}
          render={(employee: EmployeeSearchResult) => employee.firstName || "-"}
        />
        <Column
          title={t("lastName")}
          render={(employee: EmployeeSearchResult) => employee.lastName || "-"}
        />
        <Column
          title={t("jobTitle")}
          render={(employee: EmployeeSearchResult) => employee.position || "-"}
        />
        <Column
          title={t("status")}
          render={(employee: EmployeeSearchResult) =>
            t(`events.employeeSearchStatuses.${employee.searchStatus}`)
          }
        />
      </Table>
    </Card>
  );
};
