import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { FilterOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space } from "antd";
import ReportBarChart from "@components/ui/charts/ReportBarChart";
import DownloadLink from "@components/common/DownloadLink";

import { CraftProfilesReportData } from "@models/report";

import {
  getReportByCsrContractor,
  getReportProfilesByDiscipline,
  getReportByEmployedStatus,
  getReportProfilesByContractor,
  getReportProfilesByTrainingCenter
} from "@utils/reportChartsHelper";

interface Props {
  report?: CraftProfilesReportData;
  isLoading: boolean;
  onFilterClick: () => void;
}

export const CraftProfileReport: React.FC<Props> = ({
  report,
  onFilterClick,
  isLoading
}) => {
  const { i18n, t } = useTranslation();

  const byCsrContractor = useMemo(
    () => getReportByCsrContractor(report?.byCsrContractor),
    [report?.byCsrContractor]
  );

  const byStatus = useMemo(
    () => getReportByEmployedStatus(report?.byEmployedStatus),
    [report?.byEmployedStatus]
  );

  const byDiscipline = useMemo(
    () => getReportProfilesByDiscipline(i18n.language, report?.byDiscipline),
    [i18n.language, report?.byDiscipline]
  );

  const byContractor = useMemo(
    () => getReportProfilesByContractor(report?.byHiringContractor),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language, report?.byHiringContractor]
  );

  const byTrainingCenter = useMemo(
    () => getReportProfilesByTrainingCenter(report?.byTrainingCenter),
    [report?.byTrainingCenter]
  );

  return (
    <Card
      extra={
        <Space>
          <DownloadLink
            type="primary"
            size="middle"
            url={`/api/hr-profile/profiles/download`}
            fileName={`Profiles data report ${moment().format()}.xlsx`}
            label={t("craftProfiles.downloadProfilesData")}
          />
          <Button
            icon={<FilterOutlined />}
            size="large"
            shape="circle"
            ghost
            type="primary"
            onClick={() => onFilterClick()}
          />
        </Space>
      }
      loading={isLoading}
    >
      <Row gutter={16} style={{ marginTop: "32px" }}>
        <Col span={8}>
          <Card
            style={{ marginBottom: "24px" }}
            title={t("reports.profiles.byTrainingCenter")}
            extra={byTrainingCenter.labels?.length}
          >
            <ReportBarChart
              labels={byTrainingCenter.labels}
              datasets={[
                {
                  barThickness: 25,
                  backgroundColor: "#A66999",
                  borderWidth: 0,
                  data: byTrainingCenter.datasets
                }
              ]}
              max={byTrainingCenter.max}
              showDataLabels
            />
          </Card>

          <Card
            title={t("reports.profiles.byStatus")}
            extra={byStatus.labels?.length}
            bodyStyle={{ padding: "24px 0" }}
          >
            <ReportBarChart
              labels={byStatus.labels?.map((status) => t(status))}
              datasets={[
                {
                  barThickness: 25,
                  backgroundColor: "#A66999",
                  borderWidth: 0,
                  data: byStatus.datasets
                }
              ]}
              max={byStatus.max}
              showDataLabels
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card
            title={t("reports.profiles.byContractor")}
            extra={byContractor.labels?.length}
            style={{ marginBottom: "24px" }}
          >
            <ReportBarChart
              labels={byContractor.labels}
              datasets={[
                {
                  barThickness: 25,
                  backgroundColor: "#01B8AA",
                  borderWidth: 0,
                  data: byContractor.datasets
                }
              ]}
              max={byContractor.max}
              showDataLabels
            />
          </Card>

          <Card
            title={t("reports.profiles.byCSRContractor")}
            extra={byCsrContractor.labels?.length}
            bodyStyle={{ padding: "24px 0" }}
          >
            <ReportBarChart
              labels={byCsrContractor.labels}
              datasets={[
                {
                  barThickness: 25,
                  backgroundColor: "#01B8AA",
                  borderWidth: 0,
                  data: byCsrContractor.datasets
                }
              ]}
              max={byCsrContractor.max}
              showDataLabels
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={t("reports.profiles.byDiscipline")}
            extra={byDiscipline.labels?.length}
            bodyStyle={{ padding: "24px 0" }}
          >
            <ReportBarChart
              labels={byDiscipline.labels?.map((status) => t(status))}
              datasets={[
                {
                  barThickness: 25,
                  backgroundColor: "#01B8AA",
                  borderWidth: 0,
                  data: byDiscipline.datasets
                }
              ]}
              max={byDiscipline.max}
              showDataLabels
            />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
