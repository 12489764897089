import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Space, Popconfirm } from "antd";

import { EmployeeDetails } from "@models/employee";

interface FormValues {
  iin?: string;
}

interface Props {
  employee?: EmployeeDetails;

  errors?: { [key: string]: string };

  isLoading?: boolean;
  submitText?: string;
  cancelText?: string;

  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

export const EmployeeIinForm: React.FC<Props> = (props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      iin: ""
    },
    validationSchema: yup.object().shape({
      iin: yup.lazy(() => {
        return yup
          .string()
          .required(t("validation.required"))
          .matches(/^[0-9]+$/, t("validation.invalidIin"))
          .length(12, t("validation.invalidIin"));
      })
    }),
    onSubmit({ iin }) {
      if (props.onSubmit) {
        props.onSubmit({ iin });
      }
    }
  });

  useEffect(() => {
    if (props.employee) {
      const e = props.employee;

      formik.setValues({
        iin: e.iin
      });
    }
    // eslint-disable-next-line
  }, [props.employee]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("employees.iin")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.iin && "error"}
            help={formik.errors.iin}
          >
            <Input
              name="iin"
              value={formik.values.iin}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col md={24}>
          <Row justify="end">
            <Col>
              <Space align="end">
                <Button htmlType="button" onClick={props.onCancel}>
                  {props.cancelText ? props.cancelText : t("cancel")}
                </Button>
                <Popconfirm
                  title={t("employees.iinUpdateConfirm")}
                  cancelText={t("cancel")}
                  okText={t("yes")}
                  onConfirm={() => {
                    formik.submitForm();
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={props.isLoading}
                  >
                    {props.submitText ? props.submitText : t("submit")}
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
