import { useTranslation } from "react-i18next";
import { UseMutateFunction } from "react-query";

import { Button, Col, Form, Modal, Row, Space, Typography } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import { CraftProfileCard } from "@models/craftProfiles";

interface Props {
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  selectedProfileName: string;
  onSubmit: UseMutateFunction<unknown, unknown, CraftProfileCard>;
  isLoading?: boolean;
  isEmployed: boolean;
}

const HireCraftProfileModal: React.FC<Props> = ({
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  selectedProfileName,
  onSubmit,
  isEmployed,
  isLoading
}) => {
  const [t] = useTranslation();

  const employmentTranslations = isEmployed ? "fire" : "hire";

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t(`craftProfiles.${employmentTranslations}Action`)}
        footer={null}
        width={400}
        onCancel={handleCloseModal}
      >
        <Form onFinish={onSubmit} style={{ width: "100%" }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Typography.Text>
              {t(`craftProfiles.${employmentTranslations}Confirmation`, {
                name: selectedProfileName
              })}
            </Typography.Text>

            <Row justify="space-between" style={{ marginTop: "24px" }}>
              <Col>
                <Button onClick={handleCloseModal}>{t("cancel")}</Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  danger={isEmployed ? true : false}
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("yes")}
                </Button>
              </Col>
            </Row>
          </Space>
        </Form>
      </Modal>

      <Button
        icon={isEmployed ? <MinusOutlined /> : <PlusOutlined />}
        onClick={handleOpenModal}
        danger={isEmployed}
      >
        {t(`craftProfiles.${employmentTranslations}Action`)}
      </Button>
    </>
  );
};

export default HireCraftProfileModal;
