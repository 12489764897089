import { useState } from "react";

import { ChartDataSets } from "chart.js";

import { Card, Table } from "antd";
import { ClockCircleOutlined, TeamOutlined } from "@ant-design/icons";
import IconSwitch from "@components/ui/IconSwitch";

import ReportBarChart from "./ReportBarChart";
import ReportBarChartExpandable from "./ReportBarChartExpandable";

interface Props {
  title: string;
  count: number;
  expandable?: boolean;
  expandableChartData?: {
    max?: number;
    labels?: string[];
    datasets: {
      labels?: string[];
      value: number;
      subdataset: ChartDataSets;
    }[];
  };
  chartData?: {
    labels: string[];
    datasets: ChartDataSets[];
    max?: number;
  };
  tableData: {
    columns: { title: string; dataIndex: string; key: string }[];
    dataSource: {
      label?: string;
      assessment?: number;
      training?: number;
      ojtTraining?: number;
    }[];
  };
}

export const ReportTableBarChart: React.FC<Props> = (props) => {
  const [showTable, setShowTable] = useState<boolean>(false);

  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 8,
            paddingRight: 8,
            fontWeight: "bold",
            marginBottom: 8
          }}
        >
          <div style={{ display: "flex" }}>
            {props.title}
            <IconSwitch
              style={{ marginLeft: 8 }}
              value={showTable}
              onValueChange={setShowTable}
              truthyValueIcon={<ClockCircleOutlined />}
              falsyValueIcon={<TeamOutlined />}
            />
          </div>
          <div>{props.count}</div>
        </div>
      }
      bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
    >
      {showTable && (
        <Table
          size="middle"
          columns={props.tableData.columns}
          dataSource={props.tableData.dataSource.map((item) => ({
            name: item.label,
            assessment: item.assessment,
            training: item.training,
            ojtTraining: item.ojtTraining,
            total: (item.assessment || 0) + (item.training || 0)
          }))}
          pagination={false}
        ></Table>
      )}
      {!showTable &&
        (props.expandable ? (
          props.expandableChartData && (
            <ReportBarChartExpandable
              labels={props.expandableChartData.labels}
              datasets={props.expandableChartData.datasets}
              max={props.expandableChartData.max}
              showDataLabels
            />
          )
        ) : (
          <ReportBarChart
            labels={props.chartData?.labels}
            datasets={props.chartData?.datasets}
            max={props.chartData?.max}
            showDataLabels
          />
        ))}
    </Card>
  );
};
