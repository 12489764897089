import React from "react";

import styled from "styled-components";

import { PageHeader as _PageHeader } from "antd";
import { BreadcrumbRenderer } from "@components/BreadcrumbRenderer";

const PageHeader = styled(_PageHeader)`
  padding-left: 0;
  padding-right: 0;
`;

interface Breadcrumb {
  breadcrumbName: string;
  path: string;
  children?: Breadcrumb[];
}

interface Props {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  extra?: React.ReactNode;
  onBack?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined
  ) => void;
  breadcrumbs?: Breadcrumb[];
}

export const AppPageHeader = (props: Props) => {
  return (
    <PageHeader
      title={props.title}
      subTitle={props.subTitle}
      extra={props.extra}
      onBack={props.onBack}
      breadcrumb={{ routes: props.breadcrumbs, itemRender: BreadcrumbRenderer }}
    />
  );
};
