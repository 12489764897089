import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { EmployeesTable } from "@components/common/employees/EmployeesTable";
import { EmployeeFilterForm } from "@components/contractors/employees/EmployeeFilterForm";
import ExportNonIINButton from "@components/common/ExportNonIINButton";

import { disciplineApi } from "@api/disciplineApi";
import { employeeApi } from "@api/employeeApi";

import { useQueryParams } from "@hooks/useQueryParams";
import { usePagination } from "@hooks/usePagination";

interface Filters {
  iin?: string;
  firstName?: string;
  lastName?: string;
  specialityIds?: number[];
  competencyLevel?: number;
}

export const EmployeesPage = () => {
  const [t] = useTranslation();
  const queryParams = useQueryParams();
  const { page, setPage, setPagination } = usePagination();
  const [filters, setFilters] = useState<Filters>({});

  const employeePage = useQuery(
    ["getEmployeePage", page, filters],
    () =>
      employeeApi.getEmployeesPage({ page, filters }).then((res) => res.data),
    {
      onSuccess(res) {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterParams: filters
        };
        setPagination(pagination);
      }
    }
  );

  const initialFilters = useMemo(
    () => ({
      iin: queryParams.iin,
      firstName: queryParams.firstName,
      lastName: queryParams.lastName,
      competencyLevel: Number(queryParams.competencyLevel) || undefined,
      specialityIds: queryParams.specialityIds
        ? queryParams.specialityIds.map((idStr: string) => Number(idStr))
        : undefined
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const disciplineList = useQuery("allDisciplines", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const paginationSettings = {
    hideOnSinglePage: true,
    total: employeePage.data?.totalItems,
    showTotal: (total: number) => `${t("totalCount")} ${total}`,
    current: employeePage.data?.currentPage,
    pageSize: employeePage.data?.pageSize,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={t("employees.employees")} />

      <AppPageHeader
        title={t("employees.craftEmployeeList")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "/" },
          {
            breadcrumbName: t("employees.employeesPassport"),
            path: "employees"
          }
        ]}
        extra={[
          <ExportNonIINButton
            fileName="log_of_non_iin.xlsx"
            url="/api/employee/download-log-no-iin"
          />
        ]}
      />

      <EmployeeFilterForm
        initialValues={initialFilters}
        disciplines={disciplineList.data}
        onSubmit={(values) => {
          setFilters(values);
          setPage(1);
        }}
      />

      <EmployeesTable
        employees={employeePage.data?.results}
        rowLinkTo={(s) => `/admin/employees/${s.id}`}
        isLoading={employeePage.isFetching}
        pagination={paginationSettings}
      />
    </>
  );
};
