import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";

import { AxiosError } from "axios";
import Modal from "antd/lib/modal/Modal";

import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { Button, notification, Popconfirm, Space } from "antd";
import { EditSubcontractorForm } from "@components/contractors/subcontractors/EditSubcontractorForm";
import { SubcontractorTable } from "@components/common/subcontractors/SubcontractorTable";
import { NewSubcontractor } from "@components/contractors/subcontractors/NewSubcontractor";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";

import {
  Subcontractor,
  UpdateSubcontractorPayload
} from "@models/subcontractor";

import { cityApi } from "@api/cityApi";
import { contractApi } from "@api/contractApi";
import { subcontractorApi } from "@api/subcontractorApi";

import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";
import { usePagination } from "@hooks/usePagination";

export const SubcontractorsPage = () => {
  const [t] = useTranslation();

  const { user } = useContext(UserContext);
  const contractorId = user?.userRoles[0].entityId || 0;

  const [isModalShown, setIsModalShown] = useState(false);
  const [editedSubcontractor, setEditedSubcontractor] =
    useState<Subcontractor>();

  const { page, setPage, setPagination } = usePagination();

  const { data, isLoading, refetch } = useQuery(
    ["getSubcontractorPage", page],
    () =>
      subcontractorApi
        .getSubcontractorPage({ contractorId, page })
        .then((res) => res.data),
    {
      onSuccess(res) {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage
        };
        setPagination(pagination);
      }
    }
  );

  const contractList = useQuery("getContractorContracts", () =>
    contractApi.getContractorContracts(contractorId).then((res) => res.data)
  );

  const cityList = useQuery("getCities", () =>
    cityApi.getCities().then((res) => res.data)
  );

  const registerSubconractorMutation = useValidatedMutation({
    mutationFunction: (values: any) =>
      subcontractorApi.createSubcontractor({
        contractorId,
        subcontractor: values
      }),
    onSuccess() {
      notification.success({
        message: t("subcontractors.registrationSuccess")
      });
      refetch();
      setIsModalShown(false);
    }
  });
  const registrationErrors = useMemo(
    () => transformValidationError(registerSubconractorMutation.error),
    [registerSubconractorMutation.error]
  );

  const editSubconractorMutation = useValidatedMutation({
    mutationFunction: ({
      subcontractorId,
      subcontractor
    }: {
      subcontractorId: number;
      subcontractor: UpdateSubcontractorPayload;
    }) =>
      subcontractorApi.updateSubcontractor({
        contractorId,
        subcontractorId,
        subcontractor
      }),
    onSuccess() {
      notification.success({
        message: t("subcontractors.editSuccess")
      });
      refetch();
      setEditedSubcontractor(undefined);
    }
  });
  const editErrors = useMemo(
    () => transformValidationError(editSubconractorMutation.error),
    [editSubconractorMutation.error]
  );

  const deleteMemberMutation = useMutation(
    (subcontractorId: number) =>
      subcontractorApi.deleteSubcontractor({ contractorId, subcontractorId }),
    {
      onSuccess() {
        notification.success({
          message: t("subcontractors.subcontractorDeleted")
        });

        refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const paginationSettings = {
    showSizeChanger: false,
    hideOnSinglePage: true,
    current: data?.currentPage,
    total: data?.totalItems,
    pageSize: data?.pageSize,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={t("subcontractors.subcontractors")} />

      <AppPageHeader
        title={t("subcontractors.subcontractors")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "contractor" },
          {
            breadcrumbName: t("subcontractors.subcontractors"),
            path: "subcontractors"
          }
        ]}
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => setIsModalShown(true)}
          >
            {t("subcontractors.registerSubcontractor")}
          </Button>
        ]}
      />

      <Modal
        visible={isModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("subcontractors.registerSubcontractor")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsModalShown(false);
        }}
      >
        <NewSubcontractor
          contracts={contractList.data || []}
          cities={cityList.data || []}
          errors={registrationErrors}
          isLoading={registerSubconractorMutation.isLoading}
          onSubmit={(values) => {
            registerSubconractorMutation.mutate(values);
          }}
          onCancel={() => {
            setIsModalShown(false);
            registerSubconractorMutation.reset();
          }}
        />
      </Modal>

      <Modal
        visible={!!editedSubcontractor}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("subcontractors.editSubcontractor")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsModalShown(false);
        }}
      >
        <EditSubcontractorForm
          subcontractor={editedSubcontractor}
          contracts={contractList.data || []}
          cities={cityList.data || []}
          errors={editErrors}
          isLoading={editSubconractorMutation.isLoading}
          onSubmit={(values) => {
            if (editedSubcontractor) {
              editSubconractorMutation.mutate({
                subcontractorId: editedSubcontractor.id,
                subcontractor: values
              });
            }
          }}
          onCancel={() => {
            setEditedSubcontractor(undefined);
            editSubconractorMutation.reset();
          }}
        />
      </Modal>

      <SubcontractorTable
        isLoading={isLoading}
        itemLinkTo={(sub) => `/contractor/subcontractors/${sub.id}#members`}
        subcontractors={data?.results}
        pagination={paginationSettings}
        renderActions={(sub: Subcontractor) => (
          <Space>
            <Link to={`/contractor/subcontractors/${sub.id}`}>
              <Button
                title={t("subcontractors.members")}
                shape="circle"
                icon={<TeamOutlined />}
              />
            </Link>

            <Button
              title={t("edit")}
              type="primary"
              shape="circle"
              disabled={editSubconractorMutation.isLoading}
              loading={
                editSubconractorMutation.isLoading &&
                editSubconractorMutation.variables.subcontractorId === sub.id
              }
              icon={<EditOutlined />}
              onClick={() => setEditedSubcontractor(sub)}
            />

            <Popconfirm
              title={t("subcontractors.confirmDelete", {
                name: sub.name
              })}
              cancelText={t("cancel")}
              okText={t("yes")}
              disabled={deleteMemberMutation.isLoading}
              onConfirm={() => deleteMemberMutation.mutate(sub.id)}
            >
              <Button
                title={t("delete")}
                danger
                shape="circle"
                disabled={deleteMemberMutation.isLoading}
                loading={
                  deleteMemberMutation.isLoading &&
                  deleteMemberMutation.variables === sub.id
                }
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )}
      />
    </>
  );
};
