import React from "react";
import { useTranslation } from "react-i18next";

import { useMsal } from "@azure/msal-react";
import styled from "styled-components";

import { Button as _Button } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { loginRequest } from "../../authConfig";

const Button = styled(_Button)`
  border-color: white !important;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
`;

const LoginButton: React.FC = () => {
  const [t] = useTranslation();
  const { instance } = useMsal();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest
      })
      .catch((error) => console.log(error));
  };

  return (
    <Button type="primary" onClick={handleRedirect}>
      <UserOutlined />
      {t("loginBtn")}
    </Button>
  );
};

export default LoginButton;
