import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { Col, DatePicker, Form } from "antd";

interface Props {
  onStartChange: (v: moment.Moment | null) => any;
  onEndChange: (v: moment.Moment | null) => any;
  dateTimeStart: string | undefined;
  dateTimeEnd: string | undefined;
  dateTimeStartError: string | undefined;
  dateTimeEndError: string | undefined;
  isAdmin?: boolean;
}

const DateTimeRangePicker: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  return (
    <>
      <Col md={12}>
        <Form.Item
          label={t("events.stardDate")}
          validateStatus={props.dateTimeStartError && "error"}
          help={props.dateTimeStartError}
        >
          <DatePicker
            placeholder=""
            format="DD MMM YYYY"
            style={{ width: "100%" }}
            value={props.dateTimeStart ? moment(props.dateTimeStart) : null}
            onChange={props.onStartChange}
          />
        </Form.Item>
      </Col>

      <Col md={12}>
        <Form.Item
          label={t("events.endDate")}
          validateStatus={props.dateTimeEndError && "error"}
          help={props.dateTimeEndError}
        >
          <DatePicker
            placeholder=""
            format="DD MMM YYYY"
            disabledDate={(date) =>
              date && date < moment(props.dateTimeStart).startOf("day")
            }
            style={{ width: "100%" }}
            value={props.dateTimeEnd ? moment(props.dateTimeEnd) : null}
            onChange={props.onEndChange}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default DateTimeRangePicker;
