import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "antd";

const { Option } = Select;

interface Props {
  value?: number | number[];
  options?: {
    value: number;
    label: string;
  }[];
  placeholder?: string;
  mode?: "multiple";
  maxTagCount?: number;
  disabled?: boolean;
  onChange: Function;
  onClear?: Function;
}

const SearchSelect: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [searchableOptions, setSearchableOptions] = useState(props.options);

  useEffect(() => {
    setSearchableOptions(props.options);
  }, [props.options]);

  return (
    <Select
      showSearch
      notFoundContent={t("noData")}
      allowClear
      showArrow
      filterOption={false}
      disabled={props.disabled}
      value={props.value}
      placeholder={props.placeholder}
      mode={props.mode}
      maxTagCount={props.maxTagCount}
      onSearch={(v) => {
        const options = props.options?.filter((option) =>
          option.label.toUpperCase().includes(v.toUpperCase())
        );
        setSearchableOptions(options);
      }}
      onClear={() => {
        if (props.onClear) {
          props.onClear();
          setSearchableOptions(props.options);
        }
      }}
      onChange={(v) => {
        props.onChange(v);
      }}
    >
      {searchableOptions?.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default SearchSelect;
