import picture15 from "@assets/images/user-guide/1.15.png";
import picture16 from "@assets/images/user-guide/1.16.png";
import picture17 from "@assets/images/user-guide/1.17.png";
import picture18 from "@assets/images/user-guide/1.18.png";
import picture19 from "@assets/images/user-guide/1.19.png";
import picture20 from "@assets/images/user-guide/1.20.png";
import picture21 from "@assets/images/user-guide/1.21.png";
import picture22 from "@assets/images/user-guide/1.22.png";
import picture23 from "@assets/images/user-guide/1.23.png";
import picture24 from "@assets/images/user-guide/1.24.png";
import picture25 from "@assets/images/user-guide/1.25.png";
import picture26 from "@assets/images/user-guide/1.26.png";

export const events = [
  {
    title: "What’s on Events tab",
    html: `<p dir="ltr">
    Events in the system are divided by status:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            All – list of all events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Upcoming – events that starts soon, you can change/cancel event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In progress – ongoing events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Completed – events that ended, but without grades. You must fill
            out Attendance data
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Evaluated – events with grades. Once you fill out grades, system
            generates Certificates for successfully studied craft-workers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Canceled – events that were canceled by you. System sends email
            notification to TCO user with cancellation reason
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Activated - events that have been activated by TCO users, so that
            TC user could add practical &amp; overall grades.
        </p>
    </li>
</ol>
<p>
    Note: each event status is highlighted by its own color
</p>`
  },
  {
    title: "What’s on Events page",
    html: `<p>
    Events block is a core functionality of the system.
</p>`
  },
  {
    title: "How to apply filters to search events in the system?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            Navigate to Events page
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Set filters
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Search button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 1.15
</p>
<p dir="ltr">
    <img
        src=${picture15}
        width="624"
        height="481"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Date from
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Total number of Events in the system
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Date to
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Disciplines
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Event Type
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Contractors/ Subcontractors
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            CSR Phase
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training centers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training Center\`s City
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Sort by
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event location
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
</ol>`
  },
  {
    title: "What’s on Event card",
    html: `<p dir="ltr">
    In the system 6 (six) types of the events:
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Pre-screening
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 1.16
</p>
<p dir="ltr">
    <img
        src=${picture16}
        width="616"
        height="192"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Assessment
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 1.17
</p>
<p dir="ltr">
    <img
        src=${picture17}
        width="596"
        height="186"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Training
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 1.18
</p>
<p dir="ltr">
    <img
        src=${picture18}
        width="611"
        height="189"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            OJT Training
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 1.19
</p>
<p dir="ltr">
    <img
        src=${picture19}
        width="624"
        height="97"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Commissioning Training
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 1.20
</p>
<p dir="ltr">
    <img
        src=${picture20}
        width="624"
        height="113"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Commissioning Assessment
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 1.21
</p>
<p dir="ltr">
    <img
        src=${picture21}
        width="624"
        height="73"
    />
</p>
<br/>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    What’s on Event details page
</p>
<p dir="ltr">
    Picture 1.22
</p>
<p dir="ltr">
    <img
        src=${picture22}
        width="624"
        height="443"
    />
</p>
<br/>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Cancel event button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Delete event button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Download button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Download attendance button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Delete craft-worker button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Feedback button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All participants tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Attendance tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Grades tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Activate event button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Edit event button
        </p>
    </li>
</ol>`
  },
  {
    title: "How to edit an event?",
    html: `
<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Edit Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out fields and press Save button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: You can edit events only in Upcoming status.
</p>
<p dir="ltr">
    Picture 1.23
</p>
<p dir="ltr">
    <img
        src=${picture23}
        width="611"
        height="603"
    />
</p>
<p dir="ltr">
    In modal window change event details and press on Edit button.
</p>
<br/>
<p dir="ltr">
    Note: Training center can change only Upcoming events, but TCO user, with
    appropriate permissions, can change any event at any status.
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Upcoming – change Event start time/date - end time/date in any
            direction without consequences. In case there are no registered
            Craft-workers, you can increase/decrease the Number of seats or
            Academic hours.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In progress – change Event start time/date - end time/date in any
            direction, but if there is Attendance data it will delete
            permanently. In case there are registered craft-workers you can not
            decrease the Number of seats or Academic hours.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Completed – change Event start time/date - end time/date in any
            direction, but if there is Attendance data it will delete
            permanently. In case there are registered craft-workers you can not
            decrease the Number of seats or Academic hours.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Evaluated – change Event start time/date - end time/date in any
            direction, but if there are Attendance data/Grade data/Instructor
            data/Feedback data it will delete permanently. In case there are
            registered craft-workers you can not decrease the Number of seats
            or Academic hours.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Cancelled – change Event start time/date - end time/date in any
            direction. You can decrease the Number of seats.
        </p>
    </li>
</ol>`
  },
  {
    title: "How to activate an event?",
    html: `<p dir="ltr">
    Event activate feature lets Training Center users to separately put grades
    for craft employees when the event is in Completed or Evaluated status.
</p>
<p dir="ltr">
    Training Center users are able to put grades after TCO user\`s event
    activation.
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Cancel Event
        </p>
    </li>
</ol>
<p>
    Fill out Cancellation reason and click Yes
</p>`
  },
  {
    title: "How to cancel an event?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Cancel Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out Cancellation reason and click Yes
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 1.24
</p>
<p dir="ltr">
    <img
        src=${picture24}
        width="604"
        height="422"
    />
</p>
<div>
    <br/>
</div>`
  },
  {
    title: "How to delete an event?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Delete Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Click Yes
        </p>
    </li>
</ol>`
  },
  {
    title: "How to directly apply employees to the event?",
    html: `<p dir="ltr">
    In the summer 2022 release of CT portal, TCO users are now able to apply
    craft employees to the event directly. Previously this functionality was
    available for Training Center users only.
</p>
<br/>
<p dir="ltr">
    Note: Event must have Upcoming status.
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            IIN input field, you can copy-paste IINs from your file, the system
            will find a list of all available craft-workers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Clear selected button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Go to step 2 button, navigates you to Data check page
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Check box of validity. You can tick or untick the required
            craft-worker. System will show reason why craft-worker is not
            eligible
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Search button and Go to step 2 button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            On Data check page press Apply button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 1.25
</p>
<p dir="ltr">
    <img
        src=${picture25}
        width="624"
        height="297"
    />
</p>
<br/>
<p dir="ltr">
    Picture 1.26
</p>
<p dir="ltr">
    <img
        src=${picture26}
        width="624"
        height="241"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Back button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Apply button
        </p>
    </li>
</ol>`
  }
];
