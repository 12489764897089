import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, Col, Row } from "antd";
import { CloudDownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { EventStatusCard } from "@components/common/events/EventStatusCard";
import ReportVerticalBarChart from "@components/ui/charts/ReportVerticalBarChart";
import ReportBarChartExpandable from "@components/ui/charts/ReportBarChartExpandable";
import ReportBarChart from "@components/ui/charts/ReportBarChart";
import { ReportLabeledDoughnutChart } from "@components/ui/charts/ReportLabeledDoughnutChart";

import { CommissioningReportData } from "@models/report";
import { EmployeeEventStatus, EventType } from "@models/event";

import {
  getReportByCompetencyLevel,
  getReportByContractor,
  getReportByDisciplineForCommissioning,
  getReportByEventStatus,
  getReportByFailReason
} from "@utils/reportChartsHelper";
import { usePrintRef } from "@hooks/usePrintRef";

interface Props {
  activeEventType: number;
  report?: CommissioningReportData;
  isLoading: boolean;
  onEventTypeChange: (tab: number) => void;
  onFilterClick: () => void;
}

export const CommissioningReport: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();

  const byEventStatus = useMemo(
    () => getReportByEventStatus(props.report?.byEventStatus),
    [props.report]
  );

  const byDiscipline = useMemo(
    () =>
      getReportByDisciplineForCommissioning(
        i18n.language,
        props.report?.byDiscipline
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.report]
  );

  const byContractor = useMemo(
    () => getReportByContractor(props.report?.byContractor),
    [props.report]
  );

  const byCompetencyLevel = useMemo(
    () =>
      getReportByCompetencyLevel(
        i18n.language,
        props.report?.byCompetencyLevel
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.report]
  );

  const byFailReason = useMemo(
    () => getReportByFailReason(props.report?.byFailReason),
    [props.report]
  );

  const { pageRef, handleDownloadReport } = usePrintRef(
    "pageRef",
    t(`events.commissioning`) + " " + t(`eventTypes.${props.activeEventType}`)
  );

  return (
    <Card
      tabList={[
        {
          tab: t(`eventTypes.${EventType.TRAINING}`),
          key: `${EventType.TRAINING}`
        },
        {
          tab: t(`eventTypes.${EventType.ASSESSMENT}`),
          key: `${EventType.ASSESSMENT}`
        }
      ]}
      tabBarExtraContent={
        <Button
          icon={<FilterOutlined />}
          size="large"
          shape="circle"
          ghost
          type="primary"
          onClick={() => {
            props.onFilterClick();
          }}
        />
      }
      loading={props.isLoading}
      activeTabKey={props.activeEventType.toString()}
      onTabChange={(tab) => {
        props.onEventTypeChange(Number(tab));
      }}
    >
      <Button
        type="primary"
        icon={<CloudDownloadOutlined />}
        onClick={handleDownloadReport}
      >
        {t("download")}
      </Button>

      <div id="pageRef" ref={pageRef}>
        <Row gutter={16} style={{ marginTop: "32px", height: "340px" }}>
          <Col span={12}>
            <Row gutter={8} style={{ height: "100%" }}>
              {byEventStatus
                .filter((e) => e.status !== EmployeeEventStatus.EXCLUDED)
                .map((e) => (
                  <Col span={6}>
                    <EventStatusCard eventStatus={e.status} value={e.value} />
                  </Col>
                ))}
            </Row>
          </Col>
          <Col span={12}>
            <Card title="By disciplines" style={{ height: "100%" }}>
              <ReportVerticalBarChart
                chartHeight={240}
                showDataLabels
                max={
                  byDiscipline.max && byDiscipline.max + byDiscipline.max / 5
                }
                labels={byDiscipline?.labels}
                datasets={[
                  {
                    backgroundColor: "#049aff",
                    borderWidth: 0,
                    barThickness: 40,
                    data: byDiscipline.datasets
                  }
                ]}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "24px" }}>
          <Col span={8}>
            <ReportLabeledDoughnutChart
              title={"By fail reason"}
              data={byFailReason}
            />
          </Col>
          <Col span={8}>
            <Card
              title={"By contractors"}
              extra={byContractor.labels.length}
              bodyStyle={{ padding: "24px 0" }}
            >
              <ReportBarChartExpandable
                labels={byContractor.labels}
                datasets={byContractor.datasets}
                max={byContractor.max}
                showDataLabels
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card title={"By competency level"}>
              <ReportBarChart
                showDataLabels
                labels={byCompetencyLevel.labels}
                datasets={[
                  {
                    backgroundColor: "#A66999",
                    borderWidth: 0,
                    data: byCompetencyLevel.datasets
                  }
                ]}
                max={byCompetencyLevel.max}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
