export enum MemberStatus {
  INVITED = 1,
  ACTIVE = 2,
  INACTIVE = 3,
  DELETED = 4
}

export interface Member {
  id: number;
  userId: number;

  inviteId: number | null;
  invitedAt: string;
  invitationAccepted: boolean;
  enableAttempted: boolean | null;

  position: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;

  status: MemberStatus;

  permissions: {
    userId: number;
    permission: number;
  }[];

  roles: { role: number; userId: number; entityId: number | null }[];

  deletedAt: string | null;
  createdAt: string;
}
