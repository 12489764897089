import { FlagOutlined } from "@ant-design/icons";

import { general } from "@utils/userguides/contractor/general";
import { search } from "@utils/userguides/contractor/search";
import { events } from "@utils/userguides/contractor/events";
import { reports } from "@utils/userguides/contractor/reports";
import { employees } from "@utils/userguides/contractor/employees";
import { members } from "@utils/userguides/contractor/members";
import { subcontractors } from "@utils/userguides/contractor/subcontractors";
import { requests } from "@utils/userguides/contractor/requests";
import { certificateVerification } from "@utils/userguides/contractor/certificateVerification";
import { profile } from "@utils/userguides/contractor/profile";

export const contractorUserguide = [
  { title: "general", data: general, icon: <FlagOutlined /> },
  { title: "search", data: search, icon: <FlagOutlined /> },
  { title: "events", data: events, icon: <FlagOutlined /> },
  { title: "reports", data: reports, icon: <FlagOutlined /> },
  { title: "employees", data: employees, icon: <FlagOutlined /> },
  { title: "members", data: members, icon: <FlagOutlined /> },
  { title: "subcontractors", data: subcontractors, icon: <FlagOutlined /> },
  {
    title: "certificateVerification",
    data: certificateVerification,
    icon: <FlagOutlined />
  },
  { title: "requests", data: requests, icon: <FlagOutlined /> },
  { title: "profileContract", data: profile, icon: <FlagOutlined /> }
];
