import { CSSProperties, FC, useCallback, useEffect, useState } from "react";

import { ChartColor, ChartDataSets } from "chart.js";

import { Popover } from "antd";

type ReportBarChartProps = {
  max?: number;
  labels?: string[];
  label?: string;
  datasets?: ChartDataSets[];
  showDataLabels: boolean;
};

type BarExpandableProps = {
  label: string;
  value: number;
  width: number;
  backgroundColor: any;
};

const Bar: FC<BarExpandableProps> = ({
  label,
  value,
  width,
  backgroundColor
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        fontSize: 12,
        color: "#555"
      }}
    >
      <Popover content={label} trigger="hover">
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: "0.5em",
            cursor: "pointer"
          }}
        >
          {label}
        </span>
      </Popover>
      <div style={{ width: "64.5%" }}>
        <div
          style={{
            display: "flex"
          }}
        >
          <span
            style={{
              width: `${width}%`,
              height: 25
            }}
          >
            <div></div>
            <span>
              <canvas
                height={25}
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: `${backgroundColor}`
                }}
              ></canvas>
            </span>
          </span>
          <span
            style={{
              marginLeft: 2,
              paddingTop: "0.25em",
              paddingLeft: "0.5em",
              fontWeight: "bold"
            }}
          >
            {value}
          </span>
        </div>
      </div>
    </div>
  );
};

const ReportBarChart: FC<ReportBarChartProps> = ({ labels, datasets, max }) => {
  const mt2: CSSProperties = {
    marginTop: "8px"
  };
  const getDatasetsWithWidth = useCallback(
    (datasets: any, labels?: string[]) => {
      return datasets[0].data?.map((value: number, index: number) => ({
        value,
        width: max && (value / max) * 100,
        label: labels?.[index]
      }));
    },
    []
  );

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [datasetsWithWidth, setDatasetsWithWidth] = useState<any>(
    getDatasetsWithWidth(datasets, labels)
  );
  console.log(datasets, "dataSetWidthWidth");

  return (
    <div>
      <div className="mt-2" style={{ ...mt2, padding: "0 24px" }}>
        {datasetsWithWidth.map((dataset: any, index: number) => (
          <div style={{ marginBottom: "1.25em" }} key={index}>
            <Bar
              label={dataset.label || ""}
              value={dataset.value}
              width={dataset.width}
              backgroundColor={datasets?.[0].backgroundColor}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportBarChart;

ReportBarChart.defaultProps = {
  showDataLabels: false
};
