import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { TablePaginationConfig } from "antd/lib/table";

import { Space, Table, Tag, Typography } from "antd";

import { EventEmployeeGradeType } from "@models/event";
import { EmployeeShortDetails } from "@models/employee";

const Column = Table.Column;

interface Props {
  title?: React.ReactNode;
  isLoading?: boolean;
  employees?: EmployeeShortDetails[];

  pagination?: TablePaginationConfig;

  rowLinkTo?: (sub: EmployeeShortDetails) => string;

  extraColumns?: {
    title?: string;
    dataIndex?: string;
    render?: (value: any, record: unknown, index: number) => React.ReactNode;
  }[];

  renderActions?: (member: any) => React.ReactNode;
}

export const EmployeesTable = (props: Props) => {
  const { i18n, t } = useTranslation();

  return (
    <Table
      locale={{ emptyText: t("noData") }}
      loading={props.isLoading}
      tableLayout="auto"
      bordered
      pagination={
        props.pagination && {
          showSizeChanger: false,
          responsive: true,
          position: ["bottomCenter"],
          ...props.pagination
        }
      }
      title={() => (
        <Space align="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {t("employees.employees")}
          </Typography.Title>

          <Typography.Text type="secondary">
            {t("totalCount", {
              total: props.pagination?.total || 0
            })}
          </Typography.Text>
        </Space>
      )}
      rowKey="id"
      dataSource={props.employees}
    >
      <Column
        title={t("employees.fullName")}
        render={(_: undefined, e: EmployeeShortDetails) =>
          props.rowLinkTo ? (
            <Link to={props.rowLinkTo(e)}>
              {e.firstName + " " + e.lastName}
            </Link>
          ) : (
            e.firstName
          )
        }
      />
      <Column title={t("employees.iin")} dataIndex="iin" />
      <Column
        title={t("employees.competency")}
        dataIndex="competences"
        render={(_, ed: EmployeeShortDetails) =>
          ed.competencies.length
            ? ed.competencies.map((c) => (
                <Tag key={c.id}>
                  {i18n.language === "en"
                    ? c.speciality.nameEn
                    : c.speciality.nameRu}{" "}
                  (
                  {c.grade.isPassed !== EventEmployeeGradeType.FAILED &&
                  c.competencyLevel
                    ? t(`competencyLevels.${c.competencyLevel}`)
                    : t(`events.grade.${c.grade.isPassed}`)}
                  )
                </Tag>
              ))
            : "-"
        }
      />
      <Column
        title={t("employees.contractor")}
        dataIndex="contractor"
        render={(_, ed: EmployeeShortDetails) => ed.contractor.name}
      />

      {props.extraColumns &&
        props.extraColumns.map((ec, i) => (
          <Column
            key={i}
            title={ec.title}
            dataIndex={ec.dataIndex}
            render={ec.render}
          />
        ))}

      {props.renderActions && (
        <Column
          title={t("actions")}
          render={(member: any) => {
            return props.renderActions && props.renderActions(member);
          }}
        />
      )}
    </Table>
  );
};
