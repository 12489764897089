import { Discipline } from "./discipline";
import { EventShortDetails } from "./event";

export enum NoIinType {
  UNSPECIFIED = 0,
  NON_IIN_CRAFT = 1,
  PREVIOUS_NON_IIN_CRAFT = 2,
  NEVER_BEEN_NON_IIN_CRAFT = 3
}

export interface Competency {
  id: number;
  competencyLevel: number;
  specialityId: number;
  gradeId: number;

  eventId: number;
  event: {
    eventType: number;
    dateTimeStart: string;
    dateTimeEnd: string;

    trainingCenter: {
      id: number;
      name: string;
    };
  };
  grade: {
    id: number;
    isPassed: number;
  };
  speciality: {
    id: number;
    nameEn: string;
    nameRu: string;
  };
}
export interface EmployeeDetails {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  firstNameCyrillic: string;
  lastNameCyrillic: string;
  middleNameCyrillic: string;
  phoneNumber: string;
  iin: string;
  noIin: number;
  mouCategory: number;
  position: string;
  hasIin: boolean;
  birthDate: string;
  citizenshipId: number;
  residenceCountryId: number;
  residenceRegion: string;

  contractId: number;
  contractorId: number;
  lineSupervisorBadgeNumber: number;

  workLocationArea: string;
  facilityAccessStandard: string;
  facilityAccessAdditional: string;
  specialAccessJustification: string;
  waterTreatmentPlant: string;
  residentialAreas: string;
  firstNameCompanyRep: string;
  lastNameCompanyRep: string;
  phoneNumberCompanyRep: string;

  contractor: {
    id: number;
    name: string;
    parentContractorId: number;
    type: number;
  };

  competences: {
    id: number;
    employeeId: number;
    competencyLevel: number;
    specialityId: number;

    speciality: {
      id: number;
      name: string;
      nameRu: string;
      nameEn: string;
      competencyRequired: true;
      mouCategory: number;
      competencyType: number;
    };

    gradeId: number;
    grade: {
      id: number;
      isPassed: number;
    };
  }[];

  citizenShip?: {
    id: number;
    nameEn: string;
    nameRu: string;
  };

  residenceCountry: {
    id: number;
    nameEn: string;
    nameRu: string;
  };

  contract?: {
    id: number;
    contractNumber: string;
  };
}

export interface EmployeeShortDetails {
  id: number;
  competencies: Competency[];

  contractorId: number;
  firstName: string;
  firstNameCyrillic: string;
  iin: string;
  lastName: string;
  lastNameCyrillic: string;
  middleName: string;
  middleNameCyrillic: string;
  phoneNumber: string;

  contractor: {
    id: number;
    name: string;
    parentContractorId: number;
    type: number;
  };
}

export interface EmployeeEventFilters {
  contractorIds?: number[];
  isPassed?: number;
  status?: number;
}

export interface EmployeeEventDetails {
  attendanceTotal: number;
  employeeId: number;
  eventId: number;
  firstName: string;
  firstNameCyrillic: string;
  iin: string;
  lastName: string;
  lastNameCyrillic: string;
  excludedAt: string | null;
  evalutedAt: string | null;
  practicalGrade: number | null;
  theoreticalGrade: number | null;

  contractNumber: string;
  contractorName: string;
  isPassed: number;
  theoreticalStatus: number;
  practicalStatus: number;
  failReasonType: number;
  failReason: string;
  competencyLevel: number;
  recommendation: number;
  status: number;
  evaluatedAt: string;
}

export interface EmployeeSearchResult extends EmployeeDetails {
  eligible?: boolean;
  searchStatus?: EmployeeSearchStatus;
  searchMessageEn?: string;
  searchMessageRu?: string;
  notEligibleReasonEn?: string;
  notEligibleReasonRu?: string;
}

export enum EmployeeSearchStatus {
  FOUND = 1,
  NOT_FOUND = 2,
  DUPLICATE = 3,
  NOT_ELIGIBLE = 4
}

export interface CreateEmployeePayload {
  firstName: string;
  lastName: string;
  middleName: string;
  firstNameCyrillic: string;
  lastNameCyrillic: string;
  middleNameCyrillic: string;
  position: string;
  mouCategory: number;
  phoneNumber: string;
  hasIin: boolean;
  iin: string;
  birthDate: string;
  citizenshipId: number;
  residenceCountryId: number;
  residenceRegion: string;
  contractorId: number;
  contractId: number;
  lineSupervisorBadgeNumber: number;
  workLocationArea: string;
  facilityAccessStandard: string;
  facilityAccessAdditional: string;
  specialAccessJustification: string;
  waterTreatmentPlant: string;
  residentialAreas: string;
  firstNameCompanyRep: string;
  lastNameCompanyRep: string;
  phoneNumberCompanyRep: string;
}

export type EmployeeConversionResult = Partial<CreateEmployeePayload>;

export interface EditEmployeePayload {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  firstNameCyrillic: string;
  lastNameCyrillic: string;
  middleNameCyrillic: string;
  position: string;
  mouCategory: number;
  phoneNumber: string;
  birthDate?: string;
  citizenshipId: number;
  residenceCountryId: number;
  residenceRegion: string;
  contractorId: number;
  contractId: number;
  lineSupervisorBadgeNumber?: number;
  workLocationArea: string;
  facilityAccessStandard: string;
  facilityAccessAdditional: string;
  specialAccessJustification: string;
  waterTreatmentPlant: string;
  residentialAreas: string;
  firstNameCompanyRep: string;
  lastNameCompanyRep: string;
  phoneNumberCompanyRep: string;
}

export interface EmployeeEventHistoryItem {
  id: number;
  eventId: number;

  event: Omit<EventShortDetails, "isCommissioning">;
  employeeId: number;
  contractId: number;
  attendanceTotal: number;
  attendance: string;
  status: number;
  iin: string;
  evaluatedAt: string;
  excludedAt: string;
  rejectionReason: string;
  isCommissioning: boolean;

  grade?: {
    competencyLevel: number;
    practicalStatus: number;
    theoreticalStatus: number;
    theoreticalGrade: number;
    practicalGrade: number;
    isPassed: number;
    failReasonType: number;
    failReason: string;
    recommendation: number;
    certificateId: string;
  };

  speciality: Discipline;

  exclusionReasonType: number;
  exclusionReason: string;
}
