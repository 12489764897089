import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import moment from "moment";

import { Button, Modal, notification } from "antd";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { EditEventForm } from "@components/admin/events/EditEventForm";

import { AdminEditEventPayload, EventDetails } from "@models/event";

import { eventsApi } from "@api/eventsApi";
import { locationApi } from "@api/locationApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

interface Props {
  event: EventDetails;
  onEdit?: () => void;
}

const EventEditModal: React.FC<Props> = ({ event, onEdit }) => {
  const [t] = useTranslation();
  const [isEdited, setIsEdited] = useState(false);
  const [editPayload, setEditPayload] = useState<AdminEditEventPayload>({});

  const editEventMutation = useValidatedMutation({
    mutationFunction: (values: AdminEditEventPayload) =>
      eventsApi.adminEditEvent(event.id, event.type, values),
    onSuccess() {
      notification.success({ message: t("events.eventEdited") });

      setIsEdited(false);
      setEditPayload({});

      onEdit && onEdit();
    }
  });
  const validationErrors = useMemo(
    () => transformValidationError(editEventMutation.error),
    [editEventMutation.error]
  );

  const validateEventMutation = useValidatedMutation({
    mutationFunction: (values: AdminEditEventPayload) =>
      eventsApi.adminValidateEvent(event.id, values),
    onSuccess: ({ data }) => {
      if (data.length === 0) {
        editEventMutation.mutate(editPayload);
      } else {
        const failedIins = data[0].iins.join(", ");
        Modal.confirm({
          title: t("events.confirmEventEdit"),
          content: t("events.editEventDatesValidationFail", {
            iins: failedIins,
            eventId: data[0].eventId,
            dateTimeStart: moment(data[0].dateTimeStart).format(
              "DD.MM.YYYY hh:mm"
            ),
            dateTimeEnd: moment(data[0].dateTimeEnd).format("DD.MM.YYYY hh:mm")
          }),
          icon: <ExclamationCircleOutlined />,
          okText: t("yes"),
          cancelText: t("cancel"),
          onOk: () => {
            editEventMutation.mutate({
              ...editPayload,
              ignoreDatesOverlapping: true
            });
          },
          onCancel: () => {
            setEditPayload({});
          }
        });
      }
    }
  });

  const locationList = useQuery("getLocations", () =>
    locationApi.getLocations().then((res) => res.data)
  );

  return (
    <>
      <Modal
        visible={isEdited}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("edit")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsEdited(false);
        }}
      >
        <EditEventForm
          event={event}
          isLoading={
            validateEventMutation.isLoading || editEventMutation.isLoading
          }
          locations={locationList.data}
          errors={validationErrors}
          onCancel={() => setIsEdited(false)}
          onSubmit={(values) => {
            setEditPayload(values);
            validateEventMutation.mutate({
              datetimeStart: values.dateTimeStart,
              datetimeEnd: values.dateTimeEnd
            });
          }}
        />
      </Modal>

      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => setIsEdited(true)}
      >
        {t("edit")}
      </Button>
    </>
  );
};

export default EventEditModal;
