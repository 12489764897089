import { CommissioningEventGradeForm } from "@components/training-center/events/CommissioningEventGradeForm";
import { CommissioningEventGradeTable } from "@components/common/events/CommissioningEventGradeTable";

import {
  CommissioningEventGrade,
  EventDetails,
  EventEmployeeDetails
} from "@models/event";

import { getEmployeesByType } from "@utils/eventAttendanceHelper";

interface Props {
  editable?: boolean;
  editablePracticalGrade?: boolean;

  errors?: { [key: string]: string };
  isLoading?: boolean;
  eventData?: EventDetails;
  eventEmployees?: EventEmployeeDetails[];
  onSubmit?: (values: CommissioningEventGrade[]) => void;
}

export const CommissioningEventGrades = (props: Props) => {
  const { availableEmployees: ungradedEmployees } = getEmployeesByType(
    props.eventEmployees || []
  );

  return props.editable || props.editablePracticalGrade ? (
    <CommissioningEventGradeForm
      isLoading={props.isLoading}
      errors={props.errors}
      onSubmit={props.onSubmit}
      eventData={props.eventData}
      editablePracticalGrade={props.editablePracticalGrade}
      eventEmployees={
        props.editablePracticalGrade ? props.eventEmployees : ungradedEmployees
      }
    />
  ) : (
    <CommissioningEventGradeTable eventEmployees={props.eventEmployees} />
  );
};
