import picture34 from "@assets/images/user-guide/1.34.png";

export const tcReports = [
  {
    title: "Training reports",
    html: `<p dir="ltr">
    TC Reports are created by
</p>
<br/>
<p dir="ltr">
    Apply filters to search reports from training center in the system
</p>
<p dir="ltr">
    Picture 1.34
</p>
<p dir="ltr">
    <img
        src=${picture34}
        width="624"
        height="189"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training Centers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Start date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by End date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter byCSR phase
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Sort by
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Clear filters button
        </p>
    </li>
</ol>
`
  },
  {
    title: "Protocol",
    html: `<p dir="ltr">
    Apply filters to search protocols from training center in the system
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training Centers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Start date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by End date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter byCSR phase
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Sort by
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Clear filters button
        </p>
    </li>
</ol>
`
  },
  {
    title: "Profile",
    html: `<p dir="ltr">
    Apply filters to search profiles from training center in the system
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training Centers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Start date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by End date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter byCSR phase
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Sort by
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Clear filters button
        </p>
    </li>
</ol>
`
  },
  {
    title: "Certificate",
    html: `<p dir="ltr">
    Apply filters to search certificates from training center in the system
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training Centers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Start date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by End date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter byCSR phase
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Sort by
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Clear filters button
        </p>
    </li>
</ol>
`
  }
];
