import { MouCategory } from "@models/mouCategory";

export const MOU_CATEGORY_OPTIONS: { labelKey: string; value: number }[] = [
  {
    labelKey: `mouCategory.${MouCategory.ENGINEERING_MANAGEMENT}`,
    value: MouCategory.ENGINEERING_MANAGEMENT
  },
  {
    labelKey: `mouCategory.${MouCategory.SUPERVISOR_AND_FOREMAN}`,
    value: MouCategory.SUPERVISOR_AND_FOREMAN
  },
  {
    labelKey: `mouCategory.${MouCategory.HEAVY_EQUIPMENT_OPERATOR}`,
    value: MouCategory.HEAVY_EQUIPMENT_OPERATOR
  },
  { labelKey: `mouCategory.${MouCategory.CRAFT}`, value: MouCategory.CRAFT }
];
