export enum CompetencyLevel {
  UNSPECIFIED = 0,
  BASIC = 2,
  INTERMEDIATE = 3,
  ADVANCED = 4,
  COMPETENT = 5,
  PROFICIENT = 6,
  HELPER = 7,
  TRAINEE_TECHNICIAN = 20,
  JUNIOR_TECHNICIAN = 22,
  TECHNICIAN = 24,
  SENIOR_TECHNICIAN = 26
}

export enum CustomCompetencyLevel {
  DEFAULT = 1,
  INTERMEDIATE_ADVANCED = 6
}
