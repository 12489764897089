import React from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { saveAs } from "file-saver";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import { Button, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

interface DownloadLinkProps {
  url: string;
  fileName?: string;
  size?: SizeType;
  type?:
    | "text"
    | "link"
    | "ghost"
    | "default"
    | "primary"
    | "dashed"
    | undefined;
  label?: string;
  disabled?: boolean;
}

const DownloadLink: React.FC<DownloadLinkProps> = ({
  url,
  fileName,
  size = "small",
  type,
  label,
  disabled
}) => {
  const [t] = useTranslation();
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleDownload = () => {
    setIsDownloading(true);
    axios
      .get(url, { responseType: "arraybuffer" })
      .then((response) => {
        setIsDownloading(false);
        return saveAs(new Blob([response.data]), fileName);
      })
      .catch(() => {
        setIsDownloading(false);
        notification.error({
          message: t("error.errorDownload")
        });
      });
    return false;
  };

  return (
    <Button
      type={type || "link"}
      size={size}
      onClick={handleDownload}
      icon={<DownloadOutlined />}
      loading={isDownloading}
      disabled={disabled}
    >
      {label || fileName}
    </Button>
  );
};

export default DownloadLink;
