import axios from "axios";
import { saveAs } from "file-saver";

import { EventCertificate } from "@models/certificate";

interface CertificateSearchQuery {
  iin?: string;
  certificateId?: string;
}

const getAdminCertificates = ({ iin, certificateId }: CertificateSearchQuery) =>
  axios.get<EventCertificate[]>("/api/admin/certificate/search", {
    params: {
      iin,
      certificateId
    }
  });

const downloadAdminCertificate = (certificateId: string) =>
  axios
    .get(`/api/admin/certificate/${certificateId}/pdf`, {
      responseType: "blob"
    })
    .then((res) => {
      saveAs(new Blob([res.data]), `certificate_${certificateId}.pdf`);
    });

const getTcCertificates = ({
  iin,
  certificateId,
  trainingCenterId
}: CertificateSearchQuery & { trainingCenterId: number }) =>
  axios.get<EventCertificate[]>(
    `/api/training-center/${trainingCenterId}/certificate/search`,
    {
      params: {
        iin,
        certificateId
      }
    }
  );

const downloadTcCertificate = ({
  trainingCenterId,
  certificateId
}: {
  trainingCenterId: number;
  certificateId: string;
}) =>
  axios
    .get(
      `/api/training-center/${trainingCenterId}/certificate/${certificateId}/pdf`,
      {
        responseType: "blob"
      }
    )
    .then((res) => {
      saveAs(new Blob([res.data]), `certificate_${certificateId}.pdf`);
    });

const findContractorCertificate = ({
  contractorId,
  certificateId,
  iin
}: { contractorId: number } & CertificateSearchQuery) =>
  axios.get<EventCertificate>(
    `/api/contractor/${contractorId}/certificate/validate`,
    {
      params: {
        iin,
        certificateId
      }
    }
  );

const downloadContractorCertificate = ({
  contractorId,
  certificateId
}: {
  contractorId: number;
  certificateId: string;
}) =>
  axios
    .get(`/api/contractor/${contractorId}/certificate/${certificateId}/pdf`, {
      responseType: "blob"
    })
    .then((res) => {
      saveAs(new Blob([res.data]), `certificate_${certificateId}.pdf`);
    });

export const certificateApi = {
  getAdminCertificates,
  findContractorCertificate,
  getTcCertificates,
  downloadTcCertificate,
  downloadAdminCertificate,
  downloadContractorCertificate
};
