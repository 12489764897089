import { City } from "./countryCity";
import { Discipline } from "./discipline";

export interface TrainingCenter {
  id: number;
  name: string;
  address: string;
  city: City;
  eventTypes: number[];
  specialities: Discipline[];
  csrEnabled: boolean;
  commissioningEnabled: boolean;
  createdAt: string;
}

export interface TrainingCentersMap {
  [id: number]: string;
}

export interface RegisterTrainingCenterPayload {
  name: string;
  cityId?: number;
  address: string;
  specialities?: number[];
  eventTypes?: number[];
  permissions?: number[];
  csrEnabled: boolean;
  commissioningEnabled: boolean;
  email: string;
  firstName: string;
  lastName: string;
  position: string;
}

export interface EditTrainingCenterPayload {
  id?: number;
  name: string;
  cityId?: number;
  address: string;
  specialities?: number[];
  eventTypes?: number[];
  csrEnabled: boolean;
  commissioningEnabled: boolean;
}

export interface TrainingCenterReport {
  id: number;
  name: string;
  type: number;
  description: string;
  csrPhase: number;
  deletedAt: string;
  dateFrom: string;
  dateTo: string;
  attachmentFiles: {
    id: number;
    name: string;
    fileName: string;
    uniqueStorageKey: string;
    trainingCenterReportId: number;
    ticketId: number;
  }[];
  createdAt: string;
  updatedAt: string;
  edited: number;
  trainingCenterName: string;
  city: {
    id: number;
    countryId: number;
    nameEn: string;
    nameRu: string;
    name?: string;
    regionNameEn: string;
    deletedAt?: string;
  };
}

export interface TrainingCenterReportPayload {
  type?: number;
  name?: string;
  description?: string;
  csrPhase?: number;
  dateFrom?: string;
  dateTo?: string;
  files?: File[];
  attachmentFiles?: AttachmentFiles[];
  [key: string]: any;
}

export type AttachmentFiles = {
  id: number;
  name: string;
  fileName: string;
  uniqueStorageKey: string;
  trainingCenterReportId: number;
  ticketId: number;
};

export enum TcReportTypes {
  TRAINING_REPORT = 1,
  PROTOCOL_REPORT = 2,
  PROFILE_REPORT = 3,
  CERTIFICATE_REPORT = 4
}

export interface TCReportFormValues {
  dateStart?: string;
  dateEnd?: string;
  csrPhases?: number[];
  trainingCenterIds?: number[];
  sortByCreatedAt?: number;
}
