import axios from "axios";

import { AppUser } from "@models/appUser";

export const fetchUserData = async () => axios.get<AppUser>("/api/auth/me");

export const login = async (payload: { email: string; password: string }) => {
  const response = await axios.post("/api/auth/login", payload);
  const token = response?.data?.apiToken;
  localStorage.setItem("CT_PORTAL_USER_TOKEN", token);
};

export const ssoLogin = (token: string) => {
  localStorage.setItem("CT_PORTAL_USER_TOKEN", token);
};

export const logout = async () => {
  axios.post("/api/auth/logout").then((response) => {
    localStorage.removeItem("CT_PORTAL_USER_TOKEN");
    return response;
  });
};

export const changePassword = async (payload: {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}) => axios.post<{ apiToken: string }>("/api/auth/change-password", payload);

export const requestResetPassword = async (payload: { email: string }) =>
  await axios.post("/api/auth/password/email", payload);

export const resetPassword = async ({
  token,
  ...payload
}: {
  token: string;
  password: string;
  confirmPassword: string;
}) => await axios.post(`/api/auth/password/reset/${token}`, payload);

export const confirmAcceptInvite = async (payload: {
  token: string;
  password: string;
  passwordConfirmation: string;
}) => await axios.post("/api/member/submit", payload);

export const acceptUserAgreement = async () =>
  await axios.put("/api/member/user-agreement");

export const editProfile = async (payload: {
  firstName: string;
  lastName: string;
}) => await axios.post("/api/member/me", payload);

const userApi = {
  fetchUserData,
  changePassword,
  requestResetPassword,
  resetPassword,
  confirmAcceptInvite,
  login,
  ssoLogin,
  logout,
  acceptUserAgreement,
  editProfile
};

export default userApi;
