/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import { UploadFile } from "antd/lib/upload/interface";

import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import DateRangePicker from "@components/common/form/DateRangePicker";

import { CsrPhase } from "@models/event";
import { TrainingCenterReportPayload } from "@models/trainingCenter";

import { convertNotificationEntities } from "@utils/eventHelper";
import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

interface FormValues {
  type?: number;
  name?: string;
  dateFrom?: string;
  dateTo?: string;
  description?: string;
  csrPhase?: CsrPhase;
  files?: UploadFile[];
}

interface Props {
  isLoading?: boolean;
  errors?: { [key: string]: string };
  reportType?: number;
  report?: TrainingCenterReportPayload;
  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

export const NewTrainingReportForm = (props: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      type: props.reportType,
      name: undefined,
      csrPhase: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      description: undefined,
      files: undefined
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(convertNotificationEntities(values));
      }
    }
  });

  useEffect(() => {
    if (props.errors) {
      formik.setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={8}>
        <Form.Item>
          <Input name="type" value={props.reportType} type="hidden" />
        </Form.Item>
        <Col md={12}>
          <Form.Item
            label={t("events.name")}
            validateStatus={formik.errors.name && "error"}
            help={formik.errors.name}
          >
            <Input
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("events.csrPhase")}
            validateStatus={formik.errors.csrPhase && "error"}
            help={formik.errors.csrPhase}
          >
            <Select
              value={formik.values.csrPhase}
              onChange={(v) => formik.setFieldValue("csrPhase", v)}
            >
              {CSR_PHASE_OPTIONS.map((cp) => (
                <Select.Option value={cp.value} key={cp.value}>
                  {t(cp.label, { no: cp.value })}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("events.description")}
            validateStatus={formik.errors.description && "error"}
            help={formik.errors.description}
          >
            <Input.TextArea
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <DateRangePicker
          onStartChange={(v) =>
            formik.setFieldValue("dateFrom", v?.toISOString())
          }
          onEndChange={(v) => formik.setFieldValue("dateTo", v?.toISOString())}
          dateTimeStart={formik.values.dateFrom}
          dateTimeEnd={formik.values.dateTo}
          dateTimeStartError={formik.errors.dateFrom}
          dateTimeEndError={formik.errors.dateTo}
        />

        <Col md={12}>
          <Form.Item
            validateStatus={formik.errors.files && "error"}
            help={formik.errors.files}
          >
            <Upload
              multiple
              maxCount={3}
              accept=".xlsx,.pdf,.docx,.zip"
              beforeUpload={() => {
                return false;
              }}
              onChange={(ev) => {
                formik.setFieldValue("files", ev.fileList);
              }}
              onRemove={(f) => {
                const nextFormVal = formik.values.files?.filter(
                  (file) => file !== f
                );

                formik.setFieldValue("files", nextFormVal);
              }}
            >
              <Button type="dashed" icon={<UploadOutlined />}>
                {t("events.uploadFile")}
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <br />

      <Row justify="space-between">
        <Col>
          <Button onClick={() => props.onCancel && props.onCancel()}>
            {t("cancel")}
          </Button>
        </Col>

        <Col>
          <Button type="primary" htmlType="submit" loading={props.isLoading}>
            {t("create")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
