import { useTranslation } from "react-i18next";

import {
  SyncOutlined,
  ScheduleOutlined,
  CalendarOutlined,
  EditOutlined,
  UserOutlined,
  GlobalOutlined
} from "@ant-design/icons";
import { Button, Card, Col, Input, PageHeader, Row, Typography } from "antd";
import PageMeta from "@components/PageMeta";
import { BreadcrumbRenderer } from "@components/BreadcrumbRenderer";
import { Div } from "@components/Sections";

import { TrainingCenterReport, TcReportTypes } from "@models/trainingCenter";

import useDateFormatter from "@hooks/useDateFormat";

interface Props {
  data: TrainingCenterReport | undefined;
  isLoading: boolean;
  onEdit?: () => void;
  onDownload: () => void;
  path: string;
}

const REPORT_TYPE_TO_ROUTE_MAP: { [key: number]: string } = {
  [TcReportTypes.TRAINING_REPORT]: "training",
  [TcReportTypes.CERTIFICATE_REPORT]: "certificate",
  [TcReportTypes.PROFILE_REPORT]: "profile",
  [TcReportTypes.PROTOCOL_REPORT]: "protocol"
};

const { TextArea } = Input;

export const TCReportDetails = (props: Props) => {
  const [t, i18n] = useTranslation();
  const { formatter } = useDateFormatter();
  const pageTitle = props.data?.type
    ? REPORT_TYPE_TO_ROUTE_MAP[props.data.type]
    : "";

  return (
    <>
      <PageMeta title={pageTitle} />
      <PageHeader
        breadcrumb={{
          routes: [
            { breadcrumbName: "CT", path: "/" },
            {
              breadcrumbName: t("reports.reports"),
              path: `/${props.path}/tc-reports/${pageTitle}`
            },
            {
              breadcrumbName: props.data?.name ? props.data?.name : "",
              path: ""
            }
          ],
          itemRender: BreadcrumbRenderer
        }}
      />
      <Card
        title={props.data?.name ? props.data?.name : ""}
        style={{ marginBottom: 32 }}
        loading={props.isLoading}
      >
        {props.path && props.path.includes("admin") && (
          <>
            <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
              <Col md={2}>
                <div style={{ fontSize: "24px" }}>
                  <UserOutlined />
                </div>
              </Col>
              <Col md={12}>
                <Div>
                  <div>
                    <Typography.Text>
                      {props.data?.trainingCenterName}
                    </Typography.Text>
                  </div>
                  <Typography.Text type="secondary">
                    {t("events.host")}
                  </Typography.Text>
                </Div>
              </Col>
            </Row>
            <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
              <Col md={2}>
                <div style={{ fontSize: "24px" }}>
                  <GlobalOutlined />
                </div>
              </Col>
              <Col md={12}>
                <Div>
                  <div>
                    <Typography.Text>
                      {
                        props.data?.city[
                          i18n.language === "ru" ? "nameRu" : "nameEn"
                        ]
                      }
                    </Typography.Text>
                  </div>
                  <Typography.Text type="secondary">
                    {t("city")}
                  </Typography.Text>
                </Div>
              </Col>
            </Row>
          </>
        )}

        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <SyncOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {t("events.phase", { no: props.data?.csrPhase })}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("events.csrPhase")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <CalendarOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {t(`fromToRange`, {
                    from: formatter(
                      props.data?.dateFrom ? props.data.dateFrom : "",
                      "short-date"
                    ),
                    to: formatter(
                      props.data?.dateTo ? props.data?.dateTo : "",
                      "short-date"
                    )
                  })}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("dateFrom")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <ScheduleOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {formatter(
                    props.data?.createdAt ? props.data?.createdAt : "",
                    "short-datetime"
                  )}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("createdAt")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <EditOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>{props.data?.edited}</Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("reports.numberOfEdits")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <ScheduleOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {formatter(
                    props.data?.updatedAt ? props.data?.updatedAt : "",
                    "short-datetime"
                  )}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("reports.editedAt")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <TextArea rows={4} disabled={true} value={props.data?.description} />
        <Row align="middle" style={{ marginTop: 16 }} gutter={8}>
          {props.onEdit && (
            <Col md={2}>
              <Button type="primary" onClick={props.onEdit}>
                {t("edit")}
              </Button>
            </Col>
          )}

          <Col md={2}>
            <Button type="primary" onClick={props.onDownload}>
              {t("download")}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};
