import { useContext } from "react";
import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { AxiosError } from "axios";
import { differenceInHours, format, parseISO } from "date-fns";

import { Button, Modal, notification, Popconfirm, Space, Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PoweroffOutlined,
  RedoOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { MemberTable } from "@components/common/MemberTable";
import { NewAdminMemberForm } from "@components/admin/members/NewAdminMemberForm";
import { EditAdminMemberForm } from "@components/admin/members/EditAdminMemberForm";
import ExportMembersButton from "@components/common/ExportMembersButton";

import { AdminMember } from "@models/adminMember";
import { MemberStatus } from "@models/member";

import { notificationApi } from "@api/notificationApi";
import { memberApi } from "@api/memberApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";
import { UserContext } from "@contexts/UserContext";

export const MembersPage: React.FC = () => {
  const [t] = useTranslation();
  const [isModalShown, setIsModalShown] = useState(false);
  const [editedMember, setEditedMember] = useState<AdminMember>();

  const { user } = useContext(UserContext);

  const { data, isLoading, refetch } = useQuery("getAllAdminMembers", () =>
    memberApi.getAdminMembers().then((res) => res.data)
  );
  const { data: notificationDictionary } = useQuery(
    "getAdminNotifications",
    () => notificationApi.getAdminNotifications().then((res) => res.data),
    {
      cacheTime: 10000
    }
  );

  const activeMembers = data?.filter((am) => am.status === MemberStatus.ACTIVE);
  const inactiveMembers = data?.filter(
    (am) => am.status === MemberStatus.INACTIVE
  );
  const invitedMembers = data?.filter(
    (am) => am.status === MemberStatus.INVITED
  );

  const inviteMemberMutation = useValidatedMutation({
    mutationFunction: (values: any) => memberApi.inviteAdminMember(values),
    onSuccess(_, values) {
      notification.success({
        message: t("members.inviteSuccess", { email: values.email })
      });
      refetch();
      setIsModalShown(false);
    },
    onError() {}
  });
  const inviteErrors = useMemo(
    () => transformValidationError(inviteMemberMutation.error),
    [inviteMemberMutation.error]
  );

  const deleteInviteMutation = useMutation(
    (email: string) => memberApi.deleteAdminMemberInvite(email),
    {
      onSuccess() {
        notification.success({ message: t("members.inviteDeleted") });

        refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const deleteMemberMutation = useMutation(
    (id: number) => memberApi.deleteAdminMember(id),
    {
      onSuccess() {
        notification.success({ message: t("members.memberDeleted") });

        refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const resendInviteMutation = useMutation(
    (email: string) => memberApi.resendAdminMemberInvite(email),
    {
      onSuccess(_, email) {
        notification.success({
          message: t("members.inviteResent", { email })
        });

        refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const activateMemberMutation = useMutation(
    (member: AdminMember) => memberApi.activateAdminMember(member.userId),
    {
      onSuccess(_, member) {
        notification.success({
          message: t("members.activationSuccess", { email: member.email })
        });
        refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const editMemberMutation = useValidatedMutation({
    mutationFunction: ({ id, payload }: { id: number; payload: any }) =>
      memberApi.editAdminMember(id, payload),

    onSuccess() {
      notification.success({
        message: t("members.editSuccess")
      });
      refetch();
      setEditedMember(undefined);
    }
  });
  const editErrors = useMemo(
    () => transformValidationError(editMemberMutation.error),
    [editMemberMutation.error]
  );

  return (
    <>
      <PageMeta title={t("members.members")} />

      <AppPageHeader
        title={t("members.members")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "admin" },
          { breadcrumbName: t("members.members"), path: "members" }
        ]}
        extra={[
          <ExportMembersButton
            fileName="members_export.xlsx"
            url="/api/admin/export/users/admins"
          />,
          <Button
            key="2"
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => setIsModalShown(true)}
          >
            {t("members.inviteMember")}
          </Button>
        ]}
      />

      <Modal
        visible={isModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("members.memberInfo")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsModalShown(false);
          inviteMemberMutation.reset();
        }}
      >
        <NewAdminMemberForm
          isLoading={inviteMemberMutation.isLoading}
          errors={inviteErrors}
          notifications={notificationDictionary}
          onCancel={() => {
            setIsModalShown(false);
            inviteMemberMutation.reset();
          }}
          onSubmit={(values) => {
            inviteMemberMutation.mutate(values);
          }}
        />
      </Modal>

      <Modal
        visible={!!editedMember}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("edit")}
        footer={null}
        width={800}
        onCancel={() => {
          setEditedMember(undefined);
          editMemberMutation.reset();
        }}
      >
        <EditAdminMemberForm
          adminMember={editedMember}
          errors={editErrors}
          isLoading={editMemberMutation.isLoading}
          notifications={notificationDictionary}
          onCancel={() => {
            setEditedMember(undefined);
            editMemberMutation.reset();
          }}
          onSubmit={(values) => {
            if (editedMember) {
              editMemberMutation.mutate({
                id: editedMember.userId,
                payload: values
              });
            }
          }}
        />
      </Modal>

      <MemberTable
        title={t("members.activeMembers")}
        isLoading={isLoading}
        members={activeMembers}
        renderActions={(am: AdminMember) => (
          <Space size="middle">
            <Button
              title={t("edit")}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              disabled={user?.id === am.userId}
              onClick={() => {
                setEditedMember(am);
              }}
            />

            <Popconfirm
              title={t("members.confirmDelete", {
                name: am.firstName + " " + am.lastName
              })}
              cancelText={t("cancel")}
              okText={t("yes")}
              disabled={
                deleteMemberMutation.isLoading || user?.id === am.userId
              }
              onConfirm={() => deleteMemberMutation.mutate(am.userId)}
            >
              <Button
                title={t("members.delete")}
                danger
                shape="circle"
                disabled={
                  deleteMemberMutation.isLoading || user?.id === am.userId
                }
                loading={
                  deleteMemberMutation.isLoading &&
                  deleteMemberMutation.variables === am.userId
                }
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )}
      />

      <br />

      <MemberTable
        title={t("members.inactiveMembers")}
        isLoading={isLoading}
        members={inactiveMembers}
        renderActions={(am) => (
          <Space size="middle">
            <Button
              title={t("members.activate")}
              type="primary"
              shape="circle"
              icon={<PoweroffOutlined />}
              disabled={activateMemberMutation.isLoading}
              loading={
                activateMemberMutation.isLoading &&
                activateMemberMutation.variables === am.userId
              }
              onClick={() => activateMemberMutation.mutate(am)}
            />

            <Popconfirm
              title={t("members.confirmDelete", {
                name: am.firstName + " " + am.lastName
              })}
              cancelText={t("cancel")}
              okText={t("yes")}
              disabled={deleteMemberMutation.isLoading}
              onConfirm={() => deleteMemberMutation.mutate(am.userId)}
            >
              <Button
                title={t("delete")}
                danger
                shape="circle"
                disabled={deleteMemberMutation.isLoading}
                loading={
                  deleteMemberMutation.isLoading &&
                  deleteMemberMutation.variables === am.userId
                }
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )}
      />

      <br />

      <MemberTable
        title={t("members.invitedMembers")}
        isLoading={isLoading}
        members={invitedMembers}
        extraColumns={[
          {
            title: t("members.invitationDate"),
            render: (am: AdminMember) =>
              format(parseISO(am.invitedAt), "yyyy/MM/dd HH:mm:ss")
          }
        ]}
        renderActions={(am) => {
          const inviteDate = parseISO(am.invitedAt);
          const hourDiff = differenceInHours(Date.now(), inviteDate);
          const isInviteExpired = hourDiff >= 24;

          return (
            <Space size="middle">
              <Tooltip
                title={
                  isInviteExpired
                    ? t("members.resendPossible")
                    : t("members.previousInviteNotExpired")
                }
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={<RedoOutlined />}
                  disabled={!isInviteExpired || resendInviteMutation.isLoading}
                  loading={
                    resendInviteMutation.isLoading &&
                    resendInviteMutation.variables === am.email
                  }
                  onClick={() => resendInviteMutation.mutate(am.email)}
                />
              </Tooltip>

              <Popconfirm
                title={t("members.confirmDelete", {
                  name: am.firstName + " " + am.lastName
                })}
                cancelText={t("cancel")}
                okText={t("yes")}
                disabled={deleteInviteMutation.isLoading}
                onConfirm={() => deleteInviteMutation.mutate(am.email)}
              >
                <Button
                  title={t("delete")}
                  danger
                  shape="circle"
                  disabled={deleteInviteMutation.isLoading}
                  loading={
                    deleteInviteMutation.isLoading &&
                    deleteInviteMutation.variables === am.email
                  }
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          );
        }}
      />

      <br />
    </>
  );
};
