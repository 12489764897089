import {
  CompetencyLevel,
  CustomCompetencyLevel
} from "@models/competencyLevel";

export const COMPETENCY_LEVEL_SELECT_OPTIONS: {
  label: string;
  value: number;
}[] = [
  {
    label: `competencyLevels.${CompetencyLevel.BASIC}`,
    value: CompetencyLevel.BASIC
  },
  {
    label: `competencyLevels.${CompetencyLevel.COMPETENT}`,
    value: CompetencyLevel.COMPETENT
  },
  {
    label: `competencyLevels.${CompetencyLevel.ADVANCED}`,
    value: CompetencyLevel.ADVANCED
  },
  {
    label: `competencyLevels.${CompetencyLevel.INTERMEDIATE}`,
    value: CompetencyLevel.INTERMEDIATE
  },
  {
    label: `competencyLevels.${CompetencyLevel.PROFICIENT}`,
    value: CompetencyLevel.PROFICIENT
  },
  {
    label: `competencyLevels.${CompetencyLevel.HELPER}`,
    value: CompetencyLevel.HELPER
  },
  {
    label: `competencyLevels.${CompetencyLevel.TECHNICIAN}`,
    value: CompetencyLevel.TECHNICIAN
  },
  {
    label: `competencyLevels.${CompetencyLevel.JUNIOR_TECHNICIAN}`,
    value: CompetencyLevel.JUNIOR_TECHNICIAN
  },
  {
    label: `competencyLevels.${CompetencyLevel.TRAINEE_TECHNICIAN}`,
    value: CompetencyLevel.TRAINEE_TECHNICIAN
  },
  {
    label: `competencyLevels.${CompetencyLevel.SENIOR_TECHNICIAN}`,
    value: CompetencyLevel.SENIOR_TECHNICIAN
  }
];

export const COMMISSIOINING_COMPETENCY_LEVEL_SELECT_OPTIONS: {
  label: string;
  value: number;
}[] = [
  {
    label: `competencyLevels.${CompetencyLevel.TECHNICIAN}`,
    value: CompetencyLevel.TECHNICIAN
  },
  {
    label: `competencyLevels.${CompetencyLevel.JUNIOR_TECHNICIAN}`,
    value: CompetencyLevel.JUNIOR_TECHNICIAN
  },
  {
    label: `competencyLevels.${CompetencyLevel.TRAINEE_TECHNICIAN}`,
    value: CompetencyLevel.TRAINEE_TECHNICIAN
  },
  {
    label: `competencyLevels.${CompetencyLevel.SENIOR_TECHNICIAN}`,
    value: CompetencyLevel.SENIOR_TECHNICIAN
  }
];

export const CUSTOM_COMPETENCY_LEVEL_SELECT_OPTIONS: {
  label: string;
  value: number;
}[] = [
  {
    label: `customCompetencyLevels.${CustomCompetencyLevel.DEFAULT}`,
    value: CustomCompetencyLevel.DEFAULT
  },
  {
    label: `customCompetencyLevels.${CustomCompetencyLevel.INTERMEDIATE_ADVANCED}`,
    value: CustomCompetencyLevel.INTERMEDIATE_ADVANCED
  }
];
