import picture37 from "@assets/images/user-guide/1.37.png";
import picture38 from "@assets/images/user-guide/1.38.png";
import picture39 from "@assets/images/user-guide/1.39.png";

export const profiles = [
  {
    title: "What`s on Profiles tab:",
    html: `<p dir="ltr">
    Profile tab shows job- seeking craft worker profiles, which were registered
    in the system so that Contractor/ Subcontractor users could view and hire
    profiles. This module also stores all the information in the system.
</p>
<p dir="ltr">
    Remember, in order to create a craft employee profile in the HR module, the
    craft employee must be already registered within the CT Portal via CSR
    Contractors.
</p>
<br/>
<p dir="ltr">
    Once you press Profiles tab on Panel menu you will be redirected to
    Profiles page with the following information:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Create profile button;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filters;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            List of profiles registered in the system.
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Picture 1.37
</p>
<p dir="ltr">
    <img
        src=${picture37}
        width="624"
        height="774.9635815620422"
    />
</p>
`
  },
  {
    title: "How to create a profile?",
    html: `<p dir="ltr">
    Note: employees must be registered in the CT portal prior to profile
    creation.
</p>
<br/>
<p dir="ltr">
    First of all, TCO users should enter employee\`s IIN so that the system will
    search for a registered craft employee. After finding it, TCO user should
    press the Next button and fill out the several fields.
</p>
<br/>
<p dir="ltr">
    To create a craft employee profile:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            TCO users should press the Profile tab on the panel menu.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System will show a page of all profiles
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TCO user should press “Create profile” button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System will show modal window with a field to enter craft worker\`s
            IIN
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TCO user should enter craft worker\`s IIN and press ‘Search by IIN’
            button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Next, system searches for IIN
        </p>
    </li>
</ol>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            If registered, system will show craft worker\`s name in the modal
            window
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If not, system will show “Employee not found in the system or
            incorrect IIN” message. You will not be able to create a profile in
            this case.
        </p>
    </li>
</ul>
<ol start="7">
    <li dir="ltr">
        <p dir="ltr">
            TCO user presses Next button;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System shows required fields* to be filled out by TCO user as shown
            on the screenshot below;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TCO user fills out fields
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TCO user presses Create button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System creates a craft employee profile
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    The following fields must filled out in order to create a profile:
</p>
<br/>
<p dir="ltr">
    Picture 1.38
</p>
<p dir="ltr">
    <img
        src=${picture38}
        width="624"
        height="660"
    />
</p>
<br/>
<div dir="ltr" align="left">
    <table>
        <colgroup>
            <col width="133"/>
            <col width="171"/>
            <col width="59"/>
            <col width="260"/>
        </colgroup>
        <tbody>
            <tr>
                <td>
                    <p dir="ltr">
                        Field ENG
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Field RU
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M/O*
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Description
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Name
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Имя (ФИО)
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        М
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        pre-filled from the craft employee data in the system.
                    </p>
                    <p dir="ltr">
                        See pre-condition
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR Contractor
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Подрядчик КСО
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Dropdown menu:
                    </p>
                    <p dir="ltr">
                        CSR Aktau, CSR Atyrau, Demob
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Discipline
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дисциплина
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Only one discipline can be set.
                    </p>
                    <p dir="ltr">
                        Dropdown list: all disciplines of CT portal
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Home address
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Адрес
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Tel. number
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Номер телефона
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        pre-filled from the craft employee data in the system.
                    </p>
                    <p dir="ltr">
                        See pre-condition
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Date of birth
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дата рождения
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        pre-filled from the craft employee data in the system.
                    </p>
                    <p dir="ltr">
                        See pre-condition
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Marital status
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Семейное положение
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        М
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Dropdown menu:
                    </p>
                    <p dir="ltr">
                        Married/ Single
                    </p>
                    <p dir="ltr">
                        Widower/Widow
                    </p>
                    <hr/>
                    <p dir="ltr">
                        Женат/Замужем
                    </p>
                    <p dir="ltr">
                        Не женат/ Не замужем
                    </p>
                    <p dir="ltr">
                        Вдовец/Вдова
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Education
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Образование
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Additional education
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дополнительные курсы
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Work experience
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Рабочий стаж
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Language
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Знание языков
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Dropdown
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Personal qualities
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Личные качества
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        O
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Additional information
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дополнительные сведения
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        O
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<br/>
<div dir="ltr" align="left">
    <table>
        <colgroup>
            <col width="136"/>
            <col width="166"/>
            <col width="53"/>
            <col width="270"/>
        </colgroup>
        <tbody>
            <tr>
                <td>
                    <p dir="ltr">
                        Employment status
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Статус
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        O
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        dropdown menu:
                    </p>
                    <p dir="ltr">
                        employed/ Unemployed
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Contractor/ Subcontractor
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Подрядчик/ Субподрядчик
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        O
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        dropdown menu:
                    </p>
                    <p dir="ltr">
                        all Contractors/ Subcontractors in the system &amp;
                    </p>
                    <p dir="ltr">
                        other company
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Other company
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Другая
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        O
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                    <p dir="ltr">
                        if Contractor/ Subcontractor was not found
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<br/>
<p dir="ltr">
    *M/O stands for Mandatory/Optional
</p>
`
  },
  {
    title: "How to edit a profile?",
    html: `<p dir="ltr">
    TCO users can update any information related to craft employee profiles
    filled out during the create stage. Except employee name, IIN and a phone
    number.
</p>
`
  },
  {
    title: "How to view a profile?",
    html: `<p dir="ltr">
    TCO users can view an employee profile. To do that, please navigate to the
    Profile tab, then choose craft employee\`s profile in the list.
</p>
<p dir="ltr">
    Profile page contains detailed data about a person: name, date of birth,
    tel.number etc.
</p>
<p dir="ltr">
    Also, there are buttons to Hire and Download profile info.
</p>
<br/>
<p dir="ltr">
    Page example:
</p>
<p dir="ltr">
    Picture 1.39
</p>
<p dir="ltr">
    <img
        src=${picture39}
        width="624"
        height="352"
    />
</p>
`
  },
  {
    title: "How to download a profile?",
    html: `<p dir="ltr">
    TCO users can download an employee profile to a pdf file. To do that,
    please navigate to the Profile tab, then to the craft employee\`s profile
    page and press the Download button.
</p>
`
  },
  {
    title: "How to use filters?",
    html: `<p dir="ltr">
    System lets Users filter HR module data. List of filters available is
    specified below.
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            System shows All profile page and filters
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TCO user sets filters
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TCO user presses Search button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To clear filters User presses ‘Clear filters’ data
        </p>
    </li>
</ol>
`
  },
  {
    title: "What filters are available?",
    html: `<div dir="ltr" align="left">
    <table>
        <colgroup>
            <col width="91"/>
            <col width="92"/>
            <col width="261"/>
            <col width="180"/>
        </colgroup>
        <tbody>
            <tr>
                <td>
                    <p dir="ltr">
                        Filters ENG
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Filters RU
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Values/ Description
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Employment status
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Статус трудовой занятости
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employed/ Unemployed
                    </p>
                    <p dir="ltr">
                        Трудоустроен/ Не трудоустроен
                    </p>
                    <p dir="ltr">
                        Single select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                    <p dir="ltr">
                        Contractor/Subcontractor can only see Unemployed
                        profiles. This filter is not shown for Contractor/
                        Subcontractor users.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Discipline
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дисциплина
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        List of all disciplines in CT portal
                    </p>
                    <p dir="ltr">
                        Multi select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR phase
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Фаза КСО
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        None, CSR 1- 50
                        <a
                            href="https://petrelai.atlassian.net/browse/CTN-1040"
                        >
                        </a>
                    </p>
                    <p dir="ltr">
                        Multi select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR Contractor
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Подрядчик КСО
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        CSR Aktau, CSR Atyrau or Demob
                    </p>
                    <p dir="ltr">
                        КСО Актау, КСО Атырау, Демоб
                    </p>
                    <p dir="ltr">
                        Multi select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        IIN
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        ИИН
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Craft Employee IIN input field
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown to TC &amp; TCO users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Clear all button
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Очистить
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        clears all filters set
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Search button
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Поиск
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        starts the filtration/ search
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
`
  }
];
