import { useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { AxiosError } from "axios";
import Modal from "antd/lib/modal/Modal";

import { Button, notification } from "antd";
import { CloudDownloadOutlined, UserAddOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { EmployeesTable } from "@components/common/employees/EmployeesTable";
import { EmployeeFilterForm } from "@components/contractors/employees/EmployeeFilterForm";
import { EmployeeBatchUploadModal } from "@components/contractors/employees/EmployeeBatchUploadModal";
import { NewEmployeeFormContainer } from "@components/common/employees/NewEmployeeFormContainer";

import { CreateEmployeePayload } from "@models/employee";

import { employeeApi } from "@api/employeeApi";
import { contractApi } from "@api/contractApi";
import { countryCityApi } from "@api/countryCityApi";
import { subcontractorApi } from "@api/subcontractorApi";
import { disciplineApi } from "@api/disciplineApi";

import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { useQueryParams } from "@hooks/useQueryParams";
import { transformValidationError } from "@utils/errorHelper";
import { usePagination } from "@hooks/usePagination";

interface Filters {
  iin?: string;
  firstName?: string;
  lastName?: string;
  specialityIds?: number[];
  competencyLevel?: number;
}

export const EmployeesPage = () => {
  const [t] = useTranslation();
  const queryParams = useQueryParams();

  const { userContractor, userEntityId: contractorId } =
    useContext(UserContext);

  const initialFilters = useMemo(
    () => ({
      iin: queryParams.iin,
      firstName: queryParams.firstName,
      lastName: queryParams.lastName,
      competencyLevel: Number(queryParams.competencyLevel) || undefined,
      specialityIds: queryParams.specialityIds
        ? queryParams.specialityIds.map((idStr: string) => Number(idStr))
        : undefined
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [showNewEmployeeModal, setShowNewEmployeeModal] = useState(false);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const { page, setPage, setPagination } = usePagination();
  const [filters, setFilters] = useState<Filters>(initialFilters);

  const employeePage = useQuery(
    ["getEmployeePage", page, filters],
    () =>
      employeeApi
        .getEmployeePage({ contractorId, page, filters })
        .then((res) => res.data),
    {
      retry: false,
      onSuccess(res) {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterProps: filters
        };
        setPagination(pagination);
      }
    }
  );

  const contractList = useQuery(["contractorContracts", contractorId], () =>
    contractApi.getContractorContracts(contractorId).then((res) => res.data)
  );

  const subcontractorList = useQuery(
    ["contractorSubcontractors", contractorId],
    () =>
      subcontractorApi
        .getSubcontractorList(contractorId)
        .then((res) => res.data)
  );
  const allContractorList = useMemo(() => {
    return userContractor
      ? [userContractor, ...(subcontractorList.data || [])]
      : [];
  }, [userContractor, subcontractorList.data]);

  const countryList = useQuery("allCountries", () =>
    countryCityApi.getAllCountries().then((res) => res.data)
  );

  const disciplineList = useQuery("allDisciplines", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const templateDownloadMutation = useMutation(() =>
    employeeApi.downloadBatchUploadTemplate(contractorId)
  );

  const createEmployeeMutation = useValidatedMutation({
    mutationFunction: (values: CreateEmployeePayload) =>
      employeeApi.createEmployee({ contractorId, employee: values }),
    onSuccess() {
      notification.success({
        message: t("employees.createSuccess")
      });

      employeePage.refetch();
      setShowNewEmployeeModal(false);
    }
  });

  const createEmployeeBatchMutation = useValidatedMutation({
    mutationFunction: (employees: Partial<CreateEmployeePayload>[]) =>
      employeeApi
        .createEmployeeBatch({ contractorId, employees })
        .catch((err: AxiosError<Error>) => {
          if (err?.response?.status) {
            notification.error({ message: t("employees.checkDataError") });
          }

          throw err;
        }),
    onSuccess() {
      notification.success({
        message: t("employees.batchCreateSuccess")
      });

      employeePage.refetch();
      setShowBatchModal(false);
    }
  });
  const batchCreationErrors = transformValidationError(
    createEmployeeBatchMutation.error
  );

  useEffect(() => {
    if (!showBatchModal) {
      createEmployeeBatchMutation.reset();
    }

    // eslint-disable-next-line
  }, [showBatchModal]);

  const paginationSettings = {
    current: employeePage.data?.currentPage,
    pageSize: employeePage.data?.pageSize,
    total: employeePage.data?.totalItems,
    hideOnSinglePage: true,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={t("employees.employees")} />

      <AppPageHeader
        title={t("employees.craftEmployeeList")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "contractor" },
          {
            breadcrumbName: t("employees.employeesPassport"),
            path: "employees"
          }
        ]}
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => setShowNewEmployeeModal(true)}
          >
            {t("employees.addEmployee")}
          </Button>,
          <Button
            key="2"
            type="default"
            icon={<CloudDownloadOutlined />}
            onClick={() => setShowBatchModal(true)}
          >
            {t("employees.uploadEmployees")}
          </Button>
        ]}
      />

      <Modal
        visible={showNewEmployeeModal}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("employees.addEmployee")}
        footer={null}
        width={900}
        onCancel={() => setShowNewEmployeeModal(false)}
      >
        <NewEmployeeFormContainer
          countries={countryList.data}
          contracts={contractList.data}
          contractors={allContractorList}
          onSubmit={(values) => {
            createEmployeeMutation.mutate(values);
          }}
          onCancel={() => {
            setShowNewEmployeeModal(false);
          }}
        />
      </Modal>

      <EmployeeBatchUploadModal
        visible={showBatchModal}
        isLoading={createEmployeeBatchMutation.isLoading}
        errors={batchCreationErrors}
        countries={countryList.data}
        contractors={allContractorList}
        contracts={contractList.data}
        onCancel={() => setShowBatchModal(false)}
        onTemplateDownload={() => templateDownloadMutation.mutate()}
        onSubmit={(employees) => {
          createEmployeeBatchMutation.mutate(employees);
        }}
      />

      <EmployeeFilterForm
        initialValues={initialFilters}
        disciplines={disciplineList.data}
        onSubmit={(values) => {
          setFilters(values);
          setPage(1);
        }}
      />

      <EmployeesTable
        employees={employeePage.data?.results}
        rowLinkTo={(s) => `/contractor/employees/${s.id}`}
        isLoading={employeePage.isFetching}
        pagination={paginationSettings}
      />
    </>
  );
};
