export const enUserGuide = {
  general: "General",
  reports: "Reports",
  search: "Search",
  events: "Events",
  employees: "Employess",
  members: "Members",
  certificateVerification: "Certificate verification",
  profileSub: "Profile (Craft employee, HR profiles)",
  profileContract: "Profile (Craft employee, HR profiles)",
  profilesCT: "Profiles (HR Module)",
  profiles: "Profiles",
  trainingCenters: "Training centers",
  certificatesSearch: "Certificates search",
  employeePassport: "Employee passport",
  contractors: "Contractors",
  subcontractors: "Subcontractors",
  TCReports: "TC Reports",
  disciplines: "Disciplines",
  migrations: "Migrations",
  requests: "Requests"
};
