export const USER_ROLES = {
  NONE: 0,

  TRAINING_CENTER_TEACHER: 1,
  TRAINING_CENTER_MANAGER: 2,
  TRAINING_CENTER_OWNER: 3,

  SUBCONTRACTOR_MANAGER: 4,
  SUBCONTRACTOR_OWNER: 5,

  CONTRACTOR_MANAGER: 6,
  CONTRACTOR_OWNER: 7,

  ADMINISTRATOR: 8
};
