import { AxiosError } from "axios";
import { FormikErrors } from "formik";

import { ApiValidationError } from "@models/apiValidationError";

export const transformValidationError = (
  error: AxiosError<ApiValidationError> | null
) => {
  const errData = error?.response?.data;

  if (errData?.validationErrors) {
    return [...errData.validationErrors]
      .reverse()
      .reduce((o, e) => ({ ...o, [e.name]: e.description }), {});
  } else {
    return {};
  }
};

export const isValidationError = (error: AxiosError<any> | null) => {
  if (error === null) {
    return false;
  }
  const errStatus = error.response?.status;
  const errData = error?.response?.data;

  return errStatus === 400 && errData.validationErrors;
};

interface ValidationError<Q> {
  isValidationError: true;
  errors: FormikErrors<Q>;
}

interface GenericError {
  isValidationError: false;
  error: {
    status: number | undefined;
    message: string;
  };
}

export const formErrorHandler = <Q>(
  error: AxiosError<any>
): ValidationError<Q> | GenericError | null => {
  const errStatus = error.response?.status;
  const errData = error.response?.data;

  if (isValidationError(error)) {
    return {
      isValidationError: true,
      errors: transformValidationError(error)
    };
  }

  if (errStatus && errStatus < 500) {
    return {
      isValidationError: false,
      error: {
        status: errStatus,
        message: errData?.message
      }
    };
  }

  return null;
};
