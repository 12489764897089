import styled from "styled-components";

export const Div = styled.div`
  flex-direction: column;
`;

export const Section = styled.div`
  margin-bottom: 32px;
`;

export const SubSection = styled.div`
  margin-bottom: 24px;
`;
