import axios, { AxiosRequestConfig } from "axios";

import qs from "qs";

import { notification } from "antd";
import { scopes, msalInstance } from "../authConfig";
export interface PaginatedResponse<T> {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  results: T[];
}

export interface FakeAxiosResponse<T> {
  data: T;
}

const AUTH_EXCEPTIONS = ["/login"];

axios.defaults.baseURL =
  process.env.REACT_APP_CT_API_URL ||
  "https://api-fgpctp-prod-cvx.azurewebsites.net/";

axios.interceptors.request.use(async function (config: AxiosRequestConfig) {
  if (config.headers) {
    config.headers["Content-Type"] = "application/json";
  }

  const account = msalInstance.getAllAccounts()[0];
  if (account) {
    const accessTokenResponse = await msalInstance.acquireTokenSilent({
      scopes: scopes,
      account: account
    });

    if (accessTokenResponse) {
      const accessToken = accessTokenResponse.accessToken;

      if (config.headers && accessToken) {
        config.headers["Authorization"] = "Bearer " + accessToken;
      }
    }
  }
  return config;
});

axios.defaults.paramsSerializer = (qp) => {
  return qs.stringify(qp, { arrayFormat: "indices" });
};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const errorStatus = error?.response?.status;

    if (errorStatus >= 500) {
      notification.error({
        message: `Error Status: ${error.response.status}`,
        description: error.response.data?.Message
      });
    } else if (errorStatus === 401) {
      localStorage.removeItem("CT_PORTAL_USER_TOKEN");
    } else if (Number.isNaN(errorStatus)) {
      notification.error({
        message: `Something went wrong. Try again.`
      });
    }

    return Promise.reject(error);
  }
);
