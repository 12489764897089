import picture35 from "@assets/images/user-guide/1.35.png";

export const disciplice = [
  {
    title: "Disciplines",
    html: `<p dir="ltr">
    This functionality lets TCO users with relevant permissions to add a new
    discipline within the Craft Training portal.
</p>
<p dir="ltr">
    Once you press Disciplines tab on Panel menu you will be redirected to
    Disciplines page with the following information:
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Create discipline button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline list
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Edit button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline creation date
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Picture 1.35
</p>
<p dir="ltr">
    <img
        src=${picture35}
        width="624"
        height="770.9935755729675"
    />
</p>
`
  },
  {
    title: "How to create a new discipline?",
    html: `<p dir="ltr">
    To create a new discipline TCO user should fill out the following fields:
</p>
<p dir="ltr">
    -Discipline name in English and Russian;
</p>
<p dir="ltr">
    -Category (parent category to the discipline), optional;
</p>
<p dir="ltr">
    -Event type: commissioning or non- commissioning;
</p>
<p dir="ltr">
    -Competency level;
</p>
<p dir="ltr">
    -Cancel and Save buttons.
</p>
<br/>
<br/>
<p dir="ltr">
    Discipline list shows recently added disciplines;
</p>
`
  },
  {
    title: "How to edit discipline?",
    html: `<p dir="ltr">
    Edit button lets the user update the discipline name.
</p>
<br/>
<p dir="ltr">
    Discipline creation date.
</p>
`
  }
];
