import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { Button, Card, notification, Space, Tabs, Tag, Typography } from "antd";
import { EventDetailsCard } from "@components/common/events/EventDetailsCard";
import { EventEmployeeCard } from "@components/common/events/EventEmployeeCard";
import { EventEmployeesFilterForm } from "@components/common/events/EventEmployeesFilterForm";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import EventEditModal from "@pages/admin/events/components/EventEditModal";
import EventCancelModal from "@pages/admin/events/components/EventCancelModal";
import ViewEventAttendanceTable from "@components/common/events/ViewEventAttendanceTable";
import DownloadLink from "@components/common/DownloadLink";
import RejectEmployeeModal from "@components/common/events/modals/RejectEmployeeModal";
import DeleteEmployeeModal from "@components/common/events/modals/DeleteEmployeeModal";
import EventGradesTable from "@components/common/events/EventGradesTable";
import ViewEventFeedbackModal from "@components/common/events/modals/ViewEventFeedbackModal";
import EventDeleteModal from "@pages/admin/events/components/EventDeleteModal";
import EventActivateModal from "@pages/admin/events/components/EventActivateModal";
import { RegisterEmployeesToEventTemplate } from "@components/admin/common/RegisterEmployeesToEventTemplate";

import { EventEmployeeStatus, EventStatus, EventType } from "@models/event";
import { EmployeeEventFilters } from "@models/employee";
import { PERMISSIONS } from "@models/permissions";

import { eventsApi } from "@api/eventsApi";
import { contractApi } from "@api/contractApi";
import { countryCityApi } from "@api/countryCityApi";
import { employeeApi } from "@api/employeeApi";
import { eventApi } from "@api/eventApi";
import { subcontractorApi } from "@api/subcontractorApi";

import { useDefaultQuery } from "@hooks/useDefaultQuery";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";
import { EVENT_STATUS_COLOR_MAP } from "@constants/eventStatusColorMap";
import { EVENT_STATUS_TO_ROUTE_MAP } from "@constants/eventStatusToRouteMap";
import { UserContext } from "@contexts/UserContext";

interface RejectEmployeeState {
  employeeId?: number;
  employeeName?: string;
}

export const EventDetailsPage: React.FC = () => {
  const [t, i18n] = useTranslation();
  const { checkPermission } = useContext(UserContext);

  const [employeeFilters, setEmployeeFilters] = useState<EmployeeEventFilters>(
    {}
  );

  const [rejectEmployeeState, setRejectEmployeeState] =
    useState<RejectEmployeeState>({});
  const [deleteEmployeeState, setDeleteEmployeeState] =
    useState<RejectEmployeeState>({});
  const [viewFeedbackModalVisible, setViewFeedbackModalVisible] =
    useState(false);

  const params = useParams<{ id: string }>();
  const eventId = Number(params.id);

  const eventDetails = useQuery("getEventDetails", () =>
    eventsApi.getAdminEventDetails(eventId).then((res) => res.data)
  );

  const contractorList = useQuery(["getAdminEventContractors", eventId], () =>
    eventsApi.getAdminEventContractors(eventId).then((res) => res.data)
  );

  const eventData = eventDetails.data;

  const eventEmployees = useDefaultQuery(
    ["getAdminEventEmployees", eventId],
    () => eventsApi.getAdminEventEmployees(eventId).then((res) => res.data)
  );
  const filteredEventEmployees = useQuery(
    ["getFilteredEventEmployees", eventId, employeeFilters],
    () =>
      eventsApi
        .getAdminEventEmployees(eventId, employeeFilters)
        .then((res) => res.data)
  );

  const eventAttendance = useDefaultQuery(["getEventAttendance", eventId], () =>
    eventsApi.getAdminEventAttendance(eventId).then((res) => res.data)
  );

  const canActivateEvent =
    checkPermission(PERMISSIONS.EVENT_ACTIVATE) &&
    eventDetails.data?.status === EventStatus.EVALUATED;

  const handleSuccessApply = () => {
    eventDetails.refetch();
    contractorList.refetch();
    eventEmployees.refetch();
    filteredEventEmployees.refetch();
  };

  const canDeleteEvent =
    checkPermission(PERMISSIONS.EVENT_DELETE) &&
    eventDetails.data?.status !== EventStatus.CANCELLED;

  const canEditEvent =
    checkPermission(PERMISSIONS.EVENT_UPDATE) &&
    eventDetails.data?.status !== EventStatus.CANCELLED;

  const canCancelEvent =
    checkPermission(PERMISSIONS.EVENT_CANCEL) &&
    eventDetails.data?.status !== EventStatus.CANCELLED;

  const canReject = eventData?.status === EventStatus.PENDING;
  const rejectEmployee = useValidatedMutation({
    mutationFunction: (values: { rejectionReason: string }) =>
      eventsApi.adminRejectEventEmployee({
        eventId,
        employeeId: rejectEmployeeState.employeeId || 0,
        rejectionReason: values.rejectionReason
      }),
    onSuccess: () => {
      notification.success({
        message: t("events.rejectSuccessfull")
      });
      filteredEventEmployees.refetch();
      eventEmployees.refetch();
      setRejectEmployeeState({});
    },
    onError: () => notification.error(t("events.rejectError"))
  });

  const rejectEmployeeErrors = useMemo(
    () => transformValidationError(rejectEmployee.error),
    [rejectEmployee.error]
  );

  const canDelete = [
    EventStatus.INPROGRESS,
    EventStatus.COMPLETED,
    EventStatus.EVALUATED
  ].includes(eventData?.status || -1);
  const deleteEmployee = useValidatedMutation({
    mutationFunction: () =>
      eventsApi.adminDeleteEventEmlpoyee({
        eventId,
        employeeId: deleteEmployeeState.employeeId || 0
      }),
    onSuccess: () => {
      notification.success({
        message: t("events.deleteSuccessfull")
      });
      filteredEventEmployees.refetch();
      eventEmployees.refetch();
      setDeleteEmployeeState({});
    },
    onError: () => notification.error(t("events.deleteError"))
  });

  const deleteEmployeeErrors = useMemo(
    () => transformValidationError(deleteEmployee.error),
    [deleteEmployee.error]
  );
  const isEventPending =
    eventDetails.data?.status &&
    [EventStatus.PENDING, EventStatus.CANCELLED].includes(
      eventDetails.data?.status
    );

  const isEventOngoing =
    isEventPending || eventDetails.data?.status === EventStatus.INPROGRESS;

  const pageTitle = eventDetails.data
    ? eventDetails.data.name
    : t("events.event");

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={
          eventData && (
            <Space>
              {pageTitle}

              <Typography.Text style={{ fontSize: "12px", color: "#fb8c00" }}>
                ID: {eventData.id}
              </Typography.Text>

              <Tag
                color={EVENT_STATUS_COLOR_MAP[eventData.status]}
                style={{ marginRight: 16 }}
              >
                {t(`events.statuses.${eventData?.status}`)}
              </Tag>
            </Space>
          )
        }
        extra={[
          <Space>
            {canActivateEvent && eventDetails.data && (
              <EventActivateModal event={eventDetails.data} />
            )}

            {canEditEvent && eventDetails.data && (
              <EventEditModal
                event={eventDetails.data}
                onEdit={() => eventDetails.refetch()}
              />
            )}

            {canCancelEvent && eventDetails.data && (
              <EventCancelModal
                event={eventDetails.data}
                onEdit={() => eventDetails.refetch()}
              />
            )}
            {canDeleteEvent && eventDetails.data && (
              <EventDeleteModal event={eventDetails.data} onEdit={() => null} />
            )}
          </Space>
        ]}
        breadcrumbs={[
          {
            breadcrumbName: t("trainingCenters.trainingCenter"),
            path: "/"
          },
          {
            breadcrumbName: eventDetails.data?.status
              ? t("events.events") +
                " - " +
                t(`events.statuses.${eventDetails.data.status}`)
              : t("events.allEvents"),
            path: eventDetails.data?.status
              ? `/admin/events/${
                  EVENT_STATUS_TO_ROUTE_MAP[eventDetails.data.status]
                }`
              : "/admin/events/all"
          },
          {
            breadcrumbName: pageTitle,
            path: ""
          }
        ]}
      />

      <ViewEventFeedbackModal
        visible={viewFeedbackModalVisible}
        onClose={() => setViewFeedbackModalVisible(false)}
        feedbacks={eventData?.feedbacks || []}
      />

      {eventData ? (
        <EventDetailsCard
          eventData={eventData}
          showViewFeedbackBtn={[
            EventStatus.COMPLETED,
            EventStatus.EVALUATED
          ].includes(eventData.status)}
          onViewFeedback={() => setViewFeedbackModalVisible(true)}
        />
      ) : (
        <Card loading={true} />
      )}

      <br />

      <Card
        title={t("events.participantList")}
        loading={
          eventDetails.isLoading ||
          eventEmployees.isLoading ||
          filteredEventEmployees.isLoading
        }
      >
        {eventData && (
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              key="1"
              tab={t("events.allParticipants", {
                number: eventData.capacityBusy
              })}
            >
              <EventEmployeesFilterForm
                contractors={contractorList.data}
                onSubmit={(v) => {
                  setEmployeeFilters(v);
                }}
              />

              <RejectEmployeeModal
                employeeName={rejectEmployeeState.employeeName}
                visible={rejectEmployeeState.employeeId !== undefined}
                onCancel={() => setRejectEmployeeState({})}
                onSubmit={(rejectionReason) =>
                  rejectEmployee.mutate({ rejectionReason })
                }
                isLoading={rejectEmployee.isLoading}
                errors={rejectEmployeeErrors}
              />

              <DeleteEmployeeModal
                employeeName={deleteEmployeeState.employeeName}
                visible={deleteEmployeeState.employeeId !== undefined}
                onCancel={() => setDeleteEmployeeState({})}
                onSubmit={() => deleteEmployee.mutate({})}
                isLoading={deleteEmployee.isLoading}
                errors={deleteEmployeeErrors}
              />

              {filteredEventEmployees.data?.map((ee) => (
                <EventEmployeeCard
                  eventDetails={eventDetails.data}
                  employeeDetails={ee}
                  key={ee.employeeId}
                  renderExtraAction={() => {
                    if (ee.status !== EventEmployeeStatus.REGISTERED) {
                      return null;
                    }

                    if (canReject) {
                      return (
                        <Button
                          danger
                          size="small"
                          onClick={() =>
                            setRejectEmployeeState({
                              employeeId: ee.employeeId,
                              employeeName:
                                i18n.language === "en"
                                  ? `${ee.firstName} ${ee.lastName}`
                                  : `${ee.firstNameCyrillic} ${ee.lastNameCyrillic}`
                            })
                          }
                        >
                          {t("events.rejectEmployee")}
                        </Button>
                      );
                    }

                    if (canDelete) {
                      return (
                        <Button
                          danger
                          size="small"
                          onClick={() =>
                            setDeleteEmployeeState({
                              employeeId: ee.employeeId,
                              employeeName:
                                i18n.language === "en"
                                  ? `${ee.firstName} ${ee.lastName}`
                                  : `${ee.firstNameCyrillic} ${ee.lastNameCyrillic}`
                            })
                          }
                        >
                          {t("events.deleteEmployee")}
                        </Button>
                      );
                    }

                    return null;
                  }}
                />
              ))}

              <br />

              <Space>
                <DownloadLink
                  url={eventData?.resultFileLink || ""}
                  label={t("events.download")}
                  size="large"
                  type="primary"
                  fileName={`event_${eventId}_details.xlsx`}
                />

                <DownloadLink
                  url={`/api/event/${eventId}/attendance/download`}
                  label={t("events.downloadAttendance")}
                  size="large"
                  type="default"
                  fileName={`event_${eventId}_attendance.xlsx`}
                  disabled={isEventOngoing}
                />
              </Space>
            </Tabs.TabPane>

            {eventData.type !== EventType.PRESCREENING && (
              <Tabs.TabPane
                key="2"
                tab={t("events.attendance")}
                disabled={!eventEmployees.data || isEventPending}
              >
                <ViewEventAttendanceTable
                  eventDetails={eventData}
                  employees={eventEmployees.data || []}
                  attendances={eventAttendance.data || []}
                  isLoading={
                    eventEmployees.isLoading || eventAttendance.isLoading
                  }
                />
              </Tabs.TabPane>
            )}

            <Tabs.TabPane
              key="3"
              tab={t("events.grades")}
              disabled={isEventOngoing}
            >
              <EventGradesTable
                eventDetails={eventData}
                eventEmployees={eventEmployees.data || []}
              />
            </Tabs.TabPane>
          </Tabs>
        )}
      </Card>

      <br />

      {EventStatus.PENDING === eventDetails.data?.status && (
        <RegisterEmployeesToEventTemplate
          getEventDataApi={eventApi.getEventData}
          getContractorSubcontractorsApi={subcontractorApi.getSubcontractorsAll}
          getContractorContractsApi={contractApi.getAllContracts}
          getCountries={countryCityApi.getAllCountries}
          searchEmployeesByIinsApi={employeeApi.searchEmployeesByIINsForAdmin}
          updateEmployeeApi={employeeApi.updateEmployeeForAdmin}
          applyToEventApi={eventApi.adminApplyEvent}
          onSuccess={handleSuccessApply}
        />
      )}
    </>
  );
};
