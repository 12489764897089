export const enEvents = {
  events: "Events",
  allEvents: "All events",
  upcomingEvents: "Upcoming events",
  inprogressEvents: "In-progress events",
  completedEvents: "Completed events",
  evaluatedEvents: "Evaluated events",
  cancelledEvents: "Cancelled events",

  all: "All",
  upcoming: "Upcoming",
  inprogress: "In-progress",
  completed: "Completed",
  evaluated: "Evaluated",
  cancelled: "Cancelled",
  activated: "Activated",

  createEvent: "Create event",

  eventSearch: "Event Search",
  eventSearchSubtitle:
    "Here you will find upcoming events organized by training centers",

  subcontractorsSaved: "Subcontractor has been set successfully",

  dataCheck: "Data check",
  doubleClickToEdit: "Double-click a row to edit",

  removeEmployeePrompt:
    "Are you sure you want to remove employee {{name}} from the list",

  applicationSent: "Your application was successfully sent",

  registerEmployees: "Register employees",
  applyToEvent: "Apply to {{eventName}}",
  applyToTheEvent: "Apply to the event",
  cancelApplication: "Cancel application",
  setSubcontractor: "Set subcontractor",
  searchByIIN: "Search by IIN",
  clearSelected: "Clear selected",
  goToStepN: "Go to step {{n}}",
  totalIINsEntered: "Total IINs entered",
  selected: "Selected",
  notFound: "Not found",
  duplicates: "Duplicates",
  notEligible: "Not eligible",

  seatsLeft: "/ seats left",

  totalHours: "Total hours",

  employees: "employees",

  confirmEventEdit: "Are you sure you want to edit the event?",
  editEventDatesValidationFail:
    "Craft-employees {{iins}} are registered to another event ID: {{eventId}} from {{dateTimeStart}} to {{dateTimeEnd}}",

  filters: {
    dateFrom: "Date from",
    dateTo: "Date to",
    selectDate: "Select date",
    numberOfSeats: "Number of seats",
    inputNumberOfSeats: "Input a number",
    type: "Type",
    eventStatuses: "Event statuses",
    selectEventType: "Select event type",
    competencyLevel: "Competency Level",
    projects: "Projects",
    selectCompetencyLevel: "Select competency level",
    disciplines: "Disciplines",
    selectDisciplines: "Select disciplines",
    trainingCenters: "Training Centers",
    selectTrainingCenters: "Select training centers",
    contractors: "Contractors",
    language: "Language",
    selectLanguage: "Select language",
    cities: "Training Center City",
    selectCities: "Select cities",
    contractorsSubcontractors: "Contractors/Subcontractors",
    eventId: "Event ID",
    clearFilters: "Clear filters",
    eventLocation: "Event location"
  },

  sorting: {
    sortBy: "Sort by",

    1: "Start date ↓",
    2: "Start date ↑",
    3: "Utilization ↓",
    4: "Utilization ↑"
  },

  commissioning: "Commissioning",
  name: "Name",
  host: "Host",
  numberOfSeats: "Number of seats",
  minimumSeats: "Minimum seats",
  academicHours: "Academic hours",
  trainingCenter: "Training center",
  discipline: "Discipline",
  competencyLevel: "Competency Level",
  competencyGrading: "Competency Grading",
  language: "Language",
  location: "Location",
  city: "City",
  availableFor: "Available for",
  csrPhase: "CSR phase",
  standard: "Standard",
  description: "Description",
  attachment: "Attachment",
  date: "Event date",
  stardDate: "Start date",
  endDate: "End date",
  setDaysOff: "Set days off",
  setAsDayOff: "Set as day off",
  hoursPerDay: "Hours per day",
  uploadFile: "Upload file",
  phase: "Phase {{no}}",
  registered: "Registered",
  from: "From",
  to: "To",
  notificationDate: "Notification date",
  notificationEntities: "Notify",
  notificationEntitiesOptions: {
    TCO: "TCO",
    trainingCenter: "Training center"
  },
  cancellationReason: "Cancellation reason:",
  deletionReason: "Deletion reason:",
  cancelConfirm:
    "Are you sure you want to cancel event: {{name}} that starts {{dateTimeStart}}?",
  cancelComplete: "Event {{name}}, {{date}} has been successfully canceled",

  deleteConfirm:
    "Are you sure you want to delete event: {{name}} that starts {{dateTimeStart}}?",
  deleteComplete: "Event {{name}}, {{date}} has been successfully deleted",

  activateComplete: "Event {{name}}, {{date}} has been successfully activated",
  activateConfirm:
    "Are you sure you want to activate event: {{name}} that starts {{dateTimeStart}}?",

  instructor: "Instructor",
  trainingHistory: "Training history",
  eventHistory: "Event history",

  registeredCount: "Registered {{number}} out of {{total}}",
  participantList: "Participant list",
  allParticipants: "All participants",
  participant: "Participant",
  attendance: "Attendance",
  grades: "Grades",
  passed: "Passed",
  overallGrade: "Overall grade",
  overallResult: "Overall result",
  practicalGrade: "Practical grade",
  theoreticalGrade: "Theoretical grade",
  practicalStatus: "Practical status",
  theoreticalStatus: "Theoretical status",
  recommendation: "Recommendation",
  reasonToExclude: "Reason to exclude",
  dates: "Dates",
  eventId: "Event ID",
  issuedAt: "Issued at",

  completionStatus: "Completion status",
  completionStatuses: {
    1: "Incompleted",
    2: "Completed"
  },
  gradePassed: "Passed",
  gradeFailed: "Failed",

  eventCreated: "Event successfully created",
  eventEdited: "Event successfully updated",
  gradeSuccess: "Event successfully evaluated",

  employeesAmountDoNotFitEvent: "No more seats available for the event",

  download: "Download",
  downloadAttendance: "Download attendance",
  downloadCertificates: "Download certificates",
  cancelEvent: "Cancel event",
  deleteEvent: "Delete event",
  activateEvent: "Activate",
  addFeedback: "Add feedback",
  assignInstructor: "Assign instructor",
  pleaseAssignInstructor: "Please assign instructor for the event",
  instructorSetSuccess: "Instructor successfully assigned",
  submitGrade: "Grade",
  prematurelyEvaluate: "Prematurely graduate",
  exclude: "Exclude",
  prematurelyEvaluateSuccess: "The craft-employee is prematurely graduated",
  excludeSuccess: "The craft-employee is excluded",

  attendanceWarning:
    "Attendance hours of the craft employee will become unable to edit",
  editAdminAlert:
    "Please be noted that once you edit the event following information will be deleted permanently: start date/end date of the event, attendance, grades, instructor, minimum seats number, minimum seats notification recipient(s) and date",

  deregister: "De-register",
  deregisterEmployee: "De-register employee",
  deregisterConfirmation: "Are you sure you want to de-register {{name}}?",
  deregisterSuccessfull: "Employee successfully de-registered",
  deregisterError: "De-registration error, try reloading the page",
  deregisterReason: "De-registration reason",

  rejectEmployee: "Reject employee",
  rejectConfirmation: "Are you sure you want to reject {{name}}?",
  rejectSuccessfull: "Employee successfully rejected",
  rejectError: "Rejection error, try reloading the page",
  rejectionReason: "Rejection reason",

  deleteEmployee: "Delete employee",
  deleteConfirmation: "Are you sure you want to delete {{name}}?",
  deleteSuccessfull: "Employee successfully deleted",
  deleteError: "Deletion error, try reloading the page",

  feedback: "Feedback",
  feedbackWarning:
    "This feedback will be sent to TCO Craft Training coordinators, the feedback will not be available for Contractor",
  feedbackSuccess: "Feedback was added successfully",
  feedbackError: "Error saving feedback, please try again",

  feedbackAuthor: "Author",
  feedbackDate: "Date",
  contractor: "Contractor",

  certificates: "Certificates",

  eventTypes: {
    eventTypes: "Event types",
    training: "Training",
    assessment: "Assessment",
    prescreening: "Pre-screening",
    ojtTraining: "OJT training",
    commissioningAssessment: "Commissioning-Assessment",
    commissioningTraining: "Commissioning-Training"
  },

  employeeStatus: {
    1: "Registered",
    2: "Deregistered",
    3: "Rejected",
    4: "Excluded"
  },

  employeeSearchStatuses: {
    1: "Found",
    2: "Not found",
    3: "Duplicate",
    4: "Not eligible"
  },

  statuses: {
    1: "Upcoming",
    2: "In progress",
    3: "Completed",
    4: "Evaluated",
    5: "Cancelled",
    6: "Activated",
    excluded: "Excluded",
    failed: "Failed",
    passed: "Passed",
    total: "Total"
  },

  grade: {
    null: "–",
    0: "-",
    1: "Failed",
    2: "Passed",
    3: "Basic",
    4: "Intermediate",
    5: "Advanced",
    6: "Competent",
    7: "Proficient",
    8: "Helper",
    20: "Trainee technician",
    22: "Junior technician",
    24: "Technician",
    26: "Senior technician",

    ojt: {
      1: "Incompleted",
      2: "Completed"
    },

    completed: "Completed",
    incompleted: "Incompleted"
  },

  gradeMap: {
    failed: "Failed",
    passed: "Passed",
    basic: "Basic",
    intermediate: "Intermediate",
    advanced: "Advanced",
    competent: "Competent",
    proficient: "Proficient",
    helper: "Helper"
  },

  gradeStatus: {
    theoreticalStatus: "Theoretical status",
    practicalStatus: "Practical status",
    null: "–",

    0: "Failed",
    1: "Passed"
  },

  recommendationStatus: {
    null: "-",
    1: "Not ready for upskilling",
    2: "Upskilling to Junior Technician",
    3: "Upskilling to Technician",
    4: "Upskilling to Senior Technician",
    5: "Completed, no need for upskilling",
    6: "Pending"
  },

  failReason: {
    failReason: "Fail reason",
    null: "–",

    0: "–",
    1: "No show",
    2: "Attendance",
    3: "Under the influence",
    4: "Performance",
    5: "Other",
    6: "Requires training",
    7: "No practice"
  },

  exclusionReason: {
    1: "No show",
    2: "Under the influence",
    3: "Has refused",
    4: "Mid-term assessment",
    5: "Other"
  }
};
