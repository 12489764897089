export const PERMISSIONS = {
  REPORT_VIEW: 1,
  REPORT_DOWNLOAD: 2,
  REPORT_DOWNLOAD_DUMP: 3,

  MEMBER_VIEW: 4,
  MEMBER_INVITE: 5,
  MEMBER_UPDATE: 6,
  MEMBER_DELETE: 7,
  MEMBER_PERMISSION: 8,
  MEMBER_NOTIFICATION: 9,

  CONTRACTOR_VIEW: 10,
  CONTRACTOR_CREATE: 11,
  CONTRACTOR_UPDATE: 12,
  CONTRACTOR_DELETE: 13,

  CONTRACT_CREATE: 14,
  CONTRACT_VIEW: 15,
  CONTRACT_UPDATE: 16,
  CONTRACT_DELETE: 17,

  SPECIALITY_CREATE: 18,
  SPECIALITY_VIEW: 19,
  SPECIALITY_UPDATE: 20,
  SPECIALITY_DELETE: 21,

  TRAINING_CENTER_VIEW: 22,
  TRAINING_CENTER_CREATE: 23,
  TRAINING_CENTER_UPDATE: 24,
  TRAINING_CENTER_DELETE: 25,

  EVENT_VIEW: 26,
  EVENT_UPDATE: 27,
  EVENT_CANCEL: 28,
  EVENT_APPLY: 29,
  EVENT_CREATE: 30,

  EVENT_DETAILS_DOWNLOAD: 31,

  CONTRACTOR_EMPLOYEE_REJECT: 32,
  CONTRACTOR_EMPLOYEE_VIEW: 33,
  CONTRACTOR_EMPLOYEE_UPDATE: 34,
  CONTRACTOR_EMPLOYEE_CREATE: 35,
  CONTRACTOR_EMPLOYEE_DELETE: 36,

  CERTIFICATE_CREATE: 37,
  CERTIFICATE_UPDATE: 38,
  CERTIFICATE_DELETE: 39,
  CERTIFICATE_VIEW: 40,
  CERTIFICATE_DOWNLOAD: 41,

  PERMISSION_VIEW: 42,
  NOTIFICATION_VIEW: 43,

  NON_IIN_CRAFT_EMPLOYEE_UPDATE: 44,

  TRAINING_CENTER_REPORT_VIEW: 45,

  TRAINING_CENTER_INSTRUCTOR_VIEW: 46,
  TRAINING_CENTER_INSTRUCTOR_CREATE: 47,
  TRAINING_CENTER_INSTRUCTOR_UPDATE: 48,
  TRAINING_CENTER_INSTRUCTOR_DELETE: 49,

  TICKET_CREATE: 50,
  TICKET_VIEW: 51,
  TICKET_REPLY: 52,

  CITY_CREATE: 53,
  COUNTRY_CREATE: 54,

  CURRENCY_EDIT: 55,

  SUBCONTRACTOR_MEMBER_VIEW: 56,
  SUBCONTRACTOR_MEMBER_UPDATE: 57,
  SUBCONTRACTOR_MEMBER_CREATE: 58,
  SUBCONTRACTOR_MEMBER_DELETE: 59,

  TRAINING_CENTER_REPORT_CREATE: 60,

  EVENT_DATA_UPLOAD_VIEW: 64,
  EVENT_DATA_UPLOAD_EDIT: 65,
  EVENT_ACTIVATE: 66,

  EVENT_DELETE: 61
};
