import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Modal, Row, Space, Typography } from "antd";

interface FormData {
  rejectionReason?: string;
}

interface Props {
  visible: boolean;
  employeeName?: string;
  onSubmit: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: FormData;
  title?: string;
  text?: string;
}

const DeregisterEmployeeModal: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  const formik = useFormik<FormData>({
    initialValues: {},
    onSubmit: () => props.onSubmit()
  });

  return (
    <Modal
      visible={props.visible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      title={t("events.deregisterEmployee")}
      footer={null}
      width={400}
      onCancel={() => {
        formik.resetForm();
        props.onCancel();
      }}
    >
      <Space direction="vertical">
        <Typography.Text>
          {t("events.deregisterConfirmation", { name: props.employeeName })}
        </Typography.Text>

        <Row justify="space-between">
          <Col>
            <Button onClick={props.onCancel}>{t("cancel")}</Button>
          </Col>

          <Col>
            <Button
              type="primary"
              danger
              htmlType="submit"
              loading={props.isLoading}
              onClick={formik.submitForm}
            >
              {t("yes")}
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};

export default DeregisterEmployeeModal;
