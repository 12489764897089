import { useTranslation } from "react-i18next/";

import { Cascader } from "antd";

import { Country } from "@models/countryCity";

interface Props {
  value?: number[];
  countries: Country[];
  onChange: Function;
}

const CountryCitySelectCascader: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();

  const citySelectOptions = props.countries.map((country) => ({
    value: country.id,
    label: i18n.language === "en" ? country.nameEn : country.nameRu,
    disabled: country.cities.length === 0,
    children: country.cities.map((city) => ({
      value: city.id,
      label: i18n.language === "en" ? city.nameEn : city.nameRu
    }))
  }));

  return (
    <Cascader
      value={props.value}
      placeholder=""
      options={citySelectOptions}
      onChange={(
        values // values[0] => countryId, values[1] => cityId
      ) => {
        props.onChange(values);
      }}
      showSearch={{
        filter: (inputValue, path) => {
          return path.some((option) =>
            option.label
              ?.toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          );
        }
      }}
    />
  );
};

export default CountryCitySelectCascader;
