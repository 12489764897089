import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Alert, Button, Col, Form, Input, Modal, Row, Space } from "antd";

interface FormData {
  feedback?: string;
}

interface Props {
  visible: boolean;
  onSubmit: (feedback?: string) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: FormData;
}

const CreateEventFeedbackModal: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  const formik = useFormik<FormData>({
    initialValues: {
      feedback: undefined
    },
    onSubmit: (values) => props.onSubmit(values.feedback)
  });

  return (
    <Modal
      visible={props.visible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      title={t("events.addFeedback")}
      footer={null}
      width={400}
      onCancel={() => {
        formik.resetForm();
        props.onCancel();
      }}
    >
      <Space direction="vertical">
        <Alert message={t("events.feedbackWarning")} type="warning" showIcon />

        <Row gutter={8}>
          <Col md={24}>
            <Form.Item
              label={t("events.feedback")}
              validateStatus={formik.errors.feedback && "error"}
              help={formik.errors.feedback}
            >
              <Input.TextArea
                name="feedback"
                value={formik.values.feedback}
                onChange={formik.handleChange}
                rows={10}
                maxLength={500}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Button onClick={props.onCancel} danger>
              {t("cancel")}
            </Button>
          </Col>

          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={props.isLoading}
              onClick={formik.submitForm}
            >
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};

export default CreateEventFeedbackModal;
