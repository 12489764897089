import axios from "axios";

import { Instructor } from "@models/instructor";

const getAllTcInstructors = (
  trainingCenterId: number,
  filters: { specialityIds?: number[] } = {}
) =>
  axios.get<Instructor[]>(
    `/api/training-center/${trainingCenterId}/instructor/all`,
    { params: filters }
  );

const getAllInstructors = () =>
  axios.get<Instructor[]>("/api/training-center/instructor/all");

export const instructorApi = {
  getAllTcInstructors,
  getAllInstructors
};
