import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import RequestsTable from "@components/common/requests/RequestsTable";
import RequestsFilter from "@components/common/requests/RequestsFilter";

import { MemberStatus } from "@models/member";
import { FilterRequestsData } from "@models/requests";

import requestsApi from "@api/requestsApi";
import { trainingCenterApi } from "@api/trainingCenterApi";

import { UserContext } from "@contexts/UserContext";
import { useQueryParams } from "@hooks/useQueryParams";
import { usePagination } from "@hooks/usePagination";

export const RequestsListPage: React.FC = () => {
  const queryParams = useQueryParams();
  const { page, setPage, setPagination } = usePagination();
  const { ...initFilterData } = queryParams;
  const [filterData, setFilterData] =
    useState<FilterRequestsData>(initFilterData);

  const { user } = useContext(UserContext);
  const trainingCenterId = user?.userRoles[0].entityId || 0;

  const [t] = useTranslation();

  const { data, isLoading } = useQuery(
    ["getContracotrTickets", page, filterData],
    () =>
      requestsApi
        .getTrainingCenterRequests(trainingCenterId, page, filterData)
        .then((res) => res.data),
    {
      onSuccess(res) {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterParams: filterData
        };
        setPagination(pagination);
      }
    }
  );

  const { data: membersData } = useQuery(
    ["getTrainingCenterMembers", page, filterData],
    () =>
      trainingCenterApi
        .getTrainingCenterMembers(trainingCenterId)
        .then((res) => res.data)
  );

  const { data: contractors } = useQuery("getTrainingCentersMap", () =>
    trainingCenterApi
      .getContractorsWithTickets({ trainingCenterId })
      .then((res) => res.data)
  );

  const activeMembers = membersData?.filter(
    (member) => member.status === MemberStatus.ACTIVE
  );

  const paginationSettings = {
    position: ["topRight", "bottomRight"],
    pageSize: 20,
    showSizeChanger: false,
    hideOnSinglePage: true,
    total: data?.totalItems,
    current: data?.currentPage,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={t("requests.tcList")} />

      <AppPageHeader
        title={t("requests.tcList")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "tc" },
          { breadcrumbName: t("requests.requests"), path: "requests" }
        ]}
      />

      <RequestsFilter
        onFilter={setFilterData}
        contractors={contractors || {}}
        assignees={activeMembers || []}
        initialValues={initFilterData}
      />

      <RequestsTable
        data={data}
        isLoading={isLoading}
        generateUrl={(requestId) => `/tc/requests/${requestId}`}
        pagination={paginationSettings}
        showAssignee
        showContractor
      />
    </>
  );
};
