import { useTranslation } from "react-i18next";

import { Tag, Typography } from "antd";

interface Props {
  isEmployed: boolean;
}

const EmploymentStatusTag: React.FC<Props> = ({ isEmployed }) => {
  const [t] = useTranslation();

  return (
    <Tag style={{ padding: "4px" }} color={isEmployed ? "green" : "orange"}>
      <Typography.Text
        style={{
          fontSize: "15px",
          paddingLeft: "4px",
          paddingRight: "4px"
        }}
      >
        {isEmployed
          ? t("craftProfiles.employed")
          : t("craftProfiles.unemployed")}
      </Typography.Text>
    </Tag>
  );
};

export default EmploymentStatusTag;
