import picture36 from "@assets/images/user-guide/3.36.png";
import picture37 from "@assets/images/user-guide/3.37.png";
import picture38 from "@assets/images/user-guide/3.38.png";

export const requests = [
  {
    title: "Your requests to Training centers:",
    html: `<p dir="ltr">
    In order to see your requests to Training centers press on Requests tab on Panel menu you will be redirected to Your requests to Training centers page with the following information:
</p>
<p dir="ltr">
    Picture 3.36
</p>
<p dir="ltr">
    <img
        src=${picture36}
        width="624"
        height="283"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Date from
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Date to
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
        Filter by Training centers       
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Request ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
          Create request button
        </p>
    </li>
    <li dir="ltr">
    <p dir="ltr">
      Number of messages in one request
    </p>
</li>
</ol>
`
  },
  {
    title: "How to create a Request?",
    html: `<p dir="ltr">
    Picture 3.37
</p>
<p dir="ltr">
    <img
        src=${picture37}
        width="624"
        height="421"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Press on <b>Requests</b> tab on the panel menu
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press <b>Create request</b> button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out fields, upload a file and leave a message and press <b>Create</b>
            button
        </p>
    </li>
</ol>
<p dir="ltr">
    <b>Note:</b> Later only you can reply to the created Request.
</p>
`
  },
  {
    title: "How to reply to Request?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            Press on <b>Requests</b> tab on the panel menu
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Request
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Leave your message on Leave a reply field and press <b>Submit</b> button
        </p>
    </li>
</ol>
<p dir="ltr">
    <b>Note:</b> Once you reply to the Contractor’s request which hasn’t any reply
    from the Training center member yet you’ll become a Request Assignee. Later
    only Request Assignee can reply to the Request.
</p>
<p dir="ltr">
    Picture 3.38
</p>
<p dir="ltr">
    <img
        src=${picture38}
        width="624"
        height="369"
    />
</p>
`
  }
];
