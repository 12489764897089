import { useTranslation } from "react-i18next";

import { HomeOutlined } from "@ant-design/icons";
import { EmployeeInfoCard } from "@components/common/employees/EmployeeInfoCard";

import { EmployeeDetails } from "@models/employee";

type PersonalInfo = Partial<EmployeeDetails>;

interface Props {
  editable?: boolean;
  editDisabled?: boolean;

  onEdit?: () => void;

  personalInfo?: PersonalInfo;
}

export const EmployeeFacilityAccessCard = (props: Props) => {
  const [t] = useTranslation();

  const pi = props.personalInfo;

  return (
    <EmployeeInfoCard
      title={
        <>
          <HomeOutlined /> {t("employees.facilityAccesses")}
        </>
      }
      fields={[
        {
          label: t("employees.facilityAccessStandard"),
          value: pi?.facilityAccessStandard
        },
        {
          label: t("employees.facilityAccessAdditional"),
          value: pi?.facilityAccessAdditional
        },
        {
          label: t("employees.specialAccessJustification"),
          value: pi?.specialAccessJustification
        },
        {
          label: t("employees.waterTreatmentPlant"),
          value: pi?.waterTreatmentPlant
        },
        {
          label: t("employees.residentialAreas"),
          value: pi?.residentialAreas
        }
      ]}
      editable={props.editable}
      editDisabled={props.editDisabled}
      onEdit={props.onEdit}
    />
  );
};
