import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import FormItem from "antd/lib/form/FormItem";
import { useFormik } from "formik";
import moment from "moment";

import { Button, Col, DatePicker, Form, InputNumber, Row, Select } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { City } from "@models/countryCity";
import { Discipline } from "@models/discipline";
import { EventSearchFilter } from "@models/event";
import { TrainingCentersMap } from "@models/trainingCenter";
import { CompetencyLevel } from "@models/competencyLevel";

import { EVENT_TYPES } from "@constants/eventTypes";
import { LANGUAGES_MAP } from "@constants/languagesMap";
import { EVENT_TYPES_SELECT_OPTIONS } from "@constants/eventTypesSelectOptions";
import {
  COMPETENCY_LEVEL_SELECT_OPTIONS,
  CUSTOM_COMPETENCY_LEVEL_SELECT_OPTIONS
} from "@constants/competencyLevelSelectOptions";
import { SORTING_OPTIONS } from "@constants/eventSortOptions";

const setEventTypeSelectInitialValue = (
  type: number,
  isCommissioning?: boolean
) => {
  switch (type) {
    case EVENT_TYPES.ASSESSMENT:
      return isCommissioning
        ? EVENT_TYPES.COMMISSIONING_ASSESSMENT
        : EVENT_TYPES.ASSESSMENT;
    case EVENT_TYPES.TRAINING:
      return isCommissioning
        ? EVENT_TYPES.COMMISSIONING_TRAINING
        : EVENT_TYPES.TRAINING;
    default:
      return type;
  }
};

interface FormValues extends EventSearchFilter {
  eventTypeSelectValue?: number;
}
interface Props {
  initialValues?: EventSearchFilter;
  errors?: { [key: string]: string };
  disciplines?: Discipline[];
  trainingCenters?: TrainingCentersMap;
  cities?: City[];
  isLoading?: boolean;
  onSubmit?: (values: EventSearchFilter) => void;
}

export const EventsFilter: React.FC<Props> = ({ initialValues, ...props }) => {
  const { t, i18n } = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      ...initialValues,
      specialityIds: initialValues?.specialityIds?.map((s) => Number(s)),
      trainingCenterIds: initialValues?.trainingCenterIds?.map((t) =>
        Number(t)
      ),
      languageCode: Number(initialValues?.languageCode) || undefined,
      cityIds: initialValues?.cityIds?.map((c) => Number(c)),
      competencyLevel: Number(initialValues?.competencyLevel) || undefined,
      customCompetencyLevel:
        Number(initialValues?.customCompetencyLevel) || undefined,
      type: Number(initialValues?.type) || undefined,
      eventTypeSelectValue:
        setEventTypeSelectInitialValue(
          Number(initialValues?.type),
          // eslint-disable-next-line eqeqeq
          initialValues?.isCommissioning?.toString() == "true"
        ) || undefined,
      // eslint-disable-next-line eqeqeq
      isCommissioning: initialValues?.isCommissioning?.toString() == "true",
      seats: Number(initialValues?.seats) || undefined,
      sort: Number(initialValues?.sort) || undefined
    },
    onSubmit: (values) => {
      const { eventTypeSelectValue, ...payload } = values;

      if (props.onSubmit) {
        props.onSubmit(payload);
      }
    }
  });

  const disciplinesSelectOptions = props.disciplines?.map((d) => ({
    value: Number(d.id),
    label: i18n.language === "en" ? d.nameEn : d.nameRu
  }));

  const trainingCentersSelectOptions = Object.entries(
    props.trainingCenters || {}
  ).map(([id, tcName]) => ({
    value: Number(id),
    label: tcName
  }));

  const citiesSelectOptions = props.cities?.map((c) => ({
    value: c.id,
    label: i18n.language === "en" ? c.nameEn : c.nameRu
  }));

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form onSubmitCapture={() => formik.submitForm()}>
      <Row justify="end" gutter={8} style={{ marginBottom: "16px" }}>
        <Col md={4}>
          <Form.Item
            label={t("events.filters.dateFrom")}
            labelCol={{ span: 24 }}
          >
            <DatePicker
              value={
                formik.values.dateStart
                  ? moment(formik.values.dateStart, "YYYY-MM-DD")
                  : undefined
              }
              onChange={(_, value) => formik.setFieldValue("dateStart", value)}
              disabledDate={(date) =>
                date && date >= moment(formik.values.dateEnd, "YYYY-MM-DD")
              }
              placeholder={t("events.filters.selectDate")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item label={t("events.filters.dateTo")} labelCol={{ span: 24 }}>
            <DatePicker
              value={
                formik.values.dateEnd
                  ? moment(formik.values.dateEnd, "YYYY-MM-DD")
                  : undefined
              }
              onChange={(_, value) => formik.setFieldValue("dateEnd", value)}
              disabledDate={(date) =>
                date && date <= moment(formik.values.dateStart, "YYYY-MM-DD")
              }
              placeholder={t("events.filters.selectDate")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <FormItem
            label={t("events.filters.numberOfSeats")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.seats && "error"}
            help={formik.errors.seats}
          >
            <InputNumber
              name="seats"
              value={formik.values.seats}
              onChange={(values) => {
                const v = Number(values);
                if (!Number.isNaN(v)) {
                  formik.setFieldValue("seats", v);
                }
              }}
              min={0}
              placeholder={t("events.filters.inputNumberOfSeats")}
              style={{ width: "100%" }}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem label={t("events.filters.type")} labelCol={{ span: 24 }}>
            <Select
              value={formik.values.eventTypeSelectValue}
              options={EVENT_TYPES_SELECT_OPTIONS.map((o) => ({
                label: t(o.label),
                value: o.value
              }))}
              onSelect={(
                value: typeof EVENT_TYPES[keyof typeof EVENT_TYPES]
              ) => {
                switch (value) {
                  case EVENT_TYPES.COMMISSIONING_ASSESSMENT:
                    formik.setFieldValue("isCommissioning", true);
                    formik.setFieldValue("type", EVENT_TYPES.ASSESSMENT);
                    break;
                  case EVENT_TYPES.COMMISSIONING_TRAINING:
                    formik.setFieldValue("isCommissioning", true);
                    formik.setFieldValue("type", EVENT_TYPES.TRAINING);
                    break;
                  default:
                    formik.setFieldValue("isCommissioning", false);
                    formik.setFieldValue("type", value);
                }
                formik.setFieldValue("eventTypeSelectValue", value);
              }}
              onClear={() => {
                formik.setFieldValue("type", undefined);
                formik.setFieldValue("isCommissioning", undefined);
                formik.setFieldValue("eventTypeSelectValue", undefined);
              }}
              placeholder={t("events.filters.selectEventType")}
              notFoundContent={t("noData")}
              allowClear
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            label={t("events.filters.competencyLevel")}
            labelCol={{ span: 24 }}
          >
            {formik.values.eventTypeSelectValue === EVENT_TYPES.ASSESSMENT ||
            formik.values.eventTypeSelectValue ===
              EVENT_TYPES.COMMISSIONING_ASSESSMENT ? (
              <Select
                value={formik.values.customCompetencyLevel}
                options={CUSTOM_COMPETENCY_LEVEL_SELECT_OPTIONS.map(
                  (o: any) => ({
                    label: t(o.label),
                    value: o.value
                  })
                )}
                onSelect={(value: number) =>
                  formik.setFieldValue("customCompetencyLevel", value)
                }
                onClear={() =>
                  formik.setFieldValue("customCompetencyLevel", undefined)
                }
                placeholder={t("events.filters.selectCompetencyLevel")}
                notFoundContent={t("noData")}
                allowClear
              />
            ) : (
              <Select
                disabled={!formik.values.eventTypeSelectValue}
                value={formik.values.competencyLevel}
                options={COMPETENCY_LEVEL_SELECT_OPTIONS.filter(
                  (o) =>
                    o.value !== CompetencyLevel.TRAINEE_TECHNICIAN &&
                    o.value !== CompetencyLevel.TECHNICIAN &&
                    o.value !== CompetencyLevel.JUNIOR_TECHNICIAN &&
                    o.value !== CompetencyLevel.SENIOR_TECHNICIAN
                ).map((o) => ({
                  label: t(o.label),
                  value: o.value
                }))}
                onSelect={(value: number) =>
                  formik.setFieldValue("competencyLevel", value)
                }
                onClear={() =>
                  formik.setFieldValue("competencyLevel", undefined)
                }
                placeholder={t("events.filters.selectCompetencyLevel")}
                notFoundContent={t("noData")}
                allowClear
              />
            )}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label={t("events.filters.disciplines")}
            labelCol={{ span: 24 }}
          >
            <SearchSelect
              value={formik.values.specialityIds}
              options={disciplinesSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("specialityIds", value)
              }
              maxTagCount={1}
              mode="multiple"
              placeholder={t("events.filters.selectDisciplines")}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label={t("events.filters.trainingCenters")}
            labelCol={{ span: 24 }}
          >
            <SearchSelect
              value={formik.values.trainingCenterIds}
              options={trainingCentersSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("trainingCenterIds", value)
              }
              maxTagCount={1}
              mode="multiple"
              placeholder={t("events.filters.selectTrainingCenters")}
            />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem
            label={t("events.filters.language")}
            labelCol={{ span: 24 }}
          >
            <Select
              value={formik.values.languageCode}
              options={LANGUAGES_MAP.map(({ nameEn, nameRu, value }) => ({
                value,
                label: i18n.language === "en" ? nameEn : nameRu
              }))}
              onChange={(value: number) =>
                formik.setFieldValue("languageCode", value)
              }
              allowClear
              placeholder={t("events.filters.selectLanguage")}
              notFoundContent={t("noData")}
            />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem label={t("events.filters.cities")} labelCol={{ span: 24 }}>
            <SearchSelect
              value={formik.values.cityIds}
              options={citiesSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("cityIds", value)
              }
              mode="multiple"
              maxTagCount={1}
              placeholder={t("events.filters.selectCities")}
            />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem label={t("events.sorting.sortBy")} labelCol={{ span: 24 }}>
            <Select
              value={formik.values.sort}
              options={SORTING_OPTIONS.map((s) => ({
                value: s.value,
                label: t(s.label)
              }))}
              onChange={(value: number) => formik.setFieldValue("sort", value)}
              allowClear
            />
          </FormItem>
        </Col>
        <Col xs={24} style={{ textAlign: "right" }}>
          <Button htmlType="submit" type="primary" loading={props.isLoading}>
            {t("search")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
