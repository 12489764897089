import { FC, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { AxiosError } from "axios";

import { Button, notification, Modal } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { Section, SubSection } from "@components/Sections";
import { MemberTable } from "@components/common/MemberTable";
import { EditContractorMemberForm } from "@components/admin/contractors/EditContractorMemberForm";
import { NewContractorMemberForm } from "@components/admin/contractors/NewContractorMemberForm";

import { Member, MemberStatus } from "@models/member";

import { memberApi } from "@api/memberApi";
import { adminContractorApi } from "@api/adminContractorApi";

import { useDefaultQuery } from "@hooks/useDefaultQuery";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

import InvitedMemberActions from "./InvitedMemberActions";
import MemberActions from "./MemberActions";
import InactiveMemberActions from "./InactiveMemberActions";

interface MembersSectionProps {
  contractorId: number;
}

const MembersSection: FC<MembersSectionProps> = (props) => {
  const [t] = useTranslation();
  const [editedMember, setEditedMember] = useState<Member>();
  const [isInviteMember, setIsInviteMember] = useState(false);
  const { contractorId } = props;

  const contractorMembers = useDefaultQuery(
    ["getContractorMembers", contractorId],
    () => memberApi.getContractorMembers(contractorId).then((res) => res.data)
  );

  const editMemberMutation = useValidatedMutation({
    mutationFunction: ({ id, payload }: { id: number; payload: any }) =>
      adminContractorApi.updateContractorMember({
        contractorId,
        memberId: id,
        payload
      }),

    onSuccess() {
      notification.success({
        message: t("members.editSuccess")
      });
      contractorMembers.refetch();
      setEditedMember(undefined);
    }
  });
  const editErrors = useMemo(
    () => transformValidationError(editMemberMutation.error),
    [editMemberMutation.error]
  );
  const inviteMemberMutation = useValidatedMutation({
    mutationFunction: (values: any) =>
      memberApi.inviteContractorMember({ contractorId, member: values }),
    onSuccess(_, values) {
      notification.success({
        message: t("members.inviteSuccess", { email: values.email })
      });
      contractorMembers.refetch();
      setIsInviteMember(false);
    },
    onError() {}
  });
  const inviteErrors = useMemo(
    () => transformValidationError(inviteMemberMutation.error),
    [inviteMemberMutation.error]
  );

  const deleteMemberMutation = useMutation(
    (id: number) =>
      memberApi.deleteContractorMember({ contractorId, memberId: id }),
    {
      onSuccess() {
        notification.success({ message: t("members.memberDeleted") });

        contractorMembers.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const deleteInviteMutation = useMutation(
    (email: string) =>
      memberApi.deleteContractorMemberInvite({ contractorId, email }),
    {
      onSuccess() {
        notification.success({ message: t("members.inviteDeleted") });

        contractorMembers.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const activateMemberMutation = useValidatedMutation({
    mutationFunction: (id: number) =>
      adminContractorApi.activateContractorMember({
        contractorId,
        memberId: id
      }),

    onSuccess() {
      notification.success({
        message: t("members.editSuccess")
      });
      contractorMembers.refetch();
      setEditedMember(undefined);
    }
  });

  const resendInviteMutation = useMutation(
    (email: string) =>
      memberApi.resendContractorMemberInvite({ contractorId, email }),
    {
      onSuccess(_, email) {
        notification.success({
          message: t("members.inviteResent", { email })
        });
        contractorMembers.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const activeMembers = contractorMembers.data?.filter(
    (member) => member.status === MemberStatus.ACTIVE
  );
  const inactiveMembers = contractorMembers.data?.filter(
    (member) => member.status === MemberStatus.INACTIVE
  );
  const invitedMembers = contractorMembers.data?.filter(
    (member) => member.status === MemberStatus.INVITED
  );

  return (
    <Section>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "24px"
        }}
      >
        <Button
          key="2"
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => setIsInviteMember(true)}
        >
          {t("members.inviteMember")}
        </Button>
      </div>
      <SubSection>
        <MemberTable
          title={t("members.activeMembers")}
          members={activeMembers}
          renderActions={(member) => (
            <MemberActions
              deleteMemberMutation={deleteMemberMutation}
              member={member}
              onEdit={() => setEditedMember(member)}
            />
          )}
        />
      </SubSection>
      <SubSection>
        <MemberTable
          title={t("members.inactiveMembers")}
          members={inactiveMembers}
          renderActions={(member) => (
            <InactiveMemberActions
              activateMemberMutation={activateMemberMutation}
              deleteMemberMutation={deleteMemberMutation}
              member={member}
            />
          )}
        />
        <br />
      </SubSection>
      <SubSection>
        <MemberTable
          title={t("members.invitedMembers")}
          members={invitedMembers}
          renderActions={(member) => (
            <InvitedMemberActions
              member={member}
              deleteInviteMutation={deleteInviteMutation}
              resendInviteMutation={resendInviteMutation}
            />
          )}
        />
      </SubSection>

      <Modal
        visible={!!editedMember}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("edit")}
        footer={null}
        width={800}
        onCancel={() => {
          setEditedMember(undefined);
          editMemberMutation.reset();
        }}
      >
        <EditContractorMemberForm
          contractorMember={editedMember}
          errors={editErrors}
          isLoading={editMemberMutation.isLoading}
          onCancel={() => {
            setEditedMember(undefined);
            editMemberMutation.reset();
          }}
          onSubmit={(values) => {
            if (editedMember) {
              editMemberMutation.mutate({
                id: editedMember.userId,
                payload: values
              });
            }
          }}
        />
      </Modal>
      <Modal
        visible={isInviteMember}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("members.memberInfo")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsInviteMember(false);
          inviteMemberMutation.reset();
        }}
      >
        <NewContractorMemberForm
          isLoading={inviteMemberMutation.isLoading}
          errors={inviteErrors}
          onCancel={() => {
            setIsInviteMember(false);
            inviteMemberMutation.reset();
          }}
          onSubmit={(values) => {
            inviteMemberMutation.mutate(values);
          }}
        />
      </Modal>
    </Section>
  );
};

export default MembersSection;
