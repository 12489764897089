import { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { Card, Space, Tabs, Tag, Button, notification, Typography } from "antd";
import DownloadLink from "@components/common/DownloadLink";
import { EventDetailsCard } from "@components/common/events/EventDetailsCard";
import { EventEmployeeCard } from "@components/common/events/EventEmployeeCard";
import { EventEmployeesFilterForm } from "@components/common/events/EventEmployeesFilterForm";
import EventGradesTable from "@components/common/events/EventGradesTable";
import ViewEventAttendanceTable from "@components/common/events/ViewEventAttendanceTable";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import CreateEventFeedbackModal from "@components/common/events/modals/CreateEventFeedbackModal";
import ViewEventFeedbackModal from "@components/common/events/modals/ViewEventFeedbackModal";
import DeregisterEmployeeModal from "@components/common/events/modals/DeregisterEmployeeModal";

import { EventEmployeeStatus, EventStatus, EventType } from "@models/event";

import { eventApi } from "@api/eventApi";
import { eventsApi } from "@api/eventsApi";

import { transformValidationError } from "@utils/errorHelper";
import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { EVENT_STATUS_TO_ROUTE_MAP } from "@constants/eventStatusToRouteMap";
import { EVENT_STATUS_COLOR_MAP } from "@constants/eventStatusColorMap";

interface RejectEmployeeState {
  employeeId?: number;
  employeeName?: string;
}

export const EventDetailsPage = () => {
  const [t, i18n] = useTranslation();

  const { userEntityId: contractorId } = useContext(UserContext);
  const params = useParams<{ id: string }>();
  const eventId = Number(params.id);

  const [createFeedbackModalVisible, setCreateFeedbackModalVisible] =
    useState(false);
  const [viewFeedbackModalVisible, setViewFeedbackModalVisible] =
    useState(false);

  const [employeeFilters, setEmployeeFilters] = useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [deregisterEmployeeState, setDeregisterEmployeeState] =
    useState<RejectEmployeeState>({});

  const eventDetails = useQuery("getEventDetails", () =>
    eventApi
      .getContractorEventDetails({ contractorId, eventId })
      .then((res) => res.data)
  );

  const eventEmployees = useQuery(
    ["getContractorEventEmployees", contractorId, eventId],
    () =>
      eventApi
        .getContractorEventEmployees({
          contractorId,
          eventId
        })
        .then((res) => res.data)
  );

  const filteredEventEmployees = useQuery(
    ["getContractorEventEmployees", contractorId, eventId, employeeFilters],
    () =>
      eventApi
        .getContractorEventEmployees({
          contractorId,
          eventId,
          filters: employeeFilters
        })
        .then((res) => res.data)
  );

  const eventAttendance = useQuery(
    ["getContractorEventAttendance", eventId],
    () =>
      eventApi
        .getContractorEventAttendance({ contractorId, eventId })
        .then((res) => res.data)
  );

  const eventData = eventDetails.data;

  const canReject = eventData?.status === EventStatus.PENDING;
  const deregisterEmployee = useValidatedMutation({
    mutationFunction: () =>
      eventsApi.contractorDeregisterEventEmployee({
        eventId,
        employeeId: deregisterEmployeeState.employeeId || 0,
        contractorId
      }),
    onSuccess: () => {
      notification.success({
        message: t("events.deregisterSuccessfull")
      });
      eventEmployees.refetch();
      filteredEventEmployees.refetch();
      setDeregisterEmployeeState({});
    },
    onError: () => notification.error(t("events.deregisterError"))
  });

  const deregisterEmployeeErrors = useMemo(
    () => transformValidationError(deregisterEmployee.error),
    [deregisterEmployee.error]
  );

  const feedbackMutation = useValidatedMutation({
    mutationFunction: (feedback: string) =>
      eventApi
        .addContractorFeedback({
          contractorId,
          eventId,
          feedback
        })
        .then((res) => res.data),
    onSuccess: () => {
      notification.success({ message: t("events.feedbackSuccess") });
      setCreateFeedbackModalVisible(false);
      eventDetails.refetch();
    }
  });
  const feedbackErrors = useMemo(
    () => transformValidationError(feedbackMutation.error),
    [feedbackMutation.error]
  );

  const contractorFeedback = eventData
    ? eventData?.feedbacks.find(
        (eventFeedback) => eventFeedback.contractorId === contractorId
      )
    : undefined;

  const pageTitle = eventDetails.data
    ? eventDetails.data.name
    : t("events.event");

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={
          eventData && (
            <Space>
              {pageTitle}

              <Typography.Text style={{ fontSize: "12px", color: "#fb8c00" }}>
                ID: {eventData.id}
              </Typography.Text>

              <Tag
                color={EVENT_STATUS_COLOR_MAP[eventData.status]}
                style={{ marginRight: 16 }}
              >
                {t(`events.statuses.${eventData?.status}`)}
              </Tag>
            </Space>
          )
        }
        breadcrumbs={[
          {
            breadcrumbName: "CT",
            path: "/"
          },
          {
            breadcrumbName: eventDetails.data?.status
              ? t("events.events") +
                " - " +
                t(`events.statuses.${eventDetails.data.status}`)
              : t("events.allEvents"),
            path: eventDetails.data?.status
              ? `/tc/events/${
                  EVENT_STATUS_TO_ROUTE_MAP[eventDetails.data.status]
                }`
              : "/tc/events/all"
          },
          {
            breadcrumbName: pageTitle,
            path: ""
          }
        ]}
      />

      <CreateEventFeedbackModal
        visible={createFeedbackModalVisible}
        onSubmit={(feedback) => feedbackMutation.mutate(feedback)}
        onCancel={() => setCreateFeedbackModalVisible(false)}
        isLoading={feedbackMutation.isLoading}
        errors={feedbackErrors}
      />

      <ViewEventFeedbackModal
        visible={viewFeedbackModalVisible}
        onClose={() => setViewFeedbackModalVisible(false)}
        feedbacks={contractorFeedback ? [contractorFeedback] : []}
      />

      {eventData && (
        <EventDetailsCard
          eventData={eventData}
          showAddFeedbackBtn={
            contractorFeedback === undefined &&
            [EventStatus.COMPLETED, EventStatus.EVALUATED].includes(
              eventData.status
            )
          }
          onAddFeedback={() => setCreateFeedbackModalVisible(true)}
          showViewFeedbackBtn={contractorFeedback !== undefined}
          onViewFeedback={() => setViewFeedbackModalVisible(true)}
        />
      )}

      <br />

      {eventData && (
        <Card
          title={t("events.participantList")}
          loading={
            eventDetails.isLoading ||
            eventEmployees.isLoading ||
            filteredEventEmployees.isLoading
          }
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              key="1"
              tab={t("events.allParticipants", {
                number: eventData.capacityBusy
              })}
            >
              <DeregisterEmployeeModal
                employeeName={deregisterEmployeeState.employeeName}
                visible={deregisterEmployeeState.employeeId !== undefined}
                onCancel={() => setDeregisterEmployeeState({})}
                onSubmit={() => deregisterEmployee.mutate({})}
                isLoading={deregisterEmployee.isLoading}
                errors={deregisterEmployeeErrors}
              />

              <EventEmployeesFilterForm
                onSubmit={(v) => {
                  setEmployeeFilters(v);
                }}
              />

              {filteredEventEmployees.data &&
                filteredEventEmployees.data?.map((ee) => (
                  <EventEmployeeCard
                    eventDetails={eventDetails.data}
                    employeeDetails={ee}
                    key={ee.employeeId}
                    renderExtraAction={() => {
                      if (canReject) {
                        // eslint-disable-next-line eqeqeq
                        if (ee.status == EventEmployeeStatus.REGISTERED) {
                          return (
                            <Button
                              danger
                              size="small"
                              onClick={() =>
                                setDeregisterEmployeeState({
                                  employeeId: ee.employeeId,
                                  employeeName:
                                    i18n.language === "en"
                                      ? `${ee.firstName} ${ee.lastName}`
                                      : `${ee.firstNameCyrillic} ${ee.lastNameCyrillic}`
                                })
                              }
                            >
                              {t("events.deregisterEmployee")}
                            </Button>
                          );
                        }
                      }

                      return null;
                    }}
                  />
                ))}

              <br />

              <Space>
                <DownloadLink
                  url={eventData.resultFileLink}
                  label={t("events.download")}
                  size="large"
                  type="primary"
                  fileName={`event_${eventId}_details.xlsx`}
                />

                <DownloadLink
                  url={`/api/contractor/${contractorId}/event/${eventId}/attendance/download`}
                  label={t("events.downloadAttendance")}
                  size="large"
                  type="default"
                  fileName={`event_${eventId}_attendance.xlsx`}
                  disabled={[
                    EventStatus.INPROGRESS,
                    EventStatus.PENDING
                  ].includes(eventData.status)}
                />
              </Space>
            </Tabs.TabPane>

            {eventData.type !== EventType.PRESCREENING && (
              <Tabs.TabPane
                key="2"
                tab={t("events.attendance")}
                disabled={
                  !eventEmployees.data?.length ||
                  eventData.status === EventStatus.PENDING
                }
              >
                {eventDetails.data && (
                  <ViewEventAttendanceTable
                    eventDetails={eventDetails.data}
                    employees={eventEmployees.data || []}
                    attendances={eventAttendance.data || []}
                    isLoading={
                      eventEmployees.isLoading ||
                      eventDetails.isLoading ||
                      eventAttendance.isLoading
                    }
                  />
                )}
              </Tabs.TabPane>
            )}

            <Tabs.TabPane
              key="3"
              tab={t("events.grades")}
              disabled={
                !eventEmployees.data?.length ||
                eventData.status === EventStatus.PENDING
              }
            >
              {eventDetails.data && (
                <EventGradesTable
                  eventDetails={eventDetails.data}
                  eventEmployees={eventEmployees.data || []}
                />
              )}
            </Tabs.TabPane>
          </Tabs>
        </Card>
      )}
    </>
  );
};
