import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";

import Modal from "antd/lib/modal/Modal";
import { AxiosError, AxiosResponse } from "axios";

import { Button, Col, notification, Row, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { ApplyEmployeeToEventSection } from "@components/common/events/ApplyEmployeeToEventSection";
import { ContractorEventInfoSection } from "@components/common/events/ContractorEventInfoSection";
import { SetSubcontractorToEventForm } from "@components/common/events/SetSubcontractorToEventForm";
import { EventEmployeesDataTable } from "@components/common/events/EventEmployeesDataTable";
import { EditEmployeeForm } from "@components/common/events/EditEmployeeForm";

import {
  EditEmployeePayload,
  EmployeeDetails,
  EmployeeSearchResult
} from "@models/employee";
import { EventData, SetSubcontractorToEventPayload } from "@models/event";
import { Subcontractor } from "@models/subcontractor";
import { Contract } from "@models/contract";
import { Country } from "@models/countryCity";
import { PERMISSIONS } from "@models/permissions";

import { transformValidationError } from "@utils/errorHelper";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { useDefaultQuery } from "@hooks/useDefaultQuery";
import { UserContext } from "@contexts/UserContext";

interface RouteParams {
  id: string;
}

interface Props {
  getEventDataApi: (eventId: number) => Promise<AxiosResponse<EventData>>;
  getContractorSubcontractorsApi: (
    contractorId: number
  ) => Promise<AxiosResponse<Subcontractor[]>>;
  getContractorContractsApi: (
    contractorId: number
  ) => Promise<AxiosResponse<Contract[]>>;
  getCountries: () => Promise<AxiosResponse<Country[]>>;
  setSubcontractorToEventApi: (
    payload: SetSubcontractorToEventPayload
  ) => Promise<AxiosResponse<any>>;
  searchEmployeesByIinsApi: (
    contractorId: number,
    eventId: number,
    iins: string[]
  ) => Promise<AxiosResponse<EmployeeSearchResult>>;
  updateEmployeeApi: (payload: {
    contractorId: number;
    employeeId: number;
    employeeData: Partial<EmployeeDetails>;
  }) => Promise<AxiosResponse<any>>;
  applyToEventApi: (
    contractorId: number,
    eventId: number,
    employees: EmployeeDetails[]
  ) => Promise<AxiosResponse<any>>;

  redirectUrl?: (eventId: number) => string;
}

export const RegisterEmployeesToEventTemplate: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const eventId = Number(id);

  const {
    userEntityId: contractorId,
    userContractor,
    checkPermission
  } = useContext(UserContext);
  const contractorName = userContractor?.name || "";

  const [isEmployeesDataCheckStep, setIsEmployeesDataCheckStep] =
    useState<boolean>(false);

  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeDetails[]>(
    []
  );

  const [editableEmployee, setEditableEmployee] =
    useState<EmployeeDetails | null>(null);

  const [isSetSubcontractorFormShown, setIsSetSubcontractorFormShown] =
    useState(false);

  const eventData = useDefaultQuery("getContractorEvent", () =>
    props.getEventDataApi(eventId).then((res) => res.data)
  );

  const subcontractors = useDefaultQuery("getContractorSubcontractors", () =>
    props.getContractorSubcontractorsApi(contractorId).then((res) => res.data)
  );

  const contracts = useDefaultQuery("getContracts", () =>
    props.getContractorContractsApi(contractorId).then((res) => res.data)
  );

  const countries = useDefaultQuery("getCountries", () =>
    props.getCountries().then((res) => res.data)
  );

  const setSubcontractorMutation = useValidatedMutation({
    mutationFunction: (subcontractorIds: number[]) =>
      props
        .setSubcontractorToEventApi({
          eventId,
          contractorId,
          subcontractorIds
        })
        .then((res) => res.data),
    onSuccess: () => {
      eventData.refetch();
      notification.success({
        message: t("events.subcontractorsSaved")
      });
      setIsSetSubcontractorFormShown(false);
    }
  });

  const searchEmployeesMutation = useValidatedMutation({
    mutationFunction: (iins: string[]) =>
      props
        .searchEmployeesByIinsApi(contractorId, eventId, iins)
        .then((res) => res.data)
  });
  const searchEmployeesErrors = useMemo(
    () => transformValidationError(searchEmployeesMutation.error),
    [searchEmployeesMutation.error]
  );

  const editEmployeeDataMutation = useValidatedMutation({
    mutationFunction: (employeeData: EditEmployeePayload) =>
      props
        .updateEmployeeApi({
          contractorId,
          employeeId: employeeData.id,
          employeeData
        })
        .then((res) => res.data),
    onSuccess: (_, employee) => {
      setEditableEmployee(null);

      setSelectedEmployees([
        ...selectedEmployees.filter(({ iin }) => iin !== employee?.iin),
        employee
      ]);
    }
  });
  const editEmployeeDataErrors = useMemo(
    () => transformValidationError(editEmployeeDataMutation.error),
    [editEmployeeDataMutation.error]
  );

  const applyEventMutation = useMutation(
    "applyEvent",
    () =>
      props
        .applyToEventApi(contractorId, eventId, selectedEmployees)
        .then((res) => res.data),
    {
      onSuccess: () => {
        notification.success({
          message: t("events.applicationSent")
        });
        history.push(
          props.redirectUrl
            ? props.redirectUrl(eventId)
            : `/contractor/events/${eventId}`
        );
      },
      onError: (err: AxiosError) => {
        if (err.response?.data.message) {
          notification.error({
            message: err.response?.data.message
          });
        }
      }
    }
  );

  const userHasPermission = checkPermission(PERMISSIONS.EVENT_APPLY);
  const isUserContractor = !userContractor?.parentContractorId;

  const canApply =
    userHasPermission && isUserContractor
      ? !!userContractor?.events.find(({ eventId: eId }) => eId === eventId)
      : !!userContractor?.subcontractorEvents.find(
          ({ eventId: eId }) => eId === eventId
        );

  return (
    <>
      <PageMeta
        title={t("events.applyToEvent", {
          eventName: eventData?.data?.name || ""
        })}
      />
      <AppPageHeader
        title={
          !isEmployeesDataCheckStep
            ? t("events.registerEmployees")
            : t("events.dataCheck")
        }
        subTitle={
          !isEmployeesDataCheckStep ? "" : t("events.doubleClickToEdit")
        }
        breadcrumbs={[
          { breadcrumbName: "CT", path: "contractor/events" },
          {
            breadcrumbName: t("events.eventSearch"),
            path: "search"
          },
          {
            breadcrumbName: `${eventData?.data?.name || ""}`,
            path: `${eventId}/registeremployees`
          }
        ]}
        extra={
          !isEmployeesDataCheckStep && (
            <Link to="/contractor/events/search/">
              <Button danger type="ghost">
                <CloseOutlined />
                {t("events.cancelApplication")}
              </Button>
            </Link>
          )
        }
      />
      <Modal
        visible={isSetSubcontractorFormShown}
        destroyOnClose
        title={t("events.setSubcontractor")}
        maskClosable={false}
        width={800}
        footer={null}
        onCancel={() => {
          setIsSetSubcontractorFormShown(false);
        }}
      >
        <SetSubcontractorToEventForm
          subcontractors={subcontractors.data || []}
          selectedSubcontractors={
            eventData.data?.subcontractors?.map((s) => s.id) || []
          }
          isLoading={setSubcontractorMutation.isLoading}
          onCancel={() => setIsSetSubcontractorFormShown(false)}
          onSubmit={(v) => setSubcontractorMutation.mutate(v)}
        />
      </Modal>
      <Modal
        visible={!!editableEmployee}
        destroyOnClose
        maskClosable={false}
        width={1100}
        footer={null}
        onCancel={() => {
          setEditableEmployee(null);
          editEmployeeDataMutation.reset();
        }}
      >
        <EditEmployeeForm
          contracts={contracts.data || []}
          countries={countries.data || []}
          selectableContractors={[
            { id: contractorId, name: contractorName }
          ].concat(subcontractors.data || [])}
          employee={editableEmployee}
          isLoading={editEmployeeDataMutation.isLoading}
          errors={editEmployeeDataErrors}
          onCancel={() => {
            setEditableEmployee(null);
            editEmployeeDataMutation.reset();
          }}
          onSubmit={(employee) => {
            editEmployeeDataMutation.mutate(employee);
          }}
        />
      </Modal>

      {!isEmployeesDataCheckStep ? (
        <Space direction="vertical" size="large">
          <ContractorEventInfoSection
            isUserContractor={!userContractor?.parentContractorId}
            canSetSubcontractors={!!subcontractors.data?.length}
            eventData={eventData.data}
            isLoading={eventData.isLoading}
            onSetSubcontractor={() => setIsSetSubcontractorFormShown(true)}
          />

          {canApply && (
            <ApplyEmployeeToEventSection
              seatsAvailable={eventData.data?.capacityAvailable || 0}
              employeeSearchResult={
                searchEmployeesMutation.data?.employees || []
              }
              employeeSearchLoading={searchEmployeesMutation.isLoading}
              employeeSearchErrors={searchEmployeesErrors}
              onEmployeeSearch={(v) => searchEmployeesMutation.mutate(v)}
              onApplyEventStepChange={(
                selectedEmployees: EmployeeDetails[]
              ) => {
                setIsEmployeesDataCheckStep(true);
                setSelectedEmployees(selectedEmployees);
              }}
            />
          )}
        </Space>
      ) : (
        <>
          <EventEmployeesDataTable
            countries={countries.data || []}
            selectedEmployees={selectedEmployees}
            onEmployeeEdit={(employee) => {
              setEditableEmployee(employee);
            }}
            onEmployeeDelete={(employeeId) => {
              setSelectedEmployees([
                ...selectedEmployees.filter(({ id }) => id !== employeeId)
              ]);
            }}
          />
          <Row justify="space-between" style={{ marginTop: "32px" }}>
            <Col>
              {selectedEmployees.length} {t("events.employees")}
            </Col>
            <Col>
              <Space>
                <Button onClick={() => setIsEmployeesDataCheckStep(false)}>
                  {t("back")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => applyEventMutation.mutate()}
                >
                  {t("apply")}
                </Button>
              </Space>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
