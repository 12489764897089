import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Row, Space } from "antd";
import { PermissionCheckboxGroup } from "@components/common/PermissionCheckboxGroup";

import { TrainingCenterMember } from "@models/trainingCenterMember";

import { TRAINING_CENTER_PERMISSION_OPTION_LIST } from "@constants/trainingCenterPermissions";

interface FormValues {
  trainingCenterId: number;
  userId: number;
  permissions: number[];
}

interface EditFormProps {
  trainingCenterId: number;
  trainingCenterMember: TrainingCenterMember | null;
  errors?: { [key: string]: string };
  isLoading?: boolean;
  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

const EditTrainingCenterMemberPermissionsForm: React.FC<EditFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const formik = useFormik<FormValues>({
    initialValues: {
      trainingCenterId: props.trainingCenterId,
      userId: props.trainingCenterMember?.userId || 0,
      permissions: []
    },
    onSubmit: (values) => {
      if (props.onSubmit) {
        props.onSubmit({
          trainingCenterId: props.trainingCenterId,
          userId: props.trainingCenterMember?.userId || 0,
          permissions: values.permissions
        });
      }
    }
  });

  useEffect(() => {
    formik.setValues({
      trainingCenterId: props.trainingCenterId,
      userId: props.trainingCenterMember?.userId || 0,
      permissions:
        props.trainingCenterMember?.permissions.map((p) => p.permission) || []
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trainingCenterMember]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={4}>
        <Col md={24}>
          <Form.Item
            label={t("permissions.permissions")}
            validateStatus={formik.errors.permissions && "error"}
            help={formik.errors.permissions}
          >
            <PermissionCheckboxGroup
              options={TRAINING_CENTER_PERMISSION_OPTION_LIST}
              value={formik.values.permissions}
              onChange={(v) => formik.setFieldValue("permissions", v)}
            />
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("save")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditTrainingCenterMemberPermissionsForm;
