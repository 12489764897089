import { useTranslation } from "react-i18next";
import { useMemo } from "react";

import moment from "moment";

import { Space, Table, Typography } from "antd";
import SavedDateColumnBody from "@components/training-center/events/attendance/SavedDateColumnBody";
import SavedDateColumnHeader from "@components/training-center/events/attendance/SavedDateColumnHeader";

import {
  EventAttendance,
  EventDetails,
  EventEmployeeDetails
} from "@models/event";

import {
  formatAttendance,
  getEmployeesByType
} from "@utils/eventAttendanceHelper";
import { getDatesArray, setZeroedTime } from "@utils/eventDatesHelper";

const { Column } = Table;

const API_SHORT_DATE_FORMAT = "DD-MM-YYYY";

const ZERO_ATTENDANCE_HOURS = 0;

interface Props {
  eventDetails: EventDetails;
  employees: EventEmployeeDetails[];
  attendances: EventAttendance[];
  isLoading: boolean;
}

export const ViewEventAttendanceTable: React.FC<Props> = ({
  eventDetails,
  attendances,
  ...props
}) => {
  const { t } = useTranslation();

  const formattedAttendances = formatAttendance(attendances);
  const { evaluatedEmployees, excludedEmployees, sortedEmployees } =
    getEmployeesByType(props.employees);

  const eventDates = useMemo(() => {
    const start = setZeroedTime(eventDetails.dateTimeStart);
    const end = setZeroedTime(eventDetails.dateTimeEnd);

    return getDatesArray(start, end);
  }, [eventDetails.dateTimeStart, eventDetails.dateTimeEnd]);

  const findEmployeeAttendance = (employeeId: number) =>
    attendances.find((a) => a.employeeId === employeeId);

  return (
    <Table
      bordered
      locale={{ emptyText: t("noData") }}
      rowKey="employeeId"
      loading={props.isLoading}
      pagination={false}
      scroll={{ x: "max-content" }}
      dataSource={sortedEmployees}
    >
      <Column
        title={t("fullName")}
        fixed="left"
        width={300}
        render={(employee: EventEmployeeDetails) => (
          <Space direction="vertical" size="small">
            <Typography.Text>
              {employee.firstName} {employee.lastName}
            </Typography.Text>
            <Typography.Text type="secondary">{employee.iin}</Typography.Text>
          </Space>
        )}
      />
      {eventDates?.map((date) => {
        const formattedDate = moment(date).format(API_SHORT_DATE_FORMAT);
        const formattedDaysOff = eventDetails.daysOff?.map((dayOff) =>
          moment(dayOff).format(API_SHORT_DATE_FORMAT)
        );
        const isDayOff = formattedDaysOff?.includes(formattedDate);
        return (
          <Column
            width={100}
            align="center"
            title={
              <SavedDateColumnHeader date={formattedDate} isDayOff={isDayOff} />
            }
            render={(employee: EventEmployeeDetails, _) => {
              const isEvaluated = evaluatedEmployees.includes(employee);
              const isExcluded = excludedEmployees.includes(employee);
              const employeeAttendance = formattedAttendances.find(
                ({ employeeId }) => {
                  return employee.employeeId === employeeId;
                }
              );
              const employeeDateAttendance = employeeAttendance?.days.find(
                ({ date: employeeDate }) => {
                  return (
                    formattedDate === employeeDate.format(API_SHORT_DATE_FORMAT)
                  );
                }
              );
              const isDateExcluded = moment(
                moment(date, "DD-MM-YYYY").toDate()
              ).isSameOrAfter(moment(employee.excludedAt).format("YYYY-MM-DD"));

              return (
                <SavedDateColumnBody
                  employee={employee}
                  isExcluded={isExcluded}
                  isEvaluated={isEvaluated}
                  value={employeeDateAttendance?.hours}
                  isDayOff={isDayOff}
                  isDateExcluded={isDateExcluded}
                />
              );
            }}
          />
        );
      })}
      <Column
        width={100}
        align={"center"}
        title={t("events.totalHours")}
        render={(employee: EventEmployeeDetails) => {
          const savedAttendanceTotal =
            findEmployeeAttendance(employee.employeeId)
              ?.days?.filter(({ date }) => {
                const formattedDate = moment(date).format("YYYY-MM-DD");
                const isDayOff = eventDetails.daysOff?.some((day) =>
                  day.includes(formattedDate)
                );

                return !isDayOff;
              })
              .reduce((total, a) => total + a.hours, ZERO_ATTENDANCE_HOURS) ||
            ZERO_ATTENDANCE_HOURS;
          return (
            <Typography.Text strong>{savedAttendanceTotal}</Typography.Text>
          );
        }}
      />
    </Table>
  );
};

export default ViewEventAttendanceTable;
