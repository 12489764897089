import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import { differenceInHours } from "date-fns";
import { useFormik } from "formik";
import { UploadFile } from "antd/lib/upload/interface";

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Upload
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import EventDateTimeRangePicker from "@components/common/events/forms/EventDateTimeRangePicker";
import MultiDatePicker from "@components/common/MultiDatePicker";

import {
  CapacityMinNotificationEntitiesOptions,
  CsrPhase,
  EventType
} from "@models/event";
import { LanguageCode } from "@models/languageCode";
import { Contractor } from "@models/contractor";
import { CompetencyType, Discipline } from "@models/discipline";
import { CompetencyLevel } from "@models/competencyLevel";
import { TrainingCenter } from "@models/trainingCenter";
import { Location } from "@models/location";

import useDateFormatter from "@hooks/useDateFormat";
import { CUSTOM_COMPETENCY_LEVEL_SELECT_OPTIONS } from "@constants/competencyLevelSelectOptions";
import { convertNotificationEntities } from "@utils/eventHelper";
import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

interface FormValues {
  type?: EventType;
  name?: string;
  capacityMax?: number;
  capacityMin?: number;
  dateTimeStart?: string;
  dateTimeEnd?: string;
  daysOff?: string[];
  description?: string;
  hoursPerDay?: number;
  specialityId?: number;
  academicHours?: number;
  standard?: string;
  applicableContractors?: number[];
  competencyLevel?: number;
  csrPhase?: CsrPhase;
  customCompetencyLevel?: number;
  languageCode?: LanguageCode;
  isCommissioning?: boolean;
  files?: UploadFile[];
  capacityMinNotificationDate?: string;
  capacityMinNotificationEntities?: number[];
  cityId?: string;
}

interface Props {
  isLoading?: boolean;
  errors?: { [key: string]: string };

  contractors?: Contractor[];
  specialities?: Discipline[];
  trainingCenter?: TrainingCenter;
  locations?: Location[];

  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

const HOURS_PER_DAY_VARS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

type _SelectOption = {
  label: string;
  value: number;
};

export const DEFAULT_COMPETENCY_LEVEL_OPTIONS: _SelectOption[] = [
  { label: "helper", value: CompetencyLevel.HELPER },
  { label: "basic", value: CompetencyLevel.BASIC },
  { label: "intermediate", value: CompetencyLevel.INTERMEDIATE },
  { label: "advanced", value: CompetencyLevel.ADVANCED }
];

export const COMPETENCY_LEVEL_OPTIONS_MAP: { [key: number]: _SelectOption[] } =
  {
    [CompetencyType.PROFICIENT]: [
      { label: "all", value: 0 },
      { label: "competent", value: CompetencyLevel.COMPETENT }
    ],
    [CompetencyType.COMPETENT]: [
      { label: "all", value: 0 },
      { label: "proficient", value: CompetencyLevel.PROFICIENT }
    ]
  };

export const NewEventForm = (props: Props) => {
  const [t, i18n] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      type: undefined,
      name: undefined,
      academicHours: undefined,
      applicableContractors: undefined,
      capacityMax: undefined,
      capacityMin: undefined,
      competencyLevel: undefined,
      csrPhase: 0,
      customCompetencyLevel: undefined,
      dateTimeEnd: undefined,
      dateTimeStart: undefined,
      description: undefined,
      files: undefined,
      hoursPerDay: undefined,
      languageCode: undefined,
      specialityId: undefined,
      standard: undefined,
      isCommissioning: false,
      capacityMinNotificationDate: undefined,
      capacityMinNotificationEntities: undefined,
      cityId: undefined
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(convertNotificationEntities(values));
      }
    }
  });
  const dateFormatter = useDateFormatter();

  const isTraining = formik.values.type === EventType.TRAINING;
  const isAssessment = formik.values.type === EventType.ASSESSMENT;
  const isPrescreening = formik.values.type === EventType.PRESCREENING;
  const isOjtTraining = formik.values.type === EventType.OJT_TRAINING;

  const showDaysOff = isTraining || isOjtTraining;
  const showCsrPhase =
    props.trainingCenter?.csrEnabled &&
    !formik.values.isCommissioning &&
    (isTraining || isAssessment || isPrescreening);

  const showCapacityMin = !isPrescreening && !isOjtTraining;
  const showNotificationDate = !isPrescreening;
  const showAcademicHours = !isPrescreening;
  const showStandard = !isPrescreening;
  const showDescription = !isPrescreening;
  const showFiles = !isPrescreening;
  const showNotificationEntities = !!formik.values.capacityMinNotificationDate;

  const hoursTillEvent = formik.values.dateTimeStart
    ? differenceInHours(new Date(formik.values.dateTimeStart), new Date())
    : undefined;

  const filteredSpecialities = useMemo(() => {
    if (formik.values.isCommissioning) {
      return props.specialities?.filter(
        (s) => s.competencyType === CompetencyType.COMMISSIONING
      );
    }

    return props.specialities?.filter(
      (s) => s.competencyType !== CompetencyType.COMMISSIONING
    );
  }, [formik.values.isCommissioning, props.specialities]);

  const selectedSpecialityCompetencyType = useMemo(() => {
    return props.specialities?.find((s) => s.id === formik.values.specialityId)
      ?.competencyType;
  }, [formik.values.specialityId, props.specialities]);

  const competencyLevelOptions = useMemo(() => {
    return (
      COMPETENCY_LEVEL_OPTIONS_MAP[selectedSpecialityCompetencyType || 0] ||
      DEFAULT_COMPETENCY_LEVEL_OPTIONS
    );
  }, [selectedSpecialityCompetencyType]);

  const eventDates = useMemo(() => {
    if (formik.values.dateTimeStart && formik.values.dateTimeEnd) {
      try {
        const val = eachDayOfInterval({
          start: new Date(formik.values.dateTimeStart),
          end: new Date(formik.values.dateTimeEnd)
        }).map((v) => ({
          value: v.toJSON(),
          label: dateFormatter.formatter(v.toJSON(), "short-date")
        }));

        return val;
      } catch (err) {
        return [];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.dateTimeStart, formik.values.dateTimeEnd]);

  const showCompetencyLevelField = useMemo(() => {
    const isDefaultCompetency =
      selectedSpecialityCompetencyType &&
      [
        CompetencyType.DEFAULT,
        CompetencyType.DEFAULT_HELPER,
        CompetencyType.INTERMEDIATE_ADVANCED
      ].includes(selectedSpecialityCompetencyType);

    const isTraining =
      formik.values.type === EventType.TRAINING ||
      formik.values.type === EventType.OJT_TRAINING;

    return isTraining && isDefaultCompetency && !formik.values.isCommissioning;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.type,
    formik.values.isCommissioning,
    formik.values.specialityId,
    selectedSpecialityCompetencyType
  ]);

  const showCustomCompetencyLevelField = useMemo(() => {
    if (
      formik.values.type !== EventType.ASSESSMENT ||
      !formik.values.specialityId ||
      !props.specialities
    ) {
      return false;
    }

    return (
      props.specialities?.find((s) => s.id === formik.values.specialityId)
        ?.competencyType === CompetencyType.INTERMEDIATE_ADVANCED
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.type,
    formik.values.specialityId,
    formik.values.isCommissioning
  ]);

  useEffect(() => {
    if (formik.values.isCommissioning) {
      const _type = formik.values.type;

      if (_type !== EventType.ASSESSMENT && _type !== EventType.TRAINING) {
        formik.setFieldValue("type", undefined);
      }

      formik.setFieldValue("specialityId", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.isCommissioning]);

  useEffect(() => {
    if (!showDaysOff) {
      formik.setFieldValue("daysOff", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDaysOff]);

  useEffect(() => {
    if (!showCsrPhase) {
      formik.setFieldValue("csrPhase", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCsrPhase]);

  useEffect(() => {
    if (!showCapacityMin) {
      formik.setFieldValue("capacityMin", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCapacityMin]);

  useEffect(() => {
    if (!showStandard) {
      formik.setFieldValue("standard", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStandard]);

  useEffect(() => {
    if (!showDescription) {
      formik.setFieldValue("description", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDescription]);

  useEffect(() => {
    if (!showCompetencyLevelField) {
      if ((isTraining || isOjtTraining) && !formik.values.isCommissioning) {
        switch (selectedSpecialityCompetencyType) {
          case CompetencyType.COMPETENT:
            formik.setFieldValue("competencyLevel", CompetencyLevel.COMPETENT);
            break;

          case CompetencyType.PROFICIENT:
            formik.setFieldValue("competencyLevel", CompetencyLevel.PROFICIENT);
            break;

          default:
            break;
        }
      } else {
        formik.setFieldValue("competencyLevel", undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.type,
    formik.values.isCommissioning,
    formik.values.specialityId
  ]);

  // Check if the notification should be cleared up
  useEffect(() => {
    if (
      !formik.values.dateTimeStart ||
      !hoursTillEvent ||
      hoursTillEvent < 48
    ) {
      formik.setFieldValue("capacityMinNotificationDate", undefined);
      formik.setFieldValue("capacityMinNotificationEntities", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.dateTimeStart]);

  // Check if the days off are now outside of event start and end dates
  useEffect(() => {
    const currentDaysOff = formik.values.daysOff;
    formik.setFieldValue(
      "daysOff",
      currentDaysOff?.filter((day) =>
        eventDates?.some(({ value }) => value === day)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDates]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors(props.errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      {props.trainingCenter?.commissioningEnabled && (
        <Form.Item
          validateStatus={formik.errors.isCommissioning && "error"}
          help={formik.errors.isCommissioning}
        >
          <Checkbox
            checked={formik.values.isCommissioning}
            name="isCommissioning"
            onChange={formik.handleChange}
          >
            {t("events.commissioning")}
          </Checkbox>
        </Form.Item>
      )}

      <Form.Item
        validateStatus={formik.errors.type && "error"}
        help={formik.errors.type}
      >
        <Radio.Group
          name="type"
          value={formik.values.type}
          onChange={formik.handleChange}
        >
          {/* TODO: Refactor list of event types render & checks */}
          {props.trainingCenter?.eventTypes.some(
            (et) => et === EventType.TRAINING
          ) && (
            <Radio value={EventType.TRAINING}>
              {t("events.eventTypes.training")}
            </Radio>
          )}
          {props.trainingCenter?.eventTypes.some(
            (et) => et === EventType.ASSESSMENT
          ) && (
            <Radio value={EventType.ASSESSMENT}>
              {t("events.eventTypes.assessment")}
            </Radio>
          )}

          {!formik.values.isCommissioning && (
            <>
              {props.trainingCenter?.eventTypes.some(
                (et) => et === EventType.PRESCREENING
              ) && (
                <Radio value={EventType.PRESCREENING}>
                  {t("events.eventTypes.prescreening")}
                </Radio>
              )}
              {props.trainingCenter?.eventTypes.some(
                (et) => et === EventType.OJT_TRAINING
              ) && (
                <Radio value={EventType.OJT_TRAINING}>
                  {t("events.eventTypes.ojtTraining")}
                </Radio>
              )}
            </>
          )}
        </Radio.Group>
      </Form.Item>

      {formik.values.type && (
        <Row gutter={8}>
          <Col md={12}>
            <Form.Item
              label={t("events.name")}
              validateStatus={formik.errors.name && "error"}
              help={formik.errors.name}
            >
              <Input
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>

          <Col md={6}>
            <Form.Item
              label={t("events.numberOfSeats")}
              validateStatus={formik.errors.capacityMax && "error"}
              help={formik.errors.capacityMax}
            >
              <Input
                style={{ width: "100%" }}
                name="capacityMax"
                value={formik.values.capacityMax}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>

          {showCapacityMin && (
            <Col md={6}>
              <Form.Item
                label={t("events.minimumSeats")}
                validateStatus={formik.errors.capacityMin && "error"}
                help={formik.errors.capacityMin}
              >
                <Input
                  style={{ width: "100%" }}
                  name="capacityMin"
                  value={formik.values.capacityMin}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          )}

          {showAcademicHours && (
            <Col md={12}>
              <Form.Item
                label={t("events.academicHours")}
                validateStatus={formik.errors.academicHours && "error"}
                help={formik.errors.academicHours}
              >
                <Input
                  style={{ width: "100%" }}
                  name="academicHours"
                  value={formik.values.academicHours}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          )}

          <Col md={12}>
            <Form.Item
              label={t("events.discipline")}
              validateStatus={formik.errors.specialityId && "error"}
              help={formik.errors.specialityId}
            >
              <Select
                showSearch
                showArrow
                optionFilterProp={i18n.language === "en" ? "nameEn" : "nameRu"}
                filterOption={(inp, opt) => {
                  if (opt && opt.children) {
                    return (
                      opt.children
                        .toString()
                        .toLowerCase()
                        .indexOf(inp.toLowerCase()) >= 0
                    );
                  }

                  return false;
                }}
                value={formik.values.specialityId}
                onChange={(v) => formik.setFieldValue("specialityId", v)}
              >
                {filteredSpecialities?.map((spec) => (
                  <Select.Option key={spec.id} value={spec.id}>
                    {i18n.language === "en" ? spec.nameEn : spec.nameRu}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item
              label={t("events.language")}
              validateStatus={formik.errors.languageCode && "error"}
              help={formik.errors.languageCode}
            >
              <Select
                value={formik.values.languageCode}
                onChange={(v) => {
                  formik.setFieldValue("languageCode", v);
                }}
              >
                <Select.Option value={LanguageCode.RU}>RU</Select.Option>
                <Select.Option value={LanguageCode.KK}>KK</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item
              label={t("events.location")}
              validateStatus={formik.errors.cityId && "error"}
              help={formik.errors.cityId}
            >
              <Select
                value={formik.values.cityId}
                onChange={(v) => {
                  formik.setFieldValue("cityId", v);
                }}
              >
                {props.locations?.map((location, index) => (
                  <Select.Option key={`location-${index}`} value={location.id}>
                    {i18n.language === "en" ? location.nameEn : location.nameRu}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={24}>
            <Form.Item
              label={t("events.availableFor")}
              validateStatus={formik.errors.applicableContractors && "error"}
              help={formik.errors.applicableContractors}
            >
              <Select
                mode="multiple"
                showSearch
                showArrow
                optionFilterProp="name"
                filterOption={(inp, opt) => {
                  if (opt && opt.children) {
                    return (
                      opt.children
                        .toString()
                        .toLowerCase()
                        .indexOf(inp.toLowerCase()) >= 0
                    );
                  }

                  return false;
                }}
                value={formik.values.applicableContractors}
                onChange={(v) =>
                  formik.setFieldValue("applicableContractors", v)
                }
              >
                {props.contractors?.map((contr) => (
                  <Select.Option key={contr.id} value={contr.id}>
                    {contr.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {showCsrPhase && (
            <Col md={24}>
              <Form.Item
                label={t("events.csrPhase")}
                validateStatus={formik.errors.csrPhase && "error"}
                help={formik.errors.csrPhase}
              >
                <Select
                  value={formik.values.csrPhase}
                  onChange={(v) => formik.setFieldValue("csrPhase", v)}
                >
                  {CSR_PHASE_OPTIONS.map((cp) => (
                    <Select.Option value={cp.value} key={cp.value}>
                      {t(cp.label, { no: cp.value })}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {showStandard && (
            <Col md={24}>
              <Form.Item
                label={t("events.standard")}
                validateStatus={formik.errors.standard && "error"}
                help={formik.errors.standard}
              >
                <Input
                  name="standard"
                  value={formik.values.standard}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          )}

          {showCustomCompetencyLevelField && (
            <Col md={24}>
              <Form.Item
                label={t("events.competencyLevel")}
                validateStatus={formik.errors.customCompetencyLevel && "error"}
                help={formik.errors.customCompetencyLevel}
              >
                <Select
                  value={formik.values.customCompetencyLevel}
                  onChange={(v) =>
                    formik.setFieldValue("customCompetencyLevel", v)
                  }
                >
                  {CUSTOM_COMPETENCY_LEVEL_SELECT_OPTIONS.map((cl) => (
                    <Select.Option value={cl.value} key={cl.value}>
                      {t(cl.label)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {showCompetencyLevelField && (
            <Col md={24}>
              <Form.Item
                label={t("events.competencyLevel")}
                validateStatus={formik.errors.competencyLevel && "error"}
                help={formik.errors.competencyLevel}
              >
                <Select
                  value={formik.values.competencyLevel}
                  onChange={(v) => formik.setFieldValue("competencyLevel", v)}
                >
                  {competencyLevelOptions?.map((cl) => (
                    <Select.Option value={cl.value || 0} key={cl.value || 0}>
                      {t(`competencyLevels.${cl.value}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {showDescription && (
            <Col md={24}>
              <Form.Item
                label={t("events.description")}
                validateStatus={formik.errors.description && "error"}
                help={formik.errors.description}
              >
                <Input.TextArea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          )}

          <EventDateTimeRangePicker
            onStartChange={(v) =>
              formik.setFieldValue("dateTimeStart", v?.format())
            }
            onEndChange={(v) =>
              formik.setFieldValue("dateTimeEnd", v?.format())
            }
            dateTimeStart={formik.values.dateTimeStart}
            dateTimeEnd={formik.values.dateTimeEnd}
            dateTimeStartError={formik.errors.dateTimeStart}
            dateTimeEndError={formik.errors.dateTimeEnd}
          />

          {showDaysOff && (
            <>
              <Col md={12}>
                <Form.Item
                  label={t("events.setDaysOff")}
                  validateStatus={formik.errors.daysOff && "error"}
                  help={formik.errors.daysOff}
                >
                  <MultiDatePicker
                    value={formik.values.daysOff || []}
                    onChange={(v) => formik.setFieldValue("daysOff", v)}
                    disabledDate={(date) =>
                      date.isAfter(formik.values.dateTimeEnd) ||
                      date.isBefore(formik.values.dateTimeStart)
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item
                  label={t("events.hoursPerDay")}
                  validateStatus={formik.errors.hoursPerDay && "error"}
                  help={formik.errors.hoursPerDay}
                >
                  <Select
                    value={formik.values.hoursPerDay}
                    onChange={(v) => formik.setFieldValue("hoursPerDay", v)}
                  >
                    {HOURS_PER_DAY_VARS.map((v) => (
                      <Select.Option value={v} key={v}>
                        {v}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}

          {showNotificationDate && (
            <>
              <Col md={24}>
                <Form.Item
                  label={t("events.notificationDate")}
                  validateStatus={
                    formik.errors.capacityMinNotificationDate && "error"
                  }
                  help={formik.errors.capacityMinNotificationDate}
                >
                  <DatePicker
                    allowClear
                    placeholder=""
                    format="YYYY-MM-DD"
                    disabled={hoursTillEvent ? hoursTillEvent < 25 : true}
                    disabledDate={(date) =>
                      !(
                        date.isBefore(
                          moment(formik.values.dateTimeStart).startOf("day")
                        ) && date.isAfter(moment().endOf("day"))
                      )
                    }
                    style={{ width: "100%" }}
                    value={
                      formik.values.capacityMinNotificationDate
                        ? moment(formik.values.capacityMinNotificationDate)
                        : null
                    }
                    onChange={(v) =>
                      formik.setFieldValue(
                        "capacityMinNotificationDate",
                        v?.startOf("day").format()
                      )
                    }
                  />
                </Form.Item>
              </Col>
              {showNotificationEntities && (
                <Col md={24}>
                  <Form.Item
                    label={t("events.notificationEntities")}
                    validateStatus={
                      formik.errors.capacityMinNotificationEntities && "error"
                    }
                    help={formik.errors.capacityMinNotificationEntities}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      showArrow
                      optionFilterProp="name"
                      filterOption={(inp, opt) => {
                        if (opt && opt.children) {
                          return (
                            opt.children
                              .toString()
                              .toLowerCase()
                              .indexOf(inp.toLowerCase()) >= 0
                          );
                        }

                        return false;
                      }}
                      value={formik.values.capacityMinNotificationEntities}
                      onChange={(v) =>
                        formik.setFieldValue(
                          "capacityMinNotificationEntities",
                          v
                        )
                      }
                    >
                      <Select.Option
                        value={CapacityMinNotificationEntitiesOptions.TCO}
                      >
                        {t("events.notificationEntitiesOptions.TCO")}
                      </Select.Option>

                      <Select.Option
                        value={
                          CapacityMinNotificationEntitiesOptions.TRAINING_CENTER
                        }
                      >
                        {props.trainingCenter?.name}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </>
          )}

          {showFiles && (
            <Col md={12}>
              <Form.Item
                validateStatus={formik.errors.files && "error"}
                help={formik.errors.files}
              >
                <Upload
                  multiple
                  maxCount={3}
                  accept=".xlsx,.pdf,.docx,.zip"
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={(ev) => {
                    formik.setFieldValue("files", ev.fileList);
                  }}
                  onRemove={(f) => {
                    const nextFormVal = formik.values.files?.filter(
                      (file) => file !== f
                    );

                    formik.setFieldValue("files", nextFormVal);
                  }}
                >
                  <Button type="dashed" icon={<UploadOutlined />}>
                    {t("events.uploadFile")}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          )}
        </Row>
      )}

      <br />

      <Row justify="space-between">
        <Col>
          <Button onClick={() => props.onCancel && props.onCancel()}>
            {t("cancel")}
          </Button>
        </Col>

        <Col>
          <Button type="primary" htmlType="submit" loading={props.isLoading}>
            {t("create")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
