import { useTranslation } from "react-i18next";

import {
  CarryOutOutlined,
  ThunderboltOutlined,
  ToolOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import { Space, Tag } from "antd";

const TAG_BG_COLOR = "#e3f2fd";
const TAG_ICON_COLOR = "#fb8c00";
const TAG_TEXT_COLOR = "#363636";

export const EventTypeTag: React.FC<{
  eventType?: number;
  isCommissioning?: boolean;
}> = ({ eventType, isCommissioning }) => {
  const { t } = useTranslation();
  switch (eventType) {
    case 1:
      return (
        <Tag color={TAG_BG_COLOR}>
          <Space>
            <CarryOutOutlined style={{ color: TAG_ICON_COLOR }} />
            <span style={{ color: TAG_TEXT_COLOR }}>
              {isCommissioning
                ? t(`events.eventTypes.commissioningAssessment`)
                : t(`events.eventTypes.assessment`)}
            </span>
          </Space>
        </Tag>
      );
    case 2:
      return (
        <Tag color={TAG_BG_COLOR}>
          <Space>
            <ThunderboltOutlined style={{ color: TAG_ICON_COLOR }} />
            <span style={{ color: TAG_TEXT_COLOR }}>
              {isCommissioning
                ? t(`events.eventTypes.commissioningTraining`)
                : t(`events.eventTypes.training`)}
            </span>
          </Space>
        </Tag>
      );
    case 3:
      return (
        <Tag color={TAG_BG_COLOR}>
          <Space>
            <UsergroupAddOutlined style={{ color: TAG_ICON_COLOR }} />
            <span style={{ color: TAG_TEXT_COLOR }}>
              {t(`events.eventTypes.prescreening`)}
            </span>
          </Space>
        </Tag>
      );
    case 4:
      return (
        <Tag color={TAG_BG_COLOR}>
          <Space>
            <ToolOutlined style={{ color: TAG_ICON_COLOR }} />
            <span style={{ color: TAG_TEXT_COLOR }}>
              {t(`events.eventTypes.ojtTraining`)}
            </span>
          </Space>
        </Tag>
      );
    default:
      return null;
  }
};
