import { useTranslation } from "react-i18next";

import FormItem from "antd/lib/form/FormItem";
import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Select } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { Discipline } from "@models/discipline";
import { CraftProfilesListFilter } from "@models/craftProfiles";

import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

interface Props {
  hiddenFields?: (keyof CraftProfilesListFilter)[];
  initialFilter?: CraftProfilesListFilter;
  disciplines?: Discipline[];
  trainingCenters?: { id: number; name: string }[];
  contractors?: { id: number; name: string }[];
  isLoading: boolean;
  onSubmit: (values: CraftProfilesListFilter) => void;
}

export const CraftProfilesFilterForm: React.FC<Props> = ({
  initialFilter,
  hiddenFields,
  disciplines,
  trainingCenters,
  contractors,
  isLoading,
  onSubmit
}) => {
  const { t, i18n } = useTranslation();

  const formik = useFormik<CraftProfilesListFilter>({
    initialValues: {
      ...initialFilter,
      isEmployed:
        initialFilter?.isEmployed === undefined
          ? null
          : !!initialFilter?.isEmployed,
      specialityIds: initialFilter?.specialityIds,
      iin: initialFilter?.iin,
      trainingCenterIds: initialFilter?.trainingCenterIds?.map((value) =>
        Number(value)
      ),
      csrContractorIds: initialFilter?.csrContractorIds?.map((value) =>
        Number(value)
      ),
      csrPhases: initialFilter?.csrPhases?.map((value) => Number(value))
    },
    onSubmit: (values) => onSubmit(values)
  });

  return (
    <Form onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={8} justify="space-between" style={{ marginBottom: "16px" }}>
        {!hiddenFields?.includes("isEmployed") && (
          <Col md={6}>
            <Form.Item
              label={t("craftProfiles.filters.status")}
              labelCol={{ span: 24 }}
            >
              <Select
                value={formik.values.isEmployed}
                showArrow
                allowClear
                onChange={(v) => formik.setFieldValue("isEmployed", v)}
              >
                <Select.Option value={true}>
                  {t("craftProfiles.filters.employed")}
                </Select.Option>
                <Select.Option value={false}>
                  {t("craftProfiles.filters.unemployed")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        )}

        {!hiddenFields?.includes("csrPhases") && (
          <Col md={6}>
            <Form.Item
              label={t("craftProfiles.filters.csrPhases")}
              labelCol={{ span: 24 }}
            >
              <SearchSelect
                value={formik.values.csrPhases}
                options={CSR_PHASE_OPTIONS.map(({ value, label }) => ({
                  value: value,
                  label: t(label, { no: value })
                }))}
                onChange={(v: number) => formik.setFieldValue("csrPhases", v)}
                maxTagCount={1}
                mode="multiple"
              />
            </Form.Item>
          </Col>
        )}

        {!hiddenFields?.includes("csrContractorIds") && (
          <Col span={6}>
            <FormItem
              label={t("craftProfiles.filters.csrContractors")}
              labelCol={{ span: 24 }}
            >
              <SearchSelect
                value={formik.values.csrContractorIds}
                options={contractors?.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
                onChange={(value: number) =>
                  formik.setFieldValue("csrContractorIds", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}

        {!hiddenFields?.includes("trainingCenterIds") && (
          <Col span={6}>
            <FormItem
              label={t("craftProfiles.filters.trainingCenters")}
              labelCol={{ span: 24 }}
            >
              <SearchSelect
                value={formik.values.trainingCenterIds}
                options={trainingCenters?.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
                onChange={(value: number) =>
                  formik.setFieldValue("trainingCenterIds", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}

        {!hiddenFields?.includes("specialityIds") && (
          <Col span={6}>
            <FormItem
              label={t("craftProfiles.filters.disciplines")}
              labelCol={{ span: 24 }}
            >
              <SearchSelect
                value={formik.values.specialityIds}
                options={disciplines?.map(({ id, nameEn, nameRu }) => ({
                  value: id,
                  label: i18n.language === "en" ? nameEn : nameRu
                }))}
                onChange={(value: number) =>
                  formik.setFieldValue("specialityIds", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}

        {!hiddenFields?.includes("iin") && (
          <Col span={6}>
            <FormItem
              label={t("craftProfiles.filters.iin")}
              labelCol={{ span: 24 }}
            >
              <Input
                name="iin"
                value={formik.values.iin}
                onChange={formik.handleChange}
              />
            </FormItem>
          </Col>
        )}

        <Col span={12} style={{ textAlign: "right", marginTop: "40px" }}>
          <Button
            onClick={() => formik.setValues({})}
            type="ghost"
            style={{ minWidth: 150, marginRight: "8px" }}
          >
            {t("craftProfiles.filters.clearFilters")}
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
            style={{ minWidth: 150 }}
          >
            {t("search")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
