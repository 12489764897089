import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  UserOutlined,
  PhoneOutlined,
  FlagOutlined,
  ShopOutlined,
  PushpinOutlined
} from "@ant-design/icons";
import { Card, Col, PageHeader, Row, Typography } from "antd";
import PageMeta from "@components/PageMeta";
import { BreadcrumbRenderer } from "@components/BreadcrumbRenderer";
import ContractsTable from "@components/contractors/ContractsTable";
import { Section, SubSection, Div } from "@components/Sections";

import { PROJECTS } from "@models/projects";

import { subcontractorApi } from "@api/subcontractorApi";
import { contractApi } from "@api/contractApi";
import { contractorsApi } from "@api/contractorsApi";

import useDateFormatter from "@hooks/useDateFormat";
import { useDefaultQuery } from "@hooks/useDefaultQuery";

import MembersSection from "./components/MembersSection";

interface SubcontractorPageParams {
  contractorId: string;
  subcontractorId: string;
}

export const SubcontractorPage: React.FC = () => {
  const [t, i18n] = useTranslation();
  const queryParams = useParams<SubcontractorPageParams>();
  const contractorId = Number(queryParams.contractorId);
  const subcontractorId = Number(queryParams.subcontractorId);

  const { formatter } = useDateFormatter();

  const subcontractorDetails = useDefaultQuery(
    ["getSubcontractor", contractorId, subcontractorId],
    () =>
      subcontractorApi
        .getSubcontractor({ contractorId, subcontractorId })
        .then((res) => res.data)
  );

  const contractorDetails = useDefaultQuery("getContractor", () =>
    contractorsApi.getContractor(contractorId).then((res) => res.data)
  );

  const subcontractorContracts = useDefaultQuery(
    ["getSubcontractorContracts", contractorId, subcontractorId],
    () =>
      subcontractorApi
        .getSubcontractorContracts({ contractorId, subcontractorId })
        .then((res) => res.data)
  );

  const projects = useDefaultQuery("getProjects", () =>
    contractApi.getProjects().then((res) => res.data)
  );
  const filteredProjects =
    projects.data?.filter(
      (p) =>
        p.id === PROJECTS._3GP ||
        p.id === PROJECTS._3GI ||
        p.id === PROJECTS.GATHERING ||
        p.id === PROJECTS.CONTROL_AND_POWER ||
        p.id === PROJECTS.CORE_TEAM ||
        p.id === PROJECTS.CSR
    ) || [];

  const kcCategories = useDefaultQuery("getKcCategories", () =>
    contractApi.getKcCategories().then((res) => res.data)
  );

  const filteredKcCategories =
    kcCategories.data?.filter((c) => c.id !== 0) || [];

  const renderContractorTitle = () => {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {t("contractors.subcontractor") +
            " " +
            subcontractorDetails.data?.name}
        </Typography.Title>

        <Typography.Text type="secondary" style={{ marginLeft: "12px" }}>
          {t("contractors.registrationDate")}:{" "}
          {subcontractorDetails.data
            ? formatter(subcontractorDetails.data.city.createdAt)
            : ""}
        </Typography.Text>
      </div>
    );
  };

  const pageTitle = subcontractorDetails.data?.name || "";
  return (
    <>
      <PageMeta title={pageTitle} />
      <PageHeader
        breadcrumb={{
          routes: [
            { breadcrumbName: "CT", path: "/" },
            {
              breadcrumbName: t("contractors.contractors"),
              path: "/admin/contractors"
            },
            {
              breadcrumbName: contractorDetails.data?.name || "",
              path: `/${contractorId}/`
            },
            {
              breadcrumbName: pageTitle,
              path: ""
            }
          ],
          itemRender: BreadcrumbRenderer
        }}
      />
      <Card
        title={renderContractorTitle()}
        style={{ marginBottom: 32 }}
        loading={
          subcontractorDetails.isFetching || subcontractorDetails.isLoading
        }
      >
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <UserOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {subcontractorDetails.data?.bin}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.bin")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <PhoneOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {subcontractorDetails.data?.phone || "-"}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.workPhoneNumber")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <FlagOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {i18n.language === "en" && subcontractorDetails.data?.country
                    ? subcontractorDetails.data?.country?.nameEn
                    : subcontractorDetails.data?.country?.nameRu}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.country")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <ShopOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {i18n.language === "en" && subcontractorDetails
                    ? subcontractorDetails.data?.city.nameEn
                    : subcontractorDetails.data?.city.nameRu}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.city")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <PushpinOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {subcontractorDetails.data?.address}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.address")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
      </Card>
      <Section>
        <SubSection>
          <ContractsTable
            title={t("contract.contracts")}
            data={subcontractorContracts.data}
            isLoading={subcontractorContracts.isLoading}
            projects={filteredProjects}
            kcCategories={filteredKcCategories}
            canEdit={false}
          />
        </SubSection>
      </Section>

      <MembersSection
        contractorId={contractorId}
        subcontractorId={subcontractorId}
      />
    </>
  );
};
