import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { notification, Table } from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { OnlineTrainingReportTable } from "@components/common/reports/OnlineTrainingReportTable";

import { onlineTrainingReportsApi } from "@api/onlineTrainingReportsApi";

import { usePagination } from "@hooks/usePagination";

const OnlineTrainingReportPage: React.FC = () => {
  const [t] = useTranslation();
  const pageTitle = t("reports.onlineTrainingReport_other");
  const { page, setPage, setPagination } = usePagination();

  const reportsList = useQuery(
    ["online-training-reports-list", page],
    () =>
      onlineTrainingReportsApi
        .getAllOnlineTrainingReports(page)
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage
        };
        setPagination(pagination);
      },
      onError: () =>
        notification.error({
          message: t("error.errorOccured")
        })
    }
  );

  const paginationSettings = {
    hideOnSinglePage: true,
    total: reportsList.data?.totalItems,
    current: reportsList.data?.currentPage,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: "/admin/reports/online-training-reports"
          }
        ]}
      />
      {reportsList ? (
        <OnlineTrainingReportTable
          reportsData={reportsList.data}
          pagination={paginationSettings}
          isLoading={reportsList.isLoading}
        />
      ) : (
        <Table
          dataSource={[]}
          loading={true}
          locale={{
            emptyText: !reportsList ? t("error.errorOccured") : ""
          }}
        />
      )}
    </>
  );
};

export default OnlineTrainingReportPage;
