import { UseMutateFunction } from "react-query";
import { useTranslation } from "react-i18next";

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography } from "antd";

import { OnlineTrainingReportResult } from "@models/onlineTrainingReports";

interface Props {
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  currentReport: OnlineTrainingReportResult;
  handleDelete: UseMutateFunction<unknown, unknown, number, unknown>;
  isLoading?: boolean;
}

const DeleteOnlineTrainingReportModal: React.FC<Props> = ({
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  currentReport,
  isLoading,
  handleDelete
}) => {
  const [t] = useTranslation();

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("reports.deleteOnlineTrainingReport")}
        footer={null}
        width={400}
        onCancel={handleCloseModal}
      >
        <Typography.Text>
          {t("reports.deleteConfirmation", { name: currentReport.name })}
        </Typography.Text>

        <Row justify="space-between" style={{ marginTop: "20px" }}>
          <Col>
            <Button onClick={handleCloseModal}>{t("cancel")}</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              danger
              htmlType="submit"
              loading={isLoading}
              onClick={() => handleDelete(currentReport.id)}
            >
              {t("yes")}
            </Button>
          </Col>
        </Row>
      </Modal>

      <Button
        danger
        type="primary"
        icon={<DeleteOutlined />}
        onClick={handleOpenModal}
      >
        {t("delete")}
      </Button>
    </>
  );
};

export default DeleteOnlineTrainingReportModal;
