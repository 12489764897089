import { FC } from "react";

import { Space, InputNumber, Button, Typography } from "antd";
import {
  CheckCircleOutlined,
  CloseOutlined,
  EllipsisOutlined
} from "@ant-design/icons";

import { EventEmployeeDetails } from "@models/event";

interface Props {
  employee: EventEmployeeDetails;
  value: number | undefined;
  canEvaluateOrExclude: boolean;
  isExcluded: boolean;
  isEvaluated: boolean;
  isDayOff: boolean;
  isDateExcluded: boolean;
  onChange: (value: number | null | undefined | string) => void;
  onEvaluateOrExclude: () => void;
}

const DateColumnBody: FC<Props> = (props) => {
  if (props.isDayOff) {
    return <CloseOutlined />;
  }

  if (props.isExcluded && props.value == undefined) {
    return (
      <Typography.Text strong type="danger">
        E
      </Typography.Text>
    );
  }

  return (
    <Space>
      <InputNumber
        min={0}
        max={12}
        value={props.value}
        disabled={props.isExcluded || props.isEvaluated}
        onChange={props.onChange}
      />
      {props.canEvaluateOrExclude && (
        <Button
          disabled={props.isExcluded || props.isEvaluated}
          type="primary"
          size="small"
          shape="circle"
          icon={<EllipsisOutlined />}
          onClick={props.onEvaluateOrExclude}
        />
      )}
    </Space>
  );
};

export default DateColumnBody;
