import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

import { Button, notification } from "antd";
import { FileOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import DatadumpReportTable from "@components/common/reports/DatadumpReportTable";
import { AppPageHeader } from "@components/ui/AppPageHeader";

import {
  getContractorDumps,
  generateContractorDump,
  generateCommissioningContractorDump
} from "@api/datadumpApi";

import { UserContext } from "@contexts/UserContext";

const DatadumpPage = () => {
  const [t] = useTranslation();
  const pageTitle = t("reports.datadump");
  const { userEntityId: contractorId } = useContext(UserContext);

  const dataDumps = useQuery(
    ["getAdminDump"],
    () => getContractorDumps(contractorId).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  );

  const generateDump = useMutation(() => generateContractorDump(contractorId), {
    onSuccess: () => {
      dataDumps.refetch();
      notification.success({ message: t("reports.datadumpSuccess") });
    }
  });

  const generateCommissioningDump = useMutation(
    () => generateCommissioningContractorDump(contractorId),
    {
      onSuccess: () => {
        dataDumps.refetch();
        notification.success({ message: t("reports.datadumpSuccess") });
      }
    }
  );

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: "/admin/reports/performance"
          }
        ]}
        extra={
          <>
            <Button
              onClick={() => generateDump.mutate()}
              icon={<FileOutlined />}
              type="primary"
              loading={generateDump.isLoading}
            >
              {t("reports.datadumpGenerate")}
            </Button>
            <Button
              onClick={() => generateCommissioningDump.mutate()}
              icon={<FileOutlined />}
              type="primary"
              loading={generateCommissioningDump.isLoading}
            >
              {t("reports.datadumpGenerateCommissioning")}
            </Button>
          </>
        }
      />

      <DatadumpReportTable
        dataDumpsRefetch={dataDumps.refetch}
        reports={dataDumps.data}
        isLoading={dataDumps.isLoading}
        getDownloadLink={(report) =>
          `/api/contractor/${contractorId}/employee/dump/${report.blobFileName}`
        }
      />
    </>
  );
};

export default DatadumpPage;
