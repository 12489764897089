import { useTranslation } from "react-i18next";

import {
  CheckOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  ToolOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import { Card, Row, Space } from "antd";

import { DuplicatedHeadcountTypeKey } from "@models/event";

const getHeadcountIcon = (headcountType: DuplicatedHeadcountTypeKey) => {
  switch (headcountType) {
    case DuplicatedHeadcountTypeKey.HEADCOUNT:
      return (
        <span style={{ fontSize: "32px", color: "rgb(183, 42, 39)" }}>
          <TeamOutlined />
        </span>
      );
    case DuplicatedHeadcountTypeKey.ASSESSMENT:
      return (
        <span style={{ fontSize: "32px", color: "rgb(242, 200, 15)" }}>
          <CheckOutlined />
        </span>
      );
    case DuplicatedHeadcountTypeKey.COMMISSIONING_ASSESSMENT:
      return (
        <span style={{ fontSize: "32px", color: "rgb(242, 200, 15)" }}>
          <CheckOutlined />
        </span>
      );
    case DuplicatedHeadcountTypeKey.TRAINING:
      return (
        <span style={{ fontSize: "32px", color: "rgb(1, 184, 170)" }}>
          <ThunderboltOutlined />
        </span>
      );
    case DuplicatedHeadcountTypeKey.COMMISSIONING_TRAINING:
      return (
        <span style={{ fontSize: "32px", color: "rgb(1, 184, 170)" }}>
          <ThunderboltOutlined />
        </span>
      );
    case DuplicatedHeadcountTypeKey.PRE_SCREEINING:
      return (
        <span style={{ fontSize: "32px", color: "rgb(166, 105, 153)" }}>
          <UsergroupAddOutlined />
        </span>
      );
    case DuplicatedHeadcountTypeKey.OJT_TRAINING:
      return (
        <span style={{ fontSize: "32px", color: "rgb(6, 154, 255)" }}>
          <ToolOutlined />
        </span>
      );
  }
};

const getCardLabel = (headcountType: DuplicatedHeadcountTypeKey) => {
  switch (headcountType) {
    case DuplicatedHeadcountTypeKey.ASSESSMENT:
      return `reports.headcountTypes.assessment`;
    case DuplicatedHeadcountTypeKey.COMMISSIONING_ASSESSMENT:
      return `reports.headcountTypes.commissioningAssessment`;
    case DuplicatedHeadcountTypeKey.TRAINING:
      return `reports.headcountTypes.training`;
    case DuplicatedHeadcountTypeKey.COMMISSIONING_TRAINING:
      return `reports.headcountTypes.commissioningTraining`;
    case DuplicatedHeadcountTypeKey.PRE_SCREEINING:
      return `reports.headcountTypes.prescreening`;
    case DuplicatedHeadcountTypeKey.OJT_TRAINING:
      return `reports.headcountTypes.ojtTraining`;
    case DuplicatedHeadcountTypeKey.HEADCOUNT:
      return `reports.headcountTypes.headcount`;
    default:
      return "";
  }
};

interface Props {
  headcountType: DuplicatedHeadcountTypeKey;
  value?: number;
}

export const DuplicatedHeadcountCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Card
      bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Row align="middle">
        <Space
          direction="vertical"
          style={{ width: "100%", height: "100%" }}
          align="center"
        >
          {getHeadcountIcon(props.headcountType)}
          <span>{props.value || "-"}</span>
          <span>{t(getCardLabel(props.headcountType))}</span>
        </Space>
      </Row>
    </Card>
  );
};
