import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as yup from "yup";

import { Button, Col, Form, Input, Row, Space } from "antd";

import { EmployeeDetails } from "@models/employee";

interface FormValues {
  facilityAccessStandard: string;
  facilityAccessAdditional: string;
  specialAccessJustification: string;
  waterTreatmentPlant: string;
  residentialAreas: string;
}

interface Props {
  employee?: EmployeeDetails;

  isLoading?: boolean;
  submitText?: string;
  cancelText?: string;

  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

export const EmployeeFacilityAccessForm: React.FC<Props> = (props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      facilityAccessStandard: "",
      facilityAccessAdditional: "",
      specialAccessJustification: "",
      waterTreatmentPlant: "",
      residentialAreas: ""
    },
    validationSchema: yup.object().shape({
      facilityAccessStandard: yup.string().required(t("validation.required")),
      facilityAccessAdditional: yup.string().required(t("validation.required")),
      specialAccessJustification: yup
        .string()
        .when("facilityAccessAdditional", {
          is: "none",
          then: yup.string().optional(),
          otherwise: yup.string().required(t("validation.required"))
        }),
      waterTreatmentPlant: yup.string().required(t("validation.required")),
      residentialAreas: yup.string().required(t("validation.required"))
    }),
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  useEffect(() => {
    if (props.employee) {
      const e = props.employee;

      formik.setValues({
        facilityAccessStandard: e.facilityAccessStandard,
        facilityAccessAdditional: e.facilityAccessAdditional,
        residentialAreas: e.residentialAreas,
        specialAccessJustification: e.specialAccessJustification,
        waterTreatmentPlant: e.waterTreatmentPlant
      });
    }

    // eslint-disable-next-line
  }, [props.employee]);

  const getValidateStatus = (fieldName: keyof FormValues) =>
    formik.touched[fieldName] && formik.errors[fieldName] ? "error" : "";
  const getHelp = (fieldName: keyof FormValues) =>
    formik.touched[fieldName] && formik.errors[fieldName];

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label={t("employees.facilityAccessStandard")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("facilityAccessStandard")}
            help={getHelp("facilityAccessStandard")}
          >
            <Input
              name="facilityAccessStandard"
              value={formik.values.facilityAccessStandard}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("employees.facilityAccessAdditional")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("facilityAccessAdditional")}
            help={getHelp("facilityAccessAdditional")}
          >
            <Input
              name="facilityAccessAdditional"
              value={formik.values.facilityAccessAdditional}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("employees.specialAccessJustification")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("specialAccessJustification")}
            help={getHelp("specialAccessJustification")}
          >
            <Input
              name="specialAccessJustification"
              value={formik.values.specialAccessJustification}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("employees.waterTreatmentPlant")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("waterTreatmentPlant")}
            help={getHelp("waterTreatmentPlant")}
          >
            <Input
              name="waterTreatmentPlant"
              value={formik.values.waterTreatmentPlant}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("employees.residentialAreas")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("residentialAreas")}
            help={getHelp("residentialAreas")}
          >
            <Input
              name="residentialAreas"
              value={formik.values.residentialAreas}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Row justify="end">
            <Col>
              <Space align="end">
                <Button
                  htmlType="button"
                  onClick={() => props.onCancel && props.onCancel()}
                >
                  {props.cancelText ? props.cancelText : t("cancel")}
                </Button>

                <Button
                  htmlType="submit"
                  type="primary"
                  loading={props.isLoading}
                >
                  {props.submitText ? props.submitText : t("submit")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
