import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Typography } from "antd";
import { WhatNewTemplate } from "@components/common/WhatNew";

import { enContractorWhatNew } from "@utils/whatNew/enContractor";
import { ruContractorWhatNew } from "@utils/whatNew/ruContractor";

export const ContractorWhatNew: FC = () => {
  const { t, i18n } = useTranslation();
  const { Title } = Typography;

  return (
    <Space direction="vertical" style={{ width: "100%", marginTop: "50px" }}>
      <Title>{t("whatsNew")}</Title>
      <WhatNewTemplate
        whatNews={
          i18n.language === "en" ? enContractorWhatNew : ruContractorWhatNew
        }
      />
    </Space>
  );
};
