import { useTranslation } from "react-i18next";

import Column from "antd/lib/table/Column";

import { CloseOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table } from "antd";

import { Country } from "@models/countryCity";
import { EmployeeDetails } from "@models/employee";

import useDateFormatter from "@hooks/useDateFormat";

interface Props {
  countries: Country[];
  selectedEmployees: EmployeeDetails[];
  onEmployeeEdit: (employee: EmployeeDetails) => void;
  onEmployeeDelete: (employeeId: number) => void;
}

export const EventEmployeesDataTable: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();
  const { formatter } = useDateFormatter();

  return (
    <>
      <Table
        bordered
        locale={{ emptyText: t("noData") }}
        rowKey="id"
        loading={false}
        pagination={false}
        dataSource={props.selectedEmployees}
        onRow={(employee) => ({
          onDoubleClick: (e) => props.onEmployeeEdit(employee)
        })}
        scroll={{ x: 1600 }}
        style={{ marginTop: "32px" }}
      >
        <Column
          title={t("employees.firstName")}
          dataIndex="firstName"
          width="240px"
        />
        <Column
          title={t("employees.lastName")}
          dataIndex="lastName"
          width="240px"
        />
        <Column
          title={t("employees.middleName")}
          dataIndex="middleName"
          width="240px"
        />
        <Column
          title={t("employees.firstNameCyrillic")}
          dataIndex="firstNameCyrillic"
          width="240px"
        />
        <Column
          title={t("employees.lastNameCyrillic")}
          dataIndex="lastNameCyrillic"
          width="240px"
        />
        <Column
          title={t("employees.middleNameCyrillic")}
          dataIndex="middleNameCyrillic"
          width="240px"
        />
        <Column
          title={t("employees.jobTitle")}
          dataIndex="position"
          width="300px"
        />
        <Column
          title={t("employees.mouCategory")}
          render={(employee) => t(`mouCategory.${employee.mouCategory}`)}
          width="300px"
        />
        <Column
          title={t("employees.phoneNumber")}
          dataIndex="phoneNumber"
          width="200px"
        />
        <Column title={t("employees.iin")} dataIndex="iin" width="200px" />
        <Column
          title={t("employees.birthDate")}
          render={(employee) => formatter(employee.birthDate, "full-date")}
          width="300px"
        />
        <Column
          title={t("employees.citizenship")}
          render={(employee) => {
            const country = props.countries.find(
              (c) => c.id === employee.citizenshipId
            );

            return i18n.language === "en" ? country?.nameEn : country?.nameRu;
          }}
          width="240px"
        />
        <Column
          title={t("employees.residenceCountry")}
          render={(employee) => {
            const country = props.countries.find(
              (c) => c.id === employee.residenceCountryId
            );

            return i18n.language === "en" ? country?.nameEn : country?.nameRu;
          }}
          width="240px"
        />
        <Column
          title={t("employees.residenceRegion")}
          dataIndex="residenceRegion"
          width="300px"
        />
        <Column
          title={t("employees.lineSupervisorBadgeNumber")}
          dataIndex="lineSupervisorBadgeNumber"
          width="240px"
        />
        <Column
          title={t("employees.workLocationArea")}
          dataIndex="workLocationArea"
          width="300px"
        />
        <Column
          title={t("employees.facilityAccessStandard")}
          dataIndex="facilityAccessStandard"
          width="300px"
        />
        <Column
          title={t("employees.facilityAccessAdditional")}
          dataIndex="facilityAccessAdditional"
          width="300px"
        />
        <Column
          title={t("employees.specialAccessJustification")}
          dataIndex="specialAccessJustification"
          width="300px"
        />
        <Column
          title={t("employees.waterTreatmentPlant")}
          dataIndex="waterTreatmentPlant"
          width="300px"
        />
        <Column
          title={t("employees.residentialAreas")}
          dataIndex="residentialAreas"
          width="240px"
        />
        <Column
          title={t("employees.firstNameCompanyRep")}
          dataIndex="firstNameCompanyRep"
          width="240px"
        />
        <Column
          title={t("employees.lastNameCompanyRep")}
          dataIndex="lastNameCompanyRep"
          width="240px"
        />
        <Column
          title={t("employees.phoneNumberCompanyRep")}
          dataIndex="phoneNumberCompanyRep"
          width="240px"
        />
        <Column
          title={t("employees.contractor")}
          render={(employee) => employee.contractor.name}
          width="240px"
        />
        <Column
          title={t("actions")}
          width="100px"
          render={(employee) => (
            <Popconfirm
              title={t("events.removeEmployeePrompt", {
                name: `${employee.firstName} ${employee.lastName}`
              })}
              cancelText={t("cancel")}
              okText={t("yes")}
              onConfirm={() => {
                props.onEmployeeDelete(employee.id);
              }}
            >
              <Button danger>
                <CloseOutlined />
              </Button>
            </Popconfirm>
          )}
          fixed="right"
        />
      </Table>
    </>
  );
};
