import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Image, Space, Typography } from "antd";

import enAboutUsImage1 from "@assets/images/enAboutUs_1.png";
import enAboutUsImage2 from "@assets/images/enAboutUs_2.png";
import ruAboutUsImage1 from "@assets/images/ruAboutUs_1.png";
import ruAboutUsImage2 from "@assets/images/ruAboutUs_2.png";

export const AboutUs: FC = () => {
  const { t, i18n } = useTranslation();
  const { Title } = Typography;

  return (
    <Space direction="vertical" style={{ width: "100%", marginTop: "50px" }}>
      <Title>{t("aboutUs")}</Title>
      <Image
        src={i18n.language === "en" ? enAboutUsImage1 : ruAboutUsImage1}
        width={"100%"}
      ></Image>
      <Image
        src={i18n.language === "en" ? enAboutUsImage2 : ruAboutUsImage2}
        width={"100%"}
      ></Image>
    </Space>
  );
};
