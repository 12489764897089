import { FormSettings } from "@models/migrations";

const i18nRoute = "migrations.form";

// list of all possible general migration info-related fields
const eventFields = [
  {
    name: "trainingCenterId",
    type: "select",
    label: `${i18nRoute}.trainingCenter`,
    error: "TrainingCenterId"
  },
  {
    name: "eventName",
    type: "input",
    label: `${i18nRoute}.eventName`,
    error: "EventName"
  },
  { name: "numberOfSeats", type: "input", label: `${i18nRoute}.numberOfSeats` },
  { name: "minimumSeats", type: "input", label: `${i18nRoute}.minimumSeats` },
  {
    name: "academicHours",
    type: "input",
    label: `${i18nRoute}.academicHours`,
    error: "AcademicHours"
  },
  {
    name: "specialityId",
    type: "select",
    label: `${i18nRoute}.discipline`,
    error: "SpecialityId"
  },
  {
    name: "language",
    type: "select",
    label: `${i18nRoute}.language`,
    error: "Language"
  },
  { name: "csrPhase", type: "select", label: `${i18nRoute}.csrPhase` },
  {
    name: "description",
    type: "input",
    label: `${i18nRoute}.description`,
    error: "Description"
  },
  {
    name: "start",
    type: "datePicker",
    label: `${i18nRoute}.start`,
    error: "Start"
  },
  { name: "end", type: "datePicker", label: `${i18nRoute}.end`, error: "End" },
  {
    name: "standard",
    type: "input",
    label: `${i18nRoute}.standard`,
    error: "Standard"
  },
  { name: "hoursPerDay", type: "input", label: `${i18nRoute}.hoursPerDay` },
  {
    name: "cityId",
    type: "select",
    label: `${i18nRoute}.location`,
    error: "CityId"
  },
  { name: "instructorId", type: "select", label: `${i18nRoute}.instructorId` }
];

// list of all possible participants-related fields
// select prop is used for rendering <Select> elements
// hidden prop is used for rendering hidden fields needed to be passed from parsing

const participantsFields = [
  { name: "firstName", disabled: true },
  { name: "lastName", disabled: true },
  { name: "iin", disabled: true },
  { name: "subcontractor", select: true },
  { name: "practicalGrade" },
  { name: "practicalStatus", select: true },
  { name: "theoreticalGrade" },
  { name: "theoreticalStatus", select: true },
  { name: "isPassed", select: true, error: "IsPassed" },
  { name: "failReasonType", select: true, error: "FailReasonType" },
  { name: "failReason", error: "FailReason" },
  { name: "recommendation", select: true, error: "Recommendation" },
  { name: "employeeId", select: true, error: "EmployeeId" }
];

export const generateFormSettings = (settings: string[]) => {
  const result = settings.reduce(
    (acc, key) => {
      const eventFiltered = eventFields.filter((field) => field.name === key);
      const participantsFiltered = participantsFields.filter(
        (field) => field.name === key
      );

      return {
        event: [...acc.event, ...eventFiltered],
        participants: [...acc.participants, ...participantsFiltered]
      } as FormSettings;
    },
    { event: [], participants: [] } as FormSettings
  );

  return result;
};

const undefinedMigration = generateFormSettings([]);

// CTN-932
const preScreeningMigration = generateFormSettings([
  "trainingCenterId",
  "eventName",
  "numberOfSeats",
  "specialityId",
  "language",
  "cityId",
  "csrPhase",
  "description",
  "standard",
  "start",
  "end",
  "firstName",
  "lastName",
  "iin",
  "employeeId",
  "practicalGrade",
  "theoreticalGrade",
  "isPassed",
  "failReasonType",
  "failReason",
  "other"
]);

// CTN-1002
const commTrainingMigration = generateFormSettings([
  "trainingCenterId",
  "instructorId",
  "eventName",
  "numberOfSeats",
  "minimumSeats",
  "academicHours",
  "hoursPerDay",
  "specialityId",
  "language",
  "cityId",
  "standard",
  "description",
  "start",
  "end",
  "firstName",
  "lastName",
  "iin",
  "employeeId",
  "theoreticalStatus",
  "theoreticalGrade",
  "practicalGrade",
  "practicalStatus",
  "isPassed",
  "failReasonType",
  "failReason",
  "recommendation"
]);

// CTN-1003
const trainingMigration = generateFormSettings([
  "trainingCenterId",
  "instructorId",
  "eventName",
  "numberOfSeats",
  "minimumSeats",
  "academicHours",
  "hoursPerDay",
  "specialityId",
  "language",
  "cityId",
  "csrPhase",
  "standard",
  "description",
  "start",
  "end",
  "firstName",
  "lastName",
  "iin",
  "employeeId",
  "practicalGrade",
  "theoreticalGrade",
  "isPassed",
  "failReasonType",
  "failReason"
]);

// CTN-1004
const commAssessmentMigration = generateFormSettings([
  "trainingCenterId",
  "instructorId",
  "eventName",
  "numberOfSeats",
  "minimumSeats",
  "academicHours",
  "hoursPerDay",
  "specialityId",
  "language",
  "cityId",
  "standard",
  "description",
  "start",
  "end",
  "firstName",
  "lastName",
  "iin",
  "employeeId",
  "theoreticalGrade",
  "practicalGrade",
  "theoreticalStatus",
  "practicalStatus",
  "isPassed",
  "failReasonType",
  "failReason",
  "recommendation"
]);

// CTN-1005
const assessmentMigration = generateFormSettings([
  "trainingCenterId",
  "instructorId",
  "eventName",
  "numberOfSeats",
  "minimumSeats",
  "academicHours",
  "specialityId",
  "language",
  "csrPhase",
  "standard",
  "description",
  "start",
  "end",
  "cityId",
  "firstName",
  "lastName",
  "iin",
  "employeeId",
  "theoreticalGrade",
  "practicalGrade",
  "isPassed",
  "failReasonType",
  "failReason"
]);

// CTN-1006
const ojtTrainingMigration = generateFormSettings([
  "trainingCenterId",
  "instructorId",
  "eventName",
  "numberOfSeats",
  "academicHours",
  "hoursPerDay",
  "specialityId",
  "language",
  "standard",
  "description",
  "start",
  "end",
  "cityId",
  "firstName",
  "lastName",
  "iin",
  "employeeId",
  "isPassed",
  "failReasonType",
  "failReason"
]);

export const migrationTypes = {
  undefinedMigration,
  commTrainingMigration,
  trainingMigration,
  ojtTrainingMigration,
  commAssessmentMigration,
  assessmentMigration,
  preScreeningMigration
};
