import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Section, SubSection } from "@components/Sections";
import { MemberTable } from "@components/common/MemberTable";

import { MemberStatus } from "@models/member";

import { subcontractorMemberApi } from "@api/subcontractorMemberApi";

import { useDefaultQuery } from "@hooks/useDefaultQuery";

interface MembersSectionProps {
  contractorId: number;
  subcontractorId: number;
}

const MembersSection: FC<MembersSectionProps> = (props) => {
  const [t] = useTranslation();
  const { contractorId, subcontractorId } = props;

  const contractorMembers = useDefaultQuery(
    ["getSubontractorMembers", contractorId],
    () =>
      subcontractorMemberApi
        .getSubcontractorMembers({ contractorId, subcontractorId })
        .then((res) => res.data)
  );

  const activeMembers = contractorMembers.data?.filter(
    (member) => member.status === MemberStatus.ACTIVE
  );
  const inactiveMembers = contractorMembers.data?.filter(
    (member) => member.status === MemberStatus.INACTIVE
  );
  const invitedMembers = contractorMembers.data?.filter(
    (member) => member.status === MemberStatus.INVITED
  );

  return (
    <Section>
      <SubSection>
        <MemberTable
          title={t("members.activeMembers")}
          members={activeMembers}
        />
      </SubSection>
      <SubSection>
        <MemberTable
          title={t("members.inactiveMembers")}
          members={inactiveMembers}
        />
        <br />
      </SubSection>
      <SubSection>
        <MemberTable
          title={t("members.invitedMembers")}
          members={invitedMembers}
        />
      </SubSection>
    </Section>
  );
};

export default MembersSection;
