import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import {
  Card,
  Col,
  Row,
  Button,
  Input,
  Typography,
  Form,
  notification
} from "antd";

import userApi from "@api/userApi";

import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";

const { Title, Text } = Typography;

interface PageParams {
  token: string;
}

interface FormValues {
  token: string;
  password: string;
  confirmPassword: string;
}

const ResetPasswordTokenPage: React.FC = () => {
  const [t] = useTranslation();
  const { loadUser } = useContext(UserContext);
  const { token } = useParams<PageParams>();
  const history = useHistory();
  const mutation = useValidatedMutation<FormValues>({
    mutationFunction: (values: FormValues) =>
      userApi.resetPassword(values).then((res) => res.data),
    onSuccess: async (data: any) => {
      notification.success({
        message: t("resetPassword.passwordChangeSuccess")
      });
      localStorage.setItem("CT_PORTAL_USER_TOKEN", data.apiToken);
      await loadUser();
      history.replace("/profile");
    },
    onError: (error) => {
      formik.setErrors(error);
    }
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      token,
      password: "",
      confirmPassword: ""
    },
    onSubmit: (values) => mutation.mutate(values)
  });

  return (
    <div className="guest-pages-bg">
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col span={12}>
          <Card>
            <Card.Meta
              style={{ marginBottom: 16 }}
              title={<Title level={3}> {t("resetPassword.title")}</Title>}
              description={
                <Text style={{ fontSize: 14 }} disabled>
                  {t("resetPassword.enterNewPassword")}
                </Text>
              }
            />
            <Form layout="vertical" onFinish={formik.handleSubmit}>
              <Form.Item
                label={t("newPassword")}
                labelCol={{ span: 24 }}
                validateStatus={formik.errors.password && "error"}
                help={formik.errors.password}
              >
                <Input.Password
                  name="password"
                  size="large"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={t("confirmPassword")}
                labelCol={{ span: 24 }}
                validateStatus={formik.errors.confirmPassword && "error"}
                help={formik.errors.confirmPassword}
              >
                <Input.Password
                  name="confirmPassword"
                  size="large"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                />
              </Form.Item>
              <Form.Item noStyle>
                <Row justify="end" align="middle">
                  <Button type="primary" htmlType="submit">
                    {t("resetPassword.next")}
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPasswordTokenPage;
