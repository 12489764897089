import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useQuery } from "react-query";

import { Card, Typography, Row, Col, notification } from "antd";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import PageMeta from "@components/PageMeta";
import RequestReplyForm from "@components/common/requests/RequestReplyForm";
import RequestReply from "@components/common/requests/RequestReply";
import DownloadLink from "@components/common/DownloadLink";

import { RequestReplyData } from "@models/requests";

import requestsApi from "@api/requestsApi";

import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import useDateFormatter from "@hooks/useDateFormat";

export const RequestViewPage: React.FC = () => {
  const [t] = useTranslation();
  const params = useParams<{ id: string }>();
  const { user } = useContext(UserContext);
  const contractorId = user?.userRoles[0].entityId || 0;
  const { formatter } = useDateFormatter();

  const { data, refetch } = useQuery(["getContractorRequest", params.id], () =>
    requestsApi
      .getContractorRequest(contractorId, Number(params.id))
      .then((res) => res.data)
  );

  const replyRequestMutation = useValidatedMutation({
    mutationFunction: (values: RequestReplyData) => {
      return requestsApi.requestContractorReply(
        contractorId,
        Number(params.id),
        values
      );
    },
    onSuccess(_, values) {
      notification.success({
        message: t("requests.replySuccess")
      });
      refetch();
    },
    onError(errors) {
      if (errors.attachmentFile) {
        notification.error({
          message: errors.attachmentFile
        });
      }
    }
  });

  const downloadURL =
    data && data.attachmentFileId
      ? `/api/contractor/${contractorId}/ticket/attachmentFile/${data.attachmentFileId}/download`
      : "";

  return (
    <>
      <PageMeta title={t("requests.list")} />

      <AppPageHeader
        title={t("requests.requestTitle", {
          tcName: data?.trainingCenter.name
        })}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "contractor" },
          { breadcrumbName: t("requests.requests"), path: "requests" },
          { breadcrumbName: data?.subject || "...", path: params.id }
        ]}
        extra={[
          <span key="request-id">
            {t("requests.requestId")}: {data?.id}
          </span>,
          <span key="request-date">
            {t("requests.requestDate")} {formatter(data?.createdAt || "")}
          </span>
        ]}
      />

      <Card
        title={t("requests.requestSubject", { subject: data?.subject })}
        extra={
          data && data.attachmentFileId ? (
            <DownloadLink
              size="middle"
              url={downloadURL}
              fileName={data.attachmentFile.fileName}
            />
          ) : undefined
        }
      >
        {data?.text}
      </Card>

      <Row gutter={[8, 24]} style={{ padding: "24px 0" }}>
        <Col span="24">
          <Typography.Title level={3}>{t("requests.replies")}</Typography.Title>
        </Col>

        {data?.comments.map((comment) => {
          const isContractor = comment.author.role === "contractor";

          return (
            <RequestReply
              reply={comment}
              isAuthor={isContractor}
              attachmentLinkGenerator={(fileId) =>
                `/api/contractor/${contractorId}/ticket/attachmentFile/${fileId}/download`
              }
            />
          );
        })}
      </Row>

      <RequestReplyForm
        onSubmit={(data) => replyRequestMutation.mutate(data)}
        isLoading={replyRequestMutation.isLoading}
      />
    </>
  );
};
