import picture36 from "@assets/images/user-guide/1.36.png";

export const migrations = [
  {
    title: "Migrations",
    html: `<p dir="ltr">
    This functionality lets TCO users with relevant permissions to add a new
    migration within the Craft Training portal. Migration represents historical
    event data (including craft employee names, their grades etc.) that needs
    to be uploaded in bulk to the portal.
</p>
<br/>
<p dir="ltr">
    Once you press Migrations tab on Panel menu you will be redirected to
    Migrations page with the following information:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            New migration button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Table with the list of Migrations
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Delete button in Actions column
        </p>
    </li>
</ol>
<p dir="ltr">
    Page example:
</p>
<br/>
<p dir="ltr">
    Picture 1.36
</p>
<p dir="ltr">
    <img
        src=${picture36}
        width="624"
        height="774.9626884460449"
    />
</p>
`
  },
  {
    title: "How to create a new migration?",
    html: `<p dir="ltr">
    To create a new migration in CT portal TCO user must:
</p>
<p dir="ltr">
    -Press New migration button;
</p>
<p dir="ltr">
    -Choose event type;
</p>
<p dir="ltr">
    -Download, fill out and upload a corresponding template OR upload a proper
    excel file;
</p>
<p dir="ltr">
    -View and update migration information if necessary;
</p>
<p dir="ltr">
    -Upload (e.g. create) migration;
</p>
<p dir="ltr">
    -Cancel and Create buttons.
</p>
`
  },
  {
    title: "How to delete migration?",
    html: `<p dir="ltr">
    To create a new migration in CT portal TCO user must:
</p>
<p dir="ltr">
    -Navigate to Migrations page
</p>
<p dir="ltr">
    -Choose Migration
</p>
<p dir="ltr">
    -Press Delete button
</p>
`
  }
];
