export const ruCertificates = {
  search: "Поиск сертификатов",

  verification: "Проверка сертификата",
  certNumber: "Номер сертификата",
  iin: "ИИН",
  ctpNumber: "CTP #",
  discipline: "Дисциплина",
  issuingBody: "Орган-эмитент",
  level: "Уровень",
  academicHours: "Академические часы",
  completionDate: "Дата завершения",
  company: "Компания/Клиент",
  projectName: "Название проекта/Область",

  certNotFound: "Сертификат не найден",
  checkDataOrEnterNew:
    "Проверьте правильность введенных данных или введите новые",

  downloadCert: "Скачать сертификат"
};
