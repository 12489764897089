import { useTranslation } from "react-i18next";
import { useContext } from "react";

import moment from "moment";
import FormItem from "antd/lib/form/FormItem";
import { useFormik } from "formik";

import { Button, Col, DatePicker, Row, Space } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { CompetencyLevel } from "@models/competencyLevel";
import {
  FailReasonType,
  EventEmployeeGradeType,
  CommissioningEventRecommendation,
  EventStatus,
  EventType
} from "@models/event";
import { Discipline } from "@models/discipline";
import { TrainingCenter } from "@models/trainingCenter";
import { Contractor } from "@models/contractor";

import { COMMISSIOINING_COMPETENCY_LEVEL_SELECT_OPTIONS } from "@constants/competencyLevelSelectOptions";
import { UserContext } from "@contexts/UserContext";

export const RECOMMENDATION_OPTIONS = [
  {
    label: "events.recommendationStatus.1",
    value: CommissioningEventRecommendation.NOT_READY_FOR_UPSKILLING
  },
  {
    label: "events.recommendationStatus.2",
    value: CommissioningEventRecommendation.UPSKILLING_TO_JUNIOR_TECHNICIAN
  },
  {
    label: "events.recommendationStatus.3",
    value: CommissioningEventRecommendation.UPSKILLING_TO_TECHNICIAN
  },
  {
    label: "events.recommendationStatus.4",
    value: CommissioningEventRecommendation.UPSKILLING_TO_SENIOR_TECHNICIAN
  },
  {
    label: "events.recommendationStatus.5",
    value: CommissioningEventRecommendation.COMPLETED_NOT_NEED_FOR_UPSKILLING
  }
];

export const FAIL_REASON_OPTIONS = [
  {
    value: FailReasonType.NO_SHOW,
    label: `events.failReason.${FailReasonType.NO_SHOW}`
  },
  {
    value: FailReasonType.ATTENDANCE,
    label: `events.failReason.${FailReasonType.ATTENDANCE}`
  },
  {
    value: FailReasonType.UNDER_THE_INFLUENCE,
    label: `events.failReason.${FailReasonType.UNDER_THE_INFLUENCE}`
  },
  {
    value: FailReasonType.PERFORMANCE,
    label: `events.failReason.${FailReasonType.PERFORMANCE}`
  },
  {
    value: FailReasonType.OTHER,
    label: `events.failReason.${FailReasonType.OTHER}`
  },
  {
    value: FailReasonType.REQUIRES_TRAINING,
    label: `events.failReason.${FailReasonType.REQUIRES_TRAINING}`
  },
  {
    value: FailReasonType.NO_PRACTICE,
    label: `events.failReason.${FailReasonType.NO_PRACTICE}`
  }
];

const TRAINING_GRADE_OPTIONS = [
  {
    label: `events.grade.1`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `events.grade.2`,
    value: EventEmployeeGradeType.PASSED
  }
];

interface FormValues {
  dateStart?: string;
  dateEnd?: string;
  specialityIds?: number[];
  trainingCenterIds?: number[];
  contractorIds?: number[];
  competencyLevels?: CompetencyLevel[];
  recommendations?: CommissioningEventRecommendation[];
  statuses?: EventStatus[];
  project?: number;
  failReasons?: FailReasonType[];
  overallGrade?: EventEmployeeGradeType;
  theoreticalStatuses?: number[];
  practicalStatuses?: number[];
}

interface Props {
  // TODO: define Filter type
  initialFilter: FormValues;
  eventType: EventType;
  disciplines: Discipline[];
  trainingCenters: TrainingCenter[];
  contractors: Contractor[];
  projects: { id: number; name: string }[];
  isLoading: boolean;
  onSubmit: (values: FormValues) => void;
}

export const CommissioningReportFilterForm: React.FC<Props> = (props) => {
  const { userRole } = useContext(UserContext);

  const { i18n, t } = useTranslation();

  const disciplinesSelectOptions = props.disciplines.map((d) => ({
    value: Number(d.id),
    label: i18n.language === "en" ? d.nameEn : d.nameRu
  }));

  const trainingCentersSelectOptions = props.trainingCenters.map((t) => ({
    value: Number(t.id),
    label: t.name
  }));

  const contractorsSelectOptions = props.contractors.map((c) => ({
    value: Number(c.id),
    label: c.name
  }));

  const eventStatusesSelectOptions = [
    {
      value: EventStatus.INPROGRESS,
      label: t(`events.statuses.${EventStatus.INPROGRESS}`)
    },
    {
      value: EventStatus.COMPLETED,
      label: t(`events.statuses.${EventStatus.COMPLETED}`)
    },
    {
      value: EventStatus.EVALUATED,
      label: t(`events.statuses.${EventStatus.EVALUATED}`)
    }
  ];

  const projectsSelectOptions = props.projects.map((p) => ({
    value: p.id,
    label: p.name
  }));

  const formik = useFormik<FormValues>({
    initialValues: {
      ...props.initialFilter,
      specialityIds: props.initialFilter.specialityIds?.map((id) => Number(id)),
      trainingCenterIds: props.initialFilter.trainingCenterIds?.map((id) =>
        Number(id)
      ),
      contractorIds: props.initialFilter.contractorIds?.map((id) => Number(id)),
      competencyLevels: props.initialFilter.competencyLevels?.map((id) =>
        Number(id)
      ),
      recommendations: props.initialFilter.recommendations?.map((id) =>
        Number(id)
      ),
      statuses: props.initialFilter.statuses?.map((id) => Number(id)),
      project:
        props.initialFilter.project && Number(props.initialFilter.project),
      failReasons: props.initialFilter.failReasons?.map((id) => Number(id)),
      overallGrade:
        props.initialFilter.overallGrade &&
        Number(props.initialFilter.overallGrade),
      theoreticalStatuses: props.initialFilter.theoreticalStatuses?.map((id) =>
        Number(id)
      ),
      practicalStatuses: props.initialFilter.practicalStatuses?.map((id) =>
        Number(id)
      )
    },
    onSubmit: (values) => {
      props.onSubmit(values);
    }
  });

  return (
    <>
      <Row gutter={8} wrap>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("dateFrom")}
            validateStatus={formik.errors.dateStart && "error"}
            help={formik.errors.dateStart}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(_, value) => formik.setFieldValue("dateStart", value)}
              disabledDate={(date) =>
                date && date >= moment(formik.values.dateEnd, "YYYY-MM-DD")
              }
              value={
                formik.values.dateStart
                  ? moment(formik.values.dateStart, "YYYY-MM-DD")
                  : undefined
              }
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("dateTo")}
            validateStatus={formik.errors.dateEnd && "error"}
            help={formik.errors.dateEnd}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(_, value) => formik.setFieldValue("dateEnd", value)}
              disabledDate={(date) =>
                date && date <= moment(formik.values.dateStart, "YYYY-MM-DD")
              }
              value={
                formik.values.dateEnd
                  ? moment(formik.values.dateEnd, "YYYY-MM-DD")
                  : undefined
              }
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.disciplines")}
            validateStatus={formik.errors.specialityIds && "error"}
            help={formik.errors.specialityIds}
          >
            <SearchSelect
              value={formik.values.specialityIds}
              options={disciplinesSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("specialityIds", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        {userRole !== "trainingcenter" && (
          <Col span={12}>
            <FormItem
              labelCol={{ span: 24 }}
              label={t("events.filters.trainingCenters")}
              validateStatus={formik.errors.trainingCenterIds && "error"}
              help={formik.errors.trainingCenterIds}
            >
              <SearchSelect
                value={formik.values.trainingCenterIds}
                options={trainingCentersSelectOptions}
                onChange={(value: number) =>
                  formik.setFieldValue("trainingCenterIds", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}

        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.contractors")}
            validateStatus={formik.errors.contractorIds && "error"}
            help={formik.errors.contractorIds}
          >
            <SearchSelect
              value={formik.values.contractorIds}
              options={contractorsSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("contractorIds", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.competencyLevel")}
            validateStatus={formik.errors.competencyLevels && "error"}
            help={formik.errors.competencyLevels}
          >
            <SearchSelect
              value={formik.values.competencyLevels}
              options={COMMISSIOINING_COMPETENCY_LEVEL_SELECT_OPTIONS.map(
                (c) => ({
                  value: c.value,
                  label: t(c.label)
                })
              )}
              onChange={(value: number) =>
                formik.setFieldValue("competencyLevels", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        {props.eventType === EventType.ASSESSMENT && (
          <Col span={12}>
            <FormItem
              labelCol={{ span: 24 }}
              label={t("events.recommendation")}
              validateStatus={formik.errors.recommendations && "error"}
              help={formik.errors.recommendations}
            >
              <SearchSelect
                value={formik.values.recommendations}
                options={RECOMMENDATION_OPTIONS.map((r) => ({
                  ...r,
                  label: t(r.label)
                }))}
                onChange={(value: number) =>
                  formik.setFieldValue("recommendations", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.eventStatuses")}
            validateStatus={formik.errors.statuses && "error"}
            help={formik.errors.statuses}
          >
            <SearchSelect
              value={formik.values.statuses}
              options={eventStatusesSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("statuses", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.projects")}
            validateStatus={formik.errors.project && "error"}
            help={formik.errors.project}
          >
            <SearchSelect
              value={formik.values.project}
              options={projectsSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("project", value)
              }
              maxTagCount={1}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.failReason.failReason")}
            validateStatus={formik.errors.failReasons && "error"}
            help={formik.errors.failReasons}
          >
            <SearchSelect
              value={formik.values.failReasons}
              options={FAIL_REASON_OPTIONS.map((f) => ({
                ...f,
                label: t(f.label)
              }))}
              onChange={(value: number) =>
                formik.setFieldValue("failReasons", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.overallGrade")}
            validateStatus={formik.errors.overallGrade && "error"}
            help={formik.errors.overallGrade}
          >
            <SearchSelect
              value={formik.values.overallGrade}
              options={TRAINING_GRADE_OPTIONS.map((tg) => ({
                ...tg,
                label: t(tg.label)
              }))}
              onChange={(value: number) =>
                formik.setFieldValue("overallGrade", value)
              }
              maxTagCount={1}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.theoreticalStatus")}
            validateStatus={formik.errors.theoreticalStatuses && "error"}
            help={formik.errors.theoreticalStatuses}
          >
            <SearchSelect
              value={formik.values.theoreticalStatuses}
              options={[
                {
                  value: 1,
                  label: t(`events.grade.${1}`)
                },
                {
                  value: 2,
                  label: t(`events.grade.${2}`)
                }
              ]}
              onChange={(value: number) =>
                formik.setFieldValue("theoreticalStatuses", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.practicalStatus")}
            validateStatus={formik.errors.practicalStatuses && "error"}
            help={formik.errors.practicalStatuses}
          >
            <SearchSelect
              value={formik.values.practicalStatuses}
              options={[
                {
                  value: 1,
                  label: t(`events.grade.${1}`)
                },
                {
                  value: 2,
                  label: t(`events.grade.${2}`)
                }
              ]}
              onChange={(value: number) =>
                formik.setFieldValue("practicalStatuses", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            <Button onClick={() => formik.setValues({})}>
              {t("events.filters.clearFilters")}
            </Button>
            <Button
              type="primary"
              loading={props.isLoading}
              onClick={() => formik.submitForm()}
            >
              {t("filter")}
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
