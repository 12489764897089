import picture27 from "@assets/images/user-guide/1.27.png";

export const trainingCenters = [
  {
    title: "How to create a new Training Center?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Training centers tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Register training center button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out all fields in modal window and press on Register button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 1.27
</p>
<p dir="ltr">
    <img
        src=${picture27}
        width="544"
        height="677"
    />
</p>
<br/>
<p dir="ltr">
    New Training Center will be shown on the Training centers page.
</p>
<div>
    <br/>
</div>`
  },
  {
    title: "How to edit Training Centers data?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Training centers tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Edit button
        </p>
    </li>
    <li dir="ltr">
     <p dir="ltr">
        Make changes in modal window and press Save button
     </p>
    </li>
</ol>
`
  },
  {
    title: "How to delete a Training Center?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Training centers tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Delete button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In modal window press Yes button
        </p>
    </li>
</ol>`
  },
  {
    title:
      "How to invite, edit permissions and delete Training Center`s members?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Training Centers tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select one Training Center
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            On Training Centers\` details page navigate to Members block
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Invite member button – to register a new member.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Edit button – to change members data.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Delete button – to delete members from the portal.
        </p>
    </li>
</ol>`
  },
  {
    title: "How to change the permissions of a Training Center member?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Training centers tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select one Training Center
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            On Training Center details page navigate to Members block
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Edit permissions button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Tick or Untick required permissions and press Save button
        </p>
    </li>
</ol>`
  }
];
