import { UseQueryResult } from "react-query";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Steps } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import CraftProfileForm from "@components/common/craftProfiles/forms/CraftProfileForm";
import FindCraftViaIinForm from "@components/common/craftProfiles/forms/FindCraftViaIinForm";

import { ContractorEmployees } from "@models/employees";
import {
  CraftProfile,
  CraftProfilePaginated,
  CreateProfilePayloadAdmin
} from "@models/craftProfiles";

import { craftProfilesApi } from "@api/craftProfilesApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

interface Props {
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  getCraftProfiles: UseQueryResult<
    CraftProfilePaginated<CraftProfile>,
    unknown
  >;
  areFieldsHidden?: boolean;
}

const { Step } = Steps;

const AddCraftProfileModal: React.FC<Props> = ({
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  getCraftProfiles,
  areFieldsHidden = false
}) => {
  const [t] = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedProfile, setSelectedProfile] =
    useState<ContractorEmployees | null>(null);

  const createCraftProfile = useValidatedMutation({
    mutationFunction: (payload: CreateProfilePayloadAdmin) =>
      craftProfilesApi.createCraftProfile(payload),
    onSuccess: () => {
      notification.success({
        message: t("craftProfiles.createProfileSuccess")
      });
      getCraftProfiles.refetch();
      onCloseModal();
    },
    onError: () => notification.error({ message: t("error.errorOccured") })
  });

  const createCraftProfileErrors = useMemo(
    () => transformValidationError(createCraftProfile.error),
    [createCraftProfile.error]
  );

  const onCloseModal = () => {
    handleCloseModal();
    setCurrentStep(0);
    setSelectedProfile(null);
  };

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("craftProfiles.createProfile")}
        footer={null}
        width={1100}
        onCancel={handleCloseModal}
      >
        <Steps
          current={currentStep}
          style={{ marginBottom: "24px" }}
          size="small"
        >
          <Step title={t("craftProfiles.findEmployee")}></Step>
          <Step title={t("craftProfiles.fillProfileInfo")}></Step>
        </Steps>

        {currentStep === 0 ? (
          <FindCraftViaIinForm
            selectedProfile={selectedProfile}
            setSelectedProfile={(profile: ContractorEmployees | null) =>
              setSelectedProfile(profile)
            }
            goToNextStep={() => setCurrentStep(1)}
            handleCloseModal={onCloseModal}
            isLoading={false}
          />
        ) : (
          <CraftProfileForm
            isLoading={
              createCraftProfile.isLoading || getCraftProfiles.isLoading
            }
            handleCloseModal={onCloseModal}
            selectedProfile={selectedProfile}
            handleSubmit={createCraftProfile.mutate}
            errors={createCraftProfileErrors}
            areFieldsHidden={areFieldsHidden}
          />
        )}
      </Modal>

      <Button
        type="primary"
        icon={<PlusSquareOutlined />}
        onClick={handleOpenModal}
      >
        {t("craftProfiles.createProfile")}
      </Button>
    </>
  );
};

export default AddCraftProfileModal;
