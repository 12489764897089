import { useTranslation } from "react-i18next";

import moment from "moment";
import FormItem from "antd/lib/form/FormItem";
import { useFormik } from "formik";

import { Button, Col, DatePicker, Row, Space, Select } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { TrainingCenter, TCReportFormValues } from "@models/trainingCenter";

import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

export const SORTING_OPTIONS = [
  { value: 0, label: "-" },
  { value: 1, label: "common.sorting.1" },
  { value: 2, label: "common.sorting.2" }
];

interface Props {
  // TODO: define Filter type
  initialFilter: TCReportFormValues;
  trainingCenters: TrainingCenter[];
  isLoading: boolean;
  onSubmit: (values: TCReportFormValues) => void;
}

export const TCReportFilterForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const trainingCentersSelectOptions = props.trainingCenters.map((t) => ({
    value: Number(t.id),
    label: t.name
  }));

  const formik = useFormik<TCReportFormValues>({
    initialValues: {
      ...props.initialFilter,
      sortByCreatedAt: Number(props.initialFilter?.sortByCreatedAt) || undefined
    },
    onSubmit: (values) => {
      props.onSubmit(values);
    }
  });

  return (
    <>
      <Row gutter={8} wrap>
        <Col span={4}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("dateFrom")}
            validateStatus={formik.errors.dateStart && "error"}
            help={formik.errors.dateStart}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(_, value) => formik.setFieldValue("dateStart", value)}
              disabledDate={(date) =>
                date && date >= moment(formik.values.dateStart, "YYYY-MM-DD")
              }
              value={
                formik.values.dateStart
                  ? moment(formik.values.dateStart, "YYYY-MM-DD")
                  : undefined
              }
            />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("dateTo")}
            validateStatus={formik.errors.dateEnd && "error"}
            help={formik.errors.dateEnd}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(_, value) => formik.setFieldValue("dateEnd", value)}
              disabledDate={(date) =>
                date && date <= moment(formik.values.dateStart, "YYYY-MM-DD")
              }
              value={
                formik.values.dateEnd
                  ? moment(formik.values.dateEnd, "YYYY-MM-DD")
                  : undefined
              }
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.csrPhase")}
            validateStatus={formik.errors.csrPhases && "error"}
            help={formik.errors.csrPhases}
          >
            <Select
              value={formik.values.csrPhases}
              onChange={(v) => formik.setFieldValue("csrPhases", v)}
              mode="multiple"
            >
              {CSR_PHASE_OPTIONS.map((cp) => (
                <Select.Option value={cp.value} key={cp.value}>
                  {t(cp.label, { no: cp.value })}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.trainingCenters")}
            validateStatus={formik.errors.trainingCenterIds && "error"}
            help={formik.errors.trainingCenterIds}
          >
            <SearchSelect
              value={formik.values.trainingCenterIds}
              options={trainingCentersSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("trainingCenterIds", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem label={t("events.sorting.sortBy")} labelCol={{ span: 24 }}>
            <Select
              value={formik.values.sortByCreatedAt}
              options={SORTING_OPTIONS.map((s) => ({
                value: s.value,
                label: t(s.label)
              }))}
              onChange={(value: number) =>
                formik.setFieldValue("sortByCreatedAt", value)
              }
              allowClear
            />
          </FormItem>
        </Col>
      </Row>
      <Row justify="end" style={{ marginBottom: "16px" }}>
        <Col>
          <Space>
            <Button onClick={() => formik.setValues({} as TCReportFormValues)}>
              {t("events.filters.clearFilters")}
            </Button>
            <Button
              type="primary"
              loading={props.isLoading}
              onClick={() => formik.submitForm()}
            >
              {t("filter")}
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
