import React, { useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import saveAs from "file-saver";

import { Button, Space } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { TrainingReportTable } from "@components/common/tc-reports/TrainingReportTable";
import { TCReportFilterForm } from "@components/common/tc-reports/TCReportFilterForm";

import {
  TrainingCenterReport,
  TcReportTypes,
  TCReportFormValues
} from "@models/trainingCenter";

import { trainingCenterApi } from "@api/trainingCenterApi";

import { useQueryParams } from "@hooks/useQueryParams";
import { usePagination } from "@hooks/usePagination";

export const ProtocolReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const reportType = TcReportTypes.PROTOCOL_REPORT;
  const queryParams = useQueryParams();
  const { page, setPage, setPagination } = usePagination();
  const { ...initialFilters } = queryParams;
  const [filterData, setFilterData] =
    useState<TCReportFormValues>(initialFilters);

  const { data, isLoading } = useQuery(
    ["getAdminProtocolReportsPage", page, filterData],
    () =>
      trainingCenterApi
        .getAdminTrainingCenterReports(reportType, page, filterData)
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterProps: filterData
        };
        setPagination(pagination);
      }
    }
  );

  const trainingCenters = useQuery("getTcs", () =>
    trainingCenterApi.getAllTrainingCenters().then((res) => res.data)
  );

  const handleDownload = (id: number) => {
    const curReport = data?.results.find((item) => item.id === id);
    // eslint-disable-next-line array-callback-return
    curReport?.attachmentFiles.map((file) => {
      trainingCenterApi.getAdminTCReportFile(id, file.id).then((res) => {
        return saveAs(new Blob([res.data]), file.fileName);
      });
    });
  };

  const paginationSettings = {
    hideOnSinglePage: true,
    total: data?.totalItems,
    showTotal: (total: number) => `${t("totalCount")} ${total}`,
    current: data?.currentPage,
    pageSize: data?.pageSize,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={t("reports.tcReports")} />

      <AppPageHeader
        title={t("reports.protocol")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "tc" },
          { breadcrumbName: t("reports.protocol"), path: "requests" }
        ]}
      />

      <TCReportFilterForm
        initialFilter={initialFilters}
        trainingCenters={trainingCenters.data || []}
        isLoading={isLoading}
        onSubmit={(filters) => {
          setFilterData(filters);
        }}
      />

      <TrainingReportTable
        items={data?.results}
        isLoading={isLoading}
        title={t("reports.protocol")}
        rowLinkTo={(s) => `/admin/tc-report/${s.id}`}
        pagination={paginationSettings}
        renderActions={(am: TrainingCenterReport) => (
          <Space size="middle">
            <Button
              title={t("download")}
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(am.id)}
              loading={isLoading}
            />
            <Button
              title={t("viewDetails")}
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              href={`/admin/tc-report/${am.id}`}
              loading={isLoading}
            />
          </Space>
        )}
      />
    </>
  );
};
