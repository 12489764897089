import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Badge, Col, Row, Space, Typography } from "antd";

import { KnownLanguages } from "@constants/craftProfiles";

interface Props {
  title: string | number;
  profileDetails: string | number[];
}

const CraftProfileLowerRow: React.FC<Props> = ({ title, profileDetails }) => {
  const [t] = useTranslation();

  const profileDetailsRenderer = useMemo(() => {
    switch (title) {
      case "languages":
        return Array.isArray(profileDetails) ? (
          <Space direction="vertical">
            {profileDetails.map((lang, index) => (
              <Typography.Text
                key={`lang-${index}`}
                type="secondary"
                style={{ marginLeft: 50 }}
              >
                {t(
                  `${KnownLanguages.find((kl) => kl.value === lang)?.label}`
                ) || "-"}
              </Typography.Text>
            ))}
          </Space>
        ) : (
          "-"
        );
      case "education":
      case "additionalEducation":
      case "additionalInformation":
      case "workExperience":
        return (
          <div
            style={{ marginLeft: 50, color: "#A5A5A5" }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dangerouslySetInnerHTML={{ __html: profileDetails }}
          />
        );
      default:
        return (
          <Typography.Text type="secondary" style={{ marginLeft: 50 }}>
            {profileDetails || "-"}
          </Typography.Text>
        );
    }
  }, [profileDetails, t, title]);

  return (
    <Row
      align="middle"
      style={{ marginBottom: 16 }}
      gutter={8}
      className="w-full"
    >
      <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
        <Space direction="horizontal" style={{ marginBottom: "15px" }}>
          <Badge color="blue" />
          <Typography.Title style={{ fontSize: "20px", margin: 0 }}>
            {t(`craftProfiles.${title}`)}
          </Typography.Title>
        </Space>
        {profileDetailsRenderer}
      </Col>
    </Row>
  );
};

export default CraftProfileLowerRow;
