import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "antd";
import PageMeta from "@components/PageMeta";

const { Text } = Typography;

const LoginPage: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className="guest-pages-bg relative">
      <PageMeta title={t("meta.loginToCtPortal")} />
      <Text
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          color: "white",
          opacity: "0.2"
        }}
      >
        TCO
      </Text>
    </div>
  );
};

export default LoginPage;