import { EventEmployeeGradeType } from "@models/event";

export const EMPLOYEE_GRADE_TYPES = [
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.FAILED}`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.PASSED}`,
    value: EventEmployeeGradeType.PASSED
  }
];

export const EMPLOYEE_GRADE_TYPES_BY_PARTICIPANT = [
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.FAILED}`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.BASIC}`,
    value: EventEmployeeGradeType.BASIC
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.INTERMEDIATE}`,
    value: EventEmployeeGradeType.INTERMEDIATE
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.ADVANCED}`,
    value: EventEmployeeGradeType.ADVANCED
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.COMPETENT}`,
    value: EventEmployeeGradeType.COMPETENT
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.PROFICIENT}`,
    value: EventEmployeeGradeType.PROFICIENT
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.HELPER}`,
    value: EventEmployeeGradeType.HELPER
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.TRAINEE_TECHNICIAN}`,
    value: EventEmployeeGradeType.TRAINEE_TECHNICIAN
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.JUNIOR_TECHNICIAN}`,
    value: EventEmployeeGradeType.JUNIOR_TECHNICIAN
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.TECHNICIAN}`,
    value: EventEmployeeGradeType.TECHNICIAN
  },
  {
    label: `migrations.form.competencyLevels.${EventEmployeeGradeType.SENIOR_TECHNICIAN}`,
    value: EventEmployeeGradeType.SENIOR_TECHNICIAN
  }
];
