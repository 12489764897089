import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import { useFormik } from "formik";

import { Button, Col, Radio, Row } from "antd";

import { ExclusionReasonType } from "@models/event";

const EXCLUSION_REASON_TYPES = [
  {
    value: ExclusionReasonType.NO_SHOW,
    label: `events.exclusionReason.${ExclusionReasonType.NO_SHOW}`
  },
  {
    value: ExclusionReasonType.UNDER_THE_INFLUENCE,
    label: `events.exclusionReason.${ExclusionReasonType.UNDER_THE_INFLUENCE}`
  },
  {
    value: ExclusionReasonType.HAD_REFUSED,
    label: `events.exclusionReason.${ExclusionReasonType.HAD_REFUSED}`
  },
  {
    value: ExclusionReasonType.MID_TERM_ASSESSMENT,
    label: `events.exclusionReason.${ExclusionReasonType.MID_TERM_ASSESSMENT}`
  },
  {
    value: ExclusionReasonType.OTHER,
    label: `events.exclusionReason.${ExclusionReasonType.OTHER}`
  }
];
interface FormValues {
  exclusionReasonType?: number;
  exclusionReason?: string;
}
interface Props {
  errors?: { [key: string]: string };
  isLoading: boolean;
  onSubmit: (values: FormValues) => void;
}

export const ExcludeEventEmpoyeeForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const formik = useFormik<FormValues>({
    initialValues: {
      exclusionReason: undefined,
      exclusionReasonType: undefined
    },
    onSubmit: (values) => {
      props.onSubmit(values);
    }
  });

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  useEffect(() => {
    if (formik.values.exclusionReasonType !== ExclusionReasonType.OTHER) {
      formik.setFieldValue("exclusionReason", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.exclusionReasonType]);

  return (
    <>
      <Row wrap>
        <Col span={24}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.reasonToExclude")}
            validateStatus={formik.errors.exclusionReasonType && "error"}
            help={formik.errors.exclusionReasonType}
          >
            <Radio.Group
              onChange={(e) =>
                formik.setFieldValue("exclusionReasonType", e.target.value)
              }
            >
              {EXCLUSION_REASON_TYPES.map((type) => (
                <Radio value={type.value}>{t(type.label)}</Radio>
              ))}
            </Radio.Group>
          </FormItem>
        </Col>
        {formik.values.exclusionReasonType === ExclusionReasonType.OTHER && (
          <Col span={24}>
            <FormItem
              labelCol={{ span: 24 }}
              label={t("events.exclusionReason.5")}
              validateStatus={formik.errors.exclusionReason && "error"}
              help={formik.errors.exclusionReason}
            >
              <TextArea
                maxLength={250}
                name="exclusionReason"
                onChange={(e) =>
                  formik.setFieldValue("exclusionReason", e.target.value)
                }
              />
            </FormItem>
          </Col>
        )}
      </Row>
      <Row justify="end">
        <Button
          type="primary"
          loading={props.isLoading}
          onClick={() => formik.submitForm()}
        >
          {t("events.exclude")}
        </Button>
      </Row>
    </>
  );
};
