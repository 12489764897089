import { ContractorEventSearchTemplate } from "@components/contractors/common/ContractorEventSearchTemplate";

import { eventsApi } from "@api/eventsApi";
import { disciplineApi } from "@api/disciplineApi";
import { contractorsApi } from "@api/contractorsApi";
import { countryCityApi } from "@api/countryCityApi";

export const EventSearchPage: React.FC = () => {
  return (
    <ContractorEventSearchTemplate
      searchEventsApi={eventsApi.searchContractorEvents}
      getTrainingCentersMapApi={contractorsApi.getTrainingCentersMap}
      getDisciplinesApi={disciplineApi.getAllDisciplines}
      getCitiesApi={countryCityApi.getAllCities}
      navigateTo={(ed) => `/subcontractor/events/${ed.id}/registeremployees`}
      isSearchPage={true}
    />
  );
};
