import React from "react";

import styled from "styled-components";

import { Button as _Button } from "antd";
import { UserOutlined } from "@ant-design/icons";

const Button = styled(_Button)`
  font-size: 16px;
`;

interface ProfileButtonProps {
  username: string;
}

const ProfileButton: React.FC<ProfileButtonProps> = (props) => {
  return (
    <Button type="text">
      <UserOutlined />
      {props.username}
    </Button>
  );
};

export default ProfileButton;
