import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Button, Checkbox, Typography, notification } from "antd";

import userApi from "@api/userApi";

import { UserContext } from "@contexts/UserContext";

import UserAgreementText from "./UserAgreementText";

const UserAgreementModal: React.FC = () => {
  const [t] = useTranslation();
  const { user, loadUser } = useContext(UserContext);
  const [isAccepted, setIsAccepted] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptAgreement = async () => {
    setIsLoading(true);
    try {
      await userApi.acceptUserAgreement();
      await loadUser();
    } catch (error) {
      notification.error({ message: t("error.errorRequest") });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      visible={!user?.userAgreementAccepted}
      closable={false}
      width={720}
      footer={[
        <Checkbox
          onChange={(e) => setIsAccepted(e.target.checked)}
          checked={isAccepted}
        >
          {t("userAgreement.acceptCheckbox")}
        </Checkbox>,
        <Button
          onClick={handleAcceptAgreement}
          disabled={!isAccepted}
          type="primary"
          loading={isLoading}
        >
          {t("userAgreement.proceed")}
        </Button>
      ]}
    >
      <Typography.Title level={3}>
        {t("userAgreement.userAgreement")}
      </Typography.Title>
      <UserAgreementText />
    </Modal>
  );
};

export default UserAgreementModal;
