/* eslint-disable react-hooks/exhaustive-deps  */

import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

import { AxiosError } from "axios";

import { notification } from "antd";
import { CertificateSearchContainer } from "@components/common/certificates/CertificateSearchContainer";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";

import { certificateApi } from "@api/certificateApi";

import { UserContext } from "@contexts/UserContext";
import { transformValidationError } from "@utils/errorHelper";

const CertificateSearchPage: FC = () => {
  const [t] = useTranslation();

  const { userEntityId: trainingCenterId } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState<{
    iin?: string;
    certificateId?: string;
  }>();

  const certificateList = useQuery(
    "getTcCertificates",
    () =>
      certificateApi
        .getTcCertificates({
          trainingCenterId,
          iin: searchQuery?.iin,
          certificateId: searchQuery?.certificateId
        })
        .then((res) => res.data),
    {
      enabled: false,

      retry: false,
      onError(err: AxiosError) {
        if (err.response?.data.message) {
          notification.error({ message: err.response.data.message });
        }
      }
    }
  );
  const validationErrorMap = useMemo(() => {
    if (certificateList.error?.response?.data.validationErrors) {
      return transformValidationError(certificateList.error);
    }

    return {};
  }, [certificateList.error]);

  const certDownloadMutation = useMutation((certificateId: string) =>
    certificateApi.downloadTcCertificate({ trainingCenterId, certificateId })
  );

  useEffect(() => {
    if (searchQuery) {
      certificateList.refetch();
    }
  }, [searchQuery]);

  const pageTitle = t("certificates.search");

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: "CT",
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: ""
          }
        ]}
      />

      <CertificateSearchContainer
        downloading={certDownloadMutation.isLoading}
        loading={certificateList.isLoading}
        errors={validationErrorMap}
        results={certificateList.data}
        onCertificateDownload={(certificateId) => {
          certDownloadMutation.mutate(certificateId);
        }}
        onSubmit={(v) => {
          setSearchQuery(v);
        }}
      />
    </>
  );
};

export default CertificateSearchPage;
