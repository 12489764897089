import axios from "axios";

const getAdminDumps = () => axios.get("/api/employee/dump");

const generateAdminDump = () => axios.post("/api/employee/dump");

const generateCommissioningAdminDump = () =>
  axios.post("/api/employee/dump/commissioning");

const getContractorDumps = (contractorId: number) =>
  axios.get(`/api/contractor/${contractorId}/employee/dump`);

const generateContractorDump = (contractorId: number) =>
  axios.post(`/api/contractor/${contractorId}/employee/dump`);

const generateCommissioningContractorDump = (contractorId: number) =>
  axios.post(`/api/contractor/${contractorId}/employee/commissioning-dump`);

const getTCDumps = (trainingCenterId: number) =>
  axios.get(`/api/training-center/${trainingCenterId}/employee/dump`);

const generateTCDump = (trainingCenterId: number) =>
  axios.post(`/api/training-center/${trainingCenterId}/employee/dump`);

const generateTCCommissioningDump = (trainingCenterId: number) =>
  axios.post(
    `/api/training-center/${trainingCenterId}/employee/dump/commissioning`
  );

const retryGenerateDump = (dumpId: number) =>
  axios.post(`/api/employee/dump/${dumpId}/retry`);

export {
  getAdminDumps,
  generateAdminDump,
  generateCommissioningAdminDump,
  getContractorDumps,
  generateContractorDump,
  generateCommissioningContractorDump,
  getTCDumps,
  generateTCDump,
  generateTCCommissioningDump,
  retryGenerateDump
};
