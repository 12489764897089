import React, { useContext, useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

import { AxiosError } from "axios";
import moment from "moment-timezone";

import { Card, Modal, notification, Space, Tabs, Tag, Typography } from "antd";
import { EventDetailsCard } from "@components/common/events/EventDetailsCard";
import { EventEmployeeCard } from "@components/common/events/EventEmployeeCard";
import { EventEmployeesFilterForm } from "@components/common/events/EventEmployeesFilterForm";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { EventInstructorForm } from "@components/training-center/events/EventInstructorForm";
import { EventGrades } from "@components/training-center/events/EventGrades";
import { EventAttendanceTable } from "@components/training-center/events/EventAttendanceTable";
import DownloadLink from "@components/common/DownloadLink";
import CreateEventFeedbackModal from "@components/common/events/modals/CreateEventFeedbackModal";
import ViewEventFeedbackModal from "@components/common/events/modals/ViewEventFeedbackModal";

import {
  EventAttendance,
  EventAttendancePayload,
  EventEmployeeGradeType,
  EventEmployeeStatus,
  EventStatus,
  EventType,
  FailReasonType
} from "@models/event";

import { instructorApi } from "@api/instructorApi";
import { eventApi } from "@api/eventApi";

import { getEmployeesByType } from "@utils/eventAttendanceHelper";
import { useDefaultQuery } from "@hooks/useDefaultQuery";
import { EVENT_STATUS_TO_ROUTE_MAP } from "@constants/eventStatusToRouteMap";
import { EVENT_STATUS_COLOR_MAP } from "@constants/eventStatusColorMap";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";
import { UserContext } from "@contexts/UserContext";

import EventEditModal from "./components/EventEditModal";
import EventCancelModal from "./components/EventCancelModal";

export const EventDetailsPage: React.FC = () => {
  const [t] = useTranslation();

  const [isInstructorModalShown, setIsInstructorModalShown] = useState(false);
  const [createFeedbackModalVisible, setCreateFeedbackModalVisible] =
    useState(false);
  const [viewFeedbackModalVisible, setViewFeedbackModalVisible] =
    useState(false);
  const [employeeFilters, setEmployeeFilters] = useState<
    { [key: string]: any } | undefined
  >(undefined);

  const { userEntityId: trainingCenterId } = useContext(UserContext);
  const params = useParams<{ id: string }>();
  const eventId = Number(params.id);

  const eventDetails = useQuery("getEventDetails", () =>
    eventApi
      .getTrainingCenterEventDetails({ trainingCenterId, eventId })
      .then((res) => res.data)
  );

  const eventData = eventDetails.data;

  const eventEmployees = useQuery(
    ["getTcEventEmployees", trainingCenterId, eventId],
    () =>
      eventApi
        .getTcEventEmployees({
          trainingCenterId,
          eventId
        })
        .then((res) => res.data)
  );

  const filteredEventEmployees = useQuery(
    ["getTcEventEmployees", trainingCenterId, eventId, employeeFilters],
    () =>
      eventApi
        .getTcEventEmployees({
          trainingCenterId,
          eventId,
          filters: employeeFilters
        })
        .then((res) => res.data)
  );

  const eventEvalMutation = useValidatedMutation({
    mutationFunction: ({
      eventType,
      grades,
      isCommissioning
    }: {
      eventType: typeof EventType[keyof typeof EventType];
      grades: any;
      isCommissioning: boolean;
    }) =>
      eventApi.setEventGrades({
        eventType,
        eventId,
        trainingCenterId,
        isCommissioning,
        grades: grades || []
      }),
    onSuccess() {
      notification.success({ message: t("events.gradeSuccess") });

      eventDetails.refetch();
      eventEmployees.refetch();
    }
  });

  const eventEvalUpdateMutation = useValidatedMutation({
    mutationFunction: ({
      grades,
      eventId,
      trainingCenterId
    }: {
      grades: {
        employeeGradeId: number;
        practicalGrade: number;
        practicalStatus: number;
      }[];
      eventId: number;
      trainingCenterId: number;
    }) =>
      eventApi.updateEventGrades({
        grades,
        eventId,
        trainingCenterId
      }),
    onSuccess() {
      notification.success({ message: t("events.gradeSuccess") });

      eventDetails.refetch();
      eventEmployees.refetch();
    }
  });

  const eventEvalErrors = useMemo(
    () =>
      transformValidationError(
        eventEvalMutation.error
          ? eventEvalMutation.error
          : eventEvalUpdateMutation.error
      ),
    [eventEvalMutation.error, eventEvalUpdateMutation.error]
  );

  const setInstructorMutation = useMutation(
    (v: number) =>
      eventApi.setEventInstructor({
        trainingCenterId,
        eventId,
        instructorId: v
      }),
    {
      onSuccess() {
        notification.success({ message: t("events.instructorSetSuccess") });

        setIsInstructorModalShown(false);
        eventDetails.refetch();
      },
      onError(errRes: AxiosError) {
        if (!errRes.response?.data.validationErrors) {
          notification.error({ message: errRes.response?.data.message });
        } else {
          notification.error({ message: t("error.errorOccured") });
        }
      }
    }
  );

  const contractorList = useQuery("getTcEventContractors", () =>
    eventApi
      .getTcEventContractors({ trainingCenterId, eventId })
      .then((res) => res.data)
  );

  const instructorList = useQuery(
    ["getAllTcInstructors", trainingCenterId, eventData],
    () =>
      eventData &&
      instructorApi
        .getAllTcInstructors(trainingCenterId, {
          specialityIds: eventData ? [eventData.specialityId] : []
        })
        .then((res) => res.data),
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (eventData?.specialityId) {
      instructorList.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData?.specialityId]);

  const eventAttendance = useDefaultQuery("getEventAttendance", () =>
    eventApi
      .getTrainingCenterEventAttendance({ trainingCenterId, eventId })
      .then((res) => res.data)
  );

  const isAttendanceCompleted = useMemo(() => {
    const { availableEmployees } = getEmployeesByType(
      eventEmployees.data?.filter(
        (e) => e.status === EventEmployeeStatus.REGISTERED
      ) || []
    );
    const availableEmployeesIds = availableEmployees.map(
      ({ employeeId }) => employeeId
    );
    const eventLastDay = moment(eventDetails.data?.dateTimeEnd).format(
      "YYYY-MM-DD"
    );
    return eventAttendance.data?.every(
      (attendance) =>
        !availableEmployeesIds.includes(attendance.employeeId) ||
        attendance.days.some(
          (employeeAttendance) =>
            moment(employeeAttendance.date).format("YYYY-MM-DD") ===
            eventLastDay
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData, eventEmployees.data, eventAttendance.data]);

  const ableToAssignInstructor = useMemo(
    () =>
      !!(
        !eventData?.instructorId && eventData?.type !== EventType.PRESCREENING
      ),
    [eventData]
  );

  const disableInstrcutorButton = useMemo(
    () =>
      !(eventData?.status === EventStatus.COMPLETED && isAttendanceCompleted),
    [eventData, isAttendanceCompleted]
  );

  const attendanceMutation = useValidatedMutation({
    mutationFunction: (payload: {
      daysOff: Date[];
      attendances: EventAttendance[];
    }) =>
      eventApi
        .setTrainingCenterEventAttendance({
          trainingCenterId,
          eventId,
          ...payload
        })
        .then((res) => res.data),
    onSuccess: () => {
      notification.success({
        message: "Event attendance saved"
      });
      eventAttendance.refetch();
      filteredEventEmployees.refetch();
      eventEmployees.refetch();
    }
  });

  const feedbackMutation = useValidatedMutation({
    mutationFunction: (feedback: string) =>
      eventApi
        .addTCFeedback({
          trainingCenterId,
          eventId,
          feedback
        })
        .then((res) => res.data),
    onSuccess: () => {
      notification.success({ message: t("events.feedbackSuccess") });
      setCreateFeedbackModalVisible(false);
      eventDetails.refetch();
    }
  });

  const feedbackErrors = useMemo(
    () => transformValidationError(feedbackMutation.error),
    [feedbackMutation.error]
  );

  const tcFeedback = eventData
    ? eventData?.feedbacks.find(
        (eventFeedback) => eventFeedback.trainingCenterId === trainingCenterId
      )
    : undefined;

  const pageTitle = eventDetails.data
    ? eventDetails.data.name
    : t("events.event");

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={
          eventData && (
            <Space>
              {pageTitle}
              <Typography.Text style={{ fontSize: "12px", color: "#fb8c00" }}>
                ID: {eventData.id}
              </Typography.Text>

              <Tag
                color={EVENT_STATUS_COLOR_MAP[eventData.status]}
                style={{ marginRight: 16 }}
              >
                {t(`events.statuses.${eventData?.status}`)}
              </Tag>
            </Space>
          )
        }
        extra={[
          <Space>
            {eventDetails.data &&
              eventDetails.data?.status === EventStatus.PENDING && (
                <EventEditModal
                  trainingCenterId={trainingCenterId}
                  event={eventDetails.data}
                  onEdit={() => eventDetails.refetch()}
                />
              )}

            {eventDetails.data &&
              eventDetails.data?.status === EventStatus.PENDING && (
                <EventCancelModal
                  trainingCenterId={trainingCenterId}
                  event={eventDetails.data}
                  onEdit={() => eventDetails.refetch()}
                />
              )}
          </Space>
        ]}
        breadcrumbs={[
          {
            breadcrumbName: t("trainingCenters.trainingCenter"),
            path: "/"
          },
          {
            breadcrumbName: eventDetails.data?.status
              ? t("events.events") +
                " - " +
                t(`events.statuses.${eventDetails.data.status}`)
              : t("events.allEvents"),
            path: eventDetails.data?.status
              ? `/tc/events/${
                  EVENT_STATUS_TO_ROUTE_MAP[eventDetails.data.status]
                }`
              : "/tc/events/all"
          },
          {
            breadcrumbName: pageTitle,
            path: ""
          }
        ]}
      />

      <Modal
        visible={isInstructorModalShown}
        title={t("events.assignInstructor")}
        destroyOnClose
        maskClosable={false}
        width={600}
        footer={null}
        onCancel={() => setIsInstructorModalShown(false)}
      >
        <Typography.Title level={5}>
          {t("events.pleaseAssignInstructor")}
        </Typography.Title>

        <EventInstructorForm
          isLoading={setInstructorMutation.isLoading}
          instructors={instructorList.data}
          onSubmit={(v) => {
            setInstructorMutation.mutate(v.instructorId || 0);
          }}
          onCancel={() => setIsInstructorModalShown(false)}
        />
      </Modal>

      <CreateEventFeedbackModal
        visible={createFeedbackModalVisible}
        onSubmit={(feedback) => feedbackMutation.mutate(feedback)}
        onCancel={() => setCreateFeedbackModalVisible(false)}
        isLoading={feedbackMutation.isLoading}
        errors={feedbackErrors}
      />

      <ViewEventFeedbackModal
        visible={viewFeedbackModalVisible}
        onClose={() => setViewFeedbackModalVisible(false)}
        feedbacks={tcFeedback ? [tcFeedback] : []}
      />

      {eventData && (
        <EventDetailsCard
          eventData={eventData}
          showInstructorBtn={ableToAssignInstructor}
          disableInstructorBtn={disableInstrcutorButton}
          showAddFeedbackBtn={
            tcFeedback === undefined &&
            [EventStatus.COMPLETED, EventStatus.EVALUATED].includes(
              eventData.status
            ) &&
            eventData.type !== EventType.PRESCREENING
          }
          onAddFeedback={() => setCreateFeedbackModalVisible(true)}
          showViewFeedbackBtn={tcFeedback !== undefined}
          onViewFeedback={() => setViewFeedbackModalVisible(true)}
          onInstructorSet={() => setIsInstructorModalShown(true)}
        />
      )}

      <br />

      {eventData && (
        <Card
          title={t("events.participantList")}
          loading={
            eventDetails.isLoading ||
            eventEmployees.isLoading ||
            filteredEventEmployees.isLoading
          }
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              key="1"
              tab={t("events.allParticipants", {
                number: eventData.capacityBusy
              })}
            >
              <EventEmployeesFilterForm
                contractors={contractorList.data}
                onSubmit={(v) => {
                  setEmployeeFilters(v);
                }}
              />

              {filteredEventEmployees.data &&
                filteredEventEmployees.data?.map((ee) => (
                  <EventEmployeeCard
                    eventDetails={eventDetails.data}
                    employeeDetails={ee}
                    key={ee.employeeId}
                  />
                ))}

              <br />

              <Space>
                <DownloadLink
                  url={eventData.resultFileLink}
                  label={t("events.download")}
                  size="large"
                  type="primary"
                  fileName={`event_${eventId}_details.xlsx`}
                />

                <DownloadLink
                  url={`/api/training-center/${trainingCenterId}/event/${eventId}/attendance/download`}
                  label={t("events.downloadAttendance")}
                  size="large"
                  type="default"
                  fileName={`event_${eventId}_attendance.xlsx`}
                  disabled={
                    eventData.status === EventStatus.CANCELLED ||
                    eventData.status === EventStatus.PENDING
                  }
                />

                {eventData.type !== EventType.OJT_TRAINING && (
                  <DownloadLink
                    url={`/api/training-center/${trainingCenterId}/event/${eventId}/certificate/pdf`}
                    label={t("events.downloadCertificates")}
                    size="large"
                    type="default"
                    fileName={`event_${eventId}_certificates.pdf`}
                    disabled={eventData.status !== EventStatus.EVALUATED}
                  />
                )}
              </Space>
            </Tabs.TabPane>

            {eventData.type !== EventType.PRESCREENING && (
              <Tabs.TabPane
                key="2"
                tab={t("events.attendance")}
                disabled={
                  !eventEmployees.data?.length ||
                  eventData.status === EventStatus.CANCELLED ||
                  eventData.status === EventStatus.PENDING
                }
              >
                <EventAttendanceTable
                  eventDetails={eventData}
                  employees={eventEmployees.data || []}
                  attendances={eventAttendance.data || []}
                  isLoading={
                    eventEmployees.isLoading || eventAttendance.isLoading
                  }
                  isAttendanceSaving={attendanceMutation.isLoading}
                  instructors={instructorList.data || []}
                  onAttendanceSave={(payload: {
                    daysOff: string[];
                    attendances: EventAttendancePayload[];
                  }) => attendanceMutation.mutate(payload)}
                  onExcludeOrEvaluate={() => {
                    eventAttendance.refetch();
                    eventEmployees.refetch();
                    filteredEventEmployees.refetch();
                  }}
                />
              </Tabs.TabPane>
            )}

            <Tabs.TabPane
              key="3"
              tab={t("events.grades")}
              disabled={
                eventData.type === EventType.PRESCREENING &&
                (eventData.status === EventStatus.COMPLETED ||
                  eventData.status === EventStatus.EVALUATED ||
                  eventData.status === EventStatus.ACTIVATED)
                  ? false
                  : eventData.status === EventStatus.CANCELLED ||
                    eventData.status === EventStatus.PENDING ||
                    !isAttendanceCompleted ||
                    !eventDetails.data?.instructorId
              }
            >
              <EventGrades
                errors={eventEvalErrors}
                isLoading={eventEvalMutation.isLoading}
                eventData={eventData}
                eventEmployees={eventEmployees.data}
                onSubmit={(v) => {
                  eventData.status === EventStatus.ACTIVATED
                    ? eventEvalUpdateMutation.mutate({
                        grades: v.map((item) => ({
                          employeeGradeId: item.employeeGradeId,
                          practicalGrade: item.practicalGrade,
                          practicalStatus: item.practicalStatus,
                          isPassed: item.isPassed,
                          failReasonType:
                            item.isPassed !== EventEmployeeGradeType.FAILED
                              ? FailReasonType.UNDEFINED
                              : item.failReasonType === FailReasonType.UNDEFINED
                              ? 0
                              : item.failReasonType,
                          failReason: item.failReason,
                          commissioningRecommendation: item.recommendation
                        })),
                        eventId: eventData.id,
                        trainingCenterId: eventData.trainingCenterId
                      })
                    : eventEvalMutation.mutate({
                        grades: v.map((item) => ({
                          ...item,
                          failReasonType:
                            item.isPassed !== EventEmployeeGradeType.FAILED
                              ? FailReasonType.UNDEFINED
                              : item.failReasonType === FailReasonType.UNDEFINED
                              ? 0
                              : item.failReasonType
                        })),
                        eventType: eventData.type,
                        isCommissioning: eventData.isCommissioning
                      });
                }}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      )}
    </>
  );
};
