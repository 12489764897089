import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormikErrors, useFormik } from "formik";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Tag,
  Typography
} from "antd";
import {
  FlagOutlined,
  MailOutlined,
  PhoneOutlined,
  PushpinOutlined,
  ToolOutlined,
  UserOutlined
} from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { Div } from "@components/Sections";
import ProfileEditModal from "@components/profile/ProfileEditModal";

import UserAPI from "@api/userApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { UserContext } from "@contexts/UserContext";

interface FormValues {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}

export const ProfilePage: React.FC = () => {
  const [t] = useTranslation();
  const { user, loadUser } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: tryChangePassword, isLoading } =
    useValidatedMutation<FormValues>({
      mutationFunction: (values: FormValues) =>
        UserAPI.changePassword({
          oldPassword: values.oldPassword,
          password: values.password,
          passwordConfirm: values.passwordConfirm
        }).then((res) => res.data),

      onSuccess: () => {
        clearForm();
        message.success(t("profile.passwordChangeSuccess"));
      },
      onError: (error: FormikErrors<FormValues>) => {
        formik.setErrors(error);
      }
    });

  const formik = useFormik<FormValues>({
    initialValues: {
      oldPassword: "",
      password: "",
      passwordConfirm: ""
    },
    onSubmit: (values) => tryChangePassword(values)
  });

  const clearForm = () => {
    formik.resetForm();
    setIsModalOpen(false);
  };

  return (
    <div>
      <PageMeta title={t("meta.accountInfo")} />
      <PageHeader
        title={t("profile.profile")}
        extra={[
          user ? (
            <ProfileEditModal
              user={user}
              key={1}
              onProfileEdit={() => loadUser()}
            />
          ) : (
            <span />
          ),
          <Button type="primary" key={2} onClick={() => setIsModalOpen(true)}>
            {t("profile.changePassword")}
          </Button>
        ]}
      />

      <Modal
        visible={isModalOpen}
        closable={false}
        maskClosable={false}
        title={t("profile.changePassword")}
        cancelText={t("profile.cancel")}
        okText={t("profile.change")}
        confirmLoading={isLoading}
        okButtonProps={{ disabled: !formik.isValid }}
        onOk={() => formik.handleSubmit()}
        onCancel={() => clearForm()}
      >
        <Form>
          <Form.Item
            label={t("profile.currentPassword")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.oldPassword && "error"}
            help={formik.errors.oldPassword}
          >
            <Input.Password
              name="oldPassword"
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
            />
          </Form.Item>

          <Form.Item
            label={t("profile.newPassword")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.password && "error"}
            help={formik.errors.password}
          >
            <Input.Password
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </Form.Item>

          <Form.Item
            label={t("profile.confirmNewPassword")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.passwordConfirm && "error"}
            help={formik.errors.passwordConfirm}
          >
            <Input.Password
              name="passwordConfirm"
              value={formik.values.passwordConfirm}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Card style={{ marginBottom: 32 }}>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <ToolOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>{user?.id}</Typography.Text>
              </div>
              <Typography.Text type="secondary">{t("id")}</Typography.Text>
            </Div>
          </Col>
        </Row>

        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <UserOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {user?.firstName} {user?.lastName}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("members.fullName")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>

        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <MailOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>{user?.email}</Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("members.email")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <PhoneOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>{user?.phoneNumber || "-"}</Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("members.phoneNumber")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <FlagOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>{user?.position}</Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("members.position")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <PushpinOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                {user?.permissions.map(({ permission }) => (
                  <Tag key={permission}>
                    {permission} — {t(`permissions.${permission}`)}
                  </Tag>
                ))}
              </div>
              <Typography.Text type="secondary">
                {t("members.accessLevel")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
