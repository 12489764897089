import { useTranslation } from "react-i18next";

import { Col, Row, Typography } from "antd";

interface Props {
  styledIcon: (style: Record<string, string | number>) => React.ReactNode;
  primaryText: string | number;
  secondaryText: string;
  isRightColumn?: boolean;
  isFirstItem?: boolean;
}

const CraftProfileUpperRow: React.FC<Props> = ({
  primaryText,
  styledIcon,
  secondaryText,
  isFirstItem = false
}) => {
  const [t] = useTranslation();

  return (
    <Row
      align="middle"
      style={{ marginBottom: 16, marginTop: isFirstItem ? 40 : 0 }}
    >
      <Col span={4}>{styledIcon({ fontSize: "24px" })}</Col>
      <Col span={18} style={{ display: "flex", flexDirection: "column" }}>
        <Typography.Text>{primaryText || "-"}</Typography.Text>
        <Typography.Text type="secondary">
          {t(`craftProfiles.${secondaryText}`)}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default CraftProfileUpperRow;
