import React from "react";

import { Col, Alert, Comment } from "antd";
import DownloadLink from "@components/common/DownloadLink";

import { RequestTicketComment } from "@models/requests";

import useDateFormatter from "@hooks/useDateFormat";

interface RequestReplyProps {
  reply: RequestTicketComment;
  isAuthor: boolean;
  attachmentLinkGenerator?: (attachmentId: number) => string;
}

/**
 * The component is supposed to be used within the <Row /> component from Ant.Design.
 * Uses <Col /> component internally to imitate the chat structure.
 *
 * Pass attachmentLinkGenerator prop function to generate download links for training centers or contractors;
 *
 * @param props
 * @returns
 */
const RequestReply: React.FC<RequestReplyProps> = ({
  reply,
  isAuthor = false,
  attachmentLinkGenerator
}) => {
  const { formatter } = useDateFormatter();
  const downloadURL = attachmentLinkGenerator
    ? attachmentLinkGenerator(reply.attachmentFileId)
    : "";

  return (
    <>
      <Col span={8} offset={isAuthor ? 16 : 0}>
        <Alert
          type={isAuthor ? "info" : "success"}
          message={
            <Comment
              content={reply.text}
              datetime={formatter(reply.createdAt)}
              author={`${reply.author.firstName} ${reply.author.lastName}`}
              actions={
                reply.attachmentFileId
                  ? [
                      <DownloadLink
                        url={downloadURL}
                        fileName={reply.attachmentFile.fileName}
                      />
                    ]
                  : undefined
              }
            />
          }
        />
      </Col>
      {!isAuthor && <Col span={16} />}
    </>
  );
};

export default RequestReply;
