import React from "react";

import { Select } from "antd";

import { TrainingCenter } from "@models/trainingCenter";

interface Props {
  items: TrainingCenter[];
  curItems: number[];
  onChange: Function;
}

const TrainingCentersSelector: React.FC<Props> = (props) => {
  const { Option } = Select;

  return (
    <Select
      value={props.curItems}
      onChange={(value) => props.onChange(value)}
      mode="multiple"
    >
      {props.items?.map((item, index) => (
        <Option key={index} value={item.id}>
          <span>{item.name}</span>
        </Option>
      ))}
    </Select>
  );
};

export default TrainingCentersSelector;
