import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useMutation } from "react-query";

import { AxiosError, AxiosResponse } from "axios";
import Modal from "antd/lib/modal/Modal";

import { EditEmployeeForm } from "@components/common/events/EditEmployeeForm";
import { EventEmployeesDataTable } from "@components/common/events/EventEmployeesDataTable";
import { ApplyEmployeeToEventSection } from "@components/common/events/ApplyEmployeeToEventSection";
import { Button, Col, notification, Row, Space } from "antd";

import {
  EditEmployeePayload,
  EmployeeDetails,
  EmployeeSearchResult
} from "@models/employee";
import { EventData } from "@models/event";
import { Subcontractor } from "@models/subcontractor";
import { Contract } from "@models/contract";
import { Country } from "@models/countryCity";
import { PERMISSIONS } from "@models/permissions";

import { transformValidationError } from "@utils/errorHelper";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { useDefaultQuery } from "@hooks/useDefaultQuery";
import { UserContext } from "@contexts/UserContext";

interface RouteParams {
  id: string;
}

interface Props {
  getEventDataApi: (eventId: number) => Promise<AxiosResponse<EventData>>;
  getContractorSubcontractorsApi: (
    contractorId: number
  ) => Promise<AxiosResponse<Subcontractor[]>>;
  getContractorContractsApi: (
    contractorId: number
  ) => Promise<AxiosResponse<Contract[]>>;
  getCountries: () => Promise<AxiosResponse<Country[]>>;
  searchEmployeesByIinsApi: (
    eventId: number,
    iins: string[]
  ) => Promise<AxiosResponse<EmployeeSearchResult>>;
  updateEmployeeApi: (payload: {
    contractorId: number;
    employeeId: number;
    employeeData: Partial<EmployeeDetails>;
  }) => Promise<AxiosResponse>;
  applyToEventApi: (
    eventId: number,
    employees: EmployeeDetails[]
  ) => Promise<AxiosResponse>;

  onSuccess: () => void;
}

export const RegisterEmployeesToEventTemplate: React.FC<Props> = ({
  getEventDataApi,
  getContractorSubcontractorsApi,
  getContractorContractsApi,
  getCountries,
  searchEmployeesByIinsApi,
  updateEmployeeApi,
  applyToEventApi,
  onSuccess
}) => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  const eventId = Number(id);

  const {
    userEntityId: contractorId,
    userContractor,
    checkPermission
  } = useContext(UserContext);
  const contractorName = userContractor?.name || "";

  const [isEmployeesDataCheckStep, setIsEmployeesDataCheckStep] =
    useState<boolean>(false);

  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeDetails[]>(
    []
  );

  const [editableEmployee, setEditableEmployee] =
    useState<EmployeeDetails | null>(null);

  const eventData = useDefaultQuery("getContractorEvent", () =>
    getEventDataApi(eventId).then((res) => res.data)
  );

  const subcontractors = useDefaultQuery("getContractorSubcontractors", () =>
    getContractorSubcontractorsApi(contractorId).then((res) => res.data)
  );

  const contracts = useDefaultQuery("getContracts", () =>
    getContractorContractsApi(contractorId).then((res) => res.data)
  );

  const countries = useDefaultQuery("getCountries", () =>
    getCountries().then((res) => res.data)
  );

  const searchEmployeesMutation = useValidatedMutation({
    mutationFunction: (iins: string[]) =>
      searchEmployeesByIinsApi(eventId, iins).then((res) => res.data)
  });
  const searchEmployeesErrors = useMemo(
    () => transformValidationError(searchEmployeesMutation.error),
    [searchEmployeesMutation.error]
  );

  const editEmployeeDataMutation = useValidatedMutation({
    mutationFunction: (employeeData: EditEmployeePayload) =>
      updateEmployeeApi({
        contractorId,
        employeeId: employeeData.id,
        employeeData
      }).then((res) => res.data),
    onSuccess: (_, employee) => {
      setEditableEmployee(null);

      setSelectedEmployees([
        ...selectedEmployees.filter(({ iin }) => iin !== employee?.iin),
        employee
      ]);
    }
  });
  const editEmployeeDataErrors = useMemo(
    () => transformValidationError(editEmployeeDataMutation.error),
    [editEmployeeDataMutation.error]
  );

  const applyEventMutation = useMutation(
    "applyEvent",
    () => applyToEventApi(eventId, selectedEmployees).then((res) => res.data),
    {
      onSuccess: () => {
        notification.success({
          message: t("events.applicationSent")
        });

        onSuccess();
        setIsEmployeesDataCheckStep(false);
        searchEmployeesMutation.reset();
      },
      onError: (err: AxiosError) => {
        if (err.response?.data.message) {
          notification.error({
            message: err.response?.data.message
          });
        }
      }
    }
  );

  const userHasPermission = checkPermission(PERMISSIONS.EVENT_APPLY);

  return (
    <>
      <Modal
        visible={!!editableEmployee}
        destroyOnClose
        maskClosable={false}
        width={1100}
        footer={null}
        onCancel={() => {
          setEditableEmployee(null);
          editEmployeeDataMutation.reset();
        }}
      >
        <EditEmployeeForm
          contracts={contracts.data || []}
          countries={countries.data || []}
          selectableContractors={[
            { id: contractorId, name: contractorName }
          ].concat(subcontractors.data || [])}
          employee={editableEmployee}
          isLoading={editEmployeeDataMutation.isLoading}
          errors={editEmployeeDataErrors}
          onCancel={() => {
            setEditableEmployee(null);
            editEmployeeDataMutation.reset();
          }}
          onSubmit={(employee) => {
            editEmployeeDataMutation.mutate(employee);
          }}
        />
      </Modal>

      {!isEmployeesDataCheckStep ? (
        <div>
          {userHasPermission && (
            <ApplyEmployeeToEventSection
              seatsAvailable={eventData.data?.capacityAvailable || 0}
              employeeSearchResult={
                searchEmployeesMutation.data?.employees || []
              }
              employeeSearchLoading={searchEmployeesMutation.isLoading}
              employeeSearchErrors={searchEmployeesErrors}
              onEmployeeSearch={(v) => searchEmployeesMutation.mutate(v)}
              onApplyEventStepChange={(
                selectedEmployees: EmployeeDetails[]
              ) => {
                setIsEmployeesDataCheckStep(true);
                setSelectedEmployees(selectedEmployees);
              }}
            />
          )}
        </div>
      ) : (
        <>
          <EventEmployeesDataTable
            countries={countries.data || []}
            selectedEmployees={selectedEmployees}
            onEmployeeEdit={(employee) => {
              setEditableEmployee(employee);
            }}
            onEmployeeDelete={(employeeId) => {
              setSelectedEmployees([
                ...selectedEmployees.filter(({ id }) => id !== employeeId)
              ]);
            }}
          />
          <Row justify="space-between" style={{ marginTop: "32px" }}>
            <Col>
              {selectedEmployees.length} {t("events.employees")}
            </Col>
            <Col>
              <Space>
                <Button onClick={() => setIsEmployeesDataCheckStep(false)}>
                  {t("back")}
                </Button>
                <Button
                  loading={applyEventMutation.isLoading}
                  type="primary"
                  onClick={() => applyEventMutation.mutate()}
                >
                  {t("apply")}
                </Button>
              </Space>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
