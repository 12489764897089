import { OjtEventGradeTable } from "@components/common/events/OjtEventGradeTable";

import {
  EventEmployeeDetails,
  NonCommissioningEventGrade
} from "@models/event";

import { getEmployeesByType } from "@utils/eventAttendanceHelper";

import { OjtEventGradeForm } from "./OjtEventGradeForm";

interface Props {
  editable?: boolean;

  errors?: { [key: string]: string };
  isLoading?: boolean;
  eventEmployees?: EventEmployeeDetails[];
  onSubmit?: (values: NonCommissioningEventGrade[]) => void;
}

export const OjtEventGrades = (props: Props) => {
  const { availableEmployees: ungradedEmployees } = getEmployeesByType(
    props.eventEmployees || []
  );

  return props.editable ? (
    <OjtEventGradeForm
      isLoading={props.isLoading}
      errors={props.errors}
      onSubmit={props.onSubmit}
      eventEmployees={ungradedEmployees}
    />
  ) : (
    <OjtEventGradeTable eventEmployees={props.eventEmployees} />
  );
};
