import axios from "axios";

import { Location } from "@models/location";

// Kazakhstan cities === 1
const getLocations = () =>
  axios.get<Location[]>("/api/city", { params: { countryId: 1 } });

export const locationApi = {
  getLocations
};
