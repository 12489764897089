import { FC } from "react";

import moment from "moment";

import { Space, Switch } from "antd";

interface Props {
  date: string;
  isDayOff: boolean;
}

const DateColumnHeader: FC<Props> = (props) => {
  const formattedDate = moment(props.date, "DD-MM-YYYY").format("DD.MM.YYYY");

  return (
    <Space direction="vertical">
      <span>{formattedDate}</span>
      <Switch size="small" checked={props.isDayOff} disabled />
    </Space>
  );
};

export default DateColumnHeader;
