export const ruTrainingCenters = {
  trainingCenters: "Тренинг Центры",
  trainingCenter: "Тренинг Центр",
  trainingProviders: "Провайдеры обучения",

  exportUsers: "Экспорт пользователей",
  registerTrainingCenter: "Зарегистрировать тренинг центр",
  editTrainingCenter: "Редактировать тренинг центр",

  editMemberPermissions: "Редактировать уровень доступа сотрудника",

  addInstructor: "Добавить инструктора",
  editInstructor: "Редактировать инструктора",
  deleteInstructor: "Удалить инструктора",

  registerSuccess: "Тренинг центр был успешно зарегистрирован",
  editSuccess: "Тренинг центр был успешно сохранен",
  deleteSuccess: "Тренинг центр был успешно удален",

  addInstructorSuccess: "Инструктор был успешно добавлен",
  editInstructorSuccess: "Инструктор был успешно сохранен",
  deleteInstructorSuccess: "Инструктор был успешно удален",

  deleteFail: "Не удалось удалить тренинг центра",

  deletePrompt: "Вы уверены, что хотите удалить тренинг центр: {{name}}?",

  name: "Название тренинг центра"
};
