import React, { useContext } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import qs from "qs";

import DefaultLayout from "@layouts/DefaultLayout";
import IndexPage from "@pages/IndexPage";
import LoginPage from "@pages/LoginPage";
import ResetPasswordPage from "@pages/ResetPasswordPage";
import ResetPasswordTokenPage from "@pages/ResetPasswordTokenPage";
import AcceptInvitePage from "@pages/AcceptInvitePage";
import SSOAuthPage from "@pages/SSOAuthPage";

import { RouteItem } from "@models/routeItem";

import { UserContext } from "@contexts/UserContext";

const guestRoutes: RouteItem[] = [
  {
    id: "index",
    path: "/",
    component: IndexPage
  },
  {
    id: "login",
    path: "/login",
    component: LoginPage
  },
  {
    id: "resetPassword",
    path: "/reset",
    component: ResetPasswordPage
  },
  {
    id: "resetPasswordToken",
    path: "/auth/password/reset/:token",
    component: ResetPasswordTokenPage
  },
  {
    id: "invite",
    path: "/invite",
    component: AcceptInvitePage
  },
  {
    id: "sso-callback",
    path: "/sso-callback",
    component: SSOAuthPage
  }
];

const GuestRoutes: React.FC = () => {
  const { isUserLoggedIn } = useContext(UserContext);
  const location = useLocation();
  const redirectTo = location.pathname;
  return (
    <DefaultLayout>
      <Switch>
        {guestRoutes.map((route, i) =>
          route.items ? (
            route.items.map((item, i) => (
              <Route
                exact
                path={item.path}
                component={item.component}
                key={i}
              />
            ))
          ) : (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={i}
            />
          )
        )}

        {!isUserLoggedIn && (
          <Route
            path="/"
            render={() => (
              <Redirect
                to={{
                  pathname: "/login",
                  search: qs.stringify({ redirectTo })
                }}
              />
            )}
          />
        )}
      </Switch>
    </DefaultLayout>
  );
};

export default GuestRoutes;
