import { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { UploadFile } from "antd/lib/upload/interface";

import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Modal, notification, Typography } from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { NewEventForm } from "@components/training-center/events/NewEventForm";
import { EventPaginator } from "@components/common/events/EventPaginator";
import { EventFilterForm } from "@components/common/events/EventFilterForm";

import { EventFilter, EventStatus } from "@models/event";

import { eventApi } from "@api/eventApi";
import { disciplineApi } from "@api/disciplineApi";
import { contractorsApi } from "@api/contractorsApi";
import { countryCityApi } from "@api/countryCityApi";
import { trainingCenterApi } from "@api/trainingCenterApi";
import { locationApi } from "@api/locationApi";

import { UserContext } from "@contexts/UserContext";
import { transformValidationError } from "@utils/errorHelper";
import { useQueryParams } from "@hooks/useQueryParams";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { usePagination } from "@hooks/usePagination";

interface Props {
  eventStatus?: EventStatus;
}

export const TcEventPagination = (props: Props) => {
  const [t] = useTranslation();
  const qp = useQueryParams();
  const { page, setPage, setPagination } = usePagination();

  const { userEntityId } = useContext(UserContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState<EventFilter>({
    cityIds: qp.cityIds,
    competencyLevel: qp.competencyLevel,
    csrPhases: qp.csrPhases,
    sortBy: qp.sortBy,
    contractorIds: qp.contractorIds,
    languageCode: qp.languageCode,
    dateEnd: qp.dateEnd,
    dateStart: qp.dateStart,
    eventIds: qp.eventIds,
    specialityIds: qp.specialityIds,
    type: qp.type,
    eventCityIds: qp.eventCityIds,
    trainingCenterIds: qp.trainingCenterIds
  });

  const specialityList = useQuery("getTcSpecialityList", () =>
    disciplineApi.getTcSpecialities(userEntityId).then((res) => res.data)
  );

  const contractorList = useQuery("getContractors", () =>
    contractorsApi.getContractors().then((res) => res.data)
  );

  const cityList = useQuery("getCities", () =>
    countryCityApi.getAllCities().then((res) => res.data)
  );

  const locationList = useQuery("getLocations", () =>
    locationApi.getLocations().then((res) => res.data)
  );

  const eventPage = useQuery(
    ["events", page, filters],
    () =>
      eventApi
        .getTcEventPage({
          trainingCenterId: userEntityId,
          status: props.eventStatus,
          page,
          ...filters
        })
        .then((res) => res.data),
    {
      onSuccess(res) {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterParams: filters
        };
        setPagination(pagination);
      }
    }
  );

  const trainingCenter = useQuery(["trainingCenter", userEntityId], () =>
    trainingCenterApi.getTrainingCenter(userEntityId).then(({ data }) => data)
  );

  const createEventMutation = useValidatedMutation({
    mutationFunction: (values: any) =>
      eventApi.createEvent({
        trainingCenterId: userEntityId,
        event: {
          ...values,
          files: values.files?.map?.((f: UploadFile) =>
            f.originFileObj ? f.originFileObj : f
          )
        }
      }),
    onSuccess() {
      notification.success({ message: t("events.eventCreated") });

      setIsModalOpen(false);

      eventPage.refetch();
    }
  });
  const validationErrors = useMemo(
    () => transformValidationError(createEventMutation.error),
    [createEventMutation.error]
  );

  const pageTitle = props.eventStatus
    ? t("events.events") + " - " + t(`events.statuses.${props.eventStatus}`)
    : t("events.allEvents");

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={pageTitle}
        subTitle={
          <Typography.Text type="secondary">
            {t("total")}: {eventPage.data?.totalItems}
          </Typography.Text>
        }
        breadcrumbs={[
          {
            breadcrumbName: t("trainingCenters.trainingCenter"),
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: ""
          }
        ]}
        extra={[
          <Button
            type="primary"
            key="0"
            icon={<PlusCircleFilled />}
            onClick={() => setIsModalOpen(true)}
          >
            {t("events.createEvent")}
          </Button>
        ]}
      />

      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("create")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <NewEventForm
          isLoading={createEventMutation.isLoading}
          errors={validationErrors}
          specialities={specialityList.data}
          locations={locationList.data}
          contractors={contractorList.data}
          onCancel={() => setIsModalOpen(false)}
          onSubmit={(values) => {
            createEventMutation.mutate(values);
          }}
          trainingCenter={trainingCenter.data}
        />
      </Modal>

      <EventFilterForm
        initialValues={filters}
        contractors={contractorList.data}
        disciplines={specialityList.data}
        cities={cityList.data}
        hiddenFields={["trainingCenterIds"]}
        isLoading={eventPage.isLoading}
        onSubmit={(v) => {
          setFilters(v);
          setPage(1);
        }}
      />

      <EventPaginator
        pageData={eventPage.data}
        disabled={eventPage.isLoading}
        linkTo={(ev) => `/tc/events/${ev.id}`}
        onChange={(nextPage) => {
          setPage(nextPage);
        }}
      />
    </>
  );
};
