export const enContractorWhatNew = [
  {
    title:
      "New feature: Addition of the 'location' field for all event types: in Performance reports, Data Dump report, Report filters.",
    html: `<p><span style="font-weight: 400;">Users can view the location of the event including online and Tengiz. Do not mix up with the Training Center\`s city.</span></p>`
  },
  {
    title:
      "New feature: Candidate profiles: view, ‘hire’ profiles of local unemployed Training Centers` event participants as part of TCO CSR program. Available for TCO, Contractors, Subcontractors and Training Center Users.",
    html: `<p><span style="font-weight: 400;">Profiles module of CT Portal shows job- seeking craft worker profiles, which were created in the system so that Contractor/ Subcontractor users could view and hire profiles. This module also stores all the information in the system.</span></p>`
  },
  {
    title: "New feature: Online training report (KPJV) for non-craft employees",
    html: `<p><span style="font-weight: 400;">Contractor users are now able to create </span><span style="font-weight: 400;">Online Training Reports(KPJV): a list of non- craft employees with discipline, training start and end dates, etc. TCO users can view these Reports.</span></p>`
  },
  {
    title: "Changes into Reports & Data Dump modules",
    html: `<p><span style="font-weight: 400;">New functionality and some minor changes have been added to Reports and Data Dump.</span></p>`
  },
  {
    title:
      "Changes to eligibility requirements: the 1-month rule has been removed  for craft employees to reseat the event",
    html: `<p><span style="font-weight: 400;">With this change craft employees are not required to wait for one month to re-apply to the same type&amp; discipline of the event.</span></p>`
  },
  { title: "", html: `` },
  { title: "", html: `` },
  { title: "", html: `` },
  { title: "", html: `` }
];
