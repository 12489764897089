export const enReports = {
  reports: "Reports",
  performanceReports: "Performance Reports",
  mouCategory: "MoU Category",

  tcReports: "TC Reports",
  trainingReport: "Training Report",
  certificate: "Certificate",
  profile: "Profile",
  protocol: "Protocol",
  reportName: "Report Name",
  csrPhase: "CSR Phase",
  reportDate: "Report date",
  createdAt: "Created date",
  editedAt: "Last edited",
  numberOfEdits: "Edited",
  createReport: "Create Report",
  reportType: "Type",
  reportDeleted: "Report deleted",
  saveSuccess: "Report successfully saved",
  confirmDelete: "Are you sure you want to delete report: {{name}}?",
  commissioningPerformance: "Commissioning Performance",
  perYear: "Trained employees number per year",
  eventsByTrainingCenter: "Events by Training providers",
  eventsByLocation: "Events by Location",
  eventsByContractor: "Events by Contractors",
  eventsByDiscipline: "Events by Disciplines",
  citizenship: "Citizenship",
  overallGrade: "Overall Grade",
  trainingResults: "Training results",
  failReason: "Fail Reason",
  duplicatedHeadcount: "Duplicated Headcount Report",

  datadump: "Data dump",
  datadumpTitle: "All reports",
  datadumpSubtitle: "Download data dump",
  datadumpName: "File name",
  datadumpDate: "Created date",
  datadumpGenerate: "Generate data dump file",
  datadumpGenerateCommissioning: "Generate commissioning data dump file",
  datadumpGenerateError:
    "There was an error generating data dump file, please try again",

  onlineTrainingReport: "Online Training Report",
  onlineTrainingReport_other: "Online Training Reports",

  profiles: {
    profileReport: "Profile Report",
    byDiscipline: "Profiles by Disciplines",
    byStatus: "Profiles by Status",
    byCSRContractor: "Profiles by CSR Contractor",
    byTrainingCenter: "Profiles by Training Center",
    byContractor: "Hired Profiles by Contractors",
    employed: "Employed",
    unemployed: "Unemployed",
    downloadProfilesData: "Download Profiles Data"
  },

  createOnlineTrainingReport: "Create Online Training Report",
  editOnlineTrainingReport: "Edit Online Training Report",
  deleteOnlineTrainingReport: "Delete Online Training Report",

  createReportSuccess: "Report was successfully created",
  deleteReportSuccess: "Report was successfully deleted",

  deleteConfirmation: "Are you sure you want to delete report {{name}}?",
  userDeleteConfirmation:
    "Are you sure you want to delete employee {{name}} from this Report?",
  userDeleteSuccess: "User was successfully removed from current Report",
  userAddSuccess: "User was successfully added to current Report",

  completed: "Completed",
  incompleted: "Incompleted",

  downloadAcademicHoursReport: "Download Academic Hours Report",

  filter: "Filter report",

  filterNotAppliedToAcademicHours: "*filter cant'be applied to academic hours",

  headcountTypes: {
    headcount: "Headcount",
    training: "Training",
    assessment: "Assessment",
    prescreening: "Pre-screening",
    ojtTraining: "OJT training",
    commissioningAssessment: "Com.Assessment",
    commissioningTraining: "Com.Training"
  },
  host: "Host",
  status: "Status",
  datadumpSuccess: "Datadump generation might take up to 1 hour",
  unknown: "Unknown",

  uploadParticipants: "Upload participants"
};
