export const ruMembers = {
  members: "Сотрудники",

  inviteMember: "Пригласить сотрудника",
  editMember: "Редактировать сотрудника",

  activeMembers: "Активные сотрудники",
  inactiveMembers: "Неактивные сотрудники",
  totalMembers: "Всего сотрудников",
  invitedMembers: "Приглашенные сотрудники",

  accessLevel: "Уровень доступа",
  notifications: "Уведомления",

  firstName: "Имя",
  lastName: "Фамилия",
  fullName: "Полное имя",
  position: "Должность",
  email: "E-mail",
  phoneNumber: "Телефон",
  invitationDate: "Дата приглашения",
  actions: "Действия",

  memberDeleted: "Сотрудник удален",
  editSuccess: "Сотрудник успешно сохранен",

  userExport: "Экспортировать пользователей",
  edit: "Редактировать",
  delete: "Удалить",
  cancel: "Отменить",
  resend: "Переотправить",
  activate: "Активировать",
  deactivate: "Деактивировать",

  activationSuccess: "Приглашение успешно отправлено",

  memberInfo: "Данные сотрудника",
  jobTitle: "Должность",

  invite: "Пригласить",
  inviteSuccess: "Приглашение отправлено на {{email}}",
  inviteResent: "Приглашение повторно отправлено на {{email}}",
  inviteDeleted: "Приглашение удалено",

  previousInviteNotExpired:
    "Срок действия предыдущего приглашения еще не истек",
  resendPossible: "Можно повторно отправить приглашение",

  confirmDeactivate:
    "Вы уверены, что хотите деактивировать сотрудника: {{name}}?",
  confirmDelete: "Вы уверены, что хотите удалить сотрудника: {{name}}?"
};
