import { FC } from "react";
import { useTranslation } from "react-i18next";

import { marginTop } from "html2canvas/dist/types/css/property-descriptors/margin";

import { Card, Col, List, Row } from "antd";
import { MailOutlined, TeamOutlined } from "@ant-design/icons";

export const ContactUs: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Card>
        <Row>
          <Col span={12}>
            <Card.Meta
              title={t("contacts.title")}
              description={t("contacts.description")}
            />
          </Col>
        </Row>
        <List style={{ marginTop: "50px" }} itemLayout="vertical">
          <List.Item>
            <List.Item.Meta
              avatar={<MailOutlined />}
              description={t("contacts.email")}
              title={t("email")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<TeamOutlined />}
              description={t("contacts.phone")}
              title={t("phone")}
            />
          </List.Item>
        </List>
      </Card>
    </>
  );
};
