import React from "react";
import { useTranslation } from "react-i18next";

import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Tooltip,
  Typography
} from "antd";

interface Props {
  title?: React.ReactNode;

  editable?: boolean;
  editDisabled?: boolean;

  onEdit?: () => void;

  fields?: { label: React.ReactNode; value: React.ReactNode; span?: number }[];
}

const Text = Typography.Text;

export const EmployeeInfoCard: React.FC<Props> = ({
  title,
  fields,
  editable,
  editDisabled,
  onEdit
}) => {
  const [t] = useTranslation();

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Col>{title}</Col>

          {editable && (
            <Col>
              <Tooltip title={t("edit")}>
                <Button
                  type="link"
                  shape="circle"
                  icon={<EditOutlined />}
                  disabled={editDisabled}
                  onClick={onEdit}
                />
              </Tooltip>
            </Col>
          )}
        </Row>
      }
    >
      <Descriptions layout="vertical" colon={false} column={24} size="middle">
        {fields?.map((f) => (
          <Descriptions.Item
            label={<Text type="secondary">{f.label}</Text>}
            style={{ paddingBottom: 0 }}
            span={f.span || 24}
          >
            <Text strong style={{ marginBottom: 10 }}>
              {f.value || "-"}
            </Text>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Card>
  );
};
