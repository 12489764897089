export const ruSubcontractors = {
  subcontractors: "Субподрядчики",

  registerSubcontractor: "Зарегистрировать субподрядчика",

  name: "Название",
  city: "Город",
  address: "Адрес",
  workPhoneNumber: "Рабочий номер телефона",
  contract: "Контракт",
  contracts: "Контракты",
  email: "E-mail",

  firstName: "Имя",
  lastName: "Фамилия",
  jobTitle: "Должность",

  register: "Зарегистрировать",

  registrationSuccess: "Субподрядчик успешно зарегистрирован",

  accessLevel: "Уровень доступа",

  confirmDelete: "Вы уверены, что хотите удалить субподрядчика: {{name}}?",
  subcontractorDeleted: "Субподрядчик удален",
  members: "Сотрудники",

  editSuccess: "Данные субподрядчика сохранены",
  editSubcontractor: "Редактировать субподрядчика"
};
