import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button, Modal, notification, Row, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { craftProfilesApi } from "@api/craftProfilesApi";

import useValidatedMutation from "@hooks/useValidatedMutation";

const { Text } = Typography;

interface Props {
  isModalOpen: boolean;
  profileId: number;
  profileName: string;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
}

const DeleteCraftProfileModal: React.FC<Props> = ({
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  profileId,
  profileName
}) => {
  const [t] = useTranslation();
  const history = useHistory();

  const handleDeleteProfile = useValidatedMutation({
    mutationFunction: () => craftProfilesApi.deleteCraftProfile(profileId),
    onSuccess: () => {
      handleCloseModal();
      history.goBack();
    },
    onError: () => notification.error({ message: t("error.errorOccured") })
  });

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("craftProfiles.deleteProfile")}
        footer={null}
        width={400}
        onCancel={handleCloseModal}
      >
        <Text>
          {t("craftProfiles.deleteProfileConfirm", { name: profileName })}
        </Text>
        <Row justify="space-between" style={{ marginTop: "24px" }}>
          <Button onClick={handleCloseModal}>{t("no")}</Button>
          <Button
            type="primary"
            onClick={handleDeleteProfile.mutate}
            loading={handleDeleteProfile.isLoading}
          >
            {t("yes")}
          </Button>
        </Row>
      </Modal>

      <Button
        type="primary"
        icon={<DeleteOutlined />}
        onClick={handleOpenModal}
        danger
      >
        {t("delete")}
      </Button>
    </>
  );
};

export default DeleteCraftProfileModal;
