import { useEffect } from "react";
import { UseMutateFunction } from "react-query";
import { useTranslation } from "react-i18next";

import moment, { Moment } from "moment-timezone";
import { useFormik } from "formik";

import { EditOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, Row } from "antd";

import { OnlineTrainingReportResult } from "@models/onlineTrainingReports";

interface FormProps {
  name: string;
  completedAt: Moment;
  id?: number;
}
interface Props {
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  currentReport: OnlineTrainingReportResult;
  editReport: UseMutateFunction<unknown, unknown, FormProps>;
  isLoading?: boolean;
  errors: { [key: string]: string };
}

const EditOnlineTrainingReportModal: React.FC<Props> = ({
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  currentReport,
  editReport,
  errors,
  isLoading
}) => {
  const [t] = useTranslation();
  const { id, name, completedAt } = currentReport;
  const formik = useFormik<FormProps>({
    initialValues: {
      name: "",
      completedAt: moment(new Date())
    },
    onSubmit: (values) => editReport({ ...values, id })
  });

  useEffect(() => {
    formik.setValues({
      name: name,
      completedAt: moment(new Date(completedAt))
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport]);

  useEffect(() => {
    formik.setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("reports.editOnlineTrainingReport")}
        footer={null}
        width={800}
        onCancel={handleCloseModal}
      >
        <Form
          layout="vertical"
          onSubmitCapture={() => formik.submitForm()}
          initialValues={{
            name,
            completedAt: moment(completedAt)
          }}
        >
          <Row gutter={[16, 16]}>
            <Col md={18}>
              <Form.Item
                label={t("events.name")}
                validateStatus={formik.errors.name && "error"}
                help={formik.errors.name}
              >
                <Input
                  name="name"
                  placeholder={t("events.name")}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label={t("certificates.completionDate")}
                validateStatus={formik.errors.completedAt && "error"}
                help={formik.errors.completedAt}
              >
                <DatePicker
                  onChange={(value) =>
                    formik.setFieldValue("completedAt", value)
                  }
                  value={
                    formik.values.completedAt
                      ? moment(formik.values.completedAt, "YYYY-MM-DD")
                      : moment(new Date())
                  }
                  style={{ width: "100%" }}
                  format="DD MMM YYYY"
                  placeholder={t("events.filters.selectDate")}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col>
              <Button onClick={handleCloseModal}>{t("cancel")}</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t("edit")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Button icon={<EditOutlined />} onClick={handleOpenModal}>
        {t("edit")}
      </Button>
    </>
  );
};

export default EditOnlineTrainingReportModal;
