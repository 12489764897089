import { FC, useState } from "react";

import Chart, { ChartColor, ChartDataSets } from "chart.js";
import ChartjsDatalabels from "chartjs-plugin-datalabels";

import { useChartjs } from "@hooks/useChartjs";

type ReportBarChartProps = {
  max?: number;
  chartHeight?: number | "100%";
  labels?: string[];
  datasets?: ChartDataSets[];
  showDataLabels: boolean;
};

const ReportVerticalBarChart: FC<ReportBarChartProps> = ({
  max,
  labels,
  datasets,
  showDataLabels,
  chartHeight
}) => {
  // const [chartHeight, setChartHeight] = useState<number>(props.);
  const [chartOptions] = useState<Chart.ChartConfiguration>({
    type: "bar",
    plugins: [ChartjsDatalabels],
    data: {
      labels: labels,
      datasets: datasets
    },
    options: {
      hover: {
        axis: "x",
        mode: "nearest",
        animationDuration: 400,
        intersect: true
      },
      onHover(ev: MouseEvent, points: any[]) {
        return;
      },
      tooltips: {
        filter: (data) => data.yLabel !== null,
        intersect: true,
        mode: "index",
        axis: "x",
        position: "average",
        caretPadding: 2,
        displayColors: true,
        borderColor: "rgba(0,0,0,0)",
        borderWidth: 1,
        titleAlign: "center",
        callbacks: {
          title: ([point]) => {
            return point && point.label ? point.label : "";
          },
          label(tooltipItem, data) {
            const datasetsLength = data.datasets?.length ?? 0;
            if (datasetsLength === 1) {
              return `: ${tooltipItem.value}`;
            }
            const label =
              data.datasets &&
              data.datasets[tooltipItem.datasetIndex ?? 0].label;
            if (tooltipItem.datasetIndex === datasetsLength - 1) {
              const nums =
                (data.datasets &&
                  data.datasets.map(
                    (ds) =>
                      (ds.data &&
                        tooltipItem.index !== undefined &&
                        ds.data[tooltipItem.index]) ||
                      0
                  )) ||
                [];
              const sum = nums
                .map((n) => (typeof n === "number" ? n : 0))
                .reduce((s, next) => {
                  return s + next;
                }, 0);
              return `${label ?? ""}: ${sum}`;
            }
            return `${label ?? ""}: ${tooltipItem.value}`;
          }
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (_label) => _label,
              beginAtZero: true,
              sampleSize: 10,
              display: false,
              max
            },
            gridLines: {
              display: false
            },
            stacked: true
          }
        ],
        xAxes: [
          {
            offset: true,
            ticks: {
              callback: (_label) => {
                return _label;
              },
              sampleSize: 10
            },
            gridLines: {
              display: true
            }
          }
        ]
      },
      elements: {
        rectangle: {
          backgroundColor(ctx) {
            if (
              ctx.dataset &&
              typeof ctx.dataset.backgroundColor === "function"
            ) {
              return ctx.dataset.backgroundColor(ctx);
            }

            if (ctx.dataset && Array.isArray(ctx.dataset.backgroundColor)) {
              return ctx.dataset.backgroundColor[0] || "red";
            }

            if (!ctx.dataset) {
              return "red";
            }

            return (
              (ctx.dataset.backgroundColor as ChartColor | string) || "red"
            );
          }
        }
      },
      legend: {
        display: false,
        position: "bottom"
      },
      devicePixelRatio: 2,
      plugins: {
        datalabels: {
          display: (context) => {
            return (
              context.datasetIndex + 1 ===
                context.chart.data.datasets?.length && showDataLabels
            );
          },
          align: () => "end",
          anchor: (context) => "end",
          formatter(value, ctx) {
            if (
              ctx.chart.data.datasets &&
              ctx.chart.data.datasets.length > 1 &&
              ctx.chart.data.datasets[0].data
            ) {
              const datasetValues = new Array(
                ctx.chart.data.datasets[0].data.length
              ).fill(0);
              // eslint-disable-next-line array-callback-return
              ctx.chart.data.datasets.forEach((ds) => {
                if (ds.data) {
                  datasetValues[ctx.dataIndex] += ds.data[ctx.dataIndex];
                }
              });
              return datasetValues[ctx.dataIndex] === 0
                ? ""
                : datasetValues[ctx.dataIndex];
            }
            return value === 0 || !value ? "" : value;
          },
          font: {
            weight: "bold"
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false
    }
  });

  const { chartContext } = useChartjs(chartOptions);

  // useEffect(() => {
  //   setChartHeight(labels ? labels.length * 40 + 40 : 0);
  // }, [labels]);

  return (
    <div style={{ height: chartHeight }}>
      <canvas ref={chartContext} />
    </div>
  );
};

export default ReportVerticalBarChart;

ReportVerticalBarChart.defaultProps = {
  showDataLabels: false
};
