import React from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { saveAs } from "file-saver";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import { Button, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

interface ExportMembersButtonProps {
  url: string;
  fileName: string;
  size?: SizeType;
}

const ExportMembersButton: React.FC<ExportMembersButtonProps> = ({
  url,
  fileName,
  size = "middle"
}) => {
  const [t] = useTranslation();
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleDownload = () => {
    setIsDownloading(true);
    axios
      .get(url, { responseType: "arraybuffer" })
      .then((response) => {
        setIsDownloading(false);
        return saveAs(new Blob([response.data]), fileName);
      })
      .catch(() => {
        setIsDownloading(false);
        notification.error({
          message: t("error.errorDownload")
        });
      });
    return false;
  };

  return (
    <Button
      type="primary"
      size={size}
      onClick={handleDownload}
      icon={<DownloadOutlined />}
      loading={isDownloading}
    >
      {t("members.userExport")}
    </Button>
  );
};

export default ExportMembersButton;
