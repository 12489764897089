import picture34 from "@assets/images/user-guide/2.34.png";
import picture35 from "@assets/images/user-guide/2.35.png";

export const profile = [
  {
    title: "What`s on Profiles tab:",
    html: `<p dir="ltr">
    Profile tab shows job- seeking craft worker profiles, which were registered
    in the system so that Contractor/ Subcontractor users could view and hire
    profiles. This module also stores all the information in the system.
</p>
<p dir="ltr">
    Remember, in order to create a craft employee profile in the HR module, the
    craft employee must be already registered within the CT Portal via CSR
    Contractors.
</p>
<br/>
<p dir="ltr">
    Page example:
</p>
<p dir="ltr">
    Picture 2.34
</p>
<p dir="ltr">
    <img
        src=${picture34}
        width="624"
        height="774.9635815620422"
    />
</p>
`
  },
  {
    title: "How to create a profile?",
    html: `<p dir="ltr">
    To create a craft employee profile:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            TC user should press the Profile tab on the panel menu.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System will show a page of all profiles
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user should press “Create profile” button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System will show modal window with a field to enter craft worker\`s
            IIN
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user should enter craft worker\`s IIN and press ‘Search by IIN’
            button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Next, system searches for IIN
        </p>
    </li>
</ol>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            If registered, system will show craft worker\`s name in the modal
            window
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If not, system will show “Employee not found in the system or
            incorrect IIN” message. You will not be able to create a profile in
            this case.
        </p>
    </li>
</ul>
<ol start="7">
    <li dir="ltr">
        <p dir="ltr">
            TC user presses Next button;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System shows fields* to be filled out by TC user as shown on the
            screenshot below;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user fills out fields
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user presses Create button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System creates a craft employee profile
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 2.35
</p>
<p dir="ltr">
    <img
        src=${picture35}
        width="624"
        height="660"
    />
</p>
<br/>
<br/>
<p dir="ltr">
    The following fields are filled out in order to create a profile:
</p>
<br/>
<div dir="ltr" align="left">
    <table>
        <colgroup>
            <col width="133"/>
            <col width="171"/>
            <col width="46"/>
            <col width="273"/>
        </colgroup>
        <tbody>
            <tr>
                <td>
                    <p dir="ltr">
                        Field ENG
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Field RU
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M/O
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Description
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Name
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Имя (ФИО)
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        М
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        pre-filled from the craft employee data in the system.
                    </p>
                    <p dir="ltr">
                        See pre-condition
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR Contractor
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Подрядчик КСО
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Dropdown menu:
                    </p>
                    <p dir="ltr">
                        CSR Aktau,
                    </p>
                    <p dir="ltr">
                        CSR Atyrau,
                    </p>
                    <p dir="ltr">
                        Demob
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Discipline
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дисциплина
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Only one can be set.
                    </p>
                    <p dir="ltr">
                        Dropdown list: all disciplines of CT portal
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Home address
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Адрес
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Tel. number
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Номер телефона
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        pre-filled from the craft employee data in the system.
                    </p>
                    <p dir="ltr">
                        See pre-condition
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Date of birth
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дата рождения
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        pre-filled from the craft employee data in the system.
                    </p>
                    <p dir="ltr">
                        See pre-condition
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Marital status
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Семейное положение
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        М
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Dropdown menu:
                    </p>
                    <p dir="ltr">
                        Married/ Single
                    </p>
                    <p dir="ltr">
                        Widower/Widow
                    </p>
                    <hr/>
                    <p dir="ltr">
                        Женат/Замужем
                    </p>
                    <p dir="ltr">
                        Не женат/ Не замужем
                    </p>
                    <p dir="ltr">
                        Вдовец/Вдова
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Education
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Образование
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Additional education
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дополнительные курсы
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Work experience
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Рабочий стаж
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Language
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Знание языков
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        M
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Dropdown
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Personal qualities
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Личные качества
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        O
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Additional information
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дополнительные сведения
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        O
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        free text field
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<br/>
`
  },
  {
    title: "How to edit a profile?",
    html: `<p dir="ltr">
    To edit a craft employee profile:
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Go to Profiles tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user selects a craft employee profile
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user presses edit profile button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC User updates information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC User presses Save button, system saves updated data
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to view a profile?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            Go to Profiles tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user selects a craft employee profile
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System shows selected craft employee\`s profile
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to download a profile?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            Go to Profiles tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user selects a craft employee profile
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System shows selected craft employee\`s profile
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to use filters?",
    html: `<p dir="ltr">
    System lets Users filter HR module data. List of filters available is
    specified below.
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            System shows All profile page and filters
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user sets filters
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            TC user presses Search button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To clear filters User presses ‘Clear filters’ data
        </p>
    </li>
</ol>
`
  },
  {
    title: "What filters are available?",
    html: `<div dir="ltr" align="left">
    <table>
        <colgroup>
            <col width="91"/>
            <col width="92"/>
            <col width="261"/>
            <col width="180"/>
        </colgroup>
        <tbody>
            <tr>
                <td>
                    <p dir="ltr">
                        Filters ENG
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Filters RU
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Values/ Description
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Employment status
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Статус трудовой занятости
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employed/ Unemployed
                    </p>
                    <p dir="ltr">
                        Трудоустроен/ Не трудоустроен
                    </p>
                    <p dir="ltr">
                        Single select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                    <p dir="ltr">
                        Contractor/Subcontractor can only see Unemployed
                        profiles. This filter is not shown for Contractor/
                        Subcontractor users.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Discipline
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дисциплина
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        List of all disciplines in CT portal
                    </p>
                    <p dir="ltr">
                        Multi select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR phase
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Фаза КСО
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        None, CSR 1- 50
                        <a
                            href="https://petrelai.atlassian.net/browse/CTN-1040"
                        >
                        </a>
                    </p>
                    <p dir="ltr">
                        Multi select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR Contractor
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Подрядчик КСО
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        CSR Aktau, CSR Atyrau or Demob
                    </p>
                    <p dir="ltr">
                        КСО Актау, КСО Атырау, Демоб
                    </p>
                    <p dir="ltr">
                        Multi select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        IIN
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        ИИН
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Craft Employee IIN input field
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown to TC &amp; TCO users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Clear all button
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Очистить
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        clears all filters set
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Search button
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Поиск
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        starts the filtration/ search
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
`
  }
];
