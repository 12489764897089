import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Select, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { FilterRequestsData } from "@models/requests";
import { TrainingCentersMap } from "@models/trainingCenter";
import { ContractorsMap } from "@models/contractor";
import { TrainingCenterMember } from "@models/trainingCenterMember";

interface RequstsFilterProps {
  onFilter: (values: FilterRequestsData) => void;
  trainingCenters?: TrainingCentersMap;
  contractors?: ContractorsMap;
  assignees?: TrainingCenterMember[];
  initialValues?: FilterRequestsData;
}

const RequestsFilter: React.FC<RequstsFilterProps> = (props) => {
  const [t] = useTranslation();

  const formik = useFormik<FilterRequestsData>({
    initialValues: {
      DateFrom: undefined,
      DateTo: undefined,
      TrainingCenterIds: props.trainingCenters ? [] : undefined,
      ContractorIds: props.contractors ? [] : undefined,
      AssigneeIds: props.assignees ? [] : undefined,
      TicketId: undefined,
      ...props.initialValues
    },
    onSubmit(values) {
      if (props.onFilter) {
        props.onFilter({
          ...values,
          DateFrom: values.DateFrom
            ? moment(values.DateFrom).startOf("day").toISOString()
            : undefined,
          DateTo: values.DateTo
            ? moment(values.DateTo).endOf("day").toISOString()
            : undefined
        });
      }
    }
  });

  return (
    <Row gutter={8} align="bottom">
      <Col md={4}>
        <Form.Item
          label={t("requests.dateFrom")}
          labelCol={{ span: 24 }}
          validateStatus={formik.errors.DateFrom && "error"}
          help={formik.errors.DateFrom}
        >
          <DatePicker
            name="DateFrom"
            style={{ width: "100%" }}
            onChange={(_, value) => formik.setFieldValue("DateFrom", value)}
            value={
              formik.values.DateFrom
                ? moment(formik.values.DateFrom, "YYYY-MM-DD")
                : undefined
            }
          />
        </Form.Item>
      </Col>
      <Col md={4}>
        <Form.Item
          label={t("requests.dateTo")}
          labelCol={{ span: 24 }}
          validateStatus={formik.errors.DateTo && "error"}
          help={formik.errors.DateTo}
        >
          <DatePicker
            name="DateTo"
            style={{ width: "100%" }}
            value={
              formik.values.DateTo
                ? moment(formik.values.DateTo, "YYYY-MM-DD")
                : undefined
            }
            onChange={(_, value) => formik.setFieldValue("DateTo", value)}
          />
        </Form.Item>
      </Col>
      {props.trainingCenters && (
        <Col md={4}>
          <Form.Item
            label={t("trainingCenters.trainingCenters")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.TrainingCenterIds && "error"}
            help={formik.errors.TrainingCenterIds}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={(value) =>
                formik.setFieldValue("TrainingCenterIds", value)
              }
              value={formik.values.TrainingCenterIds}
              maxTagCount={1}
            >
              {Object.entries(props.trainingCenters || {}).map(
                ([id, trainingCenterName]) => (
                  <Select.Option key={id} value={id}>
                    {trainingCenterName}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        </Col>
      )}
      {props.contractors && (
        <Col md={4}>
          <Form.Item
            label={t("contractors.contractors")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.ContractorIds && "error"}
            help={formik.errors.ContractorIds}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={(value) => formik.setFieldValue("ContractorIds", value)}
              value={formik.values.ContractorIds}
              maxTagCount={1}
            >
              {Object.entries(props.contractors || {}).map(
                ([id, contractorName]) => (
                  <Select.Option key={id} value={id}>
                    {contractorName}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        </Col>
      )}
      {props.assignees && (
        <Col md={4}>
          <Form.Item
            label={t("requests.assignee")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.AssigneeIds && "error"}
            help={formik.errors.AssigneeIds}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={(value) => formik.setFieldValue("AssigneeIds", value)}
              value={formik.values.AssigneeIds}
              maxTagCount={1}
            >
              {props.assignees.map((assignee) => (
                <Select.Option key={assignee.userId} value={assignee.userId}>
                  {assignee.firstName} {assignee.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Col md={4}>
        <Form.Item
          label={t("requests.requestId")}
          labelCol={{ span: 24 }}
          validateStatus={formik.errors.TicketId && "error"}
          help={formik.errors.TicketId}
        >
          <Input
            name="TicketId"
            value={formik.values.TicketId}
            onChange={formik.handleChange}
          />
        </Form.Item>
      </Col>
      <Col md={3}>
        <Form.Item labelCol={{ span: 24 }}>
          <Button
            style={{ width: "100%" }}
            icon={<SearchOutlined />}
            onClick={formik.submitForm}
            type="primary"
          >
            {t("requests.filter")}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default RequestsFilter;
