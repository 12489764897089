export const general = [
  {
    title: "Edit my profile",
    html: `
      <ol>
        <li>
            <p>Log in to the system</p>
        </li>
        <li>
            <p>Press <b>Edit profile</b> button on your Account info page</p>
        </li>
        <li>
            <p>Update first or last name and press Change button</p>
        </li>
      </ol>
`
  },
  {
    title: "Change password",
    html: `
      <ol>
    <li>Log in to the system</li>
    <li>Press on <b>Change password</b> button on your Account info page</li>
</ol>
<p><br></p>
<p><b>Password requirements:</b></p>
<p>Password Length - minimum of 8 characters</p>
<p>Password Complexity must be composed of a combination of at least two of the following character types:&nbsp;</p>
<ul>
    <li>
        <p>uppercase letters</p>
    </li>
    <li>
        <p>lower case letters</p>
    </li>
    <li>
        <p>numbers</p>
    </li>
    <li>
        <p>symbols</p>
    </li>
</ul>
`
  },
  {
    title: "Change language",
    html: `<p>On the panel menu press on Language switcher to change interface language from English to Russian or vice versa. <br/> <b>Note:</b> By default interface language set by your system settings.</p>`
  },
  {
    title: "About us",
    html: `<p> On this page you can see information about Integrated Training Team, Vision, Objectives and Contact persons.</p>`
  },
  {
    title: "User Guide",
    html: `<p>Manual of using this portal. Main features described on this page.</p>`
  },
  {
    title: "What’s new",
    html: `<p>On this page we describe what we added to the latest version.</p>`
  },
  {
    title: "Contact Us",
    html: `<p>By pressing on the Contact Us tab you can contact the Portal Admin.</p>`
  },
  {
    title: "Portal time",
    html: `<p>Portal time shows UTC +6.</p>`
  }
];
