import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { AxiosError } from "axios";

import {
  UserOutlined,
  PhoneOutlined,
  FlagOutlined,
  ShopOutlined,
  PushpinOutlined,
  FileAddOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  notification,
  PageHeader,
  Row,
  Modal,
  Typography
} from "antd";
import PageMeta from "@components/PageMeta";
import { SubcontractorTable } from "@components/contractors/subcontractors/SubcontractorTable";
import { BreadcrumbRenderer } from "@components/BreadcrumbRenderer";
import ContractsTable from "@components/contractors/ContractsTable";
import ContractForm from "@components/admin/contractors/ContractForm";
import { Section, SubSection, Div } from "@components/Sections";

import {
  AddContractPayload,
  Contract,
  EditContractPayload
} from "@models/contract";
import { PROJECTS } from "@models/projects";

import { contractorsApi } from "@api/contractorsApi";
import { contractApi } from "@api/contractApi";
import { subcontractorApi } from "@api/subcontractorApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";
import { useDefaultQuery } from "@hooks/useDefaultQuery";
import useDateFormatter from "@hooks/useDateFormat";

import MembersSection from "./components/MembersSection";

interface ContractorPageParams {
  id: string;
}

export const ContractorPage: React.FC = () => {
  const [t, i18n] = useTranslation();
  const [isAddContractFormVisible, setIsAddContractFormVisible] =
    useState(false);
  const [editableContract, setEditableContract] = useState<Contract | null>();
  const { id } = useParams<ContractorPageParams>();
  const contractorId = Number(id);
  const { formatter } = useDateFormatter();

  const contractorDetails = useDefaultQuery("getContractor", () =>
    contractorsApi.getContractor(contractorId).then((res) => res.data)
  );

  const contractorContracts = useDefaultQuery("getContractorContracts", () =>
    contractApi.getContractorContracts(contractorId).then((res) => res.data)
  );

  const projects = useDefaultQuery("getProjects", () =>
    contractApi.getProjects().then((res) => res.data)
  );
  const filteredProjects =
    projects.data?.filter(
      (p) =>
        p.id === PROJECTS._3GP ||
        p.id === PROJECTS._3GI ||
        p.id === PROJECTS.GATHERING ||
        p.id === PROJECTS.CONTROL_AND_POWER ||
        p.id === PROJECTS.CORE_TEAM ||
        p.id === PROJECTS.CSR ||
        p.id === PROJECTS.CONSTRUCTION_OV ||
        p.id === PROJECTS.MASTER_SERVICES ||
        p.id === PROJECTS.BB
    ) || [];

  const kcCategories = useDefaultQuery("getKcCategories", () =>
    contractApi.getKcCategories().then((res) => res.data)
  );

  const filteredKcCategories =
    kcCategories.data?.filter((c) => c.id !== 0) || [];

  const addContractMutation = useValidatedMutation({
    mutationFunction: (payload: AddContractPayload) =>
      contractApi.addContract(payload),
    onSuccess: () => {
      notification.success({
        message: t("contract.contractAdded")
      });
      contractorContracts.refetch();
      setIsAddContractFormVisible(false);
    }
  });
  const addContractErrors = useMemo(
    () => transformValidationError(addContractMutation.error),
    [addContractMutation.error]
  );

  const editContractMutation = useValidatedMutation({
    mutationFunction: (payload: EditContractPayload) =>
      contractApi.editContract(payload),
    onSuccess: (_, { contractNumber }) => {
      notification.success({
        message: t("contract.contractEdited", { contractNumber })
      });
      contractorContracts.refetch();
      setEditableContract(null);
    }
  });
  const editContractErrors = useMemo(
    () => transformValidationError(editContractMutation.error),
    [editContractMutation.error]
  );

  const deleteContractMutation = useMutation(
    (contract: Contract) => contractApi.deleteContract(contract.id),
    {
      onSuccess(_, { contractNumber }) {
        notification.success({
          message: t("contract.contractDeleted", { contractNumber })
        });
        contractorContracts.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );
  const contractorSubcontractors = useDefaultQuery(
    ["getContractorSubcontractors", contractorId],
    () =>
      subcontractorApi
        .getSubcontractorPage({ contractorId })
        .then((res) => res.data)
  );

  const renderContractorTitle = () => {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {t("contractors.contractor") + " " + contractorDetails.data?.name}
        </Typography.Title>

        <Typography.Text type="secondary" style={{ marginLeft: "12px" }}>
          {t("contractors.registrationDate")}:{" "}
          {contractorDetails.data
            ? formatter(contractorDetails.data.createdAt)
            : ""}
        </Typography.Text>
      </div>
    );
  };

  const pageTitle = contractorDetails.data?.name || "";
  return (
    <>
      <PageMeta title={pageTitle} />
      <PageHeader
        breadcrumb={{
          routes: [
            { breadcrumbName: "CT", path: "/" },
            {
              breadcrumbName: t("contractors.contractors"),
              path: "/admin/contractors"
            },
            {
              breadcrumbName: pageTitle,
              path: ""
            }
          ],
          itemRender: BreadcrumbRenderer
        }}
      />
      <Card
        title={renderContractorTitle()}
        style={{ marginBottom: 32 }}
        loading={contractorDetails.isFetching}
      >
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <UserOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>{contractorDetails.data?.bin}</Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.bin")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <PhoneOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {contractorDetails.data?.phone || "-"}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.workPhoneNumber")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <FlagOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {i18n.language === "en"
                    ? contractorDetails.data?.country.nameEn
                    : contractorDetails.data?.country.nameRu}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.country")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <ShopOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {i18n.language === "en"
                    ? contractorDetails.data?.city.nameEn
                    : contractorDetails.data?.city.nameRu}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.city")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 16 }} gutter={8}>
          <Col md={2}>
            <div style={{ fontSize: "24px" }}>
              <PushpinOutlined />
            </div>
          </Col>
          <Col md={12}>
            <Div>
              <div>
                <Typography.Text>
                  {contractorDetails.data?.address}
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                {t("contractors.address")}
              </Typography.Text>
            </Div>
          </Col>
        </Row>
      </Card>
      <Section>
        <SubSection>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              key="2"
              type="primary"
              icon={<FileAddOutlined />}
              onClick={() => setIsAddContractFormVisible(true)}
            >
              {t("contract.addContract")}
            </Button>
          </div>
        </SubSection>
        <SubSection>
          <ContractsTable
            title={t("contract.contracts")}
            data={contractorContracts.data}
            isLoading={contractorContracts.isLoading}
            projects={filteredProjects}
            kcCategories={filteredKcCategories}
            onEdit={(contract: Contract) => setEditableContract(contract)}
            onDelete={(contract: Contract) =>
              deleteContractMutation.mutate(contract)
            }
          />
        </SubSection>
      </Section>

      <MembersSection contractorId={contractorId} />

      <Section>
        <SubcontractorTable
          title={t("subcontractors.subcontractors")}
          subcontractors={contractorSubcontractors.data?.results}
          itemLinkTo={(sub) =>
            `/admin/contractors/${contractorId}/subcontractor/${sub.id}`
          }
        />
      </Section>

      <Modal
        visible={isAddContractFormVisible}
        destroyOnClose
        maskClosable={false}
        title={t("contract.addContract")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsAddContractFormVisible(false);
          addContractMutation.reset();
        }}
      >
        <ContractForm
          contractorId={contractorId}
          projects={filteredProjects}
          kcCategories={filteredKcCategories}
          isLoading={addContractMutation.isLoading}
          errors={addContractErrors}
          onCancel={() => {
            setIsAddContractFormVisible(false);
            addContractMutation.reset();
          }}
          onSubmit={(values) => {
            addContractMutation.mutate(values);
          }}
        />
      </Modal>
      <Modal
        visible={!!editableContract}
        destroyOnClose
        maskClosable={false}
        title={t("contract.editContract")}
        footer={null}
        width={800}
        onCancel={() => {
          setEditableContract(null);
          editContractMutation.reset();
        }}
      >
        <ContractForm
          contract={editableContract}
          projects={filteredProjects}
          kcCategories={filteredKcCategories}
          isLoading={editContractMutation.isLoading}
          errors={editContractErrors}
          onCancel={() => {
            setEditableContract(null);
            editContractMutation.reset();
          }}
          onSubmit={(values) => {
            editContractMutation.mutate(values);
          }}
        />
      </Modal>
    </>
  );
};
