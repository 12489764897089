import { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import qs from "qs";

type Pagination = {
  totalItems: number;
  pageSize: number;
  currentPage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterParams?: Record<string, any>;
  // any value can be used
};

export const usePagination = () => {
  const history = useHistory();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<Pagination>({
    totalItems: 0,
    pageSize: 0,
    currentPage: 0
  });

  useEffect(() => {
    const currentPageNumber = Number(queryParams.get("page")) || 1;
    setPage(currentPageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { totalItems, pageSize, currentPage, filterParams } = pagination;

    history.push({
      search: qs.stringify(
        { ...filterParams, page: currentPage },
        { arrayFormat: "indices" }
      )
    });

    // if, for example totalItems = 11, pageSize = 10 and user, located on the 2nd page deletes the very last item of the Array
    // the code below will understand that the 2nd page contains no data and will push user to the 1st page.
    if (Math.ceil(totalItems / pageSize) < currentPage && page !== 1) {
      setPage(page - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return { page, setPage, setPagination };
};
