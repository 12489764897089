/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import { UploadFile } from "antd/lib/upload/interface";

import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import EventDateTimeRangePicker from "@components/common/events/forms/EventDateTimeRangePicker";

import { CsrPhase } from "@models/event";
import { TrainingCenterReport } from "@models/trainingCenter";

import { trainingCenterApi } from "@api/trainingCenterApi";

import { convertNotificationEntities } from "@utils/eventHelper";
import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

interface FormValues {
  type?: number;
  name?: string;
  dateFrom?: string;
  dateTo?: string;
  description?: string;
  csrPhase?: CsrPhase;
  files?: UploadFile[];
}

interface Props {
  isLoading?: boolean;
  errors?: { [key: string]: string };
  reportType?: number;
  report?: TrainingCenterReport;
  reportId: number;
  tcId: number;
  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

export const EditTrainingReportForm = (props: Props) => {
  const [t] = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const formik = useFormik<FormValues>({
    initialValues: {
      type: undefined,
      name: undefined,
      csrPhase: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      description: undefined,
      files: undefined
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(convertNotificationEntities(values));
      }
    }
  });

  useEffect(() => {
    if (props.report) {
      const r = props.report;
      formik.setValues({
        type: props.reportType,
        name: r.name,
        csrPhase: r.csrPhase,
        dateFrom: r.dateFrom,
        dateTo: r.dateTo,
        description: r.description
      });
    }
    // eslint-disable-next-line
  }, [props.report]);

  useEffect(() => {
    if (props.report && props.report.attachmentFiles) {
      const files = props.report.attachmentFiles;

      const filesApi = files.map((item, idx) => {
        return trainingCenterApi
          .getTCReportFile(props.tcId, props.reportId, item.id)
          .then((res) => {
            return {
              originFileObj: new File([res as BlobPart], item.fileName),
              uid: item.uniqueStorageKey,
              name: item.fileName,
              fileName: item.fileName,
              size: res.size,
              type: res.type
            };
          });
      });

      Promise.all(filesApi).then((values) => {
        formik.setFieldValue("files", values);
        setFileList(values as UploadFile[]);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [props.report?.attachmentFiles?.length]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={8}>
        <Form.Item>
          <Input name="type" value={props.reportType} type="hidden" />
        </Form.Item>
        <Col md={12}>
          <Form.Item
            label={t("events.name")}
            validateStatus={formik.errors.name && "error"}
            help={formik.errors.name}
          >
            <Input
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("events.csrPhase")}
            validateStatus={formik.errors.csrPhase && "error"}
            help={formik.errors.csrPhase}
          >
            <Select
              value={formik.values.csrPhase}
              onChange={(v) => formik.setFieldValue("csrPhase", v)}
            >
              {CSR_PHASE_OPTIONS.map((cp) => (
                <Select.Option value={cp.value} key={cp.value}>
                  {t(cp.label, { no: cp.value })}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("events.description")}
            validateStatus={formik.errors.description && "error"}
            help={formik.errors.description}
          >
            <Input.TextArea
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <EventDateTimeRangePicker
          onStartChange={(v) =>
            formik.setFieldValue("dateFrom", v?.toISOString())
          }
          onEndChange={(v) => formik.setFieldValue("dateTo", v?.toISOString())}
          dateTimeStart={formik.values.dateFrom}
          dateTimeEnd={formik.values.dateTo}
          dateTimeStartError={formik.errors.dateFrom}
          dateTimeEndError={formik.errors.dateTo}
        />

        <Col md={12}>
          <Upload
            multiple
            maxCount={3}
            accept=".xlsx,.pdf,.docx,.zip"
            beforeUpload={() => {
              return false;
            }}
            onChange={(ev) => {
              formik.setFieldValue("files", ev.fileList);
              setFileList(ev.fileList);
            }}
            onRemove={(f) => {
              const nextFormVal = formik.values.files?.filter(
                (file) => file !== f
              );

              formik.setFieldValue("files", nextFormVal);
              setFileList(nextFormVal as UploadFile[]);
            }}
            fileList={fileList}
          >
            <Button type="dashed" icon={<UploadOutlined />}>
              {t("events.uploadFile")}
            </Button>
          </Upload>
        </Col>
      </Row>

      <br />

      <Row justify="space-between">
        <Col>
          <Button onClick={() => props.onCancel && props.onCancel()}>
            {t("cancel")}
          </Button>
        </Col>

        <Col>
          <Button
            type="primary"
            htmlType="submit"
            loading={props.isLoading || isLoading}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
