import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Select, Space } from "antd";

import { Discipline } from "@models/discipline";
import { Instructor } from "@models/instructor";

const { Option } = Select;

interface FormValues {
  trainingCenterId: number;
  instructorId?: number;
  firstName: string;
  lastName: string;
  specialities: number[];
}

interface Props {
  trainingCenterId: number;
  instructor?: Instructor | null;
  trainingCenterSpecialities: Discipline[];
  errors?: { [key: string]: string };
  isLoading?: boolean;
  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

const InstructorForm: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();
  const formik = useFormik<FormValues>({
    initialValues: {
      trainingCenterId: props.trainingCenterId,
      firstName: "",
      lastName: "",
      specialities: []
    },
    onSubmit: (values) => {
      if (props.onSubmit) {
        if (props.instructor) {
          props.onSubmit({
            ...values,
            trainingCenterId: props.trainingCenterId
          });
        } else {
          const { instructorId, ...createPayload } = values;
          props.onSubmit({
            ...createPayload,
            trainingCenterId: props.trainingCenterId
          });
        }
      }
    }
  });

  useEffect(() => {
    if (props.instructor) {
      formik.setValues({
        trainingCenterId: props.trainingCenterId,
        instructorId: props.instructor.id,
        firstName: props.instructor.firstName || "",
        lastName: props.instructor.lastName || "",
        specialities: props.instructor.specialityIds || []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.instructor]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label={t("firstName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.firstName && "error"}
            help={formik.errors.firstName}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("lastName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.lastName && "error"}
            help={formik.errors.lastName}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("disciplines.disciplines")}
            validateStatus={formik.errors.specialities && "error"}
            help={formik.errors.specialities}
          >
            <Select
              allowClear
              maxTagCount={3}
              mode="multiple"
              onChange={(v) => formik.setFieldValue("specialities", v)}
              value={formik.values.specialities}
              showSearch
              optionFilterProp="name"
              filterOption={(inp, opt) => {
                if (opt && opt.children) {
                  return (
                    opt.children
                      .toString()
                      .toLowerCase()
                      .indexOf(inp.toLowerCase()) >= 0
                  );
                }

                return false;
              }}
            >
              {props.trainingCenterSpecialities?.map((discipline) => (
                <Option key={discipline.id} value={discipline.id}>
                  {i18n.language === "en"
                    ? discipline.nameEn
                    : discipline.nameRu}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("save")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default InstructorForm;
