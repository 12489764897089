export const enCtWhatNew = [
  {
    title:
      "New feature: Candidate profiles: create, view, download profiles of local unemployed Training Centers` event participants as part of TCO CSR program. \n" +
      "Available for TCO, Contractors and Training Center Users.\n",
    html: `<p><span style="font-weight: 400;">Profiles module of CT Portal shows job- seeking craft worker profiles, which were created in the system so that Contractor/ Subcontractor users could view and hire profiles. This module also stores all the information in the system.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Remember, in order to create a craft employee profile in the HR module, the craft employee must be already registered within the CT Portal via CSR Contractor. </span></p>`
  },
  {
    title:
      "New feature: Changes into grading and evaluation functionality, including implementation of multi-phase event grading system: Activation.",
    html: `<p><span style="font-weight: 400;">Event activate feature lets Training Center users to separately put grades for craft employees when the event is in Completed or Evaluated status.&nbsp;</span></p>
<p><span style="font-weight: 400;">Training Center users are able to put grades after TCO user\`s event activation.</span></p>`
  },
  {
    title:
      "Changes to eligibility requirements: the 1-month rule has been removed  for craft employees to reseat the event",
    html: `<p><span style="font-weight: 400;">With this change craft employees are not required to wait for one month to re-apply to the same type&amp; discipline of the event.</span></p>`
  },
  {
    title:
      "New feature: Addition of the 'location' field for all Event types: in Performance reports, Data Dump report, Report filters.",
    html: `TC Users are now able to specify location of the event including online and Tengiz. Do not mix up with the Training Center\`s city.`
  },
  {
    title: "Changes into Reports & Data Dump",
    html: `<p><span style="font-weight: 400;">New functionality and some minor changes have been added to Reports and Data Dump.</span></p>
<p>&nbsp;</p>`
  }
];
