import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Select, Space } from "antd";

import { TrainingCentersMap } from "@models/trainingCenter";
import { CreateRequestData } from "@models/requests";

interface Props {
  errors?: { [key: string]: string };

  isLoading?: boolean;
  trainingCenters?: TrainingCentersMap;

  onSubmit?: (values: CreateRequestData) => void;
  onCancel?: () => void;
}

export const NewRequestForm = (props: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<CreateRequestData>({
    initialValues: {
      subject: "",
      text: "",
      trainingCenterIds: [],
      attachmentFile: ""
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("requests.subject")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.subject && "error"}
            help={formik.errors.subject}
          >
            <Input
              name="subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("trainingCenters.trainingCenters")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.trainingCenterIds && "error"}
            help={formik.errors.trainingCenterIds}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={(value) =>
                formik.setFieldValue("trainingCenterIds", value)
              }
              value={formik.values.trainingCenterIds}
              maxTagCount={3}
            >
              {Object.entries(props.trainingCenters || {}).map(
                ([id, trainingCenterName]) => (
                  <Select.Option key={id} value={id}>
                    {trainingCenterName}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("requests.text")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.text && "error"}
            help={formik.errors.text}
          >
            <Input.TextArea
              name="text"
              value={formik.values.text}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("requests.attachmentFile")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.attachmentFile && "error"}
            help={formik.errors.attachmentFile}
          >
            <Input
              name="attachmentFile"
              type="file"
              onChange={(event) => {
                if (event.currentTarget.files?.length) {
                  formik.setFieldValue(
                    "attachmentFile",
                    event.currentTarget.files[0]
                  );
                } else {
                  formik.setFieldValue("attachmentFile", undefined);
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <br />

          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("requests.create")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
