import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AxiosError } from "axios";
import { useFormik } from "formik";

import {
  Card,
  Col,
  Row,
  Button,
  Input,
  Typography,
  Form,
  notification
} from "antd";

import userApi from "@api/userApi";

import { useQueryParams } from "@hooks/useQueryParams";
import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";

const { Title, Text } = Typography;

const CardTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title level={3}> {t("acceptInvite.confirmInvite")} </Title>
      <Text style={{ fontSize: 14 }} disabled>
        {t("acceptInvite.enterNewPassword")}
      </Text>
    </>
  );
};

interface FormValues {
  token: string;
  password: string;
  passwordConfirmation: string;
}

const AcceptInvitePage: React.FC = () => {
  const { t } = useTranslation();
  const { loadUser } = useContext(UserContext);
  const { token: tokenParam } = useQueryParams();
  const history = useHistory();

  useQuery(
    "checkInviteToken",
    () => {
      // make request to check invite token endpoint
    },
    {
      enabled: false,
      retry: false,
      refetchOnMount: true,
      onError: (err: AxiosError<any>) => {
        console.log({ err });
        if (err.response?.status && err.response.status < 500) {
          switch (err.response.status) {
            case 404:
              notification.error({
                message: t("acceptInvite.linkIsNoMoreValid")
              });
          }
        }
      }
    }
  );

  const mutation = useValidatedMutation<FormValues>({
    mutationFunction: (values: FormValues) =>
      userApi.confirmAcceptInvite(values).then((res) => res.data),

    onSuccess: async (data) => {
      localStorage.setItem("CT_PORTAL_USER_TOKEN", data.apiToken);
      await loadUser();
      history.replace("/profile");
    },
    onError: (err) => {
      formik.setErrors(err);
    }
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      token: tokenParam,
      password: "",
      passwordConfirmation: ""
    },
    onSubmit: (values) => mutation.mutate(values)
  });

  return (
    <div className="guest-pages-bg">
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col span={12}>
          <Card title={<CardTitle />}>
            <Form layout="vertical" onFinish={formik.handleSubmit}>
              <Form.Item
                label={t("acceptInvite.newPassword")}
                labelCol={{ span: 24 }}
                validateStatus={formik.errors.password && "error"}
                help={formik.errors.password}
              >
                <Input.Password
                  name="password"
                  size="large"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={t("acceptInvite.confirmPassword")}
                labelCol={{ span: 24 }}
                validateStatus={formik.errors.passwordConfirmation && "error"}
                help={formik.errors.passwordConfirmation}
              >
                <Input.Password
                  name="passwordConfirmation"
                  size="large"
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                />
              </Form.Item>
              <Form.Item noStyle>
                <Row justify="end" align="middle">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={mutation.isLoading}
                  >
                    {t("submit")}
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AcceptInvitePage;
