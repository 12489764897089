export const enContract = {
  contracts: "Contracts",
  contractNumber: "Contract number",
  kcCatAndArea: "KC Category and Area",
  project: "Project",
  capacity: "Max number of craft workers to be trained",
  addContract: "Add contract",
  contractAdded: "Contract successfully created",
  contractEdited: "The contract {{contractNumber}} data successfully saved",
  contractDeleted: "Contract {{contractNumber}} successfully deleted",
  confirmDelete:
    "Are you sure you want to delete contract: {{contractNumber}}?",
  editContract: "Edit contract"
};
