import { UseMutateFunction } from "react-query";

import i18next from "i18next";

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";

interface Props {
  name: string;
  id: number;
  onConfirm: UseMutateFunction<unknown, unknown, number>;
}

const DeleteButton: React.FC<Props> = ({ name, id, onConfirm }) => {
  return (
    <Popconfirm
      title={name}
      onConfirm={() => onConfirm(id)}
      okText={i18next.t("yes")}
      cancelText={i18next.t("no")}
    >
      <Button danger type="primary" shape="circle" icon={<DeleteOutlined />} />
    </Popconfirm>
  );
};

export default DeleteButton;
