export const enEmployees = {
  employees: "Employees",
  employee: "Employee",
  employeesPassport: "Employees",
  employeePassport: "Employees passport",
  craftEmployeeList: "List of Craft-employees",
  addEmployee: "Add employee",
  uploadEmployees: "Upload employees",

  search: "Search",
  filtering: "Filtering",

  personalInfo: "Personal information",
  workingInfo: "Working information",
  facilityAccesses: "Facility accesses",

  fullName: "Full Name",
  firstName: "First Name",
  lastName: "Last Name",
  middleName: "Middle Name",
  firstNameCyrillic: "Russian First Name",
  lastNameCyrillic: "Russian Last Name",
  middleNameCyrillic: "Russian Middle Name",
  jobTitle: "Job Title",
  phoneNumber: "Phone number",
  birthDate: "Date of Birth",
  citizenship: "Citizenship",
  residenceCountry: "Country of Residence",
  residenceRegion: "City/Region/Province of Residence",
  noIin: "No IIN",
  iin: "IIN",

  position: "Position",
  mouCategory: "MoU Category",
  contractor: "Contractor/Subcontractor",
  contract: "Contract",
  lineSupervisorBadgeNumber: "Line Supervisor Badge Number",

  workLocationArea: "Work Location Area",
  firstNameCompanyRep: "Company Rep First Name",
  lastNameCompanyRep: "Company Rep Last Name",
  phoneNumberCompanyRep: "Company Rep Phone Number",

  facilityAccessStandard: "Standard Site/Facility Access",
  facilityAccessAdditional: "Additional Site/Facility Access",
  specialAccessJustification: "Business justification for special access",
  waterTreatmentPlant: "Non Production Area - Water Treatment plant (SV)",
  residentialAreas: "Residential Areas - TCO Village (TCOV)",

  competency: "Competency",

  backToList: "Back to the list",

  updatePersonalInfo: "Update personal information",
  updateWorkingInfo: "Update working information",
  updateFacilityAccess: "Update facility access information",

  createSuccess: "Employee successfully added to the system",
  personalInfoUpdated: "Personal info updated",
  workingInfoUpdated: "Working info updated",
  facilityAccessUpdated: "Facility access info updated",

  notFound: "No employees with ID={{id}} found",

  uploadFile: "Upload a file",
  editData: "Check data",
  downloadTemplate: "Download template",
  chooseOrDragFile: "Select or drag a file here",
  fileUploaded: "File uploaded",
  checkDataError: "Error occured. Please check the submitted data",
  batchCreateSuccess: "Employees successfully uploaded",

  iinUpdateConfirm:
    "Are you sure you want to edit IIN? You can edit IIN only once. Edited IIN will be recorded to the employee permanently.",
  iinUpdated: "IIN updated"
};
