export const ruTcoWhatNew = [
  {
    title: "Новая функция: модуль миграции для пользователя ТШО.",
    html: `<p><span style="font-weight: 400;">Эта функция позволяет пользователям TШO с соответствующими правами добавлять новую миграцию на портал Craft Training.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Миграция представляет собой исторические данные о событиях (включая данные о событиях, имена работников, их оценки и т. д.), которые необходимо загружать массово.</span></p>`
  },
  {
    title:
      "Новая функция: изменения в функции оценки событий, включая реализацию многоэтапной системы оценивания событий: Активация.",
    html: `<p><span style="font-weight: 400;">Функция активации события позволяет пользователям Тренинг центра добавить&nbsp; оценки по практической части после активации события пользователем ТШО.</span></p>
<p><span style="font-weight: 400;">При этом, статус события изменяется с &laquo;Завершено&raquo; или &laquo;Оценено&raquo; на &laquo;Активировано&raquo;.</span></p>`
  },
  {
    title:
      "Изменения в квалификационных требованиях: отменено правило 1 месяца для ремесленных сотрудников для повторного участия в мероприятии",
    html: `<p><span style="font-weight: 400;">С этим изменением работникам не нужно ждать истечения одного месяца, чтобы повторно подать заявку на участие в том же типе и дисциплине мероприятия.</span></p>`
  },
  {
    title:
      "Новая функция: добавлено поле «локация» для всех типов событий: в отчетах, файле дамп данных, фильтрах отчетов.",
    html: `<p><span style="font-weight: 400;">Теперь пользователи могут указать место проведения мероприятия, в том числе &ldquo;Онлайн&rdquo; и &ldquo;Тенгиз&rdquo;. Не путайте с городом учебного центра.</span></p>`
  },
  {
    title:
      "Новая функция: Прямая регистрация работников на событие пользователем ТШО.",
    html: `<p><span style="font-weight: 400;">С лета 2022 года, пользователи ТШО теперь могут напрямую регистрировать сотрудников на события. </span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Ранее эта опция была доступна только для пользователей Тренинг центра.</span></p>`
  },
  {
    title:
      "Новая функция: приглашение пользователей Тренинг центра пользователем ТШО.",
    html: `<p><span style="font-weight: 400;">Теперь пользователи ТШО могут приглашать пользователей Тренинг центра на СТ Портал </span></p>`
  },
  {
    title:
      "Новая функция: Профили кандидатов. \n" +
      "\n" +
      "Доступно для ТШО, подрядчиков, субподрядчиков и пользователей учебного центра.\n",
    html: `<p><span style="font-weight: 400;">Теперь есть возможность просматривать, &laquo;нанимать&raquo;, &laquo;увольнять&raquo;, скачивать профили местных безработных, выпускников событий Учебных центров в рамках программы КСО ТШО.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">На вкладке &laquo;Профиль&raquo; отображаются профили людей, ищущих работу, которых пользователи подрядчиков/ субподрядчиков могут просмотреть и нанять на работу.</span></p>`
  },
  {
    title:
      "Новая функция: словарь дисциплин и конфигурация: Создание новых дисциплин пользователями TШO. ",
    html: `<p><span style="font-weight: 400;">Эта функция позволяет пользователям TШO с соответствующими разрешениями добавлять новую дисциплину на портал Craft Training.</span></p>`
  },
  {
    title:
      "Новая функция: Отчет об онлайн-обучении для non- крафт сотрудников ",
    html: `<p><span style="font-weight: 400;">Подрядчики теперь могут создавать онлайн-отчеты об обучении (KPJV): список non- крафт сотрудников с указанием дисциплин, датами начала и окончания обучения и т. д.&nbsp;</span></p>
<p><span style="font-weight: 400;">Пользователи ТШО могут просматривать эти отчеты.</span></p>`
  },
  {
    title: "Изменения в отчетности и файле дамп данных",
    html: `<p><span style="font-weight: 400;">Изменения в отчетности и файле дамп данных</span></p>`
  }
];
