import React from "react";
import { useTranslation } from "react-i18next";

import { Space, Table, Typography } from "antd";

import { Member } from "@models/member";

const Column = Table.Column;

interface Props {
  title?: React.ReactNode;
  isLoading?: boolean;
  members?: Member[];
  extraColumns?: {
    title?: string;
    dataIndex?: string;
    render?: (value: any, record: unknown, index: number) => React.ReactNode;
  }[];
  renderActions?: (member: any) => React.ReactNode;
}

export const MemberTable = (props: Props) => {
  const [t] = useTranslation();

  return (
    <Table
      title={
        props.title
          ? () => (
              <Space align="center">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {props.title}
                </Typography.Title>

                <Typography.Text type="secondary">
                  {t("totalCount", {
                    total: props.members ? props.members.length : 0
                  })}
                </Typography.Text>
              </Space>
            )
          : undefined
      }
      locale={{ emptyText: t("noData") }}
      loading={props.isLoading}
      tableLayout="auto"
      bordered
      pagination={false}
      rowKey="userId"
      dataSource={props.members}
    >
      <Column title={t("members.firstName")} dataIndex="firstName" />
      <Column title={t("members.lastName")} dataIndex="lastName" />
      <Column
        title={t("members.position")}
        render={(member: Member) => member.position || "-"}
      />
      <Column title={t("members.email")} dataIndex="email" />

      {props.extraColumns &&
        props.extraColumns.map((ec, i) => (
          <Column
            key={i}
            title={ec.title}
            dataIndex={ec.dataIndex}
            render={ec.render}
          />
        ))}

      {props.renderActions && (
        <Column
          title={t("members.actions")}
          render={(member: any) => {
            return props.renderActions && props.renderActions(member);
          }}
        />
      )}
    </Table>
  );
};
