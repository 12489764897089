import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UseMutateFunction } from "react-query";

import { ColumnType } from "antd/lib/table";
import { PaginationProps } from "antd/lib/pagination";

import { Table } from "antd";
import DeleteButton from "@components/common/reports/DeleteButton";

import { MigrationListResponse, MigrationRadioType } from "@models/migrations";

import { PaginatedResponse } from "@api";

import useDateFormatter from "@hooks/useDateFormat";

interface Props {
  migrationRadioTypes: MigrationRadioType[];
  migrationsList: PaginatedResponse<MigrationListResponse>;
  isLoading?: boolean;
  deleteMigration: UseMutateFunction<unknown, unknown, number>;
  pagination?: PaginationProps;
}

export const MigrationsListTable: React.FC<Props> = ({
  migrationRadioTypes,
  migrationsList,
  deleteMigration,
  isLoading,
  pagination
}) => {
  const [t] = useTranslation();
  const { formatter } = useDateFormatter();

  const columns = useMemo(
    () => {
      const e: (ColumnType<MigrationListResponse> & {
        hidden?: boolean;
      })[] = [
        {
          title: "#",
          dataIndex: "number",
          key: "id",
          width: "4%",
          render: (_, __, index: number) => <>{index + 1}</>
        },
        {
          title: t("events.name"),
          dataIndex: "eventName",
          key: "eventName"
        },
        {
          title: t("disciplines.eventType"),
          dataIndex: "eventType",
          key: "eventType",
          render: (eventType) => {
            const currentType = migrationRadioTypes.find(
              (type) => eventType === type.value
            );
            return <>{currentType?.label}</>;
          }
        },
        {
          title: t("trainingCenters.name"),
          dataIndex: "tcName",
          key: "tcName"
        },
        {
          title: t("contractors.contractor"),
          dataIndex: "contractorNames",
          key: "contractorNames"
        },
        {
          title: t("migrations.table.numberOfCraftWorkers"),
          dataIndex: "eventEmployeesCount",
          key: "eventEmployeesCount",
          align: "center"
        },
        {
          title: t("createdAt"),
          dataIndex: "createdAt",
          key: "createdAt",
          render: (date) => <>{formatter(date, "short-date")}</>
        },
        {
          title: t("migrations.table.uploadedBy"),
          dataIndex: "uploadedBy",
          key: "uploadedBy",
          render: (uploadedBy) => (
            <>{`${uploadedBy.firstName} ${uploadedBy.lastName}`}</>
          )
        },
        {
          title: t("actions"),
          dataIndex: "actions",
          key: "actions",
          fixed: "right",
          width: "7%",
          render: (_, reportData) => (
            <DeleteButton
              name={t("migrations.deleteConfirmation", {
                name: reportData.eventName
              })}
              id={reportData.id}
              onConfirm={() => deleteMigration(reportData.id)}
            />
          ),
          align: "center"
        }
      ];

      return e.filter((item) => !item.hidden);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  return (
    <Table
      columns={columns}
      dataSource={migrationsList ? migrationsList.results : []}
      pagination={pagination}
      loading={isLoading}
      scroll={{ x: 1600 }}
      rowKey="id"
    />
  );
};
