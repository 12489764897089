export const ruReports = {
  reports: "Отчеты",
  performanceReports: "Показатели",
  mouCategory: "МоВ категория",

  tcReports: "Отчеты ТЦ",
  trainingReport: "Тренинг Отчет",
  certificate: "Сертификат",
  profile: "Профиль",
  protocol: "Протокол",
  reportName: "Наименование отчета",
  csrPhase: "Фаза КСО",
  reportDate: "Дата отчета",
  createdAt: "Дата создания",
  editedAt: "Последнее редактирование",
  numberOfEdits: "Редактирования",
  createReport: "Создать отчет",
  reportType: "Тип",
  reportDeleted: "Отчет удален",
  saveSuccess: "Отчет успешно сохранен",
  confirmDelete: "Вы уверены, что хотите удалить отчет: {{name}}?",
  commissioningPerformance: "Отчёт по вводу в эксплуатацию",
  perYear: "Количество тренированных работников в год",
  eventsByTrainingCenter: "События по тренинг центрам",
  eventsByLocation: "События по локации",
  eventsByContractor: "События по подрядчикам",
  eventsByDiscipline: "События по дисциплинам",
  citizenship: "Гражданство",
  overallGrade: "Оценка",
  trainingResults: "Результаты тренинга",
  failReason: "Причина незачета",
  duplicatedHeadcount: "Дубликаты работников",

  datadump: "Data dump",
  datadumpTitle: "Все отчеты",
  datadumpSubtitle: "Скачать исходные данные",
  datadumpName: "Название файла",
  datadumpDate: "Дата создания",
  // datadumpDate: "Дата создания",
  datadumpGenerate: "Сгенерировать файл",
  datadumpGenerateCommissioning: "Сгенерировать файл (ввод в эксплуатацию)",
  datadumpGenerateError:
    "При генерации файла возникла ошибка, пожалуйста попробуйте ещё раз",

  onlineTrainingReport: "Отчет об Онлайн Тренингах",
  onlineTrainingReport_other: "Отчеты об Онлайн Тренингах",

  profiles: {
    profileReport: "Отчет по профилям",
    byDiscipline: "Профили по дисциплинам",
    byStatus: "Профили по статусам",
    byCSRContractor: "Профили по КСО",
    byTrainingCenter: "Профили по тренинг-центрам",
    byContractor: "Нанятые профили по подрядчикам",
    employed: "Трудоустроен",
    unemployed: "В поиске работы",
    downloadProfilesData: "Скачать данные по профилям"
  },

  createOnlineTrainingReport: "Создать Отчет об Онлайн Тренинге",
  editOnlineTrainingReport: "Редактировать Отчет об Онлайн Тренинге",
  deleteOnlineTrainingReport: "Удалить Отчет об Онлайн Тренинге",

  createReportSuccess: "Отчет успешно создан",
  deleteReportSuccess: "Отчет успешно удален",

  deleteConfirmation: "Вы уверены, что хотите удалить отчет {{name}}?",
  userDeleteConfirmation:
    "Вы уверены, что хотите удалить сотрудника {{name}} из данного отчета?",
  userDeleteSuccess: "Сотрудник успешно удален из отчета",
  userAddSuccess: "Сотрудник успешно добавлен к данному отчету",

  completed: "Завершен",
  incompleted: "Не завершен",

  downloadAcademicHoursReport: "Скачать Отчет По Академическим Часам",

  filter: "Фильтровать отчет",

  filterNotAppliedToAcademicHours:
    "*фильтры не применяются на академические часы",

  headcountTypes: {
    headcount: "По работникам",
    training: "Тренинг",
    assessment: "Оценка",
    prescreening: "Пре-скрининг",
    ojtTraining: "ОСП",
    commissioningAssessment: "Эксп.Оценка",
    commissioningTraining: "Эксп.Тренинг"
  },
  host: "Организатор",
  status: "Статус",
  datadumpSuccess: "Генерация датадампа может длиться до 1 часа",
  unknown: "Неизвестно",

  uploadParticipants: "Загрузить участников"
};
