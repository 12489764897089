import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Row, Typography } from "antd";

import { EventDetails } from "@models/event";

import useDateFormatter from "@hooks/useDateFormat";

interface FormValues {
  deletionReason: string;
}

interface Props {
  event: EventDetails;
  isLoading?: boolean;
  errors?: { [key: string]: string };
  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

export const EventActivateForm: React.FC<Props> = (props) => {
  const dateFormatter = useDateFormatter();
  const [t] = useTranslation();
  const { event, onSubmit, errors, isLoading, onCancel } = props;

  const formik = useFormik<FormValues>({
    initialValues: {
      deletionReason: ""
    },
    onSubmit(values) {
      if (onSubmit) {
        onSubmit(values);
      }
    }
  });

  useEffect(() => {
    if (errors) {
      formik.setErrors(errors);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Typography.Text
        style={{ display: "inline-block", paddingBottom: "24px" }}
      >
        {t("events.activateConfirm", {
          name: event.name,
          dateTimeStart: dateFormatter.formatter(
            event.dateTimeStart,
            "short-datetime"
          )
        })}
      </Typography.Text>

      <Row justify="space-between">
        <Col>
          <Button onClick={onCancel}>{t("cancel")}</Button>
        </Col>

        <Col>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("yes")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
