import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment-timezone";

import { Modal, Tabs } from "antd";

import { EventAttendance, EventDetails, EventType } from "@models/event";
import { Instructor } from "@models/instructor";

import {
  useEmployeePrematureEvaluation,
  useEmployeePrematureExclude
} from "@api/eventAttendanceApiHooks";

import { convertDateToEventTimezone } from "@utils/eventAttendanceHelper";
import { UserContext } from "@contexts/UserContext";

import { ExcludeEventEmpoyeeForm } from "./ExcludeEmployeeForm";
import { EmployeePreMatureEvaluationForm } from "./EmployeePreMatureEvaluationForm";

const SHORT_DATE_FORMAT = "DD-MM-YYYY";

interface Props {
  attendance: EventAttendance;
  date: string;
  savedAttendanceTotal: number;
  eventDetails: EventDetails;
  instructors: Instructor[];
  onCancel: () => void;
  onSuccess: () => void;
}

const EvaluateOrExcludeModal: FC<Props> = (props) => {
  const { eventDetails, onSuccess } = props;
  const [t] = useTranslation();
  const { userEntityId: trainingCenterId } = useContext(UserContext);

  const { mutation: evaluateMutation, errors: evaluateErrors } =
    useEmployeePrematureEvaluation({ onSuccess });
  const { mutation: excludeMutation, errors: excludeErrors } =
    useEmployeePrematureExclude({ onSuccess });

  const prematureDate = convertDateToEventTimezone(
    props.date,
    eventDetails.dateTimeEnd
  );

  const employeeId = props.attendance.employeeId;
  const attendance = props.attendance.days
    .filter(({ date: attendanceDate }) =>
      moment(attendanceDate, SHORT_DATE_FORMAT).isSameOrBefore(
        prematureDate,
        "day"
      )
    )
    .map(({ date, hours }) => ({
      date: convertDateToEventTimezone(date, eventDetails.dateTimeEnd),
      hours
    }));
  const excludeAttendance = props.attendance.days
    .filter(({ date: attendanceDate }) =>
      moment(attendanceDate, SHORT_DATE_FORMAT).isBefore(prematureDate, "day")
    )
    .filter(({ date }) => {
      const daysOff = eventDetails.daysOff?.map((dayOff) => ({
        date: moment(dayOff).format("DD-MM-YYYY")
      }));

      const isDayOff = daysOff?.some((day) => day.date.includes(date));

      return !isDayOff;
    })
    .map(({ date, hours }) => ({
      date: convertDateToEventTimezone(date, eventDetails.dateTimeEnd),
      hours
    }));

  const totalAttendance =
    attendance.reduce((total, a) => total + a.hours, 0) +
    props.savedAttendanceTotal;

  return (
    <Modal
      visible={!!props.attendance.employeeId}
      destroyOnClose
      maskClosable={false}
      width={800}
      footer={null}
      onCancel={props.onCancel}
    >
      <Tabs>
        <Tabs.TabPane key="1" tab={t("events.prematurelyEvaluate")}>
          <EmployeePreMatureEvaluationForm
            totalAttendance={totalAttendance}
            isEventOJT={eventDetails.type === EventType.OJT_TRAINING}
            instructors={props.instructors}
            isLoading={evaluateMutation.isLoading}
            errors={evaluateErrors}
            attendance={props.attendance}
            onSubmit={(v) => {
              evaluateMutation.mutate({
                ...v,
                isEventOJT: eventDetails.type === EventType.OJT_TRAINING,
                employeeId,
                eventId: eventDetails.id,
                trainingCenterId,
                evaluatedDate: prematureDate,
                instructorId: v.instructorId || 0,
                isPassed: v.isPassed || 0,
                attendance
              });
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab={t("events.exclude")}>
          <ExcludeEventEmpoyeeForm
            errors={excludeErrors}
            isLoading={excludeMutation.isLoading}
            onSubmit={(v) => {
              excludeMutation.mutate({
                ...v,
                endDate: prematureDate,
                daysOff: [],
                attendance: excludeAttendance,
                employeeId,
                trainingCenterId,
                eventId: eventDetails.id
              });
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default EvaluateOrExcludeModal;
