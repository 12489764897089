import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UseMutateFunction, useQuery } from "react-query";

import { useFormik } from "formik";

import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Upload
} from "antd";
import TinyMceTextField from "@components/admin/common/TinyMceTextField";

import {
  CraftProfileCardWithId,
  EditProfilePayloadAdmin,
  CraftProfilesFormSettingsAdmin
} from "@models/craftProfiles";

import { disciplineApi } from "@api/disciplineApi";
import { contractorsApi } from "@api/contractorsApi";

import useDateFormatter from "@hooks/useDateFormat";
import { doesFileFitsSize } from "@utils/doesFileFitsSize";
import { KnownLanguages, MaritalStatuses } from "@constants/craftProfiles";
import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

interface Props {
  handleCloseModal: () => void;
  editedProfile: CraftProfileCardWithId;
  handleSubmit: UseMutateFunction<unknown, unknown, EditProfilePayloadAdmin>;
  errors: { [key: string]: string };
  isLoading?: boolean;
  areFieldsHidden?: boolean;
}

const { Option } = Select;

const EditCraftProfileForm: React.FC<Props> = ({
  editedProfile,
  isLoading,
  handleCloseModal,
  handleSubmit,
  errors,
  areFieldsHidden = false
}) => {
  const [t, i18n] = useTranslation();
  const { formatter } = useDateFormatter();
  const maxFileSize = 1;

  const sortedKnonwLanguages = useMemo(
    () => KnownLanguages.sort((a, b) => t(a.label).localeCompare(t(b.label))),
    [t]
  );

  const {
    discipline,
    address,
    maritalStatus,
    education,
    additionalEducation,
    workExperience,
    languages,
    personalQualities,
    additionalInformation,
    isEmployed,
    hiredById,
    hiredByName,
    csrPhase,
    id,
    profilePhoto
  } = editedProfile;

  const disciplinesQuery = useQuery("disciplines-list", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const contractorsQuery = useQuery("contractors-list", () =>
    contractorsApi.getContractors().then((res) => res.data)
  );

  const formattedDateOfBirth = useMemo(
    () =>
      editedProfile?.birthDate
        ? formatter(editedProfile?.birthDate, "full-date")
        : "-",
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editedProfile?.birthDate]
  );

  useEffect(() => {
    formik.setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const formik = useFormik<CraftProfilesFormSettingsAdmin>({
    initialValues: {
      disciplineId: 0,
      homeAddress: "",
      maritalStatus: 0,
      education: "",
      additionalEducation: "",
      csrPhase: 0,
      workExperience: "",
      languages: [],
      personalQualities: "",
      additionalInformation: "",
      profilePhoto: null
    },
    onSubmit: (values) =>
      handleSubmit({
        profileId: id,
        payload: values
      })
  });

  useEffect(() => {
    areFieldsHidden
      ? formik.setValues({
          disciplineId: discipline.id,
          homeAddress: address,
          maritalStatus: maritalStatus,
          education: education,
          additionalEducation: additionalEducation,
          csrPhase: csrPhase || 0,
          workExperience: workExperience,
          languages: languages,
          personalQualities: personalQualities || "",
          additionalInformation: additionalInformation || "",
          profilePhoto: profilePhoto
        })
      : formik.setValues({
          disciplineId: discipline.id,
          homeAddress: address,
          maritalStatus: maritalStatus,
          education: education,
          additionalEducation: additionalEducation,
          workExperience: workExperience,
          csrPhase: csrPhase || 0,
          languages: languages,
          personalQualities: personalQualities || "",
          additionalInformation: additionalInformation || "",
          profilePhoto: profilePhoto,
          isEmployed: isEmployed || false,
          hiringContractorId: !hiredById ? 0 : hiredById,
          otherCompany: !hiredById ? hiredByName : ""
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedProfile]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item label={t("fullName")}>
            <Input value={`${editedProfile?.fullName}`} disabled={true} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("dateOfBirth")}>
            <Input value={formattedDateOfBirth} disabled={true} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("phoneNumber")}>
            <Input
              placeholder="88001234567"
              value={editedProfile?.phoneNumber || "-"}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Form.Item
            label={t("address")}
            validateStatus={formik.errors.homeAddress && "error"}
            help={formik.errors.homeAddress}
          >
            <Input
              name="homeAddress"
              value={formik.values.homeAddress}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("maritalStatus")}
            validateStatus={formik.errors.maritalStatus && "error"}
            help={formik.errors.maritalStatus}
          >
            <Select
              value={formik.values.maritalStatus}
              onChange={(value) => formik.setFieldValue("maritalStatus", value)}
            >
              {MaritalStatuses.map((ms) => (
                <Option key={ms.value} value={ms.value}>
                  {t(ms.label)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ marginTop: "0", marginBottom: "15px" }} />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item label={t("csrContractor")}>
            <Input
              value={editedProfile?.csrContractor || "-"}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t("discipline")}
            validateStatus={formik.errors.disciplineId && "error"}
            help={formik.errors.disciplineId}
          >
            <Select
              showSearch
              value={formik.values.disciplineId}
              filterOption={(inputValue, option) =>
                option?.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              onChange={(value) => formik.setFieldValue("disciplineId", value)}
              loading={disciplinesQuery.isLoading}
            >
              {disciplinesQuery.data?.map((d) => (
                <Option key={d.id} value={d.id}>
                  {i18n.language === "en" ? d.nameEn : d.nameRu}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t("csrPhase")}
            validateStatus={formik.errors.csrPhase && "error"}
            help={formik.errors.csrPhase}
          >
            <Select
              value={formik.values.csrPhase}
              onChange={(v) => formik.setFieldValue("csrPhase", v)}
            >
              {CSR_PHASE_OPTIONS.map((cp) => (
                <Select.Option value={cp.value} key={cp.value}>
                  {t(cp.label, { no: cp.value })}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t("knownLanguages")}
            validateStatus={formik.errors.languages && "error"}
            help={formik.errors.languages}
          >
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              value={formik.values.languages}
              filterOption={(input, option) =>
                option?.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => formik.setFieldValue("languages", value)}
            >
              {sortedKnonwLanguages.map((option) => (
                <Option key={option.value} value={option.value}>
                  {t(option.label)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label={t("education")}
            validateStatus={formik.errors.education && "error"}
            help={formik.errors.education}
          >
            <TinyMceTextField
              value={formik.values.education}
              handleChange={(value: string) => {
                formik.handleChange({
                  target: {
                    name: "education",
                    value
                  }
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("additionalEducation")}
            validateStatus={formik.errors.additionalEducation && "error"}
            help={formik.errors.additionalEducation}
          >
            <TinyMceTextField
              value={formik.values.additionalEducation}
              handleChange={(value: string) => {
                formik.handleChange({
                  target: {
                    name: "additionalEducation",
                    value
                  }
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label={t("workExperience")}
            validateStatus={formik.errors.workExperience && "error"}
            help={formik.errors.workExperience}
          >
            <TinyMceTextField
              value={formik.values.workExperience}
              handleChange={(value: string) => {
                formik.handleChange({
                  target: {
                    name: "workExperience",
                    value
                  }
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("additionalInformation")}
            validateStatus={formik.errors.additionalInformation && "error"}
            help={formik.errors.additionalInformation}
          >
            <TinyMceTextField
              value={formik.values.additionalInformation}
              handleChange={(value: string) => {
                formik.handleChange({
                  target: {
                    name: "additionalInformation",
                    value
                  }
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={20}>
          <Form.Item
            label={t("personalQualities")}
            validateStatus={formik.errors.personalQualities && "error"}
            help={formik.errors.personalQualities}
            initialValue={{
              selector: "#tinymce",
              branding: false
            }}
          >
            <Input
              name="personalQualities"
              value={formik.values.personalQualities}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={t("craftProfiles.uploadImage")}
            validateStatus={formik.errors.profilePhoto && "error"}
            help={formik.errors.profilePhoto}
          >
            <Upload
              accept=".jpg, .png, .jpeg"
              beforeUpload={(file) => {
                if (doesFileFitsSize(file, maxFileSize)) {
                  notification.success({
                    message: t("employees.fileUploaded")
                  });
                  return false;
                }
                notification.error({
                  message: t("fileSizeLimit", { size: maxFileSize })
                });
                return Upload.LIST_IGNORE;
              }}
              maxCount={1}
              onChange={(value) =>
                formik.setFieldValue("profilePhoto", value.file)
              }
            >
              <Button icon={<UploadOutlined />}>
                {t("craftProfiles.clickToUpload")}
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      {/* additional admin fields */}
      {!areFieldsHidden && (
        <>
          <Divider style={{ marginTop: "0", marginBottom: "15px" }} />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label={t("employmentStatus")}
                validateStatus={formik.errors.isEmployed && "error"}
                help={formik.errors.isEmployed}
              >
                <Select
                  value={formik.values.isEmployed}
                  onChange={(value) =>
                    formik.setFieldValue("isEmployed", value)
                  }
                >
                  <Option key={1} value={true}>
                    {t("craftProfiles.employmentStatusOptions.1")}
                  </Option>
                  <Option key={2} value={false}>
                    {t("craftProfiles.employmentStatusOptions.2")}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("contractorSubcontractor")}
                validateStatus={formik.errors.hiringContractorId && "error"}
                help={formik.errors.hiringContractorId}
              >
                <Select
                  showSearch
                  value={formik.values.hiringContractorId}
                  filterOption={(inputValue, option) =>
                    option?.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={(value) =>
                    formik.setFieldValue("hiringContractorId", value)
                  }
                  loading={contractorsQuery.isLoading}
                  disabled={!formik.values.isEmployed}
                >
                  {contractorsQuery.data?.map((c) => (
                    <Option key={c.id} value={c.id}>
                      {c.name}
                    </Option>
                  ))}
                  <Option value={0}>{t("other")}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={t("otherCompany")}
                validateStatus={formik.errors.otherCompany && "error"}
                help={formik.errors.otherCompany}
              >
                <Input
                  name="otherCompany"
                  value={formik.values.otherCompany || ""}
                  onChange={formik.handleChange}
                  disabled={
                    formik.values.hiringContractorId !== 0 ||
                    !formik.values.isEmployed
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row justify="space-between">
        <Col>
          <Button htmlType="button" onClick={handleCloseModal}>
            {t("cancel")}
          </Button>
        </Col>
        <Col>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
            disabled={!editedProfile}
          >
            {t("edit")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditCraftProfileForm;
