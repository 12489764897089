import { UseMutateFunction } from "react-query";
import { useTranslation } from "react-i18next";

import { Button, Modal } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import TrainingCenterMemberForm from "@components/training-center/TrainingCenterMemberForm";

interface Props {
  trainingCenterId: number;
  isModalVisible: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  onSubmit: UseMutateFunction<unknown, unknown, any>;
  errors?: { [key: string]: string };
  isLoading?: boolean;
}

const InviteTrainingCenterModal: React.FC<Props> = ({
  isModalVisible,
  handleCloseModal,
  handleOpenModal,
  onSubmit,
  errors,
  isLoading,
  trainingCenterId
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        visible={isModalVisible}
        destroyOnClose
        title={t("members.inviteMember")}
        maskClosable={false}
        footer={null}
        onCancel={handleCloseModal}
      >
        <TrainingCenterMemberForm
          trainingCenterId={trainingCenterId}
          errors={errors}
          isLoading={isLoading}
          onSubmit={onSubmit}
          onCancel={handleCloseModal}
        />
      </Modal>

      <Button
        type="primary"
        key="1"
        icon={<UserAddOutlined />}
        onClick={handleOpenModal}
      >
        {t("members.inviteMember")}
      </Button>
    </>
  );
};

export default InviteTrainingCenterModal;
