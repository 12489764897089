import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormikProps } from "formik";
import { ColumnsType } from "antd/lib/table";
import FormItem from "antd/lib/form/FormItem";

import { Button, Space, Table } from "antd";
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import OnlineTrainingFormFieldGenerator from "@components/common/reports/renderers/OnlineTrainingFormFieldGenerator";

import {
  OnlineTrainingReportFormData,
  OnlineTrainingReportUser
} from "@models/onlineTrainingReports";

interface Props {
  formik: FormikProps<OnlineTrainingReportFormData>;
  isLoading?: boolean;
}

const EditableEmployeeTable: React.FC<Props> = ({ formik, isLoading }) => {
  const [t] = useTranslation();
  const [lineEdited, setLineEdited] = useState<number | null>(null);

  const renderFormItem = useCallback(
    (name: string, index: number) => {
      const formikErrorsSelector =
        formik.errors[
          `onlineTrainingReportUsers[${index}].${
            name.charAt(0).toUpperCase() + name.slice(1)
          }` as keyof OnlineTrainingReportFormData
        ];

      return (
        <FormItem
          style={{ marginTop: "24px" }}
          validateStatus={formikErrorsSelector && "error"}
          help={formikErrorsSelector}
        >
          <OnlineTrainingFormFieldGenerator
            name={name}
            index={index}
            lineEdited={lineEdited}
            formik={formik}
            onBlur={(name: string, value: string | null) =>
              formik.setFieldValue(
                `onlineTrainingReportUsers[${index}].${name}`,
                value
              )
            }
          />
        </FormItem>
      );
    },
    [formik, lineEdited]
  );

  const deleteRow = useCallback(
    (index: number) => {
      const onlineTrainingReportUsers = [
        ...formik.values.onlineTrainingReportUsers
      ];
      onlineTrainingReportUsers.splice(index, 1);

      formik.setFieldValue(
        "onlineTrainingReportUsers",
        onlineTrainingReportUsers
      );
    },
    [formik]
  );

  const columns: ColumnsType<Partial<OnlineTrainingReportUser>> = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "number",
        key: "number",
        width: "3%",
        render: (_, __, index) => <>{index + 1}</>
      },
      {
        title: t("lastName"),
        dataIndex: "lastName",
        key: "lastName",
        render: (_, __, index) => {
          return renderFormItem("lastName", index);
        }
      },
      {
        title: t("firstName"),
        dataIndex: "firstName",
        key: "firstName",
        render: (_, __, index) => {
          return renderFormItem("firstName", index);
        }
      },
      {
        title: t("iin"),
        dataIndex: "iin",
        key: "iin",
        render: (_, __, index) => {
          return renderFormItem("iin", index);
        }
      },
      {
        title: t("employeeNumber"),
        dataIndex: "employeeNumber",
        key: "employeeNumber",
        render: (_, __, index) => {
          return renderFormItem("employeeNumber", index);
        }
      },
      {
        title: t("department"),
        dataIndex: "department",
        key: "department",
        render: (_, __, index) => {
          return renderFormItem("department", index);
        }
      },
      {
        title: t("title"),
        dataIndex: "title",
        key: "title",
        render: (_, __, index) => {
          return renderFormItem("title", index);
        }
      },
      {
        title: t("supervisor"),
        dataIndex: "supervisor",
        key: "supervisor",
        render: (_, __, index) => {
          return renderFormItem("supervisor", index);
        }
      },
      {
        title: "Rev",
        dataIndex: "rev",
        key: "rev",
        render: (_, __, index) => {
          return renderFormItem("rev", index);
        }
      },
      {
        title: t("actions"),
        align: "center",
        fixed: "right",
        width: "5%",
        key: "actions",
        render: (_, __, index) => (
          <Space>
            {index === lineEdited ? (
              <Button
                shape="circle"
                icon={<CheckOutlined />}
                type="primary"
                onClick={() => setLineEdited(null)}
              />
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                type="primary"
                onClick={() => setLineEdited(index)}
              />
            )}
            <Button
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteRow(index)}
            />
          </Space>
        )
      }
    ],
    [deleteRow, lineEdited, renderFormItem, t]
  );

  return (
    <Table
      dataSource={formik.values.onlineTrainingReportUsers}
      loading={isLoading}
      columns={columns}
      style={{ marginBottom: "24px" }}
      pagination={false}
      scroll={{ x: 2000, y: 450 }}
    />
  );
};

export default EditableEmployeeTable;
