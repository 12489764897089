/* eslint-disable react-hooks/exhaustive-deps  */

import { useMemo, useEffect, useContext, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

import { AxiosError } from "axios";

import { notification } from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { CertificateSearchContainer } from "@components/common/certificates/CertificateSearchContainer";

import { certificateApi } from "@api/certificateApi";

import { UserContext } from "@contexts/UserContext";
import { transformValidationError } from "@utils/errorHelper";

const CertificateVerifyPage: FC = () => {
  const [t] = useTranslation();

  const { userEntityId: contractorId } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState<{
    iin?: string;
    certificateId?: string;
  }>();

  const certificateData = useQuery(
    ["findContractorCertificate", contractorId],
    () =>
      certificateApi
        .findContractorCertificate({
          contractorId,
          iin: searchQuery?.iin,
          certificateId: searchQuery?.certificateId
        })
        .then((res) => res.data),
    {
      enabled: false,
      retry: false,

      onError(err: AxiosError) {
        if (err.response?.data.message) {
          notification.error({ message: err.response.data.message });
        }
      }
    }
  );
  const validationErrorMap = useMemo(() => {
    const _validationErrors =
      certificateData.error?.response?.data.validationErrors;

    if (_validationErrors) {
      return transformValidationError(_validationErrors);
    }

    return {};
  }, [certificateData.error]);
  const certNotFound = certificateData.error?.response?.status === 404;

  const certDownloadMutation = useMutation((certificateId: string) =>
    certificateApi.downloadContractorCertificate({
      certificateId,
      contractorId
    })
  );

  useEffect(() => {
    if (searchQuery) {
      certificateData.refetch();
    }
  }, [searchQuery]);

  const pageTitle = t("certificates.search");

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: "CT",
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: ""
          }
        ]}
      />

      <CertificateSearchContainer
        downloading={certDownloadMutation.isLoading}
        loading={certificateData.isLoading}
        results={
          certNotFound ? [] : certificateData.data && [certificateData.data]
        }
        errors={validationErrorMap}
        onCertificateDownload={(certificateId) => {
          certDownloadMutation.mutate(certificateId);
        }}
        onSubmit={(v) => {
          setSearchQuery(v);
        }}
      />
    </>
  );
};

export default CertificateVerifyPage;
