import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, UseQueryResult } from "react-query";

import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Col, Modal, notification, Row, Steps } from "antd";
import UploadBatchMigrationForm from "@components/admin/migrations/forms/UploadBatchMigrationForm";
import MigrationFormComposer from "@components/admin/migrations/MigrationFormComposer";

import {
  MigrationInitialFormData,
  MigrationListResponse,
  MigrationRadioType,
  MigrationType,
  MigrationTypes
} from "@models/migrations";

import { migrationsApi } from "@api/migrationsApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";
import { migrationTypes } from "@utils/migrationForms";

interface Props {
  migrationsList: UseQueryResult<MigrationListResponse[], unknown>;
  migrationRadioTypes: MigrationRadioType[];
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  isLoading?: boolean;
}

const DUPLICATE_PARTICIPANTS_ERROR =
  "The request contains duplicate participants";

const CreateMigrationModal: React.FC<Props> = ({
  migrationsList,
  migrationRadioTypes,
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  isLoading
}) => {
  const [t] = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedMigrationType, setSelectedMigrationType] =
    useState<MigrationType>({ value: 0, route: "" });

  const handleMigrationType = (type: MigrationType) => {
    setSelectedMigrationType(type);
  };

  const getMigrationTemplate = useQuery(
    "get-migration-template",
    () => migrationsApi.getMigrartionTemplate(selectedMigrationType.value),
    {
      enabled: false
    }
  );

  const uploadMigrationTable = useValidatedMutation({
    mutationFunction: (payload: Blob) =>
      migrationsApi
        .uploadTable(selectedMigrationType.route, payload)
        .then((res) => res.data),
    onSuccess: () => {
      notification.success({ message: t("employees.fileUploaded") });
      setCurrentStep(1);
    },
    onError: () => notification.error({ message: t("error.errorOccured") })
  });

  const postEditedMigration = useValidatedMutation({
    mutationFunction: (payload: MigrationInitialFormData) =>
      migrationsApi.sendMigrationData(selectedMigrationType.route, payload),
    onSuccess: () => {
      notification.success({ message: t("migrations.migrationCreateSuccess") });
      handleCloseModal();
      migrationsList.refetch();
    },
    onError: (err) => {
      if (
        err.participants &&
        err.participants === DUPLICATE_PARTICIPANTS_ERROR
      ) {
        notification.error({ message: t("error.participantsError") });
      } else {
        notification.error({ message: t("error.errorOccured") });
      }
    }
  });

  const postEditedMigrationErrors = useMemo(
    () => transformValidationError(postEditedMigration.error),
    [postEditedMigration.error]
  );

  const setFormSettings = useMemo(() => {
    switch (selectedMigrationType.value) {
      case MigrationTypes.COMMISSIONING_TRAINING:
        return migrationTypes.commTrainingMigration;
      case MigrationTypes.TRAINING:
        return migrationTypes.trainingMigration;
      case MigrationTypes.COMMISSIONING_ASSESSMENT:
        return migrationTypes.commAssessmentMigration;
      case MigrationTypes.ASSESSMENT:
        return migrationTypes.assessmentMigration;
      case MigrationTypes.OJT_TRAINING:
        return migrationTypes.ojtTrainingMigration;
      case MigrationTypes.PRESCREENING:
        return migrationTypes.preScreeningMigration;
      default:
        return migrationTypes.undefinedMigration;
    }
  }, [selectedMigrationType.value]);

  const getCurrentMigrationType = useMemo(() => {
    if (selectedMigrationType.value !== 0 && currentStep !== 0) {
      const currentType = migrationRadioTypes.find(
        (type) => type.value === selectedMigrationType.value
      );
      return ` - ${currentType?.label}`;
    } else {
      return "";
    }
  }, [selectedMigrationType.value, currentStep, migrationRadioTypes]);

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={`${t(
          "migrations.form.createMigration"
        )}${getCurrentMigrationType}`}
        footer={null}
        width="90%"
        onCancel={handleCloseModal}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
          <Col span={24}>
            <Steps current={currentStep} size="small">
              <Steps.Step title={t("employees.uploadFile")} />
              <Steps.Step title={t("employees.editData")} />
            </Steps>
          </Col>
        </Row>
        {currentStep === 0 ? (
          <UploadBatchMigrationForm
            migrationRadioTypes={migrationRadioTypes}
            handleCloseModal={handleCloseModal}
            getMigrationTemplate={getMigrationTemplate.refetch}
            uploadMigrationTable={uploadMigrationTable.mutate}
            selectedMigrationType={selectedMigrationType}
            handleMigrationType={handleMigrationType}
            isLoading={
              getMigrationTemplate.isLoading || uploadMigrationTable.isLoading
            }
          />
        ) : (
          uploadMigrationTable.data && (
            <MigrationFormComposer
              selectedMigration={selectedMigrationType.value}
              formSettings={setFormSettings}
              handleCloseModal={handleCloseModal}
              parsedData={uploadMigrationTable.data}
              loading={
                uploadMigrationTable.isLoading || postEditedMigration.isLoading
              }
              postEditedMigration={postEditedMigration.mutate}
              errors={postEditedMigrationErrors}
            />
          )
        )}
      </Modal>

      <Button
        type="primary"
        icon={<PlusSquareOutlined />}
        onClick={() => {
          setSelectedMigrationType({ value: 0, route: "" });
          setCurrentStep(0);
          handleOpenModal();
        }}
        loading={isLoading}
      >
        {t("migrations.newMigration")}
      </Button>
    </>
  );
};

export default CreateMigrationModal;
