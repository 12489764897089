import picture26 from "@assets/images/user-guide/1.26.png";
import picture27 from "@assets/images/user-guide/1.27.png";

export const certificateVerification = [
  {
    title: "Check certificate:",
    html: `<p dir="ltr">
    Picture 4.26
</p>
<p dir="ltr">
    <img
        src=${picture26}
        width="624"
        height="217"
    />
</p>
<p dir="ltr">
    Once you press Certificate verification tab on Panel menu you will be
    redirected to Check certificate page with the following information:
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Search by IIN, enter 12 digits
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Certificate number, enter 12 digits
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to Check certificate:",
    html: `<p dir="ltr">
    Picture 4.27
</p>
<p dir="ltr">
    <img
        src=${picture27}
        width="624"
        height="337"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Certificate search tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Input IIN and Certificate number
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Search button or Enter on your keyboard
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to download certificate:",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Certificate search tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Input IIN and Certificate number
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Search button or Enter on your keyboard
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Download certificate button
        </p>
    </li>
</ol>
`
  }
];
