export const enContractors = {
  contractors: "Contractors",
  contractor: "Contractor",
  contractorName: "Contractor name",
  registerContractor: "Register contractor",
  editContractor: "Edit contractor",
  companyInfo: "Company information",
  supervisorEmail: "Client supervisor email",
  contractorDeleted: "Contractor deleted",
  saveSuccess: "Contractor successfully saved",
  confirmDelete: "Are you sure you want to delete contractor: {{name}}?",

  subcontractors: "Subcontractors",
  subcontractor: "Subcontractor",
  registrationDate: "Registration date",
  bin: "BIN",
  workPhoneNumber: "Work phone number",
  country: "Country",
  city: "City",
  address: "Address",
  nonIINExport: "Download log of non-IIN"
};
