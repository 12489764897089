import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Skeleton, Space } from "antd";
import CountrySelector from "@components/ui/CountrySelector";
import TrainingCentersSelector from "@components/ui/TrainingCentersSelector";
import PhoneInput from "@components/ui/PhoneInput";
import CitySelector from "@components/ui/CitySelector";

import { Country } from "@models/countryCity";
import { TrainingCenter } from "@models/trainingCenter";
import { Contractor, ContractorEdit } from "@models/contractor";

import { contractorsApi } from "@api/contractorsApi";

interface Props {
  contractor: Contractor;

  errors?: { [key: string]: string };

  isLoading?: boolean;

  countries: Country[];

  trainingCenters: TrainingCenter[];

  onSubmit?: (values: ContractorEdit) => void;
  onCancel?: () => void;
}

const EditContractorForm = (props: Props) => {
  const [selectedCountry, setSelectedCountry] = useState<number | undefined>(
    undefined
  );
  const [t] = useTranslation();

  const availableTrainingCenters = useQuery(
    ["getAvailableTrainingCenters", props.contractor.id],
    () =>
      contractorsApi
        .getTrainingCenterNotificationsSettings(props.contractor.id)
        .then((res) => res.data)
  );

  const formik = useFormik<ContractorEdit>({
    initialValues: {
      name: "",
      address: "",
      bin: "",
      phone: "",
      supervisorEmail: "",
      trainingCentersNotificationIds: []
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit({
          ...values,
          phone: values.phone.split(" ").join("").replace("+", "")
        });
      }
    }
  });

  useEffect(() => {
    if (props.contractor && availableTrainingCenters.data) {
      formik.setValues({
        name: props.contractor.name,
        address: props.contractor.address,
        countryId: props.contractor.country.id,
        cityId: props.contractor.city.id,
        bin: props.contractor.bin,
        phone: props.contractor.phone,
        supervisorEmail: props.contractor.supervisorEmail,
        trainingCentersNotificationIds: availableTrainingCenters.data
      });
      setSelectedCountry(props.contractor.country.id);
    } else {
      formik.resetForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contractor, availableTrainingCenters.data]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return !props.contractor || availableTrainingCenters.isLoading ? (
    <Skeleton active />
  ) : (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("contractors.contractorName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.name && "error"}
            help={formik.errors.name}
          >
            <Input
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("workPhoneNumber")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.phone && "error"}
            help={formik.errors.phone}
          >
            <PhoneInput
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              maskChar={" "}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label={t("country")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.countryId && "error"}
            help={formik.errors.countryId}
          >
            <CountrySelector
              countries={props.countries}
              selectedCountry={formik.values.countryId}
              onChange={(value: number) => {
                setSelectedCountry(value);
                formik.setFieldValue("countryId", value);
                formik.setFieldValue("cityId", undefined);
                if (value !== 1) {
                  formik.setFieldValue("bin", null);
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={t("city")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.cityId && "error"}
            help={formik.errors.cityId}
          >
            <CitySelector
              cities={
                props.countries.find((c) => c.id === selectedCountry)?.cities
              }
              selectedCity={formik.values.cityId}
              onChange={(value: number) => {
                formik.setFieldValue("cityId", value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("bin")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.bin && "error"}
            help={formik.errors.bin}
          >
            <Input
              name="bin"
              value={formik.values.bin}
              onChange={formik.handleChange}
              disabled={formik.values.countryId !== 1}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("contractors.supervisorEmail")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.supervisorEmail && "error"}
            help={formik.errors.supervisorEmail}
          >
            <Input
              name="supervisorEmail"
              value={formik.values.supervisorEmail}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("address")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.address && "error"}
            help={formik.errors.address}
          >
            <Input
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("trainingCenters.trainingCenters")}
            labelCol={{ span: 24 }}
            validateStatus={
              formik.errors.trainingCentersNotificationIds && "error"
            }
            help={formik.errors.trainingCentersNotificationIds}
          >
            <TrainingCentersSelector
              items={props.trainingCenters}
              curItems={formik.values.trainingCentersNotificationIds}
              onChange={(value: number) =>
                formik.setFieldValue("trainingCentersNotificationIds", value)
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col md={24}>
          <br />
          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("edit")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditContractorForm;
