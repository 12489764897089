import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  CommentOutlined,
  FundProjectionScreenOutlined,
  RadarChartOutlined,
  StarOutlined,
  TranslationOutlined
} from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Row, Space, Typography } from "antd";
import DownloadLink from "@components/common/DownloadLink";
import { EventTypeTag } from "@components/common/events/EventTypeTag";

import { EventDetails, EventType } from "@models/event";

import useDateFormatter from "@hooks/useDateFormat";

interface Props {
  eventData: EventDetails;

  showAddFeedbackBtn?: boolean;
  showViewFeedbackBtn?: boolean;
  showInstructorBtn?: boolean;
  disableInstructorBtn?: boolean;

  onInstructorSet?: () => void;
  onAddFeedback?: () => void;
  onViewFeedback?: () => void;
}

export const EventDetailsCard = ({
  eventData,
  showAddFeedbackBtn,
  showViewFeedbackBtn,
  showInstructorBtn,
  disableInstructorBtn,
  onInstructorSet,
  onAddFeedback,
  onViewFeedback
}: Props) => {
  const [t, i18n] = useTranslation();
  const df = useDateFormatter();

  return (
    <Card>
      <Row gutter={[16, 16]} style={{ marginBottom: "12px" }}>
        <Col span={24}>
          <EventTypeTag
            eventType={eventData.type}
            isCommissioning={eventData.isCommissioning}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: "12px" }}>
        <Col span={16}>
          <Row>
            <Col span={10}>
              <Descriptions size="small" column={1}>
                <Descriptions.Item
                  label={
                    <Typography.Text type="secondary">
                      {t("events.host")}
                    </Typography.Text>
                  }
                >
                  {eventData.trainingCenter.name}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Typography.Text type="secondary">
                      {t("events.csrPhase")}
                    </Typography.Text>
                  }
                >
                  {eventData.csrPhase === 0
                    ? t("none")
                    : t("events.phase", { no: eventData.csrPhase })}
                </Descriptions.Item>
                {eventData.type !== EventType.PRESCREENING && (
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("contractors.contractors")}
                      </Typography.Text>
                    }
                  >
                    <Typography.Text>
                      {eventData.contractors.map((c, idx, contractors) => (
                        <span key={`contractors-${idx}`}>
                          {c.name.split(" ").map((word, wordIdx, words) => (
                            <Fragment key={`contractorsWords-${wordIdx}`}>
                              {word}
                              {words.length - 1 !== wordIdx && <>&nbsp;</>}
                            </Fragment>
                          ))}
                          {`${
                            idx === contractors.length - 1 ||
                            contractors.length === 1
                              ? ""
                              : ", "
                          }`}
                        </span>
                      ))}
                    </Typography.Text>
                  </Descriptions.Item>
                )}
                {eventData.instructor && (
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("events.instructor")}
                      </Typography.Text>
                    }
                  >
                    {eventData.instructor.firstName +
                      " " +
                      eventData.instructor.lastName}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>
            <Col span={7}>
              <Descriptions size="small" column={1}>
                <Descriptions.Item
                  label={
                    <Typography.Text type="secondary">
                      {t("events.location")}
                    </Typography.Text>
                  }
                >
                  {eventData.location
                    ? i18n.language === "ru"
                      ? eventData.location.nameRu
                      : eventData.location.nameEn
                    : "-"}
                </Descriptions.Item>
                {eventData.type !== EventType.PRESCREENING &&
                  eventData.academicHours !== 0 && (
                    <Descriptions.Item
                      label={
                        <Typography.Text type="secondary">
                          {t("events.academicHours")}
                        </Typography.Text>
                      }
                    >
                      {eventData.academicHours}
                    </Descriptions.Item>
                  )}
              </Descriptions>
            </Col>
            <Col span={7}>
              <Descriptions size="small" column={1}>
                <Descriptions.Item
                  label={
                    <Typography.Text type="secondary">
                      {t("events.from")}
                    </Typography.Text>
                  }
                >
                  {df.formatter(eventData.dateTimeStart)}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <Typography.Text type="secondary">
                      {t("events.to")}
                    </Typography.Text>
                  }
                >
                  {df.formatter(eventData.dateTimeEnd)}
                </Descriptions.Item>

                {eventData.type !== EventType.PRESCREENING &&
                  eventData.capacityMin !== 0 && (
                    <Descriptions.Item
                      label={
                        <Typography.Text type="secondary">
                          {t("events.minimumSeats")}
                        </Typography.Text>
                      }
                    >
                      {eventData.capacityMin}
                    </Descriptions.Item>
                  )}
              </Descriptions>
            </Col>
          </Row>
          <Row>
            <Typography.Text>{eventData.description}</Typography.Text>
          </Row>
        </Col>
        <Col md={8}>
          <Row gutter={8} style={{ textAlign: "center" }}>
            <Col md={12} style={{ padding: 16 }}>
              <Space direction="vertical" size="small">
                <RadarChartOutlined style={{ fontSize: 32 }} />

                <Typography.Text strong>
                  {i18n.language === "ru"
                    ? eventData.speciality.nameRu
                    : eventData.speciality.nameEn}
                </Typography.Text>

                <Typography.Text type="secondary">
                  {t("events.discipline")}
                </Typography.Text>
              </Space>
            </Col>

            {eventData.type !== EventType.PRESCREENING &&
              !eventData.isCommissioning && (
                <Col md={12} style={{ padding: 16 }}>
                  <Space direction="vertical" size="small">
                    <FundProjectionScreenOutlined style={{ fontSize: 32 }} />
                    <Typography.Text strong>
                      {eventData.type === EventType.ASSESSMENT
                        ? t(
                            `customCompetencyLevels.${eventData.customCompetencyLevel}`
                          )
                        : t(`competencyLevels.${eventData.competencyLevel}`)}
                    </Typography.Text>

                    <Typography.Text type="secondary">
                      {t("events.competencyLevel")}
                    </Typography.Text>
                  </Space>
                </Col>
              )}

            {eventData.type !== EventType.PRESCREENING && (
              <Col md={12} style={{ padding: 16 }}>
                <Space direction="vertical" size="small">
                  <StarOutlined style={{ fontSize: 32 }} />

                  <Typography.Text strong>{eventData.standard}</Typography.Text>

                  <Typography.Text type="secondary">
                    {t("events.standard")}
                  </Typography.Text>
                </Space>
              </Col>
            )}

            <Col md={12} style={{ padding: 16 }}>
              <Space direction="vertical" size="small">
                <TranslationOutlined style={{ fontSize: 32 }} />

                <Typography.Text strong>
                  {t(`languages.${eventData.languageCode}`)}
                </Typography.Text>

                <Typography.Text type="secondary">
                  {t("events.language")}
                </Typography.Text>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginBottom: "12px" }}>
        <Col span={24}>
          {eventData.files && (
            <Space direction="vertical">
              {eventData.files.map((f, i) => (
                <DownloadLink
                  key={f.id}
                  size="middle"
                  label={f.uniqueFileName}
                  fileName={f.uniqueFileName}
                  url={`/api/event/${eventData.id}/attachment/${f.uniqueFileName}`}
                />
              ))}
            </Space>
          )}
        </Col>
      </Row>

      <Space size={24}>
        {showInstructorBtn && (
          <Button
            type="primary"
            onClick={() => onInstructorSet && onInstructorSet()}
            disabled={disableInstructorBtn}
          >
            {t("events.instructor")}
          </Button>
        )}

        {showAddFeedbackBtn && (
          <Button
            type="primary"
            icon={<CommentOutlined />}
            onClick={() => onAddFeedback && onAddFeedback()}
          >
            {t("events.addFeedback")}
          </Button>
        )}

        {showViewFeedbackBtn && (
          <Button
            type="primary"
            icon={<CommentOutlined />}
            onClick={() => onViewFeedback && onViewFeedback()}
          >
            {t("events.feedback")}
          </Button>
        )}

        <Typography.Text strong style={{ color: "blue" }}>
          {t("events.registeredCount", {
            number: eventData.capacityBusy,
            total: eventData.capacityMax
          })}
        </Typography.Text>
      </Space>
    </Card>
  );
};
