import React from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, Col, Row } from "antd";

import { ADMIN_PERMISSION_OPTION_LIST } from "@constants/adminPermissions";

interface Props {
  options: {
    label?: string;
    values: number[];
    dependsOn?: number;
  }[];

  value: number[];
  onChange: (nextValue: number[]) => void;
}

export const PermissionCheckboxGroup = (props: Props) => {
  const [t] = useTranslation();

  return (
    <Row gutter={8}>
      {ADMIN_PERMISSION_OPTION_LIST.map((po) => {
        const isChecked = po.values.every((p) => props.value.includes(p));

        return (
          <Col xs={12} key={po.label}>
            <Checkbox
              checked={isChecked}
              disabled={!!(po.dependsOn && !props.value.includes(po.dependsOn))}
              onChange={(e) => {
                const _isChecked = (e.nativeEvent.target as HTMLInputElement)
                  .checked;
                const _dependentPermissions =
                  ADMIN_PERMISSION_OPTION_LIST.filter(
                    (_po) => _po.dependsOn === po.values[0]
                  ).reduce(
                    (prev, next) => [...prev, ...next.values],
                    [] as number[]
                  );

                if (_isChecked) {
                  props.onChange([...props.value, ...po.values]);
                } else {
                  const nextVal = props.value
                    .filter(
                      (checkedPermission) =>
                        !po.values.includes(checkedPermission)
                    )
                    .filter((cp) => !_dependentPermissions?.includes(cp)); // filter out dependent permissions if parent is unchecked

                  props.onChange([...nextVal]);
                }
              }}
            >
              {t(`permissions.${po.label || "-"}`)}
            </Checkbox>
          </Col>
        );
      })}
    </Row>
  );
};
