export const enRequests = {
  requests: "Requests",
  list: "Your requests to Training Centers",
  tcList: "Your requests from Contractors",
  create: "Create request",
  subject: "Subject",
  lastCommentDate: "Last reply date",
  numberOfReplies: "Number of replies",
  commentsCount: "Number of replies",
  text: "Text",
  file: "File",
  createdAt: "Creation date",
  createSuccess: "Request was successfully created",
  dateFrom: "Date from",
  dateTo: "Date to",
  requestId: "Request ID",
  requestTitle: "Request to: {{tcName}}",
  requestFromTitle: "Request from: {{contractorName}}",
  requestSubject: "Subject: {{subject}}",
  replies: "Replies",
  replyPlaceholder: "Start typing to reply",
  reply: "Reply",
  replyAttachment: "Attach a file",
  requestDate: "Date of request",
  filter: "Filter",
  assignee: "Assignee",
  attachmentFile: "Attachment file",
  replySuccess: "The reply was successfully created"
};
