import i18next from "i18next";

import { EmployeeEventStatus, EventType } from "@models/event";
import { MouCategoryKey } from "@models/mouCategory";
import {
  ReportByCitizenship,
  ReportByCompetencyLevel,
  ReportByContractor,
  ReportByDisciplineForCommissioning,
  ReportByEventStatus,
  ReportByFailReason,
  ReportByOverallGrade,
  ReportByTrainingCenter,
  ReportByYear,
  MouCategoryReportChartData,
  ReportByDisciplines,
  ReportByLocationUsersCount,
  ReportProfilesByCsrContractor,
  ReportProfilesByEmployedStatus,
  ReportProfilesByDisciplines,
  ReportProfilesByHiringContractor,
  ReportProfilesByTrainingCenter
} from "@models/report";

type GetReportByEventStatusFunction = (
  reportGroup?: ReportByEventStatus
) => { status: EmployeeEventStatus; value?: number }[];

export const getReportByEventStatus: GetReportByEventStatusFunction = (
  reportGroup
) =>
  reportGroup
    ? [
        {
          status: EmployeeEventStatus.INPROGRESS,
          value: reportGroup.inProgress
        },
        {
          status: EmployeeEventStatus.COMPLETED,
          value: reportGroup.completed
        },
        {
          status: EmployeeEventStatus.EVALUATED,
          value: reportGroup.evaluated
        },
        {
          status: EmployeeEventStatus.EXCLUDED,
          value: reportGroup.excluded
        },
        {
          status: EmployeeEventStatus.TOTAL,
          value: Object.values(reportGroup).reduce((sum, v) => sum + v, 0)
        }
      ]
    : [];

export const getReportByDisciplineForCommissioning = (
  lang: string,
  reportGroup?: ReportByDisciplineForCommissioning[]
) => ({
  labels:
    reportGroup?.map((d) =>
      lang === "en" ? d.disciplineEn : d.disciplineRu
    ) || [],
  datasets: reportGroup?.map((d) => d.count) || [],
  max: reportGroup?.map((d) => d.count).reduce((p, v) => (p > v ? p : v), 0)
});

export const getReportByContractor = (reportGroup?: ReportByContractor[]) => {
  const countArray = reportGroup?.map((r) => r.count) || [];
  const maxValue = countArray?.length && Math.max(...countArray);

  return {
    labels: reportGroup?.map((c) => c.name || "") || [],
    datasets:
      reportGroup?.map((c) => ({
        value: c.count,
        label: c.name,
        labels: c.subcontractors.map((s) => s.name) || [],
        subdataset: {
          backgroundColor: "#FD625E",
          data: c.subcontractors.map((s) => s.count) || []
        }
      })) || [],
    max: maxValue && maxValue + maxValue / 5
  };
};

export const getReportByContractorAcademicHours = (
  reportGroup?: ReportByContractor[]
) =>
  reportGroup?.map((r) => ({
    ...r.academicHoursCount,
    label: r.name
  })) || [];

export const getReportByCompetencyLevel = (
  lang: string,
  reportGroup?: ReportByCompetencyLevel
) => {
  const maxValue = reportGroup && Math.max(...reportGroup.map((r) => r.count));
  return {
    labels:
      reportGroup?.map((cl) => (lang === "en" ? cl.labelEn : cl.labelRu)) || [],
    datasets: reportGroup?.map((cl) => cl.count) || [],
    max: maxValue && maxValue + maxValue / 5
  };
};

export const getReportByYear = (reportGroup?: ReportByYear) => ({
  labels: reportGroup?.map((r) => r.year.toString()) || [],
  datasets: reportGroup?.map((r) => r.count)
});

export const getReportByTrainingCenter = (
  reportGroup?: ReportByTrainingCenter[]
) => {
  const countArray = reportGroup?.map((r) => r.count) || [];
  const maxValue = countArray?.length && Math.max(...countArray);
  return {
    labels: reportGroup?.map((r) => r.name || "") || [],
    datasets: reportGroup?.map((r) => r.count),
    max: maxValue && maxValue + maxValue / 5
  };
};

export const getReportByTrainingCenterAcademicHours = (
  reportGroup?: ReportByTrainingCenter[]
) =>
  reportGroup?.map((r) => ({
    ...r.academicHoursCount,
    label: r.name
  })) || [];

export const getReportByCitizenship = (
  lang: string,
  reportGroup?: ReportByCitizenship
) => {
  const total = reportGroup?.reduce((sum, r) => sum + r.count, 0) || 0;

  return total && reportGroup
    ? {
        countriesCount: reportGroup.length,
        datasets: reportGroup.map((r) => ({
          label: lang === "en" ? r.countryEn : r.countryRu,
          count: r.count,
          percentage: Math.floor((r.count / total) * 100)
        }))
      }
    : {
        countriesCount: 0,
        datasets: []
      };
};

export const getReportByFailReason = (reportGroup?: ReportByFailReason) => {
  const total = reportGroup?.reduce((sum, r) => sum + r.count, 0) || 0;

  return total && reportGroup
    ? {
        countriesCount: reportGroup.length,
        datasets: reportGroup.map((r) => ({
          label: r.failReasonTypeLabel,
          count: r.count,
          percentage: Math.floor((r.count / total) * 100)
        }))
      }
    : {
        countriesCount: 0,
        datasets: []
      };
};

export const getReportByOverallGrade = (reportGroup?: ReportByOverallGrade) => {
  const dataEntries = Object.entries(reportGroup || {});
  const total = dataEntries.reduce((sum, [key, count]) => sum + count, 0) || 0;

  return total && reportGroup
    ? {
        countriesCount: dataEntries.length,
        datasets: dataEntries.map(([key, count]) => ({
          label: i18next.t(`events.gradeMap.${key}`),
          count: count,
          percentage: Math.floor((count / total) * 100)
        }))
      }
    : {
        countriesCount: 0,
        datasets: []
      };
};

export const getMouCategoryChartDataByEventType = (
  eventType: EventType,
  mouCategoryKeys: MouCategoryKey[],
  report: MouCategoryReportChartData[] = []
) => {
  const reportByEventType = report.find(
    ({ eventType: et }) => et === eventType
  );

  const dataKeys = [...mouCategoryKeys, "total"];

  const data = {
    labels: reportByEventType?.items.map(({ year }) => year.toString()) || [],
    datasets: dataKeys.map((key) => ({
      key,
      values:
        key === "total"
          ? reportByEventType?.items.map(({ total }) => total) || []
          : reportByEventType?.items.map(
              (item) => item.disciplines.find((d) => d.key === key)?.value || 0
            ) || []
    }))
  };

  return data;
};

export const getReportByDiscipline = (
  lang: string,
  reportGroup?: ReportByDisciplines[]
) => {
  const countArray =
    reportGroup?.map((r) =>
      r.desciplines
        .map((s) =>
          Object.values(s.count).reduce<number>(
            (acc, val) => (acc += Number(val)),
            0
          )
        )
        .reduce((acc, val) => acc + val, 0)
    ) || [];

  const maxValue = reportGroup && Math.max(...countArray);

  return {
    labels: reportGroup?.map((r) => r.category.toString() || "") || [],
    datasets:
      reportGroup?.map((r) => ({
        value: r.desciplines
          .map((s) =>
            Object.values(s.count).reduce<number>(
              (acc, val) => (acc += Number(val)),
              0
            )
          )
          .reduce((acc, val) => acc + val, 0),
        label: i18next.t(`disciplines.${r.category}`),
        labels:
          r.desciplines?.map((r) =>
            lang === "en" ? r.disciplineEn : r.disciplineRu
          ) || [],
        subdataset: {
          backgroundColor: "#FD625E",
          data:
            r.desciplines.map((s) =>
              Object.values(s.count).reduce<number>(
                (acc, val) => acc + Number(val),
                0
              )
            ) || []
        }
      })) || [],
    max: maxValue && maxValue + maxValue / 5
  };
};

export const getReportByLocation = (
  lang: string,
  reportGroup?: ReportByLocationUsersCount[]
) => {
  const maxValue = reportGroup && Math.max(...reportGroup?.map((r) => r.count));

  return {
    labels:
      reportGroup?.map((r) => {
        if (!r.city) return i18next.t("reports.unknown");

        return lang === "en" ? r.city.nameEn : r.city.nameRu;
      }) || [],
    datasets: reportGroup?.map((r) => r.count),
    max: maxValue && maxValue + maxValue / 5
  };
};

export const getReportByDisciplineAcademicHours = (
  reportGroup?: ReportByDisciplines[]
) => {
  return (
    reportGroup?.map((r) => {
      const academicHoursCount = r.desciplines.reduce(
        (acc, val) => {
          if (!val.academicHoursCount) return acc;

          const { assessment, ojtTraining, training } = val.academicHoursCount;

          return {
            assessment: acc.assessment + assessment,
            ojtTraining: acc.ojtTraining + ojtTraining,
            training: acc.training + training
          };
        },
        {
          assessment: 0,
          ojtTraining: 0,
          training: 0
        }
      );

      return {
        ...academicHoursCount,
        label: i18next.t(`disciplines.${r.category}`)
      };
    }) || []
  );
};

export const getReportByCsrContractor = (
  reportGroup?: ReportProfilesByCsrContractor[]
) => {
  const maxValue = reportGroup && Math.max(...reportGroup?.map((r) => r.count));

  return {
    labels: reportGroup?.map((r) => r.name),
    datasets: reportGroup?.map((r) => r.count),
    max: maxValue && maxValue + maxValue / 5
  };
};

export const getReportByEmployedStatus = (
  reportGroup?: ReportProfilesByEmployedStatus[]
) => {
  const maxValue = reportGroup && Math.max(...reportGroup?.map((r) => r.count));

  if (maxValue === 0) {
    return {
      labels: [],
      datasets: [],
      max: 0
    };
  }

  return {
    labels: reportGroup?.map((r) =>
      r.isEmployed ? "reports.profiles.employed" : "reports.profiles.unemployed"
    ),
    datasets: reportGroup?.map((r) => r.count),
    max: maxValue && maxValue + maxValue / 5
  };
};

export const getReportProfilesByDiscipline = (
  lang: string,
  reportGroup?: ReportProfilesByDisciplines[]
) => {
  const maxValue = reportGroup && Math.max(...reportGroup?.map((r) => r.count));

  return {
    labels: reportGroup?.map((r) =>
      lang === "en" ? r.disciplineEn : r.disciplineRu
    ),
    datasets: reportGroup?.map((r) => r.count),
    max: maxValue && maxValue + maxValue / 5
  };
};

export const getReportProfilesByContractor = (
  reportGroup?: ReportProfilesByHiringContractor[]
) => {
  const maxValue = reportGroup && Math.max(...reportGroup?.map((r) => r.count));

  return {
    labels: reportGroup?.map((r) =>
      r.id === 0 ? i18next.t("craftProfiles.otherContractors") : r?.name || "-"
    ),
    datasets: reportGroup?.map((r) => r.count),
    max: maxValue && maxValue + maxValue / 5
  };
};

export const getReportProfilesByTrainingCenter = (
  reportGroup?: ReportProfilesByTrainingCenter[]
) => {
  const maxValue = reportGroup && Math.max(...reportGroup?.map((r) => r.count));

  return {
    labels: reportGroup?.map((r) => r?.trainingCenterName || "-"),
    datasets: reportGroup?.map((r) => r.count),
    max: maxValue && maxValue + maxValue / 5
  };
};
