import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { EventCancelForm } from "@components/common/events/forms/EventCancelForm";

import { CancelEventPayload, EventDetails } from "@models/event";

import { eventsApi } from "@api/eventsApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

interface Props {
  event: EventDetails;
  onEdit?: () => void;
}

const EventCancelModal: React.FC<Props> = ({ event, onEdit }) => {
  const [t] = useTranslation();
  const [isCancelling, setIsCancelling] = useState(false);

  const cancelEventMutation = useValidatedMutation({
    mutationFunction: (values: CancelEventPayload) =>
      eventsApi.adminCancelEvent({
        eventId: event.id,
        cancellationReason: values.cancellationReason
      }),
    onSuccess() {
      notification.success({
        message: t("events.cancelComplete", {
          name: event.name,
          date: event.dateTimeStart
        })
      });

      setIsCancelling(false);

      onEdit && onEdit();
    }
  });
  const validationErrors = useMemo(
    () => transformValidationError(cancelEventMutation.error),
    [cancelEventMutation.error]
  );

  return (
    <>
      <Modal
        visible={isCancelling}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("events.cancelEvent")}
        footer={null}
        width={400}
        onCancel={() => {
          setIsCancelling(false);
        }}
      >
        <EventCancelForm
          event={event}
          isLoading={cancelEventMutation.isLoading}
          errors={validationErrors}
          onCancel={() => setIsCancelling(false)}
          onSubmit={(values) => {
            cancelEventMutation.mutate(values);
          }}
        />
      </Modal>

      <Button
        danger
        icon={<CloseOutlined />}
        onClick={() => setIsCancelling(true)}
      >
        {t("events.cancelEvent")}
      </Button>
    </>
  );
};

export default EventCancelModal;
