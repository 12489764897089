import { useTranslation } from "react-i18next";

import {
  AppstoreOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseSquareOutlined,
  FileDoneOutlined
} from "@ant-design/icons";
import { Card, Row, Space } from "antd";

import { EmployeeEventStatus, EventStatus } from "@models/event";

const getEventStatusIcon = (eventStatus: EmployeeEventStatus) => {
  switch (eventStatus) {
    case EmployeeEventStatus.INPROGRESS:
      return (
        <span style={{ fontSize: "32px", color: "rgb(150, 95, 138)" }}>
          <ClockCircleOutlined />
        </span>
      );
    case EmployeeEventStatus.COMPLETED:
      return (
        <span style={{ fontSize: "32px", color: "rgb(242, 200, 15)" }}>
          <CheckOutlined />
        </span>
      );
    case EmployeeEventStatus.EVALUATED:
      return (
        <span style={{ fontSize: "32px", color: "rgb(1, 184, 170)" }}>
          <FileDoneOutlined />
        </span>
      );
    case EmployeeEventStatus.EXCLUDED:
      return (
        <span style={{ fontSize: "32px", color: "rgb(176, 20, 16)" }}>
          <CloseSquareOutlined />
        </span>
      );
    case EmployeeEventStatus.TOTAL:
      return (
        <span style={{ fontSize: "32px", color: "rgb(176, 20, 16)" }}>
          <AppstoreOutlined />
        </span>
      );
  }
};

const getEventStatus = (eventStatus: EmployeeEventStatus) => {
  switch (eventStatus) {
    case EmployeeEventStatus.INPROGRESS:
      return `events.statuses.${[EventStatus.INPROGRESS]}`;
    case EmployeeEventStatus.COMPLETED:
      return `events.statuses.${[EventStatus.COMPLETED]}`;
    case EmployeeEventStatus.EVALUATED:
      return `events.statuses.${[EventStatus.EVALUATED]}`;
    case EmployeeEventStatus.EXCLUDED:
      return "events.statuses.excluded";
    case EmployeeEventStatus.TOTAL:
      return "events.statuses.total";
    default:
      return "";
  }
};

interface Props {
  eventStatus: EmployeeEventStatus;
  value?: number;
}

export const EventStatusCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Card
      bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Row align="middle">
        <Space
          direction="vertical"
          style={{ width: "100%", height: "100%" }}
          align="center"
        >
          {getEventStatusIcon(props.eventStatus)}
          <span>{props.value || "-"}</span>
          <span>{t(getEventStatus(props.eventStatus))}</span>
        </Space>
      </Row>
    </Card>
  );
};
