import { FC } from "react";

import { Space, Typography } from "antd";
import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";

import { EventEmployeeDetails } from "@models/event";

interface Props {
  employee: EventEmployeeDetails;
  value: number | undefined;
  isExcluded: boolean;
  isEvaluated: boolean;
  isDayOff: boolean;
  isDateExcluded: boolean;
}

const SavedDateColumnBody: FC<Props> = (props) => {
  if (props.isDayOff) {
    return <CloseOutlined />;
  }

  if (props.isExcluded && props.value == undefined) {
    return (
      <Typography.Text strong type="danger">
        E
      </Typography.Text>
    );
  }

  return (
    <Space>
      <Typography.Text strong>{props.value}</Typography.Text>
    </Space>
  );
};

export default SavedDateColumnBody;
