import { useLocation } from "react-router-dom";

import qs from "qs";
export const useQueryParams = (): { [key: string]: any } => {
  const _location = useLocation();

  const _q = _location.search.slice(1);

  return qs.parse(_q, { arrayLimit: 100 });
};
