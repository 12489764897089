import { QueryKey, useQuery } from "react-query";

import { AxiosError } from "axios";

import { notification } from "antd";

export function useDefaultQuery<T = any>(
  queryName: QueryKey,
  queryFunction: () => Promise<T>,
  onSuccess?: (res: T) => void
) {
  const query = useQuery(queryName, queryFunction, {
    retry: false,
    refetchOnMount: true,
    onError: (error: AxiosError<any>) => {
      notification.error({
        message: error.response?.data.message
      });
    },
    onSuccess
  });

  return query;
}
