/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import moment from "moment";
import { differenceInHours } from "date-fns";
import eachDayOfInterval from "date-fns/eachDayOfInterval";

import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import EventDateTimeRangePicker from "@components/common/events/forms/EventDateTimeRangePicker";
import MultiDatePicker from "@components/common/MultiDatePicker";

import {
  CapacityMinNotificationEntitiesOptions,
  CsrPhase,
  EventDetails,
  EventType
} from "@models/event";
import { Location } from "@models/location";

import useDateFormatter from "@hooks/useDateFormat";
import {
  convertNotificationEntities,
  getNotificationDate,
  getNotificationEntitiesValues
} from "@utils/eventHelper";
import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

interface FormValues {
  name?: string;
  academicHours?: number;
  description?: string;
  csrPhase?: CsrPhase;
  dateTimeStart?: string;
  dateTimeEnd?: string;
  daysOff?: string[];
  hoursPerDay?: number;
  capacityMinNotificationDate?: string;
  capacityMinNotificationEntities?: number[];
  capacityMin?: number;
  capacityMax?: number;
  cityId?: number;
}

interface Props {
  event: EventDetails;
  isLoading?: boolean;
  errors?: { [key: string]: string };
  locations?: Location[];

  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

const HOURS_PER_DAY_VARS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const EditEventForm = (props: Props) => {
  const dateFormatter = useDateFormatter();
  const [t, i18n] = useTranslation();
  const { event } = props;

  const formik = useFormik<FormValues>({
    initialValues: {
      name: event.name,
      csrPhase: event.csrPhase,
      academicHours: event.academicHours,
      dateTimeEnd: event.dateTimeEnd,
      dateTimeStart: event.dateTimeStart,
      description: event.description,
      hoursPerDay: event.hoursPerDay,
      capacityMinNotificationDate: getNotificationDate(event),
      capacityMinNotificationEntities: getNotificationEntitiesValues(event),
      capacityMin: event.capacityMin,
      capacityMax: event.capacityMax,
      daysOff: event.daysOff
        ? event.daysOff.map((d) => new Date(d).toISOString())
        : undefined,
      cityId: event.location?.id
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(convertNotificationEntities(values));
      }
    }
  });

  const isTraining = event.type === EventType.TRAINING;
  const isAssessment = event.type === EventType.ASSESSMENT;
  const isPrescreening = event.type === EventType.PRESCREENING;
  const isOjtTraining = event.type === EventType.OJT_TRAINING;

  const showDaysOff = isTraining || isOjtTraining;
  const showCsrPhase =
    !event.isCommissioning && (isTraining || isAssessment || isPrescreening);

  const showCapacityMin = !isPrescreening && !isOjtTraining;
  const showNotificationSettings = !isPrescreening;
  const showCapacityMax = !isPrescreening;
  const showAcademicHours = !isPrescreening;
  const showStandard = !isPrescreening;
  const showDescription = !isPrescreening;
  const showNotificationEntities = formik.values.capacityMinNotificationDate;

  const hoursTillEvent = formik.values.dateTimeStart
    ? differenceInHours(new Date(formik.values.dateTimeStart), new Date())
    : undefined;

  const eventDates = useMemo(() => {
    if (formik.values.dateTimeStart && formik.values.dateTimeEnd) {
      try {
        const val = eachDayOfInterval({
          start: new Date(formik.values.dateTimeStart),
          end: new Date(formik.values.dateTimeEnd)
        }).map((v) => ({
          value: v.toISOString(),
          label: dateFormatter.formatter(v.toJSON(), "short-date")
        }));

        return val;
      } catch (err) {
        return [];
      }
    }
  }, [formik.values.dateTimeStart, formik.values.dateTimeEnd]);

  useEffect(() => {
    if (!showDaysOff) {
      formik.setFieldValue("daysOff", undefined);
    }
  }, [showDaysOff]);

  useEffect(() => {
    if (!showCsrPhase) {
      formik.setFieldValue("csrPhase", undefined);
    }
  }, [showCsrPhase]);

  useEffect(() => {
    if (!showCapacityMin) {
      formik.setFieldValue("capacityMin", undefined);
    }
  }, [showCapacityMin]);

  useEffect(() => {
    if (!showStandard) {
      formik.setFieldValue("standard", undefined);
    }
  }, [showStandard]);

  useEffect(() => {
    if (!showDescription) {
      formik.setFieldValue("description", undefined);
    }
  }, [showDescription]);

  useEffect(() => {
    if (!formik.values.academicHours) {
      formik.setFieldValue("academicHours", undefined);
    }
  }, [formik.values.academicHours]);
  useEffect(() => {
    if (!formik.values.capacityMin) {
      formik.setFieldValue("capacityMin", undefined);
    }
  }, [formik.values.capacityMin]);
  useEffect(() => {
    if (!formik.values.capacityMax) {
      formik.setFieldValue("capacityMax", undefined);
    }
  }, [formik.values.capacityMax]);

  // Check if the days off are now outside of event start and end dates
  useEffect(() => {
    const currentDaysOff = formik.values.daysOff;
    formik.setFieldValue(
      "daysOff",
      currentDaysOff?.filter((day) =>
        eventDates?.some(({ value }) => value === day)
      )
    );
  }, [eventDates]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      {Boolean(event.type) && (
        <Row gutter={8}>
          <Col md={24}>
            <Form.Item
              label={t("events.name")}
              validateStatus={formik.errors.name && "error"}
              help={formik.errors.name}
            >
              <Input
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>

          {showAcademicHours && (
            <Col md={showCsrPhase ? 12 : 24}>
              <Form.Item
                label={t("events.academicHours")}
                validateStatus={formik.errors.academicHours && "error"}
                help={formik.errors.academicHours}
              >
                <Input
                  name="academicHours"
                  value={formik.values.academicHours}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          )}

          <Col md={12}>
            <Form.Item
              label={t("events.location")}
              validateStatus={formik.errors.cityId && "error"}
              help={formik.errors.cityId}
            >
              <Select
                value={formik.values.cityId}
                onChange={(v) => {
                  formik.setFieldValue("cityId", v);
                }}
              >
                {props.locations?.map((location, index) => (
                  <Select.Option key={`location-${index}`} value={location.id}>
                    {i18n.language === "en" ? location.nameEn : location.nameRu}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {showCsrPhase && (
            <Col md={showAcademicHours ? 12 : 24}>
              <Form.Item
                label={t("events.csrPhase")}
                validateStatus={formik.errors.csrPhase && "error"}
                help={formik.errors.csrPhase}
              >
                <Select
                  value={formik.values.csrPhase}
                  onChange={(v) => formik.setFieldValue("csrPhase", v)}
                >
                  {CSR_PHASE_OPTIONS.map((cp) => (
                    <Select.Option value={cp.value} key={cp.value}>
                      {t(cp.label, { no: cp.value })}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {showDescription && (
            <Col md={24}>
              <Form.Item
                label={t("events.description")}
                validateStatus={formik.errors.description && "error"}
                help={formik.errors.description}
              >
                <Input.TextArea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          )}

          <EventDateTimeRangePicker
            onStartChange={(v) =>
              formik.setFieldValue("dateTimeStart", v?.toISOString())
            }
            onEndChange={(v) =>
              formik.setFieldValue("dateTimeEnd", v?.toISOString())
            }
            dateTimeStart={formik.values.dateTimeStart}
            dateTimeEnd={formik.values.dateTimeEnd}
            dateTimeStartError={formik.errors.dateTimeStart}
            dateTimeEndError={formik.errors.dateTimeEnd}
          />

          {showDaysOff && (
            <>
              <Col md={12}>
                <Form.Item
                  label={t("events.setDaysOff")}
                  validateStatus={formik.errors.daysOff && "error"}
                  help={formik.errors.daysOff}
                >
                  <MultiDatePicker
                    value={formik.values.daysOff || []}
                    onChange={(v) => formik.setFieldValue("daysOff", v)}
                    disabledDate={(date) =>
                      date.isAfter(formik.values.dateTimeEnd) ||
                      date.isBefore(formik.values.dateTimeStart)
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item
                  label={t("events.hoursPerDay")}
                  validateStatus={formik.errors.hoursPerDay && "error"}
                  help={formik.errors.hoursPerDay}
                >
                  <Select
                    value={formik.values.hoursPerDay}
                    onChange={(v) => formik.setFieldValue("hoursPerDay", v)}
                  >
                    {HOURS_PER_DAY_VARS.map((v) => (
                      <Select.Option value={v} key={v}>
                        {v}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}

          {showCapacityMax && (
            <Col md={showCapacityMin ? 12 : 24}>
              <Form.Item
                label={t("events.numberOfSeats")}
                validateStatus={formik.errors.capacityMax && "error"}
                help={formik.errors.capacityMax}
              >
                <Input
                  name="capacityMax"
                  value={formik.values.capacityMax}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          )}

          {showCapacityMin && (
            <Col md={12}>
              <Form.Item
                label={t("events.minimumSeats")}
                validateStatus={formik.errors.capacityMin && "error"}
                help={formik.errors.capacityMin}
              >
                <Input
                  name="capacityMin"
                  value={formik.values.capacityMin}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          )}

          {showNotificationSettings && (
            <>
              <Col md={24}>
                <Form.Item
                  label={t("events.notificationDate")}
                  validateStatus={
                    formik.errors.capacityMinNotificationDate && "error"
                  }
                  help={formik.errors.capacityMinNotificationDate}
                >
                  <DatePicker
                    allowClear
                    placeholder=""
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    disabled={hoursTillEvent ? hoursTillEvent < 25 : true}
                    disabledDate={(date) =>
                      !(
                        date.isBefore(
                          moment(formik.values.dateTimeStart).startOf("day")
                        ) && date.isAfter(moment().endOf("day"))
                      )
                    }
                    value={
                      formik.values.capacityMinNotificationDate
                        ? moment(formik.values.capacityMinNotificationDate)
                        : null
                    }
                    onChange={(v) =>
                      formik.setFieldValue(
                        "capacityMinNotificationDate",
                        v?.toISOString()
                      )
                    }
                  />
                </Form.Item>
              </Col>
              {showNotificationEntities && (
                <Col md={24}>
                  <Form.Item
                    label={t("events.notificationEntities")}
                    validateStatus={
                      formik.errors.capacityMinNotificationEntities && "error"
                    }
                    help={formik.errors.capacityMinNotificationEntities}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      showArrow
                      optionFilterProp="name"
                      filterOption={(inp, opt) => {
                        if (opt && opt.children) {
                          return (
                            opt.children
                              .toString()
                              .toLowerCase()
                              .indexOf(inp.toLowerCase()) >= 0
                          );
                        }

                        return false;
                      }}
                      value={formik.values.capacityMinNotificationEntities}
                      onChange={(v) =>
                        formik.setFieldValue(
                          "capacityMinNotificationEntities",
                          v
                        )
                      }
                    >
                      <Select.Option
                        value={CapacityMinNotificationEntitiesOptions.TCO}
                      >
                        {t("events.notificationEntitiesOptions.TCO")}
                      </Select.Option>

                      <Select.Option
                        value={
                          CapacityMinNotificationEntitiesOptions.TRAINING_CENTER
                        }
                      >
                        {t("events.notificationEntitiesOptions.trainingCenter")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </>
          )}
        </Row>
      )}

      <br />

      <Row justify="space-between">
        <Col>
          <Button onClick={() => props.onCancel && props.onCancel()}>
            {t("cancel")}
          </Button>
        </Col>

        <Col>
          <Button type="primary" htmlType="submit" loading={props.isLoading}>
            {t("edit")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
