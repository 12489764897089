import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { AxiosError } from "axios";
import { differenceInHours, format, parseISO } from "date-fns";
import Column from "antd/lib/table/Column";

import {
  DeleteOutlined,
  EditOutlined,
  PoweroffOutlined,
  RedoOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Modal,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography
} from "antd";
import PageMeta from "@components/PageMeta";
import InviteTrainingCenterModal from "@components/admin/training-centers/InviteTrainingCenterModal";
import EditTrainingCenterMemberPermissionsForm from "@components/admin/training-centers/EditTrainingCenterMemberPermissionsForm";
import { MemberTable } from "@components/common/MemberTable";
import { BreadcrumbRenderer } from "@components/BreadcrumbRenderer";

import { MemberStatus } from "@models/member";
import { Instructor } from "@models/instructor";
import {
  EditTrainingCenterMemberPermissionPayload,
  InviteTrainingCenterMemberPayload,
  TrainingCenterMember
} from "@models/trainingCenterMember";

import { trainingCenterApi } from "@api/trainingCenterApi";

import useDateFormatter from "@hooks/useDateFormat";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { useDefaultQuery } from "@hooks/useDefaultQuery";
import { transformValidationError } from "@utils/errorHelper";

const MAX_DISCIPLINES_IN_TABLE_COLUMN = 3;

const { Title } = Typography;

interface PageParams {
  id: string;
}

export const TrainingCenterDetailsPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { id } = useParams<PageParams>();
  const TRAINING_CENTER_ID = Number(id);

  const { formatter } = useDateFormatter();

  const [editableTrainingCenterMember, setEditableTrainingCenterMember] =
    useState<TrainingCenterMember | null>(null);

  const [isInviteMemberModalOpen, setInviteMemberModalOpen] =
    useState<boolean>(false);

  const trainingCenterDetails = useDefaultQuery("getTrainingCenter", () =>
    trainingCenterApi
      .getTrainingCenter(TRAINING_CENTER_ID)
      .then((res) => res.data)
  );

  const trainingCenterMembers = useDefaultQuery(
    "getTrainingCenterAllMembers",
    () =>
      trainingCenterApi
        .getTrainingCenterMembers(TRAINING_CENTER_ID)
        .then((res) => res.data)
  );
  const activeMembers = trainingCenterMembers.data?.filter(
    (tcMember) => tcMember.status === MemberStatus.ACTIVE
  );
  const inactiveMembers = trainingCenterMembers.data?.filter(
    (tcMember) => tcMember.status === MemberStatus.INACTIVE
  );
  const invitedMembers = trainingCenterMembers.data?.filter(
    (tcMember) => tcMember.status === MemberStatus.INVITED
  );

  const trainingCenterInstructors = useDefaultQuery(
    "getTrainingCenterInstructors",
    () =>
      trainingCenterApi
        .getTrainingCenterInstructors({
          trainingCenterId: TRAINING_CENTER_ID
        })
        .then((res) => res.data)
  );

  const trainingCenterMemberEditPermissionMutation = useValidatedMutation({
    mutationFunction: (payload: EditTrainingCenterMemberPermissionPayload) =>
      trainingCenterApi.editTrainingCenterMemberPermission(payload),

    onSuccess: () => {
      trainingCenterMembers.refetch();
      notification.success({
        message: t("members.editSuccess")
      });
      setEditableTrainingCenterMember(null);
    }
  });
  const editErrors = useMemo(
    () =>
      transformValidationError(
        trainingCenterMemberEditPermissionMutation.error
      ),
    [trainingCenterMemberEditPermissionMutation.error]
  );

  const trainingCenterMemberActivateMutation = useMutation(
    (payload: {
      trainingCenterId: number;
      userEmail: string;
      userId: number;
    }) =>
      trainingCenterApi.activateTrainingCenterMember({
        trainingCenterId: payload.trainingCenterId,
        userId: payload.userId
      }),
    {
      onSuccess: (_, context) => {
        trainingCenterMembers.refetch();
        notification.success({
          message: t("members.activationSuccess", {
            email: context.userEmail
          })
        });
      },
      onError: (err: AxiosError<any>) => {
        if (err.response && err.response.status < 500) {
          notification.error({
            message: err.response.data?.message
          });
        }
      }
    }
  );

  const trainingCenterMemberResendInviteMutation = useMutation(
    (payload: { trainingCenterId: number; email: string }) =>
      trainingCenterApi.resendTrainingCenterMemberInvite(payload),
    {
      onSuccess: (_, context) => {
        trainingCenterMembers.refetch();
        notification.success({
          message: t("members.inviteResent", {
            email: context.email
          })
        });
      },
      onError: (err: AxiosError<any>) => {
        if (err.response && err.response.status < 500) {
          notification.error({
            message: err.response.data.message
          });
        }
      }
    }
  );

  const trainingCenterMemberDeleteInviteMutation = useMutation(
    (payload: { trainingCenterId: number; email: string }) =>
      trainingCenterApi.deleteTrainingCenterMemberInvite(payload),
    {
      onSuccess: () => {
        trainingCenterMembers.refetch();
        notification.success({
          message: t("members.inviteDeleted")
        });
      },
      onError: (err: AxiosError<any>) => {
        if (err.response && err.response.status < 500) {
          notification.error({
            message: err.response.data.message
          });
        }
      }
    }
  );

  const trainingCenterMemberDeleteMutation = useMutation(
    (payload: { trainingCenterId: number; userId: number }) =>
      trainingCenterApi.deleteTrainingCenterMember(payload),
    {
      onSuccess: () => {
        trainingCenterMembers.refetch();
        notification.success({
          message: t("members.memberDeleted")
        });
      },
      onError: (err: AxiosError<any>) => {
        if (err.response && err.response.status < 500) {
          notification.error({
            message: err.response.data.message
          });
        }
      }
    }
  );

  const trainingCenterMemberInviteMutation = useValidatedMutation({
    mutationFunction: (payload: InviteTrainingCenterMemberPayload) =>
      trainingCenterApi.inviteTrainingCenterMember(payload),
    onSuccess: () => {
      trainingCenterMembers.refetch();
      notification.success({
        message: t("members.editSuccess")
      });
      setInviteMemberModalOpen(false);
    }
  });

  const inviteErrors = useMemo(
    () => transformValidationError(trainingCenterMemberInviteMutation.error),
    [trainingCenterMemberInviteMutation.error]
  );

  const pageTitle = trainingCenterDetails.data?.name || "";
  return (
    <>
      <PageMeta title={pageTitle} />
      <PageHeader
        breadcrumb={{
          routes: [
            { breadcrumbName: "CT", path: "/" },
            {
              breadcrumbName: t("trainingCenters.trainingCenters"),
              path: "/admin/training-centers"
            },
            {
              breadcrumbName: pageTitle,
              path: ""
            }
          ],
          itemRender: BreadcrumbRenderer
        }}
        extra={
          <InviteTrainingCenterModal
            isModalVisible={isInviteMemberModalOpen}
            handleOpenModal={() => setInviteMemberModalOpen(true)}
            handleCloseModal={() => setInviteMemberModalOpen(false)}
            onSubmit={trainingCenterMemberInviteMutation.mutate}
            isLoading={trainingCenterMemberInviteMutation.isLoading}
            trainingCenterId={TRAINING_CENTER_ID}
            errors={inviteErrors}
          />
        }
      />

      <Card
        style={{ marginBottom: 32 }}
        loading={trainingCenterDetails.isFetching}
      >
        <Card.Meta
          title={<Typography.Title level={2}>{pageTitle}</Typography.Title>}
          description={
            <Typography.Text type="secondary">
              {trainingCenterDetails.data &&
                t("registrationDate", {
                  date: format(
                    parseISO(trainingCenterDetails.data.createdAt),
                    "dd-MM-yyyy"
                  )
                })}
            </Typography.Text>
          }
        />

        <Row>
          <Col xs={12}>
            <Typography.Text type="secondary">
              {i18n.language === "en"
                ? trainingCenterDetails.data?.city.nameEn
                : trainingCenterDetails.data?.city.nameRu}
            </Typography.Text>
            <br />
            <Typography.Text type="secondary">
              {trainingCenterDetails.data?.address}
            </Typography.Text>
          </Col>
          <Col xs={12}>
            <Typography.Text strong>
              {t("disciplines.disciplines")}
            </Typography.Text>
            <div>
              {trainingCenterDetails.data?.specialities.map((d) => (
                <Tag>{i18n.language === "en" ? d?.nameEn : d?.nameRu}</Tag>
              ))}
            </div>
          </Col>
        </Row>
      </Card>

      <Modal
        visible={!!editableTrainingCenterMember}
        title={t("trainingCenters.editMemberPermissions")}
        destroyOnClose
        maskClosable={false}
        width={800}
        footer={null}
        onCancel={() => {
          setEditableTrainingCenterMember(null);
          trainingCenterMemberEditPermissionMutation.reset();
        }}
      >
        <EditTrainingCenterMemberPermissionsForm
          trainingCenterId={TRAINING_CENTER_ID}
          trainingCenterMember={editableTrainingCenterMember}
          isLoading={trainingCenterMemberEditPermissionMutation.isLoading}
          errors={editErrors}
          onSubmit={(values) =>
            trainingCenterMemberEditPermissionMutation.mutate(values)
          }
          onCancel={() => {
            setEditableTrainingCenterMember(null);
            trainingCenterMemberEditPermissionMutation.reset();
          }}
        />
      </Modal>

      <Title level={2}>{t("members.members")}</Title>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <MemberTable
          title={t("members.activeMembers")}
          isLoading={trainingCenterMembers.isFetching}
          members={activeMembers}
          renderActions={(tcMember: TrainingCenterMember) => (
            <Space size="middle">
              <Button
                title={t("edit")}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => setEditableTrainingCenterMember(tcMember)}
              />

              <Popconfirm
                title={t("members.confirmDelete", {
                  name: tcMember.firstName + " " + tcMember.lastName
                })}
                cancelText={t("cancel")}
                okText={t("yes")}
                disabled={trainingCenterMemberDeleteMutation.isLoading}
                onConfirm={() =>
                  trainingCenterMemberDeleteMutation.mutate({
                    trainingCenterId: TRAINING_CENTER_ID,
                    userId: tcMember.userId
                  })
                }
              >
                <Button
                  title={t("members.delete")}
                  danger
                  shape="circle"
                  disabled={trainingCenterMemberDeleteMutation.isLoading}
                  loading={
                    trainingCenterMemberDeleteMutation.isLoading &&
                    trainingCenterMemberDeleteMutation.variables?.userId ===
                      tcMember.userId
                  }
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          )}
        />

        <MemberTable
          title={t("members.inactiveMembers")}
          isLoading={trainingCenterMembers.isFetching}
          members={inactiveMembers}
          renderActions={(tcMember: TrainingCenterMember) => (
            <Space size="middle">
              <Button
                title={t("members.activate")}
                type="primary"
                shape="circle"
                icon={<PoweroffOutlined />}
                disabled={trainingCenterMemberActivateMutation.isLoading}
                loading={
                  trainingCenterMemberActivateMutation.isLoading &&
                  trainingCenterMemberActivateMutation.variables?.userId ===
                    tcMember.userId
                }
                onClick={() =>
                  trainingCenterMemberActivateMutation.mutate({
                    trainingCenterId: TRAINING_CENTER_ID,
                    userId: tcMember.userId,
                    userEmail: tcMember.email
                  })
                }
              />

              <Popconfirm
                title={t("members.confirmDelete", {
                  name: tcMember.firstName + " " + tcMember.lastName
                })}
                cancelText={t("cancel")}
                okText={t("yes")}
                disabled={trainingCenterMemberDeleteMutation.isLoading}
                onConfirm={() =>
                  trainingCenterMemberDeleteMutation.mutate({
                    trainingCenterId: TRAINING_CENTER_ID,
                    userId: tcMember.userId
                  })
                }
              >
                <Button
                  title={t("delete")}
                  danger
                  shape="circle"
                  disabled={trainingCenterMemberDeleteMutation.isLoading}
                  loading={
                    trainingCenterMemberDeleteMutation.isLoading &&
                    trainingCenterMemberDeleteMutation.variables?.userId ===
                      tcMember.userId
                  }
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          )}
        />

        <MemberTable
          title={t("members.invitedMembers")}
          isLoading={trainingCenterMembers.isFetching}
          members={invitedMembers}
          extraColumns={[
            {
              title: t("phoneNumber"),
              render: (tcMember: TrainingCenterMember) => tcMember.phoneNumber
            }
          ]}
          renderActions={(tcMember: TrainingCenterMember) => {
            const inviteDate = parseISO(tcMember.invitedAt);
            const hourDiff = differenceInHours(Date.now(), inviteDate);
            const isInviteExpired = hourDiff >= 24;

            return (
              <Space size="middle">
                <Button
                  title={t("members.resend")}
                  type="primary"
                  shape="circle"
                  icon={<RedoOutlined />}
                  disabled={
                    !isInviteExpired ||
                    trainingCenterMemberResendInviteMutation.isLoading
                  }
                  loading={
                    trainingCenterMemberResendInviteMutation.isLoading &&
                    trainingCenterMemberResendInviteMutation.variables
                      ?.email === tcMember.email
                  }
                  onClick={() =>
                    trainingCenterMemberResendInviteMutation.mutate({
                      trainingCenterId: TRAINING_CENTER_ID,
                      email: tcMember.email
                    })
                  }
                />

                <Popconfirm
                  title={t("members.confirmDelete", {
                    name: tcMember.firstName + " " + tcMember.lastName
                  })}
                  cancelText={t("cancel")}
                  okText={t("yes")}
                  disabled={trainingCenterMemberDeleteInviteMutation.isLoading}
                  onConfirm={() =>
                    trainingCenterMemberDeleteInviteMutation.mutate({
                      trainingCenterId: TRAINING_CENTER_ID,
                      email: tcMember.email
                    })
                  }
                >
                  <Button
                    title={t("delete")}
                    danger
                    shape="circle"
                    disabled={
                      trainingCenterMemberDeleteInviteMutation.isLoading
                    }
                    loading={
                      trainingCenterMemberDeleteInviteMutation.isLoading &&
                      trainingCenterMemberDeleteInviteMutation.variables
                        ?.email === tcMember.email
                    }
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </Space>
            );
          }}
        />

        <Table
          title={() => (
            <Space align="center">
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                {t("instructors")}
              </Typography.Title>

              <Typography.Text type="secondary">
                {t("totalCount", {
                  total: trainingCenterInstructors.data?.length || 0
                })}
              </Typography.Text>
            </Space>
          )}
          locale={{ emptyText: t("noData") }}
          loading={trainingCenterInstructors.isFetching}
          dataSource={trainingCenterInstructors.data}
          bordered
          pagination={false}
          rowKey="id"
          onHeaderRow={() => {
            // TODO: find a better solution for setting disciplines column title
            // Currently is set this way because expandable column configuration does not include title
            const expandable = document.getElementsByClassName(
              "ant-table-row-expand-icon-cell"
            );
            if (expandable.length > 0) {
              expandable[0].innerHTML = t("disciplines.disciplines");
            }
            return {};
          }}
          expandable={{
            expandIconColumnIndex: 2,
            columnWidth: 420,

            rowExpandable: (instructor: Instructor) =>
              instructor.specialities?.length > MAX_DISCIPLINES_IN_TABLE_COLUMN,
            expandIcon: ({ record: instructor, onExpand, expanded }) => (
              <Space wrap>
                {instructor.specialities
                  .slice(0, MAX_DISCIPLINES_IN_TABLE_COLUMN)
                  .map((discipline) => (
                    <Tag color="geekblue" key={discipline.id}>
                      {i18n.language === "en"
                        ? discipline.speciality.nameEn
                        : discipline.speciality.nameRu}
                    </Tag>
                  ))}
                {instructor.specialities?.length >
                  MAX_DISCIPLINES_IN_TABLE_COLUMN && (
                  <Button
                    title={t("readMore")}
                    size="small"
                    onClick={(event) => onExpand(instructor, event)}
                  >
                    {t("readMore")}
                  </Button>
                )}
              </Space>
            ),
            expandedRowRender: (instructor: Instructor) => (
              <Space wrap>
                {instructor.specialities?.map(
                  ({ id, speciality: { nameEn, nameRu } }) => (
                    <Tag color={"geekblue"} key={id}>
                      {i18n.language === "en" ? nameEn : nameRu}
                    </Tag>
                  )
                )}
              </Space>
            )
          }}
        >
          <Column title={t("firstName")} dataIndex="firstName" />
          <Column title={t("lastName")} dataIndex="lastName" />

          <Column
            title={t("numberOfTimesTrained")}
            dataIndex="sumEventsProvided"
          />
          <Column
            title={t("dateAdded")}
            render={(instructor: Instructor) => formatter(instructor.createdAt)}
          />
        </Table>
      </Space>
    </>
  );
};
