export const ruCommon = {
  loginBtn: "Войти",
  logoutBtn: "Выйти",

  contactUs: "Свяжитесь с нами",

  aboutUs: "О нас",
  userGuide: "Руководство пользователя",
  whatsNew: "Что нового",
  menuContract: "Контракт",

  phone: "Тел.:",

  submit: "Отправить",
  cancel: "Отменить",
  ok: "OK",
  yes: "Да",
  no: "Нет",
  back: "Назад",
  next: "Далее",
  add: "Добавить",
  apply: "Записаться",
  viewDetails: "Просмотреть",

  all: "Все",

  create: "Создать",
  register: "Зарегистрировать",
  edit: "Редактировать",
  change: "Изменить",
  delete: "Удалить",
  save: "Сохранить",
  search: "Поиск",
  filter: "Фильтровать",
  userExport: "Экспортировать пользователей",

  actions: "Действия",
  moreInfo: "Подробнее",
  noData: "Нет данных",
  download: "Скачать",
  retry: "Повторить",

  none: "Нет",

  email: "Email",
  name: "Имя",
  fullName: "Полное имя",
  firstName: "Имя",
  lastName: "Фамилия",
  middleName: "Отчество",
  jobTitle: "Должность",
  phoneNumber: "Телефон",
  workPhoneNumber: "Рабочий телефон",
  department: "Департамент",
  employeeNumber: "Номер сотрудника",
  education: "Образование",
  additionalEducation: "Дополнительные курсы",
  dateOfBirth: "Дата рождения",
  maritalStatus: "Семейное положение",
  workExperience: "Опыт работы",
  personalQualities: "Личные качества",
  additionalInformation: "Дополнительная информация",
  knownLanguages: "Знание языков",

  csrContractor: "Подрядчик КСО",
  otherCompany: "Другая организация",
  employmentStatus: "Статус трудоустройства",
  contractorSubcontractor: "Подрядчик / Субподрядчик",

  local: "Местный сотрудник",
  expat: "Экспатриант",

  password: "Пароль",
  oldPassword: "Старый пароль",
  newPassword: "Новый пароль",
  confirmPassword: "Подтвердить пароль",

  country: "Страна",
  city: "Город",
  address: "Адрес",
  language: "Язык",
  standard: "Стандарт",
  dateFrom: "Дата с",
  dateTo: "Дата по",

  count: "Количество",

  from: "с",
  to: "по",

  availableSeats: "Свободных мест",

  outOfCapacity: "{{quantity}} из {{capacity}}",

  registrationDate: "Дата регистрации: {{date}}",
  dateAdded: "Дата добавления",
  fromToRange: "c {{from}} до {{to}}",

  discipline: "Дисциплина",
  disciplines: "Дисциплины",
  instructor: "Инструктор",
  instructors: "Инструкторы",
  numberOfTimesTrained: "Количество проведенных мероприятий",
  supervisor: "Супервайзер",
  training: "Тренинг",

  eventTypes: {
    eventTypes: "Типы событий",
    0: "Undefined",
    1: "Оценка",
    2: "Тренинг",
    3: "Пре-скрининг",
    4: "ОСП",
    11: "Ввод в эксплуатацию - Оценка",
    12: "Ввод в эксплуатацию - Тренинг"
  },

  competencyLevels: {
    null: "–",
    competencyLevel: "Уровень компетентности",

    2: "Базовый",
    3: "Средний",
    4: "Продвинутый",
    5: "Компетентный",
    6: "Профессиональный",
    7: "Помощник",
    20: "Обучаемый техник",
    22: "Младший техник",
    24: "Техник",
    26: "Старший техник"
  },

  customCompetencyLevels: {
    1: "Стандартный",
    6: "Средний и продвинутый"
  },

  languages: {
    1: "Русский",
    2: "Английский",
    3: "Казахский"
  },

  totalCount: "Всего: {{total}}",
  total: "Всего",

  csr: "КСО",
  csrPhase: "Фаза КСО",
  commissioning: "Ввод в эксплуатацию",

  error: {
    errorDownload: "Не удалось скачать файл, попробуйте позднее.",
    errorRequest:
      "Не удалось провести запрос, проверьте своё подключение к сети",
    errorOccured: "Возникла ошибка",
    titleWithStatus: "Ошибка {{status}}",
    fieldRequired: "Поле {{field}} обязательно для заполнения",
    participantsError: "Запрос содержит дублирующихся участников"
  },

  userAgreement: {
    acceptCheckbox: "Я принимаю пользовательское соглашение",
    proceed: "Продолжить",
    userAgreement: "Пользовательское соглашение"
  },

  bin: "БИН",
  iin: "ИИН",
  status: "Статус",

  contractor: "Подрядчик",
  subcontractor: "Субподрядчик",
  practicalGrade: "Практическая оценка",
  theoreticalGrade: "Теоритическая оценка",
  overallGrade: "Общая оценка",
  failReason: "Причина провала",
  craftWorkerStatus: "Статус",
  theoreticalStatus: "Статус по теории",
  practicalStatus: "Статус по практике",
  recommendation: "Рекомендация",
  other: "Другое",

  createdAt: "Дата создания",
  readMore: "Подробнее",
  showLess: "Скрыть",
  hide: "Скрыть",
  andQuantityMore: "и еще {{quantity}}",

  idleLogout: "Вы вышли по причине неактивности",

  inProgress: "Генерируется",
  completed: "Завершён",
  failed: "Ошибка",

  title: "Наименование",

  expand: "Развернуть",
  fileSizeLimit: "Размер файла не должен превышать {{size}}Мб",
  select: "Выбрать"
};
