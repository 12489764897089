import React from "react";
import { useTranslation } from "react-i18next/";

import { Button, Popconfirm, Space, Table, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Contract, StaticDataMap } from "@models/contract";

interface Props {
  title?: React.ReactNode;
  isLoading?: boolean;
  projects: StaticDataMap[];
  kcCategories: StaticDataMap[];
  data?: Contract[];
  extraColumns?: {
    title?: string;
    dataIndex?: string;
    render?: (value: any, record: unknown, index: number) => React.ReactNode;
  }[];
  onEdit?: (item: Contract) => void;
  onDelete?: (contract: Contract) => void;
  canEdit?: boolean;
}

const ContractsTable = (props: Props) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("contract.contractNumber"),
      dataIndex: "contractNumber",
      key: "contractNumber"
    },
    {
      title: t("contract.kcCatAndArea"),
      dataIndex: "kcCategory",
      key: "kcCategory",
      render: (kcCategoryId: number) =>
        props.kcCategories.find((c) => c.id === kcCategoryId)?.name || ""
    },
    {
      title: t("contract.project"),
      dataIndex: "projectId",
      key: "projectId",
      render: (projectId: number) =>
        props.projects.find((c) => c.id === projectId)?.name || ""
    },
    {
      title: t("contract.capacity"),
      dataIndex: "capacity",
      key: "capacity"
    }
  ];

  const editColumn = {
    title: t("actions"),
    key: "actions",
    render: (item: Contract) => (
      <Space size="middle">
        {props.onEdit && (
          <Button
            title={t("edit")}
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              props.onEdit && props.onEdit(item);
            }}
          />
        )}

        {props.onDelete && (
          <Popconfirm
            title={t("contract.confirmDelete", {
              contractNumber: item.contractNumber
            })}
            cancelText={t("cancel")}
            okText={t("yes")}
            onConfirm={() => {
              props.onDelete && props.onDelete(item);
            }}
          >
            <Button
              title={t("delete")}
              danger
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        )}
      </Space>
    )
  };

  return (
    <Table
      title={
        props.title
          ? () => (
              <Space align="center">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {props.title}
                </Typography.Title>

                <Typography.Text type="secondary">
                  {t("totalCount", {
                    total: props.data ? props.data.length : 0
                  })}
                </Typography.Text>
              </Space>
            )
          : undefined
      }
      pagination={false}
      columns={props.canEdit ? [...columns, editColumn] : columns}
      dataSource={props.data}
      rowKey="id"
      loading={props.isLoading}
      locale={{ emptyText: t("noData") }}
      bordered
    />
  );
};

ContractsTable.defaultProps = {
  canEdit: true
};

export default ContractsTable;
