import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { AxiosError } from "axios";
import saveAs from "file-saver";

import { notification } from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { TrainingReportTable } from "@components/common/tc-reports/TrainingReportTable";
import { TCReportFilterForm } from "@components/common/tc-reports/TCReportFilterForm";
import { TCReportListItemActions } from "@components/common/tc-reports/TCReportListItemActions";

import {
  TrainingCenterReport,
  TcReportTypes,
  TCReportFormValues
} from "@models/trainingCenter";

import { trainingCenterApi } from "@api/trainingCenterApi";

import { useQueryParams } from "@hooks/useQueryParams";
import { usePagination } from "@hooks/usePagination";

export const TrainingReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const reportType = TcReportTypes.TRAINING_REPORT;
  const queryParams = useQueryParams();
  const { page, setPage, setPagination } = usePagination();
  const { ...initialFilters } = queryParams;
  const [filterData, setFilterData] =
    useState<TCReportFormValues>(initialFilters);

  const { data, refetch, isLoading } = useQuery(
    ["getAdminTrainingReportsPage", page, filterData],
    () =>
      trainingCenterApi
        .getAdminTrainingCenterReports(reportType, page, filterData)
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterProps: filterData
        };
        setPagination(pagination);
      }
    }
  );

  const trainingCenters = useQuery("getTcs", () =>
    trainingCenterApi.getAllTrainingCenters().then((res) => res.data)
  );

  const downloadTCReportMutation = useMutation(
    (payload: { reportId: number }) => {
      const curReport = data?.results.find(
        (item) => item.id === payload.reportId
      );

      const reportsDownloads =
        curReport?.attachmentFiles.map(
          (file) =>
            new Promise((resolve, reject) =>
              trainingCenterApi
                .getAdminTCReportFile(payload.reportId, file.id)
                .then((res) =>
                  resolve(saveAs(new Blob([res.data]), file.fileName))
                )
                .catch((err: AxiosError) => reject(err.response?.data.message))
            )
        ) || [];

      return Promise.all(reportsDownloads);
    },
    {
      onError: (errorMessage: string) => {
        notification.error({
          message: t("error.errorOccured")
        });
      }
    }
  );

  const deleteTCReportMutation = useMutation(
    (payload: { reportId: number }) =>
      trainingCenterApi.deleteTCReport(payload.reportId),
    {
      onSuccess: () => {
        refetch();
        notification.success({
          message: t("reports.reportDeleted")
        });
      },
      onError: (err: AxiosError) => {
        if (err.response && err.response.status < 500) {
          notification.error({
            message: t("errorOccured")
          });
        }
      }
    }
  );

  const paginationSettings = {
    hideOnSinglePage: true,
    total: data?.totalItems,
    showTotal: (total: number) => `${t("totalCount")} ${total}`,
    current: data?.currentPage,
    pageSize: data?.pageSize,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={t("reports.tcReports")} />

      <AppPageHeader
        title={t("reports.trainingReport")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "tc" },
          { breadcrumbName: t("reports.trainingReport"), path: "requests" }
        ]}
      />
      <TCReportFilterForm
        initialFilter={initialFilters}
        trainingCenters={trainingCenters.data || []}
        isLoading={isLoading}
        onSubmit={(filters) => {
          setFilterData(filters);
        }}
      />

      <TrainingReportTable
        items={data?.results}
        rowLinkTo={(s) => `/admin/tc-report/${s.id}`}
        isLoading={isLoading}
        title={t("reports.trainingReport")}
        pagination={paginationSettings}
        renderActions={(tcReport: TrainingCenterReport) => (
          <TCReportListItemActions
            reportId={tcReport.id}
            reportName={tcReport.name}
            actions={["delete", "download", "viewDetails"]}
            onDownload={(reportId: number) =>
              downloadTCReportMutation.mutate({ reportId })
            }
            isDownloadLoading={
              downloadTCReportMutation.isLoading &&
              downloadTCReportMutation.variables?.reportId === tcReport.id
            }
            onDelete={(reportId: number) =>
              deleteTCReportMutation.mutate({ reportId })
            }
            isDeleteLoading={
              deleteTCReportMutation.isLoading &&
              deleteTCReportMutation.variables?.reportId === tcReport.id
            }
          />
        )}
      />
    </>
  );
};
