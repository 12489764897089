import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { EmployeesTable } from "@components/common/employees/EmployeesTable";
import { EmployeeFilterForm } from "@components/contractors/employees/EmployeeFilterForm";

import { employeeApi } from "@api/employeeApi";
import { disciplineApi } from "@api/disciplineApi";

import { useQueryParams } from "@hooks/useQueryParams";
import { UserContext } from "@contexts/UserContext";
import { usePagination } from "@hooks/usePagination";

interface Filters {
  iin?: string;
  firstName?: string;
  lastName?: string;
  specialityIds?: number[];
  competencyLevel?: number;
}

export const EmployeesPage = () => {
  const [t] = useTranslation();
  const queryParams = useQueryParams();

  const { page, setPage, setPagination } = usePagination();
  const [filters, setFilters] = useState<Filters>({});

  const { userEntityId: trainingCenterId } = useContext(UserContext);

  const employeePage = useQuery(
    ["getTCEmployeePage", page, trainingCenterId, filters],
    () =>
      employeeApi
        .getTCEmployeesPage({ page, trainingCenterId, filters })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterProps: filters
        };
        setPagination(pagination);
      }
    }
  );

  const initialFilters = useMemo(
    () => ({
      iin: queryParams.iin,
      firstName: queryParams.firstName,
      lastName: queryParams.lastName,
      competencyLevel: Number(queryParams.competencyLevel) || undefined,
      specialityIds: queryParams.specialityIds
        ? queryParams.specialityIds.map((idStr: string) => Number(idStr))
        : undefined
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const disciplineList = useQuery("allDisciplines", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const paginationSettings = {
    current: employeePage.data?.currentPage,
    pageSize: employeePage.data?.pageSize,
    total: employeePage.data?.totalItems,
    showTotal: (total: number) => `${t("totalCount")} ${total}`,
    hideOnSinglePage: true,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={t("employees.employees")} />

      <AppPageHeader
        title={t("employees.craftEmployeeList")}
        breadcrumbs={[
          { breadcrumbName: "Training Center", path: "/" },
          {
            breadcrumbName: t("employees.employeesPassport"),
            path: "employees"
          }
        ]}
      />

      <EmployeeFilterForm
        initialValues={initialFilters}
        disciplines={disciplineList.data}
        onSubmit={(values) => {
          setFilters(values);
          setPage(1);
        }}
      />

      <EmployeesTable
        employees={employeePage.data?.results}
        rowLinkTo={(s) => `/tc/employees/${s.id}`}
        isLoading={employeePage.isFetching}
        pagination={paginationSettings}
      />
    </>
  );
};
