import { useMemo } from "react";

import {
  ExcludeEmployeePayload,
  PreMatureEvaluationPayload
} from "@models/event";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

import { eventApi } from "./eventApi";

export const useEmployeePrematureEvaluation = ({
  onSuccess
}: {
  onSuccess: () => void;
}) => {
  const mutation = useValidatedMutation({
    mutationFunction: (payload: PreMatureEvaluationPayload) =>
      eventApi.preMatureEvaluate(payload),
    onSuccess
  });
  const errors = useMemo(
    () => transformValidationError(mutation.error),
    [mutation.error]
  );

  return { mutation, errors };
};

export const useEmployeePrematureExclude = ({
  onSuccess
}: {
  onSuccess: () => void;
}) => {
  const mutation = useValidatedMutation({
    mutationFunction: (payload: ExcludeEmployeePayload) =>
      eventApi.excludeEmployee(payload),
    onSuccess: onSuccess
  });
  const errors = useMemo(
    () => transformValidationError(mutation.error),
    [mutation.error]
  );

  return { mutation, errors };
};
