import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";

import { AxiosError } from "axios";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Tag,
  Button,
  PageHeader,
  Space,
  Table,
  Tooltip,
  Popconfirm,
  notification,
  Modal
} from "antd";
import PageMeta from "@components/PageMeta";
import { BreadcrumbRenderer } from "@components/BreadcrumbRenderer";
import ExportMembersButton from "@components/common/ExportMembersButton";
import EditTrainingCenterForm from "@components/admin/training-centers/EditTrainingCenterForm";
import RegisterTrainingCenterForm from "@components/admin/training-centers/RegisterTrainingCenterForm";

import {
  RegisterTrainingCenterPayload as RegTCPayload,
  EditTrainingCenterPayload as EditTCPayload,
  TrainingCenter
} from "@models/trainingCenter";

import { countryCityApi } from "@api/countryCityApi";
import { disciplineApi } from "@api/disciplineApi";
import { trainingCenterApi } from "@api/trainingCenterApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

const MAX_DISCIPLINES_IN_TABLE_COLUMN = 3;

const Column = Table.Column;

export const TrainingCenterListPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isRegisterModalShown, setIsRegisterModalShown] = useState(false);
  const [editableTrainingCenter, setEditableTrainingCenter] =
    useState<TrainingCenter | null>(null);

  const trainingCenterList = useQuery("getAllTrainingCenters", () =>
    trainingCenterApi.getAllTrainingCenters().then((res) => res.data)
  );

  const countries = useQuery("getAllCountries", () =>
    countryCityApi.getAllCountries().then((res) => res.data)
  );

  const disciplines = useQuery("getAllDisciplines", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const trainingCenterRegisterMutation = useValidatedMutation({
    mutationFunction: (payload: RegTCPayload) =>
      trainingCenterApi.registerTrainingCenter(payload),
    onSuccess: () => {
      trainingCenterList.refetch();
      notification.success({
        message: t("trainingCenters.registerSuccess")
      });
      setIsRegisterModalShown(false);
    }
  });
  const registerErrors = useMemo(
    () => transformValidationError(trainingCenterRegisterMutation.error),
    [trainingCenterRegisterMutation.error]
  );

  const trainingCenterEditMutation = useValidatedMutation({
    mutationFunction: (payload: EditTCPayload) =>
      trainingCenterApi.editTrainingCenter(payload),
    onSuccess: () => {
      trainingCenterList.refetch();
      notification.success({
        message: t("trainingCenters.editSuccess")
      });
      setEditableTrainingCenter(null);
    }
  });
  const editErrors = useMemo(
    () => transformValidationError(trainingCenterEditMutation.error),
    [trainingCenterEditMutation.error]
  );

  const trainingCenterDeleteMutation = useMutation(
    (id: number) => trainingCenterApi.deleteTrainingCenter(id),
    {
      onSuccess: () => {
        trainingCenterList.refetch();
        notification.success({
          message: t("trainingCenters.deleteSuccess")
        });
      },
      onError: (error: AxiosError<any>) => {
        notification.error({
          message:
            error.response?.data.message || t("trainingCenters.deleteFail")
        });
      }
    }
  );

  const pageTitle = t("trainingCenters.trainingCenters");

  return (
    <>
      <PageMeta title={pageTitle} />
      <PageHeader
        title={pageTitle}
        breadcrumb={{
          routes: [
            { breadcrumbName: "CT", path: "/" },
            {
              breadcrumbName: pageTitle,
              path: "training-centers"
            }
          ],
          itemRender: BreadcrumbRenderer
        }}
        extra={[
          <ExportMembersButton
            url="/api/admin/export/users/training-centers"
            fileName="export_tc_users.xlsx"
          />,
          <Button
            type="primary"
            key="2"
            onClick={() => setIsRegisterModalShown(true)}
          >
            {t("trainingCenters.registerTrainingCenter")}
          </Button>
        ]}
      />

      <Modal
        visible={isRegisterModalShown}
        destroyOnClose
        title={t("trainingCenters.registerTrainingCenter")}
        maskClosable={false}
        width={800}
        footer={null}
        onCancel={() => {
          setIsRegisterModalShown(false);
          trainingCenterRegisterMutation.reset();
        }}
      >
        <RegisterTrainingCenterForm
          countries={countries.data || []}
          disciplines={disciplines.data}
          isLoading={trainingCenterRegisterMutation.isLoading}
          errors={registerErrors}
          onCancel={() => {
            setIsRegisterModalShown(false);
            trainingCenterRegisterMutation.reset();
          }}
          onSubmit={(values) => trainingCenterRegisterMutation.mutate(values)}
        />
      </Modal>

      <Modal
        visible={!!editableTrainingCenter}
        destroyOnClose
        title={t("trainingCenters.registerTrainingCenter")}
        maskClosable={false}
        width={800}
        footer={null}
        onCancel={() => {
          setEditableTrainingCenter(null);
          trainingCenterEditMutation.reset();
        }}
      >
        <EditTrainingCenterForm
          trainingCenter={editableTrainingCenter}
          countries={countries.data || []}
          specialities={disciplines.data}
          isLoading={trainingCenterEditMutation.isLoading}
          errors={editErrors}
          onCancel={() => {
            setEditableTrainingCenter(null);
            trainingCenterEditMutation.reset();
          }}
          onSubmit={(values) => trainingCenterEditMutation.mutate(values)}
        />
      </Modal>

      <Table
        bordered
        locale={{ emptyText: t("noData") }}
        rowKey="id"
        loading={trainingCenterList.isLoading}
        pagination={false}
        dataSource={trainingCenterList.data}
        expandable={{
          expandIconColumnIndex: 6,
          rowExpandable: (tc: TrainingCenter) =>
            tc.specialities.length > MAX_DISCIPLINES_IN_TABLE_COLUMN,
          expandedRowRender: (tc: TrainingCenter) => (
            <Space wrap>
              {tc.specialities.map(({ id, nameEn, nameRu }) => (
                <Tag color={"geekblue"} key={id}>
                  {i18n.language === "en" ? nameEn : nameRu}
                </Tag>
              ))}
            </Space>
          )
        }}
      >
        <Column title="ID" dataIndex="id" />
        <Column
          title={t("trainingCenters.name")}
          render={(tc: TrainingCenter) => (
            <Link to={`/admin/training-center/${tc.id}`}>{tc.name}</Link>
          )}
        />
        <Column
          title={t("city")}
          render={(tc: TrainingCenter) =>
            i18n.language === "en" ? tc.city.nameEn : tc.city.nameRu
          }
        />
        <Column title={t("address")} dataIndex="address" />
        <Column
          title={t("eventTypes.eventTypes")}
          render={(tc: TrainingCenter) => (
            <Space wrap>
              {tc.eventTypes.map((type) => (
                <Tag color={"geekblue"} key={type}>
                  {t(`eventTypes.${type}`)}
                </Tag>
              ))}
            </Space>
          )}
        />
        <Column
          title={t("disciplines.disciplines")}
          width={420}
          render={(tc: TrainingCenter) => (
            <>
              {tc.specialities.length > MAX_DISCIPLINES_IN_TABLE_COLUMN ? (
                <Space wrap>
                  {tc.specialities
                    .slice(0, MAX_DISCIPLINES_IN_TABLE_COLUMN)
                    .map(({ id, nameEn, nameRu }) => (
                      <Tag color={"geekblue"} key={id}>
                        {i18n.language === "en" ? nameEn : nameRu}
                      </Tag>
                    ))}
                  <span>...</span>
                </Space>
              ) : (
                <Space wrap>
                  {tc.specialities.map(({ id, nameEn, nameRu }) => (
                    <Tag color={"geekblue"} key={id}>
                      {i18n.language === "en" ? nameEn : nameRu}
                    </Tag>
                  ))}
                </Space>
              )}
            </>
          )}
        />
        <Column
          title={t("actions")}
          render={(tc: TrainingCenter) => (
            <Space>
              <Tooltip title={t("edit")}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => setEditableTrainingCenter(tc)}
                />
              </Tooltip>
              <Popconfirm
                title={t("trainingCenters.deletePrompt", { name: tc.name })}
                cancelText={t("cancel")}
                okText={t("yes")}
                disabled={trainingCenterDeleteMutation.isLoading}
                onConfirm={() => {
                  trainingCenterDeleteMutation.mutate(tc.id);
                }}
              >
                <Button
                  disabled={trainingCenterDeleteMutation.isLoading}
                  loading={
                    trainingCenterDeleteMutation.variables === tc.id &&
                    trainingCenterDeleteMutation.isLoading
                  }
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </>
  );
};
