import { RegisterEmployeesToEventTemplate } from "@components/contractors/common/RegisterEmployeesToEventTemplate";

import { contractApi } from "@api/contractApi";
import { countryCityApi } from "@api/countryCityApi";
import { employeeApi } from "@api/employeeApi";
import { eventApi } from "@api/eventApi";
import { subcontractorApi } from "@api/subcontractorApi";

export const RegisterEmployeesPage: React.FC = () => {
  return (
    <RegisterEmployeesToEventTemplate
      getEventDataApi={eventApi.getEventData}
      getContractorSubcontractorsApi={subcontractorApi.getSubcontractorList}
      getContractorContractsApi={contractApi.getContractorContracts}
      getCountries={countryCityApi.getAllCountries}
      setSubcontractorToEventApi={eventApi.setSubcontractor}
      searchEmployeesByIinsApi={employeeApi.searchEmployeesByIINs}
      updateEmployeeApi={employeeApi.updateEmployee}
      applyToEventApi={eventApi.contractorApplyEvent}
    />
  );
};
