import { useTranslation } from "react-i18next";

import { ToolOutlined } from "@ant-design/icons";
import { EmployeeInfoCard } from "@components/common/employees/EmployeeInfoCard";

import { EmployeeDetails } from "@models/employee";

type PersonalInfo = Partial<EmployeeDetails>;

interface Props {
  editable?: boolean;
  editDisabled?: boolean;

  onEdit?: () => void;

  personalInfo?: PersonalInfo;
}

export const EmployeeWorkingInfoCard = (props: Props) => {
  const [t] = useTranslation();

  const pi = props.personalInfo;

  return (
    <EmployeeInfoCard
      title={
        <>
          <ToolOutlined /> {t("employees.workingInfo")}
        </>
      }
      fields={[
        {
          label: t("employees.workLocationArea"),
          value: pi?.workLocationArea,
          span: 12
        },
        { label: t("employees.position"), value: pi?.position, span: 12 },
        {
          label: t("employees.mouCategory"),
          value: t(`mouCategory.${pi?.mouCategory}`)
        },
        { label: t("employees.contractor"), value: pi?.contractor?.name },
        {
          label: t("employees.contract"),
          value: pi?.contract?.contractNumber,
          span: 24
        },
        {
          label: t("employees.lastNameCompanyRep"),
          value: pi?.lastNameCompanyRep,
          span: 12
        },
        {
          label: t("employees.firstNameCompanyRep"),
          value: pi?.firstNameCompanyRep,
          span: 12
        },
        {
          label: t("employees.phoneNumberCompanyRep"),
          value: pi?.phoneNumberCompanyRep,
          span: 12
        },
        {
          label: t("employees.lineSupervisorBadgeNumber"),
          value: pi?.lineSupervisorBadgeNumber,
          span: 12
        }
      ]}
      editable={props.editable}
      editDisabled={props.editDisabled}
      onEdit={props.onEdit}
    />
  );
};
