import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  MouCategoryReportTableData,
  MouCategoryReportType
} from "@models/report";
import { MouCategoryKey } from "@models/mouCategory";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
`;

const THeadTR = styled.tr`
  background: #65aeff;
  color: white;
`;

const TH = styled.th`
  border: solid 1px white;
  text-align: center;
  padding: 8px;
`;

const LastTR = styled.tr`
  background: #65aeff;
  color: white;
`;

const TBodyTRFirstTD = styled.td`
  border: solid 1px #dcdcdc;
  padding: 16px;
`;

const TBodyTRTD = styled.td`
  border: solid 1px #dcdcdc;
  text-align: center;
  padding: 16px;
`;

const LastTRTD = styled.td`
  border: solid 1px white;
  text-align: center;
  padding: 16px;
`;

const POSITION_MOUCATEGORY_KEYS = [
  MouCategoryKey.ENGINEERING_MANAGEMENT,
  MouCategoryKey.SUPERVISOR_AND_FOREMAN,
  MouCategoryKey.HEAVY_EQUIPMENT_OPERATOR,
  MouCategoryKey.CRAFT
];

const DISCIPLINE_MOUCATEGORY_KEYS = [
  MouCategoryKey.SUPERVISOR_AND_FOREMAN,
  MouCategoryKey.HEAVY_EQUIPMENT_OPERATOR,
  MouCategoryKey.CRAFT
];

interface Props {
  reportType: MouCategoryReportType;
  ptdData: MouCategoryReportTableData[];
  byPeriodData: MouCategoryReportTableData[];
}

export const MouCategoryTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [mouCategoryKeys, setMouCategoryKeys] = useState(
    props.reportType === MouCategoryReportType.DISCIPLINE
      ? DISCIPLINE_MOUCATEGORY_KEYS
      : POSITION_MOUCATEGORY_KEYS
  );

  useEffect(() => {
    switch (props.reportType) {
      case MouCategoryReportType.DISCIPLINE:
        setMouCategoryKeys(DISCIPLINE_MOUCATEGORY_KEYS);
        break;
      case MouCategoryReportType.POSITION:
        setMouCategoryKeys(POSITION_MOUCATEGORY_KEYS);
        break;
      default:
        setMouCategoryKeys(DISCIPLINE_MOUCATEGORY_KEYS);
    }
  }, [props.reportType]);

  const tableData = mouCategoryKeys.map((key) => ({
    key,
    label: t(key),
    description: t(key),
    period: props.byPeriodData.find((p) => p.mouCategory === key),
    ptd: props.ptdData.find((p) => p.mouCategory === key)
  }));

  const tableDataTotals = {
    period: props.byPeriodData.reduce(
      (acc, p) => {
        acc.assessment += p.assessment;
        acc.training += p.training;
        acc.prescreening += p.prescreening;
        return acc;
      },
      {
        assessment: 0,
        training: 0,
        prescreening: 0
      }
    ),
    ptd: props.ptdData.reduce(
      (acc, p) => {
        acc.assessment += p.assessment;
        acc.training += p.training;
        acc.prescreening += p.prescreening;
        return acc;
      },
      {
        assessment: 0,
        training: 0,
        prescreening: 0
      }
    )
  };

  return (
    <>
      <Table>
        <thead>
          <THeadTR>
            <TH rowSpan={2}> MoU Category </TH>
            <TH colSpan={2}> Assessment </TH>
            <TH colSpan={2}> Training </TH>
            <TH colSpan={2}> Pre-screening </TH>
          </THeadTR>
          <THeadTR>
            <TH>Period</TH>
            <TH>PTD (all numbers)</TH>
            <TH>Period</TH>
            <TH>PTD (all numbers)</TH>
            <TH>Period</TH>
            <TH>PTD (all numbers)</TH>
          </THeadTR>
        </thead>
        <tbody>
          {tableData.map((t) => (
            <tr>
              <TBodyTRFirstTD>
                <span style={{ fontWeight: "bold" }}>{t.label}</span>
                <br />
                <span style={{ fontSize: "14px", color: "#b8b8b8" }}>
                  {t.description}
                </span>
              </TBodyTRFirstTD>
              <TBodyTRTD>{t.period?.assessment || 0}</TBodyTRTD>
              <TBodyTRTD>{t.ptd?.assessment || 0}</TBodyTRTD>
              <TBodyTRTD>{t.period?.training || 0}</TBodyTRTD>
              <TBodyTRTD>{t.ptd?.training || 0}</TBodyTRTD>
              <TBodyTRTD>{t.period?.prescreening || 0}</TBodyTRTD>
              <TBodyTRTD>{t.ptd?.prescreening || 0}</TBodyTRTD>
            </tr>
          ))}
          <LastTR>
            <LastTRTD>{t("total")}</LastTRTD>
            <LastTRTD>{tableDataTotals.period?.assessment}</LastTRTD>
            <LastTRTD>{tableDataTotals.ptd?.assessment}</LastTRTD>
            <LastTRTD>{tableDataTotals.period?.training}</LastTRTD>
            <LastTRTD>{tableDataTotals.ptd?.training}</LastTRTD>
            <LastTRTD>{tableDataTotals.period?.prescreening}</LastTRTD>
            <LastTRTD>{tableDataTotals.ptd?.prescreening}</LastTRTD>
          </LastTR>
        </tbody>
      </Table>
    </>
  );
};
