import { useTranslation } from "react-i18next";

import moment from "moment";

import { Button, Col, Descriptions, Modal, Row, Space, Typography } from "antd";

import { EventFeedback } from "@models/event";

interface Props {
  visible: boolean;
  feedbacks?: EventFeedback[];
  onClose: () => void;
}

const ViewEventFeedbackModal: React.FC<Props> = (props) => {
  const [t] = useTranslation();

  return (
    <Modal
      visible={props.visible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      title={t("events.feedback")}
      footer={null}
      width={600}
      onCancel={() => {
        props.onClose();
      }}
    >
      <Space direction="vertical" size="large">
        {props.feedbacks?.map((feedback) => (
          <Space direction="vertical" key={feedback.id}>
            <Descriptions
              bordered
              size="small"
              column={{ md: 4, sm: 2, xs: 1 }}
            >
              <Descriptions.Item
                label={
                  feedback.trainingCenterId
                    ? t("events.trainingCenter")
                    : t("events.contractor")
                }
              >
                {feedback.name}
              </Descriptions.Item>
              <Descriptions.Item label={t("events.feedbackAuthor")}>
                {feedback.userName}
              </Descriptions.Item>
              <Descriptions.Item label={t("events.feedbackDate")}>
                {moment(feedback.date).format("DD.MM.YYYY HH:mm")}
              </Descriptions.Item>
            </Descriptions>

            <Typography.Text>{feedback.feedback}</Typography.Text>
          </Space>
        ))}

        <Row justify="end">
          <Col>
            <Button onClick={props.onClose} danger>
              {t("ok")}
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};

export default ViewEventFeedbackModal;
