import { Discipline } from "@models/discipline";

export enum MaritalStatusOptions {
  UNDEFINED = 0,
  MARRIED = 1,
  SINGLE = 2,
  WIDOW = 3,
  DIVORCED = 4
}

export enum KnownLanguagesOptions {
  UNDEFINED = 0,
  RUSSIAN = 1,
  ENGLISH = 2,
  KAZAKH = 3,
  CHINESE = 4,
  FRENCH = 5,
  GERMAN = 6,
  ITALIAN = 7,
  TURKISH = 8
}

export interface CraftProfile {
  id: number;
  profileName: string;
  discipline: string;
  isEmployed: boolean;
}

export interface CraftProfilePaginated<T> {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  results: T[];
}

export interface CraftProfilesListFilter {
  trainingCenterIds?: number[];
  csrContractorIds?: number[];
  specialityIds?: number;
  isEmployed?: boolean | null;
  csrPhases?: number[];
  iin?: number;
}

export interface CraftProfilesFilter {
  specialityIds?: number;
  isEmployed?: boolean | null;
  csrPhases?: number[];
  iin?: number;
}

export interface CraftProfilesReportFilterForm {
  hiringContractorIds?: number[];
  trainingCenterIds?: number[];
  csrContractorIds?: number[];
  specialityIds?: number[];
  isEmployed?: boolean | null | string;
  csrPhases?: number[];
}

export interface CraftProfileCard {
  fullName: string;
  iin: string;
  isEmployed?: boolean;
  emailAddress: string;
  phoneNumber: string;
  address: string;
  birthDate: string;
  maritalStatus: MaritalStatusOptions;
  discipline: Discipline;
  csrContractor: string;
  education: string;
  additionalEducation: string;
  csrPhase: number;
  workExperience: string;
  languages: number[];
  personalQualities: string;
  additionalInformation: string;
  profilePhoto: string;
  hiredById?: number | null;
  hiredByName?: string;
}

export interface CraftProfileCardWithId extends CraftProfileCard {
  id: string;
}

export interface CreateProfilePayloadAdmin {
  contractorEmployeeId?: number;
  payload: CraftProfilesFormSettingsAdmin;
}

export interface EditProfilePayloadAdmin {
  profileId: string;
  payload: CraftProfilesFormSettingsAdmin;
}

export interface CraftProfilesFormSettings {
  disciplineId: number;
  homeAddress: string;
  maritalStatus: number;
  education: string;
  additionalEducation: string;
  csrPhase: number;
  workExperience: string;
  languages: number[];
  personalQualities: string;
  additionalInformation: string;
  profilePhoto: string | null;
}

export interface CraftProfilesFormSettingsAdmin
  extends CraftProfilesFormSettings {
  isEmployed?: boolean | null;
  hiringContractorId?: number | string | null;
  otherCompany?: string | null;
}
