import picture1 from "@assets/images/user-guide/3.1.png";
import picture2 from "@assets/images/user-guide/3.2.png";
import picture3 from "@assets/images/user-guide/3.3.png";
import picture4 from "@assets/images/user-guide/3.4.png";
import picture5 from "@assets/images/user-guide/3.5.png";
import picture6 from "@assets/images/user-guide/3.6.png";

export const search = [
  {
    title: "Search page:",
    html: `<p dir="ltr">
    In order to apply to the event press Search tab on Panel menu. You will be
    redirected to Event Search page with the following information:
</p>
<p dir="ltr">
    Picture 3.1
</p>
<p dir="ltr">
    <img
        src=${picture1}
        width="624"
        height="297"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Search by Start date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Disciplines
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by End date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by minimum number of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Training centers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Type
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Cities
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Apply button
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Use filters/search button to find necessary event and press on the Apply
    button.
</p>
`
  },
  {
    title: "What’s on Register employees page:",
    html: `<p dir="ltr">
    After pressing the Apply button you will be redirected to Register
    employees page with the following information:
</p>
<p dir="ltr">
    Picture 3.2
</p>
<p dir="ltr">
    <img
        src=${picture2}
        width="624"
        height="295"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Set subcontractor button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            List of subcontractors
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Cancel application button
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Picture 3.3
</p>
<p dir="ltr">
    <img
        src=${picture3}
        width="624"
        height="297"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            IIN input field, you can copy-paste IINs from your file, the system
            will find a list of all available craft-workers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Clear selected button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Go to step 2 button, navigates you to Data check page
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Check box of validity. You can tick or untick the required
            craft-worker. System will show reason why craft-worker is not
            eligible
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to apply craft-workers to the event?",
    html: `<p dir="ltr">
    In order to apply craft-workers to the event, please follow the steps:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            On event search page press Apply button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            On register employees page fill out Search by IIN field
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Search button and Go to step 2 button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            On Data check page press Apply button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 3.4
</p>
<p dir="ltr">
    <img
        src=${picture4}
        width="624"
        height="241"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Back button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Apply button
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to set subcontractors to the event?",
    html: `<p dir="ltr">
    In order to set subcontractors to the event, please follow the steps:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            On event search page press Apply button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            On register employees page press Set subcontractor button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 3.5
</p>
<p dir="ltr">
    <img
        src=${picture5}
        width="624"
        height="228"
    />
</p>
<ol start="3">
    <li dir="ltr">
        <p dir="ltr">
            System opens modal window with subcontractors
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 3.6
</p>
<p dir="ltr">
    <img
        src=${picture6}
        width="624"
        height="237"
    />
</p>
<p>
    Set subcontractors and press Save button
</p>
`
  }
];
