import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

import qs from "qs";
import Modal from "antd/lib/modal/Modal";

import { CommissioningReport } from "@components/common/reports/CommissioningReport";
import { PageMeta } from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { CommissioningReportFilterForm } from "@components/common/reports/CommissioningReportFilterForm";

import { EventType } from "@models/event";

import { disciplineApi } from "@api/disciplineApi";
import { contractorsApi } from "@api/contractorsApi";
import { trainingCenterApi } from "@api/trainingCenterApi";
import { contractApi } from "@api/contractApi";
import { reportsApi } from "@api/reportsApi";

import { useQueryParams } from "@hooks/useQueryParams";
import { useDefaultQuery } from "@hooks/useDefaultQuery";
import { UserContext } from "@contexts/UserContext";

export const CommissioningReportPage: React.FC = () => {
  const { userEntityId: trainingCenterId } = useContext(UserContext);

  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const history = useHistory();

  const { type: initialEventType, ...initialFilterData } = queryParams;

  const [activeEventType, setActiveEventType] = useState<number>(
    Number(initialEventType) || EventType.TRAINING
  );
  const [filterData, setFilterData] = useState<{}>(initialFilterData);

  const [isFilterModalShown, setIsFilterModalShown] = useState(false);

  const pageTitle = t("reports.commissioningPerformance");

  const { data, isLoading } = useQuery(
    ["getTcCommissioningReport", activeEventType, filterData],
    () =>
      reportsApi
        .getTcCommissioningReport(trainingCenterId, activeEventType, filterData)
        .then((res) => res.data),
    {
      onSuccess() {
        history.push({
          search: qs.stringify(
            { ...filterData, type: activeEventType },
            { arrayFormat: "indices" }
          )
        });
        setIsFilterModalShown(false);
      }
    }
  );

  const disciplines = useDefaultQuery("getAllDisciplines", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const trainingCenters = useDefaultQuery("getTcs", () =>
    trainingCenterApi.getAllTrainingCenters().then((res) => res.data)
  );

  const contractors = useDefaultQuery("getContractors", () =>
    contractorsApi.getContractors().then((res) => res.data)
  );

  const projects = useDefaultQuery("getProjects", () =>
    contractApi.getProjects().then((res) => res.data)
  );

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: "/admin/reports/commissioning"
          }
        ]}
      />
      <Modal
        visible={isFilterModalShown}
        destroyOnClose
        title={t("Filter")}
        maskClosable={false}
        width={600}
        footer={null}
        onCancel={() => {
          setIsFilterModalShown(false);
        }}
      >
        <CommissioningReportFilterForm
          initialFilter={initialFilterData}
          eventType={activeEventType}
          disciplines={disciplines.data || []}
          trainingCenters={trainingCenters.data || []}
          contractors={contractors.data || []}
          projects={projects.data || []}
          isLoading={isLoading}
          onSubmit={(filters) => {
            setFilterData(filters);
          }}
        />
      </Modal>
      <CommissioningReport
        report={data}
        isLoading={isLoading}
        onFilterClick={() => setIsFilterModalShown(true)}
        activeEventType={activeEventType}
        onEventTypeChange={(eventType: number) => {
          setFilterData({});
          setActiveEventType(eventType);
        }}
      />
    </>
  );
};
