import React, { useEffect, useContext, useState } from "react";

import { Spin } from "antd";
import DefaultLayout from "@layouts/DefaultLayout";

import { UserContext } from "@contexts/UserContext";

import GuestRoutes from "./GuestRoutes";
import AdminRoutes from "./AdminRoutes";
import ContractorRoutes from "./ContractorRoutes";
import TrainingCenterRoutes from "./TrainingCenterRoutes";
import SubcontractorRoutes from "./SubcontractorRoutes";

const AppRoutes: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user, userRole, loadUser } = useContext(UserContext);

  const getRoutes = () => {
    switch (userRole) {
      case "admin":
        return <AdminRoutes />;
      case "contractor":
        return <ContractorRoutes />;
      case "subcontractor":
        return <SubcontractorRoutes />;
      case "trainingcenter":
        return <TrainingCenterRoutes />;

      default:
        return <GuestRoutes />;
    }
  };

  useEffect(
    () =>
      (function handleGetUserData() {
        if (!user) {
          loadUser().finally(() => setIsLoading(false));
        }
      })(),
    []
  );

  return (
    <div>
      {isLoading && <Spin />}
      {!isLoading && getRoutes()}
    </div>
  );
};

export default AppRoutes;
