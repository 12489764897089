import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateFunction
} from "react-query";

import { RcFile } from "antd/lib/upload";

import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  notification,
  Radio,
  Row,
  Spin,
  Upload
} from "antd";

import {
  MigrationType,
  MigrationRadioType,
  MigrationTypes
} from "@models/migrations";

import { doesFileFitsSize } from "@utils/doesFileFitsSize";

interface Props {
  migrationRadioTypes: MigrationRadioType[];
  handleCloseModal: () => void;
  getMigrationTemplate: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<void, unknown>>;
  uploadMigrationTable: UseMutateFunction<unknown, unknown, RcFile>;
  selectedMigrationType: MigrationType;
  handleMigrationType: (type: MigrationType) => void;
  isLoading?: boolean;
}

const UploadBatchMigrationForm: React.FC<Props> = ({
  migrationRadioTypes,
  getMigrationTemplate,
  uploadMigrationTable,
  selectedMigrationType,
  handleMigrationType,
  handleCloseModal,
  isLoading
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const [isComissioning, setIsComissioning] = useState<boolean>(false);
  const [applicableMigrationTypes, setApllicableMigrationTypes] = useState<
    MigrationRadioType[]
  >([]);

  useEffect(() => {
    if (isComissioning) {
      const filteredMigrations = migrationRadioTypes.filter(
        (m) =>
          m.value === MigrationTypes.COMMISSIONING_TRAINING ||
          m.value === MigrationTypes.COMMISSIONING_ASSESSMENT
      );
      setApllicableMigrationTypes(filteredMigrations);
    } else {
      const filteredMigrations = migrationRadioTypes.filter(
        (m) =>
          m.value !== MigrationTypes.COMMISSIONING_TRAINING &&
          m.value !== MigrationTypes.COMMISSIONING_ASSESSMENT
      );
      setApllicableMigrationTypes(filteredMigrations);
    }
  }, [isComissioning, migrationRadioTypes]);

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ marginBottom: "24px" }}>
          <Checkbox
            onChange={() => {
              form.setFieldsValue({ migrationTypes: [] });
              setIsComissioning(!isComissioning);
            }}
          >
            {t("events.commissioning")}
          </Checkbox>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item name="migrationTypes">
            <Radio.Group
              style={{ width: "100%", maxWidth: "900px", marginBottom: "24px" }}
            >
              <Row gutter={[16, 16]}>
                {applicableMigrationTypes.map((item) => (
                  <Col span={12} key={item.value}>
                    <Radio
                      value={item.value}
                      onClick={() =>
                        handleMigrationType({
                          value: item.value,
                          route: item.route
                        })
                      }
                    >
                      {item.label}
                    </Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {selectedMigrationType.value !== 0 && (
        <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
          <Col md={24}>
            <Spin spinning={isLoading}>
              <Upload.Dragger
                accept=".xlsx,.xls"
                className="py-30"
                showUploadList={false}
                beforeUpload={(file) => {
                  if (doesFileFitsSize(file)) {
                    uploadMigrationTable(file);
                    return false;
                  }
                  notification.error({
                    message: t("fileSizeLimit", { size: 10 })
                  });
                  return false;
                }}
                disabled={selectedMigrationType.value === 0}
              >
                <InboxOutlined />
                <p>{t("employees.chooseOrDragFile")}</p>
              </Upload.Dragger>
            </Spin>
          </Col>
        </Row>
      )}
      <Row justify="space-between">
        <Col>
          <Button onClick={handleCloseModal}>{t("cancel")}</Button>
        </Col>
        <Col>
          <Button
            type="dashed"
            onClick={() => getMigrationTemplate()}
            loading={isLoading}
            disabled={selectedMigrationType.value === 0}
          >
            {t("employees.downloadTemplate")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UploadBatchMigrationForm;
