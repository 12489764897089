export const enMigrations = {
  migration: "Migration",
  migration_other: "Migrations",
  newMigration: "New Migration",

  table: {
    numberOfCraftWorkers: "Number of participants",
    uploadedBy: "Uploaded by"
  },

  form: {
    createMigration: "Create a new Migration",
    migrate: "Migrate",

    trainingCenter: "Training Center",
    eventName: "Event Name",
    numberOfSeats: "Number of seats",
    minimumSeats: "Minimum seats",
    academicHours: "Academic hours",
    discipline: "Discipline",
    language: "Language",
    csrPhase: "CSR Phase",
    description: "Description",
    city: "City",
    location: "Location",
    start: "Date from",
    end: "Date to",
    standard: "Standard",
    hoursPerDay: "Hours per day",
    instructorId: "Instructor",

    employeeId: "Contractor",

    firstName: "First Name",
    lastName: "Last Name",
    contractor: "Contractor",
    subcontractor: "Subcontractor",
    iin: "IIN",
    practicalGrade: "Practical Grade",
    practicalStatus: "Practical Status",
    theoreticalGrade: "Theoretical Grade",
    theoreticalStatus: "Theoretical Status",
    isPassed: "Overall Grade",
    failReason: "Fail Reason",
    failReasonType: "Fail Reason Type",
    other: "Other",
    recommendation: "Recommendation",
    isCompleted: "Completion Status",

    gradePassed: "Passed",
    gradeFailed: "Failed",
    iinTooltip:
      "IIN-s of the following users were not found in the system or incorrect, they will not be added to this migration",

    competencyLevel: "Competency level",
    competencyLevels: {
      null: "–",

      0: "–",
      1: "Failed",
      2: "Passed",
      3: "Basic",
      4: "Intermediate",
      5: "Advanced",
      6: "Competent",
      7: "Proficient",
      8: "Helper",
      20: "Trainee Technician",
      22: "Junior Technician",
      24: "Technician",
      26: "Senior Technician"
    },

    completionStatuses: {
      1: "Incompleted",
      2: "Completed"
    }
  },

  registeredUsers: "Registered users",
  notRegisteredUsers: "Not registered users",
  migrationCreateSuccess: "Migration was successfully created",
  migrationDeleteSuccess: "Migration was successfully deleted",
  deleteConfirmation: "Are you sure you want to delete migration {{name}}?"
};
