import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Space, Typography } from "antd";
import { PermissionCheckboxGroup } from "@components/common/PermissionCheckboxGroup";

import { PERMISSIONS } from "@models/permissions";

import {
  CONTRACTOR_PERMISSION_DEFAULT_LIST,
  CONTRACTOR_PERMISSION_OPTION_LIST
} from "@constants/contractorPermissions";

interface FormValues {
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  phoneNumber: string;
  permissions: number[];
}

interface Props {
  errors?: { [key: string]: string };

  permissions?: object[];

  isLoading?: boolean;

  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

export const NewContractorMemberForm = (props: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      position: "",
      email: "",
      phoneNumber: "",
      permissions: [
        PERMISSIONS.REPORT_VIEW,
        PERMISSIONS.MEMBER_VIEW,

        PERMISSIONS.EVENT_VIEW,
        PERMISSIONS.EVENT_DETAILS_DOWNLOAD,

        PERMISSIONS.TRAINING_CENTER_VIEW,

        PERMISSIONS.CONTRACT_VIEW,

        PERMISSIONS.CERTIFICATE_VIEW,
        PERMISSIONS.CERTIFICATE_DOWNLOAD,
        PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW
      ]
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit({
          ...values,
          permissions: Array.from(
            new Set(
              values.permissions.concat(CONTRACTOR_PERMISSION_DEFAULT_LIST)
            )
          )
        });
      }
    }
  });

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label={t("members.firstName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.firstName && "error"}
            help={formik.errors.firstName}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("members.lastName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.lastName && "error"}
            help={formik.errors.lastName}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("members.position")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.position && "error"}
            help={formik.errors.position}
          >
            <Input
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("members.email")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.email && "error"}
            help={formik.errors.email}
          >
            <Input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("members.phoneNumber")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.phoneNumber && "error"}
            help={formik.errors.phoneNumber}
          >
            <Input
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <br />

          <Typography.Title level={5}>
            {t("members.accessLevel")}
          </Typography.Title>

          <PermissionCheckboxGroup
            options={CONTRACTOR_PERMISSION_OPTION_LIST}
            value={formik.values.permissions}
            onChange={(v) => formik.setFieldValue("permissions", v)}
          />
        </Col>

        <Col md={24}>
          <br />
          <br />
          <br />

          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("members.invite")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
