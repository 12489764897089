import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { Tabs } from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { CraftProfilesPaginator } from "@components/common/craftProfiles/CraftProfilesPaginator";
import { CraftProfilesFilterForm } from "@components/common/craftProfiles/CraftProfilesFilterForm";
import AddCraftProfileModal from "@components/common/craftProfiles/modals/AddCraftProfileModal";

import { CraftProfilesListFilter } from "@models/craftProfiles";

import { craftProfilesApi } from "@api/craftProfilesApi";
import { contractorsApi } from "@api/contractorsApi";
import { trainingCenterApi } from "@api/trainingCenterApi";
import { disciplineApi } from "@api/disciplineApi";

import { usePagination } from "@hooks/usePagination";
import { useQueryParams } from "@hooks/useQueryParams";
import { PAGE_SIZE } from "@constants/craftProfiles";
import { UserContext } from "@contexts/UserContext";

interface Props {
  baseLink: string;
  baseLinkPaginator: string;
  hiddenFilterFields?: (keyof CraftProfilesListFilter)[];
  areFormFieldsHidden?: boolean;
  ableToCreateProfile?: boolean;
}

const { TabPane } = Tabs;

const CraftProfilesListTemplate: React.FC<Props> = ({
  baseLink,
  baseLinkPaginator,
  hiddenFilterFields,
  areFormFieldsHidden = false,
  ableToCreateProfile = false
}) => {
  const [t] = useTranslation();
  const qp = useQueryParams();
  const initialFilterData = qp;
  const pageTitle = t("craftProfiles.profiles");
  const { userContractor, userRole } = useContext(UserContext);

  const isContractor = useMemo(() => {
    return ["contractor", "subcontractor"].includes(userRole);
  }, [userRole]);

  const [isAddCraftOpen, setAddCraftOpen] = useState<boolean>(false);
  const [filters, setFilters] =
    useState<CraftProfilesListFilter>(initialFilterData);
  const [selectedTab, setSelectedTab] = useState<string>("allProfiles");

  const { page, setPage, setPagination } = usePagination();

  const craftProfiles = useQuery(
    ["craftProfiles", page, filters],
    () =>
      craftProfilesApi
        .getAllCraftProfiles({
          ...filters,
          pageSize: PAGE_SIZE,
          page
        })
        .then((res) => res.data),
    {
      enabled: !isContractor,
      onSuccess(res) {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterParams: filters
        };
        setPagination(pagination);
      }
    }
  );

  useEffect(() => {
    if (userContractor) {
      craftProfiles.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContractor, page, filters]);

  const getQueryEnabledStatus = useCallback(
    (key: keyof CraftProfilesListFilter) =>
      hiddenFilterFields?.includes(key) ? false : true,
    [hiddenFilterFields]
  );

  const specialityList = useQuery(
    "getTcSpecialityList",
    () => disciplineApi.getAllDisciplines().then((res) => res.data),
    {
      enabled: getQueryEnabledStatus("specialityIds")
    }
  );

  const contractorList = useQuery(
    "getContractors",
    () => contractorsApi.getContractors().then((res) => res.data),
    {
      enabled: getQueryEnabledStatus("csrContractorIds")
    }
  );

  const tcList = useQuery(
    "getTcs",
    () => trainingCenterApi.getTcDictionary().then((res) => res.data),
    {
      enabled: getQueryEnabledStatus("trainingCenterIds")
    }
  );

  const handleTabChange = (key: string) => {
    const filtersCopy = { ...filters };
    setSelectedTab(key);

    if (key === "hiredProfiles") {
      setFilters({
        ...filtersCopy,
        isEmployed: true
      });
      contractorList.refetch();
    } else {
      delete filtersCopy.isEmployed;
      setFilters({
        ...filtersCopy
      });
      contractorList.refetch();
    }
  };

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: baseLink
          }
        ]}
        extra={
          ableToCreateProfile && (
            <AddCraftProfileModal
              isModalOpen={isAddCraftOpen}
              handleOpenModal={() => setAddCraftOpen(true)}
              handleCloseModal={() => setAddCraftOpen(false)}
              getCraftProfiles={craftProfiles}
              areFieldsHidden={areFormFieldsHidden}
            />
          )
        }
      />
      <CraftProfilesFilterForm
        initialFilter={initialFilterData}
        hiddenFields={hiddenFilterFields}
        contractors={contractorList.data}
        disciplines={specialityList.data}
        trainingCenters={tcList.data}
        isLoading={craftProfiles.isLoading}
        onSubmit={(v) => {
          setFilters(v);
          setPage(1);
          setSelectedTab("allProfiles");
        }}
      />

      {isContractor && (
        <Tabs
          defaultActiveKey="allProfiles"
          activeKey={selectedTab}
          onChange={(key) => handleTabChange(key)}
        >
          <TabPane tab={t("craftProfiles.allProfiles")} key="allProfiles" />
          <TabPane tab={t("craftProfiles.hiredProfiles")} key="hiredProfiles" />
        </Tabs>
      )}

      <CraftProfilesPaginator
        craftProfilesList={craftProfiles.data}
        baseLink={baseLinkPaginator}
        onChange={(nextPage) => {
          setPage(nextPage);
        }}
      />
    </>
  );
};

export default CraftProfilesListTemplate;
