import React, { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { UploadFile } from "antd/lib/upload/interface";
import saveAs from "file-saver";

import { Button, Modal, notification, Space } from "antd";
import {
  EditOutlined,
  UserAddOutlined,
  DownloadOutlined,
  EyeOutlined
} from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { NewTrainingReportForm } from "@components/common/tc-reports/NewTrainingReportForm";
import { EditTrainingReportForm } from "@components/common/tc-reports/EditTrainingReportForm";
import { TrainingReportTable } from "@components/common/tc-reports/TrainingReportTable";

import { TrainingCenterReport, TcReportTypes } from "@models/trainingCenter";

import { trainingCenterApi } from "@api/trainingCenterApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";
import { useQueryParams } from "@hooks/useQueryParams";
import { UserContext } from "@contexts/UserContext";

interface Filters {
  iin?: string;
  firstName?: string;
  lastName?: string;
  specialityIds?: number[];
  competencyLevel?: number;
}

export const ProfileReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const { userEntityId } = useContext(UserContext);
  const TRAINING_CENTER_ID = userEntityId;
  const reportType = TcReportTypes.PROFILE_REPORT;
  const [editedReport, setEditedReport] = useState<TrainingCenterReport>();
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const queryParams = useQueryParams();
  const [page] = useState<number>(Number(queryParams.page) || 1);
  const [filters] = useState<Filters>({});

  const { data, refetch, isLoading } = useQuery(
    ["getProfileReportsPage", page, filters],
    () =>
      trainingCenterApi
        .getTrainingCenterReports(TRAINING_CENTER_ID, reportType, page)
        .then((res) => res.data)
  );

  const createTrainingReport = useValidatedMutation({
    mutationFunction: (values: any) =>
      trainingCenterApi.createTrainingCenterReports({
        trainingCenterId: TRAINING_CENTER_ID,
        payload: {
          ...values,
          files: values.files?.map?.((f: UploadFile) =>
            f.originFileObj ? f.originFileObj : f
          )
        }
      }),
    onSuccess() {
      notification.success({
        message: t("reports.saveSuccess")
      });
      refetch();
      setIsModalShown(false);
    }
  });

  const updateTrainingReport = useValidatedMutation({
    mutationFunction: (values: any) =>
      trainingCenterApi.updateTrainingCenterReport({
        trainingCenterId: TRAINING_CENTER_ID,
        tcReportId: editedReport?.id,
        payload: {
          ...values,
          files: values.files?.map?.((f: UploadFile) =>
            f.originFileObj ? f.originFileObj : f
          )
        }
      }),
    onSuccess() {
      notification.success({
        message: t("reports.saveSuccess")
      });
      refetch();
      setIsEditModalShown(false);
    }
  });

  const createErrors = useMemo(
    () => transformValidationError(createTrainingReport.error),
    [createTrainingReport.error]
  );

  const editErrors = useMemo(
    () => transformValidationError(updateTrainingReport.error),
    [updateTrainingReport.error]
  );

  const handleModal = (item?: TrainingCenterReport) => {
    setEditedReport(item ? item : undefined);
    setIsModalShown(!isModalShown);
  };

  const handleDownload = (id: number) => {
    const curReport = data?.find((item) => item.id === id);
    // eslint-disable-next-line array-callback-return
    curReport?.attachmentFiles.map((file) => {
      trainingCenterApi
        .getTCReportFile(TRAINING_CENTER_ID, id, file.id)
        .then((res) => {
          return saveAs(new Blob([res]), file.fileName);
        });
    });
  };

  return (
    <>
      <PageMeta title={t("reports.tcReports")} />

      <AppPageHeader
        title={t("reports.profile")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "tc" },
          { breadcrumbName: t("reports.profile"), path: "requests" }
        ]}
        extra={[
          <Button
            key="2"
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => {
              handleModal();
            }}
          >
            {t("reports.createReport")}
          </Button>
        ]}
      />
      <Modal
        visible={isModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("reports.profile")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsModalShown(!isModalShown);
          createTrainingReport.reset();
        }}
      >
        <NewTrainingReportForm
          isLoading={createTrainingReport.isLoading}
          errors={createErrors}
          report={editedReport}
          reportType={reportType}
          onCancel={() => {
            setIsModalShown(!isModalShown);
            createTrainingReport.reset();
          }}
          onSubmit={(values) => {
            createTrainingReport.mutate(values);
          }}
        />
      </Modal>
      <Modal
        visible={isEditModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("reports.profile")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsEditModalShown(!isEditModalShown);
          updateTrainingReport.reset();
        }}
      >
        <EditTrainingReportForm
          isLoading={updateTrainingReport.isLoading}
          errors={editErrors}
          report={editedReport}
          reportType={reportType}
          reportId={editedReport?.id || 0}
          tcId={TRAINING_CENTER_ID}
          onCancel={() => {
            setIsEditModalShown(!isEditModalShown);
            updateTrainingReport.reset();
          }}
          onSubmit={(values) => {
            updateTrainingReport.mutate(values);
          }}
        />
      </Modal>

      <TrainingReportTable
        items={data}
        isLoading={isLoading}
        title={t("reports.profile")}
        rowLinkTo={(s) => `/tc/tc-report/${s.id}`}
        renderActions={(am: TrainingCenterReport) => (
          <Space size="middle">
            <Button
              title={t("edit")}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setEditedReport(am);
                setIsEditModalShown(true);
              }}
            />
            <Button
              title={t("viewDetails")}
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              href={`/tc/tc-report/${am.id}`}
              loading={isLoading}
            />
            <Button
              title={t("download")}
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(am.id)}
              loading={isLoading}
            />
          </Space>
        )}
      />
    </>
  );
};
