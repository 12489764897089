import CraftProfilesListTemplate from "@components/common/craftProfiles/CraftProfilesListTemplate";

const BASE_LINK = "/tc/craft-profiles/all";
const BASE_LINK_PAGINATOR = "/tc/craft-profile";

export const CraftProfilesListPage = () => {
  return (
    <CraftProfilesListTemplate
      hiddenFilterFields={["trainingCenterIds"]}
      baseLink={BASE_LINK}
      baseLinkPaginator={BASE_LINK_PAGINATOR}
      areFormFieldsHidden
      ableToCreateProfile
    />
  );
};
