export const ruContract = {
  contracts: "Контракты",
  contractNumber: "Номер контракта",
  kcCatAndArea: "KC Category and Area",
  project: "Проект",
  capacity: "Вместимость",
  addContract: "Добавить контракт",
  contractAdded: "Контракт успешно добавлен",
  contractEdited: "Данные контракта {{contractNumber}} успешно сохранены",
  contractDeleted: "Контрак {{contractNumber}} успешно удален",
  confirmDelete: "Вы уверены, что хотите удалить контракт: {{contractNumber}}?",
  editContract: "Редактирование контракта"
};
