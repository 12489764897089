import React from "react";
import { HelmetProvider } from "react-helmet-async";

import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

import LoginPage from "@pages/LoginPage";

import AppRoutes from "./routes/AppRoutes";
import PageMeta from "./components/PageMeta";
import DefaultLayout from "@layouts/DefaultLayout";

const MainContent: React.FC = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <HelmetProvider>
      <PageMeta title="CT Portal" />
      <AuthenticatedTemplate>
        {activeAccount ? <AppRoutes /> : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <DefaultLayout>
          <LoginPage />
        </DefaultLayout>
      </UnauthenticatedTemplate>
    </HelmetProvider>
  );
};

const App = ({ instance }: { instance: IPublicClientApplication }) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

export default App;
