import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import {
  Col,
  Row,
  Card,
  Descriptions,
  Typography,
  notification,
  Empty,
  Table
} from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { OnlineTrainingReportDetailsTable } from "@components/common/reports/OnlineTrainingReportDetailsTable";

import { onlineTrainingReportsApi } from "@api/onlineTrainingReportsApi";

import useDateFormatter from "@hooks/useDateFormat";
import { usePagination } from "@hooks/usePagination";

const OnlineTrainingReportDetailsPage: React.FC = () => {
  const [t] = useTranslation();

  const { page, setPage, setPagination } = usePagination();

  const { formatter } = useDateFormatter();
  const { id }: { id: string } = useParams();

  const currentReportData = useQuery(
    "current-online-training-report",
    () =>
      onlineTrainingReportsApi
        .getOnlineTrainingReport(id)
        .then((res) => res.data),
    {
      onError: () =>
        notification.error({
          message: t("error.errorOccured")
        })
    }
  );

  const currentReportUsers = useQuery(
    ["current-online-training-report-users", page],
    () =>
      onlineTrainingReportsApi
        .getOnlineTrainingReportUsers({ reportId: id, page })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage
        };
        setPagination(pagination);
      },
      onError: () =>
        notification.error({
          message: t("error.errorOccured")
        })
    }
  );

  const pageTitle = currentReportData.data
    ? currentReportData?.data.name
    : t("reports.onlineTrainingReport");

  const paginationSettings = {
    hideOnSinglePage: true,
    total: currentReportUsers.data?.totalItems,
    current: currentReportUsers.data?.currentPage,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: t("reports.onlineTrainingReport_other"),
            path: "/admin/reports/online-training-reports"
          },
          {
            breadcrumbName: pageTitle,
            path: currentReportData.data
              ? `/admin/reports/online-training-report/${currentReportData.data?.id}`
              : ""
          }
        ]}
      />
      <Card
        loading={currentReportData.isLoading}
        style={{ marginBottom: "24px" }}
      >
        {!currentReportData.data ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("error.errorOccured")}
          />
        ) : (
          <>
            <Row gutter={[16, 16]} justify="space-between">
              <Col>
                <Descriptions size="small">
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("name")}
                      </Typography.Text>
                    }
                  >
                    {currentReportData.data?.name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("contractors.contractor")}
                      </Typography.Text>
                    }
                  >
                    {currentReportData.data?.contractorDto.name}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <Descriptions size="small">
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("status")}
                      </Typography.Text>
                    }
                  >
                    {currentReportData.data?.completedAt
                      ? `${t("reports.completed")} ${formatter(
                          currentReportData.data?.completedAt,
                          "short-date"
                        )}`
                      : t("incompleted")}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <Descriptions size="small">
                  <Descriptions.Item
                    label={
                      <Typography.Text type="secondary">
                        {t("createdAt")}
                      </Typography.Text>
                    }
                  >
                    {currentReportData.data &&
                      formatter(
                        currentReportData.data?.createdAt,
                        "short-date"
                      )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </>
        )}
      </Card>
      {currentReportUsers.data ? (
        <OnlineTrainingReportDetailsTable
          currentReportUsers={currentReportUsers.data}
          isLoading={currentReportUsers.isLoading}
          pagination={paginationSettings}
        />
      ) : (
        <Table
          dataSource={[]}
          loading={currentReportUsers.isLoading}
          locale={{
            emptyText: currentReportUsers.isLoading
              ? ""
              : t("error.errorOccured")
          }}
        />
      )}
    </>
  );
};

export default OnlineTrainingReportDetailsPage;
