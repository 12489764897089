import { useEffect, useRef, useState } from "react";

import Chart from "chart.js";

export const useChartjs = (chartOptions: Chart.ChartConfiguration) => {
  const chartContext = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart | undefined>();

  useEffect(() => {
    if (chart) {
      const chartTemp = chart;
      chartTemp.update({ duration: 500, lazy: false, easing: "linear" });
      setChart(chartTemp);
    }
  }, [chart]);

  useEffect(() => {
    if (chartContext.current) {
      setChart(new Chart(chartContext.current, chartOptions));
    }
  }, [chartOptions]);

  return {
    chartContext,
    chart,
    setChart
  };
};
