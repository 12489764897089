import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space
} from "antd";
import PhoneInput from "@components/ui/PhoneInput";
import IinInput from "@components/ui/IinInput";

import { Country } from "@models/countryCity";
import { EmployeeDetails } from "@models/employee";

interface FormValues {
  firstName: string;
  lastName: string;
  middleName: string;
  firstNameCyrillic: string;
  lastNameCyrillic: string;
  middleNameCyrillic: string;
  phoneNumber: string;
  birthDate: string;
  citizenshipId: number;
  residenceCountryId: number;
  residenceRegion: string;
  hasIin?: boolean;
  iin?: string;
}

interface Props {
  employee?: EmployeeDetails;

  errors?: { [key: string]: string };

  countries?: Country[];

  isLoading?: boolean;
  omitIin?: boolean;
  submitText?: string;
  cancelText?: string;

  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

export const EmployeePersonalInfoForm: React.FC<Props> = (props) => {
  const [t, i18n] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      firstNameCyrillic: "",
      lastNameCyrillic: "",
      middleNameCyrillic: "",
      phoneNumber: "",
      birthDate: "",
      citizenshipId: 0,
      residenceCountryId: 0,
      residenceRegion: "",
      hasIin: true,
      iin: ""
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(t("validation.required")),
      lastName: yup.string().required(t("validation.required")),
      middleName: yup.string().required(t("validation.required")),
      firstNameCyrillic: yup.string().required(t("validation.required")),
      lastNameCyrillic: yup.string().required(t("validation.required")),
      middleNameCyrillic: yup.string().required(t("validation.required")),
      phoneNumber: yup
        .string()
        .required(t("validation.required"))
        .matches(/^[0-9]+$/, t("validation.invalidPhoneNumber"))
        .length(11, t("validation.invalidPhoneNumber")),
      birthDate: yup.string().required(t("validation.required")),

      citizenshipId: yup
        .number()
        .required(t("validation.required"))
        .min(1, t("validation.required")),
      residenceCountryId: yup
        .number()
        .required(t("validation.required"))
        .min(1, t("validation.required")),
      residenceRegion: yup.string().required(t("validation.required")),

      hasIin: yup.lazy(() => {
        if (props.omitIin) {
          return yup.bool().optional().strip();
        }

        return yup.bool().required(t("validation.required"));
      }),
      iin: yup.lazy(() => {
        if (props.omitIin) {
          return yup.string().optional();
        }

        return yup.string().when("hasIin", {
          is: true,
          then: yup
            .string()
            .required(t("validation.required"))
            .matches(/^[0-9]+$/, t("validation.invalidIin"))
            .length(12, t("validation.invalidIin")),
          otherwise: yup.string().optional()
        });
      })
    }),
    onSubmit({ iin, hasIin, ...otherValues }) {
      if (props.onSubmit) {
        const values = props.omitIin
          ? otherValues
          : { iin, hasIin, ...otherValues };

        props.onSubmit(values);
      }
    }
  });

  useEffect(() => {
    if (props.employee) {
      const e = props.employee;

      formik.setValues({
        firstName: e.firstName,
        lastName: e.lastName,
        middleName: e.middleName,
        firstNameCyrillic: e.firstNameCyrillic,
        lastNameCyrillic: e.lastNameCyrillic,
        middleNameCyrillic: e.middleNameCyrillic,
        birthDate: e.birthDate,
        citizenshipId: e.citizenshipId,
        phoneNumber: e.phoneNumber,
        residenceCountryId: e.residenceCountryId,
        residenceRegion: e.residenceRegion,
        hasIin: e.hasIin,
        iin: e.iin
      });
    }
    // eslint-disable-next-line
  }, [props.employee]);

  const getValidateStatus = (fieldName: keyof FormValues) =>
    formik.touched[fieldName] && formik.errors[fieldName] ? "error" : "";
  const getHelp = (fieldName: keyof FormValues) =>
    formik.touched[fieldName] && formik.errors[fieldName];

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8}>
        <Col md={8}>
          <Form.Item
            label={t("employees.lastName")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("lastName")}
            help={getHelp("lastName")}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.firstName")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("firstName")}
            help={getHelp("firstName")}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.middleName")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("middleName")}
            help={getHelp("middleName")}
          >
            <Input
              name="middleName"
              value={formik.values.middleName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.lastNameCyrillic")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("lastNameCyrillic")}
            help={getHelp("lastNameCyrillic")}
          >
            <Input
              name="lastNameCyrillic"
              value={formik.values.lastNameCyrillic}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.firstNameCyrillic")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("firstNameCyrillic")}
            help={getHelp("firstNameCyrillic")}
          >
            <Input
              name="firstNameCyrillic"
              value={formik.values.firstNameCyrillic}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.middleNameCyrillic")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("middleNameCyrillic")}
            help={getHelp("middleNameCyrillic")}
          >
            <Input
              name="middleNameCyrillic"
              value={formik.values.middleNameCyrillic}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.birthDate")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("birthDate")}
            help={getHelp("birthDate")}
          >
            <DatePicker
              placeholder=""
              style={{ width: "100%" }}
              value={
                formik.values.birthDate ? moment(formik.values.birthDate) : null
              }
              onChange={(v) =>
                formik.setFieldValue("birthDate", v?.toISOString())
              }
              onBlur={() => formik.handleBlur("birthDate")}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.phoneNumber")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("phoneNumber")}
            help={getHelp("phoneNumber")}
          >
            <PhoneInput
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maskChar={" "}
              mask="99999999999"
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.citizenship")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("citizenshipId")}
            help={getHelp("citizenshipId")}
          >
            <Select
              showSearch
              optionFilterProp="name"
              filterOption={(inp, opt) => {
                if (opt && opt.children) {
                  return (
                    opt.children
                      .toString()
                      .toLowerCase()
                      .indexOf(inp.toLowerCase()) >= 0
                  );
                }

                return false;
              }}
              value={formik.values.citizenshipId || undefined}
              onChange={(v) => formik.setFieldValue("citizenshipId", v)}
              onBlur={() => formik.handleBlur("citizenshipId")}
            >
              {props.countries?.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {i18n.language === "en" ? c.nameEn : c.nameRu}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.residenceCountry")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("residenceCountryId")}
            help={getHelp("residenceCountryId")}
          >
            <Select
              value={formik.values.residenceCountryId || undefined}
              showSearch
              optionFilterProp="name"
              filterOption={(inp, opt) => {
                if (opt && opt.children) {
                  return (
                    opt.children
                      .toString()
                      .toLowerCase()
                      .indexOf(inp.toLowerCase()) >= 0
                  );
                }

                return false;
              }}
              onChange={(v) => formik.setFieldValue("residenceCountryId", v)}
              onBlur={() => formik.handleBlur("residenceCountryId")}
            >
              {props.countries?.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {i18n.language === "en" ? c.nameEn : c.nameRu}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.residenceRegion")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("residenceRegion")}
            help={getHelp("residenceRegion")}
          >
            <Input
              name="residenceRegion"
              value={formik.values.residenceRegion}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        {!props.omitIin && formik.values.hasIin && (
          <Col md={4}>
            <Form.Item
              label={t("employees.iin")}
              labelCol={{ span: 24 }}
              validateStatus={getValidateStatus("iin")}
              help={getHelp("iin")}
            >
              <IinInput
                name="iin"
                value={formik.values.iin}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </Col>
        )}

        {/* the label contradicts with the field values */}
        {/* so we have to reverse the value */}
        {!props.omitIin && (
          <Col md={4}>
            <Form.Item
              label=" "
              labelCol={{ span: 24 }}
              validateStatus={formik.errors.hasIin && "error"}
              help={formik.errors.hasIin}
            >
              <Checkbox
                checked={!formik.values.hasIin}
                onChange={(v) => {
                  const nextVal = !v.target.checked;

                  if (!nextVal) {
                    formik.setFieldValue("iin", "");
                  }

                  formik.setFieldValue("hasIin", nextVal);
                }}
              >
                {t("employees.noIin")}
              </Checkbox>
            </Form.Item>
          </Col>
        )}

        <Col md={24}>
          <Row justify="end">
            <Col>
              <Space align="end">
                <Button htmlType="button" onClick={props.onCancel}>
                  {props.cancelText ? props.cancelText : t("cancel")}
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  loading={props.isLoading}
                >
                  {props.submitText ? props.submitText : t("submit")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
