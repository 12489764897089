import axios from "axios";

import { PaginationItem } from "@models/paginationItem";
import {
  UpdateSubcontractorPayload,
  CreateSubcontractorPayload,
  Subcontractor,
  SubcontractorDetails
} from "@models/subcontractor";
import { Contract } from "@models/contract";

const getSubcontractorPage = ({
  contractorId,
  page,
  filters
}: {
  contractorId: number;
  page?: number;
  filters?: {};
}) =>
  axios.get<PaginationItem<Subcontractor>>(
    `/api/contractor/${contractorId}/subcontractor`,
    {
      params: { page }
    }
  );

const getSubcontractorList = (contractorId: number) =>
  axios.get<Subcontractor[]>(
    `/api/contractor/${contractorId}/filter/subcontractor`
  );

const getSubcontractorsAll = () =>
  axios.get<Subcontractor[]>(`api/contractor/subcontractor`);

const getSubcontractor = ({
  contractorId,
  subcontractorId
}: {
  contractorId: number;
  subcontractorId: number;
}) =>
  axios.get<SubcontractorDetails>(
    `/api/contractor/${contractorId}/subcontractor/${subcontractorId}`
  );

const createSubcontractor = ({
  contractorId,
  subcontractor
}: {
  contractorId: number;
  subcontractor: CreateSubcontractorPayload;
}) =>
  axios.post(`/api/contractor/${contractorId}/subcontractor`, subcontractor);

const deleteSubcontractor = ({
  contractorId,
  subcontractorId
}: {
  contractorId: number;
  subcontractorId: number;
}) =>
  axios.delete(
    `/api/contractor/${contractorId}/subcontractor/${subcontractorId}`
  );

const updateSubcontractor = ({
  contractorId,
  subcontractorId,
  subcontractor
}: {
  contractorId: number;
  subcontractorId: number;
  subcontractor: UpdateSubcontractorPayload;
}) =>
  axios.put(
    `/api/contractor/${contractorId}/subcontractor/${subcontractorId}`,
    subcontractor
  );

const getSubcontractorContracts = ({
  contractorId,
  subcontractorId
}: {
  contractorId: number;
  subcontractorId: number;
}) =>
  axios.get<Contract[]>(
    `/api/contractor/${contractorId}/subcontractor/${subcontractorId}/contract`
  );

export const subcontractorApi = {
  getSubcontractorList,
  getSubcontractorsAll,
  getSubcontractorPage,
  getSubcontractor,
  createSubcontractor,
  updateSubcontractor,
  deleteSubcontractor,
  getSubcontractorContracts
};
