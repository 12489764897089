import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import {
  Card,
  Col,
  Row,
  Button,
  Input,
  Typography,
  Form,
  notification
} from "antd";

import userApi from "@api/userApi";

import useValidatedMutation from "@hooks/useValidatedMutation";

const { Title, Text } = Typography;
interface FormValues {
  email: string;
}

const ResetPasswordPage: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const mutation = useValidatedMutation<FormValues>({
    mutationFunction: (values: FormValues) =>
      userApi.requestResetPassword(values),
    onSuccess: () => {
      notification.success({
        message: t("resetPassword.resetLinkSent", {
          email: formik.values.email
        })
      });
      history.replace("/login");
    },
    onError: (error) => {
      formik.setErrors(error);
    }
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      email: ""
    },
    onSubmit: (values) => mutation.mutate(values)
  });

  return (
    <div className="guest-pages-bg">
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col span={12}>
          <Card>
            <Card.Meta
              style={{ marginBottom: 16 }}
              title={<Title level={3}>{t("resetPassword.title")}</Title>}
              description={
                <Text style={{ fontSize: 14 }} disabled>
                  {t("resetPassword.enterEmail")}
                </Text>
              }
            />
            <Form layout="vertical" onFinish={formik.handleSubmit}>
              <Form.Item
                label={t("email")}
                labelCol={{ span: 24 }}
                validateStatus={formik.errors.email && "error"}
                help={formik.errors.email}
              >
                <Input
                  name="email"
                  size="large"
                  placeholder={t("email")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Form.Item>
              <Form.Item noStyle>
                <Row justify="space-between" align="middle">
                  <Link to="/login">
                    <Button type="link" style={{ padding: 0 }}>
                      {t("resetPassword.loginToCtPortal")}
                    </Button>
                  </Link>
                  <Button
                    loading={mutation.isLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    {t("resetPassword.next")}
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPasswordPage;
