import { FlagOutlined } from "@ant-design/icons";

import { general } from "@utils/userguides/subcontractor/general";
import { search } from "@utils/userguides/subcontractor/search";
import { events } from "@utils/userguides/subcontractor/events";
import { reports } from "@utils/userguides/subcontractor/reports";
import { employees } from "@utils/userguides/subcontractor/employees";
import { members } from "@utils/userguides/subcontractor/members";
import { certificateVerification } from "@utils/userguides/subcontractor/certificateVerification";
import { profile } from "@utils/userguides/subcontractor/profile";

export const subcontractorUserguide = [
  { title: "general", data: general, icon: <FlagOutlined /> },
  { title: "search", data: search, icon: <FlagOutlined /> },
  { title: "events", data: events, icon: <FlagOutlined /> },
  { title: "reports", data: reports, icon: <FlagOutlined /> },
  { title: "employees", data: employees, icon: <FlagOutlined /> },
  { title: "members", data: members, icon: <FlagOutlined /> },
  {
    title: "certificateVerification",
    data: certificateVerification,
    icon: <FlagOutlined />
  },
  { title: "profileSub", data: profile, icon: <FlagOutlined /> }
];
