import { useTranslation } from "react-i18next";

import Column from "antd/lib/table/Column";

import { Table, Typography } from "antd";
import { RECOMMENDATION_OPTIONS } from "@components/common/reports/CommissioningReportFilterForm";

import {
  EventDetails,
  EventEmployeeDetails,
  EventEmployeeGradeType,
  EventType,
  FailReasonType
} from "@models/event";

interface Props {
  eventEmployees?: EventEmployeeDetails[];
  eventDetails: EventDetails;
}

const EventGradesTable: React.FC<Props> = (props) => {
  const [t] = useTranslation();

  return (
    <Table dataSource={props.eventEmployees} pagination={false}>
      <Column
        title={t("events.participant")}
        render={(e: EventEmployeeDetails) => (
          <>
            {e.firstName}
            <br />
            {e.lastName}
            <br />
            <Typography.Text type="secondary">{e.iin}</Typography.Text>
          </>
        )}
      />

      <Column
        title={t("events.practicalGrade")}
        render={(ee: EventEmployeeDetails) => ee.practicalGrade}
      />

      <Column
        title={t("events.theoreticalGrade")}
        render={(ee: EventEmployeeDetails) => ee.theoreticalGrade}
      />

      {props.eventDetails.type !== EventType.PRESCREENING && (
        <Column
          title={t("events.academicHours")}
          render={(e) => e.attendanceTotal}
        />
      )}

      {props.eventDetails.isCommissioning && (
        <>
          <Column
            title={t("events.theoreticalStatus")}
            render={(ee: EventEmployeeDetails) =>
              t(`events.gradeStatus.${ee.theoreticalStatus}`)
            }
          />
          <Column
            title={t("events.practicalStatus")}
            render={(ee: EventEmployeeDetails) =>
              t(`events.gradeStatus.${ee.practicalStatus}`)
            }
          />
        </>
      )}

      {[EventType.OJT_TRAINING].includes(props.eventDetails.type) ||
        (props.eventDetails.isCommissioning && (
          <Column
            title={t("events.competencyLevel")}
            render={(ee: EventEmployeeDetails) =>
              t(`competencyLevels.${ee.competencyLevel}`)
            }
          />
        ))}

      <Column
        title={t("events.overallGrade")}
        render={(ee: EventEmployeeDetails) => t(`events.grade.${ee.isPassed}`)}
      />
      <Column
        title={t("events.failReason.failReason")}
        render={(ee: EventEmployeeDetails) => {
          if (ee.isPassed !== EventEmployeeGradeType.FAILED) {
            return "-";
          }

          return ee.failReasonType === FailReasonType.OTHER
            ? ee.failReason
            : t(`events.failReason.${ee.failReasonType}`);
        }}
      />
      <Column
        title={t("events.recommendation")}
        render={(ee: EventEmployeeDetails) => {
          const recommendation = RECOMMENDATION_OPTIONS.find(
            (item) => item.value === Number(ee.recommendation)
          );
          return recommendation ? t(recommendation.label) : "-";
        }}
      />
    </Table>
  );
};

export default EventGradesTable;
