import { useContext, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router";

import { Content } from "antd/lib/layout/layout";

import { WarningOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";

import { UserContext } from "@contexts/UserContext";

type ErrorPageProps = RouteComponentProps;

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const history = useHistory();
  const user = useContext(UserContext);
  useEffect(() => {
    if (!user.isUserLoggedIn) {
      history.push("/login");
    } else if (props.history.location.pathname !== props.match.path) {
      history.push("/profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history, props.match]);

  return (
    <Content
      style={{
        padding: 24,
        margin: 0,
        minHeight: 280,
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch"
      }}
    >
      <Space size="large">
        <WarningOutlined
          style={{
            color: "rgba(234, 105, 73, 0.8)",
            fontSize: 72
          }}
        />
        <Space direction="vertical">
          <Typography.Title level={1} style={{ marginBottom: 0 }}>
            Error
          </Typography.Title>
          <Typography.Title level={3} type="secondary">
            Something gone wrong
          </Typography.Title>
        </Space>
      </Space>
    </Content>
  );
};

export default ErrorPage;
