import picture1 from "@assets/images/user-guide/1.1.png";
import picture2 from "@assets/images/user-guide/1.2.png";
import picture3 from "@assets/images/user-guide/1.3.png";
import picture4 from "@assets/images/user-guide/1.4.png";
import picture5 from "@assets/images/user-guide/1.5.png";
import picture6 from "@assets/images/user-guide/1.6.png";
import picture7 from "@assets/images/user-guide/1.7.png";
import picture8 from "@assets/images/user-guide/1.8.png";
import picture9 from "@assets/images/user-guide/1.9.png";
import picture10 from "@assets/images/user-guide/1.10.png";
import picture11 from "@assets/images/user-guide/1.11.png";
import picture12 from "@assets/images/user-guide/1.12.png";
import picture13 from "@assets/images/user-guide/1.13.png";

export const reports = [
  {
    title: "Download data dump",
    html: `<ol>
    <li>
        <p>Data dump is a large file (.csv format) containing all the information about events, craft workers and their grades available in the system.&nbsp;</p><br>
        <p>In order to download data dump file follow the steps:</p>
        <ol>
            <li>
                <p>Press on Reports tab on panel menu</p>
            </li>
            <li>
                <p>Select Download data dump</p>
            </li>
            <li>
                <p>Press&nbsp;Generate data dump file&nbsp;or&nbsp;Generate commissioning data dump file&nbsp;button</p>
            </li>
            <li>
                <p>File will be shown in the Data dump files block</p>
            </li>
            <li>
                <p>Press&nbsp;Download&nbsp;button</p>
            </li>
        </ol><br>
        <p>Note:&nbsp;File generation will take up to 1 hour.&nbsp;</p><br>
        <p>Data Dump page details:</p>
        <ol>
            <li>
                <p>Report generation status: Completed, In progress, Failed.</p>
            </li>
            <li>
                <p>Generate data dump file button (for regular, non- commissioning events)</p>
            </li>
            <li>
                <p>Generate commissioning data dump file button (for commissioning events)</p>
            </li>
            <li>
                <p>Download button</p>
            </li>
            <li>
                <p>List of all previously generated files</p>
            </li>Picture 1.1
        </ol>
        <p></p>
        <p><img src=${picture1} width="624" height="227"></p>
    </li>
</ol>`
  },
  {
    title: "Performance Report",
    html: `<p>The report shows data of regular, non-commissioning event types: Assessment, Training, Pre- screening and OJT Training.</p>
<p>Details of the report are described below.</p>
<p><br></p>
<p>Report shows the following charts and buttons:</p>
<ol>
    <li>
        <p>Event type tab;</p>
    </li>
    <li>
        <p>Summary Statistic Cards;</p>
    </li>
    <li>
        <p>Trained employees number per year;</p>
    </li>
    <li>
        <p>Events by Training providers;</p>
    </li>
    <li>
        <p>Events by Contractors;</p>
    </li>
    <li>
        <p>Events by Disciplines;</p>
    </li>
    <li>
        <p>Event by Location;</p>
    </li>
    <li>
        <p>Citizenship of craft employees;</p>
    </li>
    <li>
        <p>Overall grade;</p>
    </li>
    <li>
        <p>Fail reasons;</p>
    </li>
    <li>
        <p>Download Performance Report button;</p>
    </li>
    <li>
        <p>Download Academic Hours Report button;</p>
    </li>
    <li>
        <p>Filtering;</p>
    </li>
    <li>
        <p>Switch Academic hours button.</p>
    </li>
</ol>
<p><br></p>
<p>Detailed information per each item is below.</p>
<p>&nbsp;</p>
<p>Page example:</p>
<p>Picture 1.2</p>
<p><img src=${picture2} width="624" height="448"></p>
<p>Picture 1.3</p>
<p><img src=${picture3} width="311" height="674"></p>
<p>Event type tab</p>
<p>TCO user can switch tabs to corresponding event types, so that the system will show the report on this specific type of event either Assessment, Training, Pre- screening or OJT.</p>
<p>Summary Statistic Cards</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>Card label</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>In progress</p>
                </td>
                <td>
                    <p>The number of craft employees registered to In progress events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Completed</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Completed events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Evaluated</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Evaluated events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Excluded</p>
                </td>
                <td>
                    <p>The number of craft employees excluded from the events. Shown if any craft employee has been excluded, otherwise hidden.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>The number of all registered craft employees to all types of events</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p>Trained employees number per year&nbsp;line graph demonstrates fluctuation over the years.</p>
<p><br></p>
<p>Events by Training providers:</p>
<p>System shows the number of events run by each Training Center. &nbsp;</p>
<p><br></p>
<p>Events by Contractors:</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <ol>
                        <li>
                            <p>System shows Performance report page<a href="https://petrelai.atlassian.net/browse/CTN-180">&nbsp;</a></p>
                        </li>
                        <li>
                            <p>Systems shows bar chart: Events by Contractors</p>
                        </li>
                        <li>
                            <p>User presses &apos;plus&apos; button next to Contractor company</p>
                        </li>
                        <li>
                            <p>System expands data and shows Contractor\`s Subcontractor data if there\`s any.</p>
                        </li>
                    </ol>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p>Events by Disciplines:</p>
<p>System shows the number of all craft employees taught per each discipline.&nbsp;</p>
<p><br></p>
<p>Also, discipline can be expanded if there&apos;s a plus sign (+) next to the discipline name.<br>In this case it shows all related disciplines and the number of employees taught per each discipline.</p>
<p><br></p>
<p>Event by Location:</p>
<p>Chart depicts a total number of all participants in a particular city as per &nbsp;event location, specified during the event creation stage.</p>
<p><br></p>
<p>Citizenship of craft employees:</p>
<p>Pie chart demonstrates employee citizenship countries. It shows the number and percentage.</p>
<p><br></p>
<p>Overall grade</p>
<p>Pie chart demonstrates employee overall grades. It shows the number and percentage.</p>
<p><br></p>
<p>Fail reasons</p>
<p>Pie chart demonstrates employee fail reasons. It shows the number and percentage.</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>Download Performance Report</p>
<p><br></p>
<p>In order to download Performance Report follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Download&nbsp;button</p>
    </li>
</ol>
<p><br></p>
<p><br></p>
<p><br></p>
<p>Download Academic Hours Report</p>
<p><br></p>
<p>In order to download Academic Hours Report follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Download Academic Hours Report&nbsp;button</p>
    </li>
</ol>
<p><br></p>
<p>Filtering</p>
<p>In order to use filters follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Filter&nbsp;icon</p>
    </li>
    <li>
        <p>Set up filter(s)</p>
    </li>
    <li>
        <p>Press&nbsp;Filter</p>
    </li>
</ol>
<p><br></p>
<p>To clear filters press&nbsp;Clear filters button</p>
<p><br></p>
<p>Switch Academic hours button</p>
<p>TCO user can view detailed information on the number of academic hours of the corresponding type of event (currently active tab) and it\`s total number in the following charts: Events by Training providers, Events by Contractors, Events by Disciplines.</p>`
  },
  {
    title: "Commissioning Performance Report",
    html: `<p>The page shows data of commissioning event types: Assessment, Training.</p>
<p>Details of the report are described below.</p>
<p>TCO users view all data of all Training Centers, Contractors, Subcontractors and craft workers registered in the system.&nbsp;</p>
<p><br></p>
<p>Report shows the following charts and buttons:</p>
<ol>
    <li>
        <p>Event type tab;</p>
    </li>
    <li>
        <p>Summary Statistic Cards;</p>
    </li>
    <li>
        <p>by Contractors;</p>
    </li>
    <li>
        <p>by Disciplines;</p>
    </li>
    <li>
        <p>by Fail reasons;</p>
    </li>
    <li>
        <p>by Competency level;</p>
    </li>
    <li>
        <p>Download Report button;</p>
    </li>
    <li>
        <p>Filtering.</p>
    </li>
</ol>
<p><br></p>
<p>Picture 1.4</p>
<p><img src=${picture4} width="576" height="588"></p>
<p>Event type tab</p>
<p>TCO user can switch tabs to corresponding event types, so that the system will show the report on this specific type of event either Assessment or Training.</p>
<p>Summary Statistic Cards</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>Card label</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>In progress</p>
                </td>
                <td>
                    <p>The number of craft employees registered to In progress events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Completed</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Completed events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Evaluated</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Evaluated events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>The number of all registered craft employees to all types of events</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p><br></p>
<p>Events by Competency levels:</p>
<p>System shows the number of employees of each competency level: No competency, Trainee Technician, Junior Technician, Technician, Senior Technician.&nbsp;</p>
<p><br></p>
<p>The page shows data, described below, of craft employees&apos; event performance per each commissioning event type: Assessment, Training.</p>
<p><br></p>
<p><br></p>
<p>Report shows the following bar and pie charts:</p>
<p>Summary Statistic Cards</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>Label</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>In progress</p>
                </td>
                <td>
                    <p>The number of employees registered to In progress events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Completed</p>
                </td>
                <td>
                    <p>The number of employees registered to Completed events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Evaluated</p>
                </td>
                <td>
                    <p>The number of employees registered to Evaluated events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>The number of all registered employees to all types of events</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p>Events by Competency levels:</p>
<p>System shows the number of events run by each Training Center. &nbsp;</p>
<p><br></p>
<p>Events by Contractors:</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <ol>
                        <li>
                            <p>System shows Performance report page<a href="https://petrelai.atlassian.net/browse/CTN-180">&nbsp;</a></p>
                        </li>
                        <li>
                            <p>Systems shows bar chart: Events by Contractors</p>
                        </li>
                        <li>
                            <p>User presses &apos;plus&apos; button next to Contractor company</p>
                        </li>
                        <li>
                            <p>System expands data and shows Contractor\`s Subcontractor data if there\`s any.</p>
                        </li>
                    </ol>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p>Fail reasons</p>
<p>Pie chart demonstrates employee fail reasons. It shows the number and percentage.</p>
<p><br></p>
<p><br></p>
<p>Download Performance Report</p>
<p><br></p>
<p>In order to download Performance Report follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Commissioning Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Download&nbsp;button</p>
    </li>
</ol>
<p><br></p>
<p><br></p>
<p>Filtering</p>
<p>In order to use filters follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Commissioning Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Filter&nbsp;icon</p>
    </li>
    <li>
        <p>Set up filter(s)</p>
    </li>
    <li>
        <p>Press&nbsp;Filter</p>
    </li>
</ol>
<p><br></p>
<p>To clear filters press Clear filters button</p>`
  },
  {
    title: "MoU Category",
    html: `<p>MoU stands for Memorandum of Understanding.&nbsp;</p>
<p>TCO user views graphs and a table for MoU Category combined by disciplines and .</p>
<p><br></p>
<p>System shows two line graphs and a table</p>
<p><br></p>
<p><br></p>
<p><br></p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>Assessment graph</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Supervisor and foreman</p>
                </td>
                <td>
                    <p>Number of Employees, who were evaluated in discipline &lsquo;Field Supervisor&rsquo;</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Heavy equipment</p>
                </td>
                <td>
                    <p>Number of Employees, who were evaluated in disciplines with &ldquo;operator&rdquo; in the name, except PWHT</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Craft, others</p>
                </td>
                <td>
                    <p>Number of Employees, who were evaluated in all other disciplines</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>Sum of Assessment elements</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p>Assessment graph page example:</p>
<p>Picture 1.5</p>
<p><img src=${picture5} width="623.9993591308594" height="331.99783742427826"></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>Training graph</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Supervisor and foreman</p>
                </td>
                <td>
                    <p>Number of Employees, who were evaluated in discipline Field Supervisor</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Heavy equipment</p>
                </td>
                <td>
                    <p>Number of Employees, who were evaluated in discipline with &ldquo;operator&rdquo; in the name, except PWHT</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Craft, others</p>
                </td>
                <td>
                    <p>Number of Employees, who were evaluated in all other disciplines</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>Sum of Training elements (MoU Disciplines)</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p>Training graph page example:</p>
<p>Picture 1.6</p>
<p><img src=${picture6} width="624" height="331.9991942644119"></p>
<p>MoU Categories graphs description</p>
<p>X-axis - Years, starts with the Project beginning year until current year</p>
<p>Y-axis - Number of employees&nbsp;</p>
<p><br></p>
<p><br></p>
<p><br></p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>MoU Categories by disciplines table</p>
                </td>
                <td>
                    <p>shows number of employees per each discipline per event type and period</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Table elements</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Rows:</p>
                </td>
                <td><br></td>
            </tr>
            <tr>
                <td>
                    <p>Supervisor and Foreman/ Руководитель среднего звена и мастер</p>
                </td>
                <td>
                    <p>Discipline of employee = Field Supervisor</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Heavy Equipment Operator/ Оператор тяжелого оборудования</p>
                </td>
                <td>
                    <p>Discipline of employee = All that have operator in the name, except PWHT</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Craft / Рабочий специалист</p>
                </td>
                <td>
                    <p>Discipline of employee = all others</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>total numbers per each column</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Columns:</p>
                </td>
                <td><br></td>
            </tr>
            <tr>
                <td>
                    <p>MoU Category by disciplines</p>
                </td>
                <td>
                    <p>MoU disciplines as described in rows</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Assessment</p>
                </td>
                <td>
                    <p>shows the number of employees for evaluated assessment events in each MoU discipline</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Training</p>
                </td>
                <td>
                    <p>shows the number of employees for evaluated training events in each MoU discipline</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Pre-screening</p>
                </td>
                <td>
                    <p>shows the number of employees for evaluated pre-screening events in each MoU discipline</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Period</p>
                </td>
                <td>
                    <p>Includes numbers which were selected on filters</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>PTD (all numbers)</p>
                </td>
                <td>
                    <p>Project-to-date includes all numbers from the beginning of the Project&nbsp;</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p>Table example:</p>
<p>Picture 1.7</p>
<p><img src=${picture7} width="624" height="331.9993278980255"></p>`
  },
  {
    title: "Duplicated Headcount Report",
    html: `<p>Duplicated Headcount Report shows TCO users number of duplicated craft-employees combined by IIN, Contractors and Training Providers.</p>
<p><br></p>
<p>Duplicated Headcount Report page elements:&nbsp;</p>
<ol>
    <li>
        <p>Active tab;</p>
    </li>
    <li>
        <p>Total number of craft employees registered;</p>
    </li>
    <li>
        <p>Statistic cards per each type of the event;</p>
    </li>
    <li>
        <p>Table;</p>
    </li>
    <li>
        <p>Filters.</p>
    </li>
</ol>
<p><br></p>
<p>Picture 1.8</p>
<p><img src=${picture8} width="624" height="594.993085861206"></p>
<p>Statistic cards:</p>
<ul>
    <li>
        <p>Headcount: Number of unique craft-employees&apos; IIN that has been registered to evaluated events</p>
    </li>
    <li>
        <p>Assessment: Number of evaluated assessment events</p>
    </li>
    <li>
        <p>Commissioning Assessment: Number of evaluated commissioning assessment events</p>
    </li>
    <li>
        <p>Training: Number of evaluated training events</p>
    </li>
    <li>
        <p>Commissioning Training: Number of evaluated commissioning training events</p>
    </li>
    <li>
        <p>Pre-screening: Number of evaluated pre-screening events</p>
    </li>
    <li>
        <p>OJT Training: Number of evaluated OJT training events</p>
    </li>
</ul>
<p>The table on the Duplicated headcount page:</p>
<ul>
    <li>
        <p>№: Sequential number</p>
    </li>
    <li>
        <p>Count: Craft-employee&rsquo;s number of activities</p>
    </li>
    <li>
        <p>IIN: Craft-employee&rsquo;s IIN</p>
    </li>
    <li>
        <p>Citizenship: Craft-employee&rsquo;s citizenship</p>
    </li>
    <li>
        <p>Contractor/Subcontractor: Contractor name/Subcontractor name (if any)</p>
    </li>
    <li>
        <p>Training provider: Training provider name</p>
    </li>
    <li>
        <p>Discipline: Discipline name</p>
    </li>
    <li>
        <p>Event type: Type of the event</p>
    </li>
    <li>
        <p>Overall Grade: Failed, Passed, Basic, Advanced, Intermediate, Competent, Proficient, Helper</p>
    </li>
    <li>
        <p>Event dates: start and end dates of the event</p>
    </li>
</ul>`
  },
  {
    title: "Online Training Reports (KPJV)",
    html: `<p dir="ltr">
    Contractors create KPJV Online Reports. These Reports include information
    about non- craft employees who have participated in any of the events.
</p>
<p dir="ltr">
    KPJV Online Reports helps capture those participants\` data and store the
    information all in one place.
</p>
<p dir="ltr">
    Only TCO &amp; Contractor users can view this type of the report.
</p>
<br/>
<p dir="ltr">
    List of Online Training Reports from all Contractor companies page example:
</p>
<p dir="ltr">
    Picture 1.9
</p>
<p dir="ltr">
    <img
        src=${picture9}
        width="624"
        height="331.9999351501465"
    />
</p>
<br/>
<br/>
<p dir="ltr">
    Online Training Report details:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Name of the online training report;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Online training information;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Online training participants\` information.
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Picture 1.10
</p>
<p dir="ltr">
    <img
        src=${picture10}
        width="624"
        height="261"
    />
</p>
<br/>
<br/>
<p dir="ltr">
    Page example:
</p>
<p dir="ltr">
    Picture 1.11
</p>
<p dir="ltr">
    <img
        src=${picture11}
        width="624"
        height="332"
    />
</p>
`
  },
  {
    title: "Profile Report (HR module)",
    html: `<p dir="ltr">
    The Profile report shows the breakdown of CSR HR profiles (e.g. job
    seekers).
</p>
<p dir="ltr">
    Note: This report is only available for TCO users.
</p>
<br/>
<p dir="ltr">
    The page contains the following information:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Profiles by Training Center;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Profiles by Status;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Hired Profiles by Contractors;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Profiles by CSR Contractor;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Profiles by Disciplines;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Download profiles data button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filters
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 1.12
</p>
<p dir="ltr">
    <img
        src=${picture12}
        width="624"
        height="604"
    />
</p>
<br/>
<br/>
<p dir="ltr">
    Page elements:
</p>
<p dir="ltr">
    Profiles by Training Center depict the number of profiles registered by TC.
</p>
<br/>
<p dir="ltr">
    Profiles by status represent the number of hired and unemployed profiles.
</p>
<br/>
<p dir="ltr">
    Hired Profiles by Contractors shows the number of CSR profiles hired by a
    particular Contractor company.
</p>
<br/>
<p dir="ltr">
    Profiles by CSR Contractor shows the number of registered CSR profiles per
    CSR Contractor.
</p>
<br/>
<p dir="ltr">
    Profiles by Disciplines shows the number of CSR profiles of a particular
    discipline.
</p>
<br/>
<p dir="ltr">
    Download profiles data button lets User download data via excel file. The
    information shown on the Report page is saved to excel file.
</p>
`
  },
  {
    title: "How to download all profiles?",
    html: `
<p dir="ltr">
    In order to download all profiles data to excel file TCO user should:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Go to Reports page
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press ‘Download Profiles Data’ button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System downloads excel file* with all profiles data
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    *Data downloaded to excel file:
</p>
<div dir="ltr" align="left">
    <table>
        <colgroup>
            <col width="186"/>
            <col width="429"/>
        </colgroup>
        <tbody>
            <tr>
                <td>
                    <p dir="ltr">
                        Column
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Description
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        IIN
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Craft employee IIN
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Employment status
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        employed/ unemployed
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Contractor/Subcontractor or Other company name
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        if status is 'employed'
                    </p>
                    <p dir="ltr">
                        if status is unemployed system shows N/A
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR Phase
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        filled out during profile creation/ None, 1-50
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR Contractor
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        filled out during profile creation/ CSR Aktau, CSR
                        Atyrau or Demob
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<br/>
<p dir="ltr">
    Filters
</p>
<p dir="ltr">
    The following filters are available on the Profile report page:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            CSR phases
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Employment status
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Training centers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Disciplines
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            CSR Contractors
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Contractors
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Clear filter button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Page example:
</p>
<p dir="ltr">
    Picture 1.13
</p>
<p dir="ltr">
    <img
        src=${picture13}
        width="623.986726641655"
        height="331.9992244243622"
    />
</p>
<div>
    <br/>
</div>
`
  }
];
