import React from "react";

import { ContractorEventPagination } from "@components/contractors/events/ContractorEventPagination";

import { EventStatus } from "@models/event";

export const CancelledEventsPage = () => {
  return (
    <ContractorEventPagination
      isSubcontractor
      eventStatus={EventStatus.CANCELLED}
    />
  );
};
