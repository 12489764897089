import { Moment } from "moment";

import { City } from "./countryCity";
import { Discipline } from "./discipline";
import { TrainingCenter } from "./trainingCenter";
import { LanguageCode } from "./languageCode";
import { Contractor } from "./contractor";
import { Location } from "./location";

export interface EventData {
  id: number;
  type: number;
  isCommissioning: boolean;
  status: number;
  name: string;
  speciality?: Discipline;
  competencyLevel?: number;
  languageCode?: number;
  city?: City;
  contractors?: Contractor[];
  subcontractors?: Contractor[];
  trainingCenter?: TrainingCenter;
  description?: string;
  standard?: string;
  resultFileLink?: string;
  dateTimeStart: string;
  dateTimeEnd: string;
  capacityMin: number;
  capacityMax: number;
  capacityAvailable: number;
  academicHours: number;
  capacityBusy: number;
  csrPhase: number;
  files?: {
    id: number;
    name: string;
    uniqueFileName: string;
  }[];
  createdBy?: {
    firstName: string;
    lastName: string;
    fullname: string;
    email: string;
    phoneNumber: string;
  };
}

export interface EventSearchFilter {
  dateStart?: string;
  dateEnd?: string;
  type?: number;
  isCommissioning?: boolean;
  seats?: number;
  languageCode?: number;
  competencyLevel?: number;
  customCompetencyLevel?: number;
  specialityIds?: number[];
  trainingCenterIds?: number[];
  cityIds?: number[];
  sort?: number;
}

export interface EventFilter {
  dateStart?: string;
  dateEnd?: string;
  type?: number;
  languageCode?: number;
  competencyLevel?: number;
  customCompetencyLevel?: number;
  specialityIds?: number[];
  trainingCenterIds?: number[];
  isCommissioning?: boolean;
  csrPhases?: number[];
  cityIds?: number[];
  contractorIds?: number[];
  eventIds?: number[];
  sortBy?: number;
  status?: number;
  eventCityIds?: number[];
}

export enum EventType {
  ASSESSMENT = 1,
  TRAINING = 2,
  PRESCREENING = 3,
  OJT_TRAINING = 4
}

export interface DuplicatedHeadcount {
  headcount: number;
  assessment: number;
  training: number;
  preScreening: number;
  ojtTraining: number;
  commissioningAssessment: number;
  commissioningTraining: number;
}

export enum DuplicatedHeadcountTypeKey {
  HEADCOUNT = "headcount",
  ASSESSMENT = "assessment",
  TRAINING = "training",
  PRE_SCREEINING = "preScreening",
  OJT_TRAINING = "ojtTraining",
  COMMISSIONING_ASSESSMENT = "commissioningAssessment",
  COMMISSIONING_TRAINING = "commissioningTraining"
}

export enum EventStatus {
  PENDING = 1,
  INPROGRESS = 2,
  COMPLETED = 3,
  EVALUATED = 4,
  CANCELLED = 5,
  ACTIVATED = 6
}

export enum EmployeeEventStatus {
  INPROGRESS = 2,
  COMPLETED = 3,
  EVALUATED = 4,
  EXCLUDED = "excluded",
  TOTAL = "total"
}

export enum CsrPhase {
  NONE = 0,
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FORTH = 4,
  FIFTH = 5,
  SIXTH = 6,
  SEVENTH = 7
}

export enum CapacityMinNotificationEntitiesOptions {
  TCO = 0,
  TRAINING_CENTER = 2
}

export interface EventShortDetails {
  id: number;
  name: string;
  academicHours: number;
  languageCode: LanguageCode;
  csrPhase: CsrPhase;
  status: EventStatus;
  dateTimeStart: string;
  dateTimeEnd: string;
  type: EventType;
  competencyLevel: number;
  capacityBusy: number;
  capacityMax: number;
  isCommissioning: boolean;
  location?: {
    nameEn: string;
    nameRu: string;
  };
  contractors: {
    contractorId: number;
    contractor: {
      id: number;
      name: string;
      type: number;
      bin: string;
      phone: string;
      supervisorEmail: string;
    };
    eventId: number;
  }[];
  trainingCenter: {
    id: number;
    name: string;
    address: string;
    cityId: number;
    csrEnabled: boolean;
    commissioningEnabled: boolean;
    eventTypes: number[];
    createdAt: string;
    updatedAt: string;

    city: {
      nameEn: string;
      nameRu: string;
    };
  };

  speciality: {
    id: number;
    name: string;
    nameRu: string;
    nameEn: string;
    competencyRequired: boolean;
    mouCategory: number;
    competencyType: number;
  };
}

export interface EventFeedback {
  id: number;
  userName: string;
  feedback: string;
  name: string;
  date: string;
  contractorId: number;
  trainingCenterId: number;
}

export interface EventDetails {
  id: number;
  name: string;
  status: EventStatus;
  type: number;
  trainingCenterId: number;

  academicHours: number;
  csrPhase: number;

  dateTimeStart: string;
  dateTimeEnd: string;

  specialityId: number;

  standard: string;
  competencyLevel: number;
  customCompetencyLevel: number;
  languageCode: number;
  description: string;

  resultFileLink: string;
  createdById: number;

  capacityMin: number;
  capacityMax: number;
  capacityAvailable: number;
  capacityBusy: number;
  instructorId: number;
  hoursPerDay: number;
  daysOff: string[];
  cancellationReason: string;
  isCommissioning: boolean;

  trainingCenter: {
    id: number;
    name: string;
    address: string;
    cityId: number;

    city: {
      id: number;
      nameEn: string;
      nameRu: string;
      name: string;
    };
  };

  location: Location;

  instructor?: {
    id: number;
    firstName: string;
    lastName: string;
  };

  speciality: {
    id: number;
    name: string;
    nameRu: string;
    nameEn: string;
    competencyRequired: boolean;
    mouCategory: number;
    competencyType: number;
  };

  files?: {
    id: number;
    name: string;
    uniqueFileName: string;
  }[];

  subcontractors: {
    id: number;
    name: string;
    type: number;
    bin: string;
    phone: string;
    supervisorEmail: string;
  }[];

  capacityMinNotifications: {
    id: number;
    eventId: number;
    role: number;
    dateNotify: string;
  }[];

  feedbacks: EventFeedback[];

  createdBy: {
    id: number;
    createdAt: string;
    updatedAt: string;
    email: string;
    firstName: string;
    lastName: string;
    fullname: string;
    phoneNumber: string;
    position: string;
    passwordUpdatedDate: string;
    invitationAccepted: boolean;
    status: number;
  };
  contractors: {
    id: number;
    name: string;
    type: number;
    bin: string;
    phone: string;
    supervisorEmail: string;
  }[];
}

export interface CreateEventPayload {
  type?: EventType;
  name?: string;
  capacityMax?: number;
  capacityMin?: number;
  dateTimeStart?: string;
  dateTimeEnd?: string;
  description?: string;
  hoursPerDay?: number;
  specialityId?: number;
  daysOff?: string[];
  academicHours?: number;
  standard?: string;
  applicableContractors?: number[];
  competencyLevel?: number;
  csrPhase?: CsrPhase;
  customCompetencyLevel?: number;
  languageCode?: LanguageCode;
  isCommissioning?: boolean;
  files?: File[];
  location?: string;

  capacityMinNotificationDate?: string;
  capacityMinNotificationEntities?: number[];

  [key: string]: any;
}

export interface EditEventPayload {
  name?: string;
  capacityMax?: number;
  capacityMin?: number;
  dateTimeStart?: string;
  dateTimeEnd?: string;
  description?: string;
  hoursPerDay?: number;
  specialityId?: number;
  daysOff?: string[];
  standard?: string;
  applicableContractors?: number[];
  competencyLevel?: number;
  csrPhase?: CsrPhase;
  customCompetencyLevel?: number;
  languageCode?: LanguageCode;
  isCommissioning?: boolean;
  academicHours?: number;
  location?: string;

  [key: string]: any;
}

export interface AdminEditEventPayload {
  name?: string;
  capacityMax?: number | null | "";
  academicHours?: number | null | "";
  languageCode?: LanguageCode;
  standard?: string;
  csrPhase?: CsrPhase;
  description?: string;
  cityId?: number;

  dateTimeStart?: string;
  dateTimeEnd?: string;
  daysOff?: string[];
  hoursPerDay?: number;
}

export interface CancelEventPayload {
  cancellationReason: string;
}

export interface SetSubcontractorToEventPayload {
  contractorId: number;
  eventId: number;
  subcontractorIds: number[];
}

export interface EventEmployeeDetails {
  employeeGradeId: number;
  eventId: number;
  employeeId: number;
  contractNumber: string;
  firstName: string;
  lastName: string;
  firstNameCyrillic: string;
  lastNameCyrillic: string;
  contractorName: string;
  isPassed: number;
  theoreticalGrade: number;
  theoreticalStatus: number;
  practicalGrade: number;
  practicalStatus: number;
  failReasonType: number;
  failReason: string;
  competencyLevel: number;
  recommendation: number;
  attendanceTotal: number;
  status: number;
  iin: string;
  evaluatedAt: string;
  excludedAt: string;
}

export interface NonCommissioningEventGrade {
  employeeId: number;
  isPassed?: number;
  theoreticalGrade?: number;
  practicalGrade?: number;
  failReasonType?: number;
  failReason?: string;
}

export interface CommissioningEventGrade {
  employeeId: number;
  isPassed?: number;
  competencyLevel?: number;
  theoreticalGrade?: number;
  theoreticalStatus?: number;
  practicalGrade?: number;
  failReasonType?: number;
  failReason?: string;
}

export enum EventEmployeeStatus {
  REGISTERED = 1,
  DEREGISTERED = 2,
  REJECTED = 3,
  EXCLUDED = 4
}

export interface EventAttendanceDay {
  hours: number;
  date: string;
}
export interface EventAttendance {
  employeeId: number;
  days: EventAttendanceDay[];
}

export interface FormattedEventAttendance {
  employeeId: number;
  days: { hours: number; date: Moment }[];
}

export interface EventAttendancePayload {
  employeeId: number;
  days: EventAttendanceDay[];
}

export interface PreMatureEvaluationPayload {
  attendance: EventAttendanceDay[];
  employeeId: number;
  eventId: number;
  trainingCenterId: number;
  isEventOJT: boolean;
  evaluatedDate: string;
  instructorId: number;
  theoreticalGrade?: number;
  practicalGrade?: number;
  isPassed: EventEmployeeCompletionType;
  failReasonType?: FailReasonType;
  failReason?: string;
}

export interface ExcludeEmployeePayload {
  employeeId: number;
  eventId: number;
  trainingCenterId: number;
  daysOff: string;
  endDate: string;
  attendances: EventAttendance;
  exclusionReasonType: ExclusionReasonType;
  exclusionReason: string;
}

export enum EventEmployeeCompletionType {
  INCOMPLETED = 1,
  COMPLETED = 2
}

export enum ExclusionReasonType {
  NO_SHOW = 1,
  UNDER_THE_INFLUENCE = 2,
  HAD_REFUSED = 3,
  MID_TERM_ASSESSMENT = 4,
  OTHER = 5
}

export enum FailReasonType {
  UNDEFINED = 0,
  NO_SHOW = 1,
  ATTENDANCE = 2,
  UNDER_THE_INFLUENCE = 3,
  PERFORMANCE = 4,
  OTHER = 5,
  REQUIRES_TRAINING = 6,
  NO_PRACTICE = 7
}

export enum EventEmployeeGradeType {
  UNDEFINED = 0,
  FAILED = 1,
  PASSED = 2,
  BASIC = 3,
  INTERMEDIATE = 4,
  ADVANCED = 5,
  COMPETENT = 6,
  PROFICIENT = 7,
  HELPER = 8,
  TRAINEE_TECHNICIAN = 20,
  JUNIOR_TECHNICIAN = 22,
  TECHNICIAN = 24,
  SENIOR_TECHNICIAN = 26
}

export enum CommissioningEventRecommendation {
  UNDEFINED = 0,
  NOT_READY_FOR_UPSKILLING = 1,
  UPSKILLING_TO_JUNIOR_TECHNICIAN = 2,
  UPSKILLING_TO_TECHNICIAN = 3,
  UPSKILLING_TO_SENIOR_TECHNICIAN = 4,
  COMPLETED_NOT_NEED_FOR_UPSKILLING = 5,
  PENDING = 6
}
