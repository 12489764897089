import axios from "axios";

import {
  CraftProfilePaginated,
  CraftProfilesListFilter,
  CraftProfile,
  CraftProfileCard,
  CreateProfilePayloadAdmin,
  CraftProfilesFormSettingsAdmin,
  EditProfilePayloadAdmin
} from "@models/craftProfiles";
import { ContractorEmployees } from "@models/employees";

interface HireCraftProfileProps {
  craftProfileId: string;
  contractorId: number;
}

const getAllCraftProfiles = (
  filters: CraftProfilesListFilter & { page: number; pageSize: number }
) =>
  axios.get<CraftProfilePaginated<CraftProfile>>("/api/hr-profile/all", {
    params: filters
  });

const getCraftProfileById = (id: string) =>
  axios.get<CraftProfileCard>(`/api/hr-profile/employee-profile/${id}`);

const getProfilePhotoById = (id: string) =>
  axios.get<Blob>(`/api/hr-profile/employee-profile/${id}/photo`, {
    responseType: "blob"
  });

const hireCraftProfile = ({
  craftProfileId,
  contractorId
}: HireCraftProfileProps) =>
  axios.put(`/api/hr-profile/hiring/profile/${craftProfileId}`, null, {
    params: {
      contractorId
    }
  });

const fireCraftProfile = (id: string) =>
  axios.delete(`/api/hr-profile/hiring/profile/${id}`);

const searchCraftProfile = (iin: string) =>
  axios.get<ContractorEmployees>("/api/hr-profile", {
    params: iin
  });

const createCraftProfile = ({
  contractorEmployeeId,
  payload
}: CreateProfilePayloadAdmin) => {
  const form = new FormData();

  Object.keys(payload).forEach((key) => {
    if (key === "languages") {
      payload.languages.forEach((language) => {
        form.append("languages[]", String(language));
      });
    } else {
      form.append(
        key,
        payload[key as keyof CraftProfilesFormSettingsAdmin] as string
      );
    }
  });

  return axios.post(
    `/api/hr-profile/contractor-employee/${contractorEmployeeId}`,
    form
  );
};

const editCraftProfile = ({ profileId, payload }: EditProfilePayloadAdmin) => {
  const form = new FormData();

  Object.keys(payload).forEach((key) => {
    if (key === "languages") {
      payload.languages.forEach((language) => {
        form.append("languages[]", String(language));
      });
    } else {
      form.append(
        key,
        payload[key as keyof CraftProfilesFormSettingsAdmin] as string
      );
    }
  });

  return axios.put(`/api/hr-profile/profile/${profileId}`, form);
};

const deleteCraftProfile = (profileId: number) =>
  axios.delete(`/api/hr-profile/profile/${profileId}`);

export const craftProfilesApi = {
  getCraftProfileById,
  getProfilePhotoById,
  hireCraftProfile,
  fireCraftProfile,
  getAllCraftProfiles,
  searchCraftProfile,
  createCraftProfile,
  editCraftProfile,
  deleteCraftProfile
};
