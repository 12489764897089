export const enCraftProfiles = {
  fullName: "Full name",
  filters: {
    iin: "IIN",
    disciplines: "Disciplines",
    selectDisciplines: "Select disciplines",
    trainingCenters: "Training Centers",
    selectTrainingCenters: "Select training centers",
    contractors: "Contractors",
    clearFilters: "Clear filters",
    csrContractors: "CSR Contractors",
    csrPhase: "CSR Phase",
    csrPhases: "CSR Phases",
    sortBy: "Sort",
    phase: "Phase {{no}}",
    employed: "Employed",
    unemployed: "Unemployed",
    status: "Employment status"
  },

  downloadProfilesData: "Download Profiles Data",

  profile: "Profile",
  profiles: "Profiles",
  actions: "Actions",

  hire: "Hire",
  hireAction: "Hire",
  hireConfirmation: "Are you sure you want to hire {{name}}?",
  hireSuccess: "{{name}} was successfully hired",

  fire: "Fire",
  fireAction: "Fire",
  fireConfirmation: "Are you sure you want to fire {{name}}?",
  fireSuccess: "{{name}} was successfully fired",

  name: "Name",
  csr: "CSR Contractor",
  discipline: "Discipline",
  address: "Home address",
  email: "Email",
  number: "Tel. number",
  dateOfBirth: "Date of birth",
  martialStatus: "Marital status",
  education: "Education",
  additionalEducation: "Additional education",
  workExperience: "Work experience",
  language: "Language",
  languages: "Languages",
  personalQualities: "Personal qualities",
  additionalInformation: "Additional information",
  status: "Status",
  employed: "Employed",
  unEmployed: "Unemployed",
  cancelHire: "Cancel hire",
  cancelHireText:
    "Are you sure you want to change profile status to 'unemployed'?",
  unemployed: "Unemployed",
  notIndicated: "not indicated",
  additonalInformation: "Additonal information",

  csrContractor: "CSR Contractor",
  csrPhase: "CSR Phase",
  sortBy: "Sort",
  phase: "Phase {{no}}",
  otherContractors: "Other",

  marital: {
    0: "-",
    1: "Married",
    2: "Single",
    3: "Widower/Widow",
    4: "Divorced"
  },

  professionNA: "Profession is not indicated",
  employerNA: "Employer is not indicated",

  createProfile: "Create Profile",
  createProfileSuccess: "Profile was successfully created",

  editProfile: "Edit Profile",
  editCraftProfileSuccess: "Profile was successfully edited",

  deleteProfile: "Delete Profile",
  deleteProfileConfirm: "Are you sure you want to delete profile of {{name}}?",

  findEmployee: "Find Craft Employee",
  fillProfileInfo: "Fill in Profile Information",

  uploadImage: "Profile picture",
  clickToUpload: "Upload",

  maritalStatusOptions: {
    1: "Married",
    2: "Single",
    3: "Widower/Widow",
    4: "Divorced"
  },

  csrContractorOptions: {
    1: "CSR Atyrau",
    2: "CSR Aktau",
    3: "Demob"
  },

  employmentStatusOptions: {
    1: "Employed",
    2: "Unemployed"
  },

  searchByIinSuccess: "Craft profile was found by indicated IIN",

  knownLanguagesOptions: {
    1: "Russian",
    2: "English",
    3: "Kazakh",
    4: "Chinese",
    5: "French",
    6: "German",
    7: "Italian",
    8: "Turkish"
  },

  allProfiles: "All Profiles",
  hiredProfiles: "Hired Profiles"
};
