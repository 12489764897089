import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Space, Typography } from "antd";
import { PermissionCheckboxGroup } from "@components/common/PermissionCheckboxGroup";

import { Member } from "@models/member";

import { SUBCONTRACTOR_PERMISSION_OPTION_LIST } from "@constants/subcontractorPermissionList";

interface FormValues {
  firstName: string;
  lastName: string;
  position: string;

  permissions: number[];
}

interface Props {
  subcontractorMember?: Member;

  errors?: { [key: string]: string };

  isLoading?: boolean;

  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

export const EditSubcontractorMemberForm = (props: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      position: "",

      permissions: []
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  useEffect(() => {
    if (props.subcontractorMember) {
      formik.setValues({
        firstName: props.subcontractorMember.firstName,
        lastName: props.subcontractorMember.lastName,
        position: props.subcontractorMember.position,
        permissions: props.subcontractorMember.permissions.map(
          (p) => p.permission
        )
      });
    } else {
      formik.resetForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subcontractorMember]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label={t("members.firstName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.firstName && "error"}
            help={formik.errors.firstName}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("members.lastName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.lastName && "error"}
            help={formik.errors.lastName}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("members.position")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.position && "error"}
            help={formik.errors.position}
          >
            <Input
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={
              <Typography.Title level={5}>
                {t("subcontractors.accessLevel")}
              </Typography.Title>
            }
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.permissions && "error"}
            help={formik.errors.permissions}
          >
            <PermissionCheckboxGroup
              options={SUBCONTRACTOR_PERMISSION_OPTION_LIST}
              value={formik.values.permissions}
              onChange={(v) => formik.setFieldValue("permissions", v)}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <br />
          <br />
          <br />

          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("edit")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
