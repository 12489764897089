import { SpecialityCategory } from "@models/discipline";

export const enDisciplines = {
  [SpecialityCategory.Undefined]: "Uncategorized level",
  [SpecialityCategory.GeneralCivilWorks]: "General Civil Works",
  [SpecialityCategory.CraneOperator]: "Crane Operator",
  [SpecialityCategory.DriverHeavyDutyVehicle]: "Driver: Heavy Duty Vehicle",
  [SpecialityCategory.BoltFlangeJointAssembly]: "Bolt Flange Joint Assembly",
  [SpecialityCategory.EquipmentOperatorLightEquipment]:
    "Equipment Operator: Light Equipment",
  [SpecialityCategory.EquipmentOperatorMediumEquipment]:
    "Equipment Operator: Medium Equipment",
  [SpecialityCategory.EquipmentOperatorHeavyEquipment]:
    "Equipment Operator: Heavy Equipment",
  [SpecialityCategory.EXAwarenessAndCableGlanding]:
    "EX: Awareness and Cable Glanding",
  [SpecialityCategory.FieldSupervisor]: "Field Supervisor",
  [SpecialityCategory.PainterIndustrialCoatingsInstaller]:
    "Painter/ Industrial Coatings Installer",
  [SpecialityCategory.Rigger]: "Rigger",

  discipline: "Discipline",
  disciplines: "Disciplines",
  createDiscipline: "Create Discipline",
  disciplineNameEn: "Discipline Name (EN)",
  disciplineNameRu: "Discipline Name (RU)",

  category: "Category",
  eventType: "Event Type",
  competencyLevel: "Competency Level",

  competency: {
    standard: "Standard",
    proficient: "Proficient, failed",
    competent: "Competent, failed",
    technicians:
      "Trainee Technician, Junior Technician, Technician, Senior Technician"
  },

  speciality: {
    noCompetencyLevel: "Uncategorized level",
    boltFlange: "Bolt Flange Joint Assembly",
    generalCivilWorks: "General civil works",
    craneOperator: "Crane Operator",
    heavyDutyVehicle: "Driver: Heavy Duty Vehicle",
    lightEquipment: "Equipment Operator: Light Equipment",
    mediumEquipment: "Equipment Operator: Medium Equipment",
    heavyEquipment: "Equipment Operator: Heavy Equipment",
    cableGlanding: "EX: Awareness and Cable Glanding",
    fieldSupervisor: "Field Supervisor",
    painter: "Painter/ Industrial Coatings Installer",
    rigger: "Rigger"
  },

  disciplineAddedSuccess: "Discipline was successfully added",

  commissioning: "Commissioning",
  nonComissioning: "Non-Commissioning"
};
