import { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { notification, Table } from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import DisciplinesTable from "@components/admin/disciplines/DisciplinesTable";
import EditDisciplineModal from "@components/admin/disciplines/modals/EditDisciplineModal";
import CreateDisciplineModal from "@components/admin/disciplines/modals/CreateDisciplineModal";

import {
  Discipline,
  CreateDisciplinePayload,
  EditDisciplinePayload
} from "@models/discipline";
import { PERMISSIONS } from "@models/permissions";

import { disciplineApi } from "@api/disciplineApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";
import { UserContext } from "@contexts/UserContext";

const DisciplinesListPage: React.FC = () => {
  const [t] = useTranslation();
  const { checkPermission } = useContext(UserContext);

  const [isEditDisciplineOpen, setEditDisciplineOpen] =
    useState<boolean>(false);
  const [isCreateDisciplineOpen, setCreateDisciplineOpen] =
    useState<boolean>(false);

  const [currentDiscipline, setCurrentDiscipline] = useState<Discipline>();

  const pageTitle = t("disciplines.disciplines");

  const hasDisciplineUpdatePermission = useMemo(
    () => checkPermission(PERMISSIONS.SPECIALITY_UPDATE),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const disciplinesList = useQuery(
    "disciplines-list",
    disciplineApi.getAllDisciplines
  );

  const createDiscipline = useValidatedMutation({
    mutationFunction: (values: CreateDisciplinePayload) =>
      disciplineApi.createDiscipline(values),
    onSuccess: () => {
      notification.success({
        message: t("disciplines.disciplineAddedSuccess")
      });
      disciplinesList.refetch();
      setCreateDisciplineOpen(false);
    },
    onError: () =>
      notification.error({
        message: t("error.errorOccured")
      })
  });

  const createDisciplineErrors = useMemo(
    () => transformValidationError(createDiscipline.error),
    [createDiscipline.error]
  );

  const editDiscipline = useValidatedMutation({
    mutationFunction: ({
      values,
      id
    }: {
      values: EditDisciplinePayload;
      id: number;
    }) => disciplineApi.editDiscipline(values, id),
    onSuccess: () => {
      notification.success({
        message: t("disciplines.disciplineAddedSuccess")
      });
      disciplinesList.refetch();
      setEditDisciplineOpen(false);
    },
    onError: () =>
      notification.error({
        message: t("error.errorOccured")
      })
  });

  const editDisciplineErrors = useMemo(
    () => transformValidationError(editDiscipline.error),
    [editDiscipline.error]
  );

  const handleEditModalOpen = (discipline: Discipline) => {
    setCurrentDiscipline(discipline);
    setEditDisciplineOpen(true);
  };

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={t("disciplines.disciplines")}
        subTitle={t("totalCount", {
          total: disciplinesList.data ? disciplinesList.data?.data.length : 0
        })}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "/" },
          {
            breadcrumbName: t("disciplines.disciplines"),
            path: "admin/disciplines"
          }
        ]}
        extra={
          hasDisciplineUpdatePermission && (
            <CreateDisciplineModal
              isModalOpen={isCreateDisciplineOpen}
              handleOpenModal={() => setCreateDisciplineOpen(true)}
              handleCloseModal={() => setCreateDisciplineOpen(false)}
              createDiscipline={createDiscipline.mutate}
              errors={createDisciplineErrors}
              isLoading={createDiscipline.isLoading}
            />
          )
        }
      />
      {disciplinesList.data ? (
        <>
          <DisciplinesTable
            disciplinesList={disciplinesList.data.data}
            handleOpenModal={handleEditModalOpen}
          />
          {currentDiscipline && (
            <EditDisciplineModal
              isModalVisible={isEditDisciplineOpen}
              currentDiscipline={currentDiscipline}
              handleCloseModal={() => setEditDisciplineOpen(false)}
              editDiscipline={editDiscipline.mutate}
              errors={editDisciplineErrors}
              isLoading={editDiscipline.isLoading}
            />
          )}
        </>
      ) : (
        <Table
          dataSource={[]}
          loading={disciplinesList.isLoading}
          locale={{
            emptyText: disciplinesList.isLoading ? "" : t("error.errorOccured")
          }}
        />
      )}
    </>
  );
};

export default DisciplinesListPage;
