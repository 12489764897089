import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

import qs from "qs";
import Modal from "antd/lib/modal/Modal";

import { PerformanceReportFilterForm } from "@components/common/reports/PerformanceReportFilterForm";
import { PerformanceReport } from "@components/common/reports/PerformanceReport";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import PageMeta from "@components/PageMeta";

import { EventType } from "@models/event";

import { locationApi } from "@api/locationApi";
import { disciplineApi } from "@api/disciplineApi";
import { contractorsApi } from "@api/contractorsApi";
import { countryCityApi } from "@api/countryCityApi";
import { reportsApi } from "@api/reportsApi";

import { useDefaultQuery } from "@hooks/useDefaultQuery";
import { UserContext } from "@contexts/UserContext";
import { useQueryParams } from "@hooks/useQueryParams";

export const PerformanceReportPage: React.FC = () => {
  const { userEntityId: contractorId } = useContext(UserContext);

  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const history = useHistory();

  const { type: initialEventType, ...initialFilterData } = queryParams;

  const [activeEventType, setActiveEventType] = useState<EventType>(
    Number(initialEventType) || EventType.ASSESSMENT
  );

  const [filterData, setFilterData] = useState<{}>(initialFilterData);

  const [isFilterModalShown, setIsFilterModalShown] = useState(false);

  const { data, isLoading } = useQuery(
    ["getAdminCommissioningReport", activeEventType, filterData],
    () =>
      reportsApi
        .getContractorPerformanceReport(
          contractorId,
          activeEventType,
          filterData
        )
        .then((res) => res.data),
    {
      onSuccess() {
        history.push({
          search: qs.stringify(
            { ...filterData, type: activeEventType },
            { arrayFormat: "indices" }
          )
        });
        setIsFilterModalShown(false);
      }
    }
  );

  const disciplines = useDefaultQuery("getAllDisciplines", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const trainingCenters = useDefaultQuery("getTcs", () =>
    contractorsApi.getTrainingCenters(contractorId).then((res) => res.data)
  );

  const countries = useDefaultQuery("getAllCountries", () =>
    countryCityApi.getAllCountries().then((res) => res.data)
  );

  const locations = useDefaultQuery("getAllLocations", () =>
    locationApi.getLocations().then((res) => res.data)
  );

  const pageTitle = "Performance Report";

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: "/admin/reports/performance"
          }
        ]}
      />
      <Modal
        visible={isFilterModalShown}
        destroyOnClose
        title={t("Filter")}
        maskClosable={false}
        width={600}
        footer={null}
        onCancel={() => {
          setIsFilterModalShown(false);
        }}
      >
        <PerformanceReportFilterForm
          initialFilter={initialFilterData}
          eventType={activeEventType}
          disciplines={disciplines.data || []}
          trainingCenters={trainingCenters.data || []}
          contractors={[]}
          countries={countries.data || []}
          locations={locations.data || []}
          isLoading={isLoading}
          onSubmit={(filters) => {
            setFilterData(filters);
          }}
        />
      </Modal>
      <PerformanceReport
        report={data}
        isLoading={isLoading}
        activeEventType={activeEventType}
        onEventTypeChange={(eventType: EventType) => {
          setFilterData({});
          setActiveEventType(eventType);
        }}
        onFilterClick={() => setIsFilterModalShown(true)}
      />
    </>
  );
};
