import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Row } from "antd";
import SelectProfileByIinTable from "@components/common/craftProfiles/SelectProfileByIinTable";

import { ContractorEmployees } from "@models/employees";

import { craftProfilesApi } from "@api/craftProfilesApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

interface Props {
  selectedProfile: ContractorEmployees | null;
  goToNextStep: () => void;
  setSelectedProfile: (profile: ContractorEmployees | null) => void;
  handleCloseModal: () => void;
  isLoading?: boolean;
}

interface InitialFormProps {
  iin: string;
}

const FindCraftViaIinForm: React.FC<Props> = ({
  goToNextStep,
  selectedProfile,
  setSelectedProfile,
  handleCloseModal,
  isLoading
}) => {
  const [t] = useTranslation();

  const searchByIIN = useValidatedMutation({
    mutationFunction: (value: string) =>
      craftProfilesApi.searchCraftProfile(value).then((res) => res.data),
    onSuccess: () =>
      notification.success({ message: t("craftProfiles.searchByIinSuccess") }),
    onError: () => notification.error({ message: t("error.errorOccured") })
  });

  const searchByIINErrors = useMemo(
    () => transformValidationError(searchByIIN.error),
    [searchByIIN.error]
  );

  const formik = useFormik<InitialFormProps>({
    initialValues: {
      iin: ""
    },
    onSubmit: (v) => searchByIIN.mutate(v)
  });

  useEffect(() => {
    formik.setErrors(searchByIINErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByIINErrors]);

  return (
    <Form onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            style={{ marginBottom: "24px" }}
            label={t("iin")}
            validateStatus={formik.errors.iin && "error"}
            help={formik.errors.iin}
          >
            <Input
              name="iin"
              placeholder="123123123123"
              value={formik.values.iin}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Button
            icon={<SearchOutlined />}
            type="primary"
            htmlType="submit"
            loading={searchByIIN.isLoading}
            onClick={() => setSelectedProfile(null)}
          >
            {t("events.searchByIIN")}
          </Button>
        </Col>
      </Row>
      {searchByIIN?.data && (
        <Row style={{ marginBottom: "24px" }}>
          <Col span={24}>
            <SelectProfileByIinTable
              isLoading={searchByIIN.isLoading}
              foundProfile={searchByIIN?.data || []}
              setSelectedProfile={setSelectedProfile}
            />
          </Col>
        </Row>
      )}
      <Row justify="space-between">
        <Col>
          <Button htmlType="button" onClick={handleCloseModal}>
            {t("cancel")}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            loading={isLoading}
            disabled={selectedProfile === null}
            onClick={goToNextStep}
          >
            {t("next")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FindCraftViaIinForm;
