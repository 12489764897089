import { EventStatus } from "@models/event";

export const EVENT_STATUS_COLOR_MAP = {
  [EventStatus.PENDING]: "#049aff",
  [EventStatus.INPROGRESS]: "#EBC341",
  [EventStatus.EVALUATED]: "#78909C",
  [EventStatus.COMPLETED]: "#3DC674",
  [EventStatus.CANCELLED]: "#ff4d4f",
  [EventStatus.ACTIVATED]: "#3DC674"
};
