export const ruCtWhatNew = [
  {
    title:
      "Новая функция: Профили кандидатов. \n" +
      "\n" +
      "Доступно для ТШО, подрядчиков, субподрядчиков и пользователей учебного центра.\n",
    html: `<p><span style="font-weight: 400;">Теперь есть возможность просматривать, &laquo;нанимать&raquo;, &laquo;увольнять&raquo;, скачивать профили местных безработных, выпускников событий Учебных центров в рамках программы КСО ТШО.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">На вкладке &laquo;Профиль&raquo; отображаются профили людей, ищущих работу, которых пользователи подрядчиков/ субподрядчиков могут просмотреть и нанять на работу.</span></p>`
  },
  {
    title:
      "Новая функция: изменения в функции оценки событий, включая реализацию многоэтапной системы оценивания событий: Активация.",
    html: `<p><span style="font-weight: 400;">Функция активации события позволяет пользователям Тренинг центра отдельно добавить оценки по практической части для работников после активации события пользователем ТШО.</span></p>
<p><span style="font-weight: 400;">При этом, статус события изменяется с &laquo;Завершено&raquo; или &laquo;Оценено&raquo; на &laquo;Активировано&raquo;.</span></p>`
  },
  {
    title:
      "Изменения в квалификационных требованиях: отменено правило 1 месяца для работников для повторного участия в мероприятии",
    html: ``
  },
  {
    title: "",
    html: `<p><span style="font-weight: 400;">С этим изменением работникам не нужно ждать истечения одного месяца, чтобы повторно подать заявку на участие в том же типе и дисциплине мероприятия.</span></p>`
  },
  {
    title:
      "Новая функция: добавлено поле «локация» для всех типов событий: в отчетах, файле дамп данных, фильтрах отчетов.",
    html: `<p><span style="font-weight: 400;">Теперь пользователи могут указать место проведения мероприятия, в том числе &ldquo;Онлайн&rdquo; и &ldquo;Тенгиз&rdquo;. Не путайте с городом учебного центра.</span></p>`
  },
  {
    title: "Изменения в модуле отчетности и дампа данных",
    html: `<p><span style="font-weight: 400;">В отчеты и дамп данных добавлены новые функции и другие небольшие изменения.</span></p>`
  }
];
