import { enCommon } from "./common";
import { enLogin } from "./login";
import { enMembers } from "./members";
import { enMeta } from "./meta";
import { enPermissions } from "./permissions";
import { enProfile } from "./profile";
import { enReports } from "./reports";
import { enResetPassword } from "./resetPassword";
import { enSubcontractors } from "./subcontractors";
import { enTrainingCenters } from "./trainingCenters";
import { enContractors } from "./contractors";
import { enEmployees } from "./employees";
import { enValidation } from "./validation";
import { enMouCategory } from "./mouCategory";
import { enRequests } from "./requests";
import { enContract } from "./contract";
import { enEvents } from "./events";
import { enCertificates } from "./certificates";
import { enAcceptInvite } from "./acceptInvite";
import { enDisciplines } from "./disciplines";
import { enMigrations } from "./migrations";
import { enCraftProfiles } from "./craftProfiles";
import { enCompetencyLevels } from "./competencyLevels";
import { enUserGuide } from "./userGuide";
import { enContacts } from "./contacts";

export const enTranslation = {
  translation: {
    ...enCommon,

    meta: enMeta,
    reports: enReports,
    members: enMembers,
    trainingCenters: enTrainingCenters,
    profile: enProfile,
    login: enLogin,
    resetPassword: enResetPassword,
    acceptInvite: enAcceptInvite,
    permissions: enPermissions,
    subcontractors: enSubcontractors,
    contractors: enContractors,
    contract: enContract,
    employees: enEmployees,
    validation: enValidation,
    mouCategory: enMouCategory,
    requests: enRequests,
    events: enEvents,
    certificates: enCertificates,
    disciplines: enDisciplines,
    migrations: enMigrations,
    craftProfiles: enCraftProfiles,
    competencyLevels: enCompetencyLevels,
    userGuide: enUserGuide,
    contacts: enContacts
  }
};
