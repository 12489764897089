import React, { ChangeEvent, useRef } from "react";
import ReactInputMask from "react-input-mask";

import { Input } from "antd";

interface Props {
  name: string;
  maskChar?: string;
  value: string;
  onChange: (event: ChangeEvent) => void;
  onBlur?: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  mask?: string;
}

const getMask = (val: string) => {
  const temp = val.slice(0, 1);
  if (temp && temp === "8") {
    return "8 999 999 99 99";
  } else {
    return "+7 999 999 99 99";
  }
};

const PhoneInput: React.FC<Props> = (props) => {
  const inputRef = useRef(null);

  return (
    <ReactInputMask {...props} mask={props.mask || getMask(props.value)}>
      <Input
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        ref={inputRef}
        onBlur={props.onBlur}
      />
    </ReactInputMask>
  );
};

export default PhoneInput;
