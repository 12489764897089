import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

import { Card, Col, Row, Space, Typography } from "antd";

import {
  EventEmployeeStatus,
  EventEmployeeDetails,
  EventDetails,
  EventType
} from "@models/event";

interface Props {
  eventDetails?: EventDetails;
  employeeDetails: EventEmployeeDetails;
  renderExtraAction?: () => React.ReactNode;
}

export const EventEmployeeCard: FC<Props> = (props) => {
  const [t] = useTranslation();

  const ed = props.employeeDetails;
  const showPracticalStatus =
    props.eventDetails?.isCommissioning &&
    props.eventDetails?.type === EventType.TRAINING;

  const statusColor = useMemo(() => {
    switch (props.employeeDetails.status) {
      case EventEmployeeStatus.REJECTED:
        return "red";
      case EventEmployeeStatus.EXCLUDED:
        return "red";
      case EventEmployeeStatus.DEREGISTERED:
        return "red";
      default:
        return "blue";
    }
  }, [props.employeeDetails.status]);
  return (
    <Card>
      <Row gutter={8}>
        <Col md={16}>
          <Row>
            <Col md={6}>
              <Typography.Text strong>
                {ed.firstName}
                <br />
                {ed.lastName}
              </Typography.Text>
            </Col>

            <Col md={5}>
              <Typography.Text type="secondary">
                {t("employees.iin")}
              </Typography.Text>
              <br />
              <Typography.Text strong>{ed.iin}</Typography.Text>
            </Col>

            <Col md={6}>
              <Typography.Text type="secondary">
                {t("employees.contractor")}
              </Typography.Text>
              <br />
              <Typography.Text strong>{ed.contractorName}</Typography.Text>
            </Col>

            <Col md={4}>
              <Typography.Text type="secondary">
                {t("events.overallGrade")}
              </Typography.Text>
              <br />
              <Typography.Text strong>
                {ed.isPassed ? t(`events.grade.${ed.isPassed}`) : "-"}
              </Typography.Text>
            </Col>
          </Row>
        </Col>

        <Col xs={8}>
          <Row>
            <Col xs={7}>
              <Typography.Text type="secondary">
                {t("events.theoreticalGrade")}
              </Typography.Text>
              <br />
              <Typography.Text strong>
                {ed.theoreticalGrade || "-"}
              </Typography.Text>
            </Col>

            {showPracticalStatus ? (
              <Col xs={7}>
                <Typography.Text type="secondary">
                  {t("events.practicalStatus")}
                </Typography.Text>
                <br />
                <Typography.Text strong>
                  {t(`events.gradeStatus.${ed.practicalStatus}`)}
                </Typography.Text>
              </Col>
            ) : (
              <Col xs={7}>
                <Typography.Text type="secondary">
                  {t("events.practicalGrade")}
                </Typography.Text>
                <br />
                <Typography.Text strong>
                  {ed.practicalGrade || "-"}
                </Typography.Text>
              </Col>
            )}

            <Col xs={10}>
              <Space
                direction="vertical"
                align="center"
                style={{ width: "100%" }}
              >
                <Typography.Text style={{ color: statusColor }}>
                  {t(`events.employeeStatus.${props.employeeDetails.status}`)}
                </Typography.Text>

                {props.renderExtraAction && props.renderExtraAction()}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
