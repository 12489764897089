import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import styled from "styled-components";

import { Button, Col, Form, Input, Row, Space, Typography } from "antd";
import { PermissionCheckboxGroup } from "@components/common/PermissionCheckboxGroup";
import CountrySelector from "@components/ui/CountrySelector";
import CitySelector from "@components/ui/CitySelector";
import PhoneInput from "@components/ui/PhoneInput";

import { Country } from "@models/countryCity";
import { Contractor, FormValues } from "@models/contractor";

import {
  CONTRACTOR_OWNER_PERMISSION_DEFAULT_LIST,
  CONTRACTOR_OWNER_PERMISSION_OPTION_LIST
} from "@constants/contractorPermissions";

const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

interface Props {
  contractor?: Contractor;

  errors?: { [key: string]: string };

  isLoading?: boolean;

  countries: Country[];

  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

const ContractorsForm = (props: Props) => {
  const [selectedCountry, setSelectedCountry] = useState<number | undefined>(
    undefined
  );
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      address: "",
      bin: "",
      phone: "",
      supervisorEmail: "",
      ownerFirstName: "",
      ownerLastName: "",
      ownerPosition: "",
      ownerEmail: "",
      ownerPhone: "",
      ownerPermissions: []
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit({
          ...values,
          ownerPermissions: Array.from(
            new Set(
              values.ownerPermissions.concat(
                CONTRACTOR_OWNER_PERMISSION_DEFAULT_LIST
              )
            )
          ),
          phone: values.phone.split(" ").join("").replace("+", ""),
          ownerPhone: values.ownerPhone.split(" ").join("").replace("+", "")
        });
      }
    }
  });

  useEffect(() => {
    if (props.contractor) {
      formik.setValues({
        name: props.contractor.name,
        address: "",
        countryId: props.contractor.country.id,
        cityId: props.contractor.city.id,
        bin: props.contractor.bin,
        phone: props.contractor.phone,
        supervisorEmail: props.contractor.supervisorEmail,
        ownerFirstName: "",
        ownerLastName: "",
        ownerPosition: "",
        ownerEmail: "",
        ownerPhone: "",
        ownerPermissions: []
      });
      setSelectedCountry(props.contractor.country.id);
    } else {
      formik.resetForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contractor]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <FormItem>
        <Typography.Text strong>{t("contractors.companyInfo")}</Typography.Text>
      </FormItem>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("contractors.contractorName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.name && "error"}
            help={formik.errors.name}
          >
            <Input
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("workPhoneNumber")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.phone && "error"}
            help={formik.errors.phone}
          >
            <PhoneInput
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              maskChar={" "}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label={t("country")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.countryId && "error"}
            help={formik.errors.countryId}
          >
            <CountrySelector
              countries={props.countries}
              selectedCountry={formik.values.countryId}
              onChange={(value: number) => {
                setSelectedCountry(value);
                formik.setFieldValue("countryId", value);
                formik.setFieldValue("cityId", undefined);
                if (value !== 1) {
                  formik.setFieldValue("bin", null);
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={t("city")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.cityId && "error"}
            help={formik.errors.cityId}
          >
            <CitySelector
              cities={
                props.countries.find((c) => c.id === selectedCountry)?.cities
              }
              selectedCity={formik.values.cityId}
              onChange={(value: number) => {
                formik.setFieldValue("cityId", value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("bin")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.bin && "error"}
            help={formik.errors.bin}
          >
            <Input
              name="bin"
              value={formik.values.bin}
              onChange={formik.handleChange}
              disabled={formik.values.countryId !== 1}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("contractors.supervisorEmail")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.supervisorEmail && "error"}
            help={formik.errors.supervisorEmail}
          >
            <Input
              name="supervisorEmail"
              value={formik.values.supervisorEmail}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("address")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.address && "error"}
            help={formik.errors.address}
          >
            <Input
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <FormItem>
        <Typography.Text strong>{t("members.memberInfo")}</Typography.Text>
      </FormItem>
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label={t("email")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.ownerEmail && "error"}
            help={formik.errors.ownerEmail}
          >
            <Input
              name="ownerEmail"
              value={formik.values.ownerEmail}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={t("phoneNumber")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.ownerPhone && "error"}
            help={formik.errors.ownerPhone}
          >
            <PhoneInput
              name="ownerPhone"
              value={formik.values.ownerPhone}
              onChange={formik.handleChange}
              maskChar={" "}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={8}>
          <Form.Item
            label={t("firstName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.ownerFirstName && "error"}
            help={formik.errors.ownerFirstName}
          >
            <Input
              name="ownerFirstName"
              value={formik.values.ownerFirstName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            label={t("lastName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.ownerLastName && "error"}
            help={formik.errors.ownerLastName}
          >
            <Input
              name="ownerLastName"
              value={formik.values.ownerLastName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            label={t("jobTitle")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.ownerPosition && "error"}
            help={formik.errors.ownerPosition}
          >
            <Input
              name="ownerPosition"
              value={formik.values.ownerPosition}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <br />
          <Typography.Title level={5}>
            {t("members.accessLevel")}
          </Typography.Title>

          <PermissionCheckboxGroup
            options={CONTRACTOR_OWNER_PERMISSION_OPTION_LIST}
            value={formik.values.ownerPermissions}
            onChange={(v) => formik.setFieldValue("ownerPermissions", v)}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <br />
          <br />
          <br />
          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {props.contractor ? t("edit") : t("save")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ContractorsForm;
