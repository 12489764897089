import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { Button, notification, Skeleton, Space } from "antd";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import CraftProfileCardTemplate from "@components/common/craftProfiles/CraftProfileCardTemplate";
import HireCraftProfileModal from "@components/common/craftProfiles/modals/HireCraftProfileModal";

import { craftProfilesApi } from "@api/craftProfilesApi";

import { usePrintRef } from "@hooks/usePrintRef";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { UserContext } from "@contexts/UserContext";

const CraftProfilesCardPage: React.FC = () => {
  const [t] = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { userEntityId } = useContext(UserContext);

  const [isEmployed, setEmployed] = useState<boolean>(false);

  const [isHireProfileModalVisible, setHireProfileModalVisible] =
    useState<boolean>(false);

  const {
    data: profileData,
    refetch: refetchProfile,
    isFetching
  } = useQuery(["craftProfile"], () =>
    craftProfilesApi.getCraftProfileById(id).then((res) => res.data)
  );

  const pageTitle = useMemo(() => {
    return `${t("craftProfiles.profile")}${
      profileData?.fullName ? ` - ${profileData.fullName}` : ""
    }`;
  }, [profileData?.fullName, t]);

  useEffect(() => {
    profileData?.isEmployed
      ? setEmployed(profileData?.isEmployed)
      : setEmployed(false);
  }, [profileData]);

  const hireCraftProfile = useValidatedMutation({
    mutationFunction: () =>
      craftProfilesApi.hireCraftProfile({
        craftProfileId: id,
        contractorId: userEntityId
      }),
    onSuccess: () => {
      notification.success({
        message: t("craftProfiles.hireSuccess", { name: profileData?.fullName })
      });
      refetchProfile();
      setHireProfileModalVisible(false);
    },
    onError: () => {
      notification.error({
        message: t("errors.errorOccured")
      });
    }
  });

  const fireCraftProfile = useValidatedMutation({
    mutationFunction: () => craftProfilesApi.fireCraftProfile(id),
    onSuccess: () => {
      notification.success({
        message: t("craftProfiles.fireSuccess", { name: profileData?.fullName })
      });
      refetchProfile();
      setHireProfileModalVisible(false);
    },
    onError: () => {
      notification.error({
        message: t("errors.errorOccured")
      });
    }
  });

  const { pageRef, handleDownloadReport } = usePrintRef(
    "pageRef",
    profileData?.fullName ? profileData.fullName : "profile"
  );

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: t("craftProfiles.profiles"),
            path: "/contractor/craft-profiles"
          },
          {
            breadcrumbName: pageTitle,
            path: `/contractor/craft-profile/${id}`
          }
        ]}
        extra={
          profileData ? (
            <Space>
              <HireCraftProfileModal
                isModalOpen={isHireProfileModalVisible}
                handleOpenModal={() => setHireProfileModalVisible(true)}
                handleCloseModal={() => setHireProfileModalVisible(false)}
                selectedProfileName={profileData.fullName}
                onSubmit={
                  isEmployed ? fireCraftProfile.mutate : hireCraftProfile.mutate
                }
                isLoading={
                  fireCraftProfile.isLoading || hireCraftProfile.isLoading
                }
                isEmployed={isEmployed}
              />
              <Button
                onClick={handleDownloadReport}
                type="primary"
                icon={<DownloadOutlined />}
              >
                {t("download")}
              </Button>
            </Space>
          ) : (
            <Space>
              <Button danger icon={<EditOutlined />} disabled>
                {t("craftProfiles.hireAction")}
              </Button>
              <Button type="primary" icon={<DownloadOutlined />} disabled>
                {t("download")}
              </Button>
            </Space>
          )
        }
      />
      {profileData ? (
        <div ref={pageRef} id="pageRef" className="profile-card">
          <CraftProfileCardTemplate
            profileData={profileData}
            isProfileDataLoading={isFetching}
            id={id}
          />
        </div>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};
export default CraftProfilesCardPage;
