import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import qs from "qs";

import { CloudDownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space } from "antd";
import { EventStatusCard } from "@components/common/events/EventStatusCard";
import DownloadLink from "@components/common/DownloadLink";
import ReportBarChart from "@components/ui/charts/ReportBarChart";
import ReportBarChartExpandable from "@components/ui/charts/ReportBarChartExpandable";
import { ReportLabeledDoughnutChart } from "@components/ui/charts/ReportLabeledDoughnutChart";
import ReportLineChart from "@components/ui/charts/ReportLineChart";
import { ReportTableBarChart } from "@components/ui/charts/ReportTableBarChart";

import { EmployeeEventStatus, EventType } from "@models/event";
import { PerformanceReportData } from "@models/report";

import {
  getReportByContractor,
  getReportByTrainingCenter,
  getReportByEventStatus,
  getReportByYear,
  getReportByTrainingCenterAcademicHours,
  getReportByCitizenship,
  getReportByFailReason,
  getReportByOverallGrade,
  getReportByContractorAcademicHours,
  getReportByDiscipline,
  getReportByDisciplineAcademicHours,
  getReportByLocation
} from "@utils/reportChartsHelper";
import { usePrintRef } from "@hooks/usePrintRef";

interface Props {
  report?: PerformanceReportData;
  isLoading: boolean;
  activeEventType: EventType;
  onEventTypeChange: (eventType: EventType) => void;
  onFilterClick: () => void;
  filterData?: { [key: string]: string | undefined };
}

export const PerformanceReport: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();

  const byEventStatus = useMemo(
    () => getReportByEventStatus(props.report?.byEventStatus),
    [props.report]
  );

  const byYear = useMemo(
    () => getReportByYear(props.report?.byYear),
    [props.report]
  );

  const byTrainingCenter = useMemo(
    () => getReportByTrainingCenter(props.report?.byTrainingCenter),
    [props.report]
  );

  const byLocation = useMemo(
    () =>
      getReportByLocation(i18n.language, props.report?.byLocationUsersCount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.report]
  );

  const byTrainingCenterAcademicHours = useMemo(
    () =>
      getReportByTrainingCenterAcademicHours(props.report?.byTrainingCenter),
    [props.report]
  );

  const byContractor = useMemo(
    () => getReportByContractor(props.report?.byContractor),
    [props.report]
  );

  const byContractorAcademicHours = useMemo(
    () => getReportByContractorAcademicHours(props.report?.byContractor),
    [props.report]
  );

  const byDiscipline = useMemo(
    () => getReportByDiscipline(i18n.language, props.report?.byDiscipline),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.report]
  );

  const byDisciplineAcademicHours = useMemo(
    () => getReportByDisciplineAcademicHours(props.report?.byDiscipline),
    [props.report]
  );

  const byCitizenship = useMemo(
    () => getReportByCitizenship(i18n.language, props.report?.byCitizenship),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.report]
  );

  const byOverallGrade = useMemo(
    () => getReportByOverallGrade(props.report?.byOverallGrade),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.report]
  );

  const byFailReason = useMemo(
    () => getReportByFailReason(props.report?.byFailReason),
    [props.report]
  );

  const { pageRef, handleDownloadReport } = usePrintRef(
    "pageRef",
    t(`eventTypes.${props.activeEventType}`)
  );

  return (
    <Card
      tabList={[
        {
          tab: t(`eventTypes.${EventType.ASSESSMENT}`),
          key: `${EventType.ASSESSMENT}`
        },
        {
          tab: t(`eventTypes.${EventType.TRAINING}`),
          key: `${EventType.TRAINING}`
        },
        {
          tab: t(`eventTypes.${EventType.PRESCREENING}`),
          key: `${EventType.PRESCREENING}`
        },
        {
          tab: t(`eventTypes.${EventType.OJT_TRAINING}`),
          key: `${EventType.OJT_TRAINING}`
        }
      ]}
      tabBarExtraContent={
        <Button
          icon={<FilterOutlined />}
          size="large"
          shape="circle"
          ghost
          type="primary"
          onClick={() => props.onFilterClick()}
        />
      }
      loading={props.isLoading}
      activeTabKey={props.activeEventType.toString()}
      onTabChange={(tab) => {
        props.onEventTypeChange(Number(tab));
      }}
    >
      <Space>
        <Button
          type="primary"
          icon={<CloudDownloadOutlined />}
          onClick={handleDownloadReport}
        >
          {t("download")}
        </Button>
        <DownloadLink
          type="primary"
          size="middle"
          url={`/api/dashboard/manhours/download?${qs.stringify({
            ...props.filterData,
            type: props.activeEventType
          })}`}
          fileName={`Manhours report ${moment().format()}.xlsx`}
          label={t("reports.downloadAcademicHoursReport")}
        />
      </Space>

      <div ref={pageRef} id="pageRef">
        <Row gutter={16} style={{ marginTop: "32px", height: "340px" }}>
          <Col span={12}>
            <Row gutter={8} style={{ height: "100%" }} justify="space-between">
              {props.activeEventType === EventType.TRAINING ||
              props.activeEventType === EventType.OJT_TRAINING
                ? byEventStatus.map((e, idx) => (
                    <Col key={idx} style={{ width: "20%" }}>
                      <EventStatusCard eventStatus={e.status} value={e.value} />
                    </Col>
                  ))
                : byEventStatus
                    .filter((e) => e.status !== EmployeeEventStatus.EXCLUDED)
                    .map((e, idx) => (
                      <Col key={idx} span={6}>
                        <EventStatusCard
                          eventStatus={e.status}
                          value={e.value}
                        />
                      </Col>
                    ))}
            </Row>
          </Col>
          <Col span={12}>
            <Card title={t("reports.perYear")} style={{ height: "100%" }}>
              <ReportLineChart
                chartHeight={240}
                showDataLabels
                labels={byYear.labels}
                max={
                  byYear.datasets &&
                  Math.max(...byYear.datasets) +
                    Math.max(...byYear.datasets) / 7
                }
                datasets={[
                  {
                    backgroundColor: "#049aff",
                    fill: true,
                    data: byYear.datasets
                  }
                ]}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "32px" }}>
          <Col span={8}>
            {props.activeEventType === EventType.PRESCREENING ? (
              <Card
                title={t("reports.eventsByTrainingCenter")}
                extra={byTrainingCenter.labels.length}
              >
                <ReportBarChart
                  labels={byTrainingCenter.labels}
                  datasets={[
                    {
                      barThickness: 25,
                      backgroundColor: "#A66999",
                      borderWidth: 0,
                      data: byTrainingCenter.datasets
                    }
                  ]}
                  max={byTrainingCenter.max}
                  showDataLabels
                />
              </Card>
            ) : (
              <ReportTableBarChart
                title={t("reports.eventsByTrainingCenter")}
                count={byTrainingCenter.labels.length}
                chartData={{
                  max: byTrainingCenter.max,
                  labels: byTrainingCenter.labels,
                  datasets: [
                    {
                      barThickness: 25,
                      backgroundColor: "#A66999",
                      borderWidth: 0,
                      data: byTrainingCenter.datasets || []
                    }
                  ]
                }}
                tableData={{
                  columns:
                    props.activeEventType === EventType.OJT_TRAINING
                      ? [
                          {
                            title: t("events.trainingCenter"),
                            dataIndex: "name",
                            key: "trainingCenter"
                          },
                          {
                            title: t(`eventTypes.${EventType.OJT_TRAINING}`),
                            dataIndex: "ojtTraining",
                            key: "ojtTraining"
                          }
                        ]
                      : [
                          {
                            title: t("events.trainingCenter"),
                            dataIndex: "name",
                            key: "trainingCenter"
                          },
                          props.activeEventType === EventType.ASSESSMENT
                            ? {
                                title: t(`eventTypes.${EventType.ASSESSMENT}`),
                                dataIndex: "assessment",
                                key: "assessment"
                              }
                            : {
                                title: t(`eventTypes.${EventType.TRAINING}`),
                                dataIndex: "training",
                                key: "training"
                              },
                          {
                            title: t("total"),
                            dataIndex: "total",
                            key: "total"
                          }
                        ],
                  dataSource: byTrainingCenterAcademicHours
                }}
              />
            )}
            <br />
            <Card
              title={t("reports.eventsByLocation")}
              extra={byLocation.labels.length}
            >
              <ReportBarChart
                labels={byLocation.labels}
                datasets={[
                  {
                    barThickness: 25,
                    backgroundColor: "#A66999",
                    borderWidth: 0,
                    data: byLocation.datasets || []
                  }
                ]}
                max={byLocation.max}
                showDataLabels
              />
            </Card>
            <br />
            <ReportLabeledDoughnutChart
              title={t("reports.citizenship")}
              data={byCitizenship}
            />
            <br />
            <ReportLabeledDoughnutChart
              title={t("reports.overallGrade")}
              data={byOverallGrade}
            />
            <br />
            <ReportLabeledDoughnutChart
              title={t("reports.failReason")}
              data={byFailReason}
            />
          </Col>
          <Col span={8}>
            {props.activeEventType === EventType.PRESCREENING ? (
              <Card
                title={t("reports.eventsByContractor")}
                extra={byContractor.labels.length}
                bodyStyle={{ padding: "24px 0" }}
              >
                <ReportBarChartExpandable
                  labels={byContractor.labels}
                  datasets={byContractor.datasets}
                  max={byContractor.max}
                  showDataLabels
                />
              </Card>
            ) : (
              <ReportTableBarChart
                title={t("reports.eventsByContractor")}
                count={byContractor.labels.length}
                expandable
                expandableChartData={{
                  max: byContractor.max,
                  labels: byContractor.labels,
                  datasets: byContractor.datasets
                }}
                tableData={{
                  columns:
                    props.activeEventType === EventType.OJT_TRAINING
                      ? [
                          {
                            title: t("events.contractor"),
                            dataIndex: "name",
                            key: "name"
                          },
                          {
                            title: t(`eventTypes.${EventType.OJT_TRAINING}`),
                            dataIndex: "ojtTraining",
                            key: "ojtTraining"
                          }
                        ]
                      : [
                          {
                            title: t("events.contractor"),
                            dataIndex: "name",
                            key: "contractor"
                          },
                          props.activeEventType === EventType.ASSESSMENT
                            ? {
                                title: t(`eventTypes.${EventType.ASSESSMENT}`),
                                dataIndex: "assessment",
                                key: "assessment"
                              }
                            : {
                                title: t(`eventTypes.${EventType.TRAINING}`),
                                dataIndex: "training",
                                key: "training"
                              },
                          {
                            title: t("total"),
                            dataIndex: "total",
                            key: "total"
                          }
                        ],
                  dataSource: byContractorAcademicHours
                }}
              />
            )}
          </Col>
          <Col span={8}>
            {props.activeEventType === EventType.PRESCREENING ? (
              <Card
                title={t("reports.eventsByDiscipline")}
                extra={byDiscipline.labels.length}
                bodyStyle={{ padding: "24px 0" }}
              >
                <ReportBarChartExpandable
                  labels={byDiscipline.labels}
                  datasets={byDiscipline.datasets}
                  max={byDiscipline.max}
                  showDataLabels
                />
              </Card>
            ) : (
              <ReportTableBarChart
                title={t("reports.eventsByDiscipline")}
                count={byDiscipline.labels.length}
                expandable
                expandableChartData={{
                  max: byDiscipline.max,
                  labels: byDiscipline.labels,
                  datasets: byDiscipline.datasets
                }}
                tableData={{
                  columns:
                    props.activeEventType === EventType.OJT_TRAINING
                      ? [
                          {
                            title: t("events.discipline"),
                            dataIndex: "name",
                            key: "name"
                          },
                          {
                            title: t(`eventTypes.${EventType.OJT_TRAINING}`),
                            dataIndex: "ojtTraining",
                            key: "ojtTraining"
                          }
                        ]
                      : [
                          {
                            title: t("events.discipline"),
                            dataIndex: "name",
                            key: "trainingCenter"
                          },
                          props.activeEventType === EventType.ASSESSMENT
                            ? {
                                title: t(`eventTypes.${EventType.ASSESSMENT}`),
                                dataIndex: "assessment",
                                key: "assessment"
                              }
                            : {
                                title: t(`eventTypes.${EventType.TRAINING}`),
                                dataIndex: "training",
                                key: "training"
                              },
                          {
                            title: t("total"),
                            dataIndex: "total",
                            key: "total"
                          }
                        ],
                  dataSource: byDisciplineAcademicHours
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    </Card>
  );
};
