import { Moment } from "moment-timezone";

import { Contractor } from "./contractor";

export enum Rev {
  LOCAL = 1,
  EXPAT = 2
}

export interface OnlineTrainingReport {
  name: string;
  completedAt: Date;
  onlineTrainingReportUsers?: OnlineTrainingReportUser[];
}

export interface OnlineTrainingReportFormData {
  name: string;
  completedAt: Moment;
  onlineTrainingReportUsers: Partial<OnlineTrainingReportUser>[];
}

export interface OnlineTrainingReportUser {
  firstName: string;
  lastName: string;
  iin: string;
  department: string;
  title: string;
  rev: number | null;
  id: number;
  employeeNumber?: number | string;
  supervisor?: string;
}

export interface OnlineTrainingReportFormValues
  extends OnlineTrainingReportUser {
  onlineTrainingReportId?: number;
}

export interface OnlineTrainingReportResult {
  completedAt: string;
  contractorDto: Contractor;
  createdAt: string;
  id: number;
  name: string;
  onlineTrainingReportUsers: OnlineTrainingReportUser[];
}
