/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Row, Typography } from "antd";

import { EventDetails } from "@models/event";

import useDateFormatter from "@hooks/useDateFormat";

interface FormValues {
  deletionReason: string;
}

interface Props {
  event: EventDetails;
  isLoading?: boolean;
  errors?: { [key: string]: string };
  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

export const EventDeleteForm = (props: Props) => {
  const dateFormatter = useDateFormatter();
  const [t] = useTranslation();
  const { event } = props;

  const formik = useFormik<FormValues>({
    initialValues: {
      deletionReason: ""
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  useEffect(() => {
    if (props.errors) {
      formik.setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Typography.Text
        style={{ display: "inline-block", paddingBottom: "24px" }}
      >
        {t("events.deleteConfirm", {
          name: event.name,
          dateTimeStart: dateFormatter.formatter(
            event.dateTimeStart,
            "short-datetime"
          )
        })}
      </Typography.Text>

      <Row justify="space-between">
        <Col>
          <Button onClick={props.onCancel}>{t("cancel")}</Button>
        </Col>

        <Col>
          <Button
            type="primary"
            danger
            htmlType="submit"
            loading={props.isLoading}
          >
            {t("yes")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
