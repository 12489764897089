import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { useContext } from "react";

import { IdcardOutlined } from "@ant-design/icons";
import { Button, Col, Result, Row, Skeleton, Typography } from "antd";
import { EmployeeFacilityAccessCard } from "@components/common/employees/EmployeeFacilityAccessCard";
import { EmployeePersonalInfoCard } from "@components/common/employees/EmployeePersonalInfoCard";
import { EmployeeWorkingInfoCard } from "@components/common/employees/EmployeeWorkingInfoCard";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { EmployeeEventHistoryItemCard } from "@components/common/employees/EmployeeEventHistoryItemCard";

import { employeeApi } from "@api/employeeApi";

import { UserContext } from "@contexts/UserContext";

export const EmployeeDetailsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const { userEntityId: trainingCenterId } = useContext(UserContext);
  const params = useParams<{ id: string }>();
  const employeeId = Number(params.id);

  const employeeDetails = useQuery(
    "getEmployee",
    () => employeeApi.getEmployeeTC({ employeeId }).then((res) => res.data),
    {
      refetchOnReconnect: false
    }
  );

  const eventHistory = useQuery(
    "getTcEmployeeEventHistory",
    () =>
      employeeApi
        .getTcEmployeeEventHistory({ employeeId, trainingCenterId })
        .then((res) => res.data),
    {
      refetchOnReconnect: false
    }
  );

  const ed = employeeDetails.data;

  const pageTitle = ed
    ? `${ed.firstName} ${ed.lastName}`
    : t("employees.employee") + " #" + employeeId;

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={
          <>
            {t("employees.employeePassport")} <IdcardOutlined />
          </>
        }
        breadcrumbs={[
          { breadcrumbName: "TC", path: "/" },
          { breadcrumbName: t("employees.employees"), path: "tc/employees" },
          {
            breadcrumbName: pageTitle,
            path: employeeId.toString()
          }
        ]}
        extra={[
          <Button type="primary" onClick={() => history.push("/tc/employees")}>
            {t("employees.backToList")}
          </Button>
        ]}
      />

      <Typography.Title level={5}>
        {pageTitle}

        <Typography.Text
          type="secondary"
          style={{ marginLeft: 8, fontWeight: "normal" }}
        >
          {ed ? `- ${ed.workLocationArea}, ${ed.position}` : ""}
        </Typography.Text>
      </Typography.Title>

      {employeeDetails.data && (
        <Row gutter={8}>
          <Col md={12} lg={8}>
            <EmployeePersonalInfoCard personalInfo={ed} />
          </Col>

          <Col md={12} lg={8}>
            <EmployeeWorkingInfoCard personalInfo={ed} />
          </Col>

          <Col md={12} lg={8}>
            <EmployeeFacilityAccessCard personalInfo={ed} />
          </Col>
        </Row>
      )}

      {employeeDetails.error && (
        <Result
          status="404"
          title={t("error.errorOccured")}
          subTitle={t("employees.notFound", { id: employeeId })}
        />
      )}

      {employeeDetails.isLoading && (
        <Row gutter={[8, 16]}>
          <Col md={12} lg={8}>
            <Skeleton active />
          </Col>

          <Col md={12} lg={8}>
            <Skeleton active />
          </Col>

          <Col md={12} lg={8}>
            <Skeleton active />
          </Col>

          <Col md={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}

      {eventHistory.data && (
        <>
          <br />

          <Typography.Title level={3}>
            {t("events.trainingHistory")}
          </Typography.Title>

          <Row gutter={8}>
            {eventHistory.data.results
              .filter((eh) => !!eh.evaluatedAt || !!eh.excludedAt)
              .map((eh) => (
                <Col md={12} lg={8} key={eh.id}>
                  <EmployeeEventHistoryItemCard
                    eventHistoryItem={eh}
                    getCertificateLink={(eh) =>
                      `/api/training-center/${trainingCenterId}/certificate/${eh.grade?.certificateId}/pdf`
                    }
                  />
                </Col>
              ))}
          </Row>
        </>
      )}
    </>
  );
};
