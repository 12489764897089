import { UseMutateFunction } from "react-query";
import { useTranslation } from "react-i18next";

import { Button, Modal } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import OnlineTrainingReportEmployeeForm from "@components/common/reports/forms/OnlineTrainingReportEmployeeForm";

import {
  OnlineTrainingReportFormValues,
  OnlineTrainingReportResult
} from "@models/onlineTrainingReports";

interface Props {
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  currentReport: OnlineTrainingReportResult;
  addEmployeeToReport: UseMutateFunction<
    unknown,
    unknown,
    OnlineTrainingReportFormValues
  >;
  errors: { [key: string]: string };
  isLoading?: boolean;
}

const AddEmployeeOnlineTrainingReportModal: React.FC<Props> = ({
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  addEmployeeToReport,
  errors,
  isLoading,
  currentReport
}) => {
  const [t] = useTranslation();

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("employees.addEmployee")}
        footer={null}
        width={800}
        onCancel={handleCloseModal}
      >
        <OnlineTrainingReportEmployeeForm
          handleCloseModal={handleCloseModal}
          currentReport={currentReport}
          onSubmit={addEmployeeToReport}
          isLoading={isLoading}
          errors={errors}
        />
      </Modal>

      <Button
        type="primary"
        icon={<UserAddOutlined />}
        onClick={() => {
          handleOpenModal();
        }}
      >
        {t("employees.addEmployee")}
      </Button>
    </>
  );
};

export default AddEmployeeOnlineTrainingReportModal;
