import {
  FC,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from "react";

import styled from "styled-components";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

type IconSwitchProps = HTMLAttributes<HTMLDivElement> & {
  value: boolean;
  truthyValueIcon: ReactNode;
  falsyValueIcon: ReactNode;
  onValueChange: (newValue: boolean) => void;
};

const IconSwitchBase = styled.div`
  position: relative;
  display: flex;
  height: 24px;
  width: 48px;
  border-radius: 12px;
  border: 1px solid #dcdcdc;
  background-color: #fafafa;
  cursor: pointer;
`;

const Indicator = styled.div<{ active?: boolean }>`
  position: absolute;
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  left: -1px;
  top: -1px;
  z-index: 1;
  background-color: #199ffa;
  transition: left 0.5s ease-in-out;
  ${({ active }) => active && `left: 23px;`}
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 2;
  top: -1px;
`;

const IconContainerSpan = styled.span<{ active?: boolean }>`
  color: #6f6f6f;
  font-size: 16px;
  transition: color 0.5s ease-in-out;
  ${({ active }) => active && `color: #ffffff;`}
`;

export const IconSwitch: FC<IconSwitchProps> = ({
  truthyValueIcon,
  falsyValueIcon,
  value,
  onValueChange,
  ...rest
}) => {
  const [val, setVal] = useState<boolean>(value);

  const handleSwitchClick = useCallback(() => {
    setVal((oldVal) => !oldVal);
  }, []);

  useEffect(() => {
    onValueChange(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  return (
    <IconSwitchBase onClick={handleSwitchClick} {...rest}>
      <Indicator active={val}></Indicator>
      <IconContainer>
        <IconContainerSpan active={!val}>{falsyValueIcon}</IconContainerSpan>
      </IconContainer>
      <IconContainer>
        <IconContainerSpan active={val}>{truthyValueIcon}</IconContainerSpan>
      </IconContainer>
    </IconSwitchBase>
  );
};

IconSwitch.defaultProps = {
  value: false,
  truthyValueIcon: <CheckCircleOutlined />,
  falsyValueIcon: <CloseCircleOutlined />
};

export default IconSwitch;
