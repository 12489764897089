export const enMembers = {
  members: "Members",

  inviteMember: "Invite member",
  editMember: "Edit member",

  activeMembers: "Active members",
  inactiveMembers: "Inactive members",
  totalMembers: "Total members",
  invitedMembers: "Invited members",

  accessLevel: "Access level",
  notifications: "Notifications",

  firstName: "First name",
  lastName: "Last name",
  fullName: "Full name",
  position: "Position",
  email: "Email",
  phoneNumber: "Phone",
  invitationDate: "Invitation date",
  actions: "Actions",

  memberDeleted: "Member deleted",
  editSuccess: "Member successfully saved",

  userExport: "Export users",
  edit: "Edit",
  delete: "Delete",
  cancel: "Cancel",
  resend: "Resend",
  activate: "Activate",
  deactivate: "Deactivate",

  activationSuccess: "Invitation is successfully sent to {{email}}",

  memberInfo: "Member information",
  jobTitle: "Job title",

  invite: "Invite",
  inviteSuccess: "Invite sent to {{email}}",
  inviteResent: "Invite resent to {{email}}",
  inviteDeleted: "Invite deleted",

  previousInviteNotExpired: "Previous invite is not expired yet",
  resendPossible: "You can resend the invitation",

  confirmDeactivate: "Are you sure you want to deactivate member: {{name}}?",
  confirmDelete: "Are you sure you want to delete member: {{name}}?"
};
