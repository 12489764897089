import axios from "axios";
import { saveAs } from "file-saver";

import { MigrationInitialFormData } from "@models/migrations";

interface MigrationsPagination {
  page?: number;
  pageSize?: number;
}

const getMigrations = ({ page, pageSize = 20 }: MigrationsPagination) =>
  axios.get(`api/data-migration`, {
    params: { page, pageSize }
  });

const deleteMigration = (eventId: number) =>
  axios.delete(`/api/data-migration/${eventId}/delete`);

const getMigrartionTemplate = (type: number) =>
  axios
    .get<Blob>(`api/data-migration/v2/templates/${type}`, {
      responseType: "blob"
    })
    .then((response) => {
      return saveAs(new Blob([response.data]), "migration_template.xlsx");
    });

const uploadTable = (route: string, file: Blob) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post<MigrationInitialFormData>(
    `api/data-migration/v2/${route}/parsing`,
    formData
  );
};

const sendMigrationData = (route: string, payload: MigrationInitialFormData) =>
  axios.post(`api/data-migration/v2/${route}`, payload);

export const migrationsApi = {
  getMigrations,
  deleteMigration,
  getMigrartionTemplate,
  uploadTable,
  sendMigrationData
};
