import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { UploadFile } from "antd/lib/upload/interface";
import saveAs from "file-saver";

import { Modal, notification } from "antd";
import { TCReportDetails } from "@components/common/tc-reports/TCReportDetails";
import { EditTrainingReportForm } from "@components/common/tc-reports/EditTrainingReportForm";

import { TrainingCenterReport } from "@models/trainingCenter";

import { trainingCenterApi } from "@api/trainingCenterApi";

import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

interface PageParams {
  id: string;
}

export const ReportDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { userEntityId: trainingCenterId } = useContext(UserContext);
  const { id } = useParams<PageParams>();
  const TRAINING_CENTER_REPORT_ID = Number(id);
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [editedReport, setEditedReport] = useState<TrainingCenterReport>();

  const { data, refetch, isLoading } = useQuery(
    "getReportDetails",
    () =>
      trainingCenterApi
        .getTrainingCenterReport(trainingCenterId, TRAINING_CENTER_REPORT_ID)
        .then((res) => res.data),
    {
      onSuccess(res) {
        setEditedReport(res);
      }
    }
  );

  const updateTrainingReport = useValidatedMutation({
    mutationFunction: (values: any) =>
      trainingCenterApi.updateTrainingCenterReport({
        trainingCenterId: trainingCenterId,
        tcReportId: TRAINING_CENTER_REPORT_ID,
        payload: {
          ...values,
          files: values.files?.map?.((f: UploadFile) =>
            f.originFileObj ? f.originFileObj : f
          )
        }
      }),
    onSuccess() {
      notification.success({
        message: t("reports.saveSuccess")
      });
      setIsEditModalShown(false);
      refetch();
    }
  });

  const editErrors = useMemo(
    () => transformValidationError(updateTrainingReport.error),
    [updateTrainingReport.error]
  );

  const handleModal = () => {
    setIsEditModalShown(true);
  };

  const handleDownload = () => {
    // eslint-disable-next-line array-callback-return
    data?.attachmentFiles.map((file) => {
      trainingCenterApi
        .getTCReportFile(trainingCenterId, TRAINING_CENTER_REPORT_ID, file.id)
        .then((res) => {
          return saveAs(new Blob([res]), file.fileName);
        });
    });
  };

  return (
    <>
      <Modal
        visible={isEditModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("reports.trainingReport")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsEditModalShown(!isEditModalShown);
          updateTrainingReport.reset();
        }}
      >
        <EditTrainingReportForm
          isLoading={updateTrainingReport.isLoading}
          errors={editErrors}
          report={editedReport}
          reportType={data?.type}
          reportId={editedReport?.id || 0}
          tcId={trainingCenterId}
          onCancel={() => {
            setIsEditModalShown(!isEditModalShown);
            updateTrainingReport.reset();
          }}
          onSubmit={(values) => {
            updateTrainingReport.mutate(values);
          }}
        />
      </Modal>
      <TCReportDetails
        data={data}
        isLoading={isLoading}
        onEdit={handleModal}
        onDownload={handleDownload}
        path={"tc"}
      />
    </>
  );
};
