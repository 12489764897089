import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Card, Col, Row, Space, Typography } from "antd";

import { EventData } from "@models/event";

import useDateFormatter from "@hooks/useDateFormat";
import { LANGUAGES_MAP } from "@constants/languagesMap";

import { EventTypeTag } from "./events/EventTypeTag";

const getEventLanguage = (locale: string, languageCode: number) => {
  const lang = LANGUAGES_MAP.find((l) => l.value === languageCode);

  return locale === "en" ? lang?.nameEn : lang?.nameRu;
};

const EventDataColumn: React.FC<{ label: string; data: string }> = ({
  label,
  data
}) => {
  return (
    <Col span={4}>
      <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
        {label}
      </Typography.Text>
      <br />
      <Typography.Text strong>{data}</Typography.Text>
    </Col>
  );
};

interface Props {
  eventData: EventData;
  canApply?: boolean;
  eventDetailsLink?: string;
  navigateTo?: (ed: EventData) => string;
}
export const EventSearchCard: React.FC<Props> = ({
  eventData,
  canApply,
  eventDetailsLink,
  navigateTo
}) => {
  const { t, i18n } = useTranslation();
  const { formatter } = useDateFormatter();

  const eventMainData = [
    {
      label: t("events.discipline"),
      data:
        eventData.speciality &&
        (i18n.language === "en"
          ? eventData.speciality.nameEn
          : eventData.speciality.nameRu)
    },
    {
      label: t("events.competencyLevel"),
      data:
        eventData.competencyLevel &&
        !Number.isNaN(eventData.competencyLevel) &&
        t(`competencyLevels.${eventData.competencyLevel}`)
    },
    {
      label: t("events.language"),
      data: getEventLanguage(i18n.language, eventData.languageCode || 0)
    },
    {
      label: t("events.city"),
      data:
        eventData.city &&
        (i18n.language === "en" ? eventData.city.nameEn : eventData.city.nameRu)
    },
    {
      label: t("events.trainingCenter"),
      data: eventData.trainingCenter && eventData.trainingCenter.name
    },
    {
      label: t("events.standard"),
      data: eventData.standard
    }
  ];

  return (
    <Card
      title={
        <Space align="start" size="middle">
          <EventTypeTag
            eventType={eventData.type}
            isCommissioning={eventData.isCommissioning}
          />

          <Typography.Title level={4}>{eventData.name}</Typography.Title>
          <Typography.Text style={{ fontSize: "12px", color: "#fb8c00" }}>
            ID: {eventData.id}
          </Typography.Text>
        </Space>
      }
      style={{ width: "100%", marginBottom: "16px" }}
      extra={
        <span>
          {t(`fromToRange`, {
            from: formatter(eventData.dateTimeStart, "short-date"),
            to: formatter(eventData.dateTimeEnd, "short-date")
          })}
        </span>
      }
    >
      <Row>
        <Col span={20}>
          <Row wrap>
            {eventMainData
              .filter((e) => !!e.data)
              .map((e, idx) => (
                <EventDataColumn
                  key={idx}
                  label={e.label}
                  data={String(e.data)}
                />
              ))}
          </Row>
        </Col>
        <Col span={4}>
          <Space direction="vertical" align="center" style={{ width: "100%" }}>
            <span>
              <Typography.Text
                type="secondary"
                style={{ fontSize: "12px", marginRight: "5px" }}
              >
                {t("availableSeats")}
              </Typography.Text>
              <Typography.Text style={{ fontSize: "14px", color: "#049aff" }}>
                {t("outOfCapacity", {
                  quantity: eventData.capacityAvailable,
                  capacity: eventData.capacityMax
                })}
              </Typography.Text>
            </span>

            <Link
              to={
                navigateTo
                  ? navigateTo(eventData)
                  : `/contractor/events/${eventData.id}/registeremployees`
              }
            >
              <Button type="primary">
                {canApply ? t("apply") : t("viewDetails")}
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
