export const ruEmployees = {
  employees: "Работники",
  employee: "Работник",
  employeesPassport: "Паспорт работников",
  employeePassport: "Паспорт работника",
  craftEmployeeList: "Список крафт работников",
  addEmployee: "Добавить сотрудника",
  uploadEmployees: "Загрузить сотрудников",

  search: "Поиск",
  filtering: "Фильтрация",

  personalInfo: "Личная информация",
  workingInfo: "Рабочая информация",
  facilityAccesses: "Доступы к объектам",

  fullName: "Полное имя",
  firstName: "Имя",
  lastName: "Фамилия",
  middleName: "Отчество",
  firstNameCyrillic: "Имя на русском",
  lastNameCyrillic: "Фамилия на русском",
  middleNameCyrillic: "Отчество на русском",
  jobTitle: "Должность",
  phoneNumber: "Телефон",
  iin: "ИИН",
  noIin: "Нет ИИН",
  birthDate: "Дата рождения",
  citizenship: "Гражданство",
  residenceCountry: "Страна проживания",
  residenceRegion: "Город/Регион проживания",

  position: "Должность",
  mouCategory: "MoU категория",
  contractor: "Подрядчик/Субподрядчик",
  contract: "Контракт",
  lineSupervisorBadgeNumber: "Номер пропуска линейного руководителя",

  workLocationArea: "Место работы",
  firstNameCompanyRep: "Имя представителя компании",
  lastNameCompanyRep: "Фамилия представителя компании",
  phoneNumberCompanyRep: "Телефон представителя компании",

  facilityAccessStandard: "Стандартные доступы на обьекты",
  facilityAccessAdditional: "Дополнительные доступы на обьекты",
  specialAccessJustification: "Обоснование для специальных допусков",
  waterTreatmentPlant: "Непроизводственные зоны  - Очистные сооружения (ПШ)",
  residentialAreas: "Жилые зоны – Поселок ТШО",

  competency: "Компетентность",

  backToList: "Вернуться к списку",

  updatePersonalInfo: "Обновить личную информацию",
  updateWorkingInfo: "Обновить рабочую информацию",
  updateFacilityAccess: "Обновить информацию о доступах к объектам",

  createSuccess: "Работник успешно добавлен в систему",
  personalInfoUpdated: "Личная информация обновлена",
  workingInfoUpdated: "Рабочая информация обновлена",
  facilityAccessUpdated: "Информация о доступах к объектам обновлена",

  notFound: "Работников с ID={{id}} не найдено",

  uploadFile: "Загрузить файл",
  editData: "Проверить данные",
  downloadTemplate: "Скачать шаблон",
  chooseOrDragFile: "Выберите или перетащите файл сюда",
  fileUploaded: "Файл загружен",
  checkDataError:
    "Возника ошибка. Пожалуйста, проверьте правильность заполненных данных",
  batchCreateSuccess: "Работники успешно загружены",

  iinUpdateConfirm:
    "Вы уверены что хотите обновить ИИН? Обновленный ИИН будет записан безвозвратно.",
  iinUpdated: "ИИН обновлен"
};
