import React from "react";
import { useTranslation } from "react-i18next";

import { Space, Table, Typography } from "antd";

import {
  EventEmployeeDetails,
  EventEmployeeGradeType,
  FailReasonType
} from "@models/event";

const Column = Table.Column;
const Text = Typography.Text;

interface Props {
  eventEmployees?: EventEmployeeDetails[];
}

export const CommissioningEventGradeTable = (props: Props) => {
  const [t] = useTranslation();

  return (
    <Table dataSource={props.eventEmployees} pagination={false}>
      <Column
        title={t("events.participant")}
        render={(e: EventEmployeeDetails) => (
          <Space direction="vertical" size="small">
            <Text>
              {e.firstName}
              <br />
              {e.lastName}
            </Text>
            <Text type="secondary">{e.iin}</Text>
          </Space>
        )}
      />

      <Column
        title={t("events.academicHours")}
        render={(ee: EventEmployeeDetails) => ee.attendanceTotal}
      />

      <Column
        title={t("events.competencyLevel")}
        render={(ee: EventEmployeeDetails) =>
          t(`competencyLevels.${ee.competencyLevel}`)
        }
      />

      <Column
        title={t("events.practicalStatus")}
        render={(ee: EventEmployeeDetails) =>
          t(`events.gradeStatus.${ee.practicalStatus}`)
        }
      />

      <Column
        title={t("events.theoreticalStatus")}
        render={(ee: EventEmployeeDetails) =>
          t(`events.gradeStatus.${ee.theoreticalStatus}`)
        }
      />

      <Column
        title={t("events.theoreticalGrade")}
        render={(ee: EventEmployeeDetails) => ee.theoreticalGrade}
      />

      <Column
        title={t("events.overallGrade")}
        render={(ee: EventEmployeeDetails) =>
          ee.isPassed ? t(`events.grade.${ee.isPassed}`) : ""
        }
      />

      <Column
        title={t("events.failReason.failReason")}
        render={(ee: EventEmployeeDetails) => {
          if (ee.isPassed !== EventEmployeeGradeType.FAILED) {
            return "-";
          }

          return ee.failReasonType === FailReasonType.OTHER
            ? ee.failReason
            : t(`events.failReason.${ee.failReasonType}`);
        }}
      />
      <Column
        title={t("events.recommendation")}
        render={(ee: EventEmployeeDetails) =>
          t(`events.recommendationStatus.${ee.recommendation}`)
        }
      />
    </Table>
  );
};
