import CraftProfilesListTemplate from "@components/common/craftProfiles/CraftProfilesListTemplate";

const BASE_LINK = "/admin/craft-profiles/all";
const BASE_LINK_PAGINATOR = "/admin/craft-profile";

export const CraftProfilesListPage = () => (
  <CraftProfilesListTemplate
    baseLink={BASE_LINK}
    baseLinkPaginator={BASE_LINK_PAGINATOR}
    ableToCreateProfile
  />
);
