import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

import qs from "qs";
import { AxiosError, AxiosResponse } from "axios";

import { Card, Col, Pagination, Row } from "antd";
import { PageMeta } from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { EventsFilter } from "@components/common/EventsFilter";
import { EventSearchCard } from "@components/common/EventSearchCard";

import { City } from "@models/countryCity";
import { Discipline } from "@models/discipline";
import { PERMISSIONS } from "@models/permissions";
import { EventData, EventSearchFilter } from "@models/event";
import { TrainingCentersMap } from "@models/trainingCenter";

import { PaginatedResponse } from "@api";

import { transformValidationError } from "@utils/errorHelper";
import { UserContext } from "@contexts/UserContext";
import { useQueryParams } from "@hooks/useQueryParams";
import { useDefaultQuery } from "@hooks/useDefaultQuery";

interface Props {
  searchEventsApi: (
    contractorId: number,
    page: number,
    filters: EventSearchFilter
  ) => Promise<AxiosResponse<PaginatedResponse<EventData>>>;
  getDisciplinesApi: () => Promise<AxiosResponse<Discipline[]>>;
  getTrainingCentersMapApi: (
    contractorId: number
  ) => Promise<AxiosResponse<TrainingCentersMap>>;
  getCitiesApi: () => Promise<AxiosResponse<City[]>>;
  isSearchPage: boolean;
  navigateTo?: (ed: EventData) => string;
}

export const ContractorEventSearchTemplate: React.FC<Props> = (props) => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const { page: initialPage, ...initialFilters } = queryParams;
  const [page, setPage] = useState<number>(Number(initialPage) || 1);
  const [filters, setFilters] = useState<EventSearchFilter>(initialFilters);

  const {
    userEntityId: contractorId,
    checkPermission,
    userContractor
  } = useContext(UserContext);

  const { t } = useTranslation();

  const contractorEvents = useQuery(
    ["searchContractorEvents", page, filters],
    () =>
      props
        .searchEventsApi(contractorId, page, filters)
        .then((res) => res.data),
    {
      retry: false,
      refetchOnMount: true,
      onError: (error: AxiosError) => {},
      onSuccess: (_newPage) => {
        history.push({
          search: qs.stringify(
            { page: _newPage.currentPage, ...filters },
            { arrayFormat: "indices" }
          )
        });
      }
    }
  );
  const contractorEventsSearchErrors = useMemo(
    () => transformValidationError(contractorEvents.error),
    [contractorEvents.error]
  );

  const disciplines = useDefaultQuery("getAllDisciplines", () =>
    props.getDisciplinesApi().then((res) => res.data)
  );

  const trainingCentersMap = useDefaultQuery("getTrainingCenters", () =>
    props.getTrainingCentersMapApi(contractorId).then((res) => res.data)
  );

  const cities = useDefaultQuery("getAllCities", () =>
    props.getCitiesApi().then((res) => res.data)
  );

  const userHasPermission = checkPermission(PERMISSIONS.EVENT_APPLY);
  const isUserContractor = !userContractor?.parentContractorId;

  const canUserApply = (event: EventData) =>
    userHasPermission && isUserContractor
      ? !!userContractor?.events.find(({ eventId }) => eventId === event.id)
      : !!userContractor?.subcontractorEvents.find(
          ({ eventId }) => eventId === event.id
        );

  const title = props.isSearchPage
    ? t("events.eventSearch")
    : t("events.allEvents");
  const subTitle = props.isSearchPage
    ? t("events.eventSearchSubtitle")
    : t("totalCount", {
        total: contractorEvents.data ? contractorEvents.data?.totalItems : 0
      });

  return (
    <>
      <PageMeta title={title} />
      <AppPageHeader
        title={title}
        subTitle={subTitle}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "contractor" },
          {
            breadcrumbName: title,
            path: "contractor/search"
          }
        ]}
      />
      <EventsFilter
        initialValues={initialFilters}
        errors={contractorEventsSearchErrors}
        disciplines={disciplines.data || []}
        trainingCenters={trainingCentersMap.data}
        cities={cities.data || []}
        isLoading={contractorEvents.isLoading}
        onSubmit={setFilters}
      />
      <Row>
        <Col span={24}>
          {contractorEvents.isLoading || !contractorEvents.data ? (
            <Card
              loading={contractorEvents.isLoading || !contractorEvents.data}
            />
          ) : (
            contractorEvents.data?.results.map((e) => (
              <EventSearchCard
                eventData={e}
                canApply={canUserApply(e)}
                navigateTo={props.navigateTo}
              />
            ))
          )}
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          {contractorEvents.data &&
            contractorEvents.data.totalItems >
              contractorEvents.data.pageSize && (
              <Pagination
                defaultCurrent={page}
                total={contractorEvents.data.totalItems}
                pageSize={20}
                onChange={setPage}
              />
            )}
        </Col>
      </Row>
    </>
  );
};
