import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters
} from "react-query";

import { Button, Modal, notification } from "antd";
import { EditOutlined } from "@ant-design/icons";
import EditCraftProfileForm from "@components/common/craftProfiles/forms/EditCraftProfileForm";

import {
  CraftProfileCard,
  CraftProfileCardWithId,
  EditProfilePayloadAdmin
} from "@models/craftProfiles";

import { craftProfilesApi } from "@api/craftProfilesApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

interface Props {
  refetchProfile: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<CraftProfileCard, unknown>>;
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  editedProfile: CraftProfileCardWithId;
  areFieldsHidden?: boolean;
}

const EditCraftProfileModal: React.FC<Props> = ({
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  editedProfile,
  refetchProfile,
  areFieldsHidden = false
}) => {
  const [t] = useTranslation();

  const editCraftProfile = useValidatedMutation({
    mutationFunction: (payload: EditProfilePayloadAdmin) =>
      craftProfilesApi.editCraftProfile(payload),
    onSuccess: () => {
      notification.success({
        message: t("craftProfiles.editCraftProfileSuccess")
      });
      refetchProfile();
      handleCloseModal();
    },
    onError: () => notification.error({ message: t("error.errorOccured") })
  });

  const editCraftProfileErrors = useMemo(
    () => transformValidationError(editCraftProfile.error),
    [editCraftProfile.error]
  );

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("craftProfiles.editProfile")}
        footer={null}
        width={1100}
        onCancel={handleCloseModal}
      >
        <EditCraftProfileForm
          isLoading={editCraftProfile.isLoading}
          handleCloseModal={handleCloseModal}
          editedProfile={editedProfile}
          handleSubmit={editCraftProfile.mutate}
          errors={editCraftProfileErrors}
          areFieldsHidden={areFieldsHidden}
        />
      </Modal>

      <Button icon={<EditOutlined />} onClick={handleOpenModal}>
        {t("edit")}
      </Button>
    </>
  );
};

export default EditCraftProfileModal;
