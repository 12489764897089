import { EventType, FailReasonType } from "./event";
import { MouCategoryKey } from "./mouCategory";
import { City } from "./countryCity";

export enum MouCategoryReportType {
  DISCIPLINE = 1,
  POSITION = 2
}
export interface MouCategoryReportChartData {
  eventType: EventType;
  items: {
    year: number;
    total: number;
    disciplines: {
      key: MouCategoryKey;
      value: number;
    }[];
  }[];
}

export interface MouCategoryReportTableData {
  assessment: number;
  mouCategory: MouCategoryKey;
  prescreening: number;
  training: number;
}

export type ReportByEventStatus = {
  inProgress: number;
  completed: number;
  evaluated: number;
  excluded: number;
};

export type ReportByCompetencyLevel = {
  competencyLevel: number;
  labelRu: string;
  labelEn: string;
  count: number;
}[];

export type ReportByFailReason = {
  failReasonType: FailReasonType;
  failReasonTypeLabel: string;
  count: number;
}[];

export type ReportByYear = {
  year: number;
  count: number;
}[];

export type ReportByCitizenship = {
  countryRu: string;
  countryEn: string;
  count: number;
}[];

export type ReportByOverallGrade = {
  failed: number;
  passed: number;
  basic: number;
  intermediate: number;
  advanced: number;
  competent: number;
  proficient: number;
  helper: number;
};

interface ReportByEntity {
  name?: string;
  count: number;
  academicHoursCount?: {
    assessment: number;
    training: number;
    ojtTraining: number;
  };
}

export interface ReportByLocationUsersCount extends ReportByEntity {
  city: City | null;
  cityId: string | null;
  count: number;
}

export interface ReportByContractor extends ReportByEntity {
  type: number;
  count: number;
  subcontractors: { name: string; count: number }[];
}

export interface ReportByTrainingCenter extends ReportByEntity {
  count: number;
}

export interface ReportByDisciplineForCommissioning extends ReportByEntity {
  disciplineRu: string;
  disciplineEn: string;
  count: number;
}

export interface PerformanceReportData {
  byEventStatus: ReportByEventStatus;
  byContractor: ReportByContractor[];
  byTrainingCenter: ReportByTrainingCenter[];
  byYear: ReportByYear;
  byDiscipline: ReportByDisciplines[];
  byCitizenship: ReportByCitizenship;
  byOverallGrade: ReportByOverallGrade;
  byFailReason: ReportByFailReason;
  byLocationUsersCount: ReportByLocationUsersCount[];
}
export interface CommissioningReportData {
  byEventStatus: ReportByEventStatus;
  byDiscipline: ReportByDisciplineForCommissioning[];
  byCompetencyLevel: ReportByCompetencyLevel;
  byContractor: ReportByContractor[];
  byFailReason: ReportByFailReason;
}

export interface DatadumpRecord {
  fileName: string;
  blobFileName: string;
  unixTime: number;
  date: string | number;
  id: number;
  status: DatadumpStatuses;
}

export enum DatadumpStatuses {
  InProgress = 1,
  Completed = 2,
  Failed = 3
}

export type Discipline = Pick<
  ReportByEntity,
  "count" | "academicHoursCount"
> & {
  disciplineRu: string;
  disciplineEn: string;
  specialityCategory: number;
};

export type ReportByDisciplines = {
  category: number;
  desciplines: Discipline[];
};

export type ReportProfilesByCsrContractor = {
  id: number;
  name: string;
  count: number;
};

export type ReportProfilesByDisciplines = Discipline & {
  count: number;
  specialityId: number;
};

export type ReportProfilesByEmployedStatus = {
  isEmployed: boolean;
  count: number;
};

export type ReportProfilesByHiringContractor = Pick<
  ReportByEntity,
  "name" | "count"
> & {
  id: number;
  type: number;
};

export type ReportProfilesByTrainingCenter = {
  count: number;
  trainingCenterId: number;
  trainingCenterName: string;
};

export interface CraftProfilesReportData {
  byCsrContractor: ReportProfilesByCsrContractor[];
  byDiscipline: ReportProfilesByDisciplines[];
  byEmployedStatus: ReportProfilesByEmployedStatus[];
  byHiringContractor: ReportProfilesByHiringContractor[];
  byTrainingCenter: ReportProfilesByTrainingCenter[];
  total: number;
}
