import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography
} from "antd";

import { AdminMember } from "@models/adminMember";
import { AppNotification } from "@models/notification";

import { ADMIN_PERMISSION_OPTION_LIST } from "@constants/adminPermissions";

import { PermissionCheckboxGroup } from "./PermissionCheckboxGroup";

interface FormValues {
  firstName: string;
  lastName: string;
  position: string;
  notificationIds: number[];
  permissions: number[];
}

interface Props {
  adminMember?: AdminMember;

  errors?: { [key: string]: string };

  notifications?: AppNotification[];
  permissions?: object[];

  isLoading?: boolean;

  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

export const EditAdminMemberForm = (props: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      position: "",
      notificationIds: [],
      permissions: []
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  useEffect(() => {
    if (props.adminMember) {
      formik.setValues({
        firstName: props.adminMember.firstName,
        lastName: props.adminMember.lastName,
        position: props.adminMember.position,
        notificationIds: props.adminMember.notifications.map((n) => n.id),
        permissions: props.adminMember.permissions.map((p) => p.permission)
      });
    } else {
      formik.resetForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.adminMember]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8}>
        <Col md={8}>
          <Form.Item
            label={t("members.firstName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.firstName && "error"}
            help={formik.errors.firstName}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("members.lastName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.lastName && "error"}
            help={formik.errors.lastName}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("members.position")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.position && "error"}
            help={formik.errors.position}
          >
            <Input
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <br />

          <Typography.Title level={5}>
            {t("members.accessLevel")}
          </Typography.Title>

          <PermissionCheckboxGroup
            options={ADMIN_PERMISSION_OPTION_LIST}
            value={formik.values.permissions}
            onChange={(v) => formik.setFieldValue("permissions", v)}
          />
        </Col>

        <Col xs={24}>
          <br />

          <Typography.Title level={5}>
            {t("members.notifications")}
          </Typography.Title>

          <Checkbox.Group
            value={formik.values.notificationIds}
            onChange={(val) => formik.setFieldValue("notificationIds", val)}
          >
            <Row gutter={8}>
              {props.notifications?.map((n) => (
                <Col key={n.id} xs={12}>
                  <Checkbox value={n.id} key={n.id}>
                    {n.name || n.nameEn}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Col>

        <Col md={24}>
          <br />
          <br />
          <br />

          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("edit")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
