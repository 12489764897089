import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { UserOutlined } from "@ant-design/icons";
import { Avatar, List, Pagination, Skeleton, Typography } from "antd";
import EmploymentStatusTag from "@components/common/craftProfiles/renderers/EmploymentStatusTag";

import { CraftProfilePaginated, CraftProfile } from "@models/craftProfiles";

interface Props {
  craftProfilesList?: CraftProfilePaginated<CraftProfile>;
  disabled?: boolean;
  baseLink: string;
  onChange: (nextPage: number) => void;
}

export const CraftProfilesPaginator: React.FC<Props> = ({
  craftProfilesList,
  disabled,
  onChange,
  baseLink
}) => {
  const [t] = useTranslation();

  return (
    <>
      {craftProfilesList?.results ? (
        <>
          <List
            size="large"
            itemLayout="horizontal"
            dataSource={craftProfilesList.results}
            renderItem={(profile) => (
              <Link to={`${baseLink}/${profile.id}`}>
                <List.Item
                  style={{ backgroundColor: "#ffffff", marginBottom: "10px" }}
                  className="craftProfileCard"
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor: "#049aff",
                          verticalAlign: "center",
                          marginTop: "5px"
                        }}
                        size="large"
                        icon={<UserOutlined />}
                      />
                    }
                    title={
                      <Typography.Text style={{ fontSize: "17px" }}>
                        {profile?.profileName}
                      </Typography.Text>
                    }
                    description={`${t("disciplines.discipline")}: ${
                      profile?.discipline || t("craftProfiles.notIndicated")
                    }`}
                  />
                  <EmploymentStatusTag isEmployed={profile.isEmployed} />
                </List.Item>
              </Link>
            )}
          />

          <Pagination
            showSizeChanger={false}
            style={{ textAlign: "center" }}
            current={craftProfilesList?.currentPage}
            pageSize={craftProfilesList?.pageSize}
            total={craftProfilesList?.totalItems}
            disabled={disabled}
            onChange={onChange}
            hideOnSinglePage
          />
        </>
      ) : (
        <Skeleton paragraph={{ rows: 2 }} active />
      )}
    </>
  );
};
