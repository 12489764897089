import { useTranslation } from "react-i18next";

import { Card, Col, Row, Space, Typography } from "antd";
import DownloadLink from "@components/common/DownloadLink";
import { EventTypeTag } from "@components/common/events/EventTypeTag";

import { EmployeeEventHistoryItem } from "@models/employee";
import { EventEmployeeGradeType, EventType } from "@models/event";

import useDateFormatter from "@hooks/useDateFormat";

interface Props {
  eventHistoryItem: EmployeeEventHistoryItem;

  getCertificateLink?: (eh: EmployeeEventHistoryItem) => string;
}

export const EmployeeEventHistoryItemCard = (props: Props) => {
  const df = useDateFormatter();
  const [t, i18n] = useTranslation();

  const eh = props.eventHistoryItem;

  const cardTitle =
    i18n.language === "en" ? eh.speciality.nameEn : eh.speciality.nameRu;
  const competencyLevel = eh.grade?.competencyLevel || eh.grade?.isPassed;

  return (
    <Card title={cardTitle}>
      <Row>
        <Col xs={24} style={{ marginBottom: 12 }}>
          <Space size="large">
            <EventTypeTag
              eventType={eh.event.type}
              isCommissioning={eh.isCommissioning}
            />

            <span>
              <Typography.Text>{t("events.issuedAt")}</Typography.Text>

              <br />

              <Typography.Text strong style={{ color: "rgba(234,105,73,0.8)" }}>
                {eh.evaluatedAt ? df.formatter(eh.evaluatedAt) : "-"}
              </Typography.Text>
            </span>
          </Space>
        </Col>

        {competencyLevel && (
          <Col xs={12} style={{ marginBottom: 12 }}>
            <Typography.Text type="secondary">
              {t(`events.competencyLevel`)}
            </Typography.Text>

            <br />

            <Typography.Text>
              {eh.grade?.competencyLevel ||
              eh.event.type === EventType.ASSESSMENT
                ? t(`competencyLevels.${competencyLevel}`)
                : t(`events.grade.${eh.grade?.isPassed}`)}
            </Typography.Text>
          </Col>
        )}

        <Col xs={12} style={{ marginBottom: 12 }}>
          <Typography.Text type="secondary">
            {t("events.academicHours")}
          </Typography.Text>

          <br />

          <Typography.Text>{eh.event.academicHours}</Typography.Text>
        </Col>

        <Col xs={24} style={{ marginBottom: 12 }}>
          <Typography.Text type="secondary">
            {t("events.eventId")}
          </Typography.Text>

          <br />

          <Typography.Text>{eh.eventId}</Typography.Text>
        </Col>

        <Col xs={24} style={{ marginBottom: 12 }}>
          <Typography.Text type="secondary">
            {t("events.dates")}
          </Typography.Text>

          <br />

          <Typography.Text>{`${t("from")} ${df.formatter(
            eh.event.dateTimeStart
          )} ${t("to")} ${df.formatter(
            eh.event.dateTimeEnd
          )}`}</Typography.Text>
        </Col>

        <Col xs={24} style={{ marginBottom: 12 }}>
          <Typography.Text type="secondary">
            {t("events.trainingCenter")}
          </Typography.Text>

          <br />

          <Typography.Text>{eh.event.trainingCenter?.name}</Typography.Text>
        </Col>

        <Col xs={24} style={{ marginBottom: 12 }}>
          <Row justify="space-between">
            <Col xs={12}>
              {((eh.grade && !eh.isCommissioning) ||
                (eh.isCommissioning &&
                  eh.event.type === EventType.TRAINING)) && (
                <>
                  <Typography.Text type="secondary">
                    {t("events.overallResult")}
                  </Typography.Text>

                  <br />

                  <Typography.Text
                    strong
                    type={
                      eh.grade?.isPassed !== EventEmployeeGradeType.FAILED
                        ? "success"
                        : "danger"
                    }
                  >
                    {t(
                      eh.grade?.isPassed === EventEmployeeGradeType.FAILED
                        ? `events.gradeStatus.0`
                        : `events.gradeStatus.1`
                    )}
                  </Typography.Text>
                </>
              )}
              {eh.excludedAt && (
                <>
                  <Typography.Text type="secondary">
                    {t("events.overallResult")}
                  </Typography.Text>

                  <br />

                  <Typography.Text strong type={"danger"}>
                    {t(`events.statuses.excluded`)}
                  </Typography.Text>
                </>
              )}
              {eh.grade &&
                eh.isCommissioning &&
                eh.event.type === EventType.ASSESSMENT && (
                  <Space direction="vertical" size="small">
                    <Space size="small">
                      <Typography.Text type="secondary">
                        {t("events.practicalStatus")}
                      </Typography.Text>
                      <Typography.Text
                        strong
                        type={eh.grade.practicalStatus ? "success" : "danger"}
                      >
                        {t(`events.gradeStatus.${eh.grade.practicalStatus}`)}
                      </Typography.Text>
                    </Space>
                    <Space size="small">
                      <Typography.Text type="secondary">
                        {t("events.theoreticalStatus")}
                      </Typography.Text>
                      <Typography.Text
                        strong
                        type={eh.grade.theoreticalStatus ? "success" : "danger"}
                      >
                        {t(`events.gradeStatus.${eh.grade.theoreticalStatus}`)}
                      </Typography.Text>
                    </Space>
                  </Space>
                )}
            </Col>

            <DownloadLink
              type="primary"
              size="small"
              url={props.getCertificateLink ? props.getCertificateLink(eh) : ""}
              label={t("certificates.downloadCert")}
              fileName={`certificate_${eh.grade?.certificateId}.pdf`}
              disabled={
                (eh.grade &&
                  eh.grade.isPassed === EventEmployeeGradeType.FAILED) ||
                eh.isCommissioning ||
                eh.event.type === EventType.OJT_TRAINING ||
                eh.event.type === EventType.PRESCREENING ||
                !eh.evaluatedAt
              }
            />
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
