import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ApartmentOutlined,
  InboxOutlined,
  StarOutlined,
  TranslationOutlined
} from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Tag, Typography } from "antd";
import { EventTypeTag } from "@components/common/events/EventTypeTag";
import DownloadLink from "@components/common/DownloadLink";

import { EventData, EventType } from "@models/event";

import useDateFormatter from "@hooks/useDateFormat";

interface Props {
  isUserContractor?: boolean;
  canSetSubcontractors?: boolean;
  eventData?: EventData;
  isLoading: boolean;
  onSetSubcontractor?: () => void;
}

export const ContractorEventInfoSection: React.FC<Props> = ({
  eventData,
  ...props
}) => {
  const { i18n, t } = useTranslation();
  const { formatter } = useDateFormatter();
  const [isSubcontractorsShown, setIsSubcontractorsShown] = useState(false);

  const eventMainInfo = [
    { label: t("events.host"), value: eventData?.trainingCenter?.name },
    {
      label: t("events.city"),
      value:
        eventData?.trainingCenter?.city &&
        (i18n.language === "en"
          ? eventData?.trainingCenter?.city.nameEn
          : eventData?.trainingCenter?.city.nameRu)
    },
    { label: t("events.academicHours"), value: eventData?.academicHours },
    {
      label: t("events.csrPhase"),
      value: eventData?.csrPhase
        ? t("events.phase", {
            no: eventData.csrPhase
          })
        : t("none"),
      isHidden: eventData?.type === EventType.OJT_TRAINING
    }
  ];

  const eventSecondaryInfo = [
    {
      label: t("events.discipline"),
      icon: <InboxOutlined style={{ fontSize: "32px" }} />,
      value:
        eventData &&
        (i18n.language === "en"
          ? eventData.speciality?.nameEn
          : eventData.speciality?.nameRu)
    },
    {
      label: t("events.competencyLevel"),
      icon: <ApartmentOutlined style={{ fontSize: "32px" }} />,
      value: eventData?.competencyLevel
        ? t(`competencyLevels.${eventData.competencyLevel}`)
        : ""
    },
    {
      label: t("events.standard"),
      icon: <StarOutlined style={{ fontSize: "32px" }} />,
      value: eventData?.standard
    },
    {
      label: t("events.language"),
      icon: <TranslationOutlined style={{ fontSize: "32px" }} />,
      value: eventData && t(`languages.${eventData.languageCode}`)
    }
  ];

  const eventTrainingCenterInfo = [
    {
      label: t("name"),
      value: eventData?.createdBy?.fullname
    },
    {
      label: t("email"),
      value: eventData?.createdBy?.email
    },
    {
      label: t("phoneNumber"),
      value: eventData?.createdBy?.phoneNumber
    }
  ];

  return (
    <Card
      title={
        eventData && (
          <Space align="start" size="middle">
            <Typography.Title level={4}>{eventData.name}</Typography.Title>
            <Typography.Text style={{ fontSize: "12px", color: "#fb8c00" }}>
              ID: {eventData.id}
            </Typography.Text>
            <Tag color="blue">{t(`events.statuses.${eventData.status}`)}</Tag>
          </Space>
        )
      }
      loading={props.isLoading || !eventData}
    >
      {eventData && (
        <>
          <Row wrap>
            <Col
              span={15}
              style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Space style={{ width: "100%", marginBottom: "16px" }}>
                <EventTypeTag
                  eventType={eventData.type}
                  isCommissioning={eventData.isCommissioning}
                />
                <Row wrap gutter={[12, 4]} justify="start">
                  {eventMainInfo
                    .filter((e) => !!e.value)
                    .map(
                      (e) =>
                        !e.isHidden && (
                          <Col key={e.label}>
                            <Space>
                              <Typography.Text
                                type="secondary"
                                style={{ fontSize: "12px" }}
                              >
                                {e.label}
                              </Typography.Text>
                              <Typography.Text strong>
                                {e.value}
                              </Typography.Text>
                            </Space>
                          </Col>
                        )
                    )}
                  <Col span={24}>
                    <Space>
                      <Space>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: "12px" }}
                        >
                          {t("from")}
                        </Typography.Text>
                        <Typography.Text strong>
                          {formatter(eventData.dateTimeStart)}
                        </Typography.Text>
                      </Space>
                      <Space>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: "12px" }}
                        >
                          {t("to")}
                        </Typography.Text>
                        <Typography.Text strong>
                          {formatter(eventData.dateTimeEnd)}
                        </Typography.Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Space>

              {eventData.description && (
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px" }}
                  >
                    {t("events.description")}:
                  </Typography.Text>
                  <Typography.Text strong>
                    {eventData.description}
                  </Typography.Text>
                </Space>
              )}

              {eventData.files && (
                <div>
                  <br />
                  <Space direction="vertical">
                    {eventData.files?.map((f, i) => (
                      <DownloadLink
                        key={f.id}
                        size="middle"
                        label={f.uniqueFileName}
                        fileName={f.uniqueFileName}
                        url={`/api/event/${eventData.id}/attachment/${f.uniqueFileName}`}
                      />
                    ))}
                  </Space>
                </div>
              )}
            </Col>
            <Col span={9}>
              <Row wrap justify="space-around" gutter={[8, 20]}>
                {eventSecondaryInfo.map((e) => (
                  <Col span={12} key={e.label}>
                    {e.value && (
                      <>
                        <Space
                          size="small"
                          direction="vertical"
                          align="center"
                          style={{ width: "100%" }}
                        >
                          <Typography.Text>{e.icon}</Typography.Text>
                          <Typography.Text
                            strong
                            style={{ textAlign: "center", display: "block" }}
                          >
                            {e.value}
                          </Typography.Text>
                          <Typography.Text
                            type="secondary"
                            style={{
                              fontSize: "12px",
                              textAlign: "center"
                            }}
                          >
                            {e.label}
                          </Typography.Text>
                        </Space>
                      </>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Space style={{ marginBottom: "12px" }} direction="vertical">
            {eventTrainingCenterInfo.map(
              (e) =>
                e.value && (
                  <Space key={e.label}>
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "12px" }}
                    >
                      {e.label}:
                    </Typography.Text>
                    <Typography.Text strong>{e.value}</Typography.Text>
                  </Space>
                )
            )}

            <Space>
              <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                {t("events.availableFor")}:
              </Typography.Text>
              <Typography.Text strong>
                {eventData.subcontractors?.length ? (
                  <Typography.Text strong style={{ marginRight: "12px" }}>
                    {eventData.subcontractors[0].name}
                  </Typography.Text>
                ) : (
                  "-"
                )}
                {isSubcontractorsShown &&
                  eventData.subcontractors?.slice(1).map((s) => (
                    <Typography.Text strong style={{ marginRight: "12px" }}>
                      {s.name}
                    </Typography.Text>
                  ))}
                {eventData.subcontractors &&
                  eventData.subcontractors.length > 1 && (
                    <Button
                      size="small"
                      onClick={() =>
                        setIsSubcontractorsShown(!isSubcontractorsShown)
                      }
                    >
                      {isSubcontractorsShown
                        ? t("showLess")
                        : t("andQuantityMore", {
                            quantity: eventData.subcontractors.length - 1
                          })}
                    </Button>
                  )}
              </Typography.Text>
            </Space>
          </Space>
          <Row style={{ marginBottom: "12px" }}>
            {props.isUserContractor && (
              <Button
                disabled={!props.canSetSubcontractors}
                type="primary"
                onClick={props.onSetSubcontractor}
              >
                {t("events.setSubcontractor")}
              </Button>
            )}
          </Row>
          <Row style={{ marginBottom: "12px" }}>
            <Typography.Text style={{ fontSize: "14px", color: "#049aff" }}>
              {t("events.registered")}{" "}
              {t("outOfCapacity", {
                quantity: eventData.capacityBusy,
                capacity: eventData.capacityMax
              })}
            </Typography.Text>
          </Row>
        </>
      )}
    </Card>
  );
};
