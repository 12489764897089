/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography
} from "antd";

import {
  CommissioningEventGrade,
  CommissioningEventRecommendation,
  EventDetails,
  EventEmployeeDetails,
  EventEmployeeGradeType,
  FailReasonType
} from "@models/event";

interface GradeData {
  employeeId: number;
  competencyLevel?: number;
  practicalStatus?: number;
  theoreticalStatus?: number;
  theoreticalGrade?: number;
  isPassed?: number;
  recommendation?: number;
  failReasonType?: number;
  failReason?: string;
}

interface FormValues {
  grades: GradeData[];
}

interface Props {
  isLoading?: boolean;
  errors?: { [key: string]: string };

  eventData?: EventDetails;
  eventEmployees?: EventEmployeeDetails[];
  onSubmit?: (values: CommissioningEventGrade[]) => void;

  editablePracticalGrade?: boolean;
}

const Column = Table.Column;

const COMPETENCY_LEVEL_OPTIONS = [
  {
    label: `events.grade.20`,
    value: EventEmployeeGradeType.TRAINEE_TECHNICIAN
  },
  {
    label: `events.grade.22`,
    value: EventEmployeeGradeType.JUNIOR_TECHNICIAN
  },
  {
    label: `events.grade.24`,
    value: EventEmployeeGradeType.TECHNICIAN
  },
  {
    label: `events.grade.26`,
    value: EventEmployeeGradeType.SENIOR_TECHNICIAN
  }
];

const GRADE_STATUS_OPTIONS = [
  {
    label: "events.gradeStatus.0",
    value: 0
  },
  {
    label: "events.gradeStatus.1",
    value: 1
  }
];

const RECOMMENDATION_OPTIONS = [
  {
    label: "events.recommendationStatus.1",
    value: CommissioningEventRecommendation.NOT_READY_FOR_UPSKILLING
  },
  {
    label: "events.recommendationStatus.2",
    value: CommissioningEventRecommendation.UPSKILLING_TO_JUNIOR_TECHNICIAN
  },
  {
    label: "events.recommendationStatus.3",
    value: CommissioningEventRecommendation.UPSKILLING_TO_TECHNICIAN
  },
  {
    label: "events.recommendationStatus.4",
    value: CommissioningEventRecommendation.UPSKILLING_TO_SENIOR_TECHNICIAN
  },
  {
    label: "events.recommendationStatus.5",
    value: CommissioningEventRecommendation.COMPLETED_NOT_NEED_FOR_UPSKILLING
  },
  {
    label: "events.recommendationStatus.6",
    value: CommissioningEventRecommendation.PENDING
  }
];

const FAIL_REASON_OPTIONS = [
  {
    value: FailReasonType.NO_SHOW,
    label: "events.failReason.1"
  },
  {
    value: FailReasonType.ATTENDANCE,
    label: "events.failReason.2"
  },
  {
    value: FailReasonType.UNDER_THE_INFLUENCE,
    label: "events.failReason.3"
  },
  {
    value: FailReasonType.PERFORMANCE,
    label: "events.failReason.4"
  },
  {
    value: FailReasonType.OTHER,
    label: "events.failReason.5"
  },
  {
    value: FailReasonType.NO_PRACTICE,
    label: "events.failReason.7"
  }
];

const TRAINING_GRADE_OPTIONS = [
  {
    label: `events.grade.1`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `events.grade.2`,
    value: EventEmployeeGradeType.PASSED
  }
];

const DEFAULT_FAIL_REASON_TYPE_VALUE = 0;

const DEFAULT_RECOMMENDATION_VALUE = 0;

export const CommissioningEventGradeForm = (props: Props) => {
  const [t] = useTranslation();
  const formik = useFormik<FormValues>({
    initialValues: {
      grades: []
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values.grades);
      }
    }
  });

  useEffect(() => {
    const initVal =
      props.eventEmployees?.map((ee) => {
        if (props.editablePracticalGrade) {
          return {
            employeeId: ee.employeeId,
            employeeGradeId: ee.employeeGradeId,
            competencyLevel: ee.competencyLevel,
            practicalStatus: ee.practicalStatus,
            theoreticalStatus: ee.theoreticalStatus,
            theoreticalGrade: ee.theoreticalGrade,
            isPassed: ee.isPassed,
            recommendation: ee.recommendation,
            failReasonType: ee.failReasonType,
            failReason: ee.failReason
          };
        } else {
          return { employeeId: ee.employeeId };
        }
      }) || [];

    formik.setFieldValue("grades", initVal);
  }, [props.eventEmployees]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Table
        dataSource={props.eventEmployees}
        rowKey="employeeId"
        pagination={false}
        scroll={{ x: "100%" }}
      >
        <Column
          title={t("events.participant")}
          render={(e: EventEmployeeDetails) => (
            <>
              {e.firstName}
              <br />
              {e.lastName}
              <br />
              <Typography.Text type="secondary">{e.iin}</Typography.Text>
            </>
          )}
        />

        <Column
          title={t("events.academicHours")}
          render={(e) => e.attendanceTotal}
        />

        <Column
          title={t("events.competencyLevel")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={
                props.errors?.[`[${i}].CompetencyLevel`] && "error"
              }
              help={props.errors?.[`[${i}].CompetencyLevel`]}
            >
              <Select
                disabled={props.editablePracticalGrade}
                value={formik.values.grades?.[i]?.competencyLevel}
                onChange={(v) => {
                  formik.setFieldValue(`grades[${i}].competencyLevel`, v);
                }}
              >
                {COMPETENCY_LEVEL_OPTIONS.map((go) => (
                  <Select.Option value={go.value} key={go.value}>
                    {t(go.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />

        <Column
          title={t("events.practicalStatus")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={
                props.errors?.[`[${i}].PracticalStatus`] && "error"
              }
              help={props.errors?.[`[${i}].PracticalStatus`]}
            >
              <Select
                value={formik.values.grades?.[i]?.practicalStatus}
                onChange={(v) => {
                  formik.setFieldValue(`grades[${i}].practicalStatus`, v);
                }}
              >
                {GRADE_STATUS_OPTIONS.map((gs) => (
                  <Select.Option value={gs.value} key={gs.value}>
                    {t(gs.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />

        <Column
          title={t("events.theoreticalStatus")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={
                props.errors?.[`[${i}].TheoreticalStatus`] && "error"
              }
              help={props.errors?.[`[${i}].TheoreticalStatus`]}
            >
              <Select
                disabled={props.editablePracticalGrade}
                value={formik.values.grades?.[i]?.theoreticalStatus}
                onChange={(v) => {
                  formik.setFieldValue(`grades[${i}].theoreticalStatus`, v);
                }}
              >
                {GRADE_STATUS_OPTIONS.map((gs) => (
                  <Select.Option value={gs.value} key={gs.value}>
                    {t(gs.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />

        <Column
          title={t("events.theoreticalGrade")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={
                props.errors?.[`[${i}].TheoreticalGrade`] && "error"
              }
              help={props.errors?.[`[${i}].TheoreticalGrade`]}
            >
              <InputNumber
                disabled={props.editablePracticalGrade}
                max={100}
                min={0}
                value={formik.values.grades?.[i]?.theoreticalGrade}
                onChange={(v) =>
                  formik.setFieldValue(`grades[${i}].theoreticalGrade`, v)
                }
              />
            </Form.Item>
          )}
        />

        <Column
          title={t("events.overallGrade")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={props.errors?.[`[${i}].IsPassed`] && "error"}
              help={props.errors?.[`[${i}].IsPassed`]}
            >
              <Select
                value={formik.values.grades?.[i]?.isPassed || "-"}
                onChange={(v) => {
                  formik.setFieldValue(`grades[${i}].isPassed`, v);
                  formik.setFieldValue(
                    `grades[${i}].failReasonType`,
                    DEFAULT_FAIL_REASON_TYPE_VALUE
                  );
                }}
              >
                {TRAINING_GRADE_OPTIONS.map((go) => (
                  <Select.Option value={go.value} key={go.value}>
                    {t(go.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />

        <Column
          title={t("events.failReason.failReason")}
          render={(_, __, i) =>
            formik.values.grades?.[i]?.isPassed ===
              EventEmployeeGradeType.FAILED && (
              <>
                <Form.Item
                  validateStatus={
                    props.errors?.[`[${i}].FailReasonType`] && "error"
                  }
                  help={props.errors?.[`[${i}].FailReasonType`]}
                >
                  <Select
                    value={
                      formik.values.grades?.[i]?.failReasonType ===
                      DEFAULT_FAIL_REASON_TYPE_VALUE
                        ? undefined
                        : formik.values.grades?.[i]?.failReasonType
                    }
                    onChange={(v) => {
                      formik.setFieldValue(`grades[${i}].failReasonType`, v);
                    }}
                  >
                    {FAIL_REASON_OPTIONS.map((fr) => (
                      <Select.Option value={fr.value} key={fr.value}>
                        {t(fr.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {formik.values.grades?.[i]?.failReasonType ===
                  FailReasonType.OTHER && (
                  <Form.Item
                    validateStatus={
                      props.errors?.[`[${i}].FailReason`] && "error"
                    }
                    help={props.errors?.[`[${i}].FailReason`]}
                  >
                    <Input
                      name={`grades[${i}].failReason`}
                      value={formik.values.grades?.[i]?.failReason}
                      onChange={formik.handleChange}
                    />
                  </Form.Item>
                )}
              </>
            )
          }
        />

        <Column
          title={t("events.recommendation")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={
                props.errors?.[`[${i}].recommendation`] && "error"
              }
              help={props.errors?.[`[${i}].recommendation`]}
            >
              <Select
                value={
                  formik.values.grades?.[i]?.recommendation ===
                  DEFAULT_RECOMMENDATION_VALUE
                    ? undefined
                    : formik.values.grades?.[i]?.recommendation
                }
                onChange={(v) => {
                  formik.setFieldValue(`grades[${i}].recommendation`, v);
                }}
              >
                {RECOMMENDATION_OPTIONS.map((gs) => (
                  <Select.Option value={gs.value} key={gs.value}>
                    {t(gs.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />
      </Table>

      <br />

      <Row justify="end">
        <Button type="primary" htmlType="submit" loading={props.isLoading}>
          {t("save")}
        </Button>
      </Row>
    </Form>
  );
};
