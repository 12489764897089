import { useEffect } from "react";
import { UseMutateFunction } from "react-query";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Select } from "antd";

import {
  OnlineTrainingReportResult,
  OnlineTrainingReportFormValues,
  Rev
} from "@models/onlineTrainingReports";

interface Props {
  handleCloseModal: () => void;
  onSubmit: UseMutateFunction<unknown, unknown, OnlineTrainingReportFormValues>;
  currentReport: OnlineTrainingReportResult;
  isLoading?: boolean;
  errors: { [key: string]: string };
}

const { Option } = Select;

const OnlineTrainingReportEmployeeForm: React.FC<Props> = ({
  handleCloseModal,
  isLoading,
  onSubmit,
  currentReport,
  errors
}) => {
  const [t] = useTranslation();

  const formik = useFormik<OnlineTrainingReportFormValues>({
    initialValues: {
      department: "",
      employeeNumber: "",
      firstName: "",
      iin: "",
      lastName: "",
      rev: 1,
      supervisor: "",
      title: "",
      id: 0
    },
    onSubmit: (values) =>
      onSubmit({
        ...values,
        employeeNumber: values.employeeNumber ? values.employeeNumber : 0,
        onlineTrainingReportId: currentReport.id
      })
  });

  useEffect(() => {
    formik.setErrors(errors);
  });

  return (
    <Form
      layout="vertical"
      onSubmitCapture={() => formik.submitForm()}
      initialValues={formik.initialValues}
    >
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <Form.Item
            label={t("firstName")}
            validateStatus={formik.errors.firstName && "error"}
            help={formik.errors.firstName}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={t("lastName")}
            validateStatus={formik.errors.lastName && "error"}
            help={formik.errors.lastName}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <Form.Item
            label={t("iin")}
            validateStatus={formik.errors.iin && "error"}
            help={formik.errors.iin}
          >
            <Input
              name="iin"
              style={{ width: "100%" }}
              placeholder="999999999999"
              value={formik.values.iin}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={t("employeeNumber")}
            validateStatus={formik.errors.employeeNumber && "error"}
            help={formik.errors.employeeNumber}
          >
            <Input
              name="employeeNumber"
              placeholder="0000"
              value={formik.values.employeeNumber}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <Form.Item
            label={t("department")}
            validateStatus={formik.errors.department && "error"}
            help={formik.errors.department}
          >
            <Input
              name="department"
              value={formik.values.department}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={t("title")}
            validateStatus={formik.errors.title && "error"}
            help={formik.errors.title}
          >
            <Input
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <Form.Item
            label={t("supervisor")}
            validateStatus={formik.errors.supervisor && "error"}
            help={formik.errors.supervisor}
          >
            <Input
              name="supervisor"
              value={formik.values.supervisor}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Rev">
            <Select
              value={formik.values.rev}
              onChange={(value: number) => formik.setFieldValue("rev", value)}
            >
              <Option value={Rev.LOCAL}>{t("local")}</Option>
              <Option value={Rev.EXPAT}>{t("expat")}</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Button onClick={handleCloseModal}>{t("cancel")}</Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("add")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OnlineTrainingReportEmployeeForm;
