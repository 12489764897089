export const enCommon = {
  loginBtn: "Login",
  logoutBtn: "Logout",

  contactUs: "Contact us",

  aboutUs: "About us",
  userGuide: "User guide",
  whatsNew: "What's new",
  menuContract: "Contract",

  phone: "Tel. number:",

  submit: "Submit",
  cancel: "Cancel",
  ok: "OK",
  yes: "Yes",
  no: "No",
  back: "Back",
  next: "Next",
  add: "Add",
  apply: "Apply",
  viewDetails: "View details",

  all: "All",

  create: "Create",
  register: "Register",
  edit: "Edit",
  change: "Change",
  delete: "Delete",
  save: "Save",
  search: "Search",
  filter: "Filter",
  userExport: "Export users",

  actions: "Actions",
  moreInfo: "More info",
  noData: "No data",
  download: "Download",
  retry: "Retry",

  none: "None",

  email: "Email",
  name: "Name",
  fullName: "Full Name",
  firstName: "First Name",
  lastName: "Last Name",
  middleName: "Middle Name",
  jobTitle: "Job Title",
  phoneNumber: "Phone number",
  workPhoneNumber: "Work phone number",
  department: "Department",
  employeeNumber: "Employee Number",
  education: "Education",
  additionalEducation: "Additional Education",
  dateOfBirth: "Date Of Birth",
  maritalStatus: "Marital Status",
  workExperience: "Work Experience",
  personalQualities: "Personal Qualities",
  additionalInformation: "Additional Information",
  knownLanguages: "Languages",

  csrContractor: "CSR Contractor",
  otherCompany: "Other company",
  employmentStatus: "Employment Status",
  contractorSubcontractor: "Contractor / Subcontractor",

  local: "Local",
  expat: "Expat",

  password: "Password",
  oldPassword: "Old password",
  newPassword: "New password",
  confirmPassword: "Confirm password",

  country: "Country",
  city: "City",
  address: "Address",
  language: "Language",
  standard: "Standard",
  dateFrom: "Date from",
  dateTo: "Date to",

  count: "Count",

  from: "from",
  to: "to",

  availableSeats: "Available seats",

  outOfCapacity: "{{quantity}} out of {{capacity}}",

  registrationDate: "Registration date: {{date}}",
  dateAdded: "Date added",
  fromToRange: "from {{from}} to {{to}}",

  discipline: "Discipline",
  disciplines: "Disciplines",
  instructor: "Instructor",
  instructors: "Instructors",
  numberOfTimesTrained: "Number of times trained",
  supervisor: "Supervisor",
  training: "Training",

  eventTypes: {
    eventTypes: "Event types",
    0: "Undefined",
    1: "Assessment",
    2: "Training",
    3: "Pre-screening",
    4: "OJT Training",
    11: "Commissioning - Assessment",
    12: "Commissioning - Training"
  },

  competencyLevels: {
    null: "–",
    competencyLevel: "Competency level",

    2: "Basic",
    3: "Intermediate",
    4: "Advanced",
    5: "Competent",
    6: "Proficient",
    7: "Helper",
    20: "Trainee Technician",
    22: "Junior Technician",
    24: "Technician",
    26: "Senior Technician"
  },

  customCompetencyLevels: {
    1: "Standard",
    6: "Intermediate and advanced"
  },

  languages: {
    1: "Russian",
    2: "English",
    3: "Kazakh"
  },

  totalCount: "Total: {{total}}",
  total: "Total",

  csr: "CSR",
  csrPhase: "CSR Phase",
  commissioning: "Commissioning",

  error: {
    errorDownload: "Unable to download the file, try later.",
    errorRequest:
      "Unable to perform to the request, check your internet connection",
    errorOccured: "Error occured",
    titleWithStatus: "Error {{status}}",
    fieldRequired: "The {{field}} is required",
    participantsError: "The request contains duplicate participants"
  },

  userAgreement: {
    acceptCheckbox: "I accept the user agreemnt",
    proceed: "Proceed",
    userAgreement: "User agreement"
  },

  bin: "BIN",
  iin: "IIN",
  status: "Status",

  contractor: "Contractor",
  subcontractor: "Subcontractor",
  practicalGrade: "Practical Grade",
  theoreticalGrade: "Theoretical Grade",
  overallGrade: "Overall Grade",
  failReason: "Fail Reason",
  craftWorkerStatus: "Status",
  theoreticalStatus: "Theoretical Status",
  practicalStatus: "Practical Status",
  recommendation: "Recommendation",
  other: "Other",

  createdAt: "Creation date",
  readMore: "Read more",
  showLess: "Show less",
  hide: "Hide",
  andQuantityMore: "and {{quantity}} more",

  idleLogout: "You were logged out because of your inactivity",

  sorting: {
    sortBy: "Sort by",
    1: "Creation date ↓",
    2: "Creation date ↑"
  },

  inProgress: "In progress",
  completed: "Completed",
  failed: "Failed",

  title: "Title",

  expand: "Expand",
  fileSizeLimit: "File size cannot exceed {{size}}Mb",
  select: "Select"
};
