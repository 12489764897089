import picture31 from "@assets/images/user-guide/3.31.png";
import picture32 from "@assets/images/user-guide/3.32.png";
import picture33 from "@assets/images/user-guide/3.33.png";

export const subcontractors = [
  {
    title: "Subcontractors",
    html: `<p dir="ltr">
    Picture 3.31
</p>
<p dir="ltr">
    <img
        src=${picture31}
        width="624"
        height="173"
    />
</p>
`
  },
  {
    title: "How to register a subcontractor?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Subcontractors
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press <b>Register subcontractor</b> button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out fields, set permissions and press <b>Register</b> button
        </p>
    </li>
</ol>
<br/>
<br/>
<p dir="ltr">
    Picture 3.32
</p>
<p dir="ltr">
    <img
        src=${picture32}
        width="624"
        height="445"
    />
</p>
`
  },
  {
    title: "What’s on the Subcontractors page?",
    html: `<p dir="ltr">
    Once you press on Subcontractor name you will be redirected to
    Subcontractor details page with the following information:
</p>
<p dir="ltr">
    Picture 3.33
</p>
<p dir="ltr">
    <img
        src=${picture33}
        width="624"
        height="357"
    />
</p>
`
  },
  {
    title: "How to edit members?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Subcontractors
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Subcontractor
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press <b>Edit</b> button
        </p>
    </li>
    <li>
        <p>
            Edit fields and press <b>Save</b> button
        </p>
    </li>
</ol>

`
  }
];
