import { useState, useMemo, useCallback } from "react";

import moment, { Moment } from "moment";
import { SelectProps } from "antd/lib/select";
import { DatePickerProps } from "antd/lib/date-picker";
import { CustomTagProps } from "rc-select/lib/BaseSelect";

import { Tag, DatePicker, Select } from "antd";

interface Props {
  onChange: (value: any[] | string | object) => void;
  value: string[];
  format?: string;
  disabledDate?: (date: Moment) => boolean;
  selectProps?: SelectProps<string[]>;
  datePickerProps?: DatePickerProps;
}

const FORMAT = "YYYY-MM-DD";

const MultiDatePicker: React.FC<Props> = ({
  value: selectedDate = [],
  onChange,
  disabledDate,
  format = "YYYY-MM-DD",
  selectProps = {},
  datePickerProps = {}
}) => {
  const [open, setOpen] = useState(false);
  const formatDate = useCallback(
    (value: Moment) => value.startOf("day").format(FORMAT),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [format]
  );
  const formattedSelectedDates = useMemo(() => {
    return selectedDate.map((date) => formatDate(moment(date)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const onValueChange = (date: Moment | null) => {
    if (date) {
      const t = formatDate(date);
      const index = formattedSelectedDates.indexOf(t);
      const clone = [...selectedDate];
      if (index > -1) {
        clone.splice(index, 1);
      } else {
        clone.push(date.format());
      }
      onChange && onChange(clone);
    }
  };

  const dateRender = (currentDate: Moment) => {
    const isSelected = formattedSelectedDates.includes(formatDate(currentDate));
    return (
      <div
        className={"ant-picker-cell-inner"}
        style={
          isSelected
            ? {
                position: "relative",
                zIndex: 2,
                display: "inlineBlock",
                width: "24px",
                height: "22px",
                lineHeight: "22px",
                backgroundColor: "#1890ff",
                color: "#fff",
                margin: "auto",
                borderRadius: "2px",
                transition: "background 0.3s, border 0.3s"
              }
            : {
                backgroundColor: "transparent",
                color:
                  disabledDate && disabledDate(currentDate)
                    ? "rgba(0, 0, 0, 0.25)"
                    : "rgba(0, 0, 0, 0.85)"
              }
        }
      >
        {currentDate.date()}
      </div>
    );
  };

  const renderTag = ({ onClose, value }: CustomTagProps) => {
    const handleClose = () => {
      onClose();
      onChange && onChange(selectedDate.filter((t) => t !== value));
    };
    return (
      <Tag onClose={handleClose} closable>
        {moment(value as moment.MomentInput).format(FORMAT)}
      </Tag>
    );
  };

  return (
    <Select
      allowClear
      placeholder={"Select dates"}
      {...selectProps}
      mode="multiple"
      value={selectedDate}
      onClear={() => onChange && onChange([])}
      tagRender={renderTag}
      open={open}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      dropdownMatchSelectWidth={false}
      dropdownClassName={"multipleDropdownClassName"}
      dropdownStyle={{ height: "270px", width: "280px", minWidth: "0" }}
      dropdownRender={() => {
        return (
          <DatePicker
            {...datePickerProps}
            format={(value) => value.format(FORMAT)}
            onChange={onValueChange}
            value={null}
            open
            dateRender={dateRender}
            style={{ ...datePickerProps.style, visibility: "hidden" }}
            getPopupContainer={() =>
              document.getElementsByClassName(
                "multipleDropdownClassName"
              )[0] as HTMLElement
            }
            disabledDate={disabledDate}
          />
        );
      }}
    />
  );
};

export default MultiDatePicker;
