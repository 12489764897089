import { FlagOutlined } from "@ant-design/icons";

import { general } from "@utils/userguides/ct/general";
import { reports } from "@utils/userguides/ct/reports";
import { events } from "@utils/userguides/ct/events";
import { employeePassport } from "@utils/userguides/ct/employeePassport";
import { certificateSearch } from "@utils/userguides/ct/certificateSearch";
import { members } from "@utils/userguides/ct/members";
import { requests } from "@utils/userguides/ct/requests";
import { profile } from "@utils/userguides/ct/profile";

export const ctUserguides = [
  { title: "general", data: general, icon: <FlagOutlined /> },
  { title: "reports", data: reports, icon: <FlagOutlined /> },
  { title: "events", data: events, icon: <FlagOutlined /> },
  { title: "members", data: members, icon: <FlagOutlined /> },
  {
    title: "employeePassport",
    data: employeePassport,
    icon: <FlagOutlined />
  },
  {
    title: "certificatesSearch",
    data: certificateSearch,
    icon: <FlagOutlined />
  },
  { title: "requests", data: requests, icon: <FlagOutlined /> },
  { title: "profilesCT", data: profile, icon: <FlagOutlined /> }
];
