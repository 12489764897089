import { isBefore, isEqual, isValid } from "date-fns";
import { differenceInDays } from "date-fns/esm";

export const getDatesArray = (startDate: Date, endDate: Date) => {
  if (!isValid(startDate) || !isValid(endDate)) {
    return [];
  }

  const daysBetween = differenceInDays(endDate, startDate);

  const rangedDatesArray = Array.from(new Array(daysBetween + 1)).map(
    (_, i) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      return date;
    }
  );

  return rangedDatesArray;
};

export const getEventDatesRanges = (
  eventStartDateTime: Date,
  eventEndDateTime: Date,
  lastEditedDateTime: Date | null
) => {
  const editedAttendancesDates = lastEditedDateTime
    ? getDatesArray(eventStartDateTime, lastEditedDateTime)
    : [];

  const today = new Date();
  const todayDateZeroTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const eventEndDateZeroTime = new Date(
    eventEndDateTime.getFullYear(),
    eventEndDateTime.getMonth(),
    eventEndDateTime.getDate()
  );

  const getFirstEditableDateTime = () => {
    if (lastEditedDateTime) {
      const lastEditedDateZeroTime = new Date(
        lastEditedDateTime.getFullYear(),
        lastEditedDateTime.getMonth(),
        lastEditedDateTime.getDate()
      );

      return isEqual(todayDateZeroTime, lastEditedDateZeroTime) ||
        isBefore(eventEndDateZeroTime, todayDateZeroTime)
        ? new Date("Invalid Date")
        : createDateWithEventStartTime(
            lastEditedDateTime.getFullYear(),
            lastEditedDateTime.getMonth(),
            lastEditedDateTime.getDate() + 1,
            eventStartDateTime
          );
    }
    return eventStartDateTime;
  };

  const getLastEditableDateTime = () =>
    isBefore(todayDateZeroTime, eventEndDateZeroTime)
      ? createDateWithEventStartTime(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          eventStartDateTime
        )
      : eventEndDateTime;

  const editableAttendancesDates = getDatesArray(
    getFirstEditableDateTime(),
    getLastEditableDateTime()
  );

  return { editedAttendancesDates, editableAttendancesDates };
};

export const createDateWithEventStartTime = (
  year: number,
  month: number,
  date: number,
  eventStartDateTime: Date
) =>
  new Date(
    year,
    month,
    date,
    eventStartDateTime.getHours(),
    eventStartDateTime.getMinutes(),
    eventStartDateTime.getSeconds() // getting event start time in order to avoid timezone issuess
  );

export const setZeroedTime = (date: string) => {
  const formattedDate = new Date(date);

  formattedDate.setHours(0);
  formattedDate.setMinutes(0);
  formattedDate.setSeconds(0);

  return formattedDate;
};
