import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  BarChartOutlined,
  MailOutlined,
  TeamOutlined,
  IdcardOutlined,
  SolutionOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import ApplicationLayout from "@layouts/ApplicationLayout";
import MembersPage from "@pages/training-center/members/MembersPage";
import { ProfilePage } from "@pages/ProfilePage";
import { RequestsListPage } from "@pages/training-center/requests/RequestsList";
import { RequestViewPage } from "@pages/training-center/requests/RequestView";
import { EmployeesPage } from "@pages/training-center/employees/EmployeesPage";
import { EmployeeDetailsPage } from "@pages/training-center/employees/EmployeeDetailsPage";
import { AllEventsPage } from "@pages/training-center/events/AllEventsPage";
import { EventDetailsPage } from "@pages/training-center/events/EventDetailsPage";
import { UpcomingEventsPage } from "@pages/training-center/events/UpcomingEventsPage";
import { InprogressEventsPage } from "@pages/training-center/events/InprogressEventsPage";
import { CompletedEventsPage } from "@pages/training-center/events/CompletedEventsPage";
import { EvaluatedEventsPage } from "@pages/training-center/events/EvaluatedEventsPage";
import { CancelledEventsPage } from "@pages/training-center/events/CancelledEventsPage";
import { ActivatedEventsPage } from "@pages/training-center/events/ActivatedEventsPage";
import ErrorPage from "@pages/ErrorPage";
import { TrainingReportsPage } from "@pages/training-center/tc-reports/TrainingReportsPage";
import { CertificateReportsPage } from "@pages/training-center/tc-reports/CertificateReportsPage";
import { ProfileReportsPage } from "@pages/training-center/tc-reports/ProfileReportsPage";
import { ProtocolReportsPage } from "@pages/training-center/tc-reports/ProtocolReportsPage";
import CertificateSearchPage from "@pages/training-center/CertificateSearchPage";
import { CommissioningReportPage } from "@pages/training-center/reports/CommissioningReportPage";
import DatadumpPage from "@pages/training-center/reports/DatadumpPage";
import { PerformanceReportPage } from "@pages/training-center/reports/PerformanceReportPage";
import { ReportDetailsPage } from "@pages/training-center/tc-reports/ReportDetailsPage";
import { CraftProfilesListPage } from "@pages/training-center/craftProfiles/CraftProfilesListPage";
import CraftProfilesCardPage from "@pages/training-center/craftProfiles/CraftProfilesCardPage";
import { CtUserGuide } from "@pages/training-center/UserGuide";
import { AboutUs } from "@pages/AboutUs";
import { ContactUs } from "@pages/ContactUs";
import { AdminWhatNew } from "@pages/admin/whatNew";
import { TCWhatNew } from "@pages/training-center/whatNew";

import { PERMISSIONS } from "@models/permissions";
import { RouteItem } from "@models/routeItem";

import { UserContext } from "@contexts/UserContext";

const trainingCenterRoutes: RouteItem[] = [
  {
    id: "profile",
    path: "/profile",
    component: ProfilePage
  },
  {
    id: "reports",
    text: "reports.reports",
    icon: <BarChartOutlined />,
    isNavItem: true,
    items: [
      {
        id: "reportsPerformanceReport",
        text: "reports.performanceReports",
        path: "/contractor/reports/performance",
        isNavItem: true,
        component: PerformanceReportPage
      },
      {
        id: "commissioningReport",
        text: "reports.commissioningPerformance",
        path: "/tc/reports/commissioning",
        isNavItem: true,
        component: CommissioningReportPage
      },
      {
        id: "datadumpReport",
        text: "reports.datadumpSubtitle",
        path: "/tc/reports/datadump",
        isNavItem: true,
        component: DatadumpPage
      }
    ],
    permissions: [PERMISSIONS.REPORT_VIEW]
  },
  {
    id: "events",
    text: "events.events",
    icon: <CalendarOutlined />,
    isNavItem: true,
    items: [
      {
        id: "all-events",
        text: "events.all",
        path: "/tc/events/all",
        isNavItem: true,
        component: AllEventsPage
      },
      {
        id: "upcoming-events",
        text: "events.upcoming",
        path: "/tc/events/upcoming",
        isNavItem: true,
        component: UpcomingEventsPage
      },
      {
        id: "inprogress-events",
        text: "events.inprogress",
        path: "/tc/events/inprogress",
        isNavItem: true,
        component: InprogressEventsPage
      },
      {
        id: "completed-events",
        text: "events.completed",
        path: "/tc/events/completed",
        isNavItem: true,
        component: CompletedEventsPage
      },
      {
        id: "evaluated-events",
        text: "events.evaluated",
        path: "/tc/events/evaluated",
        isNavItem: true,
        component: EvaluatedEventsPage
      },
      {
        id: "cancelled-events",
        text: "events.cancelled",
        path: "/tc/events/cancelled",
        isNavItem: true,
        component: CancelledEventsPage
      },
      {
        id: "activated-events",
        text: "events.activated",
        path: "/tc/events/activated",
        isNavItem: true,
        component: ActivatedEventsPage
      }
    ],
    permissions: [PERMISSIONS.EVENT_VIEW]
  },
  {
    id: "userGuide",
    path: "/tc/user-guide",
    component: CtUserGuide
  },
  {
    id: "contactUs",
    path: "/tc/contact-us",
    component: ContactUs
  },
  {
    id: "aboutUs",
    path: "/tc/about-us",
    component: AboutUs
  },
  {
    id: "whatNew",
    path: "/tc/what-new",
    component: TCWhatNew
  },
  {
    id: "event-details",
    path: "/tc/events/:id",
    component: EventDetailsPage
  },
  {
    id: "members",
    text: "members.members",
    icon: <TeamOutlined />,
    path: "/tc/members",
    isNavItem: true,
    component: MembersPage,
    permissions: [PERMISSIONS.MEMBER_VIEW]
  },
  {
    id: "employees",
    text: "employees.employeePassport",
    path: "/tc/employees",
    icon: <IdcardOutlined />,
    isNavItem: true,
    component: EmployeesPage,
    permissions: [PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW]
  },
  {
    id: "employee-details",
    path: "/tc/employees/:id",
    component: EmployeeDetailsPage
  },
  {
    id: "certificate-search",
    text: "certificates.search",
    path: "/tc/certificate",
    icon: <SolutionOutlined />,
    isNavItem: true,
    component: CertificateSearchPage
  },
  {
    id: "requests",
    text: "requests.requests",
    icon: <MailOutlined />,
    path: "/tc/requests",
    isNavItem: true,
    component: RequestsListPage,
    permissions: [PERMISSIONS.TICKET_VIEW]
  },
  {
    id: "requests",
    text: "requests.request",
    path: "/tc/requests/:id",
    component: RequestViewPage
  },
  {
    id: "tc-report-details",
    path: "/tc/tc-report/:id",
    component: ReportDetailsPage
  },
  {
    id: "tcReports",
    text: "reports.tcReports",
    icon: <BarChartOutlined />,
    isNavItem: true,
    items: [
      {
        id: "trainingReports",
        text: "reports.trainingReport",
        path: "/tc/tc-reports/training",
        isNavItem: true,
        component: TrainingReportsPage
      },
      {
        id: "protocolReports",
        text: "reports.protocol",
        path: "/tc/tc-reports/protocol",
        isNavItem: true,
        component: ProtocolReportsPage
      },
      {
        id: "profileReports",
        text: "reports.profile",
        path: "/tc/tc-reports/profile",
        isNavItem: true,
        component: ProfileReportsPage
      },
      {
        id: "certificateReports",
        text: "reports.certificate",
        path: "/tc/tc-reports/certificate",
        isNavItem: true,
        component: CertificateReportsPage
      }
    ]
  },
  {
    id: "craftProfiles",
    text: "craftProfiles.profiles",
    icon: <TeamOutlined />,
    path: "/tc/craft-profiles",
    component: CraftProfilesListPage,
    isNavItem: true
  },
  {
    id: "craftProfileCard",
    text: "craftProfiles.profile",
    path: "/tc/craft-profile/:id",
    component: CraftProfilesCardPage,
    isNavItem: false
  }
];

const TrainingCenterRoutes: React.FC = () => {
  const { user } = useContext(UserContext);
  const userPermissions = user?.permissions.map((p) => p.permission);
  const filteredTrainingCenterRoutes = trainingCenterRoutes.filter((route) =>
    route.permissions
      ? route.permissions.every((p) => userPermissions?.includes(p))
      : true
  );
  return (
    <ApplicationLayout navItems={filteredTrainingCenterRoutes}>
      <Switch>
        {filteredTrainingCenterRoutes.map((route, i) =>
          route.items ? (
            route.items.map((item, i) => (
              <Route
                exact
                path={item.path}
                component={item.component}
                key={i}
              />
            ))
          ) : (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={i}
            />
          )
        )}
        <Route exact path="/" render={() => <Redirect to="/profile" />} />
        <Route component={ErrorPage} />
      </Switch>
    </ApplicationLayout>
  );
};

export default TrainingCenterRoutes;
