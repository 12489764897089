import qs from "qs";
import axios from "axios";

import { Member } from "@models/member";
import { UpdateSubcontractorMemberPayload } from "@models/subcontractorMember";

const getSubcontractorMembers = ({
  contractorId,
  subcontractorId
}: {
  contractorId: number;
  subcontractorId: number;
}) =>
  axios.get<Member[]>(
    `/api/contractor/${contractorId}/subcontractor/${subcontractorId}/member`
  );

const updateSubcontractorMember = ({
  contractorId,
  subcontractorId,
  memberId,
  member
}: {
  contractorId: number;
  subcontractorId: number;
  memberId: number;
  member: UpdateSubcontractorMemberPayload;
}) =>
  axios.post(
    `/api/contractor/${contractorId}/subcontractor/${subcontractorId}/member/${memberId}`,
    member
  );

const deleteSubcontractorMember = ({
  contractorId,
  subcontractorId,
  memberId
}: {
  contractorId: number;
  subcontractorId: number;
  memberId: number;
}) =>
  axios.delete(
    `/api/contractor/${contractorId}/subcontractor/${subcontractorId}/member/${memberId}`
  );

const deleteSubcontractorMemberInvite = ({
  contractorId,
  subcontractorId,
  email
}: {
  contractorId: number;
  subcontractorId: number;
  email: string;
}) =>
  axios.delete(
    `/api/contractor/${contractorId}/subcontractor/${subcontractorId}/member/invite/delete?${qs.stringify(
      { email }
    )}`
  );

export const subcontractorMemberApi = {
  getSubcontractorMembers,
  updateSubcontractorMember,
  deleteSubcontractorMember,
  deleteSubcontractorMemberInvite
};
