import { CSSProperties, FC, useCallback, useEffect, useState } from "react";

import { ChartDataSets } from "chart.js";

import ReportBarChart from "@components/ui/charts/ReportBarChart";
import { Popover } from "antd";

type DatasetsWithWidth = {
  label?: string;
  labels?: string[];
  value: number;
  width: number;
  subdataset: ChartDataSets;
}[];

type ReportBarChartExpandableProps = {
  max?: number;
  labels?: string[];
  label?: string;
  datasets: {
    labels?: string[];
    value: number;
    subdataset: ChartDataSets;
  }[];
  showDataLabels: boolean;
};

type BarExpandableProps = {
  label: string;
  value: number;
  width: number;
  expanded: boolean;
  onExpandClick: () => void;
};

const BarExpandable: FC<BarExpandableProps> = ({
  label,
  value,
  width,
  expanded,
  onExpandClick
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleExpandClick = useCallback(() => onExpandClick(), []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        fontSize: 12,
        color: "#555"
      }}
    >
      <button
        style={{
          display: "flex",
          paddingTop: "0.25em",
          background: "none",
          border: "none",
          width: "33%",
          cursor: "pointer"
        }}
        onClick={handleExpandClick}
      >
        <span>{expanded ? "-" : "+"} </span>

        <Popover content={label} trigger="hover">
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginRight: "0.5em"
            }}
          >
            {label}
          </span>
        </Popover>
      </button>
      <div style={{ width: "64.5%" }}>
        <div
          style={{
            display: "flex"
          }}
        >
          <span
            style={{
              width: `${width}%`,
              height: 25
            }}
          >
            <div></div>
            <span>
              <canvas
                height={25}
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "rgb(1, 184, 170)"
                }}
              ></canvas>
            </span>
          </span>
          <span
            style={{
              marginLeft: 2,
              paddingTop: "0.25em",
              paddingLeft: "0.5em",
              fontWeight: "bold"
            }}
          >
            {value}
          </span>
        </div>
      </div>
    </div>
  );
};

const ReportBarChartExpandable: FC<ReportBarChartExpandableProps> = ({
  labels,
  datasets,
  max,
  showDataLabels
}) => {
  const mt2: CSSProperties = {
    marginTop: "8px"
  };
  const getDatasetsWithWidth = useCallback(
    (
      datasets: {
        value: number;
        label?: string;
        labels?: string[];
        subdataset: ChartDataSets;
      }[]
    ): DatasetsWithWidth => {
      const maxVal = datasets.reduce(
        (acc, next) => (next.value > acc ? next.value : acc),
        0
      );
      return datasets.map((ds) => ({
        ...ds,
        width: (ds.value / maxVal) * 100,
        subdataset: {
          barThickness: 25,
          ...ds.subdataset
        }
      }));
    },
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [datasetsWithWidth, setDatasetsWithWidth] = useState<DatasetsWithWidth>(
    getDatasetsWithWidth(datasets)
  );
  const [subBarChartOpen, setSubBarChartOpen] = useState<boolean[]>([]);

  useEffect(() => {
    setSubBarChartOpen(datasetsWithWidth.map(() => false));
  }, [datasetsWithWidth]);

  const handleExpandClick = useCallback(
    (index: number) => {
      setSubBarChartOpen((oldSubBarChartOpen) =>
        oldSubBarChartOpen
          .slice(0, index)
          .concat(!oldSubBarChartOpen[index])
          .concat(oldSubBarChartOpen.slice(index + 1))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subBarChartOpen]
  );

  return (
    <div>
      <div className="mt-2" style={{ ...mt2, padding: "0 24px" }}>
        {datasetsWithWidth.map((dataset, index) => (
          <div style={{ marginBottom: "1.25em" }} key={index}>
            <BarExpandable
              label={dataset.label || ""}
              value={dataset.value}
              width={dataset.width}
              onExpandClick={() => handleExpandClick(index)}
              expanded={subBarChartOpen[index]}
            />
            <div style={{ margin: "0 0 0 0.35em" }}>
              {subBarChartOpen[index] && (
                <ReportBarChart
                  labels={dataset.labels}
                  datasets={[dataset.subdataset]}
                  max={max}
                  showDataLabels
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportBarChartExpandable;

ReportBarChartExpandable.defaultProps = {
  showDataLabels: false
};
