import axios from "axios";
import qs from "qs";

import {
  CreateRequestData,
  FilterRequestsData,
  RequestReplyData,
  RequestTicket
} from "@models/requests";

import { PaginatedResponse } from ".";

const getContractorRequests = (
  contractorId: number,
  page = 1,
  filterData: FilterRequestsData = {}
) => {
  const queryString = qs.stringify({ ...filterData, page }, { indices: false });

  return axios.get<PaginatedResponse<RequestTicket>>(
    `/api/contractor/${contractorId}/ticket?${queryString}`
  );
};

const createRequest = (contractorId: number, formData: CreateRequestData) => {
  const parsedFormData = new FormData();

  parsedFormData.append("subject", formData.subject);
  for (let i = 0; i < formData.trainingCenterIds.length; i++) {
    parsedFormData.append(
      `trainingCenterIds[${i}]`,
      `${formData.trainingCenterIds[i]}`
    );
  }
  parsedFormData.append("text", formData.text);
  parsedFormData.append("attachmentFile", formData.attachmentFile);

  return axios.post<RequestTicket>(
    `/api/contractor/${contractorId}/ticket`,
    parsedFormData,
    {}
  );
};

const getContractorRequest = (contractorId: number, requestId: number) => {
  return axios.get<RequestTicket>(
    `/api/contractor/${contractorId}/ticket/${requestId}`
  );
};

const requestContractorReply = (
  contractorId: number,
  requestId: number,
  formData: RequestReplyData
) => {
  const parsedFormData = new FormData();
  parsedFormData.append("text", formData.text);
  parsedFormData.append("attachmentFile", formData.attachmentFile as string);

  return axios.post<RequestTicket>(
    `/api/contractor/${contractorId}/ticket/${requestId}/reply`,
    parsedFormData
  );
};

const getTrainingCenterRequests = (
  trainingCenterId: number,
  page: number,
  filterData: FilterRequestsData = {}
) => {
  const queryString = qs.stringify({ ...filterData, page }, { indices: false });

  return axios.get<PaginatedResponse<RequestTicket>>(
    `/api/training-center/${trainingCenterId}/ticket?${queryString}`
  );
};

const getTrainingCenterRequest = (
  trainingCenterId: number,
  requestId: number
) => {
  return axios.get<RequestTicket>(
    `/api/training-center/${trainingCenterId}/ticket/${requestId}`
  );
};

const requestTrainingCenterReply = (
  trainingCenterId: number,
  requestId: number,
  formData: RequestReplyData
) => {
  const parsedFormData = new FormData();
  parsedFormData.append("text", formData.text);
  parsedFormData.append("attachmentFile", formData.attachmentFile as string);

  return axios.post<RequestTicket>(
    `/api/training-center/${trainingCenterId}/ticket/${requestId}/reply`,
    parsedFormData
  );
};

const requestsApi = {
  getContractorRequests,
  getContractorRequest,
  requestContractorReply,
  createRequest,

  getTrainingCenterRequests,
  getTrainingCenterRequest,
  requestTrainingCenterReply
};

export default requestsApi;
