export const enProfile = {
  profile: "Profile",

  changePassword: "Change password",

  currentPassword: "Current password",
  newPassword: "New password",
  confirmNewPassword: "Confirm new password",

  editProfile: "Edit profile",
  firstName: "First name",
  lastName: "Last name",

  change: "Change",
  cancel: "Cancel",

  passwordChangeSuccess: "Password changed successfully",
  profileEditSuccess: "Profile updated successfully"
};
