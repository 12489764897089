import { FC, useCallback, useState } from "react";

import Chart, { ChartDataSets } from "chart.js";
import ChartjsDatalabels from "chartjs-plugin-datalabels";
import _ from "lodash";

import { useChartjs } from "@hooks/useChartjs";

type ReportDoughnutChartProps = {
  labels?: string[];
  datasets?: ChartDataSets[];
  options?: Chart.ChartOptions;
  showDataLabels?: boolean;
  legend?: boolean;
};

const ReportDoughnutChart: FC<ReportDoughnutChartProps> = ({
  labels,
  datasets,
  options,
  showDataLabels,
  legend
}) => {
  const handleClick = useCallback((e) => {}, []);
  const [chartOptions] = useState<Chart.ChartConfiguration>({
    type: "doughnut",
    plugins: [ChartjsDatalabels],
    data: {
      labels: labels,
      datasets: datasets
    },
    options: _.merge(
      {
        animation: {
          duration: 0
        },
        tooltips: {
          enabled: false
        },
        legend: {
          display: legend || false,
          position: "bottom"
        },
        plugins: {
          labels: {
            render: "percentage",
            fontColor: "#333",
            position: "outside",
            precision: 1,
            outsidePadding: 2,
            textMargin: 8,
            display: showDataLabels
          }
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        onClick: handleClick
      },
      options
    )
  });

  const { chartContext } = useChartjs(chartOptions);

  return (
    <div>
      <canvas ref={chartContext} />
    </div>
  );
};

export default ReportDoughnutChart;

ReportDoughnutChart.defaultProps = {
  showDataLabels: false,
  legend: false
};
