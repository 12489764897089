import axios from "axios";

import {
  Contractor,
  ContractorPaginated,
  FormValues,
  ContractorsDetails,
  ContractorEdit
} from "@models/contractor";
import { TrainingCenter, TrainingCentersMap } from "@models/trainingCenter";
import { EventData } from "@models/event";

const getContractorsPaginated = (page: number, bin?: number, name?: string) =>
  axios.get<ContractorPaginated>("/api/contractor/paginated", {
    params: { bin, name, page }
  });

const getContractors = () => axios.get<Contractor[]>("/api/contractor");

const getTrainingCenters = (contractorId: number) =>
  axios.get<TrainingCenter[]>(
    `/api/contractor/${contractorId}/training-center`
  );

const getAllTrainingCenters = () =>
  axios.get<TrainingCenter[]>("/api/training-center");

const getTrainingCenterNotificationsSettings = (contractorId: number) =>
  axios.get<number[]>(`/api/contractor/${contractorId}/notification/settings`);

const getTrainingCentersMap = (contractorId: number) =>
  axios.get<TrainingCentersMap>(
    `/api/contractor/${contractorId}/training-center/all`
  );

const getContractorEvents = (contractorId: number) =>
  axios.get<EventData>(`/api/contractor/${contractorId}/event`);

const createContractor = ({ payload }: { payload: FormValues }) =>
  axios.post(`/api/contractor`, payload);

const deleteContractor = (id: number) => axios.delete(`/api/contractor/${id}`);

const deleteSubContractorMember = (
  contractorId: number | undefined,
  subContractorId: number,
  memberId: number
) =>
  axios.delete(
    `/api/contractor/${contractorId}/subcontractor/${subContractorId}/member/${memberId}`
  );

const getContractor = (id: number) =>
  axios.get<ContractorsDetails>(`/api/contractor/${id}`);

const editContractor = ({
  id,
  payload
}: {
  id: number;
  payload: ContractorEdit;
}) => axios.put(`/api/contractor/${id}`, payload);

const resendInviteSubContractorMember = (
  contractorId: number | undefined,
  subContractorId: number,
  email: string
) =>
  axios.get<void>(
    `/api/contractor/${contractorId}/subcontractor/${subContractorId}/member/invite/resend?email=${email}`
  );

const getContractorDictionary = (parentContractorId?: number) =>
  axios.get("/api/entities-for-dropdowns/contractors", {
    params: { parentContractorId }
  });

export const contractorsApi = {
  getContractors,
  getContractorsPaginated,
  getTrainingCenters,
  getAllTrainingCenters,
  getTrainingCentersMap,
  getContractorEvents,
  createContractor,
  deleteContractor,
  getContractor,
  editContractor,
  deleteSubContractorMember,
  resendInviteSubContractorMember,
  getTrainingCenterNotificationsSettings,
  getContractorDictionary
};
