import picture1 from "@assets/images/user-guide/2.1.png";
import picture2 from "@assets/images/user-guide/2.2.png";
import picture3 from "@assets/images/user-guide/2.3.png";
import picture4 from "@assets/images/user-guide/2.4.png";

export const reports = [
  {
    title: "Download data dump",
    html: `<ol>
    <li>
        <p>Data dump is a large file (.csv format) containing all the information about events, craft workers and their grades available in the system.&nbsp;</p><br>
        <p>In order to download data dump file follow the steps:</p>
        <ol>
            <li>
                <p>Press on Reports tab on panel menu</p>
            </li>
            <li>
                <p>Select Download data dump</p>
            </li>
            <li>
                <p>Press&nbsp;Generate data dump file&nbsp;or&nbsp;Generate commissioning data dump file&nbsp;button</p>
            </li>
            <li>
                <p>File will be shown in the Data dump files block</p>
            </li>
            <li>
                <p>Press&nbsp;Download&nbsp;button</p>
            </li>
        </ol><br>
        <p>Note:&nbsp;File generation will take up to 1 hour.&nbsp;</p><br>
        <p>Data Dump page details:</p>
        <ol>
            <li>
                <p>Report generation status: Completed, In progress, Failed.</p>
            </li>
            <li>
                <p>Generate data dump file button (for regular, non- commissioning events)</p>
            </li>
            <li>
                <p>Generate commissioning data dump file button (for commissioning events)</p>
            </li>
            <li>
                <p>Download button</p>
            </li>
            <li>
                <p>List of all previously generated files</p>
            </li>Picture 2.1
        </ol>
        <p></p>
        <p><img src=${picture1} width="624" height="227"></p>
    </li>
</ol>`
  },
  {
    title: "Performance Report",
    html: `<p>The report shows data of regular, non-commissioning event types: Assessment, Training, Pre- screening and OJT Training.</p>
<p>Details of the report are described below.</p>
<p><br></p>
<p>Report shows the following charts and buttons:</p>
<ol>
    <li>
        <p>Event type tab;</p>
    </li>
    <li>
        <p>Summary Statistic Cards;</p>
    </li>
    <li>
        <p>Trained employees number per year;</p>
    </li>
    <li>
        <p>Events by Training providers;</p>
    </li>
    <li>
        <p>Events by Contractors;</p>
    </li>
    <li>
        <p>Events by Disciplines;</p>
    </li>
    <li>
        <p>Event by Location;</p>
    </li>
    <li>
        <p>Citizenship of craft employees;</p>
    </li>
    <li>
        <p>Overall grade;</p>
    </li>
    <li>
        <p>Fail reasons;</p>
    </li>
    <li>
        <p>Download Performance Report button;</p>
    </li>
    <li>
        <p>Download Academic Hours Report button;</p>
    </li>
    <li>
        <p>Filtering;</p>
    </li>
    <li>
        <p>Switch Academic hours button.</p>
    </li>
</ol>
<p><br></p>
<p>Detailed information per each item is below.</p>
<p>&nbsp;</p>
<p>Page example:</p>
<p>Picture 2.2</p>
<img src=${picture2} width="624" height="448"/>
<p>Picture 2.3</p>
<p><img src=${picture3} width="311" height="674"></p>
<p>Event type tab</p>
<p>TCO user can switch tabs to corresponding event types, so that the system will show the report on this specific type of event either Assessment, Training, Pre- screening or OJT.</p>
<p>Summary Statistic Cards</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>Card label</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>In progress</p>
                </td>
                <td>
                    <p>The number of craft employees registered to In progress events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Completed</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Completed events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Evaluated</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Evaluated events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Excluded</p>
                </td>
                <td>
                    <p>The number of craft employees excluded from the events. Shown if any craft employee has been excluded, otherwise hidden.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>The number of all registered craft employees to all types of events</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p>Trained employees number per year&nbsp;line graph demonstrates fluctuation over the years.</p>
<p><br></p>
<p>Events by Training providers:</p>
<p>System shows the number of events run by each Training Center. &nbsp;</p>
<p><br></p>
<p>Events by Contractors:</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <ol>
                        <li>
                            <p>System shows Performance report page<a href="https://petrelai.atlassian.net/browse/CTN-180">&nbsp;</a></p>
                        </li>
                        <li>
                            <p>Systems shows bar chart: Events by Contractors</p>
                        </li>
                        <li>
                            <p>User presses &apos;plus&apos; button next to Contractor company</p>
                        </li>
                        <li>
                            <p>System expands data and shows Contractor\`s Subcontractor data if there\`s any.</p>
                        </li>
                    </ol>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p>Events by Disciplines:</p>
<p>System shows the number of all craft employees taught per each discipline.&nbsp;</p>
<p><br></p>
<p>Also, discipline can be expanded if there&apos;s a plus sign (+) next to the discipline name.<br>In this case it shows all related disciplines and the number of employees taught per each discipline.</p>
<p><br></p>
<p>Event by Location:</p>
<p>Chart depicts a total number of all participants in a particular city as per &nbsp;event location, specified during the event creation stage.</p>
<p><br></p>
<p>Citizenship of craft employees:</p>
<p>Pie chart demonstrates employee citizenship countries. It shows the number and percentage.</p>
<p><br></p>
<p>Overall grade</p>
<p>Pie chart demonstrates employee overall grades. It shows the number and percentage.</p>
<p><br></p>
<p>Fail reasons</p>
<p>Pie chart demonstrates employee fail reasons. It shows the number and percentage.</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>Download Performance Report</p>
<p><br></p>
<p>In order to download Performance Report follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Download&nbsp;button</p>
    </li>
</ol>
<p><br></p>
<p><br></p>
<p><br></p>
<p>Download Academic Hours Report</p>
<p><br></p>
<p>In order to download Academic Hours Report follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Download Academic Hours Report&nbsp;button</p>
    </li>
</ol>
<p><br></p>
<p>Filtering</p>
<p>In order to use filters follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Filter&nbsp;icon</p>
    </li>
    <li>
        <p>Set up filter(s)</p>
    </li>
    <li>
        <p>Press&nbsp;Filter</p>
    </li>
</ol>
<p><br></p>
<p>To clear filters press&nbsp;Clear filters button</p>
<p><br></p>
<p>Switch Academic hours button</p>
<p>TCO user can view detailed information on the number of academic hours of the corresponding type of event (currently active tab) and it\`s total number in the following charts: Events by Training providers, Events by Contractors, Events by Disciplines.</p>`
  },
  {
    title: "Commissioning Performance Report",
    html: `<p>The page shows data of commissioning event types: Assessment, Training.</p>
<p>Details of the report are described below.</p>
<p>TCO users view all data of all Training Centers, Contractors, Subcontractors and craft workers registered in the system.&nbsp;</p>
<p><br></p>
<p>Report shows the following charts and buttons:</p>
<ol>
    <li>
        <p>Event type tab;</p>
    </li>
    <li>
        <p>Summary Statistic Cards;</p>
    </li>
    <li>
        <p>by Contractors;</p>
    </li>
    <li>
        <p>by Disciplines;</p>
    </li>
    <li>
        <p>by Fail reasons;</p>
    </li>
    <li>
        <p>by Competency level;</p>
    </li>
    <li>
        <p>Download Report button;</p>
    </li>
    <li>
        <p>Filtering.</p>
    </li>
</ol>
<p><br></p>
<p>Picture 2.4</p>
<p><img src=${picture4} width="576" height="588"></p>
<p>Event type tab</p>
<p>TCO user can switch tabs to corresponding event types, so that the system will show the report on this specific type of event either Assessment or Training.</p>
<p>Summary Statistic Cards</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>Card label</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>In progress</p>
                </td>
                <td>
                    <p>The number of craft employees registered to In progress events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Completed</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Completed events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Evaluated</p>
                </td>
                <td>
                    <p>The number of craft employees registered to Evaluated events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>The number of all registered craft employees to all types of events</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p><br></p>
<p>Events by Competency levels:</p>
<p>System shows the number of employees of each competency level: No competency, Trainee Technician, Junior Technician, Technician, Senior Technician.&nbsp;</p>
<p><br></p>
<p>The page shows data, described below, of craft employees&apos; event performance per each commissioning event type: Assessment, Training.</p>
<p><br></p>
<p><br></p>
<p>Report shows the following bar and pie charts:</p>
<p>Summary Statistic Cards</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>Label</p>
                </td>
                <td>
                    <p>Description</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>In progress</p>
                </td>
                <td>
                    <p>The number of employees registered to In progress events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Completed</p>
                </td>
                <td>
                    <p>The number of employees registered to Completed events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Evaluated</p>
                </td>
                <td>
                    <p>The number of employees registered to Evaluated events</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total</p>
                </td>
                <td>
                    <p>The number of all registered employees to all types of events</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p>Events by Competency levels:</p>
<p>System shows the number of events run by each Training Center. &nbsp;</p>
<p><br></p>
<p>Events by Contractors:</p>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td>
                    <ol>
                        <li>
                            <p>System shows Performance report page<a href="https://petrelai.atlassian.net/browse/CTN-180">&nbsp;</a></p>
                        </li>
                        <li>
                            <p>Systems shows bar chart: Events by Contractors</p>
                        </li>
                        <li>
                            <p>User presses &apos;plus&apos; button next to Contractor company</p>
                        </li>
                        <li>
                            <p>System expands data and shows Contractor\`s Subcontractor data if there\`s any.</p>
                        </li>
                    </ol>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p>Fail reasons</p>
<p>Pie chart demonstrates employee fail reasons. It shows the number and percentage.</p>
<p><br></p>
<p><br></p>
<p>Download Performance Report</p>
<p><br></p>
<p>In order to download Performance Report follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Commissioning Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Download&nbsp;button</p>
    </li>
</ol>
<p><br></p>
<p><br></p>
<p>Filtering</p>
<p>In order to use filters follow the steps:</p>
<ol>
    <li>
        <p>Press on Reports tab on panel menu</p>
    </li>
    <li>
        <p>Select Commissioning Performance Report</p>
    </li>
    <li>
        <p>Press&nbsp;Filter&nbsp;icon</p>
    </li>
    <li>
        <p>Set up filter(s)</p>
    </li>
    <li>
        <p>Press&nbsp;Filter</p>
    </li>
</ol>
<p><br></p>
<p>To clear filters press Clear filters button</p>`
  }
];
