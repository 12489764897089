import React from "react";
import { useTranslation } from "react-i18next/";
import { Link } from "react-router-dom";

import { TablePaginationConfig } from "antd/lib/table";

import { Button, Popconfirm, Space, Table } from "antd";
import { DeleteOutlined, EditOutlined, TeamOutlined } from "@ant-design/icons";

import { Contractor, ContractorPaginated } from "@models/contractor";

interface Props {
  title?: React.ReactNode;
  isLoading?: boolean;
  data?: Contractor[] | ContractorPaginated["results"] | undefined;
  pagination?: TablePaginationConfig;
  onDelete?: () => void;
  onEdit?: () => void;
  setEditedContractor: (item: Contractor) => void;
  handleModal: (item?: Contractor) => void;
  handleDelete: (id: number) => void;
}

const ContractorsTable = (props: Props) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      title: t("contractors.contractorName"),
      // dataIndex: 'name',
      key: "name",
      render: (item: Contractor) => (
        <Link to={`/admin/contractors/${item.id}`}>{item.name}</Link>
      )
    },
    {
      title: t("bin"),
      dataIndex: "bin",
      key: "bin"
    },
    {
      title: t("workPhoneNumber"),
      dataIndex: "phone",
      key: "phone"
    },
    {
      title: t("country"),
      dataIndex: ["country", i18n.language === "en" ? "nameEn" : "nameRu"]
    },
    {
      title: t("city"),
      dataIndex: ["city", i18n.language === "en" ? "nameEn" : "nameRu"]
    },
    {
      title: t("actions"),
      key: "actions",
      render: (item: Contractor) => (
        <Space size="middle">
          <Button
            title={t("members.members")}
            type="link"
            shape="circle"
            icon={<TeamOutlined />}
            href={`/admin/contractors/${item.id}`}
          />
          <Button
            title={t("edit")}
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              props.handleModal(item);
            }}
          />

          <Popconfirm
            title={t("contractors.confirmDelete", {
              name: item.name
            })}
            cancelText={t("cancel")}
            okText={t("yes")}
            // disabled={deleteMemberMutation.isLoading}
            onConfirm={() => {
              props.handleDelete(item.id);
            }}
          >
            <Button
              title={t("members.delete")}
              danger
              shape="circle"
              // disabled={deleteMemberMutation.isLoading}
              // loading={
              // 	deleteMemberMutation.isLoading &&
              // 	deleteMemberMutation.variables === am.userId
              // }
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={props.data}
      loading={props.isLoading}
      pagination={props.pagination}
      rowKey="id"
    />
  );
};

export default ContractorsTable;
