import React, { ChangeEvent, useRef } from "react";
import ReactInputMask from "react-input-mask";

import { Input } from "antd";

interface Props {
  name: string;
  value: string | undefined;
  onChange: (event: ChangeEvent) => void;
  onBlur?: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  mask?: string;
}

const IinInput: React.FC<Props> = (props) => {
  const inputRef = useRef(null);

  return (
    <ReactInputMask {...props} mask="999999999999">
      <Input
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        ref={inputRef}
        onBlur={props.onBlur}
      />
    </ReactInputMask>
  );
};

export default IinInput;
