import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from "antd";
import CountryCitySelectCascader from "@components/ui/CountryCitySelectCascader";

import { Country } from "@models/countryCity";
import { Discipline } from "@models/discipline";
import { TrainingCenter } from "@models/trainingCenter";

import { EVENT_TYPES } from "@constants/eventTypes";

interface FormValues {
  id?: number;
  name: string;
  cityId?: number;
  address: string;
  specialities?: number[];
  eventTypes?: number[];
  csrEnabled: boolean;
  commissioningEnabled: boolean;
}

const initialValues: FormValues = {
  id: undefined,
  name: "",
  address: "",
  cityId: undefined,
  specialities: undefined,
  eventTypes: undefined,
  csrEnabled: false,
  commissioningEnabled: false
};

const { Option } = Select;

interface EditFormProps {
  trainingCenter: TrainingCenter | null;
  countries: Country[];
  specialities: Discipline[] | undefined;
  errors: { [key: string]: string };
  isLoading?: boolean;
  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

const EditTrainingCenterForm: React.FC<EditFormProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedCountryCity, setSelectedCountryCity] = useState<number[]>([]);
  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: (values) => {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  const eventTypeOptions = Object.values(EVENT_TYPES)
    .filter(
      (e) =>
        e !== EVENT_TYPES.COMMISSIONING_ASSESSMENT &&
        e !== EVENT_TYPES.COMMISSIONING_TRAINING
    )
    .map((e) => ({
      label: t(`eventTypes.${e}`),
      value: e
    }));

  useEffect(() => {
    formik.setValues({
      id: props.trainingCenter?.id,
      name: props.trainingCenter?.name || "",
      cityId: props.trainingCenter?.city.id,
      address: props.trainingCenter?.address || "",
      eventTypes: props.trainingCenter?.eventTypes,
      specialities: props.trainingCenter?.specialities.map((t) => t.id),
      csrEnabled: !!props.trainingCenter?.csrEnabled,
      commissioningEnabled: !!props.trainingCenter?.commissioningEnabled
    });
    const cityId = props.trainingCenter?.city.id || 0;
    const countryId = props.trainingCenter?.city.countryId || 0;

    setSelectedCountryCity([countryId, cityId]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trainingCenter]);

  useEffect(() => {
    formik.setErrors({ ...props.errors });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={4}>
        <Col md={24}>
          <Form.Item
            label={t("trainingCenters.name")}
            validateStatus={formik.errors.name && "error"}
            help={formik.errors.name}
          >
            <Input
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={14}>
          <Form.Item
            label={t("city")}
            validateStatus={formik.errors.cityId && "error"}
            help={formik.errors.cityId}
          >
            <CountryCitySelectCascader
              value={selectedCountryCity}
              countries={props.countries}
              onChange={(values: number[]) => {
                setSelectedCountryCity(values);
                formik.setFieldValue("cityId", values[1]);
              }}
            />
          </Form.Item>
        </Col>

        <Col md={10}>
          <Form.Item
            label={t("address")}
            validateStatus={formik.errors.address && "error"}
            help={formik.errors.address}
          >
            <Input
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("disciplines.disciplines")}
            validateStatus={formik.errors.specialities && "error"}
            help={formik.errors.specialities}
          >
            <Select
              allowClear
              maxTagCount={3}
              mode="multiple"
              value={formik.values.specialities}
              onChange={(v) => formik.setFieldValue("specialities", v)}
            >
              {props.specialities?.map(({ id, nameEn, nameRu }) => (
                <Option key={id} value={id}>
                  {i18n.language === "en" ? nameEn : nameRu}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={24}>
          <Form.Item
            label={t("eventTypes.eventTypes")}
            validateStatus={formik.errors.eventTypes && "error"}
            help={formik.errors.eventTypes}
          >
            <Row>
              <Col span={24}>
                <Checkbox.Group
                  style={{ width: "100%" }}
                  value={formik.values.eventTypes}
                  onChange={(v) => formik.setFieldValue("eventTypes", v)}
                >
                  <Row>
                    {eventTypeOptions.map((option) => (
                      <Col key={option.value} span={6}>
                        <Checkbox value={option.value}>{option.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item>
            <Row>
              <Col span={6}>
                <Checkbox
                  name="csrEnabled"
                  checked={formik.values.csrEnabled}
                  onChange={formik.handleChange}
                >
                  {t("csrPhase")}
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox
                  name="commissioningEnabled"
                  checked={formik.values.commissioningEnabled}
                  onChange={formik.handleChange}
                >
                  {t("commissioning")}
                </Checkbox>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("save")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditTrainingCenterForm;
