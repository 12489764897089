import CraftProfilesListTemplate from "@components/common/craftProfiles/CraftProfilesListTemplate";

const BASE_LINK = "/subcontractor/craft-profiles/all";
const BASE_LINK_PAGINATOR = "/subcontractor/craft-profile";

export const CraftProfilesListPage = () => (
  <CraftProfilesListTemplate
    hiddenFilterFields={["iin"]}
    baseLink={BASE_LINK}
    baseLinkPaginator={BASE_LINK_PAGINATOR}
  />
);
