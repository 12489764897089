import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Popconfirm, Space } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined
} from "@ant-design/icons";

import { UserContext, UserRole } from "@contexts/UserContext";

const getReportLink = (userRole: UserRole, reportId: number) => {
  switch (userRole) {
    case "admin":
      return `/admin/tc-report/${reportId}`;
    case "trainingcenter":
      return `/tc/tc-report/${reportId}`;
    default:
      return "";
  }
};

interface Props {
  reportId: number;
  reportName: string;
  actions: ("download" | "delete" | "viewDetails")[];
  isDownloadLoading?: boolean;
  isDeleteLoading?: boolean;
  onDownload?: (reportId: number) => void;
  onDelete?: (reportId: number) => void;
}

export const TCReportListItemActions: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { userRole } = useContext(UserContext);
  const reportLink = getReportLink(userRole, props.reportId);

  return (
    <Space size="middle">
      <Button
        title={t("download")}
        type="primary"
        shape="circle"
        icon={<DownloadOutlined />}
        onClick={() => props.onDownload && props.onDownload(props.reportId)}
        loading={props.isDownloadLoading}
        disabled={props.isDownloadLoading}
      />
      <Link to={reportLink}>
        <Button
          title={t("viewDetails")}
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
        />
      </Link>

      <Popconfirm
        title={t("reports.confirmDelete", { name: props.reportName })}
        cancelText={t("cancel")}
        okText={t("yes")}
        disabled={props.isDeleteLoading}
        onConfirm={() => props.onDelete && props.onDelete(props.reportId)}
      >
        <Button
          title={t("delete")}
          danger
          shape="circle"
          icon={<DeleteOutlined />}
          loading={props.isDeleteLoading}
        />
      </Popconfirm>
    </Space>
  );
};
