import {
  CraftProfileCard,
  KnownLanguagesOptions,
  MaritalStatusOptions
} from "@models/craftProfiles";

export const SORTING_OPTIONS = [
  { value: 0, label: "-" },
  { value: 1, label: "craftProfiles.sorting.1" },
  { value: 2, label: "craftProfiles.sorting.2" },
  { value: 3, label: "craftProfiles.sorting.3" },
  { value: 4, label: "craftProfiles.sorting.4" }
];
export const PAGE_SIZE = 50;

export const KnownLanguages = [
  {
    value: KnownLanguagesOptions.RUSSIAN,
    label: `craftProfiles.knownLanguagesOptions.${KnownLanguagesOptions.RUSSIAN}`
  },
  {
    value: KnownLanguagesOptions.KAZAKH,
    label: `craftProfiles.knownLanguagesOptions.${KnownLanguagesOptions.KAZAKH}`
  },
  {
    value: KnownLanguagesOptions.ENGLISH,
    label: `craftProfiles.knownLanguagesOptions.${KnownLanguagesOptions.ENGLISH}`
  },
  {
    value: KnownLanguagesOptions.CHINESE,
    label: `craftProfiles.knownLanguagesOptions.${KnownLanguagesOptions.CHINESE}`
  },
  {
    value: KnownLanguagesOptions.FRENCH,
    label: `craftProfiles.knownLanguagesOptions.${KnownLanguagesOptions.FRENCH}`
  },
  {
    value: KnownLanguagesOptions.GERMAN,
    label: `craftProfiles.knownLanguagesOptions.${KnownLanguagesOptions.GERMAN}`
  },
  {
    value: KnownLanguagesOptions.ITALIAN,
    label: `craftProfiles.knownLanguagesOptions.${KnownLanguagesOptions.ITALIAN}`
  },
  {
    value: KnownLanguagesOptions.TURKISH,
    label: `craftProfiles.knownLanguagesOptions.${KnownLanguagesOptions.TURKISH}`
  }
];

export const MaritalStatuses = [
  {
    value: MaritalStatusOptions.MARRIED,
    label: `craftProfiles.maritalStatusOptions.${MaritalStatusOptions.MARRIED}`
  },
  {
    value: MaritalStatusOptions.SINGLE,
    label: `craftProfiles.maritalStatusOptions.${MaritalStatusOptions.SINGLE}`
  },
  {
    value: MaritalStatusOptions.WIDOW,
    label: `craftProfiles.maritalStatusOptions.${MaritalStatusOptions.WIDOW}`
  },
  {
    value: MaritalStatusOptions.DIVORCED,
    label: `craftProfiles.maritalStatusOptions.${MaritalStatusOptions.DIVORCED}`
  }
];

export const CraftProfileCardLowerRows: (keyof Pick<
  CraftProfileCard,
  | "education"
  | "additionalEducation"
  | "workExperience"
  | "languages"
  | "personalQualities"
  | "additionalInformation"
>)[] = [
  "education",
  "additionalEducation",
  "workExperience",
  "languages",
  "personalQualities",
  "additionalInformation"
];
