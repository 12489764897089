import {
  LogLevel,
  PublicClientApplication,
  RedirectRequest
} from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1___signupandsignin_fwe',
    forgotPassword: 'B2C_1_passwordreset_fwe',
    editProfile: 'B2C_1_profileediting_fwe',
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://chevronb2c.b2clogin.com/tfp/chevronb2c.onmicrosoft.com/B2C_1___signupandsignin_fwe',
    },
    forgotPassword: {
      authority: 'https://chevronb2c.b2clogin.com/tfp/chevronb2c.onmicrosoft.com/B2C_1_passwordreset_fwe',
    },
    editProfile: {
      authority: 'https://chevronb2c.b2clogin.com/tfp/chevronb2c.onmicrosoft.com/B2C_1_profileediting_fwe',
    },
  },
  authorityDomain: 'chevronb2c.b2clogin.com',
};

const msalConfig = {
  auth: {
    clientId: "0db98c95-8f05-4c71-a1c9-8516ee94b0c4", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Replace the placeholder with your tenant subdomain
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

const scopes = ["https://chevronb2c.onmicrosoft.com/0db98c95-8f05-4c71-a1c9-8516ee94b0c4/read"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const msalInstance: any = new PublicClientApplication(msalConfig);

const loginRequest: RedirectRequest = {
  scopes: scopes
};

export { msalInstance, loginRequest, scopes };

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
// export const silentRequest = {
//     scopes: ["openid", "profile"],
//     loginHint: "example@domain.net"
// };
