import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ColumnType } from "antd/lib/table";

import { EditOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";

import { Discipline } from "@models/discipline";
import { PERMISSIONS } from "@models/permissions";

import useDateFormatter from "@hooks/useDateFormat";
import { UserContext } from "@contexts/UserContext";

interface Props {
  disciplinesList: Discipline[];
  handleOpenModal: (discipline: Discipline) => void;
}

const DisciplinesTable: React.FC<Props> = ({
  handleOpenModal,
  disciplinesList
}) => {
  const [t, i18n] = useTranslation();
  const { formatter } = useDateFormatter();
  const { checkPermission } = useContext(UserContext);

  const hasDisciplineUpdatePermission = useMemo(
    () => checkPermission(PERMISSIONS.SPECIALITY_UPDATE),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = useMemo(
    () => {
      const e: (ColumnType<Discipline> & {
        hidden?: boolean;
      })[] = [
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          render: (_, __, index: number) => <>{index + 1}</>
        },
        {
          title: t("disciplines.discipline"),
          dataIndex: "discipline",
          key: "discipline",
          render: (_, discipline) => (
            <>
              {i18n.language === "ru" ? discipline.nameRu : discipline.nameEn}
            </>
          ),
          width: "50%"
        },
        {
          title: t("createdAt"),
          dataIndex: "createdAt",
          key: "createdAt",
          render: (date) => <>{formatter(date, "short-date")}</>
        },
        {
          title: t("actions"),
          dataIndex: "actions",
          key: "actions",
          render: (_, discipline) => (
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => handleOpenModal(discipline)}
            />
          ),
          align: "center",
          hidden: !hasDisciplineUpdatePermission
        }
      ];
      return e.filter((item) => !item.hidden);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );
  return (
    <Table
      dataSource={disciplinesList}
      columns={columns}
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false
      }}
    />
  );
};

export default DisciplinesTable;
