import { FC, useEffect, useState } from "react";

import Chart, { ChartColor, ChartDataSets } from "chart.js";
import ChartjsDatalabels from "chartjs-plugin-datalabels";

import { useChartjs } from "@hooks/useChartjs";

type ReportBarChartProps = {
  scaleLabel?: string;
  max?: number;
  chartHeight?: number | "100%";
  labels?: string[];
  datasets?: ChartDataSets[];
  showDataLabels: boolean;
};

const ReportCombinedBarChart: FC<ReportBarChartProps> = ({
  max,
  labels,
  scaleLabel,
  datasets,
  showDataLabels,
  chartHeight
}) => {
  // const [chartHeight, setChartHeight] = useState<number>(props.);
  const [chartOptions, setChartOptions] = useState<Chart.ChartConfiguration>({
    type: "bar",
    plugins: [ChartjsDatalabels],
    data: {
      labels: labels,
      datasets: datasets
    },
    options: {
      hover: {
        axis: "x",
        mode: "nearest",
        animationDuration: 400,
        intersect: true
      },
      onHover(ev: MouseEvent, points: any[]) {
        return;
      },
      scales: {
        yAxes: [
          {
            ticks: {
              max: max,
              min: 0,
              beginAtZero: true,
              callback: function (value) {
                return value;
              }
            },
            scaleLabel: {
              display: true,
              labelString: scaleLabel
            },
            display: true,
            position: "left",
            id: "y-axis-1",
            gridLines: {
              display: false
            }
          },
          {
            ticks: {
              max: max,
              min: 0,
              beginAtZero: true
            },
            display: false,
            type: "linear",
            id: "y-axis-2",
            gridLines: {
              display: false
            }
          }
        ],
        xAxes: [
          {
            offset: true,
            ticks: {
              callback: (_label) => {
                return _label;
              },
              sampleSize: 10
            },
            gridLines: {
              display: true
            }
          }
        ]
      },
      elements: {
        rectangle: {
          backgroundColor(ctx) {
            if (
              ctx.dataset &&
              typeof ctx.dataset.backgroundColor === "function"
            ) {
              return ctx.dataset.backgroundColor(ctx);
            }

            if (ctx.dataset && Array.isArray(ctx.dataset.backgroundColor)) {
              return ctx.dataset.backgroundColor[0] || "red";
            }

            if (!ctx.dataset) {
              return "red";
            }

            return (
              (ctx.dataset.backgroundColor as ChartColor | string) || "red"
            );
          }
        }
      },
      legend: {
        display: false,
        position: "bottom"
      },
      devicePixelRatio: 2,
      plugins: {
        datalabels: {
          display: (context) => {
            return (
              context.datasetIndex + 1 ===
                context.chart.data.datasets?.length && showDataLabels
            );
          },
          align: () => "end",
          anchor: (context) => "end",
          formatter(value, ctx) {
            if (
              ctx.chart.data.datasets &&
              ctx.chart.data.datasets.length > 1 &&
              ctx.chart.data.datasets[0].data
            ) {
              const datasetValues = new Array(
                ctx.chart.data.datasets[0].data.length
              ).fill(0);
              // eslint-disable-next-line array-callback-return
              ctx.chart.data.datasets.forEach((ds) => {
                if (ds.data) {
                  datasetValues[ctx.dataIndex] += ds.data[ctx.dataIndex];
                }
              });
              return datasetValues[ctx.dataIndex] === 0
                ? ""
                : datasetValues[ctx.dataIndex];
            }
            return value === 0 || !value ? "" : value;
          },
          font: {
            weight: "bold"
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false
    }
  });

  const { chartContext } = useChartjs(chartOptions);

  useEffect(() => {
    setChartOptions((prevState: Chart.ChartConfiguration) => ({
      ...prevState,
      data: {
        labels: labels,
        datasets: datasets
      }
    }));
  }, [datasets, labels]);

  return (
    <div style={{ height: chartHeight }}>
      <canvas ref={chartContext} />
    </div>
  );
};

export default ReportCombinedBarChart;

ReportCombinedBarChart.defaultProps = {
  showDataLabels: false
};
