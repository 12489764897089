/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography
} from "antd";

import {
  EventDetails,
  EventEmployeeDetails,
  EventEmployeeGradeType,
  EventType,
  FailReasonType
} from "@models/event";
import { CustomCompetencyLevel } from "@models/competencyLevel";
import { CompetencyType } from "@models/discipline";

interface GradeData {
  employeeId: number;
  isPassed?: number;
  theoreticalGrade?: number;
  practicalGrade?: number;
  failReasonType?: number;
  failReason?: string;
}

interface FormValues {
  grades: GradeData[];
}

interface Props {
  errors?: { [key: string]: string };
  isLoading?: boolean;
  eventData?: EventDetails;
  eventEmployees?: EventEmployeeDetails[];
  onSubmit?: (values: GradeData[]) => void;
  editablePracticalGrade?: boolean;
}

const Column = Table.Column;

const ASSESSEMENT_FAIL_REASON_OPTIONS = [
  {
    value: FailReasonType.NO_SHOW,
    label: "events.failReason.1"
  },
  {
    value: FailReasonType.ATTENDANCE,
    label: "events.failReason.2"
  },
  {
    value: FailReasonType.UNDER_THE_INFLUENCE,
    label: "events.failReason.3"
  },
  {
    value: FailReasonType.PERFORMANCE,
    label: "events.failReason.4"
  },
  {
    value: FailReasonType.OTHER,
    label: "events.failReason.5"
  },
  {
    value: FailReasonType.REQUIRES_TRAINING,
    label: "events.failReason.6"
  }
];

const TRAINING_FAIL_REASON_OPTIONS = [
  {
    value: FailReasonType.NO_SHOW,
    label: "events.failReason.1"
  },
  {
    value: FailReasonType.ATTENDANCE,
    label: "events.failReason.2"
  },
  {
    value: FailReasonType.UNDER_THE_INFLUENCE,
    label: "events.failReason.3"
  },
  {
    value: FailReasonType.PERFORMANCE,
    label: "events.failReason.4"
  },
  {
    value: FailReasonType.OTHER,
    label: "events.failReason.5"
  }
];

const DEFAULT_ASSESSMENT_GRADE_OPTIONS = [
  {
    label: `events.grade.1`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `events.grade.3`,
    value: EventEmployeeGradeType.BASIC
  },
  {
    label: `events.grade.4`,
    value: EventEmployeeGradeType.INTERMEDIATE
  },
  {
    label: `events.grade.5`,
    value: EventEmployeeGradeType.ADVANCED
  }
];

const INTERMEDIATE_ADVANCED_ASSESSMENT_GRADE_OPTIONS = [
  {
    label: `events.grade.4`,
    value: EventEmployeeGradeType.INTERMEDIATE
  },
  {
    label: `events.grade.5`,
    value: EventEmployeeGradeType.ADVANCED
  }
];

const COMPETENT_ASSESSMENT_GRADE_OPTIONS = [
  {
    label: `events.grade.6`,
    value: EventEmployeeGradeType.COMPETENT
  },
  {
    label: `events.grade.1`,
    value: EventEmployeeGradeType.FAILED
  }
];

const PROFICIENT_ASSESSMENT_GRADE_OPTIONS = [
  {
    label: `events.grade.7`,
    value: EventEmployeeGradeType.PROFICIENT
  },
  {
    label: `events.grade.1`,
    value: EventEmployeeGradeType.FAILED
  }
];

const DEFAULT_HELPER_ASSESSMENT_GRADE_OPTIONS = [
  {
    label: `events.grade.1`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `events.grade.8`,
    value: EventEmployeeGradeType.HELPER
  },
  {
    label: `events.grade.3`,
    value: EventEmployeeGradeType.BASIC
  },
  {
    label: `events.grade.4`,
    value: EventEmployeeGradeType.INTERMEDIATE
  },
  {
    label: `events.grade.5`,
    value: EventEmployeeGradeType.ADVANCED
  }
];

const TRAINING_GRADE_OPTIONS = [
  {
    label: `events.grade.1`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `events.grade.2`,
    value: EventEmployeeGradeType.PASSED
  }
];

const DEFAULT_FAIL_REASON_TYPE_VALUE = 0;

export const NonCommissioningEventGradeForm = (props: Props) => {
  const [t] = useTranslation();
  const formik = useFormik<FormValues>({
    initialValues: {
      grades: []
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values.grades);
      }
    }
  });

  useEffect(() => {
    const initVal =
      props.eventEmployees?.map((ee) => {
        if (props.editablePracticalGrade) {
          return {
            employeeGradeId: ee.employeeGradeId,
            practicalGrade: ee.practicalGrade,
            practicalStatus: ee.practicalStatus,
            employeeId: ee.employeeId,
            isPassed: ee.isPassed,
            theoreticalGrade: ee.theoreticalGrade,
            failReasonType: ee.failReasonType,
            failReason: ee.failReason
          };
        } else {
          return { employeeId: ee.employeeId };
        }
      }) || [];
    formik.setFieldValue("grades", initVal);
  }, [props.eventEmployees]);

  const assessmentGradeOptions = useMemo(() => {
    if (
      props.eventData?.customCompetencyLevel ===
      CustomCompetencyLevel.INTERMEDIATE_ADVANCED
    ) {
      return INTERMEDIATE_ADVANCED_ASSESSMENT_GRADE_OPTIONS;
    } else if (
      props.eventData?.speciality.competencyType ===
      CompetencyType.DEFAULT_HELPER
    ) {
      return DEFAULT_HELPER_ASSESSMENT_GRADE_OPTIONS;
    } else if (
      props.eventData?.speciality.competencyType === CompetencyType.PROFICIENT
    ) {
      return PROFICIENT_ASSESSMENT_GRADE_OPTIONS;
    } else if (
      props.eventData?.speciality.competencyType === CompetencyType.COMPETENT
    ) {
      return COMPETENT_ASSESSMENT_GRADE_OPTIONS;
    } else {
      return DEFAULT_ASSESSMENT_GRADE_OPTIONS;
    }
  }, [props.eventData]);

  const gradeOptions = useMemo(() => {
    if (props.eventData?.type === EventType.TRAINING) {
      return TRAINING_GRADE_OPTIONS;
    }

    if (props.eventData?.type === EventType.ASSESSMENT) {
      return assessmentGradeOptions;
    }

    return [];
  }, [props.eventData]);

  const failReasonOptions = useMemo(() => {
    if (props.eventData?.type === EventType.ASSESSMENT) {
      return ASSESSEMENT_FAIL_REASON_OPTIONS;
    }

    if (props.eventData?.type === EventType.TRAINING) {
      return TRAINING_FAIL_REASON_OPTIONS;
    }

    return [];
  }, [props.eventData]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Table
        dataSource={props.eventEmployees}
        rowKey="employeeId"
        pagination={false}
      >
        <Column
          title={t("events.participant")}
          render={(e: EventEmployeeDetails) => (
            <>
              {e.firstName}
              <br />
              {e.lastName}
              <br />
              <Typography.Text type="secondary">{e.iin}</Typography.Text>
            </>
          )}
        />

        <Column
          title={t("events.academicHours")}
          render={(e) => e.attendanceTotal}
        />

        <Column
          title={t("events.practicalGrade")}
          render={(_, __, i) => {
            return (
              <Form.Item
                validateStatus={
                  props.errors?.[`[${i}].PracticalGrade`] && "error"
                }
                help={props.errors?.[`[${i}].PracticalGrade`]}
              >
                <InputNumber
                  max={100}
                  min={0}
                  value={formik.values.grades?.[i]?.practicalGrade}
                  onChange={(v) => {
                    formik.setFieldValue(`grades[${i}].practicalGrade`, v);
                  }}
                />
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("events.theoreticalGrade")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={
                props.errors?.[`[${i}].TheoreticalGrade`] && "error"
              }
              help={props.errors?.[`[${i}].TheoreticalGrade`]}
            >
              <InputNumber
                disabled={props.editablePracticalGrade}
                max={100}
                min={0}
                value={formik.values.grades?.[i]?.theoreticalGrade}
                onChange={(v) =>
                  formik.setFieldValue(`grades[${i}].theoreticalGrade`, v)
                }
              />
            </Form.Item>
          )}
        />

        <Column
          title={t("events.overallGrade")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={props.errors?.[`[${i}].IsPassed`] && "error"}
              help={props.errors?.[`[${i}].IsPassed`]}
            >
              <Select
                value={formik.values.grades?.[i]?.isPassed}
                onChange={(v) => {
                  formik.setFieldValue(`grades[${i}].isPassed`, v);
                  formik.setFieldValue(
                    `grades[${i}].failReasonType`,
                    DEFAULT_FAIL_REASON_TYPE_VALUE
                  );
                }}
              >
                {gradeOptions.map((go) => (
                  <Select.Option value={go.value} key={go.value}>
                    {t(go.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />

        <Column
          title={t("events.failReason.failReason")}
          render={(_, __, i) =>
            formik.values.grades?.[i]?.isPassed ===
              EventEmployeeGradeType.FAILED && (
              <>
                <Form.Item
                  validateStatus={
                    props.errors?.[`[${i}].FailReasonType`] && "error"
                  }
                  help={props.errors?.[`[${i}].FailReasonType`]}
                >
                  <Select
                    value={
                      formik.values.grades?.[i]?.failReasonType ===
                      DEFAULT_FAIL_REASON_TYPE_VALUE
                        ? undefined
                        : formik.values.grades?.[i]?.failReasonType
                    }
                    onChange={(v) => {
                      if (v !== FailReasonType.OTHER) {
                        formik.setFieldValue(
                          `grades[${i}].failReason`,
                          undefined
                        );
                      }

                      formik.setFieldValue(`grades[${i}].failReasonType`, v);
                    }}
                  >
                    {failReasonOptions.map((fr) => (
                      <Select.Option value={fr.value} key={fr.value}>
                        {t(fr.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {formik.values.grades?.[i]?.failReasonType ===
                  FailReasonType.OTHER && (
                  <Form.Item
                    validateStatus={
                      props.errors?.[`[${i}].FailReason`] && "error"
                    }
                    help={props.errors?.[`[${i}].FailReason`]}
                  >
                    <Input
                      name={`grades[${i}].failReason`}
                      value={formik.values.grades?.[i]?.failReason}
                      onChange={formik.handleChange}
                    />
                  </Form.Item>
                )}
              </>
            )
          }
        />
      </Table>

      <br />

      <Row justify="end">
        <Button type="primary" htmlType="submit" loading={props.isLoading}>
          {t("save")}
        </Button>
      </Row>
    </Form>
  );
};
