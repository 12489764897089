/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Form, Row, Select, Table, Typography } from "antd";

import {
  CommissioningEventGrade,
  EventEmployeeDetails,
  EventEmployeeGradeType
} from "@models/event";

interface GradeData {
  employeeId: number;
  isPassed?: number;
}

interface FormValues {
  grades: GradeData[];
}

interface Props {
  isLoading?: boolean;
  errors?: { [key: string]: string };

  eventEmployees?: EventEmployeeDetails[];
  onSubmit?: (values: CommissioningEventGrade[]) => void;
}

const Column = Table.Column;

const OJT_GRADE_OPTIONS = [
  {
    label: `events.grade.ojt.1`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `events.grade.ojt.2`,
    value: EventEmployeeGradeType.PASSED
  }
];

export const OjtEventGradeForm = (props: Props) => {
  const [t] = useTranslation();
  const formik = useFormik<FormValues>({
    initialValues: {
      grades: []
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values.grades);
      }
    }
  });

  useEffect(() => {
    const initVal = props.eventEmployees?.map(
      (ee) => ({ employeeId: ee.employeeId } || [])
    );

    formik.setFieldValue("grades", initVal);
  }, [props.eventEmployees]);

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Table
        dataSource={props.eventEmployees}
        rowKey="employeeId"
        pagination={false}
      >
        <Column
          title={t("events.participant")}
          render={(e: EventEmployeeDetails) => (
            <>
              {e.firstName}
              <br />
              {e.lastName}
              <br />
              <Typography.Text type="secondary">{e.iin}</Typography.Text>
            </>
          )}
        />

        <Column
          title={t("events.academicHours")}
          render={(e) => e.attendanceTotal}
        />

        <Column
          title={t("events.completionStatus")}
          render={(_, __, i) => (
            <Form.Item
              validateStatus={props.errors?.[`[${i}].IsPassed`] && "error"}
              help={props.errors?.[`[${i}].IsPassed`]}
            >
              <Select
                value={formik.values.grades?.[i]?.isPassed}
                onChange={(v) => {
                  formik.setFieldValue(`grades[${i}].isPassed`, v);
                }}
              >
                {OJT_GRADE_OPTIONS.map((go) => (
                  <Select.Option value={go.value} key={go.value}>
                    {t(go.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />
      </Table>

      <br />

      <Row justify="end">
        <Button type="primary" htmlType="submit" loading={props.isLoading}>
          {t("save")}
        </Button>
      </Row>
    </Form>
  );
};
