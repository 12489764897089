import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Row, Select } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { Contractor } from "@models/contractor";
import { EmployeeEventFilters } from "@models/employee";
interface Props {
  contractors?: Contractor[];
  onSubmit?: (v: EmployeeEventFilters) => void;
}

const OVERALL_GRADE_OPTIONS = [
  { value: "", label: "all" },
  { value: 0, label: "events.gradePassed" },
  { value: 1, label: "events.gradeFailed" }
];

const EMPLOYEE_STATUS_OPTIONS = [
  { value: 0, label: "all" },
  { value: 1, label: "events.employeeStatus.1" },
  { value: 2, label: "events.employeeStatus.2" },
  { value: 3, label: "events.employeeStatus.3" },
  { value: 4, label: "events.employeeStatus.4" }
];

export const EventEmployeesFilterForm = ({ contractors, onSubmit }: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<EmployeeEventFilters>({
    initialValues: {
      contractorIds: undefined,
      isPassed: undefined,
      status: undefined
    },
    onSubmit(values) {
      if (onSubmit) {
        onSubmit(values);
      }
    }
  });

  const contractorOptions = useMemo(
    () => contractors?.map((c) => ({ value: c.id, label: c.name })),
    [contractors]
  );

  return (
    <Form
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Row gutter={8}>
        <Col md={5}>
          <Form.Item labelCol={{ span: 24 }} label={t("events.passed")}>
            <Select
              allowClear
              value={formik.values.isPassed}
              onChange={(v) => {
                formik.setFieldValue("isPassed", v);
              }}
            >
              {OVERALL_GRADE_OPTIONS.map((opt, index) => (
                <Select.Option key={`gradeSelect-${index}`} value={opt.value}>
                  {t(opt.label)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {contractorOptions && contractorOptions?.length > 0 && (
          <Col md={10}>
            <Form.Item
              labelCol={{ span: 24 }}
              label={t("events.filters.contractorsSubcontractors")}
            >
              <SearchSelect
                mode="multiple"
                options={contractorOptions}
                value={formik.values.contractorIds}
                onChange={(v: number[]) => {
                  formik.setFieldValue("contractorIds", v);
                }}
              />
            </Form.Item>
          </Col>
        )}

        <Col md={6}>
          <Form.Item labelCol={{ span: 24 }} label={t("status")}>
            <Select
              allowClear
              value={formik.values.status}
              onChange={(v) => {
                formik.setFieldValue("status", v);
              }}
            >
              {EMPLOYEE_STATUS_OPTIONS.map((opt, index) => (
                <Select.Option key={`statusSelect-${index}`} value={opt.value}>
                  {t(opt.label)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={3}>
          <Form.Item label="&#160;" labelCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit" block>
              {t("submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
