import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { retryGenerateDump } from "@api/datadumpApi";

interface RetryButtonProps {
  reportId: number;
  dataDumpsRefetch: () => void;
}

const RetryButton: React.FC<RetryButtonProps> = ({
  reportId,
  dataDumpsRefetch
}) => {
  const [t] = useTranslation();

  const { mutate, isLoading } = useMutation(
    (reportId: number) => retryGenerateDump(reportId),
    {
      onSuccess: () => {
        dataDumpsRefetch();
      }
    }
  );

  return (
    <Button
      type="ghost"
      icon={<ReloadOutlined />}
      loading={isLoading}
      onClick={() => mutate(reportId)}
    >
      {t("retry")}
    </Button>
  );
};

export default RetryButton;
