import { UseQueryResult } from "react-query";

import { FormikProps } from "formik";

import { City } from "@models/countryCity";
import { Instructor } from "@models/instructor";
import { Discipline } from "@models/discipline";
import { TrainingCenter } from "@models/trainingCenter";
import { Member } from "@models/member";

export enum MigrationTypes {
  UNDEFINED = 0,
  ASSESSMENT = 1,
  TRAINING = 2,
  PRESCREENING = 3,
  OJT_TRAINING = 4,
  COMMISSIONING_TRAINING = 5,
  COMMISSIONING_ASSESSMENT = 6
}

export enum OverallGrades {
  FAILED = 0,
  PASSED = 1
}

export interface MigrationType {
  value: number;
  route: string;
}

export interface MigrationFieldProps {
  name: keyof MigrationResponse;
  type: string;
  label: string;
  error?: keyof MigrationResponse;
}

export interface MigrationRadioType {
  value: number;
  label: string;
  route: string;
}

export interface FormSettingsParticipant {
  name: keyof ParticipantRequest;
  select?: boolean;
  hidden?: boolean;
  error?: keyof ParticipantRequest;
  disabled?: boolean;
}

export interface FormSettings {
  event: MigrationFieldProps[];
  participants: FormSettingsParticipant[];
}

export interface EmployeeContractorRelation {
  contractorId: number;
  employeeId: number;
  contractorName: string;
  contractName: string;
}

export interface MigrationListResponse {
  contractor: null;
  createdAt: string;
  eventEmployeesCount: number;
  eventName: string;
  eventType: number;
  id: number;
  tcName: string;
  updatedAt: string;
  uploadAt: string;
  uploadedBy: Member;
}

export interface ParticipantResponse {
  firstName: string;
  lastName: string;
  contractor: string;
  subcontractor: string;
  iin: string;
  phoneNumber: string;
  practicalStatus: string;
  theoreticalStatus: string;
  theoreticalGrade: string;
  isPassed: string;
  overallGrade: string;
  isRegistered: boolean;
  failReason: string;
  other: string;
  contact: string;
  craftWorkerStatus: string;
  practicalGrade: string;
  recommendations: string;
  employeeContractorRelations?: EmployeeContractorRelation[];
}

export interface ParticipantRequest extends ParticipantResponse {
  subcontractor: string;
  competencyLevel: string;
  recommendation: string;
  employeeId: string;
  isPassed: string;
  failReasonType: string;
  practicalStatus: string;
  theoreticalStatus: string;
}

export interface MigrationResponse {
  trainingCenterId: string;
  eventName: string;
  numberOfSeats: string;
  specialityId: string;
  language: string;
  csrPhase: string;
  description: string;
  start: string;
  end: string;
  minimumSeats: string;
  academicHours: string;
  standard: string;
  hoursPerDay: string;
  cityId: string;
  instructorId: string;
  competencyLevel: string;
}

export interface MigrationInitialFormData {
  participants: Partial<ParticipantRequest>[];
  event: Partial<MigrationResponse>;
}

interface MigrationsQueryProps {
  formik: FormikProps<MigrationInitialFormData>;
  instructorsQuery: UseQueryResult<Instructor[], unknown>;
  trainingCenterQuery: UseQueryResult<TrainingCenter[], unknown>;
  disciplinesQuery: UseQueryResult<Discipline[], unknown>;
  cityListQuery: UseQueryResult<City[], unknown>;
  employeeContractorRelations?: EmployeeContractorRelation[];
  isCommissioning: boolean;
}
export interface SelectItemGeneratorProps extends MigrationsQueryProps {
  name: string;
  index?: number;
  selectedMigration?: number;
}

export interface FormItemGeneratorProps extends MigrationsQueryProps {
  field: MigrationFieldProps;
  onBlur: (name: string, value: string | null) => void;
}

export interface TableFormItemGeneratorProps extends MigrationsQueryProps {
  field: FormSettingsParticipant;
  onBlur: (name: string, value: string | number | null) => void;
  index: number;
  disabled: boolean;
  selectedMigration: number;
}

export interface StaticFieldProps {
  name: keyof ParticipantRequest;
  index: number;
  notRegistered?: boolean;
  staticValue?: string | number;
}
