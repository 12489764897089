import { UseMutationResult } from "react-query";
import { useTranslation } from "react-i18next";

import { AxiosError, AxiosResponse } from "axios";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space } from "antd";

import { Member } from "@models/member";

type UseMutationAxios = UseMutationResult<
  AxiosResponse<any>,
  AxiosError<any>,
  number
>;

interface MemberActionsProps {
  member: Member;
  deleteMemberMutation: UseMutationAxios;
  onEdit: (member: Member) => void;
}

const MemberActions: React.FC<MemberActionsProps> = (props) => {
  const [t] = useTranslation();

  return (
    <Space size="middle">
      <Button
        title={t("edit")}
        type="primary"
        shape="circle"
        icon={<EditOutlined />}
        onClick={() => props.onEdit(props.member)}
      />
      <Popconfirm
        title={t("members.confirmDelete", {
          name: props.member.firstName + " " + props.member.lastName
        })}
        cancelText={t("cancel")}
        okText={t("yes")}
        disabled={props.deleteMemberMutation.isLoading}
        onConfirm={() => props.deleteMemberMutation.mutate(props.member.userId)}
      >
        <Button
          title={t("members.delete")}
          danger
          shape="circle"
          disabled={props.deleteMemberMutation.isLoading}
          loading={
            props.deleteMemberMutation.isLoading &&
            props.deleteMemberMutation.variables === props.member.userId
          }
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </Space>
  );
};

export default MemberActions;
