export const enTrainingCenters = {
  trainingCenters: "Training Centers",
  trainingCenter: "Training Center",
  trainingProviders: "Training providers",

  exportUsers: "Export users",
  registerTrainingCenter: "Register training center",
  editTrainingCenter: "Edit training center",

  editMemberPermissions: "Edit member access level",

  addInstructor: "Add Instructor",
  editInstructor: "Edit Instructor",
  deleteInstructor: "Delete Instructor",

  registerSuccess: "Training center was successfully registered",
  editSuccess: "Training center was successfully saved",
  deleteSuccess: "Training center was successfully deleted",

  addInstructorSuccess: "Instructor was successfully added",
  editInstructorSuccess: "Instructor was successfully saved",
  deleteInstructorSuccess: "Instructor was successfully deleted",

  deletePrompt: "Are you sure you want to delete training center: {{name}}?",

  deleteFail: "Couldn't delete training center due to error",

  name: "Training center's name"
};
