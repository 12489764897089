import { PERMISSIONS } from "@models/permissions";
import { PermissionGroup } from "@models/permissionsGroup";

export const CONTRACTOR_PERMISSION_DEFAULT_LIST: number[] = [
  PERMISSIONS.CONTRACTOR_UPDATE,
  PERMISSIONS.CONTRACTOR_DELETE,
  PERMISSIONS.CONTRACT_CREATE,
  PERMISSIONS.CONTRACT_VIEW,
  PERMISSIONS.CONTRACT_UPDATE,
  PERMISSIONS.CONTRACT_DELETE,
  PERMISSIONS.TRAINING_CENTER_VIEW
];

export const CONTRACTOR_OWNER_PERMISSION_DEFAULT_LIST: number[] = [
  PERMISSIONS.MEMBER_VIEW,
  PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW,
  PERMISSIONS.MEMBER_INVITE,
  PERMISSIONS.MEMBER_UPDATE,
  PERMISSIONS.MEMBER_DELETE,
  PERMISSIONS.CONTRACTOR_EMPLOYEE_CREATE,
  PERMISSIONS.CONTRACTOR_EMPLOYEE_UPDATE,
  PERMISSIONS.CONTRACTOR_EMPLOYEE_DELETE,
  PERMISSIONS.CONTRACTOR_EMPLOYEE_REJECT,
  PERMISSIONS.CONTRACTOR_UPDATE,
  PERMISSIONS.CONTRACTOR_DELETE,
  PERMISSIONS.CONTRACT_CREATE,
  PERMISSIONS.CONTRACT_VIEW,
  PERMISSIONS.CONTRACT_UPDATE,
  PERMISSIONS.CONTRACT_DELETE,
  PERMISSIONS.TRAINING_CENTER_VIEW
];

export const CONTRACTOR_OWNER_PERMISSION_OPTION_LIST: PermissionGroup[] = [
  {
    values: [PERMISSIONS.REPORT_VIEW],
    dependentPermissions: [
      {
        values: [PERMISSIONS.REPORT_DOWNLOAD_DUMP],
        label: "reportDownload"
      }
    ],
    label: "reportView"
  },
  {
    values: [PERMISSIONS.SUBCONTRACTOR_MEMBER_VIEW],
    label: "subcontractorMemberView",
    dependentPermissions: [
      {
        values: [
          PERMISSIONS.SUBCONTRACTOR_MEMBER_CREATE,
          PERMISSIONS.SUBCONTRACTOR_MEMBER_UPDATE,
          PERMISSIONS.SUBCONTRACTOR_MEMBER_DELETE
        ],
        label: "subcontractorMemberEdit"
      }
    ]
  },
  {
    values: [PERMISSIONS.TICKET_VIEW],
    label: "ticketView",
    dependentPermissions: [
      {
        values: [PERMISSIONS.TICKET_CREATE, PERMISSIONS.TICKET_REPLY],
        label: "ticketCreate"
      }
    ]
  },
  {
    values: [PERMISSIONS.EVENT_VIEW],
    label: "eventView",
    dependentPermissions: [
      { values: [PERMISSIONS.EVENT_APPLY], label: "eventApply" },
      { values: [PERMISSIONS.EVENT_DETAILS_DOWNLOAD], label: "eventDownload" }
    ]
  },
  {
    values: [PERMISSIONS.CERTIFICATE_VIEW],
    label: "certificateView",
    dependentPermissions: [
      {
        values: [PERMISSIONS.CERTIFICATE_DOWNLOAD],
        label: "certificateDownload"
      }
    ]
  }
];

export const CONTRACTOR_PERMISSION_OPTION_LIST: PermissionGroup[] = [
  {
    values: [PERMISSIONS.MEMBER_VIEW, PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW],
    label: "memberView",
    dependentPermissions: [
      {
        values: [
          PERMISSIONS.MEMBER_INVITE,
          PERMISSIONS.MEMBER_UPDATE,
          PERMISSIONS.MEMBER_DELETE,
          PERMISSIONS.CONTRACTOR_EMPLOYEE_CREATE,
          PERMISSIONS.CONTRACTOR_EMPLOYEE_UPDATE,
          PERMISSIONS.CONTRACTOR_EMPLOYEE_DELETE,
          PERMISSIONS.CONTRACTOR_EMPLOYEE_REJECT
        ],
        label: "memberEdit"
      }
    ]
  },
  {
    values: [PERMISSIONS.REPORT_VIEW],
    dependentPermissions: [
      {
        values: [PERMISSIONS.REPORT_DOWNLOAD_DUMP],
        label: "reportDownload"
      }
    ],
    label: "reportView"
  },
  {
    values: [PERMISSIONS.SUBCONTRACTOR_MEMBER_VIEW],
    label: "subcontractorMemberView",
    dependentPermissions: [
      {
        values: [
          PERMISSIONS.SUBCONTRACTOR_MEMBER_CREATE,
          PERMISSIONS.SUBCONTRACTOR_MEMBER_UPDATE,
          PERMISSIONS.SUBCONTRACTOR_MEMBER_DELETE
        ],
        label: "subcontractorMemberEdit"
      }
    ]
  },
  {
    values: [PERMISSIONS.TICKET_VIEW],
    label: "ticketView",
    dependentPermissions: [
      {
        values: [PERMISSIONS.TICKET_CREATE, PERMISSIONS.TICKET_REPLY],
        label: "ticketCreate"
      }
    ]
  },
  {
    values: [PERMISSIONS.EVENT_VIEW],
    label: "eventView",
    dependentPermissions: [
      { values: [PERMISSIONS.EVENT_APPLY], label: "eventApply" },
      { values: [PERMISSIONS.EVENT_DETAILS_DOWNLOAD], label: "eventDownload" }
    ]
  },
  {
    values: [PERMISSIONS.CERTIFICATE_VIEW],
    label: "certificateView",
    dependentPermissions: [
      {
        values: [PERMISSIONS.CERTIFICATE_DOWNLOAD],
        label: "certificateDownload"
      }
    ]
  }
];
