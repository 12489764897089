import qs from "qs";
import axios from "axios";

import { AdminMember } from "@models/adminMember";

const getAdminMembers = () => axios.get<AdminMember[]>("/api/admin/member");

interface AdminMemberInvitePayload {
  position: string;
  permissions: number[];
  notificationIds: number[];
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const inviteAdminMember = (payload: AdminMemberInvitePayload) =>
  axios.post<void>("/api/admin/member/invite", payload);

const inviteSubcontractorMember = (
  contractorId: number | undefined,
  subContractorId: number | undefined,
  payload: AdminMemberInvitePayload
) =>
  axios.post<void>(
    `/api/contractor/${contractorId}/subcontractor/${subContractorId}/member/invite`,
    payload
  );

const resendAdminMemberInvite = (email: string) =>
  axios.get<void>(`/api/admin/member/invite/resend?${qs.stringify({ email })}`);

const deleteAdminMemberInvite = (email: string) =>
  axios.delete<void>(
    `/api/admin/member/invite/delete?${qs.stringify({ email })}`
  );

const deleteAdminMember = (id: number) =>
  axios.delete<void>(`/api/admin/member/${id}`);

const activateAdminMember = (id: number) =>
  axios.get<void>(`/api/admin/member/${id}/activate`);

interface EditMemberPayload {
  firstName: string;
  lastName: string;
  position: string;
  permissions: number[];
  notificationIds: number[];
}

const editAdminMember = (id: number, payload: EditMemberPayload) =>
  axios.post(`/api/admin/member/${id}/update`, payload);

const getContractorMembers = (contractorId: number) =>
  axios.get<AdminMember[]>(`/api/contractor/${contractorId}/member`);

const getInvitedContractorMembers = (contractorId: number) =>
  axios.get<AdminMember[]>(`/api/contractor/${contractorId}/member/invited`);

const updateContractorMember = ({
  contractorId,
  memberId,
  payload
}: {
  contractorId: number;
  memberId: number;
  payload: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    position: string;
  };
}) =>
  axios.post(
    `/api/contractor/${contractorId}/member/${memberId}/update`,
    payload
  );

const deleteContractorMember = ({
  memberId,
  contractorId
}: {
  memberId: number;
  contractorId: number;
}) => axios.delete(`/api/contractor/${contractorId}/member/${memberId}`);

const deleteContractorMemberInvite = ({
  contractorId,
  email
}: {
  email: string;
  contractorId: number;
}) =>
  axios.delete(
    `/api/contractor/${contractorId}/member/invite/delete/?${qs.stringify({
      email
    })}`
  );

const resendContractorMemberInvite = ({
  contractorId,
  email
}: {
  email: string;
  contractorId: number;
}) =>
  axios.get(
    `/api/contractor/${contractorId}/member/invite/resend?${qs.stringify({
      email
    })}`
  );

interface ContractorMemberInvitePayload {
  firstName: string;
  lastName: string;
  position: string;
  permissions: number[];
  notificationIds: number[];
  email: string;
  phoneNumber: string;
}

const inviteContractorMember = ({
  contractorId,
  member
}: {
  contractorId: number;
  member: ContractorMemberInvitePayload;
}) => axios.post(`/api/contractor/${contractorId}/member/invite`, member);

const downloadMembersList = () => axios.get("/api/admin/export/users/admins");

export const memberApi = {
  getAdminMembers,
  inviteAdminMember,
  resendAdminMemberInvite,
  deleteAdminMemberInvite,
  deleteAdminMember,
  activateAdminMember,
  editAdminMember,
  getContractorMembers,
  getInvitedContractorMembers,
  updateContractorMember,
  deleteContractorMember,
  deleteContractorMemberInvite,
  resendContractorMemberInvite,
  inviteContractorMember,
  downloadMembersList,
  inviteSubcontractorMember
};
