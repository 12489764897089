import axios from "axios";

import { City, Country } from "@models/countryCity";

const getAllCountries = () =>
  axios.get<Country[]>("/api/country", { params: { HasCities: true } });
const getCountryCities = (countryId: number) =>
  axios.get<City[]>("/api/city", { params: { countryId } });
const getAllCities = () => axios.get<City[]>("/api/city");

export const countryCityApi = {
  getAllCountries,
  getCountryCities,
  getAllCities
};
