import React from "react";

import styled from "styled-components";

import { Select as _Select } from "antd";

interface SelectProps {
  "data-whitetext"?: boolean;
}

const Select = styled(_Select)`
  ${(props: SelectProps) =>
    props["data-whitetext"] &&
    `
      color: white !important;

      .ant-select-arrow {
        color: white !important;
      }
    `}
`;

interface Lang {
  title: string;
  shortTitle: string;
  iconsrc: string;
  code: string;
}

interface LanguageSelectorProps {
  whiteText?: boolean;
  withoutFlags?: boolean;
  langs: Lang[];
  currentLangCode: string;
  onLangSwitch: Function;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = (props) => {
  const { Option } = Select;

  return (
    <Select
      value={props.currentLangCode}
      // TODO: add types
      onSelect={(value: any) => props.onLangSwitch(value)}
      data-whitetext={props.whiteText}
      bordered={false}
    >
      {props.langs.map((lang, index) => (
        <Option key={index} value={lang.code}>
          {props.withoutFlags ? null : (
            <img src={lang.iconsrc} alt={lang.shortTitle} />
          )}
          <span> {props.withoutFlags ? lang.title : lang.shortTitle} </span>
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
