export const ruEvents = {
  events: "События",
  allEvents: "Все события",
  upcomingEvents: "Предстоящие события",
  inprogressEvents: "Текущие события",
  completedEvents: "Завершенные события",
  evaluatedEvents: "Оцененные события",
  cancelledEvents: "Отмененные",

  all: "Все",
  upcoming: "Предстоящие",
  inprogress: "Текущие",
  completed: "Завершенные",
  evaluated: "Оцененные",
  cancelled: "Отмененные",
  activated: "Активированные",

  createEvent: "Создать событие",

  eventSearch: "Поиск события",
  eventSearchSubtitle:
    "Здесь вы найдете предстоящие события, организованные тренинг центрами",

  subcontractorsSaved: "Субподрядчик установлен успешно назначен",

  dataCheck: "Проверка данных",
  doubleClickToEdit: "Кликните по строке дважды, чтобы редактировать",

  removeEmployeePrompt:
    "Вы действительно хотите удалить сотрудника {{name}} из списка",

  applicationSent: "Ваша заявка была успешно отправлена",

  registerEmployees: "Записать работников",
  applyToEvent: "Записаться на {{eventName}}",
  applyToTheEvent: "Записаться на событие",
  cancelApplication: "Отменить запись",
  setSubcontractor: "Назначить субподрядчика",
  searchByIIN: "Поиск по ИИН",
  clearSelected: "Очистить выделенное",
  goToStepN: "Перейти к шагу {{n}}",
  totalIINsEntered: "Всего ИИН-ов введено",
  selected: "Выбрано",
  notFound: "Не найдено",
  duplicates: "Дубликатов",
  notEligible: "Не подходят",

  seatsLeft: "/ свободно еще",

  totalHours: "Общее количество часов",

  employees: "работников",

  confirmEventEdit: "Вы точно хотите отредактировать событие?",
  editEventDatesValidationFail:
    "Крафт-сотрудники {{iins}} уже зарегестрированы на событие с ID {{eventId}} c {{dateTimeStart}} to {{dateTimeEnd}}",

  filters: {
    dateFrom: "Дата с",
    dateTo: "Дата по",
    selectDate: "Выберите дату",
    numberOfSeats: "Количество мест",
    inputNumberOfSeats: "Введите число",
    type: "Тип",
    eventStatuses: "Статусы событий",
    selectEventType: "Выберите тип события",
    competencyLevel: "Уровень компетентности",
    projects: "Проекты",
    selectCompetencyLevel: "Выберите уровень компетентности",
    disciplines: "Дисциплины",
    selectDisciplines: "Выберите дисциплины",
    trainingCenters: "Тренинг Центры",
    selectTrainingCenters: "Выберите тренинг центры",
    contractors: "Подрядчики",
    language: "Язык",
    selectLanguage: "Выберите язык",
    cities: "Города Тренинг Центра",
    selectCities: "Выберите города",
    contractorsSubcontractors: "Подрядчики/Субподрядчики",
    eventId: "ID события",
    clearFilters: "Очистить фильтры",
    eventLocation: "Локация события"
  },

  sorting: {
    sortBy: "Сортировка",

    1: "Дата начала ↓",
    2: "Дата начала ↑",
    3: "Утилизация ↓",
    4: "Утилизация ↑"
  },

  commissioning: "Ввод в эксплуатацию",
  name: "Название",
  host: "Организатор",
  numberOfSeats: "Количество мест",
  minimumSeats: "Минимум мест",
  academicHours: "Академические часы",
  trainingCenter: "Тренинг Центр",
  discipline: "Дисциплина",
  competencyLevel: "Уровень компетентности",
  competencyGrading: "Оценка компетентности",
  language: "Язык",
  location: "Локация",
  city: "Город",
  availableFor: "Доступно для",
  csrPhase: "Фаза КСО",
  standard: "Стандарт",
  description: "Описание",
  attachment: "Прикреплено",
  eventDate: "Дата события",
  stardDate: "Дата начала",
  endDate: "Дата окончания",
  setDaysOff: "Установить выходные дни",
  setAsDayOff: "Установить как выходной день",
  hoursPerDay: "Часы в день",
  uploadFile: "Загрузить файл",
  phase: "Фаза {{no}}",
  registered: "Зарегистрировано",
  from: "С",
  to: "До",
  notificationDate: "Дата уведомления",
  notificationEntities: "Уведомить",
  notificationEntitiesOptions: {
    TCO: "ТШО",
    trainingCenter: "Тренинг центр"
  },
  cancellationReason: "Причина отмены:",
  deletionReason: "Причина удаления:",
  cancelConfirm:
    "Вы уверены, что хотите отменить событие: {{name}} которое начнётся {{dateTimeStart}}?",
  cancelComplete: "Событие {{name}}, {{date}} успешно отменено",

  deleteConfirm:
    "Вы уверены, что хотите удалить событие: {{name}} которое начнётся {{dateTimeStart}}?",
  deleteComplete: "Событие {{name}}, {{date}} успешно удалено",

  instructor: "Инструктор",
  trainingHistory: "История обучения",
  eventHistory: "История событий",

  registeredCount: "Зарегистрировано {{number}} из {{total}}",
  participantList: "Списки участников",
  allParticipants: "Все участники",
  participant: "Участник",
  attendance: "Посещаемость",
  grades: "Оценки",
  passed: "Прошел",
  overallGrade: "Общая оценка",
  overallResult: "Общий результат",
  practicalGrade: "Оценка по практике",
  theoreticalGrade: "Оценка по теории",
  practicalStatus: "Статус по практике",
  theoreticalStatus: "Статус по теории",
  recommendation: "Рекомендация",
  reasonToExclude: "Причина исключения",
  dates: "Дата проведения",
  eventId: "ID события",
  issuedAt: "Дата выдачи",

  completionStatus: "Статус завершения",
  completionStatuses: {
    1: "Не завершенные",
    2: "Завершенные"
  },
  employeeCompletedEvent: "Завершил",
  employeeIncompletedEvent: "Не завершил",
  gradePassed: "Зачет",
  gradeFailed: "Незачет",

  eventCreated: "Событие успешно создано",
  eventEdited: "Событие успешно обновлено",
  gradeSuccess: "Событие успешно оцененно",

  employeesAmountDoNotFitEvent:
    "У этого события больше не осталось свободных мест",

  download: "Скачать",
  downloadAttendance: "Скачать посещаемость",
  downloadCertificates: "Скачать сертификаты",
  cancelEvent: "Отменить событие",
  deleteEvent: "Удалить событие",
  activateEvent: "Активировать",
  addFeedback: "Добавить отзыв",
  assignInstructor: "Назначить инструктора",
  pleaseAssignInstructor: "Пожалуйста, назначьте инструктора для события",
  instructorSetSuccess: "Инструктор успешно назначен",
  submitGrade: "Оценить",
  prematurelyEvaluate: "Предварительно выпустить",
  exclude: "Исключить",
  prematurelyEvaluateSuccess: "Крафт работник преждевременно выпущен",
  excludeSuccess: "Крафт работник исключен",

  attendanceWarning:
    "Часы посещения крафт работника будут недоступны к редактированию",
  editAdminAlert:
    "При изменении события, следующая информация будет удалена безвозвратно: дата начала/дата окончания события, посещаемость, оценки, инструктор, минимальное количество мест, получатель и дата уведомления о минимальном количестве мест",

  deregister: "Снять регистрацию",
  deregisterEmployee: "Снять регистрацию",
  deregisterConfirmation: "Вы точно хотите снять {{name}} с регистрации?",
  deregisterSuccessfull: "Работник успешно снят с регистрации",
  deregisterError:
    "Ошибка при снятии с регистрации, попробуйте перезагрузить страницу",
  deregisterReason: "Причина снятия",

  rejectEmployee: "Снять регистрацию",
  rejectConfirmation: "Вы точно хотите снять {{name}} с регистрации?",
  rejectSuccessfull: "Работник успешно снят с регистрации",
  rejectError:
    "Ошибка при снятии с регистрации, попробуйте перезагрузить страницу",
  rejectionReason: "Причина снятия",

  deleteEmployee: "Удалить работника",
  deleteConfirmation: "Вы точно хотите удалить {{name}} с события?",
  deleteSuccessfull: "Сотрудник успешно удалён",
  deleteError: "Ошибка при удалении, попробуйте перезагрузить страницу",

  feedback: "Отзыв",
  feedbackWarning:
    "Этот отзыв будет отправлен координаторам TCO Craft Training, обратная связь не будет доступна для подрядчика",
  feedbackSuccess: "Отзыв успешно сохранён",
  feedbackError: "Ошибка при сохранении отзыва. Пожалуйста, попробуйте ещё раз",

  feedbackAuthor: "Автор",
  feedbackDate: "Дата",
  contractor: "Контрактор",

  certificates: "Сертификаты",

  eventTypes: {
    eventTypes: "Типы события",
    training: "Тренинг",
    assessment: "Оценка",
    prescreening: "Пре-скрининг",
    ojtTraining: "ОСП",
    commissioningAssessment: "Ввод в эксплуатацию - Оценка",
    commissioningTraining: "Ввод в эксплуатацию - Тренинг"
  },

  employeeStatus: {
    1: "Зарегистрирован",
    2: "Не зарегистрирован",
    3: "Отклонен",
    4: "Исключен"
  },

  employeeSearchStatuses: {
    1: "Found",
    2: "Не найдено",
    3: "Дубликатов",
    4: "Не подходит"
  },

  statuses: {
    1: "Предстоящие",
    2: "Текущие",
    3: "Завершенные",
    4: "Оцененные",
    5: "Отмененные",
    6: "Активированные",
    excluded: "Исключенные",
    failed: "Не зачет",
    passed: "Зачет",
    total: "Всего"
  },

  grade: {
    null: "–",
    0: "-",
    1: "Незачет",
    2: "Зачет",
    3: "Базовый",
    4: "Средний",
    5: "Продвинутый",
    6: "Компетентный",
    7: "Профессиональный",
    8: "Помощник",
    20: "Обучаемый техник",
    22: "Младший техник",
    24: "Техник",
    26: "Старший техник",

    ojt: {
      1: "Incompleted",
      2: "Completed"
    },

    completed: "Завершил",
    incompleted: "Не завершил"
  },

  gradeMap: {
    failed: "Незачет",
    passed: "Зачет",
    basic: "Базовый",
    intermediate: "Средний",
    advanced: "Продвинутый",
    competent: "Компетентный",
    proficient: "Профессиональный",
    helper: "Помощник"
  },

  gradeStatus: {
    theoreticalStatus: "Статус по теории",
    practicalStatus: "Статус по практике",
    null: "–",

    0: "Незачет",
    1: "Зачет"
  },

  recommendationStatus: {
    null: "-",
    1: "Не готов к повышению квалификации",
    2: "Повышение квалификации на младшего техника",
    3: "Повышение квалификации на техника",
    4: "Повышение квалификации на старшего техника",
    5: "Завершен, нет необходимости в повышении квалификации",
    6: "В ожидании"
  },

  failReason: {
    failReason: "Причина незачета",
    null: "–",

    0: "–",
    1: "Неявка",
    2: "Посещаемость",
    3: "Наркотическое/алкогольное опьянение",
    4: "Успеваемость",
    5: "Другое",
    6: "Необходимо обучение",
    7: "Отсутствие практики"
  },

  exclusionReason: {
    1: "Неявка",
    2: "Наркотическое/алкогольное опьянение",
    3: "Отказался",
    4: "Промежуточная оценка",
    5: "Другое"
  }
};
