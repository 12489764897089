export const enPermissions = {
  permissions: "Permissions",

  1: "Report view",
  2: "Report download",
  3: "Report download dump",

  4: "Member view",
  5: "Member invite",
  6: "Member update",
  7: "Member delete",
  8: "Member permission",
  9: "Member notification",

  10: "Contractor view",
  11: "Contractor create",
  12: "Contractor update",
  13: "Contractor delete",

  14: "Contract create",
  15: "Contract view",
  16: "Contract update",
  17: "Contract delete",

  18: "Speciality create",
  19: "Speciality view",
  20: "Speciality update",
  21: "Speciality delete",

  22: "Training center view",
  23: "Training center create",
  24: "Training center update",
  25: "Training center delete",

  26: "Event view",
  27: "Event update",
  28: "Event cancel",
  29: "Event apply",
  30: "Event create",

  31: "Event details download",

  32: "Contractor employee reject",
  33: "Contractor employee view",
  34: "Contractor employee update",
  35: "Contractor employee create",
  36: "Contractor employee delete",

  37: "Certificate create",
  38: "Certificate update",
  39: "Certificate delete",
  40: "Certificate view",
  41: "Certificate download",

  42: "Permission view",
  43: "Notificate view",

  44: "Non IIN craft employee update",

  45: "Training center report view",

  46: "Training center instructor view",
  47: "Training center instructor create",
  48: "Training center instructor update",
  49: "Training center instructor delete",

  50: "Ticket create",
  51: "Ticket view",
  52: "Ticket reply",

  53: "City create",
  54: "Country create",

  55: "Currency edit",

  64: "EventDataUploadView",
  65: "EventDataUploadEdit",

  66: "DisciplineView",
  67: "DisciplineEdit",
  61: "Event delete",

  // displayed permissions

  reportView: "Reports - view",
  reportDownload: "Reports - download dumps",
  reportDownloadDump: "Reports - download dumps",
  memberView: "Members - view",
  memberEdit: "Members - edit",
  eventView: "Events - view",
  eventApply: "Events - apply",
  eventDownload: "Events - download",
  eventEdit: "Event - edit",
  eventDataUploadView: "Event - data upload view",
  eventDataUploadEdit: "Event - data upload edit",
  trainingCenterView: "Training centers - view",
  trainingCenterEdit: "Training centers - edit",
  contractorView: "Contractors - view",
  contractorEdit: "Contractors - edit",
  certificateView: "Certificates - view",
  certificateDownload: "Certificates - download",
  craftEmployeeView: "Craft employees - view",
  craftEmployeeEdit: "Craft employees - edit",
  tcReportView: "TC reports - view",
  tcReportCreate: "TC reports - create",
  trainingCenterInstructorView: "Instructor - view",
  trainingCenterInstructorEdit: "Instructor - edit",
  specialityView: "Disciplines - view",
  specialityEdit: "Disciplines - edit",
  ticketView: "Request - view",
  ticketReply: "Request - reply",
  ticketCreate: "Запросы - создание",
  subcontractorMemberView: "Subcontractors - view members",
  subcontractorMemberEdit: "Subcontractors - edit members",
  disciplineView: "Discipline - view",
  disciplineEdit: "Discipline - edit",
  eventDelete: "Event - delete",
  eventActivate: "Event Activate"
};
