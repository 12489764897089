import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import saveAs from "file-saver";

import { TCReportDetails } from "@components/common/tc-reports/TCReportDetails";

import { trainingCenterApi } from "@api/trainingCenterApi";

interface PageParams {
  id: string;
}

export const ReportDetailsPage: React.FC = () => {
  const { id } = useParams<PageParams>();
  const TRAINING_CENTER_REPORT_ID = Number(id);

  const { data, isLoading } = useQuery("getAdminReportDetails", () =>
    trainingCenterApi
      .getAdminTrainingCenterReport(TRAINING_CENTER_REPORT_ID)
      .then((res) => res.data)
  );

  const handleDownload = () => {
    // eslint-disable-next-line array-callback-return
    data?.attachmentFiles.map((file) => {
      trainingCenterApi
        .getAdminTCReportFile(TRAINING_CENTER_REPORT_ID, file.id)
        .then((res) => {
          return saveAs(new Blob([res.data]), file.fileName);
        });
    });
  };

  return (
    <TCReportDetails
      data={data}
      isLoading={isLoading}
      onDownload={handleDownload}
      path={"admin"}
    />
  );
};
