/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Typography } from "antd";

import { EventDetails } from "@models/event";

import useDateFormatter from "@hooks/useDateFormat";

interface FormValues {
  cancellationReason: string;
}

interface Props {
  event: EventDetails;
  isLoading?: boolean;
  errors?: { [key: string]: string };
  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

export const EventCancelForm = (props: Props) => {
  const dateFormatter = useDateFormatter();
  const [t] = useTranslation();
  const { event } = props;

  const formik = useFormik<FormValues>({
    initialValues: {
      cancellationReason: ""
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  useEffect(() => {
    if (props.errors) {
      formik.setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Typography.Text style={{ paddingBottom: "24px" }}>
        {t("events.cancelConfirm", {
          name: event.name,
          dateTimeStart: dateFormatter.formatter(
            event.dateTimeStart,
            "short-datetime"
          )
        })}
      </Typography.Text>

      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t("events.cancellationReason")}
            validateStatus={formik.errors.cancellationReason && "error"}
            help={formik.errors.cancellationReason}
          >
            <Input.TextArea
              name="cancellationReason"
              value={formik.values.cancellationReason}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <br />

      <Row justify="space-between">
        <Col>
          <Button onClick={props.onCancel}>{t("cancel")}</Button>
        </Col>

        <Col>
          <Button
            type="primary"
            danger
            htmlType="submit"
            loading={props.isLoading}
          >
            {t("yes")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
