import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Typography } from "antd";
import { UserguideTemplate } from "@components/common/userguides/UserguideTemplate";

import { subcontractorUserguide } from "@utils/userguides/subcontractor";

export const SubcontractorUserGuide: FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <Space direction="vertical" style={{ width: "100%", marginTop: "50px" }}>
      <Title>{t("login.userGuide")}</Title>
      <UserguideTemplate guides={subcontractorUserguide} />
    </Space>
  );
};
