import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import qs from "qs";
import Modal from "antd/lib/modal/Modal";
import Column from "antd/lib/table/Column";

import { Button, Card, Col, Row, Table } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { DuplicatedHeadcountCard } from "@components/common/reports/DuplicatedHeadcountCard";
import { DuplicatedHeadcountFilterForm } from "@components/common/reports/DuplicatedHeadcountFilterForm";
import { AppPageHeader } from "@components/ui/AppPageHeader";

import {
  DuplicatedHeadcountTypeKey,
  EventEmployeeGradeType
} from "@models/event";

import { contractorsApi } from "@api/contractorsApi";
import { countryCityApi } from "@api/countryCityApi";
import { disciplineApi } from "@api/disciplineApi";
import { reportsApi } from "@api/reportsApi";
import { trainingCenterApi } from "@api/trainingCenterApi";

import { useDefaultQuery } from "@hooks/useDefaultQuery";
import { useQueryParams } from "@hooks/useQueryParams";
import { usePagination } from "@hooks/usePagination";

export const DuplicatedHeadcountPage: React.FC = () => {
  const { t } = useTranslation();

  const queryParams = useQueryParams();
  const history = useHistory();
  const {
    type: initalType,
    page: initialPage,
    ...initialFilterData
  } = queryParams;

  const [reportType, setReportType] = useState<number>(Number(initalType) || 1);
  const { page, setPage, setPagination } = usePagination();
  const [filterData, setFilterData] = useState<{}>(initialFilterData);

  const [isFilterModalShown, setIsFilterModalShown] = useState(false);

  const { data: headcounts, isLoading: headcountsLoading } = useQuery(
    ["getDuplicatedHeadcounts", reportType, filterData],
    () =>
      reportsApi
        .getDuplicatedHeadcounts(reportType, filterData)
        .then((res) => res.data),
    {
      onSuccess() {
        history.push({
          search: qs.stringify(
            { ...filterData, type: reportType },
            { arrayFormat: "indices" }
          )
        });
        setIsFilterModalShown(false);
      }
    }
  );

  const { data: tableDataPagination, isLoading: tableLaoding } = useQuery(
    ["getDuplicatedHeadcountsTable", reportType, filterData, page],
    () =>
      reportsApi
        .getDuplicatedHeadcountsDetails(reportType, page, filterData)
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage,
          filterParams: filterData
        };
        setPagination(pagination);
      }
    }
  );

  const disciplines = useDefaultQuery("getAllDisciplines", () =>
    disciplineApi.getAllDisciplines().then((res) => res.data)
  );

  const trainingCenters = useDefaultQuery("getTcs", () =>
    trainingCenterApi.getAllTrainingCenters().then((res) => res.data)
  );

  const contractors = useDefaultQuery("getContractors", () =>
    contractorsApi.getContractors().then((res) => res.data)
  );

  const countries = useDefaultQuery("getAllCountries", () =>
    countryCityApi.getAllCountries().then((res) => res.data)
  );

  const orderedHeadcounts = headcounts
    ? [
        {
          value: headcounts.headcount,
          key: DuplicatedHeadcountTypeKey.HEADCOUNT
        },
        {
          value: headcounts.assessment,
          key: DuplicatedHeadcountTypeKey.ASSESSMENT
        },
        {
          value: headcounts.commissioningAssessment,
          key: DuplicatedHeadcountTypeKey.COMMISSIONING_ASSESSMENT
        },
        {
          value: headcounts.training,
          key: DuplicatedHeadcountTypeKey.TRAINING
        },
        {
          value: headcounts.commissioningTraining,
          key: DuplicatedHeadcountTypeKey.COMMISSIONING_TRAINING
        },
        {
          value: headcounts.preScreening,
          key: DuplicatedHeadcountTypeKey.PRE_SCREEINING
        },
        {
          value: headcounts.ojtTraining,
          key: DuplicatedHeadcountTypeKey.OJT_TRAINING
        }
      ]
    : [];

  const dateSource = useMemo(
    () =>
      tableDataPagination?.results.map((r, idx) => {
        const parentRowData = r.rows[0];
        const children = r.rows.length > 1 ? r.rows.slice(1) : undefined;

        return {
          ...parentRowData,
          id: idx + 1,
          count: r.count,
          children: children
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, tableDataPagination]
  );

  const pageTitle = t("reports.duplicatedHeadcount");

  const paginationSettings = {
    responsive: true,
    hideOnSinglePage: true,
    total: tableDataPagination?.totalItems,
    current: tableDataPagination?.currentPage,
    pageSize: tableDataPagination?.pageSize,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          { breadcrumbName: t("meta.ctPortal"), path: "/" },
          {
            breadcrumbName: pageTitle,
            path: "/admin/reports/duplicated-headcount"
          }
        ]}
      />
      <Modal
        visible={isFilterModalShown}
        destroyOnClose
        title={t("Filter")}
        maskClosable={false}
        width={600}
        footer={null}
        onCancel={() => {
          setIsFilterModalShown(false);
        }}
      >
        <DuplicatedHeadcountFilterForm
          initialFilter={initialFilterData}
          disciplines={disciplines.data || []}
          trainingCenters={trainingCenters.data || []}
          contractors={contractors.data || []}
          countries={countries.data || []}
          isLoading={headcountsLoading || tableLaoding}
          onSubmit={(filters) => {
            setFilterData(filters);
          }}
        />
      </Modal>
      <Card
        tabList={[
          {
            tab: t("iin"),
            key: "1"
          },
          {
            tab: t("contractors.contractors"),
            key: "2"
          },
          {
            tab: t("trainingCenters.trainingProviders"),
            key: "3"
          }
        ]}
        tabBarExtraContent={
          <Button
            icon={<FilterOutlined />}
            size="large"
            shape="circle"
            ghost
            type="primary"
            onClick={() => setIsFilterModalShown(true)}
          />
        }
        loading={headcountsLoading}
        activeTabKey={reportType.toString()}
        onTabChange={(tab) => {
          setFilterData({});
          setReportType(Number(tab));
        }}
      >
        <Row
          wrap
          justify={"space-between"}
          style={{ height: "340px", marginBottom: "32px" }}
        >
          {orderedHeadcounts.map(({ key, value }) => {
            return (
              <Col style={{ width: "10%" }}>
                <DuplicatedHeadcountCard headcountType={key} value={value} />
              </Col>
            );
          })}
        </Row>

        <Table
          locale={{ emptyText: t("noData") }}
          loading={tableLaoding}
          dataSource={dateSource}
          expandIconColumnIndex={1}
          pagination={{ ...paginationSettings, position: ["bottomRight"] }}
          bordered
          rowKey="id"
          scroll={{ x: true }}
        >
          <Column dataIndex="id" title="№" />
          <Column dataIndex="count" title={t("count")} />
          <Column dataIndex="iin" title={t("iin")} />
          <Column dataIndex="citizenship" title={t("employees.citizenship")} />
          <Column dataIndex="contractor" title={t("employees.contractor")} />
          <Column
            dataIndex="trainingCenter"
            title={t("trainingCenters.trainingProviders")}
            width="165px"
          />
          <Column dataIndex="discipline" title={t("disciplines.discipline")} />
          <Column
            dataIndex="eventType"
            title={t("events.eventTypes.eventTypes")}
          />
          <Column
            title={t("events.overallGrade")}
            render={(record: {
              iin: string;
              contractorId: number;
              trainingCenterId: number;
              citizenship: string;
              contractor: string;
              trainingCenter: string;
              discipline: string;
              eventType: string;
              overallGrade: EventEmployeeGradeType;
              eventDate: string;
            }) => t(`events.grade.${record.overallGrade || null}`)}
          />
          <Column
            width="115px"
            dataIndex="eventDate"
            title={t("events.date")}
          />
        </Table>
      </Card>
    </>
  );
};
