import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import { useFormik } from "formik";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Col, InputNumber, Row, Select, Space, Typography } from "antd";

import { Instructor } from "@models/instructor";
import {
  EventAttendance,
  EventEmployeeCompletionType,
  FailReasonType
} from "@models/event";

const FAIL_REASON_OPTIONS = [
  {
    value: FailReasonType.NO_SHOW,
    label: "events.failReason.1"
  },
  {
    value: FailReasonType.ATTENDANCE,
    label: "events.failReason.2"
  },
  {
    value: FailReasonType.UNDER_THE_INFLUENCE,
    label: "events.failReason.3"
  },
  {
    value: FailReasonType.PERFORMANCE,
    label: "events.failReason.4"
  },
  {
    value: FailReasonType.OTHER,
    label: "events.failReason.5"
  }
];

interface FormValues {
  instructorId?: number;
  theoreticalGrade?: number;
  practicalGrade?: number;
  isPassed?: EventEmployeeCompletionType;
  failReasonType?: FailReasonType;
  failReason?: string;
}

interface Props {
  totalAttendance: number;
  attendance?: EventAttendance;
  isEventOJT: boolean;
  isLoading: boolean;
  errors?: { [key: string]: string };
  instructors: Instructor[];
  onSubmit: (values: FormValues) => void;
}

export const EmployeePreMatureEvaluationForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const formik = useFormik<FormValues>({
    initialValues: {
      instructorId: undefined,
      isPassed: undefined
    },
    onSubmit: (values) => {
      props.onSubmit(values);
    }
  });

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  // Clear fail reason data if isPassed was changed to completed
  useEffect(() => {
    if (formik.values.isPassed === EventEmployeeCompletionType.COMPLETED) {
      formik.setFieldValue("failReasonType", undefined);
      formik.setFieldValue("failReason", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.isPassed]);

  // Clear fail reason description if failReasonType is not `FailReasonType.OTHER`
  useEffect(() => {
    if (
      formik.values.isPassed === EventEmployeeCompletionType.INCOMPLETED &&
      formik.values.failReasonType !== FailReasonType.OTHER
    ) {
      formik.setFieldValue("failReason", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.failReasonType]);

  return (
    <>
      <Row wrap gutter={8}>
        <Col span={4}>
          <FormItem labelCol={{ span: 24 }} label={t("events.attendance")}>
            <span>{props.totalAttendance}</span>
          </FormItem>
        </Col>
        {props.isEventOJT ? (
          <Col span={18}>
            <FormItem
              labelCol={{ span: 24 }}
              label={t("events.completionStatus")}
              validateStatus={formik.errors.isPassed && "error"}
              help={formik.errors.isPassed}
            >
              <Select
                value={formik.values.isPassed}
                onChange={(v) => formik.setFieldValue("isPassed", v)}
              >
                <Select.Option value={1}>
                  {t("events.employeeIncompletedEvent")}
                </Select.Option>
                <Select.Option value={2}>
                  {t("events.employeeCompletedEvent")}
                </Select.Option>
              </Select>
            </FormItem>
          </Col>
        ) : (
          <>
            <Col span={6}>
              <FormItem
                labelCol={{ span: 24 }}
                label={t("events.theoreticalGrade")}
                validateStatus={formik.errors.theoreticalGrade && "error"}
                help={formik.errors.theoreticalGrade}
              >
                <InputNumber
                  value={formik.values.theoreticalGrade}
                  onChange={(v) =>
                    formik.setFieldValue("theoreticalGrade", Number(v))
                  }
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                labelCol={{ span: 24 }}
                label={t("events.practicalGrade")}
                validateStatus={formik.errors.practicalGrade && "error"}
                help={formik.errors.practicalGrade}
              >
                <InputNumber
                  value={formik.values.practicalGrade}
                  onChange={(v) =>
                    formik.setFieldValue("practicalGrade", Number(v))
                  }
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                labelCol={{ span: 24 }}
                label={t("events.overallGrade")}
                validateStatus={formik.errors.isPassed && "error"}
                help={formik.errors.isPassed}
              >
                <Select
                  value={formik.values.isPassed}
                  onChange={(v) => formik.setFieldValue("isPassed", v)}
                >
                  <Select.Option value={1}>
                    {t("events.gradeFailed")}
                  </Select.Option>
                  <Select.Option value={2}>
                    {t("events.gradePassed")}
                  </Select.Option>
                </Select>
              </FormItem>
            </Col>
          </>
        )}
        <Col span={6}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.instructor")}
            validateStatus={formik.errors.instructorId && "error"}
            help={formik.errors.instructorId}
          >
            <Select
              value={formik.values.instructorId}
              onChange={(v) => formik.setFieldValue("instructorId", v)}
            >
              {props.instructors.map((i) => (
                <Select.Option
                  value={i.id}
                >{`${i.firstName} ${i.lastName}`}</Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        {formik.values.isPassed === EventEmployeeCompletionType.INCOMPLETED && (
          <>
            <Col span={8}>
              <FormItem
                labelCol={{ span: 24 }}
                label={t("events.failReason.failReason")}
                validateStatus={formik.errors.failReasonType && "error"}
                help={formik.errors.failReasonType}
              >
                <Select
                  value={formik.values.failReasonType}
                  onChange={(v) => formik.setFieldValue("failReasonType", v)}
                >
                  {FAIL_REASON_OPTIONS.map((o) => (
                    <Select.Option value={o.value}>{t(o.label)}</Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            {formik.values.failReasonType === FailReasonType.OTHER && (
              <Col span={16}>
                <FormItem
                  labelCol={{ span: 24 }}
                  label={t("events.failReason.5")}
                  validateStatus={formik.errors.failReason && "error"}
                  help={formik.errors.failReason}
                >
                  <TextArea
                    name="failReason"
                    value={formik.values.failReason}
                    onChange={formik.handleChange}
                    style={{ height: "32px" }}
                    maxLength={250}
                  />
                </FormItem>
              </Col>
            )}
          </>
        )}
      </Row>
      <Row>
        <Typography.Text type="danger">
          <Space>
            <ExclamationCircleFilled />
            {t("events.attendanceWarning")}
          </Space>
        </Typography.Text>
      </Row>
      <Row justify="end">
        <Button
          type="primary"
          loading={props.isLoading}
          onClick={() => formik.submitForm()}
        >
          {t("events.submitGrade")}
        </Button>
      </Row>
    </>
  );
};
