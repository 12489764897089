import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Typography } from "antd";
import { WhatNewTemplate } from "@components/common/WhatNew";

import { enSubcontractorWhatNew } from "@utils/whatNew/enSubcontractor";
import { ruSubcontractorWhatNew } from "@utils/whatNew/ruSubcontractor";

export const SubcaontractorWhatNew: FC = () => {
  const { t, i18n } = useTranslation();
  const { Title } = Typography;

  return (
    <Space direction="vertical" style={{ width: "100%", marginTop: "50px" }}>
      <Title>{t("whatsNew")}</Title>
      <WhatNewTemplate
        whatNews={
          i18n.language === "en"
            ? enSubcontractorWhatNew
            : ruSubcontractorWhatNew
        }
      />
    </Space>
  );
};
