import React from "react";
import { useTranslation } from "react-i18next";

import { TablePaginationConfig } from "antd/lib/table";

import { Space, Table, Typography } from "antd";

import { TrainingCenterReport } from "@models/trainingCenter";

import useDateFormatter from "@hooks/useDateFormat";

const Column = Table.Column;

interface Props {
  title?: React.ReactNode;
  isLoading?: boolean;
  items?: TrainingCenterReport[];
  rowLinkTo?: (sub: TrainingCenterReport) => string;
  pagination?: TablePaginationConfig;

  extraColumns?: {
    title?: string;
    dataIndex?: string;
    render?: (value: any, record: unknown, index: number) => React.ReactNode;
  }[];

  renderActions?: (member: any) => React.ReactNode;
}

export const TrainingReportTable = (props: Props) => {
  const [t] = useTranslation();
  const { formatter } = useDateFormatter();

  return (
    <Table
      locale={{ emptyText: t("noData") }}
      loading={props.isLoading}
      tableLayout="auto"
      bordered
      pagination={
        props.pagination && {
          responsive: true,
          position: ["bottomCenter"],
          ...props.pagination
        }
      }
      title={() => (
        <Space align="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {props.title}
          </Typography.Title>
          {props.pagination && (
            <Typography.Text type="secondary">
              {t("totalCount", {
                total: props.pagination?.total || 0
              })}
            </Typography.Text>
          )}
        </Space>
      )}
      rowKey="id"
      dataSource={props.items}
    >
      <Column title={"ID"} dataIndex="id" />
      <Column title={t("reports.reportName")} dataIndex="name" />
      <Column
        title={t("reports.csrPhase")}
        dataIndex="csrPhase"
        render={(record: number) => (
          <span>
            {t("events.phase", {
              no: record
            })}
          </span>
        )}
      />
      <Column
        title={t("reports.reportDate")}
        render={(record: TrainingCenterReport) => (
          <span>
            {t(`fromToRange`, {
              from: formatter(record.dateFrom, "short-date"),
              to: formatter(record.dateTo, "short-date")
            })}
          </span>
        )}
      />
      <Column
        title={t("reports.createdAt")}
        dataIndex="createdAt"
        render={(record: string) => (
          <span>{formatter(record, "short-date")}</span>
        )}
      />
      <Column title={t("reports.numberOfEdits")} dataIndex="edited" />
      <Column
        title={t("reports.editedAt")}
        dataIndex="updatedAt"
        render={(record: string) => (
          <span>{formatter(record, "short-date")}</span>
        )}
      />

      {props.renderActions && (
        <Column
          title={t("actions")}
          render={(member: any) => {
            return props.renderActions && props.renderActions(member);
          }}
        />
      )}
    </Table>
  );
};
