import { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { Empty, notification } from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { OnlineTrainingReportTable } from "@components/common/reports/OnlineTrainingReportTable";
import UploadEmployeeBatchModal from "@components/common/reports/modals/UploadEmployeeBatchModal";
import DeleteButton from "@components/common/reports/DeleteButton";

import {
  OnlineTrainingReport,
  OnlineTrainingReportResult
} from "@models/onlineTrainingReports";

import { onlineTrainingReportsApi } from "@api/onlineTrainingReportsApi";

import { transformValidationError } from "@utils/errorHelper";
import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { usePagination } from "@hooks/usePagination";

const OnlineTrainingReportPage: React.FC = () => {
  const [t] = useTranslation();
  const pageTitle = t("reports.onlineTrainingReport_other");
  const [currentStep, setCurrentStep] = useState<0 | 1>(0);

  const { userEntityId: contractorId } = useContext(UserContext);

  const [isCreateReportMdalOpen, setCreateReportModalOpen] =
    useState<boolean>(false);

  const { page, setPage, setPagination } = usePagination();

  const reportsList = useQuery(
    ["online-training-reports-list", page],
    () =>
      onlineTrainingReportsApi
        .getAllContractorOnlineTrainingReports(contractorId, page)
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage
        };
        setPagination(pagination);
      },
      onError: () =>
        notification.error({
          message: t("error.errorOccured")
        })
    }
  );

  const createReport = useValidatedMutation({
    mutationFunction: (values: OnlineTrainingReport) =>
      onlineTrainingReportsApi.createOnlineTrainingReport(contractorId, values),
    onSuccess: () => {
      notification.success({
        message: t("reports.createReportSuccess")
      });
      reportsList.refetch();
      setCreateReportModalOpen(false);
      setCurrentStep(0);
    },
    onError: () =>
      notification.error({
        message: t("error.errorOccured")
      })
  });

  const createReportErrors = useMemo(
    () => transformValidationError(createReport.error),
    [createReport.error]
  );

  const deleteReport = useValidatedMutation({
    mutationFunction: (reportId: number) =>
      onlineTrainingReportsApi.deleteOnlineTrainingReport(
        contractorId,
        reportId
      ),
    onSuccess: () => {
      notification.success({
        message: t("reports.deleteReportSuccess")
      });
      reportsList.refetch();
    },
    onError: () =>
      notification.error({
        message: t("error.errorOccured")
      })
  });

  const paginationSettings = {
    hideOnSinglePage: true,
    total: reportsList.data?.totalItems,
    current: reportsList.data?.currentPage,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: "/admin/reports/online-training-reports"
          }
        ]}
        extra={
          reportsList && (
            <UploadEmployeeBatchModal
              currentStep={currentStep}
              setCurrentStep={(step: 0 | 1) => setCurrentStep(step)}
              isModalOpen={isCreateReportMdalOpen}
              handleOpenModal={() => setCreateReportModalOpen(true)}
              handleCloseModal={() => setCreateReportModalOpen(false)}
              createReportMutation={createReport.mutate}
              isLoading={createReport.isLoading || reportsList.isLoading}
              errors={createReportErrors}
            />
          )
        }
      />
      {reportsList ? (
        <OnlineTrainingReportTable
          reportsData={reportsList.data}
          isLoading={reportsList.isLoading || deleteReport.isLoading}
          pagination={paginationSettings}
          renderDeleteButton={(props: OnlineTrainingReportResult) => {
            return (
              <DeleteButton
                name={t("reports.userDeleteConfirmation", {
                  name: props.name
                })}
                id={props.id}
                onConfirm={() => deleteReport.mutate(props.id)}
              />
            );
          }}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("error.errorOccured")}
        />
      )}
    </>
  );
};

export default OnlineTrainingReportPage;
