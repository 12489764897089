import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Modal, Row, Space, Typography } from "antd";

interface FormData {
  rejectionReason?: string;
}

interface Props {
  visible: boolean;
  employeeName?: string;
  onSubmit: (rejectReason?: string) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: FormData;
  title?: string;
  text?: string;
}

const RejectEmployeeModal: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  const formik = useFormik<FormData>({
    initialValues: {
      rejectionReason: undefined
    },
    onSubmit: (values) => props.onSubmit(values.rejectionReason)
  });

  return (
    <Modal
      visible={props.visible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      title={props.title || t("events.rejectEmployee")}
      footer={null}
      width={400}
      onCancel={() => {
        formik.resetForm();
        props.onCancel();
      }}
    >
      <Space direction="vertical">
        <Typography.Text>
          {props.text ||
            t("events.rejectConfirmation", { name: props.employeeName })}
        </Typography.Text>

        <Row gutter={8}>
          <Col md={24}>
            <Form.Item
              label={t("events.rejectionReason")}
              validateStatus={formik.errors.rejectionReason && "error"}
              help={formik.errors.rejectionReason}
            >
              <Input.TextArea
                name="rejectionReason"
                value={formik.values.rejectionReason}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Button onClick={props.onCancel}>{t("cancel")}</Button>
          </Col>

          <Col>
            <Button
              type="primary"
              danger
              htmlType="submit"
              loading={props.isLoading}
              onClick={formik.submitForm}
            >
              {t("yes")}
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};

export default RejectEmployeeModal;
