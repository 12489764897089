export const PROJECTS = {
  _3GP: 1,
  _3GI: 2,
  GATHERING: 3,
  CONTROL_AND_POWER: 4,
  CORE_TEAM: 5,
  MWP: 6,
  OV: 7,
  GSP: 8,
  AREA_51: 9,
  FGP_WPMP: 10,
  CSR: 11,
  SYSTEM_COMPLETION: 12,
  CONSTRUCTION_OV: 13,
  MASTER_SERVICES: 14,
  BB: 15
};
