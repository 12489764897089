const UserAgreementText = () => (
  <ol>
    <li>
      <b>Agreement</b> – I (“User” or “Contractor Representative” as applicable)
      agree and acknowledge that this Tengizchevroil Information Systems User
      Agreement (“Agreement”) governs the use of Tengizchevroil Systems,
      including the use of Tengizchevroil Information made available to me via
      Tengizchevroil Systems. “Tengizchevroil” means the affiliate of
      Tengizchevroil Corporation granting me (or my Contractor organization)
      access to Tengizchevroil Systems. “Tengizchevroil Systems” means any
      computing, communications or information storage networks, systems,
      equipment or media owned, controlled, or otherwise used by or on behalf of
      Tengizchevroil Corporation or an affiliate (“Tengizchevroil Group”), and
      may include, without limitation, computers, servers, routers, networks,
      controllers, storage devices, smartphones, software applications,
      databases, cloud services, Tengizchevroil Application Gateway, and other
      resources used to store, process, transmit, or otherwise use
      Tengizchevroil Information. “Tengizchevroil Application Gateway” means
      systems or equipment designated by Tengizchevroil for providing
      interconnectivity between external non-Tengizchevroil entities and
      Tengizchevroil Systems. “Tengizchevroil Information” means any business or
      technical information, including related intellectual property rights,
      owned by, licensed by, developed by or on behalf of, or that is in the
      possession of Tengizchevroil, including User Identification Information,
      Personal Data, confidential information of third parties, and intellectual
      property rights in work performed for Tengizchevroil. “User Identification
      Information” may include user names (“UserIDs”), passwords, digital
      certificates, encryption keys, smart badges, and other information and
      devices used to identify, authenticate, or authorize users of
      Tengizchevroil Systems. “Personal Data” means any information that can be
      used directly or indirectly, alone or in combination with other
      information, to identify an individual, including for example information
      relating to an individual’s race, ethnic origin/nationality, date of
      birth, government-issued identifier, credit card or bank account number,
      medical information, or sexual orientation/history.
    </li>
    <li>
      <b>User Identification Information</b> – Any User Identification
      Information issued to me is for my exclusive use in connection with
      Tengizchevroil business purposes only. Personal and other uses of User
      Identification Information are strictly prohibited. I will not share my
      User Identification Information with others or allow others to use such
      information to gain access to Tengizchevroil Systems or Tengizchevroil
      Information. I acknowledge Tengizchevroil's right to access, store, and
      otherwise use User Identification Information in connection with
      Tengizchevroil Systems, including the right to use names, email addresses,
      and other personally identifying information that may be contained in the
      User Identification Information. If I have delegated my electronic signing
      authority, I acknowledge that my delegate’s electronic signature will have
      a force equivalent to my own signature.
    </li>
    <li>
      <b>Appropriate Use</b> – I agree to use Tengizchevroil Systems and
      Tengizchevroil Information only as authorized by Tengizchevroil and as
      required to fulfill my job responsibilities. I agree to use Tengizchevroil
      Systems and Tengizchevroil solely for authorized Tengizchevroil business
      purposes. Except as authorized by Tengizchevroil, I will not use third
      party systems to access Tengizchevroil Systems or use Tengizchevroil
      Systems to access third party systems. I will not attempt unauthorized
      access to Tengizchevroil Systems or Tengizchevroil Information, and will
      not knowingly install or distribute malicious or disabling code such as
      viruses, backdoors, key loggers, and password crackers on Tengizchevroil
      Systems.
    </li>
    <li>
      <b>Company Policies</b> – I agree to comply with Tengizchevroil Group
      policies, procedures, and additional terms governing the use of
      Tengizchevroil Systems, including those related to information protection,
      data privacy, and records retention, which may be presented to me in
      connection with access to Tengizchevroil Systems. As a condition for
      continued access to Tengizchevroil Systems, I agree to comply with such
      policies, procedures, and terms, and to complete any related training that
      may be required by Tengizchevroil.
    </li>
    <li>
      <b>Tengizchevroil Information</b> – I agree to maintain the
      confidentiality and integrity of Tengizchevroil Information, and agree not
      to disclose to others or to use Tengizchevroil Information without the
      prior written consent of Tengizchevroil. I agree to promptly notify
      Tengizchevroil prior to any planned disclosure of Tengizchevroil
      Information, and to promptly notify Tengizchevroil upon learning of any
      compromise of Tengizchevroil Information. All legal rights in relation to
      Tengizchevroil Information and Tengizchevroil Systems are presumed to be
      owned by Tengizchevroil, including intellectual property rights in work
      relating to this Agreement or a Related Contract. “Related Contract” means
      the contract to which a member of Tengizchevroil Group is a party that (a)
      governs User’s work and (b) is the basis for User entering into this
      Agreement. User agrees to promptly disclose to Tengizchevroil any
      inventions or other work product related to Tengizchevroil Information or
      Tengizchevroil Systems, and to assign any rights therein as may be
      requested by Tengizchevroil. Terms and conditions with respect to
      confidentiality and intellectual property rights in the Related Contract
      shall prevail over conflicting terms in this Agreement.
    </li>
    <li>
      <b>Third Party Information</b> – I agree to comply with all copyright and
      other intellectual property laws applicable to third party information
      that I access via Tengizchevroil Systems, including open source software.
      I agree to use such third-party information only for authorized
      Tengizchevroil business purposes, and will not copy, distribute, or
      otherwise use third party information except as authorized by
      Tengizchevroil.
    </li>
    <li>
      <b>Export Controls</b> – Tengizchevroil Systems and Tengizchevroil
      Information may be subject to applicable export controls and trade
      restrictions. Except as authorized by Tengizchevroil, I will not introduce
      or upload onto Tengizchevroil Systems any information subject to export
      controls or trade restrictions.
    </li>
    <li>
      <b>Access Rights</b> – The granting of access to Tengizchevroil Systems
      and Tengizchevroil Information is at Tengizchevroil’s sole discretion and
      may be revoked by Tengizchevroil at any time for any reason. Files stored
      on Tengizchevroil Systems may be deleted, decrypted, or preserved as
      evidence without notice.
    </li>
    <li>
      <b>Monitoring</b> – Authorized users may use Tengizchevroil Systems for
      Tengizchevroil business purposes only. Any individual using Tengizchevroil
      Systems, by such use, acknowledges and consents to the right of the
      Tengizchevroil Group to monitor, access, use, and disclose any information
      generated, received, or stored on Tengizchevroil Systems, and waives any
      right of privacy or expectation of privacy in connection with his or her
      use of Tengizchevroil Systems. Monitoring may include, without limitation
      (a) scanning and imaging of USB and other storage media connected to
      Tengizchevroil Systems or to which Tengizchevroil Information is
      transmitted, (b) logging of files accessed via Tengizchevroil Systems, and
      (c) scanning, tracking, and decrypting of files, data, and internet
      traffic (including email) from, to, or within Tengizchevroil Systems.
      Tengizchevroil may use activity logs for monitoring and investigative
      purposes, and may use and disclose such logs to others as may be required.
      Tengizchevroil may permit incidental personal use of Tengizchevroil
      Systems where, under local law, such use does not substantially interfere
      with Tengizchevroil’s ability to monitor Tengizchevroil Systems. Where
      permitted, Tengizchevroil reserves the right to determine, at its sole
      discretion, what activities constitute incidental personal use.
    </li>
    <li>
      <b>Legal Consequences</b> – Any misuse or misappropriation of
      Tengizchevroil Systems or Tengizchevroil Information or violation of any
      laws may subject me to civil and/or criminal liability, and may result in
      immediate termination and removal from Tengizchevroil premises.
    </li>
    <li>
      <b>Governing Law</b> – This Agreement shall be subject to the governing
      law provisions of the Related Contract. I hereby agree to the terms of
      this Agreement as a condition of Tengizchevroil granting access to
      Tengizchevroil Systems and Tengizchevroil Information.
    </li>
  </ol>
);

export default UserAgreementText;
