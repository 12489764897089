import picture7 from "@assets/images/user-guide/3.7.png";
import picture8 from "@assets/images/user-guide/3.8.png";
import picture9 from "@assets/images/user-guide/3.9.png";
import picture10 from "@assets/images/user-guide/3.10.png";
import picture11 from "@assets/images/user-guide/3.11.png";
import picture12 from "@assets/images/user-guide/3.12.png";
import picture13 from "@assets/images/user-guide/3.13.png";

export const events = [
  {
    title: "What’s on Events tab:",
    html: `<p dir="ltr">
    Once you apply craft-workers, you can track the process of learning on the
    Events tab. Events in the system are divided by status:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            All – list of all events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Upcoming – events that starts soon, you can change/cancel event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In progress – ongoing events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Completed – events that ended, but without grades. You must fill
            out Attendance data
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Evaluated – events with grades. Once you fill out grades, system
            generates Certificates for successfully studied craft-workers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Canceled – events that were canceled by you. System sends email
            notification to TCO user with cancellation reason
        </p>
    </li>
</ol>
<p dir="ltr">
    <b>Note:</b> each event status is highlighted by its own color
</p>
`
  },
  {
    title: "What’s on Events page:",
    html: `<p dir="ltr">
    Picture 3.7
</p>
<p dir="ltr">
    <img
        src=${picture7}
        width="624"
        height="235"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Start date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training Center City
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by End date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Event type
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Contractors/Subcontractors
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Sorting by
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            CSR Phase
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training Center
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Event Location
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
</ol>
`
  },
  {
    title: "What’s on Event card",
    html: `<p dir="ltr">
    In the system 6 (six) types of the events:
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Pre-screening
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 3.8
</p>
<p dir="ltr">
    <img
        src=${picture8}
        width="616"
        height="192"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Assessment
        </p>
    </li>
</ul>
<br/>
<p dir="ltr">
    Picture 3.9
</p>
<p>
    <img
        src=${picture9}
        width="596"
        height="186"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Training
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 3.10
</p>
<p dir="ltr">
    <img
        src=${picture10}
        width="611"
        height="189"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            OJT Training
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 3.11
</p>
<p dir="ltr">
    <img
        src=${picture11}
        width="624"
        height="97"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Commissioning Training
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 3.12
</p>
<p dir="ltr">
    <img
        src=${picture12}
        width="624"
        height="113"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Commissioning Assessment
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 3.13
</p>
<p dir="ltr">
    <img
        src=${picture13}
        width="624"
        height="73"
    />
</p>
<br/>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
`
  },
  {
    title: "How to see the attendance list?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on All events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Attendance tab button
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to see the grade list?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on All events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Grades tab button
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to add feedback?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on All events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Add feedback button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Leave feedback and press Add button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: This feedback will be sent to TCO Craft Training coordinators, the
    feedback will not be available for the Training center.
</p>
`
  }
];
