import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { AxiosError } from "axios";
import Column from "antd/lib/table/Column";

import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import {
  Button,
  Modal,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  Tag
} from "antd";
import PageMeta from "@components/PageMeta";
import { MemberTable } from "@components/common/MemberTable";
import { BreadcrumbRenderer } from "@components/BreadcrumbRenderer";
import InstructorForm from "@components/training-center/InstructorForm";
import TrainingCenterMemberForm from "@components/training-center/TrainingCenterMemberForm";

import {
  InviteTrainingCenterMemberPayload,
  EditTrainingCenterMemberPayload,
  TrainingCenterMember
} from "@models/trainingCenterMember";
import {
  CreateInstructorPayload,
  EditInstructorPayload,
  Instructor
} from "@models/instructor";
import { MemberStatus } from "@models/member";
import { PERMISSIONS } from "@models/permissions";

import { trainingCenterApi } from "@api/trainingCenterApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { UserContext } from "@contexts/UserContext";
import useDateFormatter from "@hooks/useDateFormat";
import { useDefaultQuery } from "@hooks/useDefaultQuery";
import { transformValidationError } from "@utils/errorHelper";

const MAX_DISCIPLINES_IN_TABLE_COLUMN = 3;

const { Title } = Typography;

const MembersPage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { user, userEntityId, checkPermission } = useContext(UserContext);
  const TRAINING_CENTER_ID = userEntityId;

  const { formatter } = useDateFormatter();

  const [isInviteFormVisible, setIsInviteFormVisible] =
    useState<boolean>(false);

  const [editableTrainingCenter, setEditableTrainingCenter] =
    useState<TrainingCenterMember | null>(null);

  const [isAddInstructorFormVisible, setIsAddInstructorFromVisible] =
    useState<boolean>(false);

  const [editableInstructor, setEditableInstructor] =
    useState<Instructor | null>(null);

  const trainingCenterMembers = useDefaultQuery(
    "getTrainingCenterAllMembers",
    () =>
      trainingCenterApi
        .getTrainingCenterMembers(TRAINING_CENTER_ID)
        .then((res) => res.data)
  );
  const activeMembers = trainingCenterMembers.data?.filter(
    (tcMember) => tcMember.status === MemberStatus.ACTIVE
  );
  const invitedMembers = trainingCenterMembers.data?.filter(
    (tcMember) => tcMember.status === MemberStatus.INVITED
  );

  const trainingCenterInstructors = useDefaultQuery(
    "getTrainingCenterInstructors",
    () =>
      trainingCenterApi
        .getTrainingCenterInstructors({
          trainingCenterId: TRAINING_CENTER_ID
        })
        .then((res) => res.data)
  );

  const trainingCenterSpecialities = useDefaultQuery(
    "getTrainingCenterSpecialities",
    () =>
      trainingCenterApi
        .getTrainingCenterSpecialities(TRAINING_CENTER_ID)
        .then((res) => res.data)
  );

  const trainingCenterMemberInviteMutation = useValidatedMutation({
    mutationFunction: (payload: InviteTrainingCenterMemberPayload) =>
      trainingCenterApi.inviteTrainingCenterMember(payload),
    onSuccess: () => {
      trainingCenterMembers.refetch();
      notification.success({
        message: t("members.editSuccess")
      });
      setIsInviteFormVisible(false);
    }
  });
  const inviteErrors = useMemo(
    () => transformValidationError(trainingCenterMemberInviteMutation.error),
    [trainingCenterMemberInviteMutation.error]
  );

  const trainingCenterMemberEditMutation = useValidatedMutation({
    mutationFunction: (payload: EditTrainingCenterMemberPayload) =>
      trainingCenterApi.editTrainingCenterMember(payload),

    onSuccess: () => {
      trainingCenterMembers.refetch();
      notification.success({
        message: t("members.editSuccess")
      });
      setEditableTrainingCenter(null);
    }
  });
  const editErrors = useMemo(
    () => transformValidationError(trainingCenterMemberEditMutation.error),
    [trainingCenterMemberEditMutation.error]
  );

  const trainingCenterMemberDeleteInviteMutation = useMutation(
    (payload: { trainingCenterId: number; email: string }) =>
      trainingCenterApi.deleteTrainingCenterMemberInvite(payload),
    {
      onSuccess: () => {
        trainingCenterMembers.refetch();
        notification.success({
          message: t("members.inviteDeleted")
        });
      },
      onError: (err: AxiosError<any>) => {
        if (err.response && err.response.status < 500) {
          notification.error({
            message: err.response.data.message
          });
        }
      }
    }
  );

  const trainingCenterMemberDeleteMutation = useMutation(
    (payload: { trainingCenterId: number; userId: number }) =>
      trainingCenterApi.deleteTrainingCenterMember(payload),
    {
      onSuccess: () => {
        trainingCenterMembers.refetch();
        notification.success({
          message: t("members.memberDeleted")
        });
      },
      onError: (err: AxiosError<any>) => {
        if (err.response && err.response.status < 500) {
          notification.error({
            message: err.response.data.message
          });
        }
      }
    }
  );

  const instructorCreateMutation = useValidatedMutation({
    mutationFunction: (payload: CreateInstructorPayload) =>
      trainingCenterApi.createTrainingCenterInstructor(payload),
    onSuccess: () => {
      trainingCenterInstructors.refetch();
      setIsAddInstructorFromVisible(false);
      notification.success({
        message: t("trainingCenters.addInstructorSuccess")
      });
    }
  });
  const createInstructorErrors = useMemo(
    () => transformValidationError(instructorCreateMutation.error),
    [instructorCreateMutation.error]
  );

  const instructorEditMutation = useValidatedMutation({
    mutationFunction: (payload: EditInstructorPayload) =>
      trainingCenterApi.editTrainingCenterInstructor(payload),
    onSuccess: () => {
      trainingCenterInstructors.refetch();
      setEditableInstructor(null);
      notification.success({
        message: t("trainingCenters.editInstructorSuccess")
      });
    }
  });
  const editInstructorErrors = useMemo(
    () => transformValidationError(instructorEditMutation.error),
    [instructorEditMutation.error]
  );

  const instructorDeleteMutation = useMutation(
    (payload: { trainingCenterId: number; instructorId: number }) =>
      trainingCenterApi.deleteTrainingCenterInstructor(payload),
    {
      onSuccess: () => {
        trainingCenterInstructors.refetch();
        notification.success({
          message: t("trainingCenters.deleteInstructorSuccess")
        });
      },
      onError: (err: AxiosError<any>) => {
        if (err.response && err.response.status < 500) {
          notification.error({
            message: err.response.data.message
          });
        }
      }
    }
  );

  const pageTitle = t("members.members");
  return (
    <>
      <PageMeta title={pageTitle} />
      <PageHeader
        title={<Title level={2}>{t("members.members")}</Title>}
        breadcrumb={{
          routes: [
            {
              breadcrumbName: t("trainingCenters.trainingCenter"),
              path: "/"
            },
            {
              breadcrumbName: pageTitle,
              path: ""
            }
          ],
          itemRender: BreadcrumbRenderer
        }}
        extra={[
          <>
            {checkPermission(PERMISSIONS.MEMBER_INVITE) && (
              <Button
                type="primary"
                key="1"
                icon={<UserAddOutlined />}
                onClick={() => setIsInviteFormVisible(true)}
              >
                {t("members.inviteMember")}
              </Button>
            )}
          </>
        ]}
      />

      <Modal
        visible={isInviteFormVisible}
        destroyOnClose
        title={t("members.inviteMember")}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setIsInviteFormVisible(false);
          trainingCenterMemberInviteMutation.reset();
        }}
      >
        <TrainingCenterMemberForm
          trainingCenterId={TRAINING_CENTER_ID}
          errors={inviteErrors}
          isLoading={trainingCenterMemberInviteMutation.isLoading}
          onSubmit={(values) =>
            trainingCenterMemberInviteMutation.mutate(values)
          }
          onCancel={() => {
            setIsInviteFormVisible(false);
            trainingCenterMemberInviteMutation.reset();
          }}
        />
      </Modal>

      <Modal
        title={t("members.editMember")}
        visible={!!editableTrainingCenter}
        destroyOnClose
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setEditableTrainingCenter(null);
          trainingCenterMemberEditMutation.reset();
        }}
      >
        <TrainingCenterMemberForm
          trainingCenterId={TRAINING_CENTER_ID}
          trainingCenterMember={editableTrainingCenter}
          isLoading={trainingCenterMemberEditMutation.isLoading}
          errors={editErrors}
          onSubmit={(values) => trainingCenterMemberEditMutation.mutate(values)}
          onCancel={() => {
            setEditableTrainingCenter(null);
            trainingCenterMemberEditMutation.reset();
          }}
        />
      </Modal>

      <Modal
        title={t("trainingCenters.addInstructor")}
        visible={isAddInstructorFormVisible}
        destroyOnClose
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setIsAddInstructorFromVisible(false);
          instructorCreateMutation.reset();
        }}
      >
        <InstructorForm
          trainingCenterId={TRAINING_CENTER_ID}
          trainingCenterSpecialities={trainingCenterSpecialities?.data || []}
          isLoading={instructorCreateMutation.isLoading}
          errors={createInstructorErrors}
          onSubmit={(values) => instructorCreateMutation.mutate(values)}
          onCancel={() => {
            setIsAddInstructorFromVisible(false);
            instructorCreateMutation.reset();
          }}
        />
      </Modal>

      <Modal
        title={t("trainingCenters.editInstructor")}
        visible={!!editableInstructor}
        destroyOnClose
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setEditableInstructor(null);
          instructorEditMutation.reset();
        }}
      >
        <InstructorForm
          trainingCenterId={TRAINING_CENTER_ID}
          instructor={editableInstructor}
          trainingCenterSpecialities={trainingCenterSpecialities?.data || []}
          isLoading={instructorEditMutation.isLoading}
          errors={editInstructorErrors}
          onSubmit={(values) => instructorEditMutation.mutate(values)}
          onCancel={() => {
            setEditableInstructor(null);
            instructorEditMutation.reset();
          }}
        />
      </Modal>

      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <MemberTable
          title={t("members.activeMembers")}
          isLoading={trainingCenterMembers.isFetching}
          members={activeMembers}
          extraColumns={[
            {
              title: t("phoneNumber"),
              render: (tcMember: TrainingCenterMember) => tcMember.phoneNumber
            }
          ]}
          renderActions={(tcMember: TrainingCenterMember) => (
            <Space size="middle">
              <Button
                title={t("edit")}
                type="primary"
                shape="circle"
                disabled={
                  tcMember.userId === user?.id ||
                  !checkPermission(PERMISSIONS.MEMBER_UPDATE)
                }
                icon={<EditOutlined />}
                onClick={() => setEditableTrainingCenter(tcMember)}
              />
              <Popconfirm
                title={t("members.confirmDelete", {
                  name: tcMember.firstName + " " + tcMember.lastName
                })}
                cancelText={t("cancel")}
                okText={t("yes")}
                disabled={
                  tcMember.userId === user?.id ||
                  !checkPermission(PERMISSIONS.MEMBER_DELETE) ||
                  trainingCenterMemberDeleteMutation.isLoading
                }
                onConfirm={() =>
                  trainingCenterMemberDeleteMutation.mutate({
                    trainingCenterId: TRAINING_CENTER_ID,
                    userId: tcMember.userId
                  })
                }
              >
                <Button
                  title={t("members.delete")}
                  danger
                  shape="circle"
                  disabled={
                    tcMember.userId === user?.id ||
                    !checkPermission(PERMISSIONS.MEMBER_DELETE) ||
                    trainingCenterMemberDeleteMutation.isLoading
                  }
                  loading={
                    trainingCenterMemberDeleteMutation.isLoading &&
                    trainingCenterMemberDeleteMutation.variables?.userId ===
                      tcMember.userId
                  }
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          )}
        />

        <MemberTable
          title={t("members.invitedMembers")}
          isLoading={trainingCenterMembers.isFetching}
          members={invitedMembers}
          extraColumns={[
            {
              title: t("phoneNumber"),
              render: (tcMember: TrainingCenterMember) => tcMember.phoneNumber
            }
          ]}
          renderActions={(tcMember: TrainingCenterMember) => {
            return (
              <Space size="middle">
                <Popconfirm
                  title={t("members.confirmDelete", {
                    name: tcMember.firstName + " " + tcMember.lastName
                  })}
                  cancelText={t("cancel")}
                  okText={t("yes")}
                  disabled={trainingCenterMemberDeleteInviteMutation.isLoading}
                  onConfirm={() =>
                    trainingCenterMemberDeleteInviteMutation.mutate({
                      trainingCenterId: TRAINING_CENTER_ID,
                      email: tcMember.email
                    })
                  }
                >
                  <Button
                    title={t("delete")}
                    danger
                    shape="circle"
                    disabled={
                      trainingCenterMemberDeleteInviteMutation.isLoading
                    }
                    loading={
                      trainingCenterMemberDeleteInviteMutation.isLoading &&
                      trainingCenterMemberDeleteInviteMutation.variables
                        ?.email === tcMember.email
                    }
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </Space>
            );
          }}
        />

        <Table
          title={() => (
            <Row justify="space-between">
              <Col>
                <Space align="center">
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    {t("instructors")}
                  </Typography.Title>

                  <Typography.Text type="secondary">
                    {t("totalCount", {
                      total: trainingCenterInstructors.data?.length || 0
                    })}
                  </Typography.Text>
                </Space>
              </Col>
              <Col>
                <Button
                  type="primary"
                  key="1"
                  icon={<UserAddOutlined />}
                  onClick={() => setIsAddInstructorFromVisible(true)}
                >
                  {t("trainingCenters.addInstructor")}
                </Button>
              </Col>
            </Row>
          )}
          locale={{ emptyText: t("noData") }}
          loading={trainingCenterInstructors.isFetching}
          dataSource={trainingCenterInstructors.data}
          bordered
          pagination={false}
          rowKey="id"
          onHeaderRow={() => {
            // TODO: find a better solution for setting disciplines column title
            // Currently is set this way because expandable column configuration does not include title
            const expandable = document.getElementsByClassName(
              "ant-table-row-expand-icon-cell"
            );
            if (expandable.length > 0) {
              expandable[0].innerHTML = t("disciplines.disciplines");
            }
            return {};
          }}
          expandable={{
            expandIconColumnIndex: 2,
            columnWidth: 420,

            rowExpandable: (instructor: Instructor) =>
              instructor.specialities?.length > MAX_DISCIPLINES_IN_TABLE_COLUMN,
            expandIcon: ({ record: instructor, onExpand, expanded }) => (
              <Space wrap>
                {instructor.specialities
                  .slice(0, MAX_DISCIPLINES_IN_TABLE_COLUMN)
                  .map((discipline) => (
                    <Tag color="geekblue" key={discipline.id}>
                      {i18n.language === "en"
                        ? discipline.speciality.nameEn
                        : discipline.speciality.nameRu}
                    </Tag>
                  ))}
                {instructor.specialities?.length >
                  MAX_DISCIPLINES_IN_TABLE_COLUMN && (
                  <Button
                    title={t("readMore")}
                    size="small"
                    onClick={(event) => onExpand(instructor, event)}
                  >
                    {t("readMore")}
                  </Button>
                )}
              </Space>
            ),
            expandedRowRender: (instructor: Instructor) => (
              <Space wrap>
                {instructor.specialities?.map(
                  ({ id, speciality: { nameEn, nameRu } }) => (
                    <Tag color={"geekblue"} key={id}>
                      {i18n.language === "en" ? nameEn : nameRu}
                    </Tag>
                  )
                )}
              </Space>
            )
          }}
        >
          <Column title={t("firstName")} dataIndex="firstName" />
          <Column title={t("lastName")} dataIndex="lastName" />
          <Column
            title={t("numberOfTimesTrained")}
            dataIndex="sumEventsProvided"
          />
          <Column
            title={t("dateAdded")}
            render={(instructor: Instructor) => formatter(instructor.createdAt)}
          />
          <Column
            title={t("actions")}
            render={(instructor: Instructor) => {
              return (
                <Space size="middle">
                  <Button
                    title={t("edit")}
                    type="primary"
                    shape="circle"
                    disabled={
                      !checkPermission(
                        PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_UPDATE
                      )
                    }
                    icon={<EditOutlined />}
                    onClick={() =>
                      setEditableInstructor({
                        ...instructor,
                        specialityIds: instructor.specialities.map(
                          (spec) => spec.specialityId
                        )
                      })
                    }
                  />
                  <Popconfirm
                    title={t("members.confirmDelete", {
                      name: instructor.firstName + " " + instructor.lastName
                    })}
                    cancelText={t("cancel")}
                    okText={t("yes")}
                    disabled={
                      !checkPermission(
                        PERMISSIONS.TRAINING_CENTER_INSTRUCTOR_DELETE
                      ) || instructorDeleteMutation.isLoading
                    }
                    onConfirm={() =>
                      instructorDeleteMutation.mutate({
                        trainingCenterId: TRAINING_CENTER_ID,
                        instructorId: instructor.id
                      })
                    }
                  >
                    <Button
                      title={t("delete")}
                      danger
                      shape="circle"
                      disabled={instructorDeleteMutation.isLoading}
                      loading={
                        instructorDeleteMutation.isLoading &&
                        instructorDeleteMutation.variables?.instructorId ===
                          instructor.id
                      }
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </Space>
              );
            }}
          />
        </Table>
      </Space>
    </>
  );
};

export default MembersPage;
