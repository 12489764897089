import { useEffect } from "react";
import { UseMutateFunction } from "react-query";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Modal, Row } from "antd";

import { Discipline, EditDisciplinePayload } from "@models/discipline";

interface Props {
  handleCloseModal: () => void;
  isModalVisible: boolean;
  currentDiscipline: Discipline;
  editDiscipline: UseMutateFunction<
    unknown,
    unknown,
    { values: EditDisciplinePayload; id: number }
  >;
  errors: { [key: string]: string };
  isLoading?: boolean;
}

const EditDisciplineModal: React.FC<Props> = ({
  handleCloseModal,
  isModalVisible,
  currentDiscipline,
  editDiscipline,
  errors,
  isLoading
}) => {
  const [t] = useTranslation();
  const { id } = currentDiscipline;

  const formik = useFormik<EditDisciplinePayload>({
    initialValues: {
      nameEn: currentDiscipline.nameEn,
      nameRu: currentDiscipline.nameRu
    },
    onSubmit: (values) => editDiscipline({ values, id })
  });

  useEffect(() => {
    formik.setValues({
      nameEn: currentDiscipline.nameEn,
      nameRu: currentDiscipline.nameRu
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDiscipline]);

  useEffect(() => {
    formik.setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <Modal
      visible={isModalVisible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      title={t("disciplines.createDiscipline")}
      footer={null}
      width={800}
      onCancel={handleCloseModal}
    >
      <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <Form.Item
              label={t("disciplines.disciplineNameEn")}
              validateStatus={formik.errors.nameEn && "error"}
              help={formik.errors.nameEn}
            >
              <Input
                placeholder="Crane Operator: Overhead/Jib Crane"
                name="nameEn"
                value={formik.values.nameEn}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={t("disciplines.disciplineNameRu")}
              validateStatus={formik.errors.nameRu && "error"}
              help={formik.errors.nameRu}
            >
              <Input
                placeholder="Оператор крана - балки управляемого с пола"
                name="nameRu"
                value={formik.values.nameRu}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <Button htmlType="button" onClick={handleCloseModal}>
              {t("cancel")}
            </Button>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              {t("save")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditDisciplineModal;
