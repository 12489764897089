import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";

import FormItem from "antd/lib/form/FormItem";
import { useFormik } from "formik";
import moment from "moment";

import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { City } from "@models/countryCity";
import { Discipline } from "@models/discipline";
import { EventFilter, EventType } from "@models/event";

import { EVENT_TYPES_SELECT_OPTIONS } from "@constants/eventTypesSelectOptions";
import { COMPETENCY_LEVEL_SELECT_OPTIONS } from "@constants/competencyLevelSelectOptions";
import { SORTING_OPTIONS } from "@constants/eventSortOptions";
import { EVENT_TYPES } from "@constants/eventTypes";
import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

type FilterFormField =
  | "dateStart"
  | "dateEnd"
  | "type"
  | "seats"
  | "languageCode"
  | "competencyLevel"
  | "customCompetencyLevel"
  | "specialityIds"
  | "trainingCenterIds"
  | "csrPhases"
  | "contractorIds"
  | "eventIds"
  | "sortBy"
  | "cityIds"
  | "eventCityIds";

interface Props {
  hiddenFields?: FilterFormField[];
  initialValues?: EventFilter;
  errors?: { [key: string]: string };
  disciplines?: Discipline[];
  trainingCenters?: { id: number; name: string }[];
  contractors?: { id: number; name: string }[];
  cities?: City[];
  isLoading?: boolean;
  onSubmit?: (values: EventFilter) => void;
}

export const EventFilterForm: React.FC<Props> = ({
  initialValues,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  const formik = useFormik<EventFilter>({
    initialValues: {
      ...initialValues,
      specialityIds: initialValues?.specialityIds?.map((s) => Number(s)),
      trainingCenterIds: initialValues?.trainingCenterIds?.map((t) =>
        Number(t)
      ),
      contractorIds: initialValues?.contractorIds?.map((c) => Number(c)),
      languageCode: Number(initialValues?.languageCode) || undefined,
      cityIds: initialValues?.cityIds?.map((c) => Number(c)),
      competencyLevel: Number(initialValues?.competencyLevel) || undefined,
      type: Number(initialValues?.type) || undefined,
      isCommissioning: Boolean(initialValues?.isCommissioning) || undefined,
      csrPhases: initialValues?.csrPhases?.map((t) => Number(t)),
      sortBy: Number(initialValues?.sortBy) || undefined,
      eventIds: initialValues?.eventIds?.map((t) => Number(t)),
      eventCityIds: initialValues?.eventCityIds?.map((c) => Number(c))
    },
    onSubmit: (values) => {
      if (props.onSubmit) {
        if (
          [
            EVENT_TYPES.COMMISSIONING_ASSESSMENT,
            EVENT_TYPES.COMMISSIONING_TRAINING
          ].includes(values.type || 0)
        ) {
          props.onSubmit({
            ...values,
            type:
              values.type === EVENT_TYPES.COMMISSIONING_ASSESSMENT
                ? EventType.ASSESSMENT
                : EventType.TRAINING,
            isCommissioning: true
          });
        } else {
          props.onSubmit(values);
        }
      }
    }
  });

  const disciplinesSelectOptions = useMemo(
    () =>
      props.disciplines?.map((d) => ({
        value: Number(d.id),
        label: i18n.language === "en" ? d.nameEn : d.nameRu
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.disciplines]
  );

  const trainingCentersSelectOptions = props.trainingCenters?.map(
    ({ id, name }) => ({
      value: Number(id),
      label: name
    })
  );

  const contractorSelectOptions = useMemo(
    () =>
      props.contractors?.map((c) => ({
        value: c.id,
        label: c.name
      })),
    [props.contractors]
  );

  const citiesSelectOptions = useMemo(
    () =>
      props.cities?.map((c) => ({
        value: c.id,
        label: i18n.language === "en" ? c.nameEn : c.nameRu
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.cities]
  );

  const languagesSelectOptions = [
    { value: 1, label: i18n.language === "en" ? "Russian" : "Русский" },
    { value: 2, label: i18n.language === "en" ? "English" : "Английский" },
    { value: 3, label: i18n.language === "en" ? "Kazakh" : "Казахский" }
  ];

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={8} style={{ marginBottom: "16px" }}>
        {!props.hiddenFields?.includes("dateStart") && (
          <Col md={4}>
            <Form.Item
              label={t("events.filters.dateFrom")}
              labelCol={{ span: 24 }}
            >
              <DatePicker
                placeholder=""
                value={
                  formik.values.dateStart
                    ? moment(formik.values.dateStart, "YYYY-MM-DD")
                    : undefined
                }
                onChange={(_, value) =>
                  formik.setFieldValue("dateStart", value)
                }
                disabledDate={(date) =>
                  date && date >= moment(formik.values.dateEnd, "YYYY-MM-DD")
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        )}

        {!props.hiddenFields?.includes("dateEnd") && (
          <Col md={4}>
            <Form.Item
              label={t("events.filters.dateTo")}
              labelCol={{ span: 24 }}
            >
              <DatePicker
                placeholder=""
                value={
                  formik.values.dateEnd
                    ? moment(formik.values.dateEnd, "YYYY-MM-DD")
                    : undefined
                }
                onChange={(_, value) => formik.setFieldValue("dateEnd", value)}
                disabledDate={(date) =>
                  date && date <= moment(formik.values.dateStart, "YYYY-MM-DD")
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        )}

        {!props.hiddenFields?.includes("type") && (
          <Col span={6}>
            <FormItem label={t("events.filters.type")} labelCol={{ span: 24 }}>
              <Select
                value={formik.values.type}
                options={EVENT_TYPES_SELECT_OPTIONS.map((o) => ({
                  label: t(o.label),
                  value: o.value
                }))}
                onSelect={(
                  value: typeof EVENT_TYPES[keyof typeof EVENT_TYPES]
                ) => {
                  if (
                    value === EVENT_TYPES.ASSESSMENT ||
                    value === EVENT_TYPES.TRAINING
                  ) {
                    formik.setFieldValue("isCommissioning", false);
                    formik.setFieldValue("type", value);
                  } else if (
                    value === EVENT_TYPES.COMMISSIONING_ASSESSMENT ||
                    value === EVENT_TYPES.COMMISSIONING_TRAINING
                  ) {
                    formik.setFieldValue("isCommissioning", true);
                    formik.setFieldValue("type", value);
                  } else {
                    formik.setFieldValue("isCommissioning", undefined);
                    formik.setFieldValue("type", value);
                  }
                }}
                onClear={() => {
                  formik.setFieldValue("type", undefined);
                  formik.setFieldValue("isCommissioning", undefined);
                }}
                notFoundContent={t("noData")}
                allowClear
              />
            </FormItem>
          </Col>
        )}

        {!props.hiddenFields?.includes("csrPhases") && (
          <Col md={4}>
            <Form.Item label={t("events.csrPhase")} labelCol={{ span: 24 }}>
              <Select
                mode="multiple"
                value={formik.values.csrPhases}
                allowClear
                maxTagCount={1}
                showArrow
                onChange={(v) => formik.setFieldValue("csrPhases", v)}
              >
                {CSR_PHASE_OPTIONS.map((cp) => (
                  <Select.Option value={cp.value} key={cp.value}>
                    {t(cp.label, { no: cp.value })}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {!props.hiddenFields?.includes("competencyLevel") && (
          <Col span={6}>
            <FormItem
              label={t("events.filters.competencyLevel")}
              labelCol={{ span: 24 }}
            >
              <Select
                value={formik.values.competencyLevel}
                options={COMPETENCY_LEVEL_SELECT_OPTIONS.map((o) => ({
                  label: t(o.label),
                  value: o.value
                }))}
                onSelect={(value: number) =>
                  formik.setFieldValue("competencyLevel", value)
                }
                onClear={() =>
                  formik.setFieldValue("competencyLevel", undefined)
                }
                notFoundContent={t("noData")}
                allowClear
              />
            </FormItem>
          </Col>
        )}

        {!props.hiddenFields?.includes("specialityIds") && (
          <Col span={8}>
            <FormItem
              label={t("events.filters.disciplines")}
              labelCol={{ span: 24 }}
            >
              <SearchSelect
                value={formik.values.specialityIds}
                options={disciplinesSelectOptions}
                onChange={(value: number) =>
                  formik.setFieldValue("specialityIds", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}

        {!props.hiddenFields?.includes("contractorIds") && (
          <Col span={6}>
            <FormItem
              label={t("events.filters.contractorsSubcontractors")}
              labelCol={{ span: 24 }}
            >
              <SearchSelect
                value={formik.values.contractorIds}
                options={contractorSelectOptions}
                onChange={(value: number) =>
                  formik.setFieldValue("contractorIds", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}

        {!props.hiddenFields?.includes("trainingCenterIds") && (
          <Col span={6}>
            <FormItem
              label={t("events.filters.trainingCenters")}
              labelCol={{ span: 24 }}
            >
              <SearchSelect
                value={formik.values.trainingCenterIds}
                options={trainingCentersSelectOptions}
                onChange={(value: number) =>
                  formik.setFieldValue("trainingCenterIds", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}

        {!props.hiddenFields?.includes("languageCode") && (
          <Col span={4}>
            <FormItem
              label={t("events.filters.language")}
              labelCol={{ span: 24 }}
            >
              <Select
                value={formik.values.languageCode}
                options={languagesSelectOptions}
                onChange={(value: number) =>
                  formik.setFieldValue("languageCode", value)
                }
                allowClear
                notFoundContent={t("noData")}
              />
            </FormItem>
          </Col>
        )}

        {!props.hiddenFields?.includes("cityIds") && (
          <Col span={4}>
            <FormItem
              label={t("events.filters.cities")}
              labelCol={{ span: 24 }}
            >
              <SearchSelect
                value={formik.values.cityIds}
                options={citiesSelectOptions}
                onChange={(value: number) =>
                  formik.setFieldValue("cityIds", value)
                }
                mode="multiple"
                maxTagCount={1}
              />
            </FormItem>
          </Col>
        )}

        {!props.hiddenFields?.includes("eventIds") && (
          <Col span={4}>
            <FormItem
              label={t("events.filters.eventId")}
              labelCol={{ span: 24 }}
            >
              <Input
                value={formik.values.eventIds?.join(" ")}
                allowClear
                onChange={(e) =>
                  formik.setFieldValue(
                    "eventIds",
                    e.target.value !== ""
                      ? e.target.value?.split(" ")
                      : undefined
                  )
                }
              />
            </FormItem>
          </Col>
        )}

        {!props.hiddenFields?.includes("sortBy") && (
          <Col span={4}>
            <FormItem
              label={t("events.sorting.sortBy")}
              labelCol={{ span: 24 }}
            >
              <Select
                value={formik.values.sortBy}
                options={SORTING_OPTIONS.map((s) => ({
                  value: s.value,
                  label: t(s.label)
                }))}
                onChange={(value: number) =>
                  formik.setFieldValue("sortBy", value)
                }
                allowClear
              />
            </FormItem>
          </Col>
        )}
        {!props.hiddenFields?.includes("eventCityIds") && (
          <Col span={4}>
            <FormItem
              label={t("events.filters.eventLocation")}
              labelCol={{ span: 24 }}
            >
              <SearchSelect
                value={formik.values.eventCityIds}
                options={citiesSelectOptions}
                onChange={(value: number) =>
                  formik.setFieldValue("eventCityIds", value)
                }
                mode="multiple"
                maxTagCount={1}
              />
            </FormItem>
          </Col>
        )}

        <Col xs={24} style={{ textAlign: "right" }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={props.isLoading}
            style={{ width: 150 }}
          >
            {t("search")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
