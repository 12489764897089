import axios from "axios";
import qs from "qs";

import { Contractor } from "@models/contractor";
import { EmployeeEventFilters } from "@models/employee";
import {
  AdminEditEventPayload,
  EventAttendance,
  EventData,
  EventDetails,
  EventEmployeeDetails,
  EventEmployeeStatus,
  EventSearchFilter,
  EventType
} from "@models/event";

import { PaginatedResponse } from "@api";

import { getApiSuffixByEventType } from "./eventApi";

const searchContractorEvents = (
  contractorId: number,
  page: number,
  filters: EventSearchFilter
) => {
  const queryString = qs.stringify({ ...filters, page }, { indices: false });
  return axios.get<PaginatedResponse<EventData>>(
    `/api/contractor/${contractorId}/event/search?${queryString}`
  );
};

const getAdminEvents = (page: number, filters: EventSearchFilter) => {
  const queryString = qs.stringify({ ...filters, page }, { indices: false });
  return axios.get<PaginatedResponse<EventData>>(
    `/api/event/search?${queryString}`
  );
};

const getAdminEventDetails = (id: number) =>
  axios.get<EventDetails>(`/api/event/${id}`);

const getAdminEventContractors = (id: number) =>
  axios.get<Contractor[]>(`/api/event/${id}/contractor`);

const getAdminEventEmployees = (id: number, filters?: EmployeeEventFilters) =>
  axios.get<EventEmployeeDetails[]>(`/api/event/${id}/employee`, {
    params: filters
  });

const getAdminEventAttendance = (id: number) =>
  axios.get<EventAttendance[]>(`/api/event/${id}/attendance`);

const adminCancelEvent = ({
  eventId,
  cancellationReason
}: {
  eventId: number;
  cancellationReason: string;
}) =>
  axios.put(`/api/event/${eventId}/cancel`, {
    cancellationReason
  });

const adminEditEvent = (
  id: number,
  type: EventType,
  data: AdminEditEventPayload
) => {
  const apiUrlSuffix = getApiSuffixByEventType(type);

  return axios.put(`/api/event/${apiUrlSuffix}/${id}`, data);
};

const adminDeleteEvent = ({ eventId }: { eventId: number }) =>
  axios.delete(`/api/event/${eventId}`);

const adminActivateEvent = ({
  eventId,
  trainingCenterId
}: {
  trainingCenterId: number;
  eventId: number;
}) =>
  axios.put(
    `/api/training-center/${trainingCenterId}/event/${eventId}/grade/status`
  );

const contractorRejectEventEmployee = ({
  eventId,
  contractorId,
  employeeId,
  rejectionReason
}: {
  eventId: number;
  contractorId: number;
  employeeId: number;
  rejectionReason: string;
}) => {
  return axios.post(
    `/api/contractor/${contractorId}/event/${eventId}/employee/${employeeId}/reject`,
    {
      rejectionReason
    }
  );
};

const contractorDeregisterEventEmployee = ({
  eventId,
  contractorId,
  employeeId
}: {
  eventId: number;
  contractorId: number;
  employeeId: number;
}) => {
  return axios.put(
    `/api/contractor/${contractorId}/event/${eventId}/employee/${employeeId}/status`,
    {
      status: EventEmployeeStatus.DEREGISTERED
    }
  );
};

const adminRejectEventEmployee = ({
  eventId,
  employeeId,
  rejectionReason
}: {
  eventId: number;
  employeeId: number;
  rejectionReason: string;
}) =>
  axios.post(`/api/event/${eventId}/employee/${employeeId}/reject`, {
    rejectionReason
  });

const adminValidateEvent = (eventId: number, data: AdminEditEventPayload) => {
  return axios.post(`/api/event/${eventId}/validate`, data);
};

const tcValidateEvent = (
  eventId: number,
  trainingCenterId: number,
  data: AdminEditEventPayload
) =>
  axios.post(
    `/api/training-center/${trainingCenterId}/event/${eventId}/validate`,
    data
  );

const adminDeleteEventEmlpoyee = ({
  eventId,
  employeeId
}: {
  eventId: number;
  employeeId: number;
}) => axios.delete(`/api/event/${eventId}/employee/${employeeId}`);

export const eventsApi = {
  searchContractorEvents,
  getAdminEventContractors,
  getAdminEventDetails,
  getAdminEventEmployees,
  getAdminEventAttendance,
  getAdminEvents,
  adminCancelEvent,
  adminEditEvent,
  adminDeleteEvent,
  adminActivateEvent,
  adminValidateEvent,
  tcValidateEvent,
  contractorRejectEventEmployee,
  adminRejectEventEmployee,
  adminDeleteEventEmlpoyee,
  contractorDeregisterEventEmployee
};
