import React, { useMemo, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

import { AxiosError } from "axios";
import { format, parseISO } from "date-fns";

import { Button, Modal, notification, Popconfirm, Space } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import { EditSubContractorMemberForm } from "@components/subcontractors/EditSubContractorMemberForm";
import ExportMembersButton from "@components/common/ExportMembersButton";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { MemberTable } from "@components/common/MemberTable";
import { NewSubContractorMemberForm } from "@components/subcontractors/NewSubContractorMemberForm";
import PageMeta from "@components/PageMeta";

import { AdminMember } from "@models/adminMember";
import { MemberStatus } from "@models/member";

import { memberApi } from "@api/memberApi";
import { contractorsApi } from "@api/contractorsApi";
import { subcontractorMemberApi } from "@api/subcontractorMemberApi";

import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

const MembersPage: React.FC = () => {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const contractorId = user?.userRoles[0].entityId || 0;
  const [isModalShown, setIsModalShown] = useState(false);
  const [editedMember, setEditedMember] = useState<AdminMember>();

  const { data, isLoading, refetch } = useQuery("getContractor", () =>
    contractorsApi.getContractor(contractorId).then((res) => res.data)
  );

  const activeMembers = data?.members.filter(
    (am) => am.status === MemberStatus.ACTIVE
  );
  const invitedMembers = data?.members.filter(
    (am) => am.status === MemberStatus.INVITED
  );

  const inviteMemberMutation = useValidatedMutation({
    mutationFunction: (values: any) =>
      memberApi.inviteSubcontractorMember(
        data?.parentContractor?.id,
        contractorId,
        values
      ),
    onSuccess(_, values) {
      notification.success({
        message: t("members.inviteSuccess", { email: values.email })
      });
      refetch();
      setIsModalShown(false);
    },
    onError() {}
  });
  const inviteErrors = useMemo(
    () => transformValidationError(inviteMemberMutation.error),
    [inviteMemberMutation.error]
  );

  const deleteInviteMutation = useMutation(
    (email: string) =>
      subcontractorMemberApi.deleteSubcontractorMemberInvite({
        contractorId: data?.parentContractorId || 0,
        subcontractorId: contractorId,
        email
      }),
    {
      onSuccess() {
        notification.success({ message: t("members.inviteDeleted") });

        refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const deleteMemberMutation = useMutation(
    (id: number) =>
      contractorsApi.deleteSubContractorMember(
        data?.parentContractor?.id,
        contractorId,
        id
      ),
    {
      onSuccess() {
        notification.success({ message: t("members.memberDeleted") });

        refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const editMemberMutation = useValidatedMutation({
    mutationFunction: ({
      memberId,
      payload
    }: {
      memberId: number;
      payload: any;
    }) => memberApi.updateContractorMember({ contractorId, memberId, payload }),

    onSuccess() {
      notification.success({
        message: t("members.editSuccess")
      });
      refetch();
      setEditedMember(undefined);
    }
  });
  const editErrors = useMemo(
    () => transformValidationError(editMemberMutation.error),
    [editMemberMutation.error]
  );

  return (
    <>
      <PageMeta title={t("members.members")} />

      <AppPageHeader
        title={t("members.members")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "admin" },
          { breadcrumbName: t("members.members"), path: "members" }
        ]}
        extra={[
          <ExportMembersButton
            fileName="members_export.xlsx"
            url="/api/admin/export/users/admins"
          />,
          <Button
            key="2"
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => setIsModalShown(true)}
          >
            {t("members.inviteMember")}
          </Button>
        ]}
      />

      <Modal
        visible={isModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("members.memberInfo")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsModalShown(false);
          inviteMemberMutation.reset();
        }}
      >
        <NewSubContractorMemberForm
          isLoading={inviteMemberMutation.isLoading}
          errors={inviteErrors}
          onCancel={() => {
            setIsModalShown(false);
            inviteMemberMutation.reset();
          }}
          onSubmit={(values) => {
            inviteMemberMutation.mutate(values);
          }}
        />
      </Modal>

      <Modal
        visible={!!editedMember}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("edit")}
        footer={null}
        width={800}
        onCancel={() => {
          setEditedMember(undefined);
          editMemberMutation.reset();
        }}
      >
        <EditSubContractorMemberForm
          contractorMember={editedMember}
          errors={editErrors}
          isLoading={editMemberMutation.isLoading}
          onCancel={() => {
            setEditedMember(undefined);
            editMemberMutation.reset();
          }}
          onSubmit={(values) => {
            if (editedMember) {
              editMemberMutation.mutate({
                memberId: editedMember.id,
                payload: values
              });
            }
          }}
        />
      </Modal>

      <MemberTable
        title={t("members.activeMembers")}
        isLoading={isLoading}
        members={activeMembers}
        renderActions={(am: AdminMember) => (
          <Space size="middle">
            <Button
              title={t("edit")}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              disabled={am.id === user?.id}
              onClick={() => {
                setEditedMember(am);
              }}
            />

            <Popconfirm
              title={t("members.confirmDelete", {
                name: am.firstName + " " + am.lastName
              })}
              cancelText={t("cancel")}
              okText={t("yes")}
              disabled={deleteMemberMutation.isLoading}
              onConfirm={() => deleteMemberMutation.mutate(am.id)}
            >
              <Button
                title={t("members.delete")}
                danger
                shape="circle"
                disabled={deleteMemberMutation.isLoading || am.id === user?.id}
                loading={
                  deleteMemberMutation.isLoading &&
                  deleteMemberMutation.variables === am.userId
                }
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )}
      />

      <br />

      <MemberTable
        title={t("members.invitedMembers")}
        isLoading={isLoading}
        members={invitedMembers}
        extraColumns={[
          {
            title: t("members.invitationDate"),
            render: (am: AdminMember) =>
              format(parseISO(am.createdAt), "yyyy/MM/dd HH:mm:ss")
          }
        ]}
        renderActions={(am) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={t("members.confirmDelete", {
                  name: am.firstName + " " + am.lastName
                })}
                cancelText={t("cancel")}
                okText={t("yes")}
                disabled={deleteInviteMutation.isLoading}
                onConfirm={() => deleteInviteMutation.mutate(am.email)}
              >
                <Button
                  title={t("delete")}
                  danger
                  shape="circle"
                  disabled={deleteInviteMutation.isLoading}
                  loading={
                    deleteInviteMutation.isLoading &&
                    deleteInviteMutation.variables === am.email
                  }
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          );
        }}
      />
      <br />
    </>
  );
};

export default MembersPage;
