import { useTranslation } from "react-i18next";

import Column from "antd/lib/table/Column";

import { Table } from "antd";

import {
  FormSettingsParticipant,
  ParticipantResponse,
  StaticFieldProps
} from "@models/migrations";

interface Props {
  notRegisteredUsers: Partial<ParticipantResponse>[];
  participants: FormSettingsParticipant[];
  renderStaticField: (props: StaticFieldProps) => string;
}

const NotRegisteredUsersTable: React.FC<Props> = ({
  notRegisteredUsers,
  participants,
  renderStaticField
}) => {
  const [t] = useTranslation();

  return (
    <Table
      dataSource={notRegisteredUsers}
      scroll={{ x: 2600, y: 300 }}
      pagination={{ hideOnSinglePage: true }}
      rowKey="index"
    >
      <Column
        title="#"
        dataIndex="number"
        key="number"
        width="3%"
        render={(_, __, index) => <>{index + 1}</>}
      />

      {participants.map(
        (p, index) =>
          !p.hidden && (
            <Column
              title={t(`migrations.form.${p.name}`)}
              dataIndex={p.name}
              key={p.name}
              render={(v, obj: ParticipantResponse) => {
                return !obj.isRegistered && p.name === "iin" ? (
                  <span style={{ color: "#ff4d4f" }}>{v}</span>
                ) : (
                  renderStaticField({
                    name: p.name,
                    index,
                    notRegistered: true,
                    staticValue: v
                  })
                );
              }}
            />
          )
      )}
    </Table>
  );
};

export default NotRegisteredUsersTable;
