import React from "react";
import { useTranslation } from "react-i18next";

import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Row, Typography } from "antd";

import { EventCertificate } from "@models/certificate";

interface Props {
  certificate: EventCertificate;
  loading?: boolean;
  onDownload?: (certificateId: string) => void;
}

export const CertificateDetailsCard = (props: Props) => {
  const [t, i18n] = useTranslation();

  const isEn = i18n.language === "en";

  const cert = props.certificate;

  return (
    <Card
      title={
        <Row justify="space-between">
          <Typography.Title level={4}>
            {cert.firstName + " " + cert.lastName}
          </Typography.Title>

          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() =>
              props.onDownload && props.onDownload(cert.certificateId)
            }
            loading={props.loading}
          >
            {t("certificates.downloadCert")}
          </Button>
        </Row>
      }
      bodyStyle={{ padding: 0 }}
    >
      <Descriptions
        bordered
        size="small"
        labelStyle={{ fontWeight: "bold" }}
        contentStyle={{ padding: 4 }}
        column={2}
      >
        <Descriptions.Item label={t("certificates.ctpNumber")}>
          {cert.certificateId}
        </Descriptions.Item>

        <Descriptions.Item label={t("certificates.issuingBody")}>
          {cert.trainingCenterName}
        </Descriptions.Item>

        <Descriptions.Item label={t("certificates.iin")}>
          {cert.iin}
        </Descriptions.Item>

        <Descriptions.Item label={t("certificates.level")}>
          {cert.competencyLevel}
        </Descriptions.Item>

        <Descriptions.Item label={t("certificates.discipline")}>
          {isEn ? cert.disciplineNameEn : cert.disciplineNameRu}
        </Descriptions.Item>

        <Descriptions.Item label={t("certificates.academicHours")}>
          {cert.academicHours}
        </Descriptions.Item>

        <Descriptions.Item label={t("certificates.completionDate")}>
          {cert.endDate}
        </Descriptions.Item>

        <Descriptions.Item label={t("certificates.company")}>
          {cert.contractorName}
        </Descriptions.Item>

        <Descriptions.Item label={t("certificates.projectName")}>
          {cert.projectName}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
