import qs from "qs";
import axios from "axios";

import { ContractorsMap } from "@models/contractor";
import {
  CreateInstructorPayload,
  EditInstructorPayload,
  Instructor
} from "@models/instructor";
import {
  EditTrainingCenterPayload,
  RegisterTrainingCenterPayload,
  TrainingCenter,
  TrainingCenterReport,
  TrainingCenterReportPayload
} from "@models/trainingCenter";
import {
  TrainingCenterMember,
  EditTrainingCenterMemberPermissionPayload,
  InviteTrainingCenterMemberPayload,
  EditTrainingCenterMemberPayload
} from "@models/trainingCenterMember";
import { Discipline } from "@models/discipline";
import { PaginationItem } from "@models/paginationItem";

const getAllTrainingCenters = () =>
  axios.get<TrainingCenter[]>("/api/training-center");

const getTrainingCenter = (id: number) =>
  axios.get<TrainingCenter>(`/api/training-center/${id}`);

const registerTrainingCenter = (payload: RegisterTrainingCenterPayload) =>
  axios.post("api/training-center", payload);

const editTrainingCenter = ({ id, ...payload }: EditTrainingCenterPayload) =>
  axios.put(`/api/training-center/${id}`, payload);

const deleteTrainingCenter = (id: number) =>
  axios.delete(`/api/training-center/${id}`);

const getTrainingCenterMembers = (trainingCenterId: number) =>
  axios.get<TrainingCenterMember[]>(
    `/api/training-center/${trainingCenterId}/member`
  );

const getTrainingCenterMember = (payload: {
  trainingCenterId: number;
  userId: number;
}) =>
  axios.get<TrainingCenterMember>(
    `/api/training-center/${payload.trainingCenterId}/member/${payload.userId}`
  );

const inviteTrainingCenterMember = ({
  trainingCenterId,
  ...payload
}: InviteTrainingCenterMemberPayload) =>
  axios.post(`/api/training-center/${trainingCenterId}/member/invite`, payload);

const editTrainingCenterMember = ({
  trainingCenterId,
  userId,
  ...payload
}: EditTrainingCenterMemberPayload) =>
  axios.post(
    `/api/training-center/${trainingCenterId}/member/${userId}/update`,
    payload
  );

const editTrainingCenterMemberPermission = (
  payload: EditTrainingCenterMemberPermissionPayload
) =>
  axios.post(
    `/api/training-center/${payload.trainingCenterId}/member/${payload.userId}/permission/update`,
    { permissions: payload.permissions }
  );

const activateTrainingCenterMember = (payload: {
  trainingCenterId: number;
  userId: number;
}) =>
  axios.get(
    `api/admin/member/${payload.userId}/training-center/${payload.trainingCenterId}/activate`
  );

const resendTrainingCenterMemberInvite = (payload: {
  trainingCenterId: number;
  email: string;
}) =>
  axios.get(
    `/api/training-center/${
      payload.trainingCenterId
    }/member/invite/resend?${qs.stringify({ email: payload.email })}`
  );

const deleteTrainingCenterMemberInvite = (payload: {
  trainingCenterId: number;
  email: string;
}) =>
  axios.delete(
    `/api/training-center/${
      payload.trainingCenterId
    }/member/invite/delete?${qs.stringify({ email: payload.email })}`
  );

const deleteTrainingCenterMember = (payload: {
  trainingCenterId: number;
  userId: number;
}) =>
  axios.delete(
    `/api/training-center/${payload.trainingCenterId}/member/${payload.userId}`
  );

const getTrainingCenterInstructors = (payload: {
  trainingCenterId: number;
  specialityIds?: number[];
}) =>
  axios.get<Instructor[]>(
    `/api/training-center/${payload.trainingCenterId}/instructor/all`,
    {
      params: payload.specialityIds
    }
  );

const createTrainingCenterInstructor = ({
  trainingCenterId,
  ...payload
}: CreateInstructorPayload) =>
  axios.post(`/api/training-center/${trainingCenterId}/instructor`, payload);

const editTrainingCenterInstructor = ({
  trainingCenterId,
  instructorId,
  ...payload
}: EditInstructorPayload) =>
  axios.post(
    `/api/training-center/${trainingCenterId}/instructor/${instructorId}`,
    payload
  );

const deleteTrainingCenterInstructor = (payload: {
  trainingCenterId: number;
  instructorId: number;
}) =>
  axios.delete(
    `/api/training-center/${payload.trainingCenterId}/instructor/${payload.instructorId}`
  );

const getTrainingCenterSpecialities = (trainingCenterId: number) =>
  axios.get<Discipline[]>(
    `/api/training-center/${trainingCenterId}/specialities`
  );

const getContractorsWithTickets = (payload: { trainingCenterId: number }) =>
  axios.get<ContractorsMap>(
    `/api/training-center/${payload.trainingCenterId}/contractor/with-tickets`
  );

const getTrainingCenterReports = (
  trainingCenterId: number,
  reportType: number,
  page: number
) =>
  axios.get<TrainingCenterReport[]>(
    `/api/training-center/${trainingCenterId}/reports`,
    { params: { type: reportType, page } }
  );

const getTrainingCenterReport = (
  trainingCenterId: number,
  trainingCenterReportId: number
) =>
  axios.get<TrainingCenterReport>(
    `/${trainingCenterId}/reports/${trainingCenterReportId}`
  );

const getAdminTrainingCenterReport = (trainingCenterReportId: number) =>
  axios.get<TrainingCenterReport>(
    `/api/admin/training-center/reports/${trainingCenterReportId}`
  );

const createTrainingCenterReports = ({
  trainingCenterId,
  payload: { files, ...payload }
}: {
  trainingCenterId: number;
  payload: TrainingCenterReportPayload;
}) => {
  const formData = new FormData();
  for (const k in payload) {
    const val: any = payload[k];

    if (val !== undefined) {
      formData.append(k, val);
    }
  }
  if (files && files.length) {
    files.forEach((v, i) => {
      formData.append(`files`, v);
    });
  }
  return axios.post(
    `/api/training-center/${trainingCenterId}/reports`,
    formData
  );
};

const updateTrainingCenterReport = ({
  trainingCenterId,
  tcReportId,
  payload: { files, ...payload }
}: {
  trainingCenterId: number;
  tcReportId: number | undefined;
  payload: TrainingCenterReportPayload;
}) => {
  const formData = new FormData();
  for (const k in payload) {
    const val: any = payload[k];

    if (val !== undefined) {
      formData.append(k, val);
    }
  }
  if (files && files.length) {
    files.forEach((v, i) => {
      formData.append(`files`, v);
    });
  }
  return axios.put(
    `/api/training-center/${trainingCenterId}/reports/${tcReportId}`,
    formData
  );
};

const getTCReportFile = (
  trainingCenterId: number,
  reportId: number,
  attachmentId: number
) =>
  axios
    .get<any>(
      `/api/training-center/${trainingCenterId}/reports/${reportId}/attachment/${attachmentId}`,
      {
        responseType: "blob"
      }
    )
    .then((res) => {
      return new Blob([res.data]);
    });

const getAdminTCReportFile = (reportId: number, attachmentId: number) =>
  axios.get<any>(
    `/api/training-center/reports/${reportId}/attachment/${attachmentId}`,
    {
      responseType: "blob"
    }
  );

const getAdminTrainingCenterReports = (
  reportType: number,
  page: number,
  filterData: {}
) =>
  axios.get<PaginationItem<TrainingCenterReport>>(
    `/api/training-center/reports`,
    { params: { type: reportType, page, ...filterData } }
  );

const deleteTCReport = (id: number) =>
  axios.delete<void>(`/api/training-center/reports/${id}`);

const getContractorTcList = (contractorId: number) =>
  axios.get<TrainingCenter[]>(
    `/api/contractor/${contractorId}/training-center`
  );

const getTcDictionary = () =>
  axios.get<{ name: string; id: number }[]>(
    `/api/entities-for-dropdowns/training-centers`
  );

export const trainingCenterApi = {
  getAllTrainingCenters,
  getTrainingCenter,
  registerTrainingCenter,
  editTrainingCenter,
  deleteTrainingCenter,
  getTrainingCenterMembers,
  getTrainingCenterMember,
  inviteTrainingCenterMember,
  editTrainingCenterMember,
  editTrainingCenterMemberPermission,
  activateTrainingCenterMember,
  resendTrainingCenterMemberInvite,
  deleteTrainingCenterMemberInvite,
  deleteTrainingCenterMember,
  getTrainingCenterInstructors,
  createTrainingCenterInstructor,
  editTrainingCenterInstructor,
  deleteTrainingCenterInstructor,
  getTrainingCenterSpecialities,
  getContractorsWithTickets,
  getTrainingCenterReports,
  getTrainingCenterReport,
  getAdminTrainingCenterReport,
  getContractorTcList,
  createTrainingCenterReports,
  getTCReportFile,
  getAdminTCReportFile,
  getAdminTrainingCenterReports,
  deleteTCReport,
  getTcDictionary,
  updateTrainingCenterReport
};
