import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import FormItem from "antd/lib/form/FormItem";
import { useFormik } from "formik";
import moment from "moment";

import { Button, Col, DatePicker, Row, Space } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { Contractor } from "@models/contractor";
import { Country } from "@models/countryCity";
import { Discipline } from "@models/discipline";
import { EventEmployeeGradeType } from "@models/event";
import { TrainingCenter } from "@models/trainingCenter";

export const GRADE_OPTIONS = [
  {
    label: `events.grade.1`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `events.grade.2`,
    value: EventEmployeeGradeType.PASSED
  },
  {
    label: `events.grade.3`,
    value: EventEmployeeGradeType.BASIC
  },
  {
    label: `events.grade.4`,
    value: EventEmployeeGradeType.INTERMEDIATE
  },
  {
    label: `events.grade.5`,
    value: EventEmployeeGradeType.ADVANCED
  },
  {
    label: `events.grade.6`,
    value: EventEmployeeGradeType.COMPETENT
  },
  {
    label: `events.grade.7`,
    value: EventEmployeeGradeType.PROFICIENT
  },
  {
    label: `events.grade.8`,
    value: EventEmployeeGradeType.HELPER
  }
];

interface FormValues {
  dateStart?: string;
  dateEnd?: string;
  specialityIds?: number[];
  trainingCenterIds?: number[];
  contractorsIds?: number[];
  grade?: EventEmployeeGradeType;
  countryId?: number;
}

interface Props {
  initialFilter: FormValues;
  disciplines: Discipline[];
  trainingCenters: TrainingCenter[];
  contractors: Contractor[];
  countries: Country[];
  isLoading: boolean;
  onSubmit: (values: FormValues) => void;
}

export const DuplicatedHeadcountFilterForm: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();

  const disciplinesSelectOptions = useMemo(
    () =>
      props.disciplines.map((d) => ({
        value: Number(d.id),
        label: i18n.language === "en" ? d.nameEn : d.nameRu
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.disciplines]
  );

  const trainingCentersSelectOptions = useMemo(
    () =>
      props.trainingCenters.map((t) => ({
        value: Number(t.id),
        label: t.name
      })),
    [props.trainingCenters]
  );

  const contractorsSelectOptions = useMemo(
    () =>
      props.contractors.map((c) => ({
        value: Number(c.id),
        label: c.name
      })),
    [props.contractors]
  );

  const formik = useFormik<FormValues>({
    initialValues: {
      ...props.initialFilter,
      specialityIds: props.initialFilter.specialityIds?.map((s) => Number(s)),
      trainingCenterIds: props.initialFilter.trainingCenterIds?.map((t) =>
        Number(t)
      ),
      contractorsIds: props.initialFilter.contractorsIds?.map((c) => Number(c)),
      grade: props.initialFilter.grade && Number(props.initialFilter.grade),
      countryId:
        props.initialFilter.countryId && Number(props.initialFilter.countryId)
    },
    onSubmit: (values) => {
      props.onSubmit(values);
    }
  });

  return (
    <>
      <Row gutter={8} wrap>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("dateFrom")}
            validateStatus={formik.errors.dateStart && "error"}
            help={formik.errors.dateStart}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(_, value) => formik.setFieldValue("dateStart", value)}
              disabledDate={(date) =>
                date && date >= moment(formik.values.dateEnd, "YYYY-MM-DD")
              }
              value={
                formik.values.dateStart
                  ? moment(formik.values.dateStart, "YYYY-MM-DD")
                  : undefined
              }
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("dateTo")}
            validateStatus={formik.errors.dateEnd && "error"}
            help={formik.errors.dateEnd}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(_, value) => formik.setFieldValue("dateEnd", value)}
              disabledDate={(date) =>
                date && date <= moment(formik.values.dateStart, "YYYY-MM-DD")
              }
              value={
                formik.values.dateEnd
                  ? moment(formik.values.dateEnd, "YYYY-MM-DD")
                  : undefined
              }
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.disciplines")}
            validateStatus={formik.errors.specialityIds && "error"}
            help={formik.errors.specialityIds}
          >
            <SearchSelect
              value={formik.values.specialityIds}
              options={disciplinesSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("specialityIds", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.trainingCenters")}
            validateStatus={formik.errors.trainingCenterIds && "error"}
            help={formik.errors.trainingCenterIds}
          >
            <SearchSelect
              value={formik.values.trainingCenterIds}
              options={trainingCentersSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("trainingCenterIds", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.contractors")}
            validateStatus={formik.errors.contractorsIds && "error"}
            help={formik.errors.contractorsIds}
          >
            <SearchSelect
              value={formik.values.contractorsIds}
              options={contractorsSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("contractorsIds", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.overallGrade")}
            validateStatus={formik.errors.grade && "error"}
            help={formik.errors.grade}
          >
            <SearchSelect
              value={formik.values.grade}
              options={GRADE_OPTIONS.map((tg) => ({
                ...tg,
                label: t(tg.label)
              }))}
              onChange={(value: number) => formik.setFieldValue("grade", value)}
              maxTagCount={1}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("employees.citizenship")}
            validateStatus={formik.errors.countryId && "error"}
            help={formik.errors.countryId}
          >
            <SearchSelect
              options={props.countries?.map((c) => ({
                value: c.id,
                label: i18n.language === "en" ? c.nameEn : c.nameRu
              }))}
              value={formik.values.countryId}
              onChange={(v: number) => formik.setFieldValue("countryId", v)}
            />
          </FormItem>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            <Button onClick={() => formik.setValues({})}>
              {t("events.filters.clearFilters")}
            </Button>
            <Button
              type="primary"
              loading={props.isLoading}
              onClick={() => formik.submitForm()}
            >
              {t("filter")}
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
