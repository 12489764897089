import React from "react";

import { PrescreeningEventGradeTable } from "@components/common/events/PrescreeningEventGradeTable";

import {
  EventEmployeeDetails,
  NonCommissioningEventGrade
} from "@models/event";

import { getEmployeesByType } from "@utils/eventAttendanceHelper";

import { PrescreeningEventGradeForm } from "./PrescreeningEventGradeForm";

interface Props {
  editable?: boolean;

  errors?: { [key: string]: string };
  isLoading?: boolean;
  eventEmployees?: EventEmployeeDetails[];
  onSubmit?: (values: NonCommissioningEventGrade[]) => void;
}

export const PrescreeningEventGrades = (props: Props) => {
  const { availableEmployees: ungradedEmployees } = getEmployeesByType(
    props.eventEmployees || []
  );

  return props.editable ? (
    <PrescreeningEventGradeForm
      isLoading={props.isLoading}
      errors={props.errors}
      onSubmit={props.onSubmit}
      eventEmployees={ungradedEmployees}
    />
  ) : (
    <PrescreeningEventGradeTable eventEmployees={props.eventEmployees} />
  );
};
