import axios from "axios";

import {
  AddContractPayload,
  EditContractPayload,
  Contract,
  StaticDataMap
} from "@models/contract";

const getProjects = () =>
  axios.get<StaticDataMap[]>("/api/static-data/projects");

const getKcCategories = () =>
  axios.get<StaticDataMap[]>("/api/static-data/kc/categories");

const getAllContracts = () => axios.get<Contract[]>("/api/contract");

const getContractorContracts = (contractorId: number) =>
  axios.get<Contract[]>(`/api/contract/contractor/${contractorId}`);

const addContract = (payload: AddContractPayload) =>
  axios.post(`/api/contract`, payload);

const editContract = ({ contractId, ...payload }: EditContractPayload) =>
  axios.put(`/api/contract/${contractId}`, payload);

const deleteContract = (contractId: number) =>
  axios.delete(`/api/contract/${contractId}`);

export const contractApi = {
  getProjects,
  getKcCategories,
  getAllContracts,
  getContractorContracts,
  addContract,
  editContract,
  deleteContract
};
