import { useRef } from "react";

import { jsPDF } from "jspdf";
import "svg2pdf.js";
import html2canvas from "html2canvas";

export const usePrintRef = (refId: string, reportName: string) => {
  const pageRef = useRef<HTMLDivElement>(
    document.getElementById(refId) as HTMLDivElement
  );

  const handleDownloadReport = () => {
    html2canvas(pageRef.current, {
      allowTaint: true
    }).then((canvas) => {
      const titleHeight = 100;
      const orientation = canvas.width > canvas.height ? "l" : "p";
      const pdf = new jsPDF(orientation, "pt", [
        canvas.width,
        canvas.height + titleHeight
      ]);

      const titleCanvas = document.createElement("canvas");
      titleCanvas.width = canvas.width;
      titleCanvas.height = titleHeight;
      const titleCanvasCtx = titleCanvas.getContext("2d");
      if (titleCanvasCtx) {
        titleCanvasCtx.font = 'bold 48px "Fira Sans"';
        titleCanvasCtx.fillText(reportName, 15, 60);
        pdf.addImage(
          titleCanvas,
          "PNG",
          0,
          0,
          titleCanvas.width,
          titleCanvas.height
        );
      }

      pdf.addImage(canvas, "PNG", 0, titleHeight, canvas.width, canvas.height);
      pdf.save(reportName + "_report.pdf");
    });
  };

  return { pageRef, handleDownloadReport };
};
