import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormikProps } from "formik";

import { Input, Select } from "antd";

import {
  OnlineTrainingReportFormData,
  OnlineTrainingReportUser,
  Rev
} from "@models/onlineTrainingReports";

interface Props {
  name: string;
  index: number;
  lineEdited: number | null;
  formik: FormikProps<OnlineTrainingReportFormData>;
  onBlur: (name: string, value: string | null) => void;
}

const { Option } = Select;

const OnlineTrainingFormFieldGenerator: React.FC<Props> = ({
  name,
  index,
  lineEdited,
  formik,
  onBlur
}) => {
  const [t] = useTranslation();
  const selectedLine = lineEdited === index;
  const [editedInput, setEditedInput] = useState<string | null>(null);

  const formikValueSelector = useMemo(
    () =>
      formik.values.onlineTrainingReportUsers[index][
        name as keyof OnlineTrainingReportUser
      ],
    [formik.values.onlineTrainingReportUsers, index, name]
  );

  const isString = (val: unknown): val is string => typeof val === "string";

  const inputValue = () => {
    const formikValueSelector =
      formik.values.onlineTrainingReportUsers[index][
        name as keyof OnlineTrainingReportUser
      ];

    if (editedInput) {
      return editedInput;
    }
    if (editedInput === "") {
      return null;
    }
    return isString(formikValueSelector) ? formikValueSelector : null;
  };

  switch (name) {
    case "firstName":
    case "lastName":
    case "iin":
    case "employeeNumber":
    case "title":
    case "supervisor":
    case "department":
      return selectedLine ? (
        <Input
          name={`onlineTrainingReportUsers[${index}].${name}`}
          value={
            editedInput === null
              ? (formikValueSelector as keyof OnlineTrainingReportUser)
              : editedInput
          }
          onChange={(e) => setEditedInput(e.target.value)}
          onBlur={() => onBlur(name, inputValue())}
        />
      ) : (
        <>{formikValueSelector}</>
      );
    case "rev":
      return selectedLine ? (
        <Select
          value={formikValueSelector || null}
          onChange={(value: number | string) =>
            formik.setFieldValue(
              `onlineTrainingReportUsers[${index}].${name}`,
              Number(value)
            )
          }
        >
          <Option value={Rev.LOCAL}>{t("local")}</Option>
          <Option value={Rev.EXPAT}>{t("expat")}</Option>
        </Select>
      ) : (
        <>
          {formikValueSelector === Rev.LOCAL
            ? t("local")
            : formikValueSelector === Rev.EXPAT
            ? t("expat")
            : "-"}
        </>
      );
    default:
      return <>{formikValueSelector}</>;
  }
};

export default OnlineTrainingFormFieldGenerator;
