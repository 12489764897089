import picture30 from "@assets/images/user-guide/1.30.png";
import picture31 from "@assets/images/user-guide/1.31.png";

export const employeePassport = [
  {
    title: "Craft employees",
    html: `<ol>
    <li>
        <p>In order to see the list of Craft employees press on the Employees tab on Panel menu and select Craft.</p>
        <p>List of Craft-employees contains the following information:</p>
        <p>Picture 1.30</p>
        <p><img src=${picture30} width="624" height="368"></p>
        <ol>
            <li>
                <p>Filter by IIN, you can enter the first 3 numbers</p>
            </li>
            <li>
                <p>Filter by Discipline</p>
            </li>
            <li>
                <p>Filter by First name, you can enter the first 3 letters</p>
            </li>
            <li>
                <p>Filter by Last name, you can enter first 3 letters&nbsp;</p>
            </li>
            <li>
                <p>Filter by Competency level</p>
            </li>
            <li>
                <p>Download log of non-IIN button</p>
            </li>
            <li>
                <p>Search button</p>
            </li>
        </ol><br>
        <p>Use filters to search Craft-worker and press on First Name Last Name to see Details.</p>
        <p><br></p>
    </li>
</ol>`
  },
  {
    title: "Employee passport",
    html: `<p dir="ltr">
    Employee Passport page contains two main blocks:
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Craft-workers information block
        </p>
    </li>
</ul>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Personal information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Working information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Facility accesses information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Back to the list button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 1.31
</p>
<p dir="ltr">
    <img
        src=${picture31}
        width="624"
        height="365"
    />
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Training History block
        </p>
    </li>
</ul>
<p dir="ltr">
    Check training history of craft-workers, download certificate, if
    necessary.
</p>
`
  }
];
