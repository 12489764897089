import picture26 from "@assets/images/user-guide/2.26.png";

export const members = [
  {
    title: "What’s on Members page:",
    html: `<p dir="ltr">
    Once you press Members tab on Panel menu you will be redirected to Members
    page with the following information:
</p>
<p dir="ltr">
    Picture 2.26
</p>
<p dir="ltr">
    <img
        src=${picture26}
        width="624"
        height="383"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Active members block with total number of active members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Invite member button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Invited members block with total number of invited members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Delete invited member button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Instructors block with total number of invited members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Add instructor button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Edit and Delete instructor button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: System allows to edit only your colleagues information, in case if
    you have appropriate permission.
</p>
`
  },
  {
    title: "How to invite a member?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Invite member button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out fields and press on Invite button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: Invited member will be shown in Invited members block, until he/she
    does not finish the registration process.
</p>
`
  },
  {
    title: "How to edit member’s data?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Edit button on Active members list
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Edit fields and press on Save button
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to delete an active member?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Delete button on Active members list and press on Yes
            button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: Deleted member won't be able to log in to the system.
</p>
`
  },
  {
    title: "Why can I delete an invited member?",
    html: `<p dir="ltr">
    Once you invite your colleague, the system sends an email notification to
    finish the registration process. Invitation link in email expires in 24
    hours, so in case your colleague missed the expiration date you can delete
    him/her and invite again.
</p>
`
  },
  {
    title: "How to add an instructor?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Add instructor button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out fields and press Save button
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to edit instructor's data?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Members
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Edit button on Instructors list
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Edit fields and press Save button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: You can edit Instructor’s First name Last name and Disciplines.
</p>
`
  },
  {
    title: "How to delete an instructor?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Members
        </p>
    </li>
    <li dir="ltr">
    <p dir="ltr">
    Press Delete button on Instructors list and click Yes
    </p>
</li>
</ol>
`
  }
];
