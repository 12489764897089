import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import FormItem from "antd/lib/form/FormItem";

import { Button, Col, Row, Select } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { Discipline } from "@models/discipline";
import { CraftProfilesReportFilterForm } from "@models/craftProfiles";

import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

interface Props {
  initialFilter?: CraftProfilesReportFilterForm;
  disciplines: Discipline[];
  trainingCenters: { id: number; name: string }[];
  contractors: { id: number; name: string }[];
  isLoading: boolean;
  onSubmit: (values: CraftProfilesReportFilterForm) => void;
}

export const CraftProfileReportFilterForm: React.FC<Props> = ({
  initialFilter,
  disciplines,
  trainingCenters,
  contractors,
  isLoading,
  onSubmit
}) => {
  const { i18n, t } = useTranslation();

  const disciplinesSelectOptions = useMemo(
    () =>
      disciplines?.map(({ id, nameEn, nameRu }) => ({
        value: Number(id),
        label: i18n.language === "en" ? nameEn : nameRu
      })),
    [disciplines, i18n.language]
  );

  const trainingCentersSelectOptions = useMemo(
    () =>
      trainingCenters?.map(({ id, name }) => ({
        value: Number(id),
        label: name
      })),
    [trainingCenters]
  );

  const contractorsSelectOptions = useMemo(
    () =>
      contractors?.map(({ id, name }) => ({
        value: Number(id),
        label: name
      })),
    [contractors]
  );

  const formik = useFormik<CraftProfilesReportFilterForm>({
    initialValues: {
      hiringContractorIds: initialFilter?.hiringContractorIds?.map((value) =>
        Number(value)
      ),
      trainingCenterIds: initialFilter?.trainingCenterIds?.map((value) =>
        Number(value)
      ),
      csrContractorIds: initialFilter?.csrContractorIds?.map((value) =>
        Number(value)
      ),
      specialityIds: initialFilter?.specialityIds?.map((value) =>
        Number(value)
      ),
      isEmployed:
        initialFilter?.isEmployed === undefined ||
        initialFilter?.isEmployed === ""
          ? null
          : !!initialFilter?.isEmployed,
      csrPhases: initialFilter?.csrPhases?.map((value) => Number(value))
    },
    onSubmit: (values) => onSubmit(values)
  });

  return (
    <Row gutter={8} wrap>
      <Col span={12}>
        <FormItem
          label={t("craftProfiles.filters.csrPhases")}
          labelCol={{ span: 24 }}
        >
          <Select
            mode="multiple"
            value={formik.values.csrPhases}
            allowClear
            maxTagCount={1}
            showArrow
            onChange={(v) => formik.setFieldValue("csrPhases", v)}
          >
            {CSR_PHASE_OPTIONS.map((cp) => (
              <Select.Option value={cp.value} key={cp.value}>
                {t(cp.label, { no: cp.value })}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          label={t("craftProfiles.filters.status")}
          labelCol={{ span: 24 }}
        >
          <Select
            value={formik.values.isEmployed}
            showArrow
            allowClear
            onChange={(v) => formik.setFieldValue("isEmployed", v)}
          >
            <Select.Option value={true}>
              {t("craftProfiles.filters.employed")}
            </Select.Option>
            <Select.Option value={false}>
              {t("craftProfiles.filters.unemployed")}
            </Select.Option>
          </Select>
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          label={t("craftProfiles.filters.trainingCenters")}
          labelCol={{ span: 24 }}
        >
          <SearchSelect
            value={formik.values.trainingCenterIds}
            options={trainingCentersSelectOptions}
            onChange={(value: number) =>
              formik.setFieldValue("trainingCenterIds", value)
            }
            maxTagCount={1}
            mode="multiple"
          />
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          label={t("craftProfiles.filters.disciplines")}
          labelCol={{ span: 24 }}
        >
          <SearchSelect
            value={formik.values.specialityIds}
            options={disciplinesSelectOptions}
            onChange={(value: number) =>
              formik.setFieldValue("specialityIds", value)
            }
            maxTagCount={1}
            mode="multiple"
          />
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          label={t("craftProfiles.filters.csrContractors")}
          labelCol={{ span: 24 }}
        >
          <SearchSelect
            value={formik.values.csrContractorIds}
            options={contractorsSelectOptions}
            onChange={(value: number) =>
              formik.setFieldValue("csrContractorIds", value)
            }
            maxTagCount={1}
            mode="multiple"
          />
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          label={t("craftProfiles.filters.contractors")}
          labelCol={{ span: 24 }}
        >
          <SearchSelect
            value={formik.values.hiringContractorIds}
            options={contractorsSelectOptions}
            onChange={(value: number) =>
              formik.setFieldValue("hiringContractorIds", value)
            }
            maxTagCount={1}
            mode="multiple"
          />
        </FormItem>
      </Col>

      <Col span={24} style={{ textAlign: "right", marginTop: "40px" }}>
        <Button
          onClick={() => formik.setValues({})}
          type="ghost"
          style={{ minWidth: 150, marginRight: "8px" }}
        >
          {t("craftProfiles.filters.clearFilters")}
        </Button>

        <Button
          onClick={() => formik.submitForm()}
          htmlType="submit"
          type="primary"
          loading={isLoading}
          style={{ minWidth: 150 }}
        >
          {t("search")}
        </Button>
      </Col>
    </Row>
  );
};
