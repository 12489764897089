import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Steps } from "antd";

import { Country } from "@models/countryCity";

import { EmployeeFacilityAccessForm } from "./EmployeeFacilityAccessForm";
import { EmployeePersonalInfoForm } from "./EmployeePersonalInfoForm";
import { EmployeeWorkingInfoForm } from "./EmployeeWorkingInfoForm";

const Step = Steps.Step;

interface Props {
  countries?: Country[];
  contractors?: { id: number; name: string }[];
  contracts?: { id: number; contractNumber: string }[];

  isLoading?: boolean;

  onCancel?: () => void;
  onSubmit?: (values: any) => void;
}

export const NewEmployeeFormContainer: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  const [personalInfo, setPersonalInfo] = useState<any>();
  const [workingInfo, setWorkingInfo] = useState<any>();
  const [, setFacilityAccess] = useState<any>();

  return (
    <>
      <Steps current={currentStep}>
        <Step title={t("employees.personalInfo")}></Step>

        <Step title={t("employees.workingInfo")}></Step>

        <Step title={t("employees.facilityAccesses")}></Step>
      </Steps>

      <br />

      {currentStep === 0 && (
        <>
          <EmployeePersonalInfoForm
            employee={personalInfo}
            countries={props.countries}
            submitText={t("next")}
            onCancel={() => props.onCancel && props.onCancel()}
            onSubmit={(values) => {
              setPersonalInfo(values);
              setCurrentStep(1);
            }}
          />
        </>
      )}

      {currentStep === 1 && (
        <>
          <EmployeeWorkingInfoForm
            contractors={props.contractors}
            contracts={props.contracts}
            cancelText={t("back")}
            submitText={t("next")}
            onCancel={() => setCurrentStep(0)}
            onSubmit={(values) => {
              setWorkingInfo(values);
              setCurrentStep(2);
            }}
          />
        </>
      )}

      {currentStep === 2 && (
        <>
          <EmployeeFacilityAccessForm
            cancelText={t("back")}
            onCancel={() => setCurrentStep(1)}
            onSubmit={(values) => {
              setFacilityAccess(values);

              if (props.onSubmit) {
                // merge data and send
                props.onSubmit({
                  ...personalInfo,
                  ...workingInfo,
                  ...values
                });
              }
            }}
          />
        </>
      )}
    </>
  );
};
