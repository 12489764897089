import { UseMutationResult } from "react-query";
import { useTranslation } from "react-i18next";

import { differenceInHours, parseISO } from "date-fns";
import { AxiosError, AxiosResponse } from "axios";

import { Button, Popconfirm, Space, Tooltip } from "antd";
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";

import { Member } from "@models/member";

type UseMutationAxios = UseMutationResult<
  AxiosResponse<any>,
  AxiosError<any>,
  number
>;

interface InactiveMemberActionsProps {
  member: Member;
  deleteMemberMutation: UseMutationAxios;
  activateMemberMutation: UseMutationAxios;
}

const InactiveMemberActions: React.FC<InactiveMemberActionsProps> = (props) => {
  const [t] = useTranslation();
  const inviteDate = parseISO(props.member.invitedAt);
  const hourDiff = differenceInHours(Date.now(), inviteDate);
  const isInviteExpired = hourDiff >= 24;

  return (
    <Space size="middle">
      <Tooltip
        title={
          isInviteExpired
            ? t("members.activate")
            : t("members.previousInviteNotExpired")
        }
      >
        <Button
          type="primary"
          shape="circle"
          icon={<UserAddOutlined />}
          disabled={!isInviteExpired || props.activateMemberMutation.isLoading}
          loading={
            props.activateMemberMutation.isLoading &&
            props.activateMemberMutation.variables === props.member.userId
          }
          onClick={() =>
            props.activateMemberMutation.mutate(props.member.userId)
          }
        />
      </Tooltip>
      <Popconfirm
        title={t("members.confirmDelete", {
          name: props.member.firstName + " " + props.member.lastName
        })}
        cancelText={t("cancel")}
        okText={t("yes")}
        disabled={props.deleteMemberMutation.isLoading}
        onConfirm={() => props.deleteMemberMutation.mutate(props.member.userId)}
      >
        <Button
          title={t("members.delete")}
          danger
          shape="circle"
          disabled={props.deleteMemberMutation.isLoading}
          loading={
            props.deleteMemberMutation.isLoading &&
            props.deleteMemberMutation.variables === props.member.userId
          }
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </Space>
  );
};

export default InactiveMemberActions;
