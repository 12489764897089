import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ColumnsType } from "antd/lib/table";

import { Button, Table } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import { ContractorEmployees } from "@models/employees";

interface Props {
  foundProfile: ContractorEmployees;
  setSelectedProfile: (profile: ContractorEmployees | null) => void;
  isLoading?: boolean;
}

const SelectProfileByIinTable: React.FC<Props> = ({
  isLoading,
  setSelectedProfile,
  foundProfile
}) => {
  const [t] = useTranslation();
  const [isProfileSelected, setProfileSelected] = useState<boolean>(false);
  const columns: ColumnsType<ContractorEmployees> = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "number",
        key: "id",
        render: (_, __, index: number) => <>{index + 1}</>
      },
      {
        title: t("firstName"),
        dataIndex: "firstName",
        key: "firstName"
      },
      {
        title: t("lastName"),
        dataIndex: "lastName",
        key: "lastName"
      },
      {
        title: t("actions"),
        dataIndex: "actions",
        key: "actions",
        width: "3%",
        align: "center",
        render: (_, profileData) =>
          !isProfileSelected ? (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                setProfileSelected(true);
                setSelectedProfile(profileData);
              }}
            >
              {t("select")}
            </Button>
          ) : (
            <Button
              icon={<MinusOutlined />}
              danger
              onClick={() => {
                setProfileSelected(false);
                setSelectedProfile(null);
              }}
            >
              {t("cancel")}
            </Button>
          )
      }
    ],
    [isProfileSelected, setSelectedProfile, t]
  );

  return (
    <Table
      columns={columns}
      dataSource={[foundProfile]}
      loading={isLoading}
      pagination={false}
    />
  );
};

export default SelectProfileByIinTable;
