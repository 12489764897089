import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { TablePaginationConfig } from "antd/lib/table";

import { Table, Tag, Space, Typography } from "antd";

import { Subcontractor } from "@models/subcontractor";
import { Contract } from "@models/contract";

const Column = Table.Column;

interface Props {
  title?: React.ReactNode;
  isLoading?: boolean;
  subcontractors?: Subcontractor[];
  pagination?: TablePaginationConfig;
  itemLinkTo?: (sub: Subcontractor) => string;
  renderActions?: (member: any) => React.ReactNode;
}

export const SubcontractorTable = (props: Props) => {
  const [t, i18n] = useTranslation();

  return (
    <Table
      title={
        props.title
          ? () => (
              <Space align="center">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {props.title}
                </Typography.Title>

                <Typography.Text type="secondary">
                  {t("totalCount", {
                    total: props.subcontractors
                      ? props.subcontractors.length
                      : 0
                  })}
                </Typography.Text>
              </Space>
            )
          : undefined
      }
      locale={{ emptyText: t("noData") }}
      loading={props.isLoading}
      tableLayout="auto"
      bordered
      pagination={{
        position: ["bottomCenter"],
        ...props.pagination
      }}
      rowKey="id"
      dataSource={props.subcontractors}
    >
      <Column
        title={t("subcontractors.name")}
        dataIndex="name"
        render={(name: string, sub: Subcontractor) =>
          props.itemLinkTo ? (
            <Link to={props.itemLinkTo(sub)}>{name}</Link>
          ) : (
            name
          )
        }
      />

      <Column
        title={t("subcontractors.workPhoneNumber")}
        dataIndex="phone"
        render={(p) => p || "-"}
      />

      <Column
        title={t("subcontractors.city")}
        dataIndex="city"
        render={(c) => (i18n.language === "en" ? c.nameEn : c.nameRu)}
      />

      <Column title={t("subcontractors.address")} dataIndex="address" />

      <Column
        title={t("subcontractors.contract")}
        dataIndex="contracts"
        render={(scs) =>
          scs.map((c: Contract) => <Tag key={c.id}>{c.contractNumber}</Tag>)
        }
      />

      {props.renderActions && (
        <Column
          title={t("actions")}
          render={(sub: Subcontractor) => {
            return props.renderActions && props.renderActions(sub);
          }}
        />
      )}
    </Table>
  );
};
