import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Editor } from "@tinymce/tinymce-react";

import { EDITOR_API_KEY } from "@constants/tinyEditor";

type Props = {
  value: string;
  handleChange: (value: string) => void;
};

const TinyMceTextField: FC<Props> = ({ value, handleChange }) => {
  const { i18n } = useTranslation();

  return (
    <Editor
      apiKey={EDITOR_API_KEY}
      value={value}
      onEditorChange={handleChange}
      init={{ branding: false, height: 250, language: i18n.language }}
    />
  );
};

export default TinyMceTextField;
