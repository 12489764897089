import axios from "axios";

import { AppNotification } from "@models/notification";

const getAdminNotifications = () =>
  axios.get<AppNotification[]>("/api/admin/notification");

export const notificationApi = {
  getAdminNotifications
};
