import {
  CopyOutlined,
  FileTextOutlined,
  FlagOutlined
} from "@ant-design/icons";

import { general } from "@utils/userguides/tco/general";
import { reports } from "@utils/userguides/tco/reports";
import { events } from "@utils/userguides/tco/events";
import { members } from "@utils/userguides/tco/members";
import { employeePassport } from "@utils/userguides/tco/employeePassport";
import { certificateSearch } from "@utils/userguides/tco/certificateSearch";
import { profiles } from "@utils/userguides/tco/profiles";
import { trainingCenters } from "@utils/userguides/tco/trainingCenters";
import { migrations } from "@utils/userguides/tco/migrations";
import { contractors } from "@utils/userguides/tco/contractors";
import { tcReports } from "@utils/userguides/tco/tcReports";
import { disciplice } from "@utils/userguides/tco/disciplice";

export const tcoUserguides = [
  {
    title: "general",
    data: general,
    icon: <FlagOutlined />
  },
  {
    title: "reports",
    data: reports,
    icon: <FileTextOutlined />
  },
  {
    title: "members",
    data: members,
    icon: <FileTextOutlined />
  },
  {
    title: "events",
    data: events,
    icon: <FileTextOutlined />
  },
  {
    title: "trainingCenters",
    data: trainingCenters,
    icon: <FileTextOutlined />
  },
  {
    title: "contractors",
    data: contractors,
    icon: <FileTextOutlined />
  },
  {
    title: "employeePassport",
    data: employeePassport,
    icon: <FileTextOutlined />
  },
  {
    title: "certificatesSearch",
    data: certificateSearch,
    icon: <FileTextOutlined />
  },
  {
    title: "TCReports",
    data: tcReports,
    icon: <FileTextOutlined />
  },
  {
    title: "disciplines",
    data: disciplice,
    icon: <FileTextOutlined />
  },
  {
    title: "migrations",
    data: migrations,
    icon: <FileTextOutlined />
  },
  {
    title: "profiles",
    data: profiles,
    icon: <FileTextOutlined />
  }
];
