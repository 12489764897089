import { useContext, useEffect } from "react";
import { UseMutateFunction, useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import moment, { Moment } from "moment";

import { InboxOutlined, PlusSquareOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Spin,
  Steps,
  Typography,
  Upload
} from "antd";
import EditableEmployeeTable from "@components/common/reports/EditableEmployeeTable";

import {
  OnlineTrainingReportFormData,
  OnlineTrainingReportUser
} from "@models/onlineTrainingReports";

import { onlineTrainingReportsApi } from "@api/onlineTrainingReportsApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { UserContext } from "@contexts/UserContext";
import { doesFileFitsSize } from "@utils/doesFileFitsSize";

interface FormProps {
  name: string;
  completedAt: Moment;
  onlineTrainingReportUsers: Partial<OnlineTrainingReportUser>[];
}

interface Props {
  currentStep: 0 | 1;
  setCurrentStep: (step: 0 | 1) => void;
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  createReportMutation: UseMutateFunction<unknown, unknown, FormProps>;
  errors: { [key: string]: string };
  isLoading?: boolean;
}

const { Step } = Steps;

const UploadEmployeeBatchModal: React.FC<Props> = ({
  currentStep,
  setCurrentStep,
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  createReportMutation,
  errors,
  isLoading
}) => {
  const [t] = useTranslation();
  const { userEntityId: contractorId } = useContext(UserContext);

  const downloadOnlineTrainingTemplate = useQuery(
    "get-online-training-template",
    () => onlineTrainingReportsApi.getOnlineTrainingTemplate(),
    {
      enabled: false
    }
  );

  const uploadOnlineTrainingTable = useValidatedMutation({
    mutationFunction: (payload: Blob) =>
      onlineTrainingReportsApi
        .uploadOnlineTrainingTable(payload, contractorId)
        .then((res) => res.data),
    onSuccess: () => {
      notification.success({ message: t("employees.fileUploaded") });
      setCurrentStep(1);
    },
    onError: () => notification.error({ message: t("error.errorOccured") })
  });

  const formik = useFormik<OnlineTrainingReportFormData>({
    initialValues: {
      name: "",
      completedAt: moment(new Date()),
      onlineTrainingReportUsers: []
    },
    onSubmit: (values) => createReportMutation(values)
  });

  useEffect(() => {
    formik.setFieldValue(
      "onlineTrainingReportUsers",
      uploadOnlineTrainingTable.data
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadOnlineTrainingTable.data]);

  useEffect(() => {
    formik.setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("reports.createOnlineTrainingReport")}
        footer={null}
        width="90%"
        onCancel={handleCloseModal}
      >
        <Steps
          current={currentStep}
          size="small"
          style={{ marginBottom: "24px" }}
        >
          <Step title={t("employees.uploadFile")}></Step>
          <Step title={t("employees.editData")}></Step>
        </Steps>
        <Form
          layout="vertical"
          onSubmitCapture={() => formik.submitForm()}
          initialValues={formik.initialValues}
        >
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <Form.Item
                label={t("name")}
                validateStatus={formik.errors.name && "error"}
                help={formik.errors.name}
              >
                <Input
                  name="name"
                  placeholder={t("name")}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={t("certificates.completionDate")}
                validateStatus={formik.errors.completedAt && "error"}
                help={formik.errors.completedAt}
              >
                <DatePicker
                  onChange={(value) =>
                    formik.setFieldValue("completedAt", value)
                  }
                  value={
                    formik.values.completedAt
                      ? moment(formik.values.completedAt, "YYYY-MM-DD")
                      : moment(new Date())
                  }
                  style={{ width: "100%" }}
                  format="DD MMM YYYY"
                  placeholder={t("events.filters.selectDate")}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>
          {currentStep === 0 ? (
            <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
              <Col md={24}>
                <Spin spinning={uploadOnlineTrainingTable.isLoading}>
                  <Upload.Dragger
                    accept=".xlsx,.xls"
                    className="py-30"
                    beforeUpload={(file) => {
                      if (doesFileFitsSize(file)) {
                        uploadOnlineTrainingTable.mutate(file);
                        return false;
                      }
                      notification.error({
                        message: t("fileSizeLimit", { size: 10 })
                      });
                      return false;
                    }}
                    fileList={[]}
                  >
                    <InboxOutlined />
                    <p>{t("employees.chooseOrDragFile")}</p>
                    <p>
                      <Typography.Text type="secondary">
                        {t("reports.uploadParticipants")}
                      </Typography.Text>
                    </p>
                    <p>
                      <Typography.Text type="secondary">
                        {t("fileSizeLimit", { size: 10 })}
                      </Typography.Text>
                    </p>
                  </Upload.Dragger>
                </Spin>
              </Col>
            </Row>
          ) : (
            <EditableEmployeeTable formik={formik} isLoading={isLoading} />
          )}
          <Row justify="space-between">
            <Col>
              <Button
                onClick={() => {
                  setCurrentStep(0);
                  handleCloseModal();
                }}
              >
                {t("cancel")}
              </Button>
            </Col>
            <Col>
              {currentStep === 0 ? (
                <Button
                  type="dashed"
                  onClick={() => downloadOnlineTrainingTemplate.refetch()}
                  loading={
                    uploadOnlineTrainingTable.isLoading ||
                    downloadOnlineTrainingTemplate.isLoading
                  }
                >
                  {t("employees.downloadTemplate")}
                </Button>
              ) : (
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {t("save")}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>

      <Button
        type="primary"
        icon={<PlusSquareOutlined />}
        onClick={() => {
          formik.resetForm();
          handleOpenModal();
        }}
      >
        {t("reports.createReport")}
      </Button>
    </>
  );
};

export default UploadEmployeeBatchModal;
