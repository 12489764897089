import picture5 from "@assets/images/user-guide/2.5.png";
import picture6 from "@assets/images/user-guide/2.6.png";
import picture7 from "@assets/images/user-guide/2.7.png";
import picture8 from "@assets/images/user-guide/2.8.png";
import picture9 from "@assets/images/user-guide/2.9.png";
import picture10 from "@assets/images/user-guide/2.10.png";
import picture11 from "@assets/images/user-guide/2.11.png";
import picture12 from "@assets/images/user-guide/2.12.png";
import picture13 from "@assets/images/user-guide/2.13.png";
import picture14 from "@assets/images/user-guide/2.14.png";
import picture15 from "@assets/images/user-guide/2.15.png";
import picture16 from "@assets/images/user-guide/2.16.png";
import picture17 from "@assets/images/user-guide/2.17.png";
import picture18 from "@assets/images/user-guide/2.18.png";
import picture19 from "@assets/images/user-guide/2.19.png";
import picture20 from "@assets/images/user-guide/2.20.png";
import picture21 from "@assets/images/user-guide/2.21.png";
import picture22 from "@assets/images/user-guide/2.22.png";
import picture23 from "@assets/images/user-guide/2.23.png";
import picture24 from "@assets/images/user-guide/2.24.png";
import picture25 from "@assets/images/user-guide/2.25.png";

export const events = [
  {
    title: "What’s on Events tab:",
    html: `<p dir="ltr">
    Events in the system are divided by status:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            All – list of all events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Upcoming – events that starts soon, you can change/cancel event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In progress – ongoing events
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Completed – events that ended, but without grades. You must fill
            out Attendance data
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Evaluated – events with grades. Once you fill out grades, system
            generates Certificates for successfully studied craft-workers
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Cancelled – events that were cancelled by you. System sends email
            notification to TCO user with cancellation reason
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Activated - events that have been activated by TCO users, so that
            TC users could add practical &amp; overall grades.
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: each event status is highlighted by its own color
</p>
<div>
    <br/>
</div>
`
  },
  {
    title: "How to apply filters to search events in the system?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            Navigate to Events page
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Set filters
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Search button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 2.5
</p>
<p dir="ltr">
    <img
        src=${picture5}
        width="624"
        height="481"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Date from
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Total number of Events in the system
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Date to
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Disciplines
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Event Type
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Contractors/ Subcontractors
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            CSR Phase
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training centers- not available for TC users
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Filter by Training Center\`s City
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Sort by
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event location
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
</ol>
`
  },
  {
    title: "What’s on Event card",
    html: `<p dir="ltr">
    In the system 6 (six) types of the events:
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Pre-screening
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 2.6
</p>
<p dir="ltr">
    <img
        src=${picture6}
        width="616"
        height="192"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Assessment
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 2.7
</p>
<p dir="ltr">
    <img
        src=${picture7}
        width="596"
        height="186"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Training
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 2.8
</p>
<p dir="ltr">
    <img
    src=${picture8}
    width="611"
        height="189"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            OJT Training
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 2.9
</p>
<p dir="ltr">
    <img
        src=${picture9}
        width="624"
        height="97"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Commissioning Training
        </p>
    </li>
</ul>
<p dir="ltr">
    Picture 2.10
</p>
<p dir="ltr">
    <img
        src=${picture10}
        width="624"
        height="113"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
<br/>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Commissioning Assessment
        </p>
    </li>
</ul>
<br/>
<p dir="ltr">
    Picture 2.11.
    <img
        src=${picture11}
        width="624"
        height="73"
    />
</p>
<br/>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Type of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event ID
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Status of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event start time/date - end time/date
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Number of registered craft-workers out of available seats
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Academic hours of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event discipline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discipline competency level
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event language
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event venue
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event host
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participants list button
        </p>
    </li>
</ol>
`
  },
  {
    title: "What’s on Event details page:",
    html: `<p dir="ltr">
    This page demonstrates all details of the event.
</p>
<br/>
<p dir="ltr">
    Picture 2.12
</p>
<p dir="ltr">
    <img
        src=${picture12}
        width="624"
        height="443"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Cancel event button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Delete event button- only available for TCO users
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Event information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Download button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Download attendance button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Delete craft-worker button- only available for TCO users
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Feedback button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All participants tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Attendance tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Grades tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Activate event button- only available for TCO users
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Edit event button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note:
</p>
<p dir="ltr">
    Grade list active when you set instructor. Download attendance button will
    be available when you put grades.
</p>
<p dir="ltr">
    Edit and Cancel buttons are available if the event is in the Upcoming
    status.
</p>
`
  },
  {
    title: "How to create an Assessment event?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → All
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Create button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Assessment
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out all fields and press Create button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 2.13
</p>
<p dir="ltr">
    <img
        src=${picture13}
        width="624"
        height="868"
    />
</p>
<div>
    <br/>
</div>
`
  },
  {
    title: "How to create a Training event?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → All
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Create button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Training
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out all fields and press Create button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 2.14
</p>
<p dir="ltr">
    <img
        src=${picture14}
        width="614"
        height="917"
    />
</p>
`
  },
  {
    title: "How to create a Pre-screening event?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → All
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Create button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Pre-screening
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out all fields and press Create button
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Picture 2.15
</p>
<p dir="ltr">
    <img
        src=${picture15}
        width="612"
        height="607"
    />
</p>
`
  },
  {
    title: "How to create a Commissioning event",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → All
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Create button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Tick on the Commissioning box
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Assessment/Training event type
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out all fields and press Create button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Once event is created it is ready to be applied to by
            Contractors/Subcontractors to which it is available for
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 2.16
</p>
<p dir="ltr">
    <img
        src=${picture16}
        width="607"
        height="855"
    />
</p>
`
  },
  {
    title: "How to edit an event?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → Upcoming
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Edit Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out fields and press Save button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: You can edit events only in Upcoming status.
</p>
<p dir="ltr">
    Picture 2.17
</p>
<p dir="ltr">
    <img
        src=${picture17}
        width="611"
        height="603"
    />
</p>
`
  },
  {
    title: "How to cancel an event?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → Upcoming
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on Cancel Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out Cancellation reason and click Yes
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: You can cancel events in Upcoming status.
</p>
<p dir="ltr">
    Picture 2.18
</p>
<p dir="ltr">
    <img
        src=${picture18}  
        width="604"
        height="422"
    />
</p>
`
  },
  {
    title: "How to fill out attendance?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → In progress/Completed
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Attendance list button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out attendance and press Save button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 2.19
</p>
<p dir="ltr">
    <img
        src=${picture19}
        width="624"
        height="237"
    />
</p>
<p>
    Note: You can fill out attendance to events that In progress and Completed
    statuses. System does not allow more than 12 hours per day. You can set
    days as a weekend by pressing on swichter.
</p>
`
  },
  {
    title: "How to assign an instructor?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → Completed
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Instructor button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Assign Instructor and press Add button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: You can assign instructors only after filling out the Attendance
    list.
</p>
<p dir="ltr">
    Picture 2.20
</p>
<p dir="ltr">
    <img
        src=${picture20}
        width="571"
        height="242"
    />
</p>
<div>
    <br/>
</div>
`
  },
  {
    title: "How to put grades?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events &gt; Completed
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Grade list button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out grades and press Save button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: You can put grades in Completed or Activated status and after filling
    out Attendance and assigning Instructor to the event.
</p>
<p dir="ltr">
    Picture 2.21
</p>
<p dir="ltr">
    <img
        src=${picture21}
        width="617"
        height="215"
    />
</p>`
  },
  {
    title: "How to exclude an employee from the event?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → In progress/Completed
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Attendance list button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Choose employee and date of exclusion
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press … sign next to the date of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Choose Exclude tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Set reason
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Exclude button
        </p>
    </li>
</ol>
<p dir="ltr">
    Page example:
</p>
<p dir="ltr">
    Picture 2.22
</p>
<p dir="ltr">
    <img
        src=${picture22}
        width="624"
        height="328"
    />
</p>
<div>
    <br/>
</div>
`
  },
  {
    title: "How to prematurely graduate an employee from the event?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events → In progress/Completed
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Attendance list tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Choose employee and date of exclusion
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press … sign next to the date of the event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Choose Prematurely graduate tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Add grades
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Grade button
        </p>
    </li>
</ol>
<p dir="ltr">
    Page example:
</p>
<p dir="ltr">
    Picture 2.23
</p>
<p dir="ltr">
    <img
        src=${picture23}
        width="624"
        height="188"
    />
</p>
<br/>
<p dir="ltr">
    Page example for OJT events:
</p>
<p dir="ltr">
    Picture 2.24
</p>
<p dir="ltr">
    <img
        src=${picture24}
        width="624"
        height="184"
    />
</p>
`
  },
  {
    title: "How to add feedback?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Events &gt; Completed
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select Event
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Add feedback button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out modal window and press Add button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 2.25
</p>
<p dir="ltr">
    <img
        src=${picture25}     
        width="568"
        height="370"
    />
</p>
`
  }
];
