import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { IdcardOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Result,
  Row,
  Skeleton,
  Typography,
  Modal,
  notification,
  Tabs
} from "antd";
import { EmployeeIinForm } from "@components/common/employees/EmployeeIinForm";
import { EmployeeFacilityAccessCard } from "@components/common/employees/EmployeeFacilityAccessCard";
import { EmployeePersonalInfoCard } from "@components/common/employees/EmployeePersonalInfoCard";
import { EmployeeWorkingInfoCard } from "@components/common/employees/EmployeeWorkingInfoCard";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { EmployeeEventHistoryItemCard } from "@components/common/employees/EmployeeEventHistoryItemCard";
import EventCard from "@components/common/events/EventCard";

import { EmployeeDetails } from "@models/employee";

import { employeeApi } from "@api/employeeApi";

import useValidatedMutation from "@hooks/useValidatedMutation";

export const EmployeeDetailsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const [isPersonalInfoEdited, setIsPersonalInfoEdited] = useState(false);

  const params = useParams<{ id: string }>();
  const employeeId = Number(params.id);

  const employeeDetails = useQuery("getEmployee", () =>
    employeeApi.getEmployeeTC({ employeeId }).then((res) => res.data)
  );

  const eventHistory = useQuery(
    "getTcEmployeeEventHistory",
    () =>
      employeeApi
        .getEmployeeEventHistory({ employeeId })
        .then((res) => res.data),
    {
      refetchOnReconnect: false
    }
  );

  const ed = employeeDetails.data;

  const pageTitle = ed
    ? `${ed.firstName} ${ed.lastName}`
    : t("employees.employee") + " #" + employeeId;

  const updateEmployeeMutation = useValidatedMutation({
    mutationFunction: (values: Partial<EmployeeDetails>) =>
      employeeApi.updateEmployeeIin({
        employeeId,
        employeeData: { iin: values.iin }
      }),
    onSuccess() {
      employeeDetails.refetch();
    }
  });

  return (
    <>
      <PageMeta title={pageTitle} />

      <AppPageHeader
        title={
          <>
            {t("employees.employeePassport")} <IdcardOutlined />
          </>
        }
        breadcrumbs={[
          { breadcrumbName: "CT", path: "/" },
          { breadcrumbName: t("employees.employees"), path: "admin/employees" },
          {
            breadcrumbName: pageTitle,
            path: employeeId.toString()
          }
        ]}
        extra={[
          <Button
            type="primary"
            onClick={() => history.push("/admin/employees")}
          >
            {t("employees.backToList")}
          </Button>
        ]}
      />

      <Modal
        visible={isPersonalInfoEdited}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("employees.updatePersonalInfo")}
        footer={null}
        width={900}
      >
        <EmployeeIinForm
          isLoading={updateEmployeeMutation.isLoading}
          employee={ed}
          submitText={t("save")}
          onCancel={() => {
            setIsPersonalInfoEdited(false);
          }}
          onSubmit={(values) => {
            updateEmployeeMutation.mutateAsync(values).then(() => {
              notification.success({
                message: t("employees.iinUpdated")
              });

              setIsPersonalInfoEdited(false);
            });
          }}
        />
      </Modal>

      <Typography.Title level={5}>
        {pageTitle}

        <Typography.Text
          type="secondary"
          style={{ marginLeft: 8, fontWeight: "normal" }}
        >
          {ed ? `- ${ed.workLocationArea}, ${ed.position}` : ""}
        </Typography.Text>
      </Typography.Title>

      {employeeDetails.data && (
        <Row gutter={8}>
          <Col md={12} lg={8}>
            <EmployeePersonalInfoCard
              personalInfo={ed}
              editable={ed?.noIin === 1}
              onEdit={() => {
                setIsPersonalInfoEdited(true);
              }}
            />
          </Col>

          <Col md={12} lg={8}>
            <EmployeeWorkingInfoCard personalInfo={ed} />
          </Col>

          <Col md={12} lg={8}>
            <EmployeeFacilityAccessCard personalInfo={ed} />
          </Col>
        </Row>
      )}

      {employeeDetails.error && (
        <Result
          status="404"
          title={t("error.errorOccured")}
          subTitle={t("employees.notFound", { id: employeeId })}
        />
      )}

      {employeeDetails.isLoading && (
        <Row gutter={[8, 16]}>
          <Col md={12} lg={8}>
            <Skeleton active />
          </Col>

          <Col md={12} lg={8}>
            <Skeleton active />
          </Col>

          <Col md={12} lg={8}>
            <Skeleton active />
          </Col>

          <Col md={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}

      {eventHistory.data && (
        <>
          <br />

          <Typography.Title level={3}>
            {t("events.trainingHistory")}
          </Typography.Title>

          <Tabs defaultActiveKey="1">
            <Tabs.TabPane key="1" tab={t("events.certificates")}>
              <Row gutter={8}>
                {eventHistory.data.results
                  .filter((eh) => !!eh.evaluatedAt || !!eh.excludedAt)
                  .map((eh) => (
                    <Col md={12} lg={8} key={eh.id}>
                      <EmployeeEventHistoryItemCard
                        eventHistoryItem={eh}
                        getCertificateLink={(eh) =>
                          `/api/admin/certificate/${eh.grade?.certificateId}/pdf`
                        }
                      />
                    </Col>
                  ))}
              </Row>
            </Tabs.TabPane>

            <Tabs.TabPane key={2} tab={t("events.eventHistory")}>
              {eventHistory.data.results.map((eh) => (
                <EventCard
                  event={{ ...eh.event, isCommissioning: eh.isCommissioning }}
                  linkTo={() => `/admin/events/${eh.eventId}`}
                />
              ))}
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </>
  );
};
