import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";

import moment, { Moment } from "moment";

import { DatePicker, Input } from "antd";
import SelectItemGenerator from "@components/admin/migrations/renderers/SelectItemGenerator";

import { FormItemGeneratorProps, MigrationResponse } from "@models/migrations";

const DATE_FORMAT = "DD MMM YYYY, HH:mm";
const TIME_FORMAT = "HH:mm";

const FormItemGenerator: React.FC<FormItemGeneratorProps> = ({
  field,
  formik,
  instructorsQuery,
  trainingCenterQuery,
  disciplinesQuery,
  cityListQuery,
  isCommissioning,
  onBlur
}) => {
  const [t] = useTranslation();

  const [editedInput, setEditedInput] = useState<string | null>(null);

  const generateSelectItem = useCallback(
    (name: string) => (
      <SelectItemGenerator
        name={name}
        formik={formik}
        instructorsQuery={instructorsQuery}
        isCommissioning={isCommissioning}
        trainingCenterQuery={trainingCenterQuery}
        disciplinesQuery={disciplinesQuery}
        cityListQuery={cityListQuery}
      />
    ),
    [
      cityListQuery,
      disciplinesQuery,
      formik,
      instructorsQuery,
      isCommissioning,
      trainingCenterQuery
    ]
  );

  const inputValue = () => {
    if (editedInput) {
      return editedInput;
    }
    if (editedInput === "") {
      return null;
    }
    return formik.values.event[field.name as keyof MigrationResponse] || null;
  };

  switch (field.type) {
    case "input":
      return (
        <Input
          name={`event.${field.name}`}
          value={
            editedInput === null
              ? formik.values.event[field.name as keyof MigrationResponse]
              : editedInput
          }
          placeholder={t(field.label)}
          onChange={(e) => setEditedInput(e.target.value)}
          onBlur={() => onBlur(field.name, inputValue())}
        />
      );
    case "datePicker":
      return (
        <DatePicker
          format={DATE_FORMAT}
          allowClear={false}
          name={field.name}
          value={moment(
            formik.values.event[
              field.name as keyof MigrationResponse
            ] as unknown as Moment
          )}
          showTime={{
            format: TIME_FORMAT
          }}
          onChange={(_, value) =>
            formik.setFieldValue(`event.${field.name}`, moment(value))
          }
          style={{ width: "100%" }}
        />
      );
    case "select":
      return generateSelectItem(field.name);
    default:
      return null;
  }
};

export default FormItemGenerator;
