import { useMemo } from "react";

import { Card, Col, Row, Space } from "antd";
import ReportDoughnutChart from "@components/ui/charts/ReportDoughnutChart";

const COLOR_SET = [
  "#26537D",
  "#3EC6C4",
  "#F29132",
  "#1E4264",
  "#329E9D",
  "#C27428",
  "#102335",
  "#257776",
  "#91571E",
  "#888888",
  "#e83151"
];

interface Props {
  title: string;
  data: {
    countriesCount: number;
    datasets: {
      label: string;
      count: number;
      percentage: number;
    }[];
  };
}

export const ReportLabeledDoughnutChart: React.FC<Props> = (props) => {
  const datasets = useMemo(
    () => [
      {
        data: props.data.datasets.map((r) => r.count) || [],
        backgroundColor: props.data.datasets.map((_, idx) => COLOR_SET[idx])
      }
    ],
    [props.data]
  );

  return (
    <Card title={props.title} extra={props.data.countriesCount}>
      <ReportDoughnutChart
        datasets={datasets}
        showDataLabels={false}
        options={{
          scales: undefined,
          tooltips: {
            enabled: false
          },
          plugins: {
            labels: {
              fontColor: "transparent"
            },
            datalabels: {
              display: false
            }
          }
        }}
      />
      <br />
      {props.data.datasets.map((r, idx) => (
        <Row justify="space-between" key={idx}>
          <Col>
            <Space>
              <div
                style={{
                  height: 18,
                  width: 18,
                  backgroundColor: COLOR_SET[idx]
                }}
              />
              <span>{r.label}</span>
            </Space>
          </Col>
          <Col>
            <Space size="large">
              <span>{r.count}</span>
              <span>{r.percentage}%</span>
            </Space>
          </Col>
        </Row>
      ))}
    </Card>
  );
};
