import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  BarChartOutlined,
  BookOutlined,
  TeamOutlined,
  BlockOutlined,
  IdcardOutlined,
  CalendarOutlined,
  SolutionOutlined,
  AppstoreOutlined,
  ForkOutlined
} from "@ant-design/icons";
import ApplicationLayout from "@layouts/ApplicationLayout";
import { MembersPage } from "@pages/admin/members/MembersPage";
import { ContractorsPage } from "@pages/admin/contractors/ContractorsPage";
import { ContractorPage } from "@pages/admin/contractors/ContractorPage";
import { EmployeesPage } from "@pages/admin/employees/EmployeesPage";
import { EmployeeDetailsPage } from "@pages/admin/employees/EmployeeDetailsPage";
import { ProfilePage } from "@pages/ProfilePage";
import { TrainingCenterListPage } from "@pages/admin/training-centers/TrainingCenterListPage";
import { TrainingCenterDetailsPage } from "@pages/admin/training-centers/TrainingCenterDetailsPage";
import { SubcontractorPage } from "@pages/admin/subcontractor/SubcontractorDetails";
import ErrorPage from "@pages/ErrorPage";
import { EventDetailsPage } from "@pages/admin/events/EventDetailsPage";
import { TrainingReportsPage } from "@pages/admin/tc-reports/TrainingReportsPage";
import { CertificateReportsPage } from "@pages/admin/tc-reports/CertificateReportsPage";
import { ProfileReportsPage } from "@pages/admin/tc-reports/ProfileReportsPage";
import { ProtocolReportsPage } from "@pages/admin/tc-reports/ProtocolReportsPage";
import { CommissioningReportPage } from "@pages/admin/reports/CommissioningReportPage";
import CertificateSearchPage from "@pages/admin/CertificateSearchPage";
import { AllEventsPage } from "@pages/admin/events/AllEventsPage";
import { UpcomingEventsPage } from "@pages/admin/events/UpcomingEventsPage";
import { InprogressEventsPage } from "@pages/admin/events/InprogressEventsPage";
import { CompletedEventsPage } from "@pages/admin/events/CompletedEventsPage";
import { EvaluatedEventsPage } from "@pages/admin/events/EvaluatedEventsPage";
import { CancelledEventsPage } from "@pages/admin/events/CancelledEventsPage";
import { ActivatedEventsPage } from "@pages/admin/events/ActivatedEventsPage";
import { PerformanceReportPage } from "@pages/admin/reports/PerformanceReportPage";
import DatadumpPage from "@pages/admin/reports/DatadumpPage";
import { MouCategoryPage } from "@pages/admin/reports/MoUCategoryPage";
import { ReportDetailsPage } from "@pages/admin/tc-reports/ReportDetailsPage";
import { DuplicatedHeadcountPage } from "@pages/admin/reports/DuplicatedHeadcountPage";
import OnlineTrainingReportPage from "@pages/admin/reports/OnlineTrainingReportPage";
import OnlineTrainingReportDetailsPage from "@pages/admin/reports/OnlineTrainingReportDetailsPage";
import DisciplinesListPage from "@pages/admin/disciplines/DisciplinesListPage";
import MigrationListPage from "@pages/admin/migration/MigrationListPage";
import { CraftProfileReportsPage } from "@pages/admin/reports/CraftProfileReportsPage";
import { CraftProfilesListPage } from "@pages/admin/craftProfiles/CraftProfilesListPage";
import CraftProfilesCardPage from "@pages/admin/craftProfiles/CraftProfilesCardPage";
import { AdminUserGuide } from "@pages/admin/UserGuide";
import { ContactUs } from "@pages/ContactUs";
import { AdminWhatNew } from "@pages/admin/whatNew/component";
import { AboutUs } from "@pages/AboutUs";

import { PERMISSIONS } from "@models/permissions";
import { RouteItem } from "@models/routeItem";

import { UserContext } from "@contexts/UserContext";

const adminRoutes: RouteItem[] = [
  {
    id: "profile",
    path: "/profile",
    component: ProfilePage
  },
  {
    id: "reports",
    text: "reports.reports",
    icon: <BarChartOutlined />,
    isNavItem: true,
    permissions: [PERMISSIONS.REPORT_VIEW],
    items: [
      {
        id: "reportsPerformanceReport",
        text: "reports.performanceReports",
        path: "/admin/reports/performance",
        isNavItem: true,
        component: PerformanceReportPage
      },
      {
        id: "reportsMouCategory",
        text: "reports.mouCategory",
        path: "/admin/reports/mou-category",
        isNavItem: true,
        component: MouCategoryPage
      },
      {
        id: "reportsDuplicatedHeadcount",
        text: "reports.duplicatedHeadcount",
        path: "/admin/reports/duplicated-headcount",
        isNavItem: true,
        component: DuplicatedHeadcountPage
      },
      {
        id: "commissioningReport",
        text: "reports.commissioningPerformance",
        path: "/admin/reports/commissioning",
        isNavItem: true,
        component: CommissioningReportPage
      },
      {
        id: "datadumpReport",
        text: "reports.datadumpSubtitle",
        path: "/admin/reports/datadump",
        isNavItem: true,
        component: DatadumpPage
      },
      {
        id: "onlineTrainingReport",
        text: "reports.onlineTrainingReport_other",
        path: "/admin/reports/online-training-reports",
        isNavItem: true,
        component: OnlineTrainingReportPage
      },
      {
        id: "profileReport",
        text: "reports.profiles.profileReport",
        path: "/admin/reports/profile",
        isNavItem: true,
        component: CraftProfileReportsPage
      }
    ]
  },
  {
    id: "userGuide",
    path: "/admin/user-guide",
    component: AdminUserGuide
  },
  {
    id: "contactUs",
    path: "/admin/contact-us",
    component: ContactUs
  },
  {
    id: "whatNew",
    path: "/admin/what-new",
    component: AdminWhatNew
  },
  {
    id: "aboutUs",
    path: "/admin/about-us",
    component: AboutUs
  },
  {
    id: "online-training-report-details",
    path: "/admin/reports/online-training-report/:id",
    component: OnlineTrainingReportDetailsPage
  },
  {
    id: "members",
    text: "members.members",
    icon: <TeamOutlined />,
    path: "/admin/members",
    component: MembersPage,
    isNavItem: true,
    permissions: [PERMISSIONS.MEMBER_VIEW]
  },
  {
    id: "events",
    text: "events.events",
    icon: <CalendarOutlined />,
    isNavItem: true,
    permissions: [PERMISSIONS.EVENT_VIEW],
    items: [
      {
        id: "all-events",
        text: "events.all",
        path: "/admin/events/all",
        isNavItem: true,
        component: AllEventsPage
      },
      {
        id: "upcoming-events",
        text: "events.upcoming",
        path: "/admin/events/upcoming",
        isNavItem: true,
        component: UpcomingEventsPage
      },
      {
        id: "inprogress-events",
        text: "events.inprogress",
        path: "/admin/events/inprogress",
        isNavItem: true,
        component: InprogressEventsPage
      },
      {
        id: "completed-events",
        text: "events.completed",
        path: "/admin/events/completed",
        isNavItem: true,
        component: CompletedEventsPage
      },
      {
        id: "evaluated-events",
        text: "events.evaluated",
        path: "/admin/events/evaluated",
        isNavItem: true,
        component: EvaluatedEventsPage
      },
      {
        id: "cancelled-events",
        text: "events.cancelled",
        path: "/admin/events/cancelled",
        isNavItem: true,
        component: CancelledEventsPage
      },
      {
        id: "activated-events",
        text: "events.activated",
        path: "/admin/events/activated",
        isNavItem: true,
        component: ActivatedEventsPage
      }
    ]
  },
  {
    id: "eventDetails",
    text: "events.eventDetails",
    path: "/admin/events/:id",
    isNavItem: false,
    component: EventDetailsPage
  },
  {
    id: "training-centers-list",
    path: "/admin/training-centers",
    text: "trainingCenters.trainingCenters",
    icon: <BookOutlined />,
    component: TrainingCenterListPage,
    isNavItem: true,
    permissions: [PERMISSIONS.TRAINING_CENTER_VIEW]
  },
  {
    id: "training-centers-details",
    path: "/admin/training-center/:id",
    component: TrainingCenterDetailsPage
  },
  {
    id: "contractors",
    text: "contractors.contractors",
    icon: <BlockOutlined />,
    path: "/admin/contractors",
    component: ContractorsPage,
    isNavItem: true
  },
  {
    id: "employees",
    text: "employees.employeePassport",
    path: "/admin/employees",
    icon: <IdcardOutlined />,
    isNavItem: true,
    component: EmployeesPage,
    permissions: [PERMISSIONS.CONTRACTOR_EMPLOYEE_VIEW]
  },
  {
    id: "certificate-search",
    text: "certificates.search",
    path: "/admin/certificates",
    icon: <SolutionOutlined />,
    isNavItem: true,
    component: CertificateSearchPage,
    permissions: [PERMISSIONS.CERTIFICATE_VIEW]
  },
  {
    id: "employee-details",
    path: "/admin/employees/:id",
    component: EmployeeDetailsPage
  },
  {
    id: "contractor",
    path: "/admin/contractors/:id",
    component: ContractorPage
  },
  {
    id: "subcontractor",
    path: "/admin/contractors/:contractorId/subcontractor/:subcontractorId",
    component: SubcontractorPage
  },
  {
    id: "admin-report-details",
    path: "/admin/tc-report/:id",
    component: ReportDetailsPage
  },
  {
    id: "tcReports",
    text: "reports.tcReports",
    icon: <BarChartOutlined />,
    isNavItem: true,
    items: [
      {
        id: "trainingReports",
        text: "reports.trainingReport",
        path: "/admin/tc-reports/training",
        isNavItem: true,
        component: TrainingReportsPage
      },
      {
        id: "protocolReports",
        text: "reports.protocol",
        path: "/admin/tc-reports/protocol",
        isNavItem: true,
        component: ProtocolReportsPage
      },
      {
        id: "profileReports",
        text: "reports.profile",
        path: "/admin/tc-reports/profile",
        isNavItem: true,
        component: ProfileReportsPage
      },
      {
        id: "certificateReports",
        text: "reports.certificate",
        path: "/admin/tc-reports/certificate",
        isNavItem: true,
        component: CertificateReportsPage
      }
    ]
  },
  {
    id: "disciplines",
    text: "disciplines.disciplines",
    icon: <AppstoreOutlined />,
    path: "/admin/disciplines",
    component: DisciplinesListPage,
    isNavItem: true,
    permissions: [PERMISSIONS.SPECIALITY_VIEW]
  },
  {
    id: "migrations",
    text: "migrations.migration_other",
    icon: <ForkOutlined />,
    path: "/admin/migrations",
    component: MigrationListPage,
    isNavItem: true
  },
  {
    id: "craftProfiles",
    text: "craftProfiles.profiles",
    icon: <TeamOutlined />,
    path: "/admin/craft-profiles",
    component: CraftProfilesListPage,
    isNavItem: true
  },
  {
    id: "craftProfileCard",
    text: "craftProfiles.profile",
    path: "/admin/craft-profile/:id",
    component: CraftProfilesCardPage,
    isNavItem: false
  }
];

const AdminRoutes: React.FC = () => {
  const { user } = useContext(UserContext);
  const userPermissions = user?.permissions.map((p) => p.permission);
  const filteredAdminRoutes = adminRoutes.filter((route) =>
    route.permissions
      ? route.permissions.every((p) => userPermissions?.includes(p))
      : true
  );
  return (
    <ApplicationLayout navItems={filteredAdminRoutes}>
      <Switch>
        {filteredAdminRoutes.map((route, i) =>
          route.items ? (
            route.items.map((item, i) => (
              <Route
                exact
                path={item.path}
                component={item.component}
                key={i}
              />
            ))
          ) : (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={i}
            />
          )
        )}

        <Route exact path="/" render={() => <Redirect to="/profile" />} />

        <Route component={ErrorPage} />
      </Switch>
    </ApplicationLayout>
  );
};

export default AdminRoutes;
