import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { notification } from "antd";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { MigrationsListTable } from "@components/admin/migrations/MigrationsListTable";
import CreateMigrationModal from "@components/admin/migrations/modals/CreateMigrationModal";

import { MigrationTypes } from "@models/migrations";

import { migrationsApi } from "@api/migrationsApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { usePagination } from "@hooks/usePagination";

const MigratioListPage: React.FC = () => {
  const [t] = useTranslation();
  const pageTitle = t("migrations.migration_other");
  const [isMigrationModalOpen, setMigrationModalOpen] =
    useState<boolean>(false);

  const { page, setPage, setPagination } = usePagination();

  const migrationRadioTypes = useMemo(
    () => [
      {
        value: MigrationTypes.COMMISSIONING_TRAINING,
        label: t("events.eventTypes.commissioningTraining"),
        route: "comissioning-training"
      },
      {
        value: MigrationTypes.TRAINING,
        label: t("events.eventTypes.training"),
        route: "training"
      },
      {
        value: MigrationTypes.OJT_TRAINING,
        label: t("events.eventTypes.ojtTraining"),
        route: "ojt-training"
      },
      {
        value: MigrationTypes.COMMISSIONING_ASSESSMENT,
        label: t("events.eventTypes.commissioningAssessment"),
        route: "comissioning-assessment"
      },
      {
        value: MigrationTypes.ASSESSMENT,
        label: t("events.eventTypes.assessment"),
        route: "assessment"
      },
      {
        value: MigrationTypes.PRESCREENING,
        label: t("events.eventTypes.prescreening"),
        route: "pre-screening"
      }
    ],
    [t]
  );

  const migrationsList = useQuery(
    ["migrations-list", page],
    () => migrationsApi.getMigrations({ page }).then((res) => res.data),
    {
      onSuccess: (res) => {
        const pagination = {
          totalItems: res.totalItems,
          pageSize: res.pageSize,
          currentPage: res.currentPage
        };
        setPagination(pagination);
      }
    }
  );

  const deleteMigration = useValidatedMutation({
    mutationFunction: (eventId: number) =>
      migrationsApi.deleteMigration(eventId),
    onSuccess: () => {
      notification.success({ message: t("migrations.migrationDeleteSuccess") });
      migrationsList.refetch();
    },
    onError: () => notification.error({ message: t("error.errorOccured") })
  });

  const paginationSettings = {
    hideOnSinglePage: true,
    showSizeChanger: false,
    total: migrationsList.data?.totalItems,
    current: migrationsList.data?.currentPage,
    pageSize: migrationsList.data?.pageSize,
    onChange: (nextPage: number) => setPage(nextPage)
  };

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={t("migrations.migration_other")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "/" },
          {
            breadcrumbName: t("migrations.migration_other"),
            path: "admin/migrations"
          }
        ]}
        extra={
          <CreateMigrationModal
            migrationsList={migrationsList}
            migrationRadioTypes={migrationRadioTypes}
            isModalOpen={isMigrationModalOpen}
            handleOpenModal={() => setMigrationModalOpen(true)}
            handleCloseModal={() => setMigrationModalOpen(false)}
          />
        }
      />

      <MigrationsListTable
        migrationRadioTypes={migrationRadioTypes}
        migrationsList={migrationsList.data || []}
        isLoading={migrationsList.isLoading || deleteMigration.isLoading}
        deleteMigration={deleteMigration.mutate}
        pagination={paginationSettings}
      />
    </>
  );
};

export default MigratioListPage;
