import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import {
  Button,
  Col,
  Modal,
  notification,
  Row,
  Spin,
  Steps,
  Upload
} from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { Country } from "@models/countryCity";
import { CreateEmployeePayload } from "@models/employee";

import { employeeApi } from "@api/employeeApi";

import { UserContext } from "@contexts/UserContext";

import { EmployeeBatchEditForm } from "./EmployeeBatchEditForm";

interface Props {
  visible?: boolean;

  errors?: { [key: string]: string };

  contractors?: { id: number; name: string }[];
  contracts?: { id: number; contractNumber: string }[];
  countries?: Country[];

  isLoading?: boolean;

  onTemplateDownload?: () => void;
  onCancel?: () => void;
  onSubmit?: (employees: Partial<CreateEmployeePayload>[]) => void;
}

export const EmployeeBatchUploadModal = (props: Props) => {
  const [t] = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  const { userEntityId } = useContext(UserContext);

  const uploadMutation = useMutation(
    (file: Blob) =>
      employeeApi
        .uploadEmployeeBatch({ contractorId: userEntityId, file })
        .then((res) => res.data),
    {
      onSuccess() {
        notification.success({ message: t("employees.fileUploaded") });

        setCurrentStep(1);
      }
    }
  );

  useEffect(() => {
    if (!props.visible) {
      uploadMutation.reset();
      setCurrentStep(0);
    }

    // eslint-disable-next-line
  }, [props.visible]);

  return (
    <Modal
      visible={props.visible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      title={t("employees.addEmployee")}
      footer={null}
      width="90%"
    >
      <Steps current={currentStep}>
        <Steps.Step title={t("employees.uploadFile")} />
        <Steps.Step title={t("employees.editData")} />
      </Steps>

      <br />

      {currentStep === 0 && (
        <div>
          <Upload.Dragger
            accept=".xlsx,.xls"
            disabled={uploadMutation.isLoading}
            beforeUpload={(file) => {
              uploadMutation.mutate(file);

              return false;
            }}
            fileList={[]}
          >
            {uploadMutation.isLoading && <Spin />}

            {!uploadMutation.isLoading && (
              <>
                <InboxOutlined />

                <p>{t("employees.chooseOrDragFile")}</p>
              </>
            )}
          </Upload.Dragger>

          <br />

          <Row justify="space-between">
            <Col>
              <Button onClick={() => props.onCancel && props.onCancel()}>
                {t("cancel")}
              </Button>
            </Col>

            <Col>
              <Button
                type="dashed"
                onClick={() =>
                  props.onTemplateDownload && props.onTemplateDownload()
                }
              >
                {t("employees.downloadTemplate")}
              </Button>
            </Col>
          </Row>
        </div>
      )}

      {currentStep === 1 && (
        <>
          <EmployeeBatchEditForm
            isLoading={props.isLoading}
            errors={props.errors}
            countries={props.countries}
            contracts={props.contracts}
            contractors={props.contractors}
            employees={uploadMutation.data}
            onCancel={props.onCancel}
            onSubmit={(employees) => {
              if (props.onSubmit) {
                props.onSubmit(employees);
              }
            }}
          />
        </>
      )}
    </Modal>
  );
};
