import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import moment from "moment";
import FormItem from "antd/lib/form/FormItem";

import { Button, Col, DatePicker, Row, Select, Space } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { Contractor } from "@models/contractor";
import { Country } from "@models/countryCity";
import { Discipline } from "@models/discipline";
import {
  EventEmployeeGradeType,
  EventStatus,
  EventType,
  FailReasonType
} from "@models/event";
import { Location } from "@models/location";
import { TrainingCenter } from "@models/trainingCenter";

import { UserContext } from "@contexts/UserContext";
import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";

const FAIL_REASON_OPTIONS = [
  {
    value: FailReasonType.NO_SHOW,
    label: "events.failReason.1"
  },
  {
    value: FailReasonType.ATTENDANCE,
    label: "events.failReason.2"
  },
  {
    value: FailReasonType.UNDER_THE_INFLUENCE,
    label: "events.failReason.3"
  },
  {
    value: FailReasonType.PERFORMANCE,
    label: "events.failReason.4"
  },
  {
    value: FailReasonType.OTHER,
    label: "events.failReason.5"
  }
];

const TRAINING_GRADE_OPTIONS = [
  {
    label: `events.grade.1`,
    value: EventEmployeeGradeType.FAILED
  },
  {
    label: `events.grade.2`,
    value: EventEmployeeGradeType.PASSED
  }
];

interface FormValues {
  dateStart?: string;
  dateEnd?: string;
  specialityIds?: number[];
  trainingCenterIds?: number[];
  contractorIds?: number[];
  statuses?: EventStatus[];
  csrPhases?: number[];
  failReasons?: FailReasonType[];
  overallGrade?: EventEmployeeGradeType;
  completionStatus?: number;
  citizenshipId?: number;
  locationId?: number;
}

interface Props {
  initialFilter: {
    dateStart?: string;
    dateEnd?: string;
    specialityIds?: number[];
    trainingCenterIds?: number[];
    contractorIds?: number[];
    statuses?: EventStatus[];
    csrPhases?: number[];
    failReasons?: number[];
    overallGrade?: EventEmployeeGradeType;
    completionStatus?: number;
    citizenshipId?: number;
    locationId?: number;
  };
  eventType: EventType;
  disciplines: Discipline[];
  trainingCenters: TrainingCenter[];
  contractors: Contractor[];
  countries: Country[];
  isLoading: boolean;
  locations: Location[];
  onSubmit: (values: FormValues) => void;
}

export const PerformanceReportFilterForm: React.FC<Props> = (props) => {
  const { userRole } = useContext(UserContext);

  const { i18n, t } = useTranslation();

  const disciplinesSelectOptions = useMemo(
    () =>
      props.disciplines.map((d) => ({
        value: Number(d.id),
        label: i18n.language === "en" ? d.nameEn : d.nameRu
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.disciplines]
  );

  const trainingCentersSelectOptions = useMemo(
    () =>
      props.trainingCenters.map((t) => ({
        value: Number(t.id),
        label: t.name
      })),
    [props.trainingCenters]
  );

  const contractorsSelectOptions = useMemo(
    () =>
      props.contractors.map((c) => ({
        value: Number(c.id),
        label: c.name
      })),
    [props.contractors]
  );

  const eventStatusesSelectOptions = [
    {
      value: EventStatus.INPROGRESS,
      label: t(`events.statuses.${EventStatus.INPROGRESS}`)
    },
    {
      value: EventStatus.COMPLETED,
      label: t(`events.statuses.${EventStatus.COMPLETED}`)
    },
    {
      value: EventStatus.EVALUATED,
      label: t(`events.statuses.${EventStatus.EVALUATED}`)
    }
  ];

  const formik = useFormik<FormValues>({
    initialValues: {
      ...props.initialFilter,
      specialityIds: props.initialFilter.specialityIds?.map((s) => Number(s)),
      trainingCenterIds: props.initialFilter.trainingCenterIds?.map((t) =>
        Number(t)
      ),
      contractorIds: props.initialFilter.contractorIds?.map((c) => Number(c)),
      statuses: props.initialFilter.statuses?.map((s) => Number(s)),
      csrPhases: props.initialFilter.csrPhases?.map((c) => Number(c)),
      failReasons: props.initialFilter.failReasons?.map((fr) => Number(fr)),
      overallGrade:
        props.initialFilter.overallGrade &&
        Number(props.initialFilter.overallGrade),
      completionStatus:
        props.initialFilter.completionStatus &&
        Number(props.initialFilter.completionStatus),
      citizenshipId:
        props.initialFilter.citizenshipId &&
        Number(props.initialFilter.citizenshipId),
      locationId:
        props.initialFilter.locationId && Number(props.initialFilter.locationId)
    },
    onSubmit: (values) => {
      props.onSubmit(values);
    }
  });

  return (
    <>
      <Row gutter={8} wrap>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("dateFrom")}
            validateStatus={formik.errors.dateStart && "error"}
            help={formik.errors.dateStart}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder={t("events.filters.selectDate")}
              onChange={(_, value) => formik.setFieldValue("dateStart", value)}
              disabledDate={(date) =>
                date && date >= moment(formik.values.dateEnd, "YYYY-MM-DD")
              }
              value={
                formik.values.dateStart
                  ? moment(formik.values.dateStart, "YYYY-MM-DD")
                  : undefined
              }
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("dateTo")}
            validateStatus={formik.errors.dateEnd && "error"}
            help={formik.errors.dateEnd}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder={t("events.filters.selectDate")}
              onChange={(_, value) => formik.setFieldValue("dateEnd", value)}
              disabledDate={(date) =>
                date && date <= moment(formik.values.dateStart, "YYYY-MM-DD")
              }
              value={
                formik.values.dateEnd
                  ? moment(formik.values.dateEnd, "YYYY-MM-DD")
                  : undefined
              }
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.disciplines")}
            validateStatus={formik.errors.specialityIds && "error"}
            help={formik.errors.specialityIds}
          >
            <SearchSelect
              value={formik.values.specialityIds}
              options={disciplinesSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("specialityIds", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        {userRole !== "trainingcenter" && (
          <Col span={12}>
            <FormItem
              labelCol={{ span: 24 }}
              label={t("events.filters.trainingCenters")}
              validateStatus={formik.errors.trainingCenterIds && "error"}
              help={formik.errors.trainingCenterIds}
            >
              <SearchSelect
                value={formik.values.trainingCenterIds}
                options={trainingCentersSelectOptions}
                onChange={(value: number) =>
                  formik.setFieldValue("trainingCenterIds", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}
        {userRole !== "contractor" && (
          <Col span={12}>
            <FormItem
              labelCol={{ span: 24 }}
              label={t("events.filters.contractors")}
              validateStatus={formik.errors.contractorIds && "error"}
              help={formik.errors.contractorIds}
            >
              <SearchSelect
                value={formik.values.contractorIds}
                options={contractorsSelectOptions}
                onChange={(value: number) =>
                  formik.setFieldValue("contractorIds", value)
                }
                maxTagCount={1}
                mode="multiple"
              />
            </FormItem>
          </Col>
        )}
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.filters.eventStatuses")}
            validateStatus={formik.errors.statuses && "error"}
            help={formik.errors.statuses}
          >
            <SearchSelect
              value={formik.values.statuses}
              options={eventStatusesSelectOptions}
              onChange={(value: number) =>
                formik.setFieldValue("statuses", value)
              }
              maxTagCount={1}
              mode="multiple"
            />
          </FormItem>
        </Col>
        {props.eventType !== EventType.OJT_TRAINING && (
          <>
            <Col span={12}>
              <FormItem
                labelCol={{ span: 24 }}
                label={t("events.csrPhase")}
                validateStatus={formik.errors.csrPhases && "error"}
                help={formik.errors.csrPhases}
              >
                <SearchSelect
                  value={formik.values.csrPhases}
                  onChange={(v: number) => formik.setFieldValue("csrPhases", v)}
                  options={CSR_PHASE_OPTIONS.map((cp) => ({
                    label: t(cp.label, { no: cp.value }),
                    value: cp.value
                  }))}
                  mode="multiple"
                  maxTagCount={2}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                labelCol={{ span: 24 }}
                label={t("events.failReason.failReason")}
                validateStatus={formik.errors.failReasons && "error"}
                help={formik.errors.failReasons}
              >
                <SearchSelect
                  value={formik.values.failReasons}
                  options={FAIL_REASON_OPTIONS.map((f) => ({
                    value: Number(f.value),
                    label: t(f.label)
                  }))}
                  onChange={(value: number) =>
                    formik.setFieldValue("failReasons", value)
                  }
                  maxTagCount={1}
                  mode="multiple"
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                labelCol={{ span: 24 }}
                label={t("events.overallGrade")}
                validateStatus={formik.errors.overallGrade && "error"}
                help={formik.errors.overallGrade}
              >
                <Select
                  value={formik.values.overallGrade}
                  options={TRAINING_GRADE_OPTIONS.map((tg) => ({
                    ...tg,
                    label: t(tg.label)
                  }))}
                  onChange={(value: number) =>
                    formik.setFieldValue("overallGrade", value)
                  }
                  maxTagCount={1}
                />
              </FormItem>
            </Col>
          </>
        )}
        {props.eventType === EventType.OJT_TRAINING && (
          <Col span={12}>
            <FormItem
              labelCol={{ span: 24 }}
              label={t("events.completionStatus")}
              validateStatus={formik.errors.completionStatus && "error"}
              help={formik.errors.completionStatus}
            >
              <Select
                value={formik.values.completionStatus}
                onChange={(v) => formik.setFieldValue("completionStatus", v)}
              >
                <Select.Option value={2} key={2}>
                  {t("events.completionStatuses.2")}
                </Select.Option>
                <Select.Option value={1} key={1}>
                  {t("events.completionStatuses.1")}
                </Select.Option>
              </Select>
            </FormItem>
          </Col>
        )}
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("employees.citizenship")}
            validateStatus={formik.errors.citizenshipId && "error"}
            help={formik.errors.citizenshipId}
          >
            <SearchSelect
              options={props.countries?.map((c) => ({
                value: c.id,
                label: i18n.language === "en" ? c.nameEn : c.nameRu
              }))}
              value={formik.values.citizenshipId}
              onChange={(v: number) => formik.setFieldValue("citizenshipId", v)}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            label={t("events.location")}
            validateStatus={formik.errors.locationId && "error"}
            help={formik.errors.locationId}
          >
            <SearchSelect
              options={props.locations?.map((location) => ({
                value: location.id,
                label:
                  i18n.language === "en" ? location.nameEn : location.nameRu
              }))}
              value={formik.values.locationId}
              onChange={(v: number) => formik.setFieldValue("locationId", v)}
            />
          </FormItem>
        </Col>
      </Row>
      <Row justify="start" style={{ marginBottom: "32px" }}>
        <Col>
          <span>{t("reports.filterNotAppliedToAcademicHours")}</span>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            <Button onClick={() => formik.setValues({})}>
              {t("events.filters.clearFilters")}
            </Button>
            <Button
              type="primary"
              loading={props.isLoading}
              onClick={() => formik.submitForm()}
            >
              {t("filter")}
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
