import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { EventActivateForm } from "@components/common/events/forms/EventActivateForm";

import { EventDetails } from "@models/event";

import { eventsApi } from "@api/eventsApi";

import useValidatedMutation from "@hooks/useValidatedMutation";
import { transformValidationError } from "@utils/errorHelper";

interface Props {
  event: EventDetails;
  onEdit?: () => void;
}

const EventActivateModal: React.FC<Props> = ({ event, onEdit }) => {
  const [t] = useTranslation();
  const [isActivating, setIsActivate] = useState(false);

  const activateEventMutation = useValidatedMutation({
    mutationFunction: () =>
      eventsApi.adminActivateEvent({
        eventId: event.id,
        trainingCenterId: event.trainingCenterId
      }),
    onSuccess() {
      notification.success({
        message: t("events.activateComplete", {
          name: event.name,
          date: event.dateTimeStart
        })
      });

      setIsActivate(false);

      onEdit && onEdit();
    }
  });
  const validationErrors = useMemo(
    () => transformValidationError(activateEventMutation.error),
    [activateEventMutation.error]
  );

  return (
    <>
      <Modal
        visible={isActivating}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("events.activateEvent")}
        footer={null}
        width={400}
        onCancel={() => {
          setIsActivate(false);
        }}
      >
        <EventActivateForm
          event={event}
          isLoading={activateEventMutation.isLoading}
          errors={validationErrors}
          onCancel={() => setIsActivate(false)}
          onSubmit={(values) => {
            activateEventMutation.mutate(values);
          }}
        />
      </Modal>

      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={() => setIsActivate(true)}
      >
        {t("events.activateEvent")}
      </Button>
    </>
  );
};

export default EventActivateModal;
