import React from "react";

import { Pagination } from "antd";

import { PaginationItem } from "@models/paginationItem";
import { EventShortDetails } from "@models/event";

import EventCard from "./EventCard";

interface Props {
  pageData?: PaginationItem<EventShortDetails>;
  disabled?: boolean;
  onChange?: (nextPage: number) => void;
  linkTo?: (ev: EventShortDetails) => string;
}

export const EventPaginator = ({
  pageData,
  linkTo,
  onChange,
  disabled
}: Props) => {
  return (
    <div>
      {pageData?.results.map((ev) => (
        <EventCard event={ev} key={ev.id} linkTo={linkTo} />
      ))}

      {pageData && (
        <Pagination
          showSizeChanger={false}
          style={{ textAlign: "center" }}
          current={pageData?.currentPage}
          pageSize={pageData?.pageSize}
          total={pageData?.totalItems}
          hideOnSinglePage
          disabled={disabled}
          onChange={onChange}
        />
      )}
    </div>
  );
};
