import { useTranslation } from "react-i18next";

import moment from "moment";

import { Space, Table, Tag, Typography } from "antd";
import DownloadLink from "@components/common/DownloadLink";
import RetryButton from "@components/common/reports/RetryButton";

import { DatadumpRecord, DatadumpStatuses } from "@models/report";

interface Props {
  reports: DatadumpRecord[];
  isLoading: boolean;
  getDownloadLink: (report: DatadumpRecord) => string;
  dataDumpsRefetch: () => void;
}

const DatadumpReportTable: React.FC<Props> = (props) => {
  const [t] = useTranslation();

  return (
    <Table
      title={() => (
        <Space align="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {t("reports.datadumpTitle")}
          </Typography.Title>

          <Typography.Text type="secondary">
            {t("reports.datadumpSubtitle")}
          </Typography.Text>
        </Space>
      )}
      locale={{ emptyText: t("noData") }}
      loading={props.isLoading}
      tableLayout="auto"
      bordered
      pagination={false}
      rowKey="userId"
      dataSource={props.reports}
    >
      <Table.Column title={t("reports.datadumpName")} dataIndex="fileName" />
      <Table.Column
        title={t("reports.status")}
        render={(report: DatadumpRecord) => (
          <Tag
            color={
              report.status === DatadumpStatuses.Completed
                ? "green"
                : report.status === DatadumpStatuses.InProgress
                ? "yellow"
                : "red"
            }
          >
            {t(
              report.status === DatadumpStatuses.Completed
                ? "completed"
                : report.status === DatadumpStatuses.InProgress
                ? "inProgress"
                : "failed"
            )}
          </Tag>
        )}
      />
      <Table.Column
        title={t("reports.datadumpDate")}
        render={(report) =>
          moment.unix(report.unixTime).format("D MMM YYYY, HH:mm")
        }
      />
      <Table.Column
        title={t("actions")}
        render={(report: DatadumpRecord) =>
          report.status !== DatadumpStatuses.Failed ? (
            <DownloadLink
              type="primary"
              size="middle"
              url={props.getDownloadLink(report)}
              fileName={report.fileName}
              label={t("download")}
              disabled={report.status === DatadumpStatuses.InProgress}
            />
          ) : (
            <RetryButton
              reportId={report.id}
              dataDumpsRefetch={props.dataDumpsRefetch}
            />
          )
        }
      />
    </Table>
  );
};

export default DatadumpReportTable;
