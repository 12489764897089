import picture28 from "@assets/images/user-guide/1.28.png";
import picture29 from "@assets/images/user-guide/1.29.png";

export const profile = [
  {
    title: "Profile (Craft employee, HR profiles)",
    html: `<p dir="ltr">
    Profile tab shows job- seeking craft worker profiles, which were registered
    in the system so that Contractor/ Subcontractor users could view and hire
    profiles. This module also stores all the information in the system.
</p>
<br/>
<p dir="ltr">
    Once you press Profiles tab on Panel menu you will be redirected to
    Profiles page with the following information:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Filters;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            List of profiles registered in the system.
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    Note: Only profiles with unemployed status are shown for Contractor/
    Subcontractor users.
</p>
<br/>
<p dir="ltr">
    Picture 4.28
</p>
<p dir="ltr">
    <img
        src=${picture28}
        width="624"
        height="229"
    />
</p>
<br/>
<br/>
<p dir="ltr">
    List of profiles represents all unemployed persons\` profiles with
    unemployed status. They are available for hiring.
</p>
<p dir="ltr">
    The Hired tab shows those employee profiles who have been hired by the
    Contractor.
</p>
`
  },
  {
    title: "How to view a profile?",
    html: `<p dir="ltr">
    Contractor users can view an employee profile. To do that, please navigate
    to the Profile tab, then choose craft employee\`s profile in the list.
</p>
<p dir="ltr">
    Profile page contains detailed data about a person: name, date of birth,
    tel.number etc.
</p>
<p dir="ltr">
    Also, there are buttons to Hire and Download profile info.
</p>
<br/>
<p dir="ltr">
    Page example:
</p>
<p dir="ltr">
    Picture 4.29
</p>
<p dir="ltr">
    <img
        src=${picture29}
        width="624"
        height="352"
    />
</p>
`
  },
  {
    title: "How to hire a profile?",
    html: `<p dir="ltr">
    Contractor users can view and hire an employee profile. To do that, please
    navigate to the Profile tab, then to the craft employee\`s profile page and
    press Hire button.
</p>
`
  },
  {
    title: "How to cancel an employee hire?",
    html: `<p dir="ltr">
    Contractor users can cancel their hire of an employee. To do that, please
    navigate to the Profile tab, then to the craft employee\`s profile page and
    press Cancel hire button.
</p>
`
  },
  {
    title: "How to download a profile?",
    html: `<p dir="ltr">
    Contractor users can download an employee profile to a pdf file. To do
    that, please navigate to the Profile tab, then to the craft employee\`s
    profile page and press the Download button.
</p>
`
  },
  {
    title: "How to use filters?",
    html: `<p dir="ltr">
    System lets Users filter HR module data. List of filters available is
    specified below.
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            System shows All profile page and filters
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Contractor user sets filters
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Contractor user presses Search button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To clear filters User presses ‘Clear filters’ data
        </p>
    </li>
</ol>
`
  },
  {
    title: "What filters are available?",
    html: `<div dir="ltr" align="left">
    <table>
        <colgroup>
            <col width="91"/>
            <col width="92"/>
            <col width="261"/>
            <col width="180"/>
        </colgroup>
        <tbody>
            <tr>
                <td>
                    <p dir="ltr">
                        Filters ENG
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Filters RU
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Values/ Description
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Employment status
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Статус трудовой занятости
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Employed/ Unemployed
                    </p>
                    <p dir="ltr">
                        Трудоустроен/ Не трудоустроен
                    </p>
                    <p dir="ltr">
                        Single select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                    <p dir="ltr">
                        Contractor/Subcontractor can only see Unemployed
                        profiles. This filter is not shown for Contractor/
                        Subcontractor users.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Discipline
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Дисциплина
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        List of all disciplines in CT portal
                    </p>
                    <p dir="ltr">
                        Multi select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR phase
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Фаза КСО
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        None, CSR 1- 50
                        <a
                            href="https://petrelai.atlassian.net/browse/CTN-1040"
                        >
                        </a>
                    </p>
                    <p dir="ltr">
                        Multi select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        CSR Contractor
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Подрядчик КСО
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        CSR Aktau, CSR Atyrau or Demob
                    </p>
                    <p dir="ltr">
                        КСО Актау, КСО Атырау, Демоб
                    </p>
                    <p dir="ltr">
                        Multi select dropdown menu
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Clear all button
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Очистить
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        clears all filters set
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr">
                        Search button
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Поиск
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        starts the filtration/ search
                    </p>
                </td>
                <td>
                    <p dir="ltr">
                        Shown for all users
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
`
  }
];
