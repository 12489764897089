import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as yup from "yup";

import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import PhoneInput from "@components/ui/PhoneInput";

import { EmployeeDetails } from "@models/employee";
import { MouCategory } from "@models/mouCategory";

interface FormValues {
  position: string;
  mouCategory: number;
  contractorId: number;
  contractId: number;
  lineSupervisorBadgeNumber: string;
  workLocationArea: string;
  firstNameCompanyRep: string;
  lastNameCompanyRep: string;
  phoneNumberCompanyRep: string;
}

interface Props {
  employee?: EmployeeDetails;

  contractors?: { id: number; name: string }[];
  contracts?: { id: number; contractNumber: string }[];

  isLoading?: boolean;
  submitText?: string;
  cancelText?: string;

  onCancel?: () => void;
  onSubmit?: (values: FormValues) => void;
}

const MOU_CATEGORY_OPTIONS = [
  {
    labelKey: `mouCategory.${MouCategory.ENGINEERING_MANAGEMENT}`,
    value: MouCategory.ENGINEERING_MANAGEMENT
  },
  {
    labelKey: `mouCategory.${MouCategory.SUPERVISOR_AND_FOREMAN}`,
    value: MouCategory.SUPERVISOR_AND_FOREMAN
  },
  {
    labelKey: `mouCategory.${MouCategory.HEAVY_EQUIPMENT_OPERATOR}`,
    value: MouCategory.HEAVY_EQUIPMENT_OPERATOR
  },
  { labelKey: `mouCategory.${MouCategory.CRAFT}`, value: MouCategory.CRAFT }
];

export const EmployeeWorkingInfoForm: React.FC<Props> = (props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      position: "",
      mouCategory: 0,
      contractorId: 0,
      contractId: 0,
      lineSupervisorBadgeNumber: "",
      workLocationArea: "",
      firstNameCompanyRep: "",
      lastNameCompanyRep: "",
      phoneNumberCompanyRep: ""
    },
    validationSchema: yup.object().shape({
      position: yup.string().required(t("validation.required")),
      mouCategory: yup
        .number()
        .min(1, t("validation.required"))
        .required(t("validation.required")),
      contractorId: yup
        .number()
        .min(1, t("validation.required"))
        .required(t("validation.required")),
      contractId: yup
        .number()
        .min(1, t("validation.required"))
        .required(t("validation.required")),
      lineSupervisorBadgeNumber: yup
        .string()
        .matches(/^[0-9]+$/, t("validation.invalidFormat"))
        .length(8, "Невалидный номер")
        .required(t("validation.required")),
      workLocationArea: yup.string().required(t("validation.required")),
      firstNameCompanyRep: yup.string().required(t("validation.required")),
      lastNameCompanyRep: yup.string().required(t("validation.required")),
      phoneNumberCompanyRep: yup
        .string()
        .matches(/^[0-9]+$/, t("validation.invalidPhoneNumber"))
        .length(11, t("validation.invalidPhoneNumber"))
        .required(t("validation.required"))
    }),
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  const getValidateStatus = (fieldName: keyof FormValues) =>
    formik.touched[fieldName] && formik.errors[fieldName] ? "error" : "";
  const getHelp = (fieldName: keyof FormValues) =>
    formik.touched[fieldName] && formik.errors[fieldName];

  useEffect(() => {
    if (props.employee) {
      const e = props.employee;

      formik.setValues({
        contractId: e.contractId,
        contractorId: e.contractorId,
        firstNameCompanyRep: e.firstNameCompanyRep,
        lastNameCompanyRep: e.lastNameCompanyRep,
        lineSupervisorBadgeNumber: e.lineSupervisorBadgeNumber.toString(),
        mouCategory: e.mouCategory,
        position: e.position,
        phoneNumberCompanyRep: e.phoneNumberCompanyRep,
        workLocationArea: e.workLocationArea
      });
    }

    // eslint-disable-next-line
  }, [props.employee]);

  return (
    <Form onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label={t("employees.workLocationArea")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("workLocationArea")}
            help={getHelp("workLocationArea")}
          >
            <Input
              name="workLocationArea"
              value={formik.values.workLocationArea}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("employees.position")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("position")}
            help={getHelp("position")}
          >
            <Input
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("employees.mouCategory")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("mouCategory")}
            help={getHelp("mouCategory")}
          >
            <Select
              value={formik.values.mouCategory || undefined}
              onChange={(v) => formik.setFieldValue("mouCategory", v)}
              onBlur={formik.handleBlur}
              id="mouCategory"
            >
              {MOU_CATEGORY_OPTIONS.map((c) => (
                <Select.Option key={c.value} value={c.value}>
                  {t(c.labelKey)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("employees.lineSupervisorBadgeNumber")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("lineSupervisorBadgeNumber")}
            help={getHelp("lineSupervisorBadgeNumber")}
          >
            <PhoneInput
              mask="99999999"
              name="lineSupervisorBadgeNumber"
              value={formik.values.lineSupervisorBadgeNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("employees.contractor")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("contractorId")}
            help={getHelp("contractorId")}
          >
            <Select
              value={formik.values.contractorId || undefined}
              showSearch
              optionFilterProp="name"
              filterOption={(inp, opt) => {
                if (opt && opt.children) {
                  return (
                    opt.children
                      .toString()
                      .toLowerCase()
                      .indexOf(inp.toLowerCase()) >= 0
                  );
                }

                return false;
              }}
              onChange={(v) => formik.setFieldValue("contractorId", v)}
              onBlur={() => formik.handleBlur("contractorId")}
              id="contractorId"
            >
              {props.contractors?.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("employees.contract")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("contractId")}
            help={getHelp("contractId")}
          >
            <Select
              value={formik.values.contractId || undefined}
              showSearch
              optionFilterProp="name"
              filterOption={(inp, opt) => {
                if (opt && opt.children) {
                  return (
                    opt.children
                      .toString()
                      .toLowerCase()
                      .indexOf(inp.toLowerCase()) >= 0
                  );
                }

                return false;
              }}
              onChange={(v) => formik.setFieldValue("contractId", v)}
              onBlur={() => formik.handleBlur("contractId")}
            >
              {props.contracts?.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.contractNumber}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.lastNameCompanyRep")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("lastNameCompanyRep")}
            help={getHelp("lastNameCompanyRep")}
          >
            <Input
              name="lastNameCompanyRep"
              value={formik.values.lastNameCompanyRep}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.firstNameCompanyRep")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("firstNameCompanyRep")}
            help={getHelp("firstNameCompanyRep")}
          >
            <Input
              name="firstNameCompanyRep"
              value={formik.values.firstNameCompanyRep}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Form.Item
            label={t("employees.phoneNumberCompanyRep")}
            labelCol={{ span: 24 }}
            validateStatus={getValidateStatus("phoneNumberCompanyRep")}
            help={getHelp("phoneNumberCompanyRep")}
          >
            <PhoneInput
              name="phoneNumberCompanyRep"
              value={formik.values.phoneNumberCompanyRep}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              mask="99999999999"
            />
          </Form.Item>
        </Col>

        <Col md={24}>
          <Row justify="end">
            <Col>
              <Space align="end">
                <Button
                  htmlType="button"
                  onClick={() => props.onCancel && props.onCancel()}
                >
                  {props.cancelText ? props.cancelText : t("cancel")}
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  loading={props.isLoading}
                >
                  {props.submitText ? props.submitText : t("submit")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
