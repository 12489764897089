import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import FormItem from "antd/lib/form/FormItem";
import moment from "moment";

import { Card, DatePicker, Row, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { MouCategoryChart } from "@components/common/reports/MouCategoryChart";
import { MouCategoryTable } from "@components/common/reports/MouCategoryTable";

import { MouCategoryReportType } from "@models/report";
import { EventType } from "@models/event";

import { reportsApi } from "@api/reportsApi";

import { useDefaultQuery } from "@hooks/useDefaultQuery";

export const MouCategoryPage: React.FC = () => {
  const { t } = useTranslation();

  const [activeReportType, setActiveReportType] =
    useState<MouCategoryReportType>(MouCategoryReportType.DISCIPLINE);

  const [dateRange, setDateRange] = useState<{
    dateStart?: string;
    dateEnd?: string;
  }>({});

  const mouCategoryChartsData = useDefaultQuery(
    ["getMouChartsData", activeReportType],
    () =>
      reportsApi
        .getMouCategoryChartsData(activeReportType)
        .then((res) => res.data)
  );

  const mouCategoryTableDataByPeriod = useDefaultQuery(
    ["getMouTableByPeriod", activeReportType, dateRange],
    () =>
      reportsApi
        .getMouCategoryTableData(activeReportType, dateRange)
        .then((res) => res.data)
  );

  const mouCategoryTableDataPTD = useDefaultQuery(
    ["getMouTableDataPTD", activeReportType],
    () =>
      reportsApi
        .getMouCategoryTableDataPTD(activeReportType)
        .then((res) => res.data)
  );

  const pageTitle = "MoU Category";

  return (
    <>
      <PageMeta title={pageTitle} />
      <AppPageHeader
        title={pageTitle}
        breadcrumbs={[
          {
            breadcrumbName: t("meta.ctPortal"),
            path: "/"
          },
          {
            breadcrumbName: pageTitle,
            path: "/admin/reports/mou-category"
          }
        ]}
      />

      <Card
        tabList={[
          {
            tab: t(`Discipline`),
            key: `${MouCategoryReportType.DISCIPLINE}`
          },
          {
            tab: t(`Position`),
            key: `${MouCategoryReportType.POSITION}`
          }
        ]}
        loading={mouCategoryChartsData.isLoading}
        activeTabKey={activeReportType.toString()}
        onTabChange={(tab) => {
          setActiveReportType(Number(tab));
        }}
      >
        <MouCategoryChart
          reportType={activeReportType}
          eventType={EventType.ASSESSMENT}
          report={mouCategoryChartsData.data || []}
        />
        <br />
        <br />
        <MouCategoryChart
          reportType={activeReportType}
          eventType={EventType.TRAINING}
          report={mouCategoryChartsData.data || []}
        />
        <br />
        <br />
        <br />
        <Row>
          <Space size="large" style={{ width: "100%" }}>
            <FormItem label={t("dateFrom")}>
              <DatePicker
                onChange={(_, value) => {
                  setDateRange((prev) => ({ ...prev, dateStart: value }));
                }}
                disabledDate={(date) =>
                  date && date >= moment(dateRange.dateEnd, "YYYY-MM-DD")
                }
                value={
                  dateRange.dateStart
                    ? moment(dateRange.dateStart, "YYYY-MM-DD")
                    : undefined
                }
              />
            </FormItem>
            <FormItem label={t("dateFrom")}>
              <DatePicker
                onChange={(_, value) => {
                  setDateRange((prev) => ({ ...prev, dateEnd: value }));
                }}
                disabledDate={(date) =>
                  date && date <= moment(dateRange.dateStart, "YYYY-MM-DD")
                }
                value={
                  dateRange.dateEnd
                    ? moment(dateRange.dateEnd, "YYYY-MM-DD")
                    : undefined
                }
              />
            </FormItem>
            {mouCategoryTableDataByPeriod.isLoading && (
              <FormItem>
                <LoadingOutlined
                  style={{ fontSize: "24px", color: "#65aeff" }}
                />
              </FormItem>
            )}
          </Space>
        </Row>
        <MouCategoryTable
          reportType={activeReportType}
          ptdData={mouCategoryTableDataPTD.data || []}
          byPeriodData={mouCategoryTableDataByPeriod.data || []}
        />
      </Card>
    </>
  );
};
