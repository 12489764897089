import { useMutation } from "react-query";

import { AxiosError } from "axios";
import { FormikErrors } from "formik";

import { notification } from "antd";

import {
  isValidationError,
  transformValidationError
} from "@utils/errorHelper";

const useValidatedMutation = <QueryValues = any>({
  mutationFunction,
  onSuccess,
  onError,
  onSettled
}: {
  mutationFunction: Function;
  onError?: (error: FormikErrors<QueryValues>) => void;
  onSuccess?: (data: any, variables: QueryValues, context: unknown) => void;
  onSettled?: () => void;
}) => {
  const mutation = useMutation<any, AxiosError, QueryValues>(
    (values: QueryValues) => mutationFunction(values),
    {
      onSuccess,
      onSettled,
      onError: (error) => {
        if (isValidationError(error)) {
          onError && onError(transformValidationError(error));
        } else {
          const errData = error.response?.data;
          notification.error({
            message: errData?.message || ""
          });
        }
      }
    }
  );
  return mutation;
};

export default useValidatedMutation;
