export const ruRequests = {
  requests: "Запросы",
  list: "Ваши запросы к Тренинг Центрам",
  tcList: "Ваши запросы от Подрядчиков",
  create: "Создать запрос",
  subject: "Тема",
  lastCommentDate: "Дата последнего ответа",
  numberOfReplies: "Количество ответов",
  commentsCount: "Количество ответов",
  text: "Текст",
  file: "Файл",
  createdAt: "Дата создания",
  createSuccess: "Запрос успешно создан",
  dateFrom: "Дата с",
  dateTo: "Дата по",
  requestId: "ID запроса",
  requestTitle: "Запрос к: {{tcName}}",
  requestFromTitle: "Запрос от: {{contractorName}}",
  requestSubject: "Тема: {{subject}}",
  replies: "Ответы",
  replyPlaceholder: "Начните печатать чтобы ответить",
  reply: "Ответить",
  replyAttachment: "Прикрепите файл",
  requestDate: "Дата запроса",
  filter: "Фильтровать",
  assignee: "Исполнитель",
  attachmentFile: "Прикреплённый файл",
  replySuccess: "Ответ успешно создан"
};
