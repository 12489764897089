import { Helmet } from "react-helmet-async";

interface HelmetProps {
  title: string;
}

export const PageMeta: React.FC<HelmetProps> = ({ title }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title} - CT Portal</title>
    </Helmet>
  );
};

export default PageMeta;
