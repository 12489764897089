import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "antd";
import SelectItemGenerator from "@components/admin/migrations/renderers/SelectItemGenerator";

import {
  TableFormItemGeneratorProps,
  ParticipantResponse
} from "@models/migrations";
import { EventEmployeeGradeType } from "@models/event";

const TableFormItemGenerator: React.FC<TableFormItemGeneratorProps> = ({
  field,
  onBlur,
  formik,
  index,
  instructorsQuery,
  isCommissioning,
  trainingCenterQuery,
  disciplinesQuery,
  employeeContractorRelations,
  disabled,
  cityListQuery,
  selectedMigration
}) => {
  const [t] = useTranslation();
  const [editedInput, setEditedInput] = useState<string | null>(null);

  const inputValue = () => {
    if (editedInput) {
      return editedInput;
    }
    if (editedInput === "") {
      return null;
    }
    return (
      formik.values.participants[index][
        field.name as keyof Omit<
          ParticipantResponse,
          "employeeContractorRelations" | "isRegistered"
        >
      ] || null
    );
  };

  const generateInputValue = useMemo(() => {
    if (
      field.name === "failReason" &&
      Number(formik.values.participants[index].isPassed) !==
        EventEmployeeGradeType.FAILED
    ) {
      setEditedInput(null);
      formik.setFieldValue(`participants[${index}].failReason`, "");
      return "";
    }
    return editedInput === null
      ? formik.values.participants[index][
          field.name as keyof Omit<
            ParticipantResponse,
            "employeeContractorRelations" | "isRegistered"
          >
        ]
      : editedInput;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedInput, field.name, formik.values.participants, index]);

  if (field.select) {
    return (
      <SelectItemGenerator
        name={field.name}
        index={index}
        formik={formik}
        instructorsQuery={instructorsQuery}
        isCommissioning={isCommissioning}
        trainingCenterQuery={trainingCenterQuery}
        disciplinesQuery={disciplinesQuery}
        employeeContractorRelations={employeeContractorRelations}
        cityListQuery={cityListQuery}
        selectedMigration={selectedMigration}
      />
    );
  } else {
    return (
      <Input
        name={`participants[${index}].${field.name}`}
        value={generateInputValue}
        disabled={disabled}
        placeholder={t(`migrations.form.${field.name}`)}
        onChange={(e) => setEditedInput(e.target.value)}
        onBlur={() => onBlur(field.name, inputValue())}
      />
    );
  }
};

export default TableFormItemGenerator;
