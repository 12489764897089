export const ruMigrations = {
  migration: "Миграция",
  migration_other: "Миграции",
  newMigration: "Новая Миграция",

  table: {
    numberOfCraftWorkers: "Количество участников",
    uploadedBy: "Кем загружено"
  },

  form: {
    createMigration: "Создать новую Миграцию",
    migrate: "Мигрировать",

    trainingCenter: "Тренинг Центр",
    eventName: "Название события",
    numberOfSeats: "Количество мест",
    minimumSeats: "Минимум мест",
    academicHours: "Академические часы",
    discipline: "Дисциплина",
    language: "Язык",
    csrPhase: "Фаза КСО",
    description: "Описание",
    city: "Город",
    location: "Локация",
    availableFor: "Доступно для",
    standard: "Стандарт",
    hoursPerDay: "Часы в день",
    start: "Дата начала",
    end: "Дата окончания",
    instructorId: "Инструктор",

    employeeId: "Подрядчик",

    firstName: "Имя",
    lastName: "Фамилия",
    iin: "ИИН",
    contractor: "Подрядчик",
    subcontractor: "Субподрядчик",
    practicalGrade: "Практическая оценка",
    practicalStatus: "Статус по практике",
    theoreticalGrade: "Теоритическая оценка",
    theoreticalStatus: "Статус по теории",
    isPassed: "Общая оценка",
    failReason: "Причина провала",
    failReasonType: "Тип причины провала",
    other: "Другое",
    recommendation: "Рекомендация",
    isCompleted: "Статус завершения",

    gradePassed: "Зачет",
    gradeFailed: "Незачет",

    iinTooltip:
      "ИИН данных пользователей не был найден в системе или был введен некорректно, пользователи не будут добавлены в текущую миграцию",

    competencyLevel: "Уровень компетентности",
    competencyLevels: {
      null: "–",

      0: "–",
      1: "Незачет",
      2: "Зачет",
      3: "Базовый",
      4: "Средний",
      5: "Продвинутый",
      6: "Компетентный",
      7: "Профессиональный",
      8: "Помощник",
      20: "Обучаемый техник",
      22: "Младший техник",
      24: "Техник",
      26: "Старший техник"
    },

    completionStatuses: {
      1: "Не завершенные",
      2: "Завершенные"
    }
  },

  registeredUsers: "Зарегистирорванные пользователи",
  notRegisteredUsers: "Незарегистрированные пользователи",
  migrationCreateSuccess: "Миграция успешно создана",
  migrationDeleteSuccess: "Миграция успешно удалена",
  deleteConfirmation: "Вы уверены, что хотите удалить миграцию {{name}}?"
};
