import { UseMutationResult } from "react-query";
import { useTranslation } from "react-i18next";

import { differenceInHours, parseISO } from "date-fns";
import { AxiosError, AxiosResponse } from "axios";

import { Button, Popconfirm, Space, Tooltip } from "antd";
import { DeleteOutlined, RedoOutlined } from "@ant-design/icons";

import { Member } from "@models/member";

type UseMutationAxios = UseMutationResult<
  AxiosResponse<any>,
  AxiosError<any>,
  string
>;

interface InvitedMemberActionsProps {
  member: Member;
  deleteInviteMutation: UseMutationAxios;
  resendInviteMutation: UseMutationAxios;
}

const InvitedMemberActions: React.FC<InvitedMemberActionsProps> = (props) => {
  const [t] = useTranslation();
  const inviteDate = parseISO(props.member.invitedAt);
  const hourDiff = differenceInHours(Date.now(), inviteDate);
  const isInviteExpired = hourDiff >= 24;

  return (
    <Space size="middle">
      <Tooltip
        title={
          isInviteExpired
            ? t("members.resendPossible")
            : t("members.previousInviteNotExpired")
        }
      >
        <Button
          type="primary"
          shape="circle"
          icon={<RedoOutlined />}
          disabled={!isInviteExpired || props.resendInviteMutation.isLoading}
          loading={
            props.resendInviteMutation.isLoading &&
            props.resendInviteMutation.variables === props.member.email
          }
          onClick={() => props.resendInviteMutation.mutate(props.member.email)}
        />
      </Tooltip>
      <Popconfirm
        title={t("members.confirmDelete", {
          name: props.member.firstName + " " + props.member.lastName
        })}
        cancelText={t("cancel")}
        okText={t("yes")}
        disabled={props.deleteInviteMutation.isLoading}
        onConfirm={() => props.deleteInviteMutation.mutate(props.member.email)}
      >
        <Button
          title={t("members.delete")}
          danger
          shape="circle"
          disabled={props.deleteInviteMutation.isLoading}
          loading={
            props.deleteInviteMutation.isLoading &&
            props.deleteInviteMutation.variables === props.member.email
          }
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </Space>
  );
};

export default InvitedMemberActions;
