export enum MouCategory {
  ENGINEERING_MANAGEMENT = 1,
  SUPERVISOR_AND_FOREMAN = 2,
  HEAVY_EQUIPMENT_OPERATOR = 3,
  CRAFT = 4
}

export enum MouCategoryKey {
  ENGINEERING_MANAGEMENT = "engineeringManagement",
  SUPERVISOR_AND_FOREMAN = "supervisor",
  HEAVY_EQUIPMENT_OPERATOR = "heavyEquipment",
  CRAFT = "other"
}
