import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "antd";
import {
  FAIL_REASON_OPTIONS,
  RECOMMENDATION_OPTIONS
} from "@components/common/reports/CommissioningReportFilterForm";

import { LanguageCode } from "@models/languageCode";
import {
  EmployeeContractorRelation,
  MigrationTypes,
  OverallGrades,
  ParticipantResponse,
  SelectItemGeneratorProps
} from "@models/migrations";
import { CompetencyType } from "@models/discipline";
import { EventEmployeeGradeType } from "@models/event";

import { COMPETENCY_LEVEL_SELECT_OPTIONS } from "@constants/competencyLevelSelectOptions";
import { CSR_PHASE_OPTIONS } from "@constants/csrPhases";
import {
  EMPLOYEE_GRADE_TYPES,
  EMPLOYEE_GRADE_TYPES_BY_PARTICIPANT
} from "@constants/EmployeeGradeOptions";

const { Option } = Select;

const SelectItemGenerator: React.FC<SelectItemGeneratorProps> = ({
  name,
  formik,
  index,
  instructorsQuery,
  trainingCenterQuery,
  disciplinesQuery,
  employeeContractorRelations,
  cityListQuery,
  isCommissioning,
  selectedMigration
}) => {
  const [t, i18n] = useTranslation();

  const showEmployeeGradeTypesByParticipant = useMemo(
    () =>
      selectedMigration === MigrationTypes.COMMISSIONING_ASSESSMENT ||
      selectedMigration === MigrationTypes.ASSESSMENT,
    [selectedMigration]
  );

  const getFilteredEmployee = useMemo(() => {
    const filteredEmployee =
      index !== undefined &&
      employeeContractorRelations?.find((employee) => {
        return (
          employee.employeeId ===
          Number(
            formik.values.participants[index][
              name as keyof Omit<
                ParticipantResponse,
                "employeeContractorRelations" | "isRegistered"
              >
            ]
          )
        );
      });
    if (filteredEmployee) {
      return `${filteredEmployee.contractorName} - ${t(
        "employees.contract"
      )}: ${filteredEmployee.contractName}`;
    }
    return "-";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeContractorRelations, formik.values.participants, t]);

  const resetFailReason = (value: number) => {
    if (value !== EventEmployeeGradeType.FAILED) {
      formik.setFieldValue(`participants[${index}].failReasonType`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  switch (name) {
    case "trainingCenterId":
      return (
        <Select
          value={formik.values.event[name] || "-"}
          onChange={(v) => formik.setFieldValue(`event.${name}`, v)}
          loading={trainingCenterQuery.isLoading}
        >
          {trainingCenterQuery.data &&
            trainingCenterQuery.data.map((tc) => (
              <Option key={tc.id} value={tc.id}>
                {tc.name}
              </Option>
            ))}
        </Select>
      );
    case "specialityId":
      return (
        <Select
          showSearch
          filterOption={(inputValue, option) =>
            option?.props.children
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          }
          value={formik.values.event[name] || "-"}
          onChange={(v) => formik.setFieldValue(`event.${name}`, v)}
          loading={disciplinesQuery.isLoading}
        >
          {!isCommissioning
            ? disciplinesQuery.data &&
              disciplinesQuery.data.map((discipline) => (
                <Option key={discipline.id} value={discipline.id}>
                  {i18n.language === "en"
                    ? discipline.nameEn
                    : discipline.nameRu}
                </Option>
              ))
            : disciplinesQuery.data
                ?.filter(
                  (d) => d.competencyType === CompetencyType.COMMISSIONING
                )
                .map((discipline) => (
                  <Option key={discipline.id} value={discipline.id}>
                    {i18n.language === "en"
                      ? discipline.nameEn
                      : discipline.nameRu}
                  </Option>
                ))}
        </Select>
      );
    case "instructorId":
      return (
        <Select
          showSearch
          filterOption={(inputValue, option) =>
            option?.props.children
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          }
          value={formik.values.event[name] || "-"}
          onChange={(v) => formik.setFieldValue(`event.${name}`, v)}
          loading={instructorsQuery.isLoading}
        >
          {instructorsQuery.data?.map((inst) => (
            <Option value={inst.id} key={inst.id}>
              {`${inst.firstName} ${inst.lastName}`}
            </Option>
          ))}
        </Select>
      );
    case "csrPhase":
      return (
        <Select
          value={formik.values.event[name] || "-"}
          onChange={(v) => formik.setFieldValue(`event.${name}`, v)}
        >
          {CSR_PHASE_OPTIONS.map((cp) => (
            <Option value={cp.value} key={cp.value}>
              {t(cp.label, { no: cp.value })}
            </Option>
          ))}
        </Select>
      );
    case "language":
      return (
        <Select
          value={formik.values.event[name] || "-"}
          onChange={(v) => formik.setFieldValue(`event.${name}`, v)}
        >
          <Option key={LanguageCode.RU} value={LanguageCode.RU}>
            {t(`languages.${LanguageCode.RU}`)}
          </Option>
          <Option key={LanguageCode.EN} value={LanguageCode.EN}>
            {t(`languages.${LanguageCode.EN}`)}
          </Option>
          <Option key={LanguageCode.KK} value={LanguageCode.KK}>
            {t(`languages.${LanguageCode.KK}`)}
          </Option>
        </Select>
      );
    case "cityId":
      return (
        <Select
          showSearch
          filterOption={(inputValue, option) =>
            option?.props.children
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          }
          value={formik.values.event[name] || "-"}
          onChange={(v) => formik.setFieldValue(`event.${name}`, v)}
          loading={cityListQuery.isLoading}
          style={{ width: "100%" }}
        >
          {cityListQuery.data &&
            cityListQuery.data.map((city) => (
              <Option key={city.id} value={city.id}>
                {i18n.language === "ru" ? city.nameRu : city.nameEn}
              </Option>
            ))}
        </Select>
      );
    case "competencyLevel":
      return (
        <Select
          value={
            (index !== undefined &&
              formik.values.participants[index][
                name as keyof ParticipantResponse
              ]) ||
            "-"
          }
          onChange={(v) =>
            formik.setFieldValue(`participants[${index}].${name}`, v)
          }
          style={{ width: "100%" }}
        >
          {COMPETENCY_LEVEL_SELECT_OPTIONS.map((cl) => (
            <Option key={cl.value} value={cl.value}>
              {t(cl.label)}
            </Option>
          ))}
        </Select>
      );
    case "recommendation":
      return (
        <Select
          value={
            (index !== undefined &&
              formik.values.participants[index][
                name as keyof Omit<
                  ParticipantResponse,
                  "employeeContractorRelations" | "isRegistered"
                >
              ]) ||
            "-"
          }
          onChange={(v) =>
            formik.setFieldValue(`participants[${index}].${name}`, v)
          }
          style={{ width: "100%" }}
        >
          {RECOMMENDATION_OPTIONS.map((ro) => (
            <Option key={ro.value} value={ro.value}>
              {t(ro.label)}
            </Option>
          ))}
        </Select>
      );
    case "employeeId":
      return (
        <Select
          value={getFilteredEmployee}
          onChange={(value: string | EmployeeContractorRelation[]) => {
            const { employeeId, contractorId } = JSON.parse(value as string);

            formik.setFieldValue(`participants[${index}]`, {
              ...formik.values.participants[index as number],
              employeeId,
              contractorId
            });
          }}
          style={{ width: "100%" }}
        >
          {employeeContractorRelations?.map((ecr) => (
            <Option
              key={ecr.employeeId}
              value={JSON.stringify({
                employeeId: ecr.employeeId,
                contractorId: ecr.contractorId
              })}
            >
              {`${ecr.contractorName} - ${t("employees.contract")}: ${
                ecr.contractName
              }`}
            </Option>
          ))}
        </Select>
      );
    case "isPassed":
      return (
        <Select
          value={
            (index !== undefined &&
              formik.values.participants[index][
                name as keyof ParticipantResponse
              ]) ||
            "-"
          }
          onChange={(v) => {
            formik.setFieldValue(`participants[${index}].${name}`, v);
            resetFailReason(Number(v));
          }}
        >
          {showEmployeeGradeTypesByParticipant ? (
            <>
              {EMPLOYEE_GRADE_TYPES_BY_PARTICIPANT.map((item) => (
                <Option key={item.value} value={item.value}>
                  {t(item.label)}
                </Option>
              ))}
            </>
          ) : (
            <>
              {EMPLOYEE_GRADE_TYPES.map((item) => (
                <Option key={item.value} value={item.value}>
                  {t(item.label)}
                </Option>
              ))}
            </>
          )}
        </Select>
      );
    case "failReasonType":
      return (
        <Select
          value={
            (index !== undefined &&
              formik.values.participants[index][
                name as keyof ParticipantResponse
              ]) ||
            "-"
          }
          disabled={
            index !== undefined &&
            Number(formik.values.participants[index]["isPassed"]) !==
              EventEmployeeGradeType.FAILED
          }
          onChange={(v) =>
            formik.setFieldValue(`participants[${index}].${name}`, v)
          }
        >
          {FAIL_REASON_OPTIONS.map((fro) => (
            <Option key={fro.value} value={fro.value}>
              {t(fro.label)}
            </Option>
          ))}
        </Select>
      );
    case "practicalStatus":
    case "theoreticalStatus":
      return (
        <Select
          value={
            index !== undefined &&
            formik.values.participants[index][name as keyof ParticipantResponse]
          }
          onChange={(v) =>
            formik.setFieldValue(`participants[${index}].${name}`, v)
          }
        >
          <Option key={OverallGrades.FAILED} value={OverallGrades.FAILED}>
            {t("migrations.form.gradeFailed")}
          </Option>
          <Option key={OverallGrades.PASSED} value={OverallGrades.PASSED}>
            {t("migrations.form.gradePassed")}
          </Option>
        </Select>
      );
    default:
      return null;
  }
};

export default SelectItemGenerator;
