import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import moment from "moment";

import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table
} from "antd";

import { MouCategory } from "@models/mouCategory";
import { Country } from "@models/countryCity";
import {
  CreateEmployeePayload,
  EmployeeConversionResult
} from "@models/employee";

import useDateFormatter from "@hooks/useDateFormat";

interface Props {
  employees?: EmployeeConversionResult[];
  errors?: any;

  contractors?: { id: number; name: string }[];
  contracts?: { id: number; contractNumber: string }[];
  countries?: Country[];

  isLoading?: boolean;

  onCancel?: () => void;
  onSubmit?: (pl: Partial<CreateEmployeePayload>[]) => void;
}

const Column = Table.Column;

const MOU_CATEGORY_OPTIONS = [
  {
    labelKey: `mouCategory.${MouCategory.ENGINEERING_MANAGEMENT}`,
    value: MouCategory.ENGINEERING_MANAGEMENT
  },
  {
    labelKey: `mouCategory.${MouCategory.SUPERVISOR_AND_FOREMAN}`,
    value: MouCategory.SUPERVISOR_AND_FOREMAN
  },
  {
    labelKey: `mouCategory.${MouCategory.HEAVY_EQUIPMENT_OPERATOR}`,
    value: MouCategory.HEAVY_EQUIPMENT_OPERATOR
  },
  { labelKey: `mouCategory.${MouCategory.CRAFT}`, value: MouCategory.CRAFT }
];

export const EmployeeBatchEditForm = (props: Props) => {
  const [t, i18n] = useTranslation();
  const formatter = useDateFormatter();

  const [editedRow, setEditedRow] = useState<number>();
  const formik = useFormik<{ employees: EmployeeConversionResult[] }>({
    initialValues: {
      employees: []
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values.employees);
      }
    }
  });

  const deleteRow = (ind: number) => {
    const employees = [...formik.values.employees];
    employees.splice(ind, 1);

    formik.setFieldValue("employees", employees);
  };

  const countryMap = useMemo(() => {
    return (
      props.countries?.reduce<{ [id: string]: Country }>((prev, next) => {
        return {
          ...prev,
          [next.id]: i18n.language === "en" ? next.nameEn : next.nameRu
        };
      }, {}) || {}
    );

    // eslint-disable-next-line
  }, [props.countries]);

  const contractMap = useMemo(() => {
    return (
      props.contracts?.reduce<{
        [id: string]: { contract_number: string; id: number };
      }>((prev, next) => {
        return { ...prev, [next.id]: next.contractNumber };
      }, {}) || {}
    );

    // eslint-disable-next-line
  }, [props.contracts]);

  const contractorMap = useMemo(() => {
    return (
      props.contractors?.reduce<{
        [id: string]: { name: string; id: number };
      }>((prev, next) => {
        return { ...prev, [next.id]: next.name };
      }, {}) || {}
    );

    // eslint-disable-next-line
  }, [props.contracts]);

  useEffect(() => {
    if (props.employees) {
      formik.setValues({
        employees: [...props.employees.map((e, i) => ({ ...e, _id: i }))]
      });
    }

    // eslint-disable-next-line
  }, [props.employees]);

  return (
    <div>
      <Table
        dataSource={formik.values.employees}
        pagination={false}
        bordered
        rowKey="_id"
        scroll={{ x: 3600, y: "auto" }}
      >
        <Column
          title={t("employees.lastName")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].LastName`] && "error"
                }
                help={props.errors[`employees[${i}].LastName`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].lastName`}
                    value={e.lastName}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.lastName || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.firstName")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].FirstName`] && "error"
                }
                help={props.errors[`employees[${i}].FirstName`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].firstName`}
                    value={e.firstName}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.firstName || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.middleName")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].MiddleName`] && "error"
                }
                help={props.errors[`employees[${i}].MiddleName`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].middleName`}
                    value={e.middleName}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.middleName || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.firstNameCyrillic")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].FirstNameCyrillic`] && "error"
                }
                help={props.errors[`employees[${i}].FirstNameCyrillic`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].firstNameCyrillic`}
                    value={e.firstNameCyrillic}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.firstNameCyrillic || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.lastNameCyrillic")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].LastNameCyrillic`] && "error"
                }
                help={props.errors[`employees[${i}].LastNameCyrillic`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].lastNameCyrillic`}
                    value={e.lastNameCyrillic}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.lastNameCyrillic || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.middleNameCyrillic")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].MiddleNameCyrillic`] && "error"
                }
                help={props.errors[`employees[${i}].MiddleNameCyrillic`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].middleNameCyrillic`}
                    value={e.middleNameCyrillic}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.middleNameCyrillic || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.iin")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={props.errors[`employees[${i}].Iin`] && "error"}
                help={props.errors[`employees[${i}].Iin`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].iin`}
                    value={e.iin}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.iin || "-"
                )}
              </Form.Item>
            );
          }}
        />
        <Column
          title={t("employees.phoneNumber")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].PhoneNumber`] && "error"
                }
                help={props.errors[`employees[${i}].PhoneNumber`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].phoneNumber`}
                    value={e.phoneNumber}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.phoneNumber || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.contract")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].ContractId`] && "error"
                }
                help={props.errors[`employees[${i}].ContractId`]}
              >
                {editing ? (
                  <Select
                    value={e.contractId || undefined}
                    showSearch
                    optionFilterProp="contractNumber"
                    filterOption={(inp, opt) => {
                      if (opt && opt.children) {
                        return (
                          opt.children
                            .toString()
                            .toLowerCase()
                            .indexOf(inp.toLowerCase()) >= 0
                        );
                      }

                      return false;
                    }}
                    onChange={(v) =>
                      formik.setFieldValue(`employees[${i}].contractId`, v)
                    }
                  >
                    {props.contracts?.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.contractNumber}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  contractMap[e.contractId || 0] || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.contractor")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].ContractorId`] && "error"
                }
                help={props.errors[`employees[${i}].ContractorId`]}
              >
                {editing ? (
                  <Select
                    value={e.contractorId || undefined}
                    showSearch
                    optionFilterProp="name"
                    filterOption={(inp, opt) => {
                      if (opt && opt.children) {
                        return (
                          opt.children
                            .toString()
                            .toLowerCase()
                            .indexOf(inp.toLowerCase()) >= 0
                        );
                      }

                      return false;
                    }}
                    onChange={(v) =>
                      formik.setFieldValue(`employees[${i}].contractorId`, v)
                    }
                  >
                    {props.contractors?.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  contractorMap[e.contractorId || ""] || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.birthDate")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            const _value = e.birthDate;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].BirthDate`] && "error"
                }
                help={props.errors[`employees[${i}].BirthDate`]}
              >
                {editing ? (
                  <DatePicker
                    placeholder=""
                    style={{ width: "100%" }}
                    value={_value ? moment(_value) : null}
                    onChange={(v) =>
                      formik.setFieldValue(
                        `employees[${i}].birthDate`,
                        v?.toISOString()
                      )
                    }
                  />
                ) : _value ? (
                  formatter.formatter(_value, "full-date")
                ) : (
                  "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.citizenship")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].CitizenshipId`] && "error"
                }
                help={props.errors[`employees[${i}].CitizenshipId`]}
              >
                {editing ? (
                  <Select
                    value={e.citizenshipId || undefined}
                    showSearch
                    optionFilterProp="name"
                    filterOption={(inp, opt) => {
                      if (opt && opt.children) {
                        return (
                          opt.children
                            .toString()
                            .toLowerCase()
                            .indexOf(inp.toLowerCase()) >= 0
                        );
                      }

                      return false;
                    }}
                    onChange={(v) =>
                      formik.setFieldValue(`employees[${i}].citizenshipId`, v)
                    }
                  >
                    {props.countries?.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {i18n.language === "en" ? c.nameEn : c.nameRu}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  countryMap[e.citizenshipId || 0] || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.residenceCountry")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].ResidenceCountryId`] && "error"
                }
                help={props.errors[`employees[${i}].ResidenceCountryId`]}
              >
                {editing ? (
                  <Select
                    value={e.residenceCountryId || undefined}
                    showSearch
                    optionFilterProp="name"
                    filterOption={(inp, opt) => {
                      if (opt && opt.children) {
                        return (
                          opt.children
                            .toString()
                            .toLowerCase()
                            .indexOf(inp.toLowerCase()) >= 0
                        );
                      }

                      return false;
                    }}
                    onChange={(v) =>
                      formik.setFieldValue(
                        `employees[${i}].residenceCountryId`,
                        v
                      )
                    }
                  >
                    {props.countries?.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {i18n.language === "en" ? c.nameEn : c.nameRu}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  countryMap[e.residenceCountryId || 0] || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.residenceRegion")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].ResidenceRegion`] && "error"
                }
                help={props.errors[`employees[${i}].ResidenceRegion`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].residenceRegion`}
                    value={e.residenceRegion}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.residenceRegion || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.mouCategory")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;
            const _text = e.mouCategory
              ? t(`mouCategory.${e.mouCategory}`)
              : "-";

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].MouCategory`] && "error"
                }
                help={props.errors[`employees[${i}].MouCategory`]}
              >
                {editing ? (
                  <Select
                    value={e.mouCategory || undefined}
                    onChange={(v) =>
                      formik.setFieldValue(`employees[${i}].mouCategory`, v)
                    }
                  >
                    {MOU_CATEGORY_OPTIONS.map((c) => (
                      <Select.Option key={c.value} value={c.value}>
                        {t(c.labelKey)}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  _text
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.position")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].Position`] && "error"
                }
                help={props.errors[`employees[${i}].Position`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].position`}
                    value={e.position}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.position || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.firstNameCompanyRep")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].FirstNameCompanyRep`] && "error"
                }
                help={props.errors[`employees[${i}].FirstNameCompanyRep`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].firstNameCompanyRep`}
                    value={e.firstNameCompanyRep}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.firstNameCompanyRep || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.lastNameCompanyRep")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].LastNameCompanyRep`] && "error"
                }
                help={props.errors[`employees[${i}].LastNameCompanyRep`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].lastNameCompanyRep`}
                    value={e.lastNameCompanyRep}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.lastNameCompanyRep || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.phoneNumberCompanyRep")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].PhoneNumberCompanyRep`] &&
                  "error"
                }
                help={props.errors[`employees[${i}].PhoneNumberCompanyRep`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].phoneNumberCompanyRep`}
                    value={e.phoneNumberCompanyRep}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.phoneNumberCompanyRep || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.lineSupervisorBadgeNumber")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].LineSupervisorBadgeNumber`] &&
                  "error"
                }
                help={props.errors[`employees[${i}].LineSupervisorBadgeNumber`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].lineSupervisorBadgeNumber`}
                    value={e.lineSupervisorBadgeNumber}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.lineSupervisorBadgeNumber || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.workLocationArea")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].WorkLocationArea`] && "error"
                }
                help={props.errors[`employees[${i}].WorkLocationArea`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].workLocationArea`}
                    value={e.workLocationArea}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.workLocationArea || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.facilityAccessStandard")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].FacilityAccessStandard`] &&
                  "error"
                }
                help={props.errors[`employees[${i}].FacilityAccessStandard`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].facilityAccessStandard`}
                    value={e.facilityAccessStandard}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.facilityAccessStandard || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.facilityAccessAdditional")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].FacilityAccessAdditional`] &&
                  "error"
                }
                help={props.errors[`employees[${i}].FacilityAccessAdditional`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].facilityAccessAdditional`}
                    value={e.facilityAccessAdditional}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.facilityAccessAdditional || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.specialAccessJustification")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].SpecialAccessJustification`] &&
                  "error"
                }
                help={
                  props.errors[`employees[${i}].SpecialAccessJustification`]
                }
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].specialAccessJustification`}
                    value={e.specialAccessJustification}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.specialAccessJustification || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.waterTreatmentPlant")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].WaterTreatmentPlant`] && "error"
                }
                help={props.errors[`employees[${i}].WaterTreatmentPlant`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].waterTreatmentPlant`}
                    value={e.waterTreatmentPlant}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.waterTreatmentPlant || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("employees.residentialAreas")}
          render={(_, e: EmployeeConversionResult, i) => {
            const editing = i === editedRow;

            return (
              <Form.Item
                labelCol={{ span: 24 }}
                validateStatus={
                  props.errors[`employees[${i}].ResidentialAreas`] && "error"
                }
                help={props.errors[`employees[${i}].ResidentialAreas`]}
              >
                {editing ? (
                  <Input
                    name={`employees[${i}].residentialAreas`}
                    value={e.residentialAreas}
                    onChange={formik.handleChange}
                  />
                ) : (
                  e.residentialAreas || "-"
                )}
              </Form.Item>
            );
          }}
        />

        <Column
          title={t("actions")}
          fixed="right"
          width={100}
          render={(_, __, i) => {
            const editing = i === editedRow;

            return (
              <Space>
                {editing ? (
                  <Space>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<CheckOutlined />}
                      onClick={() => setEditedRow(undefined)}
                    />
                  </Space>
                ) : (
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => setEditedRow(i)}
                  />
                )}
                <Button
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => deleteRow(i)}
                />
              </Space>
            );
          }}
        />
      </Table>

      <br />

      <Row justify="space-between">
        <Col>
          <Button onClick={props.onCancel}>{t("cancel")}</Button>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={formik.submitForm}
            loading={props.isLoading}
          >
            {t("submit")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
