import React from "react";
import { Link } from "react-router-dom";

import { Button } from "antd";

export const BreadcrumbRenderer = (
  route: any,
  params: any,
  routes: any,
  paths: any
) => {
  const last = routes.indexOf(route) === routes.length - 1;

  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={"/" + paths.join("/")}>
      <Button type="link" style={{ padding: 0 }}>
        {route.breadcrumbName}
      </Button>
    </Link>
  );
};
