import axios from "axios";

import { City } from "@models/countryCity";

const getCities = () => axios.get<City[]>("/api/city");

const getCity = (id: number) => axios.get<City>(`/api/city/${id}`);

export const cityApi = {
  getCities,
  getCity
};
