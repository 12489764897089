import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Col, Row, Typography } from "antd";

type WhatNewTempalateProps = {
  whatNews: ReadonlyArray<{
    title: string;
    html: string;
  }>;
};

export const WhatNewTemplate: FC<WhatNewTempalateProps> = ({ whatNews }) => {
  const { t } = useTranslation();

  return (
    <>
      {whatNews.map((item) => (
        <Row>
          <Col span={12}>
            <Typography.Title level={3}>{item.title}</Typography.Title>
            <Typography.Text>
              <div dangerouslySetInnerHTML={{ __html: item.html }}></div>
            </Typography.Text>
          </Col>
        </Row>
      ))}
    </>
  );
};
