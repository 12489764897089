import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Col, Form, Input, Row, Space } from "antd";

import { TrainingCenterMember } from "@models/trainingCenterMember";

interface FormValues {
  trainingCenterId: number;
  userId?: number;
  email?: string;
  firstName: string;
  lastName: string;
  position: string;
  phoneNumber: string;
}

interface Props {
  trainingCenterId: number;
  trainingCenterMember?: TrainingCenterMember | null;
  errors?: { [key: string]: string };
  isLoading?: boolean;
  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

const TrainingCenterMemberForm: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  const formik = useFormik<FormValues>({
    initialValues: {
      trainingCenterId: props.trainingCenterId,
      email: "",
      firstName: "",
      lastName: "",
      position: "",
      phoneNumber: ""
    },
    onSubmit: (values) => {
      if (props.onSubmit) {
        if (props.trainingCenterMember) {
          const { email, ...editPayload } = values;
          props.onSubmit({
            ...editPayload,
            trainingCenterId: props.trainingCenterId,
            userId: props.trainingCenterMember?.userId || 0
          });
        } else {
          props.onSubmit({
            ...values,
            email: values.email || "",
            trainingCenterId: props.trainingCenterId
          });
        }
      }
    }
  });

  useEffect(() => {
    if (props.trainingCenterMember) {
      formik.setValues({
        trainingCenterId: props.trainingCenterId,
        firstName: props.trainingCenterMember?.firstName || "",
        lastName: props.trainingCenterMember?.lastName || "",
        position: props.trainingCenterMember?.position || "",
        phoneNumber: props.trainingCenterMember?.phoneNumber || ""
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trainingCenterMember]);

  useEffect(() => {
    if (props.errors) {
      formik.setErrors({ ...props.errors });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  return (
    <Form layout="vertical" onSubmitCapture={() => formik.submitForm()}>
      <Row gutter={8}>
        {!props.trainingCenterMember && (
          <Col md={24}>
            <Form.Item
              label={t("email")}
              labelCol={{ span: 24 }}
              validateStatus={formik.errors.email && "error"}
              help={formik.errors.email}
            >
              <Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </Form.Item>
          </Col>
        )}

        <Col md={12}>
          <Form.Item
            label={t("firstName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.firstName && "error"}
            help={formik.errors.firstName}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("lastName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.lastName && "error"}
            help={formik.errors.lastName}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("jobTitle")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.position && "error"}
            help={formik.errors.position}
          >
            <Input
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            label={t("phoneNumber")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.phoneNumber && "error"}
            help={formik.errors.phoneNumber}
          >
            <Input
              name="phoneNumber"
              placeholder="87001112233"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={props.onCancel}>
                    {t("cancel")}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={props.isLoading}
                  >
                    {t("save")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TrainingCenterMemberForm;
