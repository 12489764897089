import React, { useMemo } from "react";

import { NonCommissioningEventGrades } from "@components/training-center/events/NonCommissioningEventGrades";
import { CommissioningEventGrades } from "@components/training-center/events/CommissioningEventGrades";
import { OjtEventGrades } from "@components/training-center/events/OjtEventGrades";
import { PrescreeningEventGrades } from "@components/training-center/events/PrescreeninngEventGrades";

import {
  EventDetails,
  EventEmployeeDetails,
  EventEmployeeStatus,
  EventStatus,
  EventType
} from "@models/event";

interface Props {
  errors?: { [key: string]: string };
  isLoading?: boolean;
  eventData?: EventDetails;
  eventEmployees?: EventEmployeeDetails[];
  onSubmit?: (values: any[]) => void;
}

export const EventGrades = (props: Props) => {
  const employees = useMemo(
    () =>
      props.eventEmployees?.filter(
        (e) => e.status === EventEmployeeStatus.REGISTERED
      ),
    [props.eventEmployees]
  );

  if (props.eventData?.type === EventType.OJT_TRAINING) {
    return (
      <OjtEventGrades
        editable={props.eventData?.status === EventStatus.COMPLETED}
        errors={props.errors}
        isLoading={props.isLoading}
        eventEmployees={employees}
        onSubmit={(v) => props.onSubmit && props.onSubmit(v)}
      />
    );
  } else if (props.eventData?.type === EventType.PRESCREENING) {
    return (
      <PrescreeningEventGrades
        editable={props.eventData?.status === EventStatus.COMPLETED}
        errors={props.errors}
        isLoading={props.isLoading}
        eventEmployees={employees}
        onSubmit={(v) => props.onSubmit && props.onSubmit(v)}
      />
    );
  }

  return (
    <>
      {!props.eventData?.isCommissioning && (
        <NonCommissioningEventGrades
          editable={props.eventData?.status === EventStatus.COMPLETED}
          editablePracticalGrade={
            props.eventData?.status === EventStatus.ACTIVATED
          }
          errors={props.errors}
          isLoading={props.isLoading}
          eventData={props.eventData}
          eventEmployees={employees}
          onSubmit={(v) => props.onSubmit && props.onSubmit(v)}
        />
      )}

      {props.eventData?.isCommissioning && (
        <CommissioningEventGrades
          editable={props.eventData.status === EventStatus.COMPLETED}
          editablePracticalGrade={
            props.eventData?.status === EventStatus.ACTIVATED
          }
          errors={props.errors}
          isLoading={props.isLoading}
          eventData={props.eventData}
          eventEmployees={employees}
          onSubmit={(v) => props.onSubmit && props.onSubmit(v)}
        />
      )}
    </>
  );
};
