import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Card, Col, Row, Space } from "antd";
import ReportCombinedBarChart from "@components/ui/charts/ReportCombinedChart";

import { EventType } from "@models/event";
import { MouCategoryKey } from "@models/mouCategory";
import {
  MouCategoryReportChartData,
  MouCategoryReportType
} from "@models/report";

import { getMouCategoryChartDataByEventType } from "@utils/reportChartsHelper";

const CHART_DATASETS_CONFIGS: { [key: string]: any } = {
  [MouCategoryKey.ENGINEERING_MANAGEMENT]: {
    label: "Engineering and management",
    fill: false,
    yAxisID: "y-axis-1",
    backgroundColor: "#C27428",
    barThickness: 25
  },
  [MouCategoryKey.SUPERVISOR_AND_FOREMAN]: {
    label: "Supervisor and foreman",
    fill: false,
    yAxisID: "y-axis-1",
    backgroundColor: "#A66999",
    barThickness: 25
  },
  [MouCategoryKey.HEAVY_EQUIPMENT_OPERATOR]: {
    label: "Heavy equipment",
    fill: false,
    yAxisID: "y-axis-1",
    backgroundColor: "#01B8AA",
    barThickness: 25
  },
  [MouCategoryKey.CRAFT]: {
    label: "Craft, others",
    fill: false,
    yAxisID: "y-axis-1",
    backgroundColor: "#F2C80F",
    barThickness: 25
  },
  total: {
    label: "Total",
    type: "line",
    fill: false,
    yAxisID: "y-axis-2",
    backgroundColor: "#049AFF",
    borderColor: "#049AFF",
    borderWidth: 1,
    borderDash: [5, 5]
  }
};

const POSITION_MOUCATEGORY_KEYS = [
  MouCategoryKey.ENGINEERING_MANAGEMENT,
  MouCategoryKey.SUPERVISOR_AND_FOREMAN,
  MouCategoryKey.HEAVY_EQUIPMENT_OPERATOR,
  MouCategoryKey.CRAFT
];

const DISCIPLINE_MOUCATEGORY_KEYS = [
  MouCategoryKey.SUPERVISOR_AND_FOREMAN,
  MouCategoryKey.HEAVY_EQUIPMENT_OPERATOR,
  MouCategoryKey.CRAFT
];

interface Props {
  reportType: MouCategoryReportType;
  eventType: EventType;
  report: MouCategoryReportChartData[];
}

export const MouCategoryChart: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [mouCategoryKeys, setMouCategoryKeys] = useState(
    props.reportType === MouCategoryReportType.DISCIPLINE
      ? DISCIPLINE_MOUCATEGORY_KEYS
      : POSITION_MOUCATEGORY_KEYS
  );

  useEffect(() => {
    switch (props.reportType) {
      case MouCategoryReportType.DISCIPLINE:
        setMouCategoryKeys(DISCIPLINE_MOUCATEGORY_KEYS);
        break;
      case MouCategoryReportType.POSITION:
        setMouCategoryKeys(POSITION_MOUCATEGORY_KEYS);
        break;
      default:
        setMouCategoryKeys(DISCIPLINE_MOUCATEGORY_KEYS);
    }
  }, [props.reportType]);

  const chartData = useMemo(
    () =>
      getMouCategoryChartDataByEventType(
        props.eventType,
        mouCategoryKeys,
        props.report
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.reportType, props.report, mouCategoryKeys]
  );

  const totalMaxValue = Math.max(
    ...(chartData.datasets.find((d) => d.key === "total")?.values || [])
  );

  const chartDatasetsWithConfigs = useMemo(
    () =>
      chartData.datasets.map((d) => ({
        ...CHART_DATASETS_CONFIGS[d.key],
        data: d.values
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.reportType, props.report, mouCategoryKeys]
  );

  return (
    <Card title={t(`eventTypes.${props.eventType}`)}>
      <ReportCombinedBarChart
        showDataLabels={false}
        labels={chartData.labels}
        scaleLabel={"Number of employees"}
        chartHeight={340}
        max={totalMaxValue}
        datasets={chartDatasetsWithConfigs}
      />
      <Row justify="space-around" style={{ marginTop: "24px" }}>
        {mouCategoryKeys.map((d, idx) => (
          <Col key={idx}>
            <Space>
              <div
                style={{
                  minWidth: "50px",
                  height: "15px",
                  backgroundColor:
                    CHART_DATASETS_CONFIGS[d]?.backgroundColor || "aqua"
                }}
              />
              <span>{t(CHART_DATASETS_CONFIGS[d]?.label || "")}</span>
            </Space>
          </Col>
        ))}

        <Col>
          <Space>
            <div
              style={{
                minWidth: "50px",
                border: "1px dashed transparent",
                borderColor: CHART_DATASETS_CONFIGS.total.backgroundColor
              }}
            />
            <span>{t(CHART_DATASETS_CONFIGS.total.label)}</span>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
