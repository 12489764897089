export const ruCompetencyLevels = {
  null: "-",

  0: "Без компетенции",
  2: "Базовый",
  3: "Средний",
  4: "Продвинутый",
  5: "Компетентный",
  6: "Опытный",
  7: "Помощник",
  20: "Техник-стажер",
  22: "Младший-техник",
  24: "Техник",
  26: "Старший-техник"
};
