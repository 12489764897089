import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutateFunction } from "react-query";

import { useFormik } from "formik";

import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";

import {
  CompetencyType,
  SpecialityCategory,
  CreateDisciplinePayload
} from "@models/discipline";

const { Option } = Select;
interface Props {
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  createDiscipline: UseMutateFunction<
    unknown,
    unknown,
    CreateDisciplinePayload
  >;
  errors: { [key: string]: string };
  isLoading: boolean;
}

const CreateDisciplineModal: React.FC<Props> = ({
  isModalOpen,
  handleOpenModal,
  handleCloseModal,
  createDiscipline,
  isLoading,
  errors
}) => {
  const [t] = useTranslation();
  const [isComissioning, setComissioning] = useState<boolean>(true);

  const [form] = Form.useForm();
  const formik = useFormik<CreateDisciplinePayload>({
    initialValues: {
      nameEn: "",
      nameRu: "",
      specialityCategory: 0,
      competencyType: CompetencyType.COMMISSIONING
    },
    onSubmit: (values) => createDiscipline(values)
  });

  const competencyLevels = [
    { name: "standard", value: CompetencyType.DEFAULT },
    {
      name: "proficient",
      value: CompetencyType.PROFICIENT
    },
    {
      name: "competent",
      value: CompetencyType.COMPETENT
    },
    {
      name: "technicians",
      value: CompetencyType.COMMISSIONING
    }
  ];

  const specialities = useMemo(
    () => [
      {
        name: t("disciplines.speciality.noCompetencyLevel"),
        value: SpecialityCategory.Undefined
      },
      {
        name: t("disciplines.speciality.boltFlange"),
        value: SpecialityCategory.BoltFlangeJointAssembly
      },
      {
        name: t("disciplines.speciality.generalCivilWorks"),
        value: SpecialityCategory.GeneralCivilWorks
      },
      {
        name: t("disciplines.speciality.craneOperator"),
        value: SpecialityCategory.CraneOperator
      },
      {
        name: t("disciplines.speciality.heavyDutyVehicle"),
        value: SpecialityCategory.DriverHeavyDutyVehicle
      },
      {
        name: t("disciplines.speciality.lightEquipment"),
        value: SpecialityCategory.EquipmentOperatorLightEquipment
      },
      {
        name: t("disciplines.speciality.mediumEquipment"),
        value: SpecialityCategory.EquipmentOperatorMediumEquipment
      },
      {
        name: t("disciplines.speciality.heavyEquipment"),
        value: SpecialityCategory.EquipmentOperatorHeavyEquipment
      },
      {
        name: t("disciplines.speciality.cableGlanding"),
        value: SpecialityCategory.EXAwarenessAndCableGlanding
      },
      {
        name: t("disciplines.speciality.fieldSupervisor"),
        value: SpecialityCategory.FieldSupervisor
      },
      {
        name: t("disciplines.speciality.painter"),
        value: SpecialityCategory.PainterIndustrialCoatingsInstaller
      },
      {
        name: t("disciplines.speciality.rigger"),
        value: SpecialityCategory.Rigger
      }
    ],
    [t]
  );

  const handleCompetencySelect = (value: number) => {
    value === 0
      ? formik.setFieldValue("competencyType", CompetencyType.COMMISSIONING)
      : formik.setFieldValue("competencyType", CompetencyType.DEFAULT);
  };

  useEffect(() => {
    formik.setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <>
      <Modal
        visible={isModalOpen}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("disciplines.createDiscipline")}
        footer={null}
        width={800}
        onCancel={handleCloseModal}
      >
        <Form
          form={form}
          layout="vertical"
          onSubmitCapture={() => formik.submitForm()}
          initialValues={{
            eventType: 0
          }}
        >
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <Form.Item
                label={t("disciplines.disciplineNameEn")}
                validateStatus={formik.errors.nameEn && "error"}
                help={formik.errors.nameEn}
              >
                <Input
                  name="nameEn"
                  placeholder="Crane Operator: Overhead/Jib Crane"
                  value={formik.values.nameEn}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={t("disciplines.disciplineNameRu")}
                validateStatus={formik.errors.nameRu && "error"}
                help={formik.errors.nameRu}
              >
                <Input
                  name="nameRu"
                  placeholder="Оператор крана - балки управляемого с пола"
                  value={formik.values.nameRu}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <Form.Item label={t("disciplines.category")}>
                <Select
                  value={formik.values.specialityCategory}
                  placeholder={t("disciplines.speciality.noCompetencyLevel")}
                  onChange={(value) => {
                    formik.setFieldValue("specialityCategory", value);
                  }}
                >
                  {specialities.map(({ name, value }, index) => (
                    <Option key={`${name}-${index}`} value={value}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name="eventType" label={t("disciplines.eventType")}>
                <Select
                  placeholder={t("disciplines.commissioning")}
                  onChange={(value: number) => {
                    setComissioning(!isComissioning);
                    handleCompetencySelect(value);
                  }}
                >
                  <Option value={0}>{t("disciplines.commissioning")}</Option>
                  <Option value={1}>{t("disciplines.nonComissioning")}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={24}>
              <Form.Item label={t("disciplines.competencyLevel")}>
                <Select
                  placeholder={t("disciplines.competency.standard")}
                  value={formik.values.competencyType}
                  onChange={(value) =>
                    formik.setFieldValue("competencyType", value)
                  }
                >
                  {competencyLevels.map(({ name, value }) => (
                    <Option
                      value={value}
                      disabled={
                        (name !== "technicians" && isComissioning) ||
                        (name === "technicians" && !isComissioning)
                      }
                      key={value}
                    >
                      {t(`disciplines.competency.${name}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col>
              <Button htmlType="button" onClick={handleCloseModal}>
                {t("cancel")}
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary" loading={isLoading}>
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Button
        type="primary"
        icon={<PlusSquareOutlined />}
        onClick={() => {
          formik.resetForm();
          handleOpenModal();
        }}
        loading={isLoading}
      >
        {t("disciplines.createDiscipline")}
      </Button>
    </>
  );
};

export default CreateDisciplineModal;
