export const enTcoWhatNew = [
  {
    title: "New feature: Migration module for TCO User",
    html: `<p><span style="font-weight: 400;">This functionality lets TCO users with relevant permissions add a new migration within the Craft Training Portal.&nbsp;</span></p>
<p><span style="font-weight: 400;">Migration represents historical event data (including event info, craft employee names, their grades etc.) that needs to be uploaded in bulk.</span></p>`
  },
  {
    title:
      "New feature: Changes into grading and evaluation functionality, including implementation of multi-phase event grading system: Activation.",
    html: `<p><span style="font-weight: 400;">Activation feature lets Training Center users to separately put practical grades for craft employees when the event is in Completed or Evaluated status.&nbsp;</span></p>
<p><span style="font-weight: 400;">Training Center users are able to put practical grades after event activation is made by TCO user.</span></p>`
  },
  {
    title:
      "Changes to eligibility requirements: the 1-month rule has been removed  for craft employees to reseat the event;",
    html: `<p><span style="font-weight: 400;">With this change craft employees are not required to wait for one month to re-apply to the same type&amp; discipline of the event.</span></p>`
  },
  {
    title:
      "New feature: Addition of the 'location' field for all Event types: in Performance reports, Data Dump report, Report filters. ",
    html: `<p>TC Users are now able to specify location of the event including online and Tengiz. Do not mix up with the Training Center\`s city.</p>`
  },
  {
    title:
      "New feature: Direct apply of craft workers to the event by TCO user.",
    html: `<p><span style="font-weight: 400;">In the summer 2022 release of CT portal, TCO users are now able to apply craft employees to the event directly. Previously this option was only available for Training Center users. </span></p>`
  },
  {
    title: "New feature: Invitation of Training Center users by TCO user.",
    html: `<p><span style="font-weight: 400;">TCO users can invite Training Center users to the Portal.</span></p>`
  },
  {
    title:
      "New feature: Candidate Profiles: create, view, download profiles of local unemployed Training Centers` event graduates. \n" +
      "Part of TCO CSR program. \n" +
      "Available for TCO, Contractors and Training Center Users.\n",
    html: `<p><span style="font-weight: 400;">Profiles module of CT Portal shows job- seeking craft worker profiles, so that Contractor/ Subcontractor users could view and hire profiles. This module also stores all the information in the system.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Remember, in order to create a craft employee profile in the HR module, the craft employee must be already registered within the CT Portal via CSR Contractor. </span></p>`
  },
  {
    title:
      "New feature: Discipline dictionary and configuration: TCO Users now able to create new disciplines in the CT Portal.",
    html: `<p><span style="font-weight: 400;">This functionality lets TCO users with relevant permissions to add a new discipline within the Craft Training portal.</span></p>`
  },
  {
    title: "New feature: Online training report (KPJV) for non-craft employees",
    html: `<p><span style="font-weight: 400;">Contractor users are now able to create </span><span style="font-weight: 400;">Online Training Reports(KPJV): a list of non- craft employees with discipline, training start and end dates, etc. TCO users can view these Reports.</span></p>`
  },
  {
    title: "Changes into reporting and data dump module",
    html: `<p><span style="font-weight: 400;">New functionality and some minor changes have been added to Reports and Data Dump.</span></p>`
  }
];
