import { EventStatus } from "@models/event";

export const EVENT_STATUS_TO_ROUTE_MAP: { [key: number]: string } = {
  [EventStatus.PENDING]: "upcoming",
  [EventStatus.COMPLETED]: "completed",
  [EventStatus.CANCELLED]: "cancelled",
  [EventStatus.INPROGRESS]: "inprogress",
  [EventStatus.EVALUATED]: "evaluated",
  [EventStatus.ACTIVATED]: "activated"
};
