import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Button, Form, Row, Space } from "antd";
import SearchSelect from "@components/ui/SearchSelect";

import { Instructor } from "@models/instructor";

interface FormValues {
  instructorId?: number;
}

interface Props {
  isLoading?: boolean;
  instructors?: Instructor[];

  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

export const EventInstructorForm = (props: Props) => {
  const [t] = useTranslation();

  const formik = useFormik<FormValues>({
    initialValues: {
      instructorId: undefined
    },
    onSubmit(values) {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    }
  });

  const instructorOptions = useMemo(
    () =>
      props.instructors?.map((inst) => ({
        value: inst.id,
        label: inst.firstName + " " + inst.lastName
      })),
    [props.instructors]
  );

  return (
    <Form
      layout="vertical"
      onSubmitCapture={() => {
        formik.submitForm();
      }}
    >
      <Form.Item label={t("events.instructor")}>
        <SearchSelect
          options={instructorOptions}
          onChange={(v: number) => formik.setFieldValue("instructorId", v)}
        />
      </Form.Item>

      <Row justify="end">
        <Space>
          <Button onClick={() => props.onCancel && props.onCancel()}>
            {t("cancel")}
          </Button>

          <Button type="primary" htmlType="submit" loading={props.isLoading}>
            {t("add")}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
