import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Menu } from "antd";

function getCurrentNavItemIds(
  navItems: NavItem[],
  currentPath: string,
  ids: string[] = []
): string[] {
  const currentNavItem = navItems.find(
    (navItem) =>
      navItem.items?.find((item) => item.path === currentPath) ||
      navItem.path === currentPath
  );
  ids.push(currentNavItem?.id || "");

  if (currentNavItem?.items) {
    return getCurrentNavItemIds(currentNavItem.items, currentPath, ids);
  }

  return ids;
}

const Navbar = styled.div`
  background-color: #049aff !important;

  .ant-menu {
    background-color: transparent !important;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    color: #363636 !important;
  }

  .ant-menu-item-active span a,
  .ant-menu-submenu-open.ant-menu-submenu-active .ant-menu-submenu-title {
    color: #363636 !important;
  }

  .ant-menu-item-selected span,
  .ant-menu-item-selected span a,
  .ant-menu-submenu-selected {
    color: white !important;
  }
`;
const NavbarMenu = styled(Menu)`
  max-width: 1208px;
  height: 48px;
  margin: 0 auto;
  border-bottom: none !important;
`;

interface NavItemProps {
  id: string;
  text?: string;
  icon?: React.ReactNode;
  path?: string;
  isNavItem?: boolean;
}
interface NavItem extends NavItemProps {
  items?: NavItemProps[];
}

export interface NavigationProps {
  navItems: NavItem[];
}

const Navigation: React.FC<NavigationProps> = ({ navItems }) => {
  const [t] = useTranslation();
  const location = useLocation();
  const [defaultNavIds, setDefaultNavIds] = useState<string[]>([]);

  useEffect(() => {
    setDefaultNavIds(getCurrentNavItemIds(navItems, location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Navbar>
      <NavbarMenu
        onClick={() => {
          setDefaultNavIds(
            getCurrentNavItemIds(navItems, window.location.pathname)
          );
        }}
        selectedKeys={defaultNavIds}
        theme="light"
        mode="horizontal"
      >
        {navItems.map(
          (navItem) =>
            navItem.isNavItem &&
            (navItem.items ? (
              <Menu.SubMenu
                key={navItem.id}
                title={t(navItem.text!)}
                icon={navItem.icon}
              >
                {navItem.items.map((item) => (
                  <Menu.Item key={item.id}>
                    <Link to={item.path || "/wrongrouteitem"}>
                      {t(item.text!)}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={navItem.id} icon={navItem.icon}>
                <Link to={navItem.path || "/wrongrouteitem"}>
                  {t(navItem.text!)}
                </Link>
              </Menu.Item>
            ))
        )}
      </NavbarMenu>
    </Navbar>
  );
};

export default Navigation;
