import picture28 from "@assets/images/user-guide/1.28.png";
import picture29 from "@assets/images/user-guide/1.29.png";

export const contractors = [
  {
    title: "What's on All contractors page?",
    html: `<p dir="ltr">
    Once you press the Contractor tab on Panel menu you will be redirected to
    Contractors details page with the following information.
</p>
<p dir="ltr">
    Picture 1.28
</p>
<p dir="ltr">
    <img
        src=${picture28}
        width="624"
        height="257"
    />
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Search by BIN
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Company BIN, or a dash shown if company not from Kazakhstsan
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Contractor name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Register Contractor button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Export users button
        </p>
    </li>
</ol>
<p dir="ltr">
    Search button
</p>
<ol start="6">
    <li dir="ltr">
        <p dir="ltr">
            Action button
        </p>
    </li>
</ol>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Contractor details page
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Edit Contractor
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Delete Contractor
        </p>
    </li>
</ul>`
  },
  {
    title: "How to register Contractor?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Contractors tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Register contractor button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Fill out all fields in modal window and press Register button
        </p>
    </li>
</ol>
<p dir="ltr">
    Picture 1.29
</p>
<p dir="ltr">
    <img
        src=${picture29}
        width="552"
        height="868"
    />
</p>`
  },
  {
    title: "How to export users?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Contractors tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Export users button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            System will download an excel file with all registered Contractors
            data
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to edit Contractor?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Contractors tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Edit button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Make changes in modal window and press Save button
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to delete Contractor?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Contractors tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Delete button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In modal window press Yes button
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to increase the ‘Max number of Craft Workers to be trained’?",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Contractors tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select one Contractor
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            On Contractors details page navigate co Contracts block
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Edit button
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Make changes in Edit modal window and press Save button
        </p>
    </li>
</ol>
<p dir="ltr">
    Note: Capacity cannot be less than 0
</p>
`
  },
  {
    title: "Invite, edit and delete Contractor’s member",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on Contractors tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Select one Contractor
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            On Contractors details page navigate to Members block
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Invite member button – to register with KC/CT permission.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Edit button – to change members data.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press Delete button – to delete members from the portal.
        </p>
    </li>
</ol>
`
  }
];
