import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormikErrors, useFormik } from "formik";

import { Form, message, Input, Modal, Button } from "antd";

import { AppUser } from "@models/appUser";

import UserAPI from "@api/userApi";

import useValidatedMutation from "@hooks/useValidatedMutation";

interface FormValues {
  firstName: string;
  lastName: string;
}

interface ProfileEditModalProps {
  user: AppUser;
  onProfileEdit?: () => void;
}

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({
  user,
  onProfileEdit
}) => {
  const [t] = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: tryChangePassword, isLoading } =
    useValidatedMutation<FormValues>({
      mutationFunction: (values: FormValues) =>
        UserAPI.editProfile({
          firstName: values.firstName,
          lastName: values.lastName
        }).then((res) => res.data),

      onSuccess: () => {
        clearForm();
        message.success(t("profile.profileEditSuccess"));

        onProfileEdit && onProfileEdit();
      },
      onError: (error: FormikErrors<FormValues>) => {
        formik.setErrors(error);
      }
    });

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName
    },
    onSubmit: (values) => tryChangePassword(values)
  });

  useEffect(() => {
    formik.setValues({
      firstName: user.firstName,
      lastName: user.lastName
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const clearForm = () => {
    formik.resetForm();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" key="1" onClick={() => setIsModalOpen(true)}>
        {t("profile.editProfile")}
      </Button>

      <Modal
        visible={isModalOpen}
        closable={false}
        maskClosable={false}
        title={t("profile.editProfile")}
        cancelText={t("profile.cancel")}
        okText={t("profile.change")}
        confirmLoading={isLoading}
        okButtonProps={{ disabled: !formik.isValid }}
        onOk={() => formik.handleSubmit()}
        onCancel={() => clearForm()}
      >
        <Form>
          <Form.Item
            label={t("profile.firstName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.firstName && "error"}
            help={formik.errors.firstName}
          >
            <Input
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </Form.Item>

          <Form.Item
            label={t("profile.lastName")}
            labelCol={{ span: 24 }}
            validateStatus={formik.errors.lastName && "error"}
            help={formik.errors.lastName}
          >
            <Input
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProfileEditModal;
