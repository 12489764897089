import picture34 from "@assets/images/user-guide/3.34.png";
import picture35 from "@assets/images/user-guide/3.35.png";

export const certificateVerification = [
  {
    title: "Check certificate:",
    html: `<p dir="ltr">
    Picture 3.34
</p>
<p dir="ltr">
    <img
        src=${picture34}
        width="624"
        height="217"
    />
</p>
<p dir="ltr">
    Once you press Certificate verification tab on Panel menu you will be
    redirected to Check certificate page with the following information:
</p>
<br/>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Search by IIN, enter 12 digits
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search by Certificate number, enter 12 digits
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Search button
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to Check certificate:",
    html: `<p dir="ltr">
    Picture 3.35
</p>
<p dir="ltr">
    <img
        src=${picture35}
        width="624"
        height="337"
    />
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on <b>Certificate search</b> tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Input IIN and Certificate number
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press on <b>Search</b> button or Enter on your keyboard
        </p>
    </li>
</ol>
`
  },
  {
    title: "How to download certificate:",
    html: `<ol>
    <li dir="ltr">
        <p dir="ltr">
            On panel menu press on <b>Certificate search</b> tab
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Input IIN and Certificate number
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press <b>Search</b> button or Enter on your keyboard
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Press <b>Download certificate</b> button
        </p>
    </li>
</ol>
`
  }
];
