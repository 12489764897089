import React, { useContext, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { saveAs } from "file-saver";
import { AxiosError } from "axios";
import { UploadFile } from "antd/lib/upload/interface";

import { Button, Modal, notification } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import PageMeta from "@components/PageMeta";
import { AppPageHeader } from "@components/ui/AppPageHeader";
import { NewTrainingReportForm } from "@components/common/tc-reports/NewTrainingReportForm";
import { EditTrainingReportForm } from "@components/common/tc-reports/EditTrainingReportForm";
import { TrainingReportTable } from "@components/common/tc-reports/TrainingReportTable";
import { TCReportListItemActions } from "@components/common/tc-reports/TCReportListItemActions";

import { TrainingCenterReport, TcReportTypes } from "@models/trainingCenter";

import { trainingCenterApi } from "@api/trainingCenterApi";

import { transformValidationError } from "@utils/errorHelper";
import { useQueryParams } from "@hooks/useQueryParams";
import { UserContext } from "@contexts/UserContext";
import useValidatedMutation from "@hooks/useValidatedMutation";

interface Filters {
  iin?: string;
  firstName?: string;
  lastName?: string;
  specialityIds?: number[];
  competencyLevel?: number;
}

export const TrainingReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const { userEntityId } = useContext(UserContext);
  const TRAINING_CENTER_ID = userEntityId;
  const reportType = TcReportTypes.TRAINING_REPORT;
  const [editedReport, setEditedReport] = useState<TrainingCenterReport>();
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const queryParams = useQueryParams();
  const [page] = useState<number>(Number(queryParams.page) || 1);
  const [filters] = useState<Filters>({});

  const { data, refetch, isLoading } = useQuery(
    ["getTrainingReportsPage", page, filters],
    () =>
      trainingCenterApi
        .getTrainingCenterReports(TRAINING_CENTER_ID, reportType, page)
        .then((res) => res.data)
  );

  const createTrainingReport = useValidatedMutation({
    mutationFunction: (values: any) =>
      trainingCenterApi.createTrainingCenterReports({
        trainingCenterId: TRAINING_CENTER_ID,
        payload: {
          ...values,
          files: values.files?.map?.((f: UploadFile) =>
            f.originFileObj ? f.originFileObj : f
          )
        }
      }),
    onSuccess() {
      notification.success({
        message: t("reports.saveSuccess")
      });
      refetch();
      setIsModalShown(false);
    }
  });

  const updateTrainingReport = useValidatedMutation({
    mutationFunction: (values: any) =>
      trainingCenterApi.updateTrainingCenterReport({
        trainingCenterId: TRAINING_CENTER_ID,
        tcReportId: editedReport?.id,
        payload: {
          ...values,
          files: values.files?.map?.((f: UploadFile) =>
            f.originFileObj ? f.originFileObj : f
          )
        }
      }),
    onSuccess() {
      notification.success({
        message: t("reports.saveSuccess")
      });
      refetch();
      setIsEditModalShown(false);
    },
    onError(err) {
      notification.error({
        message: t("error.errorOccured")
      });
    }
  });

  const createErrors = useMemo(
    () => transformValidationError(createTrainingReport.error),
    [createTrainingReport.error]
  );

  const editErrors = useMemo(
    () => transformValidationError(updateTrainingReport.error),
    [updateTrainingReport.error]
  );

  const downloadTCReportMutation = useMutation(
    (payload: { reportId: number }) => {
      const curReport = data?.find((item) => item.id === payload.reportId);

      const reportsDownloads =
        curReport?.attachmentFiles.map(
          (file) =>
            new Promise((resolve, reject) =>
              trainingCenterApi
                .getAdminTCReportFile(payload.reportId, file.id)
                .then((res) =>
                  resolve(saveAs(new Blob([res.data]), file.fileName))
                )
                .catch((err: AxiosError<any>) =>
                  reject(err.response?.data.message)
                )
            )
        ) || [];

      return Promise.all(reportsDownloads);
    },
    {
      onError: (errorMessage: string) => {
        notification.error({
          message: t("error.errorOccured")
        });
      }
    }
  );

  const handleModal = (item?: TrainingCenterReport) => {
    setEditedReport(item ? item : undefined);
    setIsModalShown(!isModalShown);
  };

  return (
    <>
      <PageMeta title={t("reports.tcReports")} />

      <AppPageHeader
        title={t("reports.trainingReport")}
        breadcrumbs={[
          { breadcrumbName: "CT", path: "tc" },
          { breadcrumbName: t("reports.trainingReport"), path: "requests" }
        ]}
        extra={[
          <Button
            key="2"
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => {
              handleModal();
            }}
          >
            {t("reports.createReport")}
          </Button>
        ]}
      />
      <Modal
        visible={isModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("reports.trainingReport")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsModalShown(!isModalShown);
          createTrainingReport.reset();
        }}
      >
        <NewTrainingReportForm
          isLoading={createTrainingReport.isLoading}
          errors={createErrors}
          report={editedReport}
          reportType={reportType}
          onCancel={() => {
            setIsModalShown(!isModalShown);
            createTrainingReport.reset();
          }}
          onSubmit={(values) => {
            createTrainingReport.mutate(values);
          }}
        />
      </Modal>
      <Modal
        visible={isEditModalShown}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title={t("reports.trainingReport")}
        footer={null}
        width={800}
        onCancel={() => {
          setIsEditModalShown(!isEditModalShown);
          updateTrainingReport.reset();
        }}
      >
        <EditTrainingReportForm
          isLoading={updateTrainingReport.isLoading}
          errors={editErrors}
          report={editedReport}
          reportType={reportType}
          reportId={editedReport?.id || 0}
          tcId={TRAINING_CENTER_ID}
          onCancel={() => {
            setIsEditModalShown(!isEditModalShown);
            updateTrainingReport.reset();
          }}
          onSubmit={(values) => {
            updateTrainingReport.mutate(values);
          }}
        />
      </Modal>

      <TrainingReportTable
        items={data}
        isLoading={isLoading}
        title={t("reports.trainingReport")}
        rowLinkTo={(s) => `/tc/tc-report/${s.id}`}
        renderActions={(tcReport: TrainingCenterReport) => (
          <TCReportListItemActions
            reportId={tcReport.id}
            reportName={tcReport.name}
            actions={["delete", "download", "viewDetails"]}
            onDownload={(reportId: number) =>
              downloadTCReportMutation.mutate({ reportId })
            }
            isDownloadLoading={
              downloadTCReportMutation.isLoading &&
              downloadTCReportMutation.variables?.reportId === tcReport.id
            }
          />
        )}
      />
    </>
  );
};
