import React from "react";
import { useTranslation } from "react-i18next";

import { Space, Table, Typography, Button, PaginationProps } from "antd";
import { RightOutlined, CommentOutlined } from "@ant-design/icons";

import { RequestTicket } from "@models/requests";

import { PaginatedResponse } from "@api";

import useDateFormatter from "@hooks/useDateFormat";

const Column = Table.Column;

interface Props {
  title?: React.ReactNode;
  isLoading?: boolean;
  data?: PaginatedResponse<RequestTicket>;
  onDelete?: () => void;
  onEdit?: () => void;
  showAssignee?: boolean;
  showTrainingCenter?: boolean;
  showContractor?: boolean;
  // Function used to generate separate request view urls for Training Centers and Contractors
  generateUrl: (requestId: number) => string;
  pagination?: PaginationProps;
}

const RequestsTable: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  const { formatter } = useDateFormatter();

  return (
    <Table
      title={() => (
        <Space align="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {props.title}
          </Typography.Title>

          <Typography.Text type="secondary">
            {t("totalCount", { total: props.data?.totalItems })}
          </Typography.Text>
        </Space>
      )}
      locale={{ emptyText: t("noData") }}
      loading={props.isLoading}
      tableLayout="auto"
      bordered
      pagination={{
        ...props.pagination,
        position: ["topRight", "bottomRight"],
        pageSize: 20,
        showSizeChanger: false,
        hideOnSinglePage: true
      }}
      rowKey="id"
      dataSource={props.data?.results}
    >
      <Column
        title="ID"
        render={(request: RequestTicket) => (
          <Button type="link" href={props.generateUrl(request.id)}>
            {request.id}
          </Button>
        )}
      />
      <Column title={t("requests.subject")} dataIndex="subject" />
      {props.showTrainingCenter && (
        <Column
          title={t("trainingCenters.trainingCenter")}
          dataIndex={["trainingCenter", "name"]}
        />
      )}
      {props.showContractor && (
        <Column
          title={t("contractors.contractor")}
          dataIndex={["contractor", "name"]}
        />
      )}
      {props.showAssignee && (
        <Column
          title={t("requests.assignee")}
          render={({ trainingCenterUser }: RequestTicket) =>
            trainingCenterUser
              ? `${trainingCenterUser.firstName} ${trainingCenterUser.lastName}`
              : ""
          }
        />
      )}
      <Column
        title={t("requests.lastCommentDate")}
        render={(request: RequestTicket) =>
          request.lastCommentDate && formatter(request.lastCommentDate)
        }
      />
      <Column
        title={t("requests.createdAt")}
        render={(request: RequestTicket) => formatter(request.createdAt)}
      />
      <Column
        title={t("requests.numberOfReplies")}
        render={(request: RequestTicket) => (
          <Button
            type="primary"
            icon={<CommentOutlined />}
            href={props.generateUrl(request.id)}
          >
            &nbsp;{request.commentsCount}
          </Button>
        )}
      />

      <Column
        render={(request: RequestTicket) => (
          <Button
            shape="circle"
            icon={<RightOutlined />}
            href={props.generateUrl(request.id)}
          />
        )}
      />
    </Table>
  );
};

export default RequestsTable;
