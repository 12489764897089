import axios, { AxiosError } from "axios";
import qs from "qs";
import { saveAs } from "file-saver";

import { ApiValidationError } from "@models/apiValidationError";
import {
  CreateEmployeePayload,
  EmployeeConversionResult,
  EmployeeDetails,
  EmployeeEventHistoryItem,
  EmployeeSearchResult,
  EmployeeShortDetails
} from "@models/employee";
import { PaginationItem } from "@models/paginationItem";

const getEmployeesPage = ({
  page,
  filters = {}
}: {
  page: number;
  filters?: {
    iin?: string;
    firstName?: string;
    lastName?: string;
    specialityIds?: number[];
    competencyLevel?: number;
  };
}) =>
  axios.get<PaginationItem<EmployeeShortDetails>>(`/api/employee`, {
    params: { page, ...filters }
  });

const getTCEmployeesPage = ({
  page,
  trainingCenterId,
  filters = {}
}: {
  page: number;
  trainingCenterId: number;
  filters?: {
    iin?: string;
    firstName?: string;
    lastName?: string;
    specialityIds?: number[];
    competencyLevel?: number;
  };
}) =>
  axios.get<PaginationItem<EmployeeShortDetails>>(
    `/api/training-center/${trainingCenterId}/employee`,
    {
      params: { page, ...filters }
    }
  );

const getEmployeePage = ({
  contractorId,
  page,
  filters = {}
}: {
  contractorId: number;
  page: number;
  filters?: {
    iin?: string;
    firstName?: string;
    lastName?: string;
    specialityIds?: number[];
    competencyLevel?: number;
  };
}) =>
  axios.get<PaginationItem<EmployeeShortDetails>>(
    `/api/contractor/${contractorId}/employee`,
    { params: { page, ...filters } }
  );

const getEmployee = ({
  contractorId,
  employeeId
}: {
  contractorId: number;
  employeeId: number;
}) =>
  axios.get<EmployeeDetails>(
    `/api/contractor/${contractorId}/employee/${employeeId}`
  );

const getEmployeeTC = ({ employeeId }: { employeeId: number }) =>
  axios.get<EmployeeDetails>(`/api/employee/${employeeId}`);

const getEmployeeAdmin = ({ employeeId }: { employeeId: number }) =>
  axios.get<EmployeeDetails>(`/api/employee/${employeeId}`);

const createEmployee = ({
  contractorId,
  employee
}: {
  contractorId: number;
  employee: CreateEmployeePayload;
}) =>
  axios
    .post(`/api/contractor/${contractorId}/employee`, employee)
    .catch((err: AxiosError<ApiValidationError>) => {
      // transform error object

      if (err.response?.data.validationErrors) {
        const validationErrors = err.response?.data.validationErrors;

        const reformattedErrors = validationErrors.map((ve) => ({
          name: ve.name.substring(5), // remove array item prefix
          description: ve.description
        }));

        err.response.data.validationErrors = reformattedErrors;

        throw err;
      }
    });

const updateEmployee = ({
  contractorId,
  employeeId,
  employeeData
}: {
  contractorId: number;
  employeeId: number;
  employeeData: Partial<EmployeeDetails>;
}) =>
  axios.put(
    `/api/contractor/${contractorId}/employee/${employeeId}`,
    employeeData
  );

const updateEmployeeForAdmin = ({
  employeeId,
  employeeData
}: {
  contractorId: number;
  employeeId: number;
  employeeData: Partial<EmployeeDetails>;
}) =>
  axios.put(`/api/contractor/employee/${employeeId}/forAdmin`, employeeData);

const updateEmployeeIin = ({
  employeeId,
  employeeData
}: {
  employeeId: number;
  employeeData: Partial<EmployeeDetails>;
}) => axios.put(`/api/employee/${employeeId}/iin`, employeeData);

const uploadEmployeeBatch = ({
  contractorId,
  file
}: {
  contractorId: number;
  file: Blob;
}) => {
  const formData = new FormData();

  formData.append("file", file);

  return axios.post<EmployeeConversionResult[]>(
    `/api/contractor/${contractorId}/employee/batch/convert`,
    formData
  );
};

const createEmployeeBatch = ({
  contractorId,
  employees
}: {
  contractorId: number;
  employees: Partial<CreateEmployeePayload>[];
}) =>
  axios.post<void>(`/api/contractor/${contractorId}/employee/batch`, {
    employees
  });

const downloadBatchUploadTemplate = (contractorId: number) =>
  axios
    .get(`/api/contractor/${contractorId}/employee/batch/template`, {
      responseType: "arraybuffer"
    })
    .then((response) => {
      return saveAs(new Blob([response.data]), "employee_template.xlsx");
    });

const searchEmployeesByIINs = (
  contractorId: number,
  eventId: number,
  iins: string[]
) => {
  const queryString = qs.stringify({ iins }, { indices: false });
  return axios.get<EmployeeSearchResult>(
    `/api/contractor/${contractorId}/event/${eventId}/employee/search-by-iins?${queryString}`
  );
};

const searchEmployeesByIINsForAdmin = (eventId: number, iins: string[]) => {
  const queryString = qs.stringify({ iins }, { indices: false });
  return axios.get<EmployeeSearchResult>(
    `/event/${eventId}/employee/search-by-iins-for-admin?${queryString}`
  );
};

const getTcEmployeeEventHistory = ({
  employeeId,
  trainingCenterId
}: {
  trainingCenterId: number;
  employeeId: number;
}) =>
  axios.get<PaginationItem<EmployeeEventHistoryItem>>(
    `/api/training-center/${trainingCenterId}/employee/${employeeId}/event/history`
  );

const getContractorEmployeeEventHistory = ({
  employeeId,
  contractorId
}: {
  contractorId: number;
  employeeId: number;
}) =>
  axios.get<PaginationItem<EmployeeEventHistoryItem>>(
    `/api/contractor/${contractorId}/employee/${employeeId}/event/history`
  );

const getEmployeeEventHistory = ({ employeeId }: { employeeId: number }) =>
  axios.get<PaginationItem<EmployeeEventHistoryItem>>(
    `/api/employee/${employeeId}/event/history`
  );

export const employeeApi = {
  getTCEmployeesPage,
  getEmployeePage,
  getEmployee,
  createEmployee,
  updateEmployee,
  updateEmployeeForAdmin,
  uploadEmployeeBatch,
  createEmployeeBatch,
  downloadBatchUploadTemplate,
  getEmployeesPage,
  getEmployeeTC,
  getEmployeeAdmin,
  updateEmployeeIin,
  searchEmployeesByIINs,
  searchEmployeesByIINsForAdmin,
  getTcEmployeeEventHistory,
  getContractorEmployeeEventHistory,
  getEmployeeEventHistory
};
