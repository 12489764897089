export interface Discipline {
  id: number;
  speciality: {
    nameEn: string;
    nameRu: string;
  };
  specialityId: number;
  nameEn: string;
  nameRu: string;
  competencyType: CompetencyType;
  competencyRequired?: boolean;
}

export interface EditDisciplinePayload {
  nameEn: string;
  nameRu: string;
}

export interface CreateDisciplinePayload extends EditDisciplinePayload {
  competencyType: number;
  specialityCategory: number;
}

export enum CompetencyType {
  DEFAULT = 1,
  DEFAULT_HELPER = 2,
  COMPETENT = 3,
  PROFICIENT = 4,
  COMMISSIONING = 5,
  INTERMEDIATE_ADVANCED = 6
}

export enum SpecialityCategory {
  Undefined = 0,
  BoltFlangeJointAssembly = 1,
  GeneralCivilWorks = 2,
  CraneOperator = 3,
  DriverHeavyDutyVehicle = 4,
  EquipmentOperatorLightEquipment = 5,
  EquipmentOperatorMediumEquipment = 6,
  EquipmentOperatorHeavyEquipment = 7,
  EXAwarenessAndCableGlanding = 8,
  FieldSupervisor = 9,
  PainterIndustrialCoatingsInstaller = 10,
  Rigger = 11
}
